import React from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'

import buildActionCreators from '../../../helpers/buildActionCreators'
import RemoveArticle from './RemoveArticle'
import Translate from '../../../Translate'
import * as ActionTypes from '../../../constants/actionTypes'
import { isAlertRemoveArticleOpen } from '../../../selectors/uiSelectors'

type RemoveArticleModalProps = {
  isOpen: boolean
  removeArticleClose: () => void
  removeArticleOpen: () => void
  removeArticles: () => void
}

class RemoveArticleModal extends React.PureComponent<RemoveArticleModalProps> {
  removeArticles = () => {
    this.props.removeArticles()
    this.props.removeArticleClose()
  }

  render() {
    const { isOpen, removeArticleClose } = this.props

    return (
      <Modal
        className="modal top am-fade-and-slide-top am-fade"
        tabIndex="-1"
        role="dialog"
        show={isOpen}
        onHide={removeArticleClose}
      >
        <Modal.Header>
          <button type="button" className="close" onClick={removeArticleClose}>
            ×
          </button>
          <h4 className="modal-title">
            <Translate i18nString="Remove articles from next alert" />
          </h4>
        </Modal.Header>

        <Modal.Body className="op-modal-alert_remove_articles">
          <RemoveArticle {...this.props} />
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-container">
            <button className="op-button green" onClick={this.removeArticles}>
              <Translate i18nString="Remove articles" />
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    )
  }
}

// @ts-ignore
RemoveArticleModal = connect(
  (state) => ({
    isOpen: isAlertRemoveArticleOpen(state),
  }),
  buildActionCreators({
    removeArticles: ActionTypes.ALERT_REMOVE_TRASHED_ARTICLES,
    removeArticleOpen: ActionTypes.ALERT_REMOVE_ARTICLE_OPEN,
    removeArticleClose: ActionTypes.ALERT_REMOVE_ARTICLE_CLOSE,
  }),
  // @ts-ignore
)(RemoveArticleModal)

export default RemoveArticleModal

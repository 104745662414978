import React from 'react'

import { tagStyle } from './common'
import type { TagComponentProps } from '../../opoint/flow'

export default class TrashTag extends React.PureComponent<TagComponentProps> {
  onTrashClickHandler = () => {
    const { article, tag, setTrashTagFn } = this.props
    setTrashTagFn(tag, article)
  }

  render() {
    const { article, checked, tag } = this.props

    return (
      // @ts-ignore
      <div className={`op-tag op-tag--trash ${tagStyle(tag, article, checked)}`}>
        <span className="op-icon-bin" />
        &nbsp;
        <span onClick={this.onTrashClickHandler} dangerouslySetInnerHTML={{ __html: tag.name }} />
      </div>
    )
  }
}

import React from 'react'
import { connect } from 'react-redux'

import AutocompleteItem from '../../opoint/search/AutocompleteItem'
import buildActionCreators from '../../helpers/buildActionCreators'
import SearchInput from '../../components/search/SearchInput'
import { ProfileEditorFilterComponent } from '../search/FilterComponent'
import { isProfileEditorFiltersPanelOpen, getInlineSuggestions } from '../../selectors/profilesSelectors'
import { filterIsInverted } from '../../opoint/search/index'
import { emptyFunction } from '../../helpers/common'
import * as ActionTypes from '../../constants/actionTypes'
import type { ProfileEditorActionPayload, Searchline, Suggestion, Filter } from '../../opoint/flow'

type ProfileEditorLineProps = {
  id: number
  searchline: Searchline
  onSearchTermChangedProfileEditor: (payload: ProfileEditorActionPayload) => void
  onProfileEditorFocusedLineChanged: ({ id: number }) => void
  onSearchDataClearedProfileEditor: ({ id: number }) => void
  alwaysShowClearAll: boolean
  onSearchFilterAdded: ({ id: number, filter: Filter }) => void
  onSearchFilterToggled: (filter: Filter) => void
  onSearchFilterRemoved: ({ id: number, filter: Filter }) => void
  invertFilter: ({ id: number, filter: Filter }) => void
  inlineSuggestions: Array<Suggestion>
  isProfileEditorFiltersPanelOpen: boolean
  placeholder: string
}

class ProfileEditorLine extends React.PureComponent<ProfileEditorLineProps> {
  static defaultProps = {
    alwaysShowClearAll: true,
  }

  onSearchTermChanged = (text) => {
    const { id, onSearchTermChangedProfileEditor } = this.props
    onSearchTermChangedProfileEditor({ id, text })
  }

  onFilterAdded = (filter) => {
    const { id, onSearchFilterAdded } = this.props
    onSearchFilterAdded({ id, filter })
  }

  onFilterToggled = (filter) => {
    const { onSearchFilterToggled } = this.props
    onSearchFilterToggled(filter)
  }

  onSearchFilterRemoved = (filter) => {
    const { id, onSearchFilterRemoved } = this.props
    onSearchFilterRemoved({ id, filter })
  }

  onFocus = () => {
    const { id, onProfileEditorFocusedLineChanged } = this.props

    onProfileEditorFocusedLineChanged({ id })
  }

  excludeHandler = (filter) => {
    const { id, invertFilter } = this.props
    !filterIsInverted(filter) && invertFilter({ id, filter })
  }

  includeHandler = (filter) => {
    const { id, invertFilter } = this.props

    filterIsInverted(filter) && invertFilter({ id, filter })
  }

  clearAllHandler = () => {
    const { id, onSearchDataClearedProfileEditor } = this.props
    onSearchDataClearedProfileEditor({ id })
  }

  render() {
    const {
      alwaysShowClearAll,
      id,
      inlineSuggestions,
      isProfileEditorFiltersPanelOpen /* eslint-disable-line no-shadow */,
      placeholder,
      searchline,
    } = this.props

    return (
      <SearchInput
        alwaysShowClearAll={alwaysShowClearAll}
        clearAllHandler={this.clearAllHandler}
        excludeHandler={this.excludeHandler}
        filterComponent={ProfileEditorFilterComponent}
        filters={searchline.filters}
        id={id}
        includeHandler={this.includeHandler}
        onFilterAdded={this.onFilterAdded}
        onFilterToggled={this.onFilterToggled}
        onFocus={this.onFocus}
        onLoadSuggestions={emptyFunction}
        onSearchTermChanged={this.onSearchTermChanged}
        placeholder={placeholder}
        removeFilter={this.onSearchFilterRemoved}
        searchterm={searchline.searchterm}
        showInlineSuggestions={!isProfileEditorFiltersPanelOpen}
        suggestionComponent={AutocompleteItem}
        suggestions={inlineSuggestions}
        triggerSearch={emptyFunction}
      />
    )
  }
}

export default connect(
  (state, { id }: any) => ({
    inlineSuggestions: getInlineSuggestions(id)(state),
    isProfileEditorFiltersPanelOpen: isProfileEditorFiltersPanelOpen(state),
  }),
  buildActionCreators({
    onSearchTermChangedProfileEditor: ActionTypes.SEARCHTERM_CHANGED_PROFILE_EDITOR,
    onSearchDataClearedProfileEditor: ActionTypes.SEARCHDATA_CLEAR_PROFILE_EDITOR,
    onProfileEditorFocusedLineChanged: ActionTypes.PROFILE_EDITOR_FOCUSED_LINE_CHANGED,
    onSearchFilterAdded: ActionTypes.PROFILE_EDITOR_FILTER_ADDED,
    onSearchFilterToggled: ActionTypes.PROFILE_EDITOR_SEARCHFILTER_TOGGLE,
    onSearchFilterRemoved: ActionTypes.PROFILE_EDITOR_FILTER_REMOVED,
    invertFilter: ActionTypes.PROFILE_EDITOR_FILTER_INVERTED,
  }),
  // @ts-ignore
)(ProfileEditorLine)

import React from 'react'

import { highlightSearched } from '../../../opoint/common/highlight'

export default class AlertSuggestion extends React.PureComponent<any> {
  onClickHandler = () => {
    const { onClickHandler, suggestion } = this.props

    onClickHandler(suggestion)
  }

  render() {
    const { hint, suggestion } = this.props
    const suggestionHtml = { __html: highlightSearched(suggestion.name, hint) }

    return (
      <span className="auto-item" onClick={this.onClickHandler}>
        <span className="auto-item__name" dangerouslySetInnerHTML={suggestionHtml} />
      </span>
    )
  }
}

import React from 'react'

import Tag from './tagItem'

// Tag listing component for left column
export default class TagList extends React.Component<any, any> {
  constructor(props) {
    super(props)
    // state is necessary for correct DnD sorting of tags
    this.state = {
      prevOffset: 0,
      dragDir: null, // true is down, false is up
    }
  }

  setDirection = (dir) => {
    this.setState({ dragDir: dir })
  }

  dropHandler = (data) => {
    this.props.onTagsReorder(data)
  }

  dragHoverHandler = (offset) => {
    this.setState({ prevOffset: offset })
  }

  render() {
    const { dragDir, prevOffset } = this.state
    const { displayImportant, tags, filtered, lastUsedTagID } = this.props

    const importantTags =
      tags.length >= 5
        ? tags.filter((tag) =>
            tags
              .slice() // Finds out which 5 tags have lowest score and displays them accordingly
              .sort(({ score: a }, { score: b }) => a - b)
              .slice(0, 5)
              .includes(tag),
          )
        : tags

    let displayTags

    if (displayImportant && !filtered) {
      displayTags = importantTags
    } else {
      displayTags = tags
    }

    return (
      <ul>
        {displayTags.map((tag, key) => (
          <Tag
            dragDir={dragDir}
            dragHoverHandler={this.dragHoverHandler}
            dropHandler={this.dropHandler}
            index={key}
            key={tag.id}
            prevOffset={prevOffset}
            setDirection={this.setDirection}
            tag={tag}
            isActive={lastUsedTagID === tag.id}
          />
        ))}
      </ul>
    )
  }
}

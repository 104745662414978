import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { translate } from 'react-i18next'

import buildActionCreators from '../../helpers/buildActionCreators'
import entityFilter from '../common/entityFilter'
import EntityHeader from '../common/EntityHeader'
import FilterMessage from '../common/FilterMessage'
import { getUISetting } from '../../selectors/settingsSelectors'
import { isAnalyticsSelected, getAnalyticsTags } from '../../selectors/analytics'
import { MODULE } from '../../opoint/settings/index'
import { searchContext } from '../../selectors/routingSelectors'
import * as ActionTypes from '../../constants/actionTypes'

type StatViewsListingProps = {
  displayImportant: string
  expanded?: boolean
  filterExpression?: string
  list: Array<Object>
  numberOfFiltered: number
  toggleCategory: () => {}
  resetFilter: () => {}
  onLabelClick: () => {}
  isChecked: () => {}
}

class AnalyticsListing extends React.PureComponent<any> {
  onOpenPageClickHandler = (id) => () => {
    this.props.onLabelClick(this.props.searchContext, id)
  }

  isExpanded = () => {
    const { expanded, filterExpression } = this.props
    return expanded || !!filterExpression
  }

  render() {
    const {
      filterExpression,
      list: analytics,
      numberOfFiltered,
      resetFilter,
      isChecked,
      i18n,
      toggleCategory,
    } = this.props
    if (!analytics.length) {
      return null
    }
    return (
      <div className="col-md-12">
        <div className="row">
          <div className="entity">
            <EntityHeader
              module={MODULE.STATISTICS}
              newButton={false}
              isExpanded={this.isExpanded()}
              newEntityTitle={i18n.t('Create new tag')}
              {...this.props}
            />
            <div
              className={classNames('entity__body', 'entity__body--marginBottom2', {
                'entity_body--hidden': !this.isExpanded(),
              })}
            >
              {analytics && !!analytics.length && (
                <ul>
                  {analytics.map((analytic) => (
                    <li key={analytic.id} title={analytic.name}>
                      <div className="checkbox-container">
                        <div>
                          <div className="op-checkbox">
                            <input type="checkbox" id={analytic.id} checked={isChecked(analytic.id)} readOnly />
                            <label htmlFor={analytic.id} onClick={this.onOpenPageClickHandler(analytic.id)} />
                          </div>
                          <span className="checkbox-desc" onClick={this.onOpenPageClickHandler(analytic.id)}>
                            {analytic.name}
                          </span>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
              <FilterMessage
                filterExpression={filterExpression}
                listLength={analytics.length}
                listName="views"
                numberOfFiltered={numberOfFiltered}
                resetFilter={resetFilter}
                toggleCategory={toggleCategory}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const ConnectedAnalyticsListing = connect(
  (state, props: any) => ({
    list: getAnalyticsTags(state),
    expanded: getUISetting(props.category)(state),
    searchContext: searchContext(state),
    isChecked: (id) => isAnalyticsSelected(id)(state),
  }),
  {
    onLabelClick: (searchUrlContext, id) => push(`/${searchUrlContext}/?filters=tag:${id}`),
    ...buildActionCreators({
      toggleCategory: ActionTypes.TOGGLE_UI_SETTING,
      resetFilter: ActionTypes.ENTITY_FILTER_CHANGED,
      setActiveView: ActionTypes.STATISTICS_VIEWS_SET_ACTIVE,
    }),
  },
)(entityFilter(AnalyticsListing))

const translatedAnalyticsListing = translate([], { wait: true })(ConnectedAnalyticsListing)

export default translatedAnalyticsListing

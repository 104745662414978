import React from 'react'
import R from 'ramda'
import classNames from 'classnames'

import Loader from '../../common/Loader'
import Translate from '../../../Translate'
import { Article, XhrStatus } from '../../../opoint/flow'

/**
 * This is a higher order component that holds the context
 * of an edited article.
 * If an article component is wrapped with this
 * HOC, it can support editable mode.
 */
type Props = {
  article: Article
  updateArticle: (Article, UpdatedArticleData) => void
  xhrStatus: XhrStatus
  previewMode: boolean
  previewModeOn: () => void
  previewModeOff: () => void
}
const EditableArticle = (ArticleComponent) =>
  class extends React.Component<Props, any> {
    constructor(props) {
      super(props)
      this.state = {
        content: {},
      }

      this.onSaveClick = this.onSaveClick.bind(this)
      this.onEditClick = this.onEditClick.bind(this)
      this.editableModeFnHandler = this.editableModeFnHandler.bind(this)
      this.onContentChange = this.onContentChange.bind(this)
    }

    onSaveClick() {
      const { article, updateArticle } = this.props

      updateArticle(article, this.state.content)
    }

    onEditClick() {
      this.props.previewModeOff()
    }

    onContentChange(content) {
      this.setState({
        content: R.merge(this.state.content, content),
      })
    }

    editableModeFnHandler(editable) {
      const { previewModeOn, previewModeOff } = this.props

      editable ? previewModeOff() : previewModeOn()
    }

    render() {
      const { article, xhrStatus, previewMode } = this.props

      return (
        <div className="editContent">
          <div className="editContent__options">
            {!previewMode && (
              <div>
                <button className="op-button-toolbar" onClick={this.onSaveClick}>
                  <Translate i18nString="Save" />
                </button>
              </div>
            )}
            {previewMode && (
              <button className="op-button-toolbar" onClick={this.onEditClick}>
                <Translate i18nString="Edit" />
              </button>
            )}
          </div>
          <div
            className={classNames('editContent__content', {
              action: xhrStatus === 'IN_PROGRESS',
              success: xhrStatus === 'SUCCESS',
              failure: xhrStatus === 'FAILURE',
            })}
          >
            {xhrStatus === 'IN_PROGRESS' && (
              <div className="editContent__message">
                <Loader />
              </div>
            )}
            <ArticleComponent
              {...this.props}
              article={!previewMode ? R.merge(article, this.state.content) : article}
              editable={!previewMode}
              editMode
              editableModeFn={this.editableModeFnHandler}
              onContentChange={this.onContentChange}
            />
          </div>
        </div>
      )
    }
  }

export default EditableArticle

import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'rc-slider'
import { connect } from 'react-redux'

import buildActionCreators from '../../../helpers/buildActionCreators'
import Module from './Module'
import GENERATED_BACKEND_TRANSLATIONS from '../../../generated-backend-translations'
import { getTemplateSortableModules } from '../../../selectors/templatesSelectors'
import { getUpdatedModule } from '../../../opoint/templates/index'
import * as ActionTypes from '../../../constants/actionTypes'
import Translate from '../../../Translate'
import type { TemplateDetail, Widget } from '../../../opoint/flow'

type Props = {
  modules: TemplateDetail
  sortableModules: { [moduleName: string]: Array<Widget> }
  openSortableModuleModal: ({ module: any }) => void
  maxLevel: number
}

type State = {
  level: number
}

class ModuleWrapper extends React.PureComponent<Props, State> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  constructor(props) {
    super(props)

    this.state = {
      level: props.maxLevel,
    }
  }

  componentWillReceiveProps({ maxLevel }) {
    if (this.props.maxLevel < maxLevel) {
      this.setState({
        level: maxLevel,
      })
    }
  }

  onLevelChange = (value) => {
    this.setState({
      level: value,
    })
  }

  render() {
    const { modules, maxLevel, sortableModules, openSortableModuleModal } = this.props
    const { level } = this.state
    const { i18n } = this.context

    return (
      <div>
        {maxLevel > 0 && (
          <div>
            <span className="template-editor__slider-span">
              <Translate i18nString="Choose level (options with this level or with lower level will be shown)" />
            </span>
            <div className="template-editor__slider-wrapper">
              <Slider
                className="template-editor__slider"
                min={0}
                max={maxLevel}
                step={1}
                value={level}
                onChange={this.onLevelChange}
                dots
              />
              <span className="vertical-align">{level}</span>
            </div>
          </div>
        )}
        {modules &&
          Object.keys(modules).map((key, index) => {
            let module = modules[key]
            const sortableModuleWidgets = sortableModules[key]
            if (module.sortable && sortableModuleWidgets) {
              module = getUpdatedModule(module, sortableModuleWidgets)
            }
            return (
              <div key={index} className="form-group op-settings-item">
                <h2 style={{ display: 'inline-block' }}>
                  {i18n.t(GENERATED_BACKEND_TRANSLATIONS.templates[module.header]) || module.header}
                </h2>
                {module.sortable && (
                  <button
                    type="button"
                    className="op-button-square mod-bigger mod-inline"
                    title={i18n.t('Sort settings')}
                    onClick={() => openSortableModuleModal({ module: key })}
                  >
                    <span className="op-icon-list-numbered" />
                  </button>
                )}
                <Module module={module} maxLevel={level} />
              </div>
            )
          })}
      </div>
    )
  }
}

// @ts-ignore
ModuleWrapper = connect(
  (state) => ({
    sortableModules: getTemplateSortableModules(state),
  }),
  buildActionCreators({
    openSortableModuleModal: ActionTypes.TEMPLATE_EDITOR_MODULE_SORT_MODAL_OPEN,
  }),
  // @ts-ignore
)(ModuleWrapper)

export default ModuleWrapper

import React, { useState, useEffect, memo } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next-latest'
import classNames from 'classnames'

import styles from './index.module.scss'
import Modal from '../common/Modal'
import buildActionCreators from '../../helpers/buildActionCreators'
import Translate from '../common/Translate'
import version from '../../version.json'
import { getOpointLocale } from '../../selectors/settingsSelectors'
import { isHelpModalOpen } from '../../selectors/uiSelectors'
import { locales } from '../../opoint/common/constants'
import { HELP_VIDEOS, HELP_PDFS } from '../../opoint/help/index'
import { HELP_MODAL_CLOSE } from '../../constants/actionTypes'
import { getCurrentM360Version } from '../../opoint/version/index'
import type { LocaleData, LocaleKey, HelpVideo } from '../../opoint/flow'
import Flexbox from '../common/Flexbox'

const { fullCurrentM360Version } = getCurrentM360Version()

interface Props {
  data: LocaleData
  helpModalClose: () => void
  isOpen: boolean
  opointLocale: LocaleKey
}

const renderPdfManualLink = (locale: LocaleKey): React.ReactElement => (
  <a href={HELP_PDFS[locale].link} target="_blank">
    <Translate text="User manual" />
  </a>
)

const setDefaultVideo = (videos: Array<HelpVideo>): HelpVideo | null => (videos && videos.length && videos[0]) || null

const HelpModal = ({ data, helpModalClose, isOpen, opointLocale }: Props) => {
  const [currentVideo, setCurrentVideo] = useState<HelpVideo | null>(setDefaultVideo(HELP_VIDEOS[opointLocale]))

  const { t } = useTranslation()

  useEffect(() => {
    setCurrentVideo(setDefaultVideo(HELP_VIDEOS[opointLocale]))
  }, [opointLocale])

  const renderVersion = () => <small className="version">{`${t('Version')}: ${fullCurrentM360Version}`}</small>

  const renderPhone = () => (
    <h4>
      {`${t('Call us')}: `}
      <a href={`tel:${data.phone}`}>{data.phone}</a>
    </h4>
  )

  const renderEmail = () => (
    <a href={`mailto:${data.email}`} className="op-button green">
      <Translate text="Send us an email" />
    </a>
  )

  const renderTop = () => (
    <Flexbox className={styles.actionsWrapper} flexDirection="row" justifyContent="space-between" alignItems="center">
      {HELP_PDFS[opointLocale] ? renderPdfManualLink(opointLocale) : renderPdfManualLink('en-GB')}
      <Flexbox className={styles.emailPhoneWrapper} flexDirection="row" alignItems="center">
        {!!data.phone && renderPhone()}
        {renderEmail()}
      </Flexbox>
    </Flexbox>
  )

  const renderVideoList = () => (
    <ul>
      {HELP_VIDEOS[opointLocale].map((video, index) => (
        <li key={index} className={classNames({ [styles.active]: video.name === currentVideo?.name })}>
          <button onClick={() => setCurrentVideo(video)}>{video.name}</button>
        </li>
      ))}
    </ul>
  )

  const renderCurrentVideo = () => (
    <iframe
      title={currentVideo.name}
      width="100%"
      height="400"
      src={currentVideo.link}
      frameBorder="0"
      allowFullScreen
    />
  )

  return (
    <Modal isOpen={isOpen} onHide={helpModalClose} title="Help" footer={version && renderVersion()}>
      <div className={styles.modalWrapper}>
        {renderTop()}
        <hr />
        {HELP_VIDEOS[opointLocale] && HELP_VIDEOS[opointLocale].length && (
          <Flexbox className={styles.videoWrapper} flexDirection="row">
            <div>
              <h4>
                <Translate text="Watch instruction video:" />
              </h4>
              {renderVideoList()}
            </div>
            <div>{currentVideo && renderCurrentVideo()}</div>
          </Flexbox>
        )}
      </div>
    </Modal>
  )
}

export default connect(
  (state) => {
    const opointLocale = getOpointLocale(state)
    return {
      opointLocale,
      isOpen: isHelpModalOpen(state),
      data: locales[opointLocale].data,
    }
  },
  buildActionCreators({
    helpModalClose: HELP_MODAL_CLOSE,
  }),
)(memo(HelpModal))

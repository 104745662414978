import React from 'react'

import AlertTag from './alert-tag'
import KeywordTag from './keyword-tag'
import MentometerTag from './mentometer-tag'
import { TAG_TYPES } from '../../opoint/tags'

export default class ArticleTag extends React.PureComponent<any> {
  render() {
    const { tag } = this.props

    switch (tag.type) {
      case TAG_TYPES.KEYWORD:
        // @ts-ignore
        return <KeywordTag {...this.props} />
      case TAG_TYPES.MENTOMETER:
        // @ts-ignore
        return <MentometerTag {...this.props} />
      case TAG_TYPES.ALERT:
        // @ts-ignore
        return <AlertTag {...this.props} />
      default:
        return <span />
    }
  }
}

// @ts-nocheck
import React from 'react'
import classNames from 'classnames'
import Rx from 'rxjs'
import R from 'ramda'
import { translate } from 'react-i18next'

import Checkbox from '../../common/Checkbox'
import Loader from '../../common/Loader'
import Radio from '../../common/Radio'
import ReportsError from '../ReportsError'
import ReportsSortItem from './ReportsSortItem'
import Select from '../../common/Select'
import ToggleCheckbox from '../../common/ToggleCheckbox'
import Translate from '../../../Translate'
import { stripHTML } from '../../../opoint/common/index'
import { groupedArticlesLength, takeGroupedArticles } from '../../../opoint/reports/index'
import ArticlesGroupingLabel from '../../common/ArticlesGroupingLabel'
import type { Article } from '../../../opoint/flow'

const zeroArticlesReportsError = ({ active, i18n }) => <ReportsError active={active} error={i18n.t('Zero articles')} />
const ZeroArticlesReportsError = translate([], { wait: true })(zeroArticlesReportsError)

type Props = {
  groupedArticles: { [key: string]: Array<Article> }
  groups: Object
  context: string
  loading: boolean
  loadArticles: (context: any) => void
  markToDelete: (article: Article) => void
  showDeleted: boolean
  move: (x: number, y: number) => void
  toggleShowDeleted: () => void
  changeFilterMode: (filterMode: string) => void
  changeCompactMode: (value: boolean) => void
  compactMode: boolean
  filterMode: 'highlight' | 'filter'
  activeSort: Object
  sortOptions: Array<Object>
  tagSort: (string: string) => void
  source: {
    type: 'profile' | 'tag'
    id: number
  }
  saving: boolean
  showDeleteToggle: boolean
  editArticle: (article: Article) => void
  previewArticle: (article: Article) => void
  includeAllText: boolean
  includeAllTextToggle: () => void
  isXlsTemplate: boolean
}

type State = {
  filter: string
  articlesShown: number
}

export default class ReportsSort extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      filter: '',
      articlesShown: 15,
    }
    this.input$ = new Rx.Subject()
    this.input$.debounceTime(50).subscribe(this.changeFilter)
  }

  componentDidMount() {
    const { groupedArticles, loadArticles, tagSort } = this.props
    if (!groupedArticles) {
      loadArticles()
    }
    tagSort('sortBy')
  }

  input$: any

  loadMore = () => {
    const { loadArticles, context } = this.props

    loadArticles(context)
  }

  showMore = () => {
    this.setState(
      R.evolve({
        articlesShown: R.add(15),
      }),
    )
  }

  changeFilter = ({ target: { value: filter } }: any) => {
    this.setState({
      filter,
    })
  }

  changeFilterMode = () => {
    const { changeFilterMode, filterMode } = this.props

    changeFilterMode(filterMode === 'filter' ? 'highlight' : 'filter')
  }

  changeCompactMode = (value: number) => {
    const { changeCompactMode } = this.props
    changeCompactMode(!!value)
  }

  handleFilterChange = (event: any) => {
    event.persist()
    this.input$.next(event)
  }

  markToDelete = (document: Article) => {
    const { markToDelete } = this.props
    return () => markToDelete(document)
  }

  editArticle = (document: Article) => {
    const { editArticle } = this.props
    return () => editArticle(document)
  }

  previewArticle = (document: Article) => {
    const { previewArticle } = this.props
    return () => previewArticle(document)
  }

  render() {
    const {
      groupedArticles,
      tagSort,
      activeSort,
      sortOptions,
      showDeleted,
      filterMode,
      compactMode,
      toggleShowDeleted,
      loading,
      groups,
      move,
      context,
      source: { type, id: tagId } = {},
      saving,
      showDeleteToggle,
      includeAllText,
      includeAllTextToggle,
      isXlsTemplate,
    } = this.props
    const { filter, articlesShown } = this.state

    const takenGroupedArticles =
      type === 'tag' && groupedArticles ? takeGroupedArticles(groupedArticles, articlesShown) : groupedArticles

    const filterMatched = (text) => new RegExp(filter, 'i').test(stripHTML(text))

    return (
      <div className={classNames('op-modal-report-body-content', { 'custom-sort-in-progress': saving })}>
        <div className="sort__header">
          {type === 'tag' && (
            <div className="sort__headerItem">
              <Select
                className="op-select"
                value={activeSort && activeSort.value}
                onChange={tagSort}
                options={sortOptions}
              />
            </div>
          )}
          <div className="sort__headerItem">
            <span>
              <Translate i18nString="Find" />:
            </span>
            <input type="text" className="op-input" onChange={this.handleFilterChange} />
          </div>
          <div className="sort__headerItem">
            <Translate i18nString="Filter" />
            <Radio selected={filterMode === 'filter'} onChange={this.changeFilterMode} />
            <Translate i18nString="Highlight" />
            <Radio selected={filterMode === 'highlight'} onChange={this.changeFilterMode} />
          </div>
          <div className="sort__headerItem">
            <Translate i18nString="Compact mode" />
            <ToggleCheckbox input={{ value: +compactMode, onChange: this.changeCompactMode }} />
          </div>
          {showDeleteToggle && (
            <div className="sort__headerItem">
              <span>
                <Translate i18nString="Show deleted" />:
              </span>
              <ToggleCheckbox input={{ value: showDeleted, onChange: toggleShowDeleted }} />
            </div>
          )}
        </div>
        {!isXlsTemplate && (
          <Checkbox
            id="includeAllText"
            className="group__identical-wrapper"
            classNameMod="group__identical"
            value={includeAllText}
            isChecked={includeAllText}
            onChangeHandler={includeAllTextToggle}
          >
            <span style={{ marginLeft: '7px', fontWeight: 'normal' }}>
              <ArticlesGroupingLabel />
            </span>
          </Checkbox>
        )}
        {!takenGroupedArticles && <Loader />}
        {takenGroupedArticles && (
          <div className={classNames('sort__list', { 'sort__list--compact': compactMode })}>
            <ZeroArticlesReportsError active={!Object.values(takenGroupedArticles).length} />
            {Object.values(takenGroupedArticles).map((articles, groupIndex) => (
              <div className="sort__group" key={groupIndex}>
                {Object.keys(takenGroupedArticles)[groupIndex] && Object.keys(takenGroupedArticles).length > 1 && (
                  /* show the name of the category, only when
                   * we have more then one category (the default on) */
                  <h1>{groups[Object.keys(takenGroupedArticles)[groupIndex]].name}</h1>
                )}
                {articles.map(
                  (
                    {
                      newSinceLastSorted,
                      deleted = false,
                      document,
                      document: {
                        id,
                        tags,
                        position,
                        header: { text } = {},
                        first_source: { name: sourceName },
                      },
                    },
                    articleIndex,
                  ) =>
                    ((!showDeleted && !deleted) || showDeleted) &&
                    ((filterMode === 'filter' && (filterMatched(text) || filterMatched(sourceName))) ||
                      filterMode === 'highlight') && (
                      <ReportsSortItem
                        deleted={deleted}
                        document={document}
                        documentPriority={type === 'tag' && tags[tagId] && tags[tagId].weight}
                        editArticleFn={this.editArticle(document)}
                        filter={this.state.filter}
                        group={groups[Object.keys(takenGroupedArticles)[groupIndex]]}
                        highlighted={
                          filterMode === 'highlight' && !!filter && (filterMatched(text) || filterMatched(sourceName))
                        }
                        index={articleIndex}
                        key={`${id}_${position}`}
                        markToDelete={this.markToDelete(document)}
                        move={move}
                        newSinceLastSorted={newSinceLastSorted}
                        previewArticleFn={this.previewArticle(document)}
                      />
                    ),
                )}
              </div>
            ))}
            {loading && <Loader />}
            {!loading && context && (
              <div className="sort__footer">
                <button className="op-button op-button--center green" onClick={this.loadMore}>
                  <Translate i18nString="Show more articles from the report" />
                </button>
              </div>
            )}
            {type === 'tag' && articlesShown < groupedArticlesLength(groupedArticles) && (
              <div className="sort__footer">
                <button className="op-button op-button--center green" onClick={this.showMore}>
                  <Translate i18nString="Show more articles from the report" />
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

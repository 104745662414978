import React from 'react'

import ShareContentInput from '../../common/ShareContentInput'

// This wrapper was added because if this was at connected component
// it caused several weird bugs
// Also, even thought it should be probably refactored,
// take care of css bugs caused by position relative on certain elements of layout
const ShareArticleInputWrapper = (props) => (
  <ShareContentInput
    styles={{ zIndex: 100, position: 'relative' }}
    suggestionWrapperStyle={{ maxHeight: '15em' }}
    {...props}
  />
)

export default ShareArticleInputWrapper

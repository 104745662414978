import React from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'

import { articleId } from '../../opoint/articles/index'

/**
 * This component represents checkbox for selecting specific articles
 * for sharing, tagging, creating reports
 * and other actions available
 */
class SelectArticleCheckbox extends React.PureComponent<any> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  onChange = (article) => () => this.props.onChange(article)

  render() {
    const { article, checked } = this.props
    const { i18n } = this.context

    return (
      <div className="op-checkbox">
        <input
          type="checkbox"
          id={`articles-checkbox-${articleId(article)}`}
          name="check"
          onChange={this.onChange(article)}
          checked={checked}
          aria-label={checked ? i18n.t('Unselect article') : i18n.t('Select article')}
        />
        <label htmlFor={`articles-checkbox-${articleId(article)}`} />
      </div>
    )
  }
}

export default translate([], { wait: true })(SelectArticleCheckbox)

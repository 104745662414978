// @ts-nocheck
import R from 'ramda'
import Rx from 'rxjs'
import { push } from 'react-router-redux'
import { formValueSelector } from 'redux-form'

import buildAction from '../helpers/buildAction'
import { getBaskets } from '../selectors/tagsSelectors'
import { getOpointLocale } from '../selectors/settingsSelectors'
import { logOutOnExpiredToken, serverIsDown } from './configureEpics'
import { defaultStatParams, parseTimeFilterToTimeStamps, searchStatistics } from '../opoint/search'
import { SPECIAL_ASPECTS_IDS, getSelectedTagLikeEntities } from '../opoint/statistics/aspects'
import { getMainSearchLine, getSearchTimePeriod, isProfileSelected, isTagSelected } from '../selectors/searchSelectors'
import {
  getActiveViewId,
  getAspectById,
  getAspectCombo,
  getBaskets as getAspectsBaskets,
  getChangedAspectsCountBy,
  getChangedAspectsType,
  getComputedAspectGroup,
  getCountBy,
  getEditName,
  getSelectedAspects,
  getSubQueries,
  getAspectsRequested,
  getActiveStatView,
} from '../selectors/statisticsSelectors'
import {
  deleteStatisticsView,
  getStatisticViewData,
  getStatisticViews,
  reduceSumName,
  saveStatisticView,
  exportPDF,
} from '../opoint/statistics/index'
import * as ActionTypes from '../constants/actionTypes'
import type { SearchItem } from '../opoint/flow'

const TIMED_REQUESTED_COUNT = 2000

const statisticsExtendTimeRangeEpic = (action$: any, { getState }: any) =>
  Rx.Observable.zip(
    action$.ofType(ActionTypes.SEARCHFILTER_ADDED),
    action$.ofType(ActionTypes.STATISTICS_EXTEND_TIME_RANGE),
  ).switchMap(([{ payload }]) => {
    const { preserveAspects } = payload
    return Rx.Observable.of(buildAction(ActionTypes.FETCH_STATISTICS, { preserveAspects }))
  })

const statisticsSearchEpic = (action$: any, { getState }: any) =>
  Rx.Observable.combineLatest(
    action$.ofType(ActionTypes.FETCH_STATISTICS),
    action$.ofType(ActionTypes.SETTINGS_FETCH_SUCCESS).take(1),
  )
    .delay(50)
    .switchMap(([{ payload }]) => {
      const { params: customParams, preserveAspects } = payload || {}
      const state = getState()
      const customCount = state.statistics.extendedCount
      const customStartDate = state.statistics.extendedStartDate
      const searchline = getMainSearchLine(state)
      const requiredSearchItem: SearchItem = {
        linemode: 'R',
        searchline,
      }
      // search params
      // TODO: move to separate function for easy reuse (same as in searchEpics)
      const baskets = { baskets: getBaskets(state) }
      const timeFilter = getSearchTimePeriod(state)
      const counts = {}
      const aspectsRequested = {}
      let timePeriod = {}

      if (timeFilter) {
        // if time filter is specified, request more articles than usual 500
        counts.requestedarticles = TIMED_REQUESTED_COUNT
        timePeriod = parseTimeFilterToTimeStamps(timeFilter.id)
      }

      const aspectsRequestedFromState = getAspectsRequested(state)

      // preserve previously selected aspects if any
      if (!R.isEmpty(aspectsRequestedFromState)) {
        aspectsRequested.aspect_requested = aspectsRequestedFromState
      } else {
        aspectsRequested.aspect_requested = []
      }

      let subqueries
      if (aspectsRequested.aspect_requested.includes(SPECIAL_ASPECTS_IDS.PROFILE)) {
        subqueries = state.search.profileTagIds.map((id) => ({ id }))
      }

      aspectsRequested.aspect_requested = R.uniq(aspectsRequested.aspect_requested)

      /* data from payload */
      if (customCount !== undefined) {
        counts.requestedarticles = Math.max(counts.requestedarticles, customCount) || customCount
      }
      if (customStartDate !== undefined) {
        // NOTE this causes that time filter tag no longer match fetching data range
        timePeriod.oldest = customStartDate / 1000
      }

      const normalizedCustomParams = R.when(R.propEq('baskets', ''), R.dissoc('baskets'))(customParams || {})

      const finalSearchParams = {
        aspect_info_limit: 1,
        aspect_sep_limit: 0,
        compute_aspects: getComputedAspectGroup(state),
        subqueries,
        ...aspectsRequested,
        ...timePeriod,
        ...counts,
        ...baskets,
        ...normalizedCustomParams,
      }

      return Rx.Observable.fromPromise(
        searchStatistics([requiredSearchItem], finalSearchParams, {}, getOpointLocale(state)),
      )
        .map((response) => buildAction(ActionTypes.FETCH_STATISTICS_SUCCESS, { response, preserveAspects }))
        .catch(logOutOnExpiredToken)
        .catch(serverIsDown)
        .catch(() => Rx.Observable.of(buildAction(ActionTypes.FETCH_STATISTICS_FAILURE)))
    })

const statisticsAspectsResendEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.STATISTICS_ASPECT_RESEND).switchMap(({ payload }) => {
    const { aspect } = payload
    const state = getState()

    const params = {}

    const specialAspectsIds = R.values(SPECIAL_ASPECTS_IDS)

    params.aspect_combo = getAspectCombo(state)

    params.aspect_requested = R.uniq([
      // add previously requested aspects
      ...getAspectsRequested(state),
      // always add profiles, tags, analytics and sentiment
      ...specialAspectsIds,
      // add aspects requested explicitly
      aspect.id,
    ])

    // request to compute this aspect
    // binary OR to also include all preceding aspects
    /* eslint-disable-next-line no-bitwise */
    params.compute_aspects = getComputedAspectGroup(state) | aspect.group

    // add selected profiles to sub-queries
    params.subqueries = getSubQueries(state)
    // and other selected tagLikeEntities (normal, sentiments, analytics, but not profiles)
    // to baskets:
    params.baskets = getAspectsBaskets(state)
    // current aspect is not yet toggled on, so previous params does
    // not contain tagLikeEntities of current aspect, have to add it now:
    switch (aspect.id) {
      case SPECIAL_ASPECTS_IDS.PROFILE:
        params.subqueries = params.subqueries.concat(getSelectedTagLikeEntities(aspect).map(({ id }) => ({ id })))
        break
      case SPECIAL_ASPECTS_IDS.TAG:
      case SPECIAL_ASPECTS_IDS.SENTIMENT:
      case SPECIAL_ASPECTS_IDS.ANALYSIS:
        params.baskets = R.uniq([
          ...(params.baskets ? params.baskets.split(',') : []).map((id) => +id),
          ...getSelectedTagLikeEntities(aspect).map(({ id }) => +id),
        ]).join(',')
        break
      default:
        break
    }

    return Rx.Observable.of(
      buildAction(ActionTypes.STATISTICS_ASPECT_TOGGLE, payload),
      buildAction(ActionTypes.FETCH_STATISTICS, { params, preserveAspects: true }),
    )
  })

const statisticsAspectToggleEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.STATISTICS_ASPECT_TRY_TOGGLE).switchMap(({ payload }) => {
    const { aspectId, selected } = payload
    const aspect = getAspectById(aspectId)(getState())

    switch (true) {
      case aspect.comp_part === -1: // need to compute aspect data
      case Object.values(aspect.aspectpart)[0] && Object.values(aspect.aspectpart)[0].names[0] === 'No profile':
        payload.aspect = aspect
        return Rx.Observable.of(buildAction(ActionTypes.STATISTICS_ASPECT_RESEND, payload))

      case aspect.selected: // aspect turning off
      case aspect.comp_part > -1: // turning on but no need to compute additional data
        return Rx.Observable.of(buildAction(ActionTypes.STATISTICS_ASPECT_TOGGLE, payload))

      case !aspect:
      case aspect.selected === selected: // no change
      default:
        // this should not ever happen
        return Rx.Observable.of()
    }
  })

/*
 * Combines multiple action to new action so tags (including special "tags"
 * like profiles analytics etc.) can all be stored to aspects in statistics
 * in one step after all of them are available
 */
const statisticsTagsEpic = (action$: any, { getState }: any) =>
  Rx.Observable.combineLatest(
    action$.ofType(ActionTypes.FETCH_STATISTICS_SUCCESS),
    action$.ofType(ActionTypes.TAGS_FETCH_SUCCESS).take(1),
    action$.ofType(ActionTypes.ANALYSIS_TAGS_FETCH_SUCCESS).take(1),
    action$.ofType(ActionTypes.PROFILES_FETCH_SUCCESS).take(1),
  ).switchMap(([_, { payload: allTags }, { payload: analysis }, { payload: profiles }]) => {
    const state = getState()
    return Rx.Observable.of(
      buildAction(ActionTypes.STATISTICS_TAG_LISTS, {
        allTags: R.map((t) => R.assoc('selected', isTagSelected(t.id)(state))(t))(allTags),
        analysis: R.map((t) => R.assoc('selected', isTagSelected(t.id)(state))(t))(analysis),
        profiles: R.map((t) => R.assoc('selected', isProfileSelected(t.id)(state))(t))(profiles),
      }),
    )
  })

const fetchStatisticViewsEpic = (action$: any) =>
  action$
    .ofType(ActionTypes.LOG_IN_SUCCESS)
    .switchMap(() =>
      Rx.Observable.from(getStatisticViews()).map((data) =>
        buildAction(ActionTypes.STATISTIC_VIEWS_FETCH_SUCCESS, data),
      ),
    )

// because sometimes we want to show active statistic view and not fetch new statistics
/* deprecated for some time @dmytro */
/* const showStatisticsEpic = (action$: any, { getState }: any) =>
    action$.ofType(ActionTypes.STATISTICS_SHOW)
      .switchMap(() => {
        const viewId = getState().statistics.activeStatView
        return viewId !== null
          ? Rx.Observable.of(buildAction(ActionTypes.STATISTICS_VIEWS_OPEN, { id: viewId }))
          : Rx.Observable.of(buildAction(ActionTypes.FETCH_STATISTICS))
      }) */

const fetchStatViewsDetailsEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.STATISTICS_VIEWS_OPEN).switchMap(({ payload: { id } }) => {
    const state = getState()

    const searchLine$ = Rx.Observable.fromPromise(getStatisticViewData(id)).map((data) => data)

    return searchLine$.switchMap(({ dashboard: { search, chartCollection } }) => {
      const baskets = {
        baskets: getBaskets(state),
      }
      const searchline = {
        filters: search.filters.filter((filter) => filter.type !== 'timePeriod'),
        searchterm: search.expression,
      }
      const requiredSearchItem: SearchItem = {
        searchline,
        linemode: 'R',
      }

      const params = R.merge(search.params, {
        ...baskets,
        subqueries: R.compose(
          R.map((filter) => ({ id: +filter.id })),
          R.filter(R.propEq('type', 'profile')),
        )(search.filters),
      })
      const parsedFilters = search.filters
      const expression = search.expression || ''

      return Rx.Observable.fromPromise(
        searchStatistics(
          [requiredSearchItem],
          {
            ...params,
            compute_aspects: getComputedAspectGroup(state),
            aspect_info_limit: 1,
            aspect_sep_limit: 0,
            aspect_requested: getAspectsRequested(state),
          },
          {},
          getOpointLocale(state),
        ),
      )
        .switchMap((response) => {
          let changedAspectsType
          let changedAspectsCountBy
          const correctAspectsResponse = R.evolve({
            searchresult: {
              aspectset: {
                aspect: R.map((aspect) => {
                  let isSelected = false
                  chartCollection.forEach((chart) => {
                    if (chart.id === aspect.id) {
                      isSelected = true
                    }
                    if (chart.type !== 'default') {
                      changedAspectsType = {
                        ...changedAspectsType,
                        [chart.id]: chart.type,
                      }
                    }
                    if (chart.reduceSum && chart.reduceSum.key !== 'default') {
                      changedAspectsCountBy = {
                        ...changedAspectsCountBy,
                        [chart.name]: chart.reduceSum.name,
                      }
                    } else if (chart.reduceSum && chart.reduceSum.name !== 'n. of articles') {
                      changedAspectsCountBy = {
                        ...changedAspectsCountBy,
                        [chart.name]: chart.reduceSum.name,
                      }
                    }
                  })
                  return isSelected
                    ? {
                        ...aspect,
                        selected: true,
                      }
                    : {
                        ...aspect,
                        selected: false,
                      }
                }),
              },
            },
          })(response)

          return Rx.Observable.of(
            buildAction(ActionTypes.ROUTER_SEARCH_DATA_CHANGE, { parsedFilters, expression }),
            buildAction(ActionTypes.FETCH_STATISTICS_SUCCESS, {
              response: correctAspectsResponse,
              isStatView: true,
              changedAspectsType,
              changedAspectsCountBy,
            }),
          )
        })
        .catch(logOutOnExpiredToken)
        .catch(serverIsDown)
        .catch(() => Rx.Observable.of(buildAction(ActionTypes.FETCH_STATISTICS_FAILURE)))
    })
  })

export const deleteStatViewEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.STATISTICS_VIEW_DELETE).switchMap(() => {
    const deletedView = getActiveViewId(getState())

    const deleteView$ = Rx.Observable.fromPromise(deleteStatisticsView(deletedView))
      .map(() => buildAction(ActionTypes.STATISTICS_VIEW_DELETE_SUCCESS))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.STATISTICS_VIEW_DELETE_FAILURE)))
    const fetchViews$ = Rx.Observable.defer(getStatisticViews).switchMap((data) =>
      Rx.Observable.of(
        buildAction(ActionTypes.STATISTIC_VIEWS_FETCH_SUCCESS, data),
        data[0]
          ? push(`/statistics/search?filters=chart:${data[0].id}`)
          : buildAction(ActionTypes.GO_TO_DEFAULT_PROFILE),
      ),
    )

    return deleteView$.switchMap((deleteAlertAction) =>
      Rx.Observable.concat(Rx.Observable.of(deleteAlertAction), fetchViews$),
    )
  })

export const saveStatViewEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.STATISTICS_VIEW_SAVE).switchMap(() => {
    const state = getState()
    const id = getActiveViewId(state)
    const name = getEditName(state)
    const color = 'blue'
    const selectedAspects = getSelectedAspects(state)
    const countBy = getCountBy(state)
    const changedAspectsType = getChangedAspectsType(state)
    const changedAspectsCountBy = getChangedAspectsCountBy(state)
    const reduceSumDefault = {
      key: 'default',
      name: reduceSumName[countBy],
    }
    const timeFilter = getSearchTimePeriod(state)
    const counts = {}
    let timePeriod = {}

    if (timeFilter) {
      // if time filter is specified, request more articles than usual 30
      counts.requestedarticles = TIMED_REQUESTED_COUNT
      timePeriod = parseTimeFilterToTimeStamps(timeFilter.id)
    }

    const params = {
      ...defaultStatParams,
      acceptedcacheage: -1,
      aspect_info_limit: 1,
      aspect_sep_limit: 0,
      compute_aspects: getComputedAspectGroup(state),
      aspect_requested: getAspectsRequested(state),
      baskets: getBaskets(state),
      watch_id: 0,
      ...timePeriod,
      ...counts,
    }
    const searchline = getMainSearchLine(state)

    const correctPayload = {
      color,
      name,
      dashboard: {
        chartCollection: R.map((aspect) => {
          const aspectData = R.pick(['id', 'name'], aspect)
          const customCountBy = changedAspectsCountBy[aspect.name]
          const reduceSum =
            customCountBy && customCountBy !== 'count'
              ? {
                  key: reduceSumName[customCountBy],
                  name: reduceSumName[customCountBy],
                }
              : reduceSumDefault
          const chartData = {
            reduceSum,
            ...aspectData,
            isArrayAspect: false,
            type: changedAspectsType[aspect.name] || 'default',
            filters: null,
          }
          return chartData
        }, selectedAspects),
        chartLanguage: getOpointLocale(state),
        search: {
          expression: searchline.searchterm,
          filters: searchline.filters,
          params,
        },
      },
    }

    const saveView$ = Rx.Observable.fromPromise(saveStatisticView(correctPayload, id))

      .switchMap(({ id }) =>
        Rx.Observable.concat(
          Rx.Observable.of(buildAction(ActionTypes.STATISTICS_VIEW_SAVE_SUCCESS)),
          Rx.Observable.of(push(`/statistics/search?filters=chart:${id}`)),
        ),
      )
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.STATISTICS_VIEW_SAVE_FAILURE)))

    return saveView$.switchMap((saveViewAction) =>
      Rx.Observable.concat(
        Rx.Observable.of(saveViewAction),
        Rx.Observable.of(buildAction(ActionTypes.FETCH_STATISTICS, { preserveAspects: true })),
      ),
    )
  })

const exportStatisticsAsPDF = (action$: any, { getState }: any) =>
  action$
    .ofType(ActionTypes.STATISTICS_VIEW_EXPORT_PDF)
    .switchMap(({ payload: { SVGs, metaString } }) => {
      const state = getState()

      const exportAsPdfModalFormVelues = formValueSelector('statisticsExportPdf')(
        state,
        'description',
        'title',
        'fileName',
      )

      const statsView = getActiveStatView(state)
      const activeStatisticsViewName = statsView && statsView.name

      return Rx.Observable.fromPromise(
        exportPDF({
          activeStatisticsViewName,
          metaString,
          SVGs,
          ...exportAsPdfModalFormVelues,
        }),
      )
    })
    .map((activeStatisticsViewName) =>
      buildAction(ActionTypes.STATISTICS_VIEW_EXPORT_PDF_SUCCESS, { activeStatisticsViewName }),
    )
    .catch(logOutOnExpiredToken)
    .catch(serverIsDown)
    .catch(() => Rx.Observable.of(buildAction(ActionTypes.STATISTICS_VIEW_EXPORT_PDF_FAILURE)))

export default [
  // showStatisticsEpic,
  deleteStatViewEpic,
  exportStatisticsAsPDF,
  fetchStatisticViewsEpic,
  fetchStatViewsDetailsEpic,
  saveStatViewEpic,
  statisticsAspectsResendEpic,
  statisticsAspectToggleEpic,
  statisticsExtendTimeRangeEpic,
  statisticsSearchEpic,
  statisticsTagsEpic,
]

import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { translate } from 'react-i18next'

import buildActionCreators from '../../helpers/buildActionCreators'
import { searchRouteHelper } from '../../helpers/searchRouteHelper'
import { WATCH_INDEX_LIMIT } from '../../opoint/profiles/index'
import * as ActionTypes from '../../constants/actionTypes'

class ProfileListItemComponent extends React.PureComponent<any> {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  }

  handleLabelClick = () => {
    const { profile, searchContext, onLabelClick, resetWatchQuery, watchQuery } = this.props
    const timestamp = Date.now()
    // TODO profile score = 0
    onLabelClick(searchContext, profile, timestamp)
    // If profile has watchQuery, reset it
    watchQuery && resetWatchQuery({ watchId: watchQuery.id })
  }

  handleCheckboxClick = () => {
    const { profile, searchContext, onCheckboxClick } = this.props
    const { filters } = this.context.router.location.query
    onCheckboxClick(searchContext, filters, profile)
  }

  handleSettingsIconClick = () => {
    const { profile, searchContext, onSettingsIconClick } = this.props
    onSettingsIconClick(searchContext, profile)
  }

  render() {
    const { profile, isTree, watchQuery, checked } = this.props
    return (
      <div className="op-content-left-profiles-profile-list-item_wrapper">
        <div className={classNames('op-checkbox', { 'mod-profile-has-children': isTree && profile.children.length })}>
          <input type="checkbox" id={`profile-list-checkbox-${profile.id}`} checked={checked} readOnly />
          <label htmlFor={`profile-list-checkbox-${profile.id}`} onClick={this.handleCheckboxClick} />
        </div>
        <span className="op-content-left-profiles-profile-name" onClick={this.handleLabelClick}>
          {profile.name}
        </span>
        {watchQuery && !!watchQuery.cnt && (
          <span className="op-content-left-watch-count">
            {watchQuery.cnt > WATCH_INDEX_LIMIT ? `${WATCH_INDEX_LIMIT}+` : watchQuery.cnt}
          </span>
        )}
      </div>
    )
  }
}

const ProfileListItemTranslate = translate([], { wait: true })(ProfileListItemComponent)
const ProfileListItem = connect(null, {
  onSettingsIconClick: (searchRoute, profile) => push(`/${searchRoute}/edit/?filters=profile:${profile.id}`),
  onLabelClick: (searchRoute, profile, timestamp) =>
    push(`/${searchRoute}/?filters=profile:${profile.id}&timestamp=${timestamp}`),
  onCheckboxClick: (searchRoute, filters, profile) =>
    push(`/${searchRoute}/?filters=${searchRouteHelper(filters, `profile:${profile.id}`)}`),
  ...buildActionCreators({
    // We need this so we don't have wrong new profiles number when visiting new profile
    resetWatchQuery: ActionTypes.RESET_WATCH_INDEX_FOR_PROFILE,
  }),
})(ProfileListItemTranslate)

export default ProfileListItem

export function tagStyle(tag, article, checked = [], activeTag = undefined) {
  // normalize tag id (for trash tags working properly)
  const { id } = tag
  // If not operating on a specific article, style according to all
  // currently checked articles
  let tagWeight = 0
  let tagUsed = false
  // Count of currently checked articles that the tag is applied to
  let tagAppliedTo = 0

  if (article !== undefined) {
    // if article has children (is AlertTag with children)
    // we mark it enabled if at least one of a tag is enabled
    if (tag.children && tag.children.length) {
      tagUsed = tag.children.find((innerTag) => article.tags[innerTag.id]) !== undefined || article.tags[id]
    } else if (article.tags[id]) {
      tagWeight = article.tags[id].weight
      tagUsed = true
    } else {
      tagWeight = 0
      tagUsed = false
    }
  } else {
    checked.forEach((checkedArticle) => {
      if (checkedArticle.tags[id]) {
        tagAppliedTo += 1
      }
    })
    if (tagAppliedTo === checked.length) {
      tagWeight = 1
    }
  }

  const style = {
    'mod-weight-negative': tagWeight < 0,
    'mod-weight-positive': tagWeight > 0,
    'mod-weight-neutral': tagWeight === 0,
    'mod-active-tag': tag.id === (activeTag && activeTag.id),
    applied: tagAppliedTo > 0,
    used: tagUsed,
    disabled: !tagUsed,
  }
  // we filter those classnames where a condition is met
  const styleList = Object.keys(style).filter((key) => style[key])
  if (tag.color) {
    styleList.push(tag.color)
  }

  return styleList.join(' ')
}

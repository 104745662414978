import React from 'react'

import { tagStyle } from './common'
import type { Article, Tag } from '../../opoint/flow'

export const ArticleListingTag = ({ article, tag }: { article: Article; tag: Tag }) => {
  if (tag.color !== null) {
    // Only colored keyword tags are shown in listing mode
    return (
      <li className={`op-content-right-section-content-left-tags-list-item op-tag-small ${tagStyle(tag, article)}`}>
        <i className="op-icon-tag" title={tag.name} />
      </li>
    )
  }
  // fallback
  return null
}

// @ts-nocheck
import React from 'react'
import PropTypes from 'prop-types'
import className from 'classnames'

import ArticleBody from './common/ArticleBody'
import ArticleFooter from './common/ArticleFooter'
import ArticleHeaderText from './common/ArticleHeaderText'
import ArticleSourceLine from './common/ArticleSourceLine'
import Translate from '../../Translate'
import { shouldHaveStickyTags, shouldHaveStickyLeftSide } from '../../opoint/articles/index'
import { translatedFromLanguages } from '../../opoint/common/constants'
import type { Article } from '../../opoint/flow'
import { ArticleMetadataType } from '../../opoint/flow'
import ArticleMetadata from './common/ArticleMetadata'
import ArticleSideButtons from './common/ArticleSideButtons'
import { CONTENT_HEIGHT_THRESHOLD } from '../../constants'

type shouldDisplayFooterProps = {
  editMode: boolean
  originalArticle: Article
  shouldHideTags: boolean
}

// it is exported so we can test it
export const shouldDisplayFooter = ({ editMode, originalArticle, shouldHideTags }: shouldDisplayFooterProps) => {
  // If we are in edit mode, don't show footer
  if (!editMode) {
    // original article is only present if article has identical articles
    // show footer in case there is identical articles
    if (originalArticle) {
      return true
      // If tags should be hidden and article doesn't have identical articles, hide them
    }
    if (shouldHideTags) {
      return false
    }
    return true
  }
  return false
}

type Props = {
  active: boolean
  activeArticle?: Article
  article: any
  articleSource: string
  checked: boolean
  editable: boolean
  editMode: boolean
  editTooltip: React.ReactElement
  identical: number
  locale: string
  onCheckedToggle: (article: Article) => void
  onEditArticleModalOpen: ({ article: Article }) => void
  originalArticle: Article
  setActiveIdenticalArticle: () => void
  shareArticle: (article: Article) => void
  shareTooltip: React.ReactElement
  shouldHideTags: boolean
  tagsFn: (article: Article) => void
  visibleTrashTagsFn: (article: Article) => void
  allowExpanded: boolean
  articleMetadata: Array<ArticleMetadataType>
}

type State = {
  expanded: boolean
  isReadMoreDisabled: boolean
  isSticky: boolean
  isLeftSideSticky: boolean
  isLeftSideAtTheBottom: boolean
}

class ArticleReactComponent extends React.PureComponent<Props, State> {
  static defaultProps = {
    editable: false,
    allowExpanded: true,
  }

  static contextTypes = {
    i18n: PropTypes.object,
  }

  constructor(props: Props) {
    super(props)

    // I think ? : is more readable in this case than !props.allowExpanded
    // If we allow expanded mode, we start with non-expanded mode (false)
    // If we don't allow expanded mode, we want to show the whole article
    // noinspection RedundantConditionalExpressionJS
    this.state = {
      expanded: !props.allowExpanded,
      isReadMoreDisabled: false,
      isSticky: false,
      isLeftSideSticky: false,
      isLeftSideAtTheBottom: false,
    }

    this.articlePartsRefs = {}
  }

  componentDidMount() {
    const shownLength = this.articlePartsRefs.content.getBoundingClientRect().height
    const totalLength = this.getExpandedArticleHeight()

    this.isAboveThreshold = shownLength > CONTENT_HEIGHT_THRESHOLD

    if (totalLength <= shownLength) {
      this.setState({ isReadMoreDisabled: true }) /* eslint-disable-line */
    }

    if (this.state.expanded) {
      this.expand()
    }
  }

  onCheckedToggleHandler = () => {
    const { onCheckedToggle, article } = this.props
    onCheckedToggle(article)
  }

  onEditClickHandler = () => {
    const { onEditArticleModalOpen, article } = this.props
    onEditArticleModalOpen({ article })
  }

  getExpandedArticleHeight = () => {
    const { headline, sourceLine, contentText } = this.articlePartsRefs
    return [headline, sourceLine, contentText].map((x) => x.getBoundingClientRect().height).reduce((a, b) => a + b)
  }

  expand = () => {
    /* Expands an article */
    // 1.1 ratio, because the whole 'Read more' system is a bit fragile, this needs to be properly refactored
    // probably with some new design in a future.
    this.articlePartsRefs.content.style['max-height'] = `${this.getExpandedArticleHeight() + 200}px`
    this.setState({ expanded: true })
  }

  collapse = () => {
    // this.props.deactivateStickyTags(this.props.articleId);
    this.articlePartsRefs.content.style['max-height'] = null
    this.setState({ expanded: false })
  }

  toggleExpanded = () => {
    if (this.state.expanded) {
      this.collapse()
    } else {
      this.expand()
    }
  }

  articlePartsRefs: Object

  shareArticleHandler = () => {
    const { shareArticle, article } = this.props
    shareArticle(article)
  }

  checkSticky = () => {
    const isSticky = shouldHaveStickyTags(
      this.articlePartsRefs.content,
      this.articlePartsRefs.contentText,
      this.articlePartsRefs.footer,
    )
    this.setState({ isSticky })

    return isSticky
  }

  checkStickySidePanel = () => {
    if (this.articlePartsRefs.sidePanel && this.isAboveThreshold) {
      const { isSticky, isAtTheBottom } = shouldHaveStickyLeftSide(
        this.articlePartsRefs.content,
        this.articlePartsRefs.sidePanel,
      )
      this.setState({ isLeftSideSticky: isSticky, isLeftSideAtTheBottom: isAtTheBottom })
    }
  }

  render() {
    const {
      article,
      editMode,
      editTooltip,
      identical,
      locale,
      originalArticle,
      setActiveIdenticalArticle,
      shareTooltip,
      shouldHideTags,
      tagsFn,
      articleMetadata,
      visibleTrashTagsFn,
    } = this.props
    const { i18n } = this.context
    const { isReadMoreDisabled, isSticky, expanded, isLeftSideAtTheBottom, isLeftSideSticky } = this.state

    const stateClass = {
      'is-read-more-disabled': isReadMoreDisabled,
    }

    const stickyClass = {
      'sticky-tags': isSticky,
    }

    const readMoreClass = {
      'is-expanded': editMode || expanded,
      'is-collapsed': !editMode && !expanded,
    }

    const displayFooter = shouldDisplayFooter({ editMode, originalArticle, shouldHideTags })

    return (
      <article
        style={displayFooter ? {} : { paddingBottom: '30px' }}
        className={className(
          'op-content-article-listing-article',
          stateClass,
          isReadMoreDisabled ? {} : readMoreClass,
          stickyClass,
        )}
      >
        {article.translated && (
          <div className="op-content-article-listing-pre-headline-info">
            {article.translated_count ? (
              <Translate
                i18nString="First {{numberOfCharacters}} characters were machine-translated from {{lang}}"
                context={{
                  lang: i18n.t(translatedFromLanguages[article.orig_language.text]),
                  numberOfCharacters: article.translated_count,
                }}
              />
            ) : (
              <Translate
                i18nString="Machine-translated from {{lang}}"
                context={{ lang: i18n.t(translatedFromLanguages[article.orig_language.text]) }}
              />
            )}
          </div>
        )}
        <ArticleMetadata article={article} articleMetadata={articleMetadata} locale={locale} />
        <div
          className="op-content-article-listing-article-content-wrapper"
          ref={(ref) => {
            this.articlePartsRefs.content = ref
          }}
        >
          {!editMode && (
            <ArticleSideButtons
              isSticky={isLeftSideSticky}
              isAtTheBottom={isLeftSideAtTheBottom}
              getRef={(ref) => {
                this.articlePartsRefs.sidePanel = ref
              }}
              shareButton={{
                tooltip: shareTooltip,
                onClick: this.shareArticleHandler,
              }}
              editButton={{
                tooltip: editTooltip,
                onClick: this.onEditClickHandler,
              }}
            />
          )}
          <div
            className={className(
              'op-content-article-listing-article-content',
              !editMode ? 'has-left-side' : '',
              isLeftSideSticky ? 'left-side-sticky' : '',
            )}
          >
            <div
              ref={(ref) => {
                this.articlePartsRefs.headline = ref
              }}
            >
              <ArticleHeaderText {...this.props} />
            </div>

            <div
              className="op-content-article-listing-article-content-source-line"
              ref={(ref) => {
                this.articlePartsRefs.sourceLine = ref
              }}
            >
              <ArticleSourceLine
                article={article}
                dateFormat="LONG"
                locale={locale}
                relative={false}
                setActiveArticle={setActiveIdenticalArticle}
                showAuthor
                showBadge
                sourceIsLink
              />
            </div>

            <div
              ref={(ref) => {
                this.articlePartsRefs.contentText = ref
              }}
            >
              <ArticleBody {...this.props} article={article || originalArticle} />
            </div>

            {!editMode && this.props.allowExpanded && (
              <div className="op-content-article-listing-article-content-readmore" onClick={this.toggleExpanded}>
                <div className="op-content-article-listing-article-content-readmore-more">
                  <Translate i18nString="Read more" />
                </div>
                <div className="op-content-article-listing-article-content-readmore-less">
                  <Translate i18nString="Show less" />
                </div>
              </div>
            )}
          </div>
        </div>

        {displayFooter && (
          <div
            className="op-content-article-listing-article-footer"
            ref={(ref) => {
              this.articlePartsRefs.footer = ref
            }}
            style={{
              width: `${
                this.articlePartsRefs.content && this.articlePartsRefs.content.getBoundingClientRect().width
              }px`,
            }}
          >
            <ArticleFooter
              article={article}
              identical={identical}
              locale={locale}
              originalArticle={originalArticle}
              shouldHideTags={shouldHideTags}
              tagsFn={tagsFn}
              visibleTrashTagsFn={visibleTrashTagsFn}
            />
          </div>
        )}
      </article>
    )
  }
}

export default ArticleReactComponent

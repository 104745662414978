import * as React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import R from 'ramda'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { translate } from 'react-i18next'

import buildActionCreators from '../../helpers/buildActionCreators'
import FiltersDropDownButton from '../search/FiltersDropDownButton'
import Loader from '../common/Loader'
import ProfileEditorLine from './ProfileEditorLine'
import ProfileHistory from './ProfileHistory'
import ProfileEditorFilters from './ProfileEditorFilters'
import ProfileEditorTips from './ProfileEditorTips'
import ProfileParentSelect from './ProfileParentSelect'
import ProfileEditorWrapper from './ProfileEditorWrapper'
import Translate from '../../Translate'
import { HISTORY_TYPE } from '../../opoint/profiles/index'
import { profileIdFromLocationQuery } from '../../opoint/search/url'
import {
  getAllHistoryResultsLength,
  getOpenProfile,
  getProfileEditorDebug,
  getProfileHistory,
  getProfiles,
  getShowMoreHistorySegment,
  isDeletedProfilesExpanded,
  isProfileEditorFiltersPanelOpen,
  isProfileHistoryExpanded,
} from '../../selectors/profilesSelectors'
import * as ActionTypes from '../../constants/actionTypes'
import { getUISetting } from '../../selectors/settingsSelectors'
import type { DebugLine } from '../../opoint/flow'
import ToggleCheckbox from '../common/ToggleCheckbox'
import ProfileDeleteConfirmationModal from './ProfileDeleteConfirmationModal'

const nameField = ({ i18n }) => (
  <Field name="profileName" component="input" type="text" placeholder={i18n.t('Profile name')} className="op-input" />
)
const NameField = translate([], { wait: true })(nameField)

const storedSearch = ({ i18n }) => (
  <Field
    name="storedSearch"
    component={ToggleCheckbox}
    type="text"
    placeholder={i18n.t('Store search')}
    className="op-input"
  />
)
const StoredSearch = translate([], { wait: true })(storedSearch)

const DebugMessage = ({
  id,
  fixProfileSearchterm,
  line,
}: {
  id: number
  fixProfileSearchterm: ({ id: number, searchterm: string }) => void
  line: DebugLine | void
}): React.ReactElement => {
  const fixSearchterm = () => {
    // @ts-ignore
    fixProfileSearchterm({ id, searchterm: line.query })
  }

  return (
    <div>
      {!!line && (
        <span className="op-search-input_debug-message">
          {line.query ? (
            <div onClick={fixSearchterm}>
              <Translate
                i18nString="M360 understood the above search as {{query}}. Click here to use that or edit the search string to fix the error."
                context={{
                  query: `"${line.query}"`,
                }}
                isDangerous
              />
            </div>
          ) : (
            line.state !== 1 && <Translate i18nString="M360 could not resolve this query." />
          )}
        </span>
      )}
    </div>
  )
}

class ProfileEditor extends React.PureComponent<any> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  componentDidMount() {
    const {
      fetchProfileHistory,
      fetchDeletedProfilesHistory,
      profileId,
      isDeletedProfilesExpanded /* eslint-disable-line no-shadow */,
      isProfileHistoryExpanded /* eslint-disable-line no-shadow */,
      autoexpandSearchlines,
    } = this.props
    isProfileHistoryExpanded && fetchProfileHistory({ profileId })
    isDeletedProfilesExpanded && fetchDeletedProfilesHistory()

    // Do the computation of necessary data only if it is relevant
    if (autoexpandSearchlines) {
      this.addProfileEditorLines()
    }
  }

  componentDidUpdate(prevProps) {
    // We want so show lines only if they are not present already, due to lot of computation we
    // do it only in relevant cases
    if (this.props.autoexpandSearchlines && !prevProps.autoexpandSearchlines) {
      this.addProfileEditorLines()
    }

    if (this.props.profile.id !== prevProps.profile.id) {
      if (this.props.filtersOpen) {
        this.props.toggleFiltersPanel()
      }
      this.props.clearDebug()
    }
  }

  componentWillUnmount() {
    this.props.clearDebug()
  }

  addProfileEditorLines = () => {
    const {
      profile,
      debug: { lines },
    } = this.props

    const enumerate = R.addIndex(R.map)((x, y) => [y, x])
    const profilesWithDebug = lines
      ? profile.items.map((item, index) => ({ line: lines[index], ...item }))
      : profile.items

    const searchLines =
      // @ts-ignore
      profilesWithDebug && R.groupBy(R.compose(R.prop('linemode'), R.nth(1)), enumerate(profilesWithDebug))

    const hasNoSechlines = R.all(R.isEmpty, [
      (profilesWithDebug && searchLines.E) || [],
      (profilesWithDebug && searchLines.O) || [],
    ])

    if (hasNoSechlines) {
      this.addProfileEditorLineOE()
    }
  }

  addProfileEditorLineHandler = (key) => () => this.props.addProfileEditorLine(key)

  addProfileEditorLineOE = () => {
    this.props.addProfileEditorLine('O')
    this.props.addProfileEditorLine('O')
    this.props.addProfileEditorLine('E')
    this.props.addProfileEditorLine('E')
  }

  expandProfileHistory = () => {
    const { fetchProfileHistory, expandProfileHistory, profileId } = this.props
    fetchProfileHistory({ profileId })
    expandProfileHistory()
  }

  expandDeletedProfiles = () => {
    const { fetchDeletedProfilesHistory, expandDeletedProfiles } = this.props
    fetchDeletedProfilesHistory()
    expandDeletedProfiles()
  }

  render() {
    const {
      profile,
      profiles,
      profileId,
      toggleFiltersPanel,
      filtersOpen,
      debug: { lines },
      fixProfileSearchterm,
      isProfileHistoryExpanded /* eslint-disable-line no-shadow */,
      isDeletedProfilesExpanded /* eslint-disable-line no-shadow */,
      shrinkProfileHistory,
      profileHistory,
      setOldProfileVersion,
      showMoreProfileHistory,
      showMoreHistorySegment,
      allHistoryResultsLength,
    } = this.props
    const { i18n } = this.context

    const enumerate = R.addIndex(R.map)((x, y) => [y, x])
    const profilesWithDebug = lines
      ? profile.items.map((item, index) => ({ line: lines[index], ...item }))
      : profile.items

    const searchLines =
      // @ts-ignore
      profilesWithDebug && R.groupBy(R.compose(R.prop('linemode'), R.nth(1)), enumerate(profilesWithDebug))

    const hideOESearchLines = R.all(R.isEmpty, [
      (profilesWithDebug && searchLines.E) || [],
      (profilesWithDebug && searchLines.O) || [],
    ])

    const HistoryWrapper = (type: string): React.Component<any, any> | React.ReactElement => {
      if (profileHistory.results.length === 0) {
        // @ts-ignore
        if (HISTORY_TYPE === 'deletedProfiles') {
          return (
            <div className="col-md-12">
              <Translate i18nString="No deleted profiles" />
            </div>
          )
        }
        return (
          <div className="col-md-12">
            <Translate i18nString="No profile history" />
          </div>
        )
      }
      return (
        <ProfileHistory
          allHistoryResultsLength={allHistoryResultsLength}
          historyType={type}
          profileHistory={profileHistory}
          setOldProfileVersion={setOldProfileVersion}
          showMoreHistorySegment={showMoreHistorySegment}
          showMoreProfileHistory={showMoreProfileHistory}
        />
      )
    }

    return (
      <div id="op-profile-editor">
        <div className="row article-top-content">
          <div className="col-md-12">
            <form name="profileForm">
              <div className="col-md-12 op-content-article-listing-profile-editor">
                <div className="row op-content-article-listing-profile-editor-section">
                  <div className="col-md-6 mod-left">
                    <div className="row">
                      <div className="op-form-group">
                        <label>
                          <Translate i18nString="Profile name" />
                        </label>
                        <div>
                          <NameField />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mod-right">
                    <div className="row">
                      <div>
                        <ProfileParentSelect profiles={profiles} profileId={profile.id} />
                      </div>
                      <label>
                        <Translate i18nString="Parent" />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row op-content-article-listing-profile-editor-section">
                  <div className="col-md-6 util_col-vertical-center">
                    <div className="row">
                      <h4 onClick={this.addProfileEditorLineHandler('R')}>
                        <Translate i18nString="Find articles matching the search lines" />
                        <i>
                          <span className="op-icon-circle-plus" />
                        </i>
                      </h4>
                    </div>
                  </div>
                  <div className="col-md-6 util_col-vertical-center">
                    <div className="row">
                      <ProfileEditorTips />
                    </div>
                  </div>
                  <div>
                    <div>
                      <ul className="op-content-article-listing-profile-editor-section-rules">
                        {profilesWithDebug &&
                          searchLines.R &&
                          searchLines.R.map(([key, { line, searchline }]) => (
                            <li key={key} className="op-content-article-listing-profile-editor-section-rules-rule">
                              <div className="op-search-input_wrapper op-search-input_wrapper--profile-editor">
                                <ProfileEditorLine
                                  id={key}
                                  placeholder={i18n.t('Required search')}
                                  searchline={searchline}
                                  alwaysShowClearAll={key !== 0}
                                />
                                <DebugMessage id={key} line={line} fixProfileSearchterm={fixProfileSearchterm} />
                              </div>
                              {key === 0 && <FiltersDropDownButton handleFilterToggle={toggleFiltersPanel} />}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
                {filtersOpen && (
                  <div className="row op-content-article-listing-profile-editor-section">
                    <div className="op-content-article-listing-profile-editor-section-filters_wrapper">
                      <div className="op-content-article-listing-profile-editor-section-filters">
                        <ProfileEditorFilters
                          sliderSettings={{
                            responsive: [
                              { breakpoint: 1335, settings: { slidesToShow: 3, slidesToScroll: 2 } },
                              { breakpoint: 1815, settings: { slidesToShow: 4 } },
                              { breakpoint: 10000, settings: { slidesToShow: 9 } },
                            ],
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="row op-content-article-listing-profile-editor-section">
                  {hideOESearchLines ? (
                    <div className="col-md-6">
                      <div className="row">
                        <h4 onClick={this.addProfileEditorLineOE}>
                          <Translate i18nString="Use more search lines to tune result" />
                        </h4>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="col-md-6">
                        <div className="row mod-border-right">
                          <h4 onClick={this.addProfileEditorLineHandler('O')}>
                            <Translate i18nString="and at least one of search lines" />
                            <i>
                              <span className="op-icon-circle-plus" />
                            </i>
                          </h4>
                          <div>
                            <ul className="op-content-article-listing-profile-editor-section-rules">
                              {profilesWithDebug &&
                                searchLines.O &&
                                searchLines.O.map(([key, { line, searchline }]) => (
                                  <li
                                    key={key}
                                    className="op-content-article-listing-profile-editor-section-rules-rule mod-full"
                                  >
                                    <ProfileEditorLine
                                      id={key}
                                      placeholder={i18n.t('Optional search')}
                                      searchline={searchline}
                                    />
                                    <DebugMessage id={key} line={line} fixProfileSearchterm={fixProfileSearchterm} />
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row mod-border-left">
                          <h4 onClick={this.addProfileEditorLineHandler('E')}>
                            <Translate i18nString="And none of the search lines" />
                            <i>
                              <span className="op-icon-circle-plus" />
                            </i>
                          </h4>
                          <div>
                            <ul className="op-content-article-listing-profile-editor-section-rules">
                              {profilesWithDebug &&
                                searchLines.E &&
                                searchLines.E.map(([key, { line, searchline }]) => (
                                  <li
                                    key={key}
                                    className="op-content-article-listing-profile-editor-section-rules-rule mod-full"
                                  >
                                    <ProfileEditorLine
                                      id={key}
                                      placeholder={i18n.t('Excluded search')}
                                      searchline={searchline}
                                    />
                                    <DebugMessage id={key} line={line} fixProfileSearchterm={fixProfileSearchterm} />
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {
                  // If user doesn't have this enabled,
                  // it is undefined, otherwise it is object | null
                  profile.stored_search !== undefined && (
                    <div className="row op-content-article-listing-profile-editor-section">
                      <h4>
                        <Translate i18nString="Store search" />
                        {
                          // If it is null, show just store search, otherwise show ID
                          profile.stored_search && `: ${profile.stored_search.id}`
                        }
                      </h4>
                      <StoredSearch />
                    </div>
                  )
                }
                <hr className="col-md-12 op-content-profiles-cutting-line" />
                <div className="row op-content-article-listing-profile-editor-section">
                  <div className="col-md-12">
                    <div className="row op-content-profiles-history">
                      {profileId && (
                        <h4
                          className="col-md-4"
                          onClick={isProfileHistoryExpanded ? shrinkProfileHistory : this.expandProfileHistory}
                        >
                          <i
                            className={classNames('op-icon-arrow-down', { 'mod-expanded': isProfileHistoryExpanded })}
                          />
                          <Translate i18nString="Profile history" />
                        </h4>
                      )}
                      {!profileId && (
                        <h4
                          className="col-md-4"
                          onClick={isDeletedProfilesExpanded ? shrinkProfileHistory : this.expandDeletedProfiles}
                        >
                          <Translate i18nString="Deleted profiles" />
                          <i
                            className={classNames('op-icon-arrow-down', { 'mod-expanded': isDeletedProfilesExpanded })}
                          />
                        </h4>
                      )}
                    </div>
                  </div>
                  {isProfileHistoryExpanded && (
                    <div className="row">
                      {!profileHistory ? <Loader /> : HistoryWrapper(HISTORY_TYPE.PROFILE_HISTORY)}
                    </div>
                  )}
                  {isDeletedProfilesExpanded && (
                    <div className="row">
                      {!profileHistory ? <Loader /> : HistoryWrapper(HISTORY_TYPE.DELETED_PROFILES)}
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

// @ts-ignore
ProfileEditor = translate([], { wait: true })(ProfileEditor)

// @ts-ignore
ProfileEditor = reduxForm({
  form: 'profileEditor',
  keepDirtyOnReinitialize: false,
  enableReinitialize: true,
  // @ts-ignore
})(ProfileEditor)

// @ts-ignore
ProfileEditor = connect(
  (state: any, props: any) => {
    const initialValues = props.profile
      ? {
          profileName: props.profile.name,
          profileParent: props.profile.parent,
          storedSearch: props.profile.stored_search && props.profile.stored_search.active,
        }
      : {
          profileName: '',
          profileParent: 0,
        }

    return {
      initialValues,
      profiles: getProfiles(state),
      filtersOpen: isProfileEditorFiltersPanelOpen(state),
    }
  },
  buildActionCreators({
    clearDebug: ActionTypes.PROFILE_EDITOR_CLEAR_DEBUG,
    addProfileEditorLine: ActionTypes.ADD_PROFILE_EDITOR_LINE,
    toggleFiltersPanel: ActionTypes.PROFILE_EDITOR_FILTERS_TOGGLE,
  }),
)(ProfileEditor)

// @ts-ignore
ProfileEditor = ProfileEditorWrapper(ProfileEditor)

// @ts-ignore
ProfileEditor = connect(
  (state, props: any) => ({
    profile: getOpenProfile(state),
    debug: getProfileEditorDebug(state),
    profileId: profileIdFromLocationQuery(props.location.query.filters),
    isProfileHistoryExpanded: isProfileHistoryExpanded(state),
    isDeletedProfilesExpanded: isDeletedProfilesExpanded(state),
    profileHistory: getProfileHistory(state),
    showMoreHistorySegment: getShowMoreHistorySegment(state),
    allHistoryResultsLength: getAllHistoryResultsLength(state),
    autoexpandSearchlines: getUISetting('AUTOEXPAND_PROFILE_EDITOR_SEARCHLINES')(state),
  }),
  buildActionCreators({
    loadProfileDetails: ActionTypes.LOAD_EDITED_PROFILE,
    leaveProfileEditor: ActionTypes.LEAVE_PROFILE_EDITOR,
    loadEditedProfileSuccess: ActionTypes.LOAD_EDITED_PROFILE_SUCCESS,
    fixProfileSearchterm: ActionTypes.PROFILE_EDITOR_FIX_SEARCHTERM,
    fetchProfileHistory: ActionTypes.PROFILE_HISTORY_FETCH,
    expandProfileHistory: ActionTypes.PROFILE_EXPAND_HISTORY,
    expandDeletedProfiles: ActionTypes.DELETED_PROFILES_EXPAND,
    shrinkProfileHistory: ActionTypes.PROFILE_SHRINK_HISTORY,
    setOldProfileVersion: ActionTypes.PROFILE_SET_OLD_VERSION,
    fetchDeletedProfilesHistory: ActionTypes.DELETED_PROFILES_HISTORY_FETCH,
    showMoreProfileHistory: ActionTypes.PROFILE_HISTORY_SHOW_MORE,
  }),
)(ProfileEditor)

export default ProfileEditor

import { KeyValue } from '../types/settings'
import { IMAGE_URL_BASE } from '../constants/settings'

// Transforms string of format 'key=val,key2=val2,...' to array of objects [{key: 'key', value: 'val'},{key: 'key2', value: 'val2'},...]
export const keyValueParse = (str?: string, sep: string = ','): KeyValue[] => {
  if (!str) {
    return []
  }

  return str.split(sep).map((item) => {
    const [key, value] = item.split('=')
    return {
      key,
      value,
    }
  })
}

// Transforms array of objects [{key: 'key', value: 'val'},{key: 'key2', value: 'val2'},...] to string of format 'key=val,key2=val2,...'
export const keyValueSerialize = (obj?: KeyValue[], sep: string = ','): string => {
  if (!Array.isArray(obj) || obj.length === 0) {
    return ''
  }

  return obj.map(({ key, value }) => `${key}=${value}`).join(sep)
}

export const changeImageUrlBase = (str: string): string => {
  return str.replace(IMAGE_URL_BASE.old, IMAGE_URL_BASE.new)
}

export const swapSignInString = (str: string): string => str.replace(/^(\+|-)/, (sign) => (sign === '-' ? '+' : '-'))

// @ts-nocheck
import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'

import buildActionCreators from '../../helpers/buildActionCreators'
import entityFilter from '../common/entityFilter'
import EntityHeader from '../common/EntityHeader'
import FilterMessage from '../common/FilterMessage'
import Translate from '../../Translate'
import TagList from './TagList'
import { getFilteredTags, getLastUsedTagId } from '../../selectors/tagsSelectors'
import { MODULE } from '../../opoint/settings/index'
import { TAG_TYPES } from '../../opoint/tags/index'
import { getUISetting } from '../../selectors/settingsSelectors'
import * as ActionTypes from '../../constants/actionTypes'
import type { Tag } from '../../opoint/flow'

type TagListingProps = {
  displayImportant: string
  expanded?: boolean
  filterExpression?: string
  list: Array<Tag>
  numberOfFiltered: number
  onNewTagClicked: () => {}
  toggleCategory: () => {}
  type: number
}

class TagListing extends React.Component<TagListingProps> {
  static contextTypes = {
    showAddTagPopover: PropTypes.func,
  }

  isExpanded = () => {
    const { expanded, filterExpression } = this.props
    return expanded || !!filterExpression
  }

  showAddTagPopoverHandler = (e: any): void => {
    const { showAddTagPopover } = this.context

    showAddTagPopover(e)
  }

  toggleTagVisibility = () => {
    this.props.toggleCategory('HIDE_ALL_TAGS')
  }

  render() {
    const {
      list: tags,
      displayImportant,
      filterExpression,
      numberOfFiltered,
      onTagsReorder,
      toggleCategory,
      showImportantSetting,
      resetFilter,
      type,
      i18n,
      hideAllTags,
      lastUsedTagID,
    } = this.props

    // If there are no sentiment tags, we don't display listing in the left column
    if (type === TAG_TYPES.MENTOMETER && !tags.length) {
      return <div />
    }

    const toggleTagVisibilityButton = (
      <button
        className="op-button-square entity-all-tags-visibility"
        title={hideAllTags ? i18n.t('Display tags on articles ') : i18n.t('Hide all tags on articles')}
        onClick={this.toggleTagVisibility}
      >
        {hideAllTags ? <span className="op-icon-striked-eye" /> : <span className="op-icon-eye" />}
      </button>
    )

    return (
      <div className="col-md-12">
        <div className="row">
          <div className="entity">
            <EntityHeader
              module={MODULE.TAG}
              onNewButtonClicked={this.showAddTagPopoverHandler}
              toggleTagVisibilityButton={toggleTagVisibilityButton}
              newEntityTitle={i18n.t('Create new tag')}
              {...this.props}
              isExpanded={this.isExpanded()}
            />
            <div
              className={classNames('entity__body', 'entity__body--marginBottom2', {
                'entity_body--hidden': !this.isExpanded(),
              })}
            >
              {!tags.length && !filterExpression && <Translate i18nString="No tags" />}
              {tags && !!tags.length && (
                <TagList
                  displayImportant={displayImportant}
                  onTagsReorder={onTagsReorder}
                  tags={tags}
                  filtered={!!filterExpression}
                  lastUsedTagID={lastUsedTagID}
                />
              )}
              <FilterMessage
                displayImportant={displayImportant}
                filterExpression={filterExpression}
                listLength={tags.length}
                listName="tags"
                numberOfFiltered={numberOfFiltered}
                showImportantSetting={showImportantSetting}
                toggleCategory={toggleCategory}
                resetFilter={resetFilter}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

TagListing = entityFilter(TagListing)

TagListing = translate([], { wait: true })(TagListing)

export default connect(
  (state, props) => ({
    list: getFilteredTags(state)(props.type),
    expanded: getUISetting(props.category)(state),
    displayImportant: getUISetting(props.showImportantSetting)(state),
    hideAllTags: getUISetting('HIDE_ALL_TAGS')(state),
    lastUsedTagID: getLastUsedTagId(state),
  }),
  buildActionCreators({
    toggleCategory: ActionTypes.TOGGLE_UI_SETTING,
    resetFilter: ActionTypes.ENTITY_FILTER_CHANGED,
    onTagsReorder: ActionTypes.TAGS_REORDER,
  }),
)(TagListing)

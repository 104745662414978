// @ts-nocheck

import Rx from 'rxjs'
import moment from 'moment'
import { push } from 'react-router-redux'

import buildAction from '../../helpers/buildAction'
import { logOutOnExpiredToken, serverIsDown } from '../configureEpics'
import { searchDataToURLParam } from '../../opoint/search/url'
import * as ActionTypes from '../../constants/actionTypes'
import { getDefaultHome } from '../../selectors/settingsSelectors'
import type { Searchline } from '../../opoint/flow'

type searchAction = {
  payload: {
    searchline: Searchline
    pathname: string
    timestamp: number
  }
}

const onSearch = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.SEARCH).map(({ payload: { searchline, pathname, timestamp } }: searchAction) => {
    // const op = '/search/'
    // return push(`${op}${searchDataToURLParam(searchline, timestamp)}`)
    const unixTimestamp = timestamp || moment().format('x')
    return push(`${pathname}${searchDataToURLParam(searchline, unixTimestamp)}`)
  })

const searchGoToStatisticsEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.SEARCH_GO_TO_STATISTICS).map(() => {
    const state = getState()
    const {
      routing: {
        locationBeforeTransitions: { search },
      },
    } = state
    return push(`/statistics/${search}`)
  })

const searchGoToEditProfileEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.SEARCH_GO_TO_EDIT_PROFILE).map(() => {
    const state = getState()
    const {
      routing: {
        locationBeforeTransitions: { search },
      },
    } = state
    return push(`/search/edit/${search}`)
  })

const searchGoToNewsEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.SEARCH_GO_TO_NEWS).map(() => {
    const state = getState()
    const {
      routing: {
        locationBeforeTransitions: { search },
      },
    } = state
    return push(`/search/${search}`)
  })

const profileEditorGoBackToSearchEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.PROFILE_EDITOR_BACK_TO_SEARCH).map(() => {
    const state = getState()
    const {
      routing: {
        locationBeforeTransitions: { search },
      },
    } = state
    return push(`/search/${search}`)
  })

const searchReplaceFiltersEpic = (action$: any, { getState }: any) =>
  action$
    .ofType(ActionTypes.SEARCH_REPLACE_FILTERS)
    .map(({ payload: { pathname, params } }) => push(`${pathname}${params}`))

const addAlertCancelEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.ALERT_ADD_CANCEL).map(() => {
    const state = getState()
    const {
      alerts: { list },
    } = state
    // If there are any alerts, redirect to the first one of them
    if (list && list[0]) {
      return push(`/alerts/${list[0].id}`)
    }

    const defaultHome = getDefaultHome(getState())
    // If not, redirect to the default profile
    if (defaultHome && defaultHome.type && defaultHome.id) {
      return push(`/search/?filters=${defaultHome.type}:${defaultHome.id}`)
    }

    // If there is no default profile, redirect to the empty route
    return push('/')
  })

const editAlertCancelEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.ALERT_EDIT_CANCEL).map(() => {
    const state = getState()
    const {
      form: {
        alert: {
          edit: {
            values: { id },
          },
        },
      },
    } = state
    return push(`/alerts/${id}`)
  })

export const goToNewFeedEpic = (action$: any) =>
  action$
    .ofType(ActionTypes.GO_TO_NEW_FEED)
    .map(() => push('/feeds'))
    .catch(logOutOnExpiredToken)
    .catch(serverIsDown)
    .catch(() => Rx.Observable.of(buildAction(ActionTypes.FEED_NEW_ERROR)))

export default [
  addAlertCancelEpic,
  editAlertCancelEpic,
  goToNewFeedEpic,
  onSearch,
  profileEditorGoBackToSearchEpic,
  searchGoToEditProfileEpic,
  searchGoToNewsEpic,
  searchGoToStatisticsEpic,
  searchReplaceFiltersEpic,
]

import React, { useEffect, useState, useCallback } from 'react'
import update from 'immutability-helper'

import { keyValueSerialize } from '../../helpers/common'
import { Option } from '../../types/settings'
import { createOptionsFromSetting } from '../../helpers/settings'
import { list } from './index.module.scss'
import DragNDropOption from './DragNDropOption'

type Props = {
  allOptions: Option[]
  input: {
    value: string
    onChange: (val: string) => void
  }
}

const SettingToDragNDropOptions = ({ allOptions, input: { value, onChange } }: Props) => {
  const [options, setOptions] = useState(createOptionsFromSetting(allOptions, value))

  useEffect(() => {
    onChange(
      keyValueSerialize(
        options.map(({ value, checked }) => ({
          key: value,
          value: checked ? 'true' : 'false',
        })),
      ),
    )
  }, [options])

  const moveBox = useCallback(
    ({ targetValue, sourceValue }) => {
      const fromIndex = options.findIndex(({ value }) => value === sourceValue)
      const toIndex = options.findIndex(({ value }) => value === targetValue)
      setOptions(
        // @ts-ignore
        update(options, {
          $splice: [
            [fromIndex, 1],
            [toIndex, 0, options[fromIndex]],
          ],
        }),
      )
    },
    [options],
  )

  const onChangeHandler = (value: string) => {
    setOptions(options.map((item) => (item.value === value ? { ...item, checked: !item.checked } : item)))
  }

  return (
    <ul className={list}>
      {options.map(({ checked, name, value }, i) => (
        <DragNDropOption
          key={i}
          value={value}
          label={name}
          checked={checked}
          onChange={() => onChangeHandler(value)}
          dropHandler={moveBox}
          index={i}
        />
      ))}
    </ul>
  )
}

export default SettingToDragNDropOptions

import React from 'react'
import classNames from 'classnames'

type inputProps = {
  input: Object
  label: string | Function
  id: string
  errorMsg: string | Function
  meta: any
  touched: boolean
  error: boolean
}

export const OpointTextarea = (props: inputProps) => {
  const {
    input,
    label,
    id,
    errorMsg,
    meta: { touched, error },
  } = props

  return (
    <div className={classNames('op-form-group', touched && error ? 'is-error' : null)}>
      <label htmlFor={id}>{label}</label>
      <textarea {...input} id={id} name="title" />
      {touched && error && <p className="help-block">{errorMsg}</p>}
    </div>
  )
}

// @ts-nocheck
import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'

import Translate from '../../../Translate'
import { DAY_CONST_TIME_SCHEDULE } from '../../../opoint/alerts/weekdays'
import { isWorkingDay, isWeekendDay, WHEN_ALERT } from '../../../opoint/alerts/index'

type Props = {
  timeConfiguration: Object
  whenAlert: number
}

// TODO: Get rid of the $FlowFixMe's (Mixed x Object prop access) @honza
class TimeSchedule extends React.PureComponent<any, any> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  timeString = (hour: number, minute: number): string => moment({ hour, minute }).format('H:mm')

  dayScheduleString = (dayIndex: string, timeConf: any): string => {
    const { end, start, interval, sendInterval } = timeConf
    const { i18n } = this.context

    let scheduleString = ''
    const isInterval = interval.length !== 1
    const startString = this.timeString(interval[0], start)
    const endString = this.timeString(interval[interval.length - 1], end)

    if (isInterval) {
      scheduleString = `${i18n.t('From {{time}}')} <strong>${startString}</strong> ${i18n.t(
        'to {{time}}',
      )} <strong>${endString}</strong>`
    } else {
      scheduleString = `${i18n.t('At {{time}}')} <strong>${startString}</strong>`
    }

    if (sendInterval) {
      scheduleString += `, ${i18n.t('with interval {{interval}}')} <strong>${sendInterval}</strong> ${i18n.t(
        'minutes',
      )}`
    } else if (isInterval) {
      scheduleString += `, ${i18n.t('sent immediately')}`
    }

    return scheduleString
  }

  dayScheduleStrings = (dayConf: Object, dayIndex: string): Array<string> =>
    // $FlowFixMe
    Object.entries(dayConf).map(([key, config]) => this.dayScheduleString(dayIndex, config))

  daySchedule = (dayIndex: string, i18n: any): Array<string> => {
    const { timeConfiguration: timeConf, whenAlert } = this.props

    const dayConf = timeConf[dayIndex]
    const anyDayHasConf = Object.keys(timeConf).length

    const weekdaysTimeCfg = timeConf.weekdays
    const weekendsTimeCfg = timeConf.weekends
    const everydayTimeCfg = timeConf.alldays

    if (whenAlert === WHEN_ALERT.AS_SOON_AS_POSSIBLE) {
      return ['Sent immediately']
    }
    if (dayConf && dayConf.length) {
      return this.dayScheduleStrings(dayConf, dayIndex)
    }
    if (weekdaysTimeCfg && weekdaysTimeCfg.length && isWorkingDay(dayIndex)) {
      return this.dayScheduleStrings(weekdaysTimeCfg, 'weekdays')
    }
    if (weekendsTimeCfg && weekendsTimeCfg.length && isWeekendDay(dayIndex)) {
      return this.dayScheduleStrings(weekendsTimeCfg, 'weekends')
    }
    if (everydayTimeCfg && everydayTimeCfg.length) {
      return this.dayScheduleStrings(everydayTimeCfg, 'alldays')
    }
    if (anyDayHasConf && dayIndex === 'holidays') {
      return [i18n.t('Same as other days')]
    }
    return [i18n.t('Alerts are not sent on this day')]
  }

  render() {
    const { timeConfiguration } = this.props
    const { i18n } = this.context
    if (!timeConfiguration) {
      return null
    }
    return (
      <div className="col-md-3 op-content-right_wrapper mod-alerts">
        <div className="op-content-right_wrapper__row row">
          <div className="col-md-12 op-content-right-alerts-schedule">
            <div className="op-content-right-alerts-schedule-header">
              <div className="row">
                <div className="col-lg-12">
                  <h2>
                    <Translate i18nString="Time schedule" />
                  </h2>
                </div>
              </div>
            </div>
            <div className="op-content-right-section-alerts-schedule-time">
              {
                // $FlowFixMe
                Object.entries(DAY_CONST_TIME_SCHEDULE).map(([id, day]: [string, any]) => (
                  <div key={id} className="row">
                    <div className="col-lg-4 util_col-vertical-center" style={{ height: '20px' }}>
                      <h3>{i18n.t(day.displayName)}</h3>
                    </div>
                    <div className="col-lg-8 util_col-vertical-center">
                      <ul className="op-content-right-section-alerts-schedule-time-list">
                        {/*
                         #TODO refactor line variable to be separate component
                         */}
                        {this.daySchedule(day.indexName, i18n).map((line, key) => (
                          <li key={key} dangerouslySetInnerHTML={{ __html: line }} />
                        ))}
                      </ul>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default translate()(TimeSchedule)

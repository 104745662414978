import React from 'react'

import HorizontalScroll from '../../../new-components/common/HorizontalScroll'
import ArticleMetadataBox from '../../../new-components/articles/ArticleMetadataBox'
import { Article, ArticleMetadataType, LocaleKey } from '../../../opoint/flow'

type ArticleMetadataProps = {
  article: Article
  articleMetadata: Array<ArticleMetadataType>
  locale: LocaleKey
}

const ArticleMetadata = ({ article, articleMetadata, locale }: ArticleMetadataProps) => {
  if (!articleMetadata || articleMetadata.length === 0) {
    return null
  }

  return (
    <div className="op-content-article-listing-article-options">
      <HorizontalScroll>
        {articleMetadata.map((metadata) => (
          <ArticleMetadataBox key={metadata} article={article} type={metadata} locale={locale} />
        ))}
      </HorizontalScroll>
    </div>
  )
}

export default ArticleMetadata

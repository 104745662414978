import React from 'react'

import FilterTag from '../../opoint/search/FilterTag'
import { getContactFilterName } from '../../opoint/contacts/index'
import type { Filter } from '../../opoint/flow'

type ContactFilterProps = {
  deleteHandler: (filter: Filter) => void
  filter: Filter
  // parentId: number | void,
  // separator: string,
}

export default class ContactsFilter extends React.PureComponent<ContactFilterProps> {
  deleteHandler = (filter: Filter): Function => (): void => this.props.deleteHandler(filter)

  render() {
    const { filter } = this.props
    const contactFilterName = getContactFilterName(filter)
    let name
    switch (filter.type) {
      case 'group':
        name = `Group: ${contactFilterName}`
        break
      case 'phoneNumber':
        name = `Phone number: ${contactFilterName}`
        break
      case 'email':
        name = `Email: ${contactFilterName}`
        break
      default:
        name = contactFilterName
    }
    return (
      <FilterTag
        deleteHandler={this.deleteHandler(filter)}
        filter={{
          name,
          type: filter.type,
        }}
      />
    )
  }
}

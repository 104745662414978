// @ts-nocheck
import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'

import buildActionCreators from '../../helpers/buildActionCreators'
import Translate from '../../Translate'
import GroupCheckbox from '../common/GroupCheckbox'
import { getContactFilter, getContactsSortedByName, getEditedGroup } from '../../selectors/contactSelectors'
import * as ActionTypes from '../../constants/actionTypes'
import type { Group, Contact, Filter } from '../../opoint/flow'

type GroupProps = {
  groups: Array<Group>
  sortedContacts: Array<Contact>
  activeGroup: Group
  removeActiveGroup: () => void
  initNewGroup: () => void
  clearContactFilter: () => void
  onDeleteGroup: () => void
  checkContactGroups: (id: number) => void
  filterChange: (filter: Filter) => void
  contactFilter: Filter
}

type Id = {
  id: number
}

export class GroupEditorComponent extends React.PureComponent<GroupProps> {
  componentWillMount() {
    this.props.initNewGroup()
  }

  componentWillUnmount() {
    this.props.removeActiveGroup()
    this.props.clearContactFilter()
  }

  getContactName = ({ firstName, lastName }: Contact) => `${firstName} ${lastName}`

  keyFn = ({ id }: Id) => id

  valueFn = ({ id }: Id) => id

  checkContactGroupsHandler = (id: number) => {
    this.props.checkContactGroups(id)
  }

  isChecked = (contact: Contact) => {
    const { activeGroup } = this.props
    return !!(activeGroup && activeGroup.contacts && activeGroup.contacts[contact.id])
  }

  filteringFn = (expr: string, contact: Contact) => {
    const re = new RegExp(expr, 'i')
    return re.test(this.getContactName(contact))
  }

  renderComponent = (contact: Contact) => <span>{this.getContactName(contact)}</span>

  render() {
    const { sortedContacts, onDeleteGroup, filterChange, activeGroup, contactFilter } = this.props
    const isEditGroup = activeGroup && Object.keys(activeGroup).length > 1

    return (
      <div className="col-md-12 mod-full-height">
        <div name="row mod-full-height">
          <div className="row groupForm">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12">
                  <div className="op-form-group">
                    <label htmlFor="name">
                      <Translate i18nString="Group name" />
                    </label>
                    <Field name="name" id="name" type="text" component="input" required />
                  </div>
                </div>
              </div>
            </div>

            {!!sortedContacts.length && (
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <label>
                      <Translate i18nString="Contacts" />
                    </label>
                    <GroupCheckbox
                      list={sortedContacts}
                      keyFn={this.keyFn}
                      valueFn={this.valueFn}
                      renderComponent={this.renderComponent}
                      filteringFn={this.filteringFn}
                      filterExp={contactFilter}
                      onFilterChange={filterChange}
                      isChecked={this.isChecked}
                      onToggle={this.checkContactGroupsHandler}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="col-md-12 mod-to-bottom">
              <div className="row">
                <div className="col-md-12  util_col-vertical-center">
                  <div className="row">
                    <div className="op-modal-contacts-right-button-wrapper">
                      <button className="op-button simple-blue" type="submit" data-test="group-save">
                        <Translate i18nString="save" />
                      </button>
                      {isEditGroup && (
                        <button
                          type="button"
                          className="op-button simple-red"
                          data-test="group-delete"
                          onClick={onDeleteGroup}
                        >
                          <Translate i18nString="delete" />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const GroupEditorForm = reduxForm({
  form: 'groupEditor',
  enableReinitialize: true,
})(GroupEditorComponent)

const GroupEditor = connect(
  (state) => {
    const activeGroup = getEditedGroup(state)
    const initVal = activeGroup
      ? {
          id: activeGroup.id || 0,
          url: activeGroup.url || '',
          name: activeGroup.name || '',
        }
      : null
    return {
      activeGroup,
      initialValues: initVal,
      sortedContacts: getContactsSortedByName(state),
      contactFilter: getContactFilter(state),
    }
  },
  buildActionCreators({
    checkContactGroups: ActionTypes.CONTACT_GROUPS_CHECKED,
    removeActiveGroup: ActionTypes.GROUP_REMOVE_ACTIVE,
    initNewGroup: ActionTypes.GROUP_INIT_NEW,
    onDeleteGroup: ActionTypes.GROUP_DELETE_CONFIRM,
    filterChange: ActionTypes.CONTACT_FILTER_CHANGE,
    clearContactFilter: ActionTypes.CLEAR_CONTACT_FILTER,
  }),
)(GroupEditorForm)

export default GroupEditor

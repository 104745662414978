import * as React from 'react'

import Translate from '../../Translate'

const ProfileEditorWrapper = (ComposedComponent) =>
  class extends React.Component<any, any> {
    componentWillMount() {
      this.loadProfile()
    }

    componentDidUpdate() {
      this.loadProfile()
    }

    componentWillUnmount() {
      this.props.leaveProfileEditor()
    }

    loadProfile = () => {
      const { profile, loadProfileDetails, profileId } = this.props
      if (profile === null || profile.id !== profileId) {
        loadProfileDetails({ profileId })
      }
    }

    render() {
      const { profile } = this.props
      if (profile === null) {
        return (
          <div>
            <Translate i18nString="Loading" />
            ...
          </div>
        )
      }

      return <ComposedComponent {...this.props} profile={profile} />
    }
  }

export default ProfileEditorWrapper

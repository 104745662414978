import React from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'

import buildActionCreators from '../../../helpers/buildActionCreators'
import * as ActionTypes from '../../../constants/actionTypes'
import { isEditArticleModalOpen } from '../../../selectors/uiSelectors'
import EditArticle from './EditArticle'
import Translate from '../../../Translate'

class EditArticleModal extends React.PureComponent<any> {
  render() {
    const { isOpen, closeModal } = this.props
    return (
      <Modal show={isOpen} onHide={closeModal}>
        <Modal.Header>
          <button type="button" className="close" onClick={closeModal}>
            ×
          </button>
          <h4 className="modal-title">
            <Translate i18nString="Edit article" />
          </h4>
        </Modal.Header>
        <Modal.Body>
          <EditArticle />
        </Modal.Body>
      </Modal>
    )
  }
}

// @ts-ignore
EditArticleModal = connect(
  (state) => ({
    isOpen: isEditArticleModalOpen(state),
  }),
  buildActionCreators({
    closeModal: ActionTypes.EDIT_ARTICLE_MODAL_CLOSE,
  }),
)(EditArticleModal)

export default EditArticleModal

import React from 'react'
import R from 'ramda'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'

import buildActionCreators from '../../helpers/buildActionCreators'
import FiltersDropDownButton from '../search/FiltersDropDownButton'
import FiltersPanelSearchWrapped from '../../new-components/filters/FilterPanel'
import NotificationsPopover from './NotificationsPopover'
import SearchInputWrapper from './SearchInputWrapper'
import Translate from '../../Translate'
import UserPopover from './UserPopover'
import { getPathname } from '../../selectors/routingSelectors'
import { getSearchterm } from '../../selectors/searchSelectors'
import { getUser } from '../../selectors/authSelectors'
import * as ActionTypes from '../../constants/actionTypes'
import { isNewVersionNotificationShown } from '../../selectors/uiSelectors'
import { setReleaseNotesViewed } from '../../opoint/version/index'

type HeaderActionsProps = {
  goBackToSearch: () => void
  onFilterPanelToggle: ({ searchterm: string }) => void
  addArticle: () => void
  pathname: string
  searchterm: string
  newVersionNotification: boolean
  showSearch: boolean
  hideNewVersionNotification: any
  openReleaseNotesModal: any
}

class HeaderActions extends React.Component<HeaderActionsProps> {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    i18n: PropTypes.object,
  }

  popoverRef: any

  constructor(props) {
    super(props)
    this.popoverRef = null
  }

  onBackClickHandler = () => {
    this.props.goBackToSearch()
  }

  onNewVersionClickHandler = () => {
    const { hideNewVersionNotification, openReleaseNotesModal } = this.props

    openReleaseNotesModal()
    hideNewVersionNotification()
    setReleaseNotesViewed()
  }

  handleFilterToggle = () => {
    this.props.onFilterPanelToggle({ searchterm: this.props.searchterm })
  }

  addArticleClickHandler = () => {
    this.props.addArticle()
  }

  getHeaderSearchInput = (isNotSearch) => {
    const { showSearch } = this.props

    if (!showSearch) {
      return <div className="col-md-10  op-content-top-header-rest-searchbar_wrapper u-no-padding" />
    }

    return (
      <div className="col-md-10  op-content-top-header-rest-searchbar_wrapper u-no-padding">
        {isNotSearch ? (
          <div className="op-content-top-header-rest-searchbar-back">
            <a onClick={this.onBackClickHandler}>
              <i className="op-icon-arrow-big-left" />
              <Translate i18nString="back to search" />
            </a>
          </div>
        ) : (
          <SearchInputWrapper />
        )}
      </div>
    )
  }

  render() {
    const { i18n } = this.context
    const { pathname, newVersionNotification, showSearch } = this.props
    const isNotSearch =
      pathname.indexOf('/search/edit') === 0 ||
      pathname.indexOf('/search/new') === 0 ||
      !(R.contains('search', pathname) || R.contains('statistics', pathname))

    return (
      <div className="col-md-10 op-content-top-header-rest_wrapper">
        <div className="row">
          {/* TODO is-show class = isFiltering */}
          {!!showSearch && (
            <FiltersPanelSearchWrapped
              sliderSettings={{
                responsive: [
                  { breakpoint: 960, settings: { slidesToShow: 3, slidesToScroll: 3 } },
                  { breakpoint: 1420, settings: { slidesToShow: 4 } },
                  { breakpoint: 10000, settings: { slidesToShow: 9 } },
                ],
              }}
            />
          )}

          {this.getHeaderSearchInput(isNotSearch)}

          <div className="col-md-2  op-content-top-header-rest-user_wrapper">
            <div className="row">
              <ul className="op-content-top-header-rest-user-list">
                {!isNotSearch && showSearch && <FiltersDropDownButton handleFilterToggle={this.handleFilterToggle} />}
                {newVersionNotification && (
                  <li
                    title={i18n.t("New version release! Open to see what's new in M360")}
                    className="op-content-top-header-rest-user-list-item user-options user-options__new-version-notification mod-full-height u-all-center"
                    onClick={this.onNewVersionClickHandler}
                  >
                    <i className="op-icon-new_releases" />
                  </li>
                )}
                <li
                  className="op-content-top-header-rest-user-list-item user-options user-options__upload mod-full-height u-all-center"
                  onClick={this.addArticleClickHandler}
                >
                  <i className="op-icon-upload-arrow" />
                </li>
                <NotificationsPopover />
                <UserPopover />
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

// @ts-ignore
HeaderActions = translate([], { wait: true })(HeaderActions)

// @ts-ignore
export default HeaderActions = connect(
  (state) => ({
    user: getUser(state),
    searchterm: getSearchterm(state),
    pathname: getPathname(state),
    newVersionNotification: isNewVersionNotificationShown(state),
  }),
  buildActionCreators({
    addArticle: ActionTypes.ADD_ARTICLE_MODAL_OPEN,
    onFilterPanelToggle: ActionTypes.FILTERS_PANEL_TOGGLE,
    onSearchTriggered: ActionTypes.SEARCH,
    goBackToSearch: ActionTypes.PROFILE_EDITOR_BACK_TO_SEARCH,
    openReleaseNotesModal: ActionTypes.RELEASE_NOTES_MODAL_OPEN,
    hideNewVersionNotification: ActionTypes.HIDE_NEW_VERSION_NOTIFICATION,
  }),
  // @ts-ignore
)(HeaderActions)

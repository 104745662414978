import React from 'react'
import PropTypes from 'prop-types'

/**
 * Component used to display video matches
 */
export default class VideoMatchesReactComponent extends React.Component<any, any> {
  static propTypes = {
    article: PropTypes.object.isRequired,
    stopVideos: PropTypes.func.isRequired,
    videoContainer: PropTypes.object,
  }

  static defaultProps = {
    videoContainer: {},
  }

  shouldComponentUpdate() {
    return false
  }

  onClick = (seconds) => () => this.jumpTo(seconds)

  jumpTo(seconds) {
    const { videoContainer, stopVideos } = this.props

    stopVideos()
    videoContainer.currentTime = seconds
    videoContainer.paused && videoContainer.play()
  }

  render() {
    const { article } = this.props
    return (
      <div className="row">
        <div className="col-lg-12">
          <ul className="op-content-article-listing-article-content-matches-list">
            {/* eslint-disable-next-line no-underscore-dangle */}
            {article._videoMatches &&
              article._videoMatches.map((match) => (
                <li
                  className={`op-content-article-listing-article-content-matches-list-item color-num-${match.color}`}
                  key={match.id}
                  onClick={this.onClick(match.seconds)}
                >
                  {match.readableSeconds} {match.matchText}
                </li>
              ))}
          </ul>
        </div>
      </div>
    )
  }
}

import React from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'

import buildActionCreators from '../../helpers/buildActionCreators'
import HistoryListing from './HistoryListing'
import Translate from '../../Translate'
import { REPORTSHISTORY_PAGE_LENGTH } from '../../opoint/reportsHistory/index'
import * as ActionTypes from '../../constants/actionTypes'

class ReportsHistoryModal extends React.PureComponent<any> {
  fetchNextReports = () => {
    const { reports, fetchReportsHistory } = this.props
    const page = reports ? reports.length / REPORTSHISTORY_PAGE_LENGTH + 1 : 1
    fetchReportsHistory({ page })
  }

  render() {
    const { isOpen, reportsModalClose, reports, loading, totalCount } = this.props
    return (
      <Modal show={isOpen} onHide={reportsModalClose}>
        <Modal.Header>
          <button type="button" className="close" onClick={reportsModalClose}>
            ×
          </button>
          <h4 className="modal-title">
            <Translate i18nString="Report history" />
          </h4>
        </Modal.Header>
        <Modal.Body className="op-modal-settings">
          <HistoryListing reports={reports} loading={loading} fetchNextReports={this.fetchNextReports} />
        </Modal.Body>
        <Modal.Footer>
          {!loading && reports && totalCount !== reports.length && (
            <button className="op-button green" onClick={this.fetchNextReports}>
              <Translate i18nString="Load more" />
            </button>
          )}
        </Modal.Footer>
      </Modal>
    )
  }
}

// @ts-ignore
ReportsHistoryModal = connect(
  (state: any) => ({
    reports: state.reportsHistory.modalReportsHistory,
    isOpen: state.ui.reportsHistoryOpen,
    loading: state.reportsHistory.loading,
    totalCount: state.reportsHistory.totalCount,
  }),
  buildActionCreators({
    reportsModalClose: ActionTypes.REPORTS_HISTORY_CLOSE,
    fetchReportsHistory: ActionTypes.REPORTHISTORY_FETCH,
  }),
)(ReportsHistoryModal)

export default ReportsHistoryModal

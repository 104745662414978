import React, { ReactNode } from 'react'

import Tooltip from '../../common/Tooltip'
import styles from './Entity.module.scss'

export interface Props {
  popupContent: ReactNode
  children: ReactNode
  tooltipClassName?: string
}

const Entity = ({ children, popupContent, tooltipClassName }: Props) => {
  return (
    <Tooltip className={tooltipClassName} placement="top" content={popupContent}>
      <span className={styles.wrapper}>{children}</span>
    </Tooltip>
  )
}

export default Entity

import React from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'

import buildActionCreators from '../../../helpers/buildActionCreators'
import FormWrapper from './FormWrapper'
import Translate from '../../../Translate'
import { isEditorModalOpen } from '../../../selectors/uiSelectors'
import * as ActionTypes from '../../../constants/actionTypes'

type Props = {
  isOpen: boolean
  templateModalClose: () => void
}

const TemplateEditorModal = (props: Props) => (
  <Modal show={props.isOpen} onHide={props.templateModalClose}>
    <Modal.Header>
      <button type="button" className="close" onClick={props.templateModalClose}>
        ×
      </button>
      <h4 className="modal-title">
        <Translate i18nString="Template editor" />
      </h4>
    </Modal.Header>
    <Modal.Body className="op-modal-settings">
      <div className="op-modal-settings">
        {/*
            @ts-ignore */}
        <FormWrapper {...props} />
      </div>
    </Modal.Body>
  </Modal>
)

export default connect(
  (state) => ({
    isOpen: isEditorModalOpen(state),
  }),
  buildActionCreators({
    templateModalClose: ActionTypes.TEMPLATE_EDITOR_MODAL_CLOSE,
  }),
)(TemplateEditorModal)

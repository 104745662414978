// @ts-nocheck
import React from 'react'

import { highlightWithSuggestionServerHint } from '../../../opoint/common/highlight'
import '../../../opoint/search/AutocompleteItem.css'
import type { Filter } from '../../../opoint/flow'

type Props = {
  suggestion: Filter
  onClickHandler: (suggestion: any) => void
}

class AutocompleteItem extends React.PureComponent<Props> {
  onClick: Function = () => {
    const { suggestion, onClickHandler } = this.props

    onClickHandler(suggestion)
  }

  render() {
    const { suggestion } = this.props
    const { name, hl } = suggestion
    const suggestionHtml = { __html: highlightWithSuggestionServerHint(name, hl) }

    return (
      <span className="auto-item" onClick={this.onClick}>
        <span className="auto-item__name" dangerouslySetInnerHTML={suggestionHtml} />
      </span>
    )
  }
}

export default AutocompleteItem

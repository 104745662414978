import React from 'react'
import classNames from 'classnames'

import { IconName } from '../../types/theme'
import styles from './Icon.module.scss'

export interface Props {
  name: IconName
  verticalCenter?: boolean
}

export default function Icon({ name, verticalCenter = false }: Props) {
  return (
    <span className={classNames(styles.wrapper, { [styles.verticalCenter]: verticalCenter })}>
      <i className={`op-icon-${name}`} />
    </span>
  )
}

import React from 'react'

class ToggleCheckbox extends React.PureComponent<any> {
  onClickHandler = () => {
    const {
      input: { value, onChange },
    } = this.props
    onChange(+!value)
  }

  render() {
    const {
      input: { value },
    } = this.props
    return (
      <div className="op-toggle" onClick={this.onClickHandler}>
        <input checked={value} readOnly className="op-toggle-checkbox" id="editable-alert" type="checkbox" />
        <label className="op-toggle-label">
          <span className="op-toggle-inner" />
          <span className="op-toggle-switch no-text" />
        </label>
      </div>
    )
  }
}
export default ToggleCheckbox

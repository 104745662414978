import Rx from 'rx-dom'
import R from 'ramda'
import jwtDecode from 'jwt-decode'

import secretServerTime from './secret'
import config from '../common/config'
import { handleErrors } from '../common'

export const APPROXIMATE_CLOCK_SKEW = 1000 * 8
export const SERVER_TIME_TIMER = 1000 * 60 * 5 - APPROXIMATE_CLOCK_SKEW

export function removeToken() {
  window.localStorage.removeItem('ls.OP-PORTAL-AUTH-TOKEN')
}

export function setToken(token: string) {
  window.localStorage.setItem('ls.OP-PORTAL-AUTH-TOKEN', token)
}

function getToken() {
  return window.localStorage.getItem('ls.OP-PORTAL-AUTH-TOKEN')
}

export function isAuthenticated() {
  return getToken() !== null
}

export const decodeToken: (token: string) => any = jwtDecode

export function getUserInfo() {
  return isAuthenticated() ? jwtDecode(getToken()) : null
}

/**
 * Authenticates a user using username and password
 * @param username
 * @param password
 * @param rememberMe
 * @returns Promise
 */

export function authenticate(username: string, password: string, rememberMe: boolean = true) {
  const requestHeaders = R.merge(config.getRequestHeaders(), {
    url: config.getUrl('/api/auth/get-token/'),
    method: 'POST',
    body: JSON.stringify({
      username,
      password,
      remember_me: rememberMe,
    }),
  })
  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function authenticateByIp(username: string, rememberMe: boolean = true) {
  const requestHeaders = R.merge(config.getRequestHeaders(), {
    url: config.getUrl('/api/auth/get-token-by-ip/'),
    method: 'POST',
    body: JSON.stringify({
      username,
      remember_me: rememberMe,
    }),
  })
  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function usersLoginInfo() {
  const requestHeaders = R.merge(config.getRequestHeaders(false), {
    url: config.getUrl('/api/users/login_info/'),
    method: 'GET',
  })
  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function authenticateWithToken(token: string) {
  const requestHeaders = R.merge(config.getRequestHeaders(false), {
    url: config.getUrl(`/api/auth/auto-login/?hash=${token}/`),
  })
  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function refreshToken() {
  const requestHeaders = R.merge(config.getRequestHeaders(), {
    url: config.getUrl('/api/auth/refresh-token/'),
    method: 'POST',
    body: JSON.stringify({
      token: getToken(),
    }),
  })
  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function getServerTime(): Promise<number> {
  return fetch(config.getUrl('/api/auth/get-server-time/'), { ...config.getRequestHeaders(false) })
    .then(handleErrors)
    .then((r) => r.json())
    .then((data) => data.timestamp)
    .catch((e) => e) // we don't want to log anything here
}

export function setServerTime(timestamp: number): void {
  secretServerTime.setTime(timestamp)
}

export function getServerTimePromise(): Promise<number> {
  return fetch(config.getUrl('/api/auth/get-server-time/'), { ...config.getRequestHeaders(false) })
    .then((r) => r.json())
    .then((e) => e.timestamp)
}

export function fetchServerTime(): Promise<number> {
  return getServerTimePromise().then((timestamp) => Promise.resolve(timestamp))
}

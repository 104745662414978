import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'

import { filterId } from '../../opoint/search'
import { highlightSearched, highlightWithSuggestionServerHint } from '../../opoint/common/highlight'
import styles from './index.module.scss'

class FiltersPanelRow extends React.PureComponent<any> {
  onClickHandler = () => {
    const { filter, onSearchFilterToggled, onCustomTimePeriod } = this.props

    const isCustomTimeFilter = filter.type === 'custom'
    isCustomTimeFilter ? onCustomTimePeriod() : onSearchFilterToggled(filter)
  }

  render() {
    const {
      filter: { name, hl },
      isSelected,
      searchterm,
      isMainSearch,
      profileEditorSearchterm,
    } = this.props

    const term = isMainSearch ? searchterm : profileEditorSearchterm

    return (
      <li
        className={classNames({
          [styles.selected]: isSelected,
        })}
        onClick={this.onClickHandler}
      >
        {hl ? (
          <span dangerouslySetInnerHTML={{ __html: highlightWithSuggestionServerHint(name, hl) }} />
        ) : (
          <span dangerouslySetInnerHTML={{ __html: highlightSearched(name, term) }} />
        )}
      </li>
    )
  }
}

const FiltersPanelRowWrapped = connect((state, props: any) => ({
  isSelected: !!props.searchFilters[filterId(props.filter)],
}))(FiltersPanelRow)

export default FiltersPanelRowWrapped

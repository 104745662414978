import R from 'ramda'

/**
 * This helper helps build query for router
 * @param filtersQuery: String (profile:2;profile:1)
 * @param filter:String (profile:3)
 * @returns {string} (profile:2;profile:1;profile:3)
 */
export function searchRouteHelper(filtersQuery = '', filter) {
  return R.compose(
    R.join(';'),
    R.ifElse(R.contains(filter), R.reject(R.equals(filter)), R.append(filter)),
    R.reject(R.isEmpty),
    R.split(';'),
  )(filtersQuery)
}

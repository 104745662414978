import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'

import AlertSuggestion from './AlertSuggestion'
import buildActionCreators from '../../../helpers/buildActionCreators'
import SearchInput from '../../../components/search/SearchInput'
import Translate from '../../../Translate'
import { AlertEditorFilterComponent } from '../../search/FilterComponent'
import { getActiveProfileTagList } from '../../../opoint/feeds/index'
import { getEditedAlert } from '../../../selectors/alertsSelectors'
import { getProfileTagList } from '../../../selectors/searchSelectors'
import { returnFirst, emptyFunction } from '../../../helpers/common'
import * as ActionTypes from '../../../constants/actionTypes'
import type { Alert, Profile, Tag } from '../../../opoint/flow'

type ProfileInputProps = {
  onSearchFilterAdded: () => {}
  onSearchFilterToggled: () => {}
  onSearchFilterRemoved: () => {}
  onClearAll: () => {}
  removeActiveAlert: () => void
  searchterm: string
  onSearchTermChanged: () => void
  activeAlert: Alert
  profileTagList: Array<Profile | Tag>
}

function filterSuggestions(suggestions, searchterm) {
  const re = new RegExp(searchterm, 'i')
  const filteredSuggestions = suggestions.filter((term) => re.test(term.name))
  const sortedSuggestions = filteredSuggestions.sort((a, b) => {
    const firstName = a.name.toLowerCase()
    const secondName = b.name.toLowerCase()
    if (firstName < secondName) {
      return -1
    }
    if (firstName > secondName) {
      return 1
    }
    return 0
  })
  return sortedSuggestions.map((suggestion) =>
    Object.prototype.hasOwnProperty.call(suggestion, 'filtersUsed')
      ? { ...suggestion, name: `Profile: ${suggestion.name}` }
      : { ...suggestion, name: `Tag: ${suggestion.name}` },
  )
}

class ProfileInput extends React.Component<ProfileInputProps> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  componentWillUnmount() {
    this.props.removeActiveAlert()
  }

  isExpressionEmpty = () => {
    const { searchterm } = this.props
    return searchterm === ''
  }

  render() {
    const {
      searchterm,
      onSearchFilterAdded,
      onSearchFilterToggled,
      onSearchFilterRemoved,
      onClearAll,
      profileTagList,
      activeAlert,
      onSearchTermChanged,
    } = this.props
    const { i18n } = this.context

    if (!activeAlert) {
      return (
        <div className="col-md-12  mod-full-height  mod-alerts">
          <h3>
            <Translate i18nString="Loading ..." />
          </h3>
        </div>
      )
    }

    // @ts-ignore
    const activeIds = activeAlert && activeAlert.mifluzIdLists
    const activeProfileTagList = getActiveProfileTagList(profileTagList, activeIds)

    return (
      <SearchInput
        placeholder={i18n.t('Enter tag or profile')}
        filterComponent={AlertEditorFilterComponent}
        suggestionComponent={AlertSuggestion}
        onLoadSuggestions={emptyFunction}
        tagSeparator={false}
        onFilterAdded={onSearchFilterAdded}
        onFilterToggled={onSearchFilterToggled}
        removeFilter={onSearchFilterRemoved}
        clearAllHandler={onClearAll}
        triggerSearch={emptyFunction}
        typeComparator={returnFirst}
        filters={activeProfileTagList}
        suggestions={filterSuggestions(profileTagList, searchterm)}
        // @ts-ignore
        searchterm={searchterm}
        hint={searchterm}
        // @ts-ignore
        onSearchTermChanged={onSearchTermChanged}
        selectAllIcon="op-icon-checked"
        isExpressionEmpty={this.isExpressionEmpty}
        autoSuggestion
        {...this.props}
      />
    )
  }
}

// @ts-ignore
ProfileInput = translate([], { wait: true })(ProfileInput)

// @ts-ignore
ProfileInput = connect(
  (state) => {
    const activeAlert = getEditedAlert(state)
    const searchterm = activeAlert ? activeAlert.searchterm : ''
    return {
      activeAlert,
      searchterm,
      profileTagList: getProfileTagList(state),
    }
  },
  buildActionCreators({
    onSearchFilterAdded: ActionTypes.ALERT_ADD_FILTER,
    onSearchFilterToggled: ActionTypes.ALERT_TOGGLE_FILTER,
    onSearchFilterRemoved: ActionTypes.ALERT_REMOVE_FILTER,
    onClearAll: ActionTypes.ALERT_CLEAR_ALL,
    onSearchTermChanged: ActionTypes.ALERT_SEARCHTERM_CHANGED,
    removeActiveAlert: ActionTypes.ALERT_REMOVE_ACTIVE,
    initMifluzIdLists: ActionTypes.ALERT_INIT_MIFLUZ,
  }),
  // @ts-ignore
)(ProfileInput)

export default ProfileInput

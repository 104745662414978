import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import * as R from 'ramda'
import crossfilter from 'crossfilter2'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'

import { apiToLocaleTypes } from '../../opoint/statistics/index'
import buildActionCreators from '../../helpers/buildActionCreators'
import CrossfilterGraph from './CrossfilterGraph'
import SwitchableGraph from './SwitchableGraph'
import OpointDate from '../../opoint/common/time'
import Translate from '../../Translate'
import {
  getChangedAspectsCountBy,
  getChangedAspectsType,
  getCountBy,
  getSelectedAspects,
  getStatDocuments,
  getStatFilters,
} from '../../selectors/statisticsSelectors'
import { getSearchMeta } from '../../selectors/searchSelectors'
import * as ActionTypes from '../../constants/actionTypes'

class StatisticsContainer extends React.Component<any, any> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  cfDocuments: any
  dimension: any

  constructor(props) {
    super(props)
    const { documents } = props

    this.cfDocuments = crossfilter(documents || [])
    this.dimension = this.cfDocuments.dimension((d) => d)
  }

  componentWillUpdate(nextProps) {
    if (this.props.documents !== nextProps.documents) {
      this.cfDocuments = crossfilter(nextProps.documents)
      // number of dimensions is limited to 32 so we need not to create new one every time
      // but only if new crossfilter is created
      this.dimension = this.cfDocuments.dimension((d) => d)
    }

    const articles = R.map(
      (article) => ({
        id_article: article.id_article,
        id_site: article.id_site,
        time: article.date,
      }),
      this.dimension.top(Infinity),
    )

    this.props.updateArticles({ articles })
  }

  componentDidUpdate = (prevProps) => {
    if (!prevProps.exportPDFtrigger && this.props.exportPDFtrigger) {
      // Request API to generate PDF doc
      this.getSVGs()
      // Reset trigger value to false
      this.props.resetExportPDFTrigger()
    }
  }

  getSVGs = () => {
    const { exportPDF, searchMeta } = this.props
    const { i18n } = this.context
    const { foundDocumentsCount, rangeStart, rangeEnd } = searchMeta

    const hasEndTime = Math.abs(moment(rangeEnd).diff(moment()) / 60000) > 5
    const rangeStartHumanFormat = OpointDate.humanFormat(moment(rangeStart))
    const rangeEndHumanFormat = OpointDate.humanFormat(moment(rangeEnd))

    const metaString = hasEndTime
      ? i18n.t('{{documentsCount}} articles from {{rangeStartHumanFormat}} to {{rangeEndHumanFormat}}', {
          documentsCount: foundDocumentsCount,
          rangeStartHumanFormat,
          rangeEndHumanFormat,
        })
      : i18n.t('{{documentsCount}} articles from {{rangeStartHumanFormat}}', {
          documentsCount: foundDocumentsCount,
          rangeStartHumanFormat,
        })

    return exportPDF({
      SVGs: [this.mainGraphPdfExportData, ...this.graphsPdfExportData.values()]
        .filter((
          { svg }, // cut off emty svgs
        ) => svg())
        .map(({ svg, title, width }) => ({
          svg: svg(),
          title: title(),
          width: width(),
        })),
      metaString,
    })
  }

  getPdfExportData = (getSvgString, { title, width, getName }) =>
    this.graphsPdfExportData.set(getName(), {
      svg: getSvgString,
      title,
      width,
    })

  getMainGraphPdfExportData = (getSvgString, { title, width }) => {
    const { i18n } = this.context

    this.mainGraphPdfExportData = {
      svg: getSvgString,
      title: () => i18n.t('Main graph'),
      width: () => 'MAIN_GRAPH',
    }
  }

  graphsPdfExportData: any = new Map()
  mainGraphPdfExportData = {}

  render() {
    const {
      countBy,
      filters,
      filterChange,
      aspects,
      updateFilterType,
      changedAspectsType,
      updateFilterCountBy,
      changedAspectsCountBy,
    } = this.props

    if (this.cfDocuments.size()) {
      return (
        <div className="charts">
          <CrossfilterGraph
            title="main-graph"
            values={this.cfDocuments}
            filter={filters.time}
            onFilterChange={filterChange}
            countBy={countBy}
            getPdfExportData={this.getMainGraphPdfExportData}
          />
          {aspects.map((aspect) => (
            <SwitchableGraph
              key={aspect.id}
              aspect={aspect}
              // @ts-ignore
              values={this.cfDocuments}
              // @ts-ignore
              commonDimension={this.dimension}
              filter={filters[aspect.id]}
              onFilterChange={filterChange}
              countBy={countBy}
              aspectType={changedAspectsType ? apiToLocaleTypes[changedAspectsType[aspect.id]] : undefined}
              aspectCountBy={changedAspectsCountBy[aspect.name]}
              updateFilterType={updateFilterType}
              updateFilterCountBy={updateFilterCountBy}
              getPdfExportData={this.getPdfExportData}
            />
          ))}
        </div>
      )
    }
    return (
      <div className="text-center">
        <Translate i18nString="No data found for this search" />
      </div>
    )
  }
}

// @ts-ignore
StatisticsContainer = translate([], { wait: true })(StatisticsContainer)

export default connect(
  (state: any) => ({
    aspects: getSelectedAspects(state),
    changedAspectsCountBy: getChangedAspectsCountBy(state),
    changedAspectsType: getChangedAspectsType(state),
    countBy: getCountBy(state),
    documents: getStatDocuments(state),
    exportPDFtrigger: state.statistics.exportPDFtrigger,
    filters: getStatFilters(state),
    searchMeta: getSearchMeta(state),
  }),
  buildActionCreators({
    exportPDF: ActionTypes.STATISTICS_VIEW_EXPORT_PDF,
    filterChange: ActionTypes.STATISTICS_FILTER_CHANGED,
    resetExportPDFTrigger: ActionTypes.STATISTICS_VIEW_EXPORT_PDF_RESET_TRIGGER,
    updateArticles: ActionTypes.STATISTICS_UPDATE_FILTERED_ARTICLES,
    updateFilterCountBy: ActionTypes.STATISTICS_UPDATE_FILTER_COUNTBY,
    updateFilterType: ActionTypes.STATISTICS_UPDATE_FILTER_TYPE,
  }),
)(StatisticsContainer)

import React from 'react'
import Rx from 'rxjs'
import { translate } from 'react-i18next'

import Loader from '../common/Loader'
import ReportsError from './ReportsError'
import Translate from '../../Translate'
import { REPORT_STATUS } from '../../opoint/reports/index'
import { TEMPLATE_TYPES } from '../../opoint/templates/index'

const zeroArticlesReportsError = ({ active, i18n }) => <ReportsError active={active} error={i18n.t('Zero articles')} />
const ZeroArticlesReportsError = translate([], { wait: true })(zeroArticlesReportsError)

const invalidReportError = ({ active, i18n }) => (
  <ReportsError active={active} error={i18n.t('Not valid report request')} />
)
const InvalidReportError = translate([], { wait: true })(invalidReportError)

type Props = {
  title: string
  reportObject: any
  includeAllText: boolean
  createFn: (params: any) => void
  setTitleFn: () => void
  setPrefaceFn: () => void
  setFooterFn: () => void
  loadArticles: () => void
  groupedArticles: any
  error: boolean
  activeTemplate: {
    id: number
    type: number
  }
}

type State = {
  optionsVisible: boolean
}

export default class ReportsCreate extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      optionsVisible: false,
    }

    const { setTitleFn, setPrefaceFn, setFooterFn } = this.props

    this.input$ = new Rx.Subject()
    this.input$.debounceTime(150).subscribe(setTitleFn)

    this.preface$ = new Rx.Subject()
    this.preface$.debounceTime(150).subscribe(setPrefaceFn)

    this.footer$ = new Rx.Subject()
    this.footer$.debounceTime(150).subscribe(setFooterFn)
  }

  componentDidMount() {
    const { groupedArticles, loadArticles } = this.props
    if (!groupedArticles) {
      loadArticles()
    }
  }

  onOptionsClick = () => {
    this.setState({ optionsVisible: !this.state.optionsVisible })
  }

  onCreateClickHandler = () => {
    this.props.createFn({ groupidentical: !this.props.includeAllText })
  }

  input$: any
  preface$: any
  footer$: any

  handleTitleChange = (event: any) => {
    event.persist()
    const {
      target: { value },
    } = event

    this.input$.next(value)
  }

  handlePrefaceChange = (event: any) => {
    event.persist()
    const {
      target: { value },
    } = event

    this.preface$.next(value)
  }

  handleFooterChange = (event: any) => {
    event.persist()
    const {
      target: { value },
    } = event

    this.footer$.next(value)
  }

  render() {
    const { title, error, reportObject, groupedArticles, activeTemplate } = this.props
    const { optionsVisible } = this.state

    return (
      <div className="op-modal-report-body-content">
        <InvalidReportError active={error} />
        {!groupedArticles && <Loader />}
        {groupedArticles && (
          <div>
            <ZeroArticlesReportsError active={!Object.values(groupedArticles).length} />
            {!!Object.values(groupedArticles).length && (
              <div>
                {(reportObject && reportObject.status === REPORT_STATUS.IN_PROGRESS) || (
                  <div className="create__form">
                    <h3>
                      <Translate i18nString="Set report title" />
                    </h3>
                    <input type="text" className="op-input" defaultValue={title} onChange={this.handleTitleChange} />
                    {TEMPLATE_TYPES.XLS !== activeTemplate.type && (
                      <div className="op-report-create-options">
                        <div className="op-report-create-options-toggle" onClick={this.onOptionsClick}>
                          {!optionsVisible && (
                            <div>
                              <span>
                                <Translate i18nString="Abstract" />
                              </span>
                              <i className="op-icon-arrow-down" />
                            </div>
                          )}
                        </div>
                        {optionsVisible && (
                          <div className="op-report-create-options-wrapper">
                            <label htmlFor="report-preface">
                              <Translate i18nString="Preface" />
                            </label>
                            <textarea id="report-preface" className="op-input" onChange={this.handlePrefaceChange} />
                            {TEMPLATE_TYPES.HTML === activeTemplate.type && (
                              <div>
                                <label htmlFor="report-footer">
                                  <Translate i18nString="Footer" />
                                </label>
                                <textarea id="report-footer" className="op-input" onChange={this.handleFooterChange} />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}

                    <button disabled={error} className="op-button green" onClick={this.onCreateClickHandler}>
                      <Translate i18nString="Create" />
                    </button>
                  </div>
                )}
                {reportObject && reportObject.status === REPORT_STATUS.IN_PROGRESS && (
                  <div className="modal-body-actions">
                    <div className="modal-body-action-view">
                      <div className="util_center-parent mod-full-all">
                        <div className="util_center-child">
                          <h3>
                            <Translate i18nString="Generating report" />
                          </h3>
                          <p>
                            <Translate i18nString="You can safely close the modal" />
                            .
                            <br />
                            <Translate i18nString="You will be notified when the report is available" />.
                          </p>
                          <Loader />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <ZeroArticlesReportsError active={reportObject && reportObject.status === REPORT_STATUS.ERROR} />
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

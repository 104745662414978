import secretServerTime from '../auth/secret'

type Params = {
  headers: {
    accept: string
    'accept-language': string
    'content-type': string
    authorization?: string
  }
  responseType: 'json'
}

export const LOAD_WATCH_INDEX_ARTICLES_REFRESH_LIMIT = 20

export default class OpointConfig {
  static get jwtAuthToken(): string {
    // @ts-ignore
    return window.localStorage.getItem('ls.OP-PORTAL-AUTH-TOKEN')
  }

  static getUrl(path: string): string {
    return process.env.REACT_APP_API === 'development' || process.env.NODE_ENV === 'development'
      ? `https://m360-dev.opoint.com${path}`
      : `https://m360.opoint.com${path}`

    // return `https://m360.opoint.com${path}`
  }

  static calculateSecret = (timestamp?: number): string => {
    if (!timestamp) {
      return ''
    }
    const timeslot = Math.floor(timestamp / 300)
    const t = (((timeslot % 16147561) * (13 * timeslot + 31)) % 16147561) % 16147561
    /* eslint-disable-next-line no-bitwise */
    const secret = btoa(
      `${String.fromCharCode((t >> 16) % 256)}${String.fromCharCode((t >> 8) % 256)}${String.fromCharCode(t % 256)}`,
    )

    return secret
  }

  /* eslint-disable quote-props */
  static getRequestHeaders(requireAuth: boolean = true): Params {
    const params: Params = {
      headers: {
        accept: 'application/json',
        'accept-language': 'en-GB',
        'content-type': 'application/json',
      },
      responseType: 'json',
    }
    if (requireAuth) {
      // @ts-ignore
      const secret = this.calculateSecret(secretServerTime.getTime())
      params.headers.authorization = `JWT ${this.jwtAuthToken}${secret}`
    }

    return params
  }
}

import React from 'react'
import classNames from 'classnames'
import Immutable from 'immutable' /* eslint-disable-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types'
import Draft, { ContentState, Editor, EditorState, getSafeBodyFromHTML, RichUtils } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'

import { isTextValid } from '../../../helpers/editText'
import Translate from '../../../Translate'

const blockRenderMap = Immutable.Map({
  header: {
    element: 'h',
  },
  'header-one': {
    element: 'h1',
  },
  paragraph: {
    element: 'p',
  },
  unstyled: {
    element: 'span',
  },
})

export default class EditableText extends React.Component<any, any> {
  static propTypes = {
    onContentChange: PropTypes.func.isRequired,
  }

  static contextTypes = {
    URLService: PropTypes.object,
  }

  constructor(props) {
    super(props)
    const {
      article: {
        body: { text },
      },
    } = this.props

    this.state = {
      editorState: EditorState.createWithContent(
        isTextValid(text)
          ? ContentState.createFromBlockArray(Draft.convertFromHTML(text, getSafeBodyFromHTML, blockRenderMap))
          : ContentState.createFromText(''),
      ),
    }
  }

  onChange = (editorState) => {
    const { onContentChange } = this.props
    this.setState({ editorState })
    const html = stateToHTML(editorState.getCurrentContent(), {
      blockRenderers: {
        header: (block) => `<h>${block.getText()}</h>`,
      },
    })

    onContentChange({
      body: {
        text: html,
      },
    })
  }

  onHeaderClick = () => {
    if (RichUtils.getCurrentBlockType(this.state.editorState) === 'header') {
      this.onChange(RichUtils.toggleBlockType(this.state.editorState, 'paragraph'))
    } else {
      this.onChange(RichUtils.toggleBlockType(this.state.editorState, 'header'))
    }
  }

  getButtonStylesForBlockType = (blockType) =>
    classNames({ 'is-active': blockType === RichUtils.getCurrentBlockType(this.state.editorState) })

  render() {
    return (
      <div>
        <div className="editor-options">
          <button
            onClick={this.onHeaderClick}
            className={`${this.getButtonStylesForBlockType('header')} op-button-toolbar`}
          >
            <Translate i18nString="Sub-headline" />
          </button>
          {/* {RichUtils.getCurrentBlockType(this.state.editorState)} */}
        </div>
        <Editor editorState={this.state.editorState} onChange={this.onChange} blockRenderMap={blockRenderMap} />
      </div>
    )
  }
}

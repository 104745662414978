import React from 'react'

import { IMAGES } from '../../../constants'
import Translate from '../../common/Translate'
import styles from './SearchImage.module.scss'

const SearchImage = () => (
  <div className={`row ${styles.wrapper}`}>
    <img src={IMAGES.search_icon} width="100" alt="Search" title="Search" />
    <p>
      <Translate text="Search and find with Infomedia" />
    </p>
  </div>
)

export default SearchImage

import React, { SyntheticEvent, memo, ReactNode } from 'react'
import classNames from 'classnames'
import pick from 'ramda/src/pick'
import range from 'ramda/src/range'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import type { InjectedFormProps } from 'redux-form'
import { useTranslation } from 'react-i18next-latest'

import styles from './index.module.scss'
import buildActionCreators from '../../helpers/buildActionCreators'
import Translate from '../common/Translate'
import MultipleInput from '../../components/common/MultipleInput'
import { LISTING_STYLE_NAMES, TIME_OFFSET } from '../../opoint/settings'
import { getProfiles } from '../../selectors/profilesSelectors'
import { getTags } from '../../selectors/tagsSelectors'
import { setBit, clearBit, isBitSet } from '../../opoint/common'
import { languages, locales, TRANSLATE_ARTICLE, TRANSLATION_LINK } from '../../opoint/common/constants'
import Checkbox from '../../new-components/common/Checkbox'
import Icon from '../../new-components/common/Icon'
import {
  getAutoTranslationQuota,
  getAutoTranslationRemainingQuota,
  getOpointLocale,
  getOpointLocaleSetting,
  getSettingsChoices,
  getUISettings,
  canShowEntitiesHightlight,
} from '../../selectors/settingsSelectors'
import { SETTINGS_MODAL_CLOSE, SETTINGS_SAVE } from '../../constants/actionTypes'
import {
  allFilterOptions,
  allMetadataOptions,
  FILTERS_ORDER_DEFAULT,
  autoTranslateLanguages,
} from '../constants/settings'
import SettingToOptions from '../redux-form/SettingToOptions'
import SettingToDragNDropOptions from '../redux-form/SettingToDragNDropOptions'
import { keyValueSerialize, swapSignInString } from '../helpers/common'
import { getChangedValues } from '../helpers/settings'
import type { Profile } from '../types/profile'
import type { Tag } from '../types/tag'
import type { SettingsChoicesKeys, SettingsChoiceValue } from '../types/settings'
import SettingWrapper, { SettingTitle } from './SettingWrapper'
import Radio from '../common/Radio'

const OptGroupProfiles = ({ profiles }) => {
  const { t } = useTranslation()
  return (
    <optgroup label={t('Profiles')}>
      {profiles.map(({ id, name }) => (
        <option key={id} value={JSON.stringify({ id, type: 'profile' })}>
          {name}
        </option>
      ))}
    </optgroup>
  )
}

const OptGroupTags = ({ tags }) => {
  const { t } = useTranslation()
  return (
    <optgroup label={t('Tags')}>
      {tags.map(({ id, name }) => (
        <option key={id} value={JSON.stringify({ id, type: 'tag' })}>
          {name}
        </option>
      ))}
    </optgroup>
  )
}

interface FormValues {
  APP_UI_SETTINGS: {
    AUTOEXPAND_PROFILE_EDITOR_SEARCHLINES: boolean
    NEW_PORTAL_SHOW_METADATA: string
    NEW_PORTAL_SEARCH_FILTERS: string
    NEW_PORTAL_FILTERS: string
  }
  LANGUAGE: string
  APP_DEFAULT_HOME: string
  MAX_GT_ARTICLE_LENGTH: number
  NEW_PORTAL_AUTOLOAD: boolean
  NEW_PORTAL_AUTOSHOW_SUGGESTION: true
  NEW_PORTAL_LAYOUT: 0 | 1 | 2 | 3 | 4
  NEW_PORTAL_RELATIVE_DATE_LIMIT: number
  TRANSLATE_CFG: string
  TRANSLATE_TO: string
  TRANSLATE_TYPE: number
  COLORBAR_COLORS: 1 | 2 | 3 | 4 | 5 | 6 | 7
  ENTITIES_SELECTED: 0 | 1
  GT_QUOTA: {
    google_translated: number
    quota: number
    total_translated: number
  }
}

type FieldType = {
  name: string
  type: string
  count: number
}

type RegisteredFieldName =
  | 'APP_DEFAULT_HOME'
  | 'APP_UI_SETTINGS.AUTOEXPAND_PROFILE_EDITOR_SEARCHLINES'
  | 'APP_UI_SETTINGS.NEW_PORTAL_FILTERS'
  | 'APP_UI_SETTINGS.NEW_PORTAL_SEARCH_FILTERS'
  | 'APP_UI_SETTINGS.NEW_PORTAL_SHOW_METADATA'
  | 'COLORBAR_COLORS'
  | 'LANGUAGE'
  | 'NEW_PORTAL_AUTOLOAD'
  | 'NEW_PORTAL_AUTOSHOW_SUGGESTION'
  | 'NEW_PORTAL_LAYOUT'
  | 'NEW_PORTAL_RELATIVE_DATE_LIMIT'
  | 'TRANSLATE_CFG'
  | 'TRANSLATE_TYPE'
  | 'ENTITIES_SELECTED'

interface RegisteredFields {
  APP_DEFAULT_HOME: FieldType
  'APP_UI_SETTINGS.AUTOEXPAND_PROFILE_EDITOR_SEARCHLINES': FieldType
  'APP_UI_SETTINGS.NEW_PORTAL_FILTERS': FieldType
  'APP_UI_SETTINGS.NEW_PORTAL_SEARCH_FILTERS': FieldType
  'APP_UI_SETTINGS.NEW_PORTAL_SHOW_METADATA': FieldType
  COLORBAR_COLORS: FieldType
  LANGUAGE: FieldType
  NEW_PORTAL_AUTOLOAD: FieldType
  NEW_PORTAL_AUTOSHOW_SUGGESTION: FieldType
  NEW_PORTAL_LAYOUT: FieldType
  NEW_PORTAL_RELATIVE_DATE_LIMIT: FieldType
  TRANSLATE_CFG: FieldType
  TRANSLATE_TYPE: FieldType
  ENTITIES_SELECTED: FieldType
}

interface SettingsProps {
  canShowEntitiesHightlight: boolean
  saveSettings: (payload: any) => void
  change: (key: RegisteredFieldName, value: any) => void
  settingsModalClose: () => void
  profiles: Profile[]
  tags: Tag[]
  locale: string
  remainingQuota: number
  quota: number
  initialValues: FormValues
  settingsForm: {
    initial: FormValues
    registeredFields: RegisteredFields
    values: FormValues
  }
  choices: {
    [K in SettingsChoicesKeys]?: SettingsChoiceValue[]
  }
}

const SettingsFormComponent: React.FC<InjectedFormProps<FormValues, SettingsProps> & SettingsProps> = ({
  profiles,
  tags,
  settingsForm,
  locale,
  quota,
  remainingQuota,
  choices,
  canShowEntitiesHightlight,
  initialValues,
  saveSettings,
  settingsModalClose,
  change,
}: SettingsProps) => {
  const { t } = useTranslation()

  if (!settingsForm) {
    return null
  }

  const { TRANSLATE_TYPE, TRANSLATE_CFG, TRANSLATE_TO } = settingsForm.values

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault()
    saveSettings({ settings: getChangedValues(initialValues, settingsForm.values) })
    settingsModalClose()
  }

  const changeTranslationType = (type) => ({ target }) => {
    const newType = target.checked ? setBit(TRANSLATE_TYPE, type) : clearBit(TRANSLATE_TYPE, type)
    change('TRANSLATE_TYPE', newType)
  }

  const changeLangFilterType = (type) => ({ target }) => {
    const newCfg =
      target.checked && ['blacklist', 'whitelist'].includes(type) ? swapSignInString(TRANSLATE_CFG) : TRANSLATE_CFG
    change('TRANSLATE_CFG', newCfg)
  }

  const changeLangsFilterList = (values) => {
    const sign = TRANSLATE_CFG[0]
    const newCfg = sign + values.map(({ id }) => id).join(':')
    change('TRANSLATE_CFG', newCfg)
  }

  const languagesOptions = autoTranslateLanguages.map((langObj) => ({
    id: Object.keys(langObj)[0],
    name: Object.values(langObj)[0],
  }))

  let langsFilterType = 'none'

  const translateType = TRANSLATE_TYPE || 0

  if (TRANSLATE_CFG && TRANSLATE_CFG[0] === '-') {
    langsFilterType = 'blacklist'
  }
  if (TRANSLATE_CFG && TRANSLATE_CFG[0] === '+') {
    langsFilterType = 'whitelist'
  }

  const languagesSelected = languagesOptions.filter(({ id }) => TRANSLATE_CFG?.includes(id))

  const fullWidth = (content: ReactNode) => <div className={styles.full}>{content}</div>
  const halfWidth = (content: ReactNode) => <div className={styles.half}>{content}</div>

  const renderLanguage = () => (
    <SettingWrapper title="Language">
      {fullWidth(
        <Field name="LANGUAGE" component="select" className="op-select mod-login">
          <option key="browser" value="browser">
            {t('Browser language')}
          </option>
          {Object.entries(locales).map(([key, { name }]) => (
            <option key={key} value={key}>
              {t(name)}
            </option>
          ))}
        </Field>,
      )}
    </SettingWrapper>
  )

  const renderRemainingQuota = () => {
    const remaining = remainingQuota.toLocaleString(locale)
    const total = quota.toLocaleString(locale)
    return (
      <div className="op-note mod_info">
        <Icon name="info" />
        {`${t('Your remaining quota is')} ${remaining} ${t('out of')} ${total} ${t('characters')}.`}
      </div>
    )
  }

  const renderTranslations = () => (
    <SettingWrapper title="Automatic translations">
      {halfWidth(
        <Checkbox
          label={<Translate text="Translate articles, reports and alerts" />}
          checked={isBitSet(translateType, TRANSLATE_ARTICLE)}
          onChange={changeTranslationType(TRANSLATE_ARTICLE)}
          disabled={!remainingQuota}
        />,
      )}
      {halfWidth(
        <Checkbox
          label={<Translate text="Show link to google translate on articles" />}
          checked={isBitSet(translateType, TRANSLATION_LINK)}
          onChange={changeTranslationType(TRANSLATION_LINK)}
        />,
      )}
      {!remainingQuota &&
        halfWidth(
          <div className="op-note mod_warning">
            <Icon name="notification" />
            <Translate text="You have no translation quota available" />.
          </div>,
        )}
      {Boolean(translateType && (remainingQuota || isBitSet(translateType, TRANSLATION_LINK))) && (
        <>
          {fullWidth(<hr />)}
          {fullWidth(
            <>
              <SettingTitle text="Translate to" />
              <Field name="TRANSLATE_TO" id="TRANSLATE_TO" component="select" className="op-select mod-login">
                {languagesOptions.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {t(name)}
                  </option>
                ))}
              </Field>
            </>,
          )}
          {fullWidth(<hr />)}
          {halfWidth(
            <Radio
              label={<Translate text="Translate from all languages except the following" />}
              checked={langsFilterType === 'blacklist'}
              onChange={changeLangFilterType('blacklist')}
              name="autoTranslateFilterType"
            />,
          )}
          {halfWidth(
            <Radio
              label={<Translate text="Translate only from the following languages" />}
              name="autoTranslateFilterType"
              checked={langsFilterType === 'whitelist'}
              onChange={changeLangFilterType('whitelist')}
            />,
          )}
          {fullWidth(<hr />)}
          {fullWidth(
            <>
              <SettingTitle
                text={langsFilterType === 'blacklist' ? 'Blacklist of languages' : 'Whitelist of languages'}
              />
              <div
                className={classNames({
                  excluded: langsFilterType === 'blacklist',
                  included: langsFilterType === 'whitelist',
                })}
              >
                <MultipleInput
                  values={languagesSelected}
                  options={languagesOptions.filter(({ id }) => id !== TRANSLATE_TO)}
                  placeholder={
                    {
                      whitelist: t('Translate language'),
                      blacklist: t("Don't translate language"),
                    }[langsFilterType]
                  }
                  onChange={changeLangsFilterList}
                />
              </div>
            </>,
          )}
          {Boolean(isBitSet(translateType, TRANSLATE_ARTICLE) && remainingQuota) && (
            <>
              {fullWidth(<hr />)}
              {fullWidth(
                <>
                  <SettingTitle text="Maximum number of characters to translate in each article" />
                  <div className="row">
                    <div className="op-form-group col-md-6">
                      <Field
                        name="MAX_GT_ARTICLE_LENGTH"
                        component="input"
                        type="number"
                        min="0"
                        max="Infinity"
                        step="100"
                      />
                    </div>
                    {renderRemainingQuota()}
                  </div>
                </>,
              )}
            </>
          )}
        </>
      )}
      <Field name="TRANSLATE_TYPE" component="input" type="hidden" />
      <Field name="TRANSLATE_CFG" component="input" type="hidden" />
    </SettingWrapper>
  )

  const renderAutoloadArticles = () => (
    <SettingWrapper>
      {fullWidth(
        <Checkbox
          label={<Translate text="Autoload articles" />}
          checked={settingsForm.values.NEW_PORTAL_AUTOLOAD}
          onChange={() => change('NEW_PORTAL_AUTOLOAD', !settingsForm.values.NEW_PORTAL_AUTOLOAD)}
        />,
      )}
    </SettingWrapper>
  )

  const renderHighlightEntities = () => (
    <SettingWrapper>
      {fullWidth(
        <Checkbox
          label={<Translate text="Highlight entities in text" />}
          checked={settingsForm.values.ENTITIES_SELECTED === 1}
          onChange={() => change('ENTITIES_SELECTED', settingsForm.values.ENTITIES_SELECTED === 1 ? 0 : 1)}
        />,
      )}
    </SettingWrapper>
  )

  const renderLayout = () => (
    <SettingWrapper title="Layout">
      {fullWidth(
        <Field name="NEW_PORTAL_LAYOUT" component="select" className="op-select mod-login">
          {choices.NEW_PORTAL_LAYOUT.map(({ value }) => (
            <option value={value}>{LISTING_STYLE_NAMES[value]}</option>
          ))}
        </Field>,
      )}
    </SettingWrapper>
  )

  const renderMaximumColors = () => (
    <SettingWrapper title="Maximum number of colours for highlighting matches">
      {fullWidth(
        <Field name="COLORBAR_COLORS" component="select" className="op-select mod-login">
          {range(1, 8).map((number) => (
            <option key={number} value={number}>
              {number}
            </option>
          ))}
        </Field>,
      )}
    </SettingWrapper>
  )

  const renderSearchSuggestions = () => (
    <SettingWrapper>
      {fullWidth(
        <Checkbox
          label={<Translate text="Show search suggestions" />}
          checked={settingsForm.values.NEW_PORTAL_AUTOSHOW_SUGGESTION}
          onChange={() => change('NEW_PORTAL_AUTOSHOW_SUGGESTION', !settingsForm.values.NEW_PORTAL_AUTOSHOW_SUGGESTION)}
        />,
      )}
      {settingsForm.values.NEW_PORTAL_AUTOSHOW_SUGGESTION && (
        <>
          {fullWidth(<hr />)}
          {fullWidth(
            <>
              <SettingTitle text="Search suggestions" />
              <Field
                name="APP_UI_SETTINGS.NEW_PORTAL_SEARCH_FILTERS"
                component={SettingToOptions}
                allOptions={allFilterOptions}
              />
            </>,
          )}
        </>
      )}
    </SettingWrapper>
  )

  const renderFilters = () => (
    <SettingWrapper title="Filters">
      {fullWidth(
        <Field
          name="APP_UI_SETTINGS.NEW_PORTAL_FILTERS"
          component={SettingToDragNDropOptions}
          allOptions={allFilterOptions}
        />,
      )}
    </SettingWrapper>
  )

  const renderMetadata = () => (
    <SettingWrapper title="Metadata">
      {fullWidth(
        <Field
          name="APP_UI_SETTINGS.NEW_PORTAL_SHOW_METADATA"
          component={SettingToDragNDropOptions}
          allOptions={allMetadataOptions}
        />,
      )}
    </SettingWrapper>
  )

  const renderHomepage = () => (
    <SettingWrapper title="Home page">
      {fullWidth(
        <Field name="APP_DEFAULT_HOME" component="select" className="op-select mod-login">
          <optgroup label="-----">
            <option value={JSON.stringify({ type: 'search' })}>{t('Search')}</option>,
          </optgroup>
          <OptGroupProfiles profiles={profiles} />
          <OptGroupTags tags={tags} />
        </Field>,
      )}
    </SettingWrapper>
  )

  const renderUseRelativeTime = () => (
    <SettingWrapper title="Use relative time">
      {fullWidth(
        <Field name="NEW_PORTAL_RELATIVE_DATE_LIMIT" component="select" className="op-select mod-login">
          {Object.entries(TIME_OFFSET).map(([_, value]) => (
            <option key={`time-offset-${value.sec}`} value={value.sec}>
              {t(value.label)}
            </option>
          ))}
        </Field>,
      )}
    </SettingWrapper>
  )

  const renderAutoExpandProfile = () => (
    <SettingWrapper>
      {fullWidth(
        <Checkbox
          label={<Translate text="Auto expand profile editor searchlines" />}
          checked={settingsForm.values.APP_UI_SETTINGS.AUTOEXPAND_PROFILE_EDITOR_SEARCHLINES}
          onChange={() =>
            change(
              'APP_UI_SETTINGS.AUTOEXPAND_PROFILE_EDITOR_SEARCHLINES',
              !settingsForm.values.APP_UI_SETTINGS.AUTOEXPAND_PROFILE_EDITOR_SEARCHLINES,
            )
          }
        />,
      )}
    </SettingWrapper>
  )

  return (
    <form className="op-modal-settings" onSubmit={handleSubmit}>
      {renderLanguage()}
      {renderTranslations()}
      {renderAutoloadArticles()}
      {canShowEntitiesHightlight && renderHighlightEntities()}
      {renderLayout()}
      {renderMaximumColors()}
      {renderSearchSuggestions()}
      {renderFilters()}
      {renderMetadata()}
      {renderHomepage()}
      {renderUseRelativeTime()}
      {renderAutoExpandProfile()}
      <div className={styles.submitWrapper}>
        <button type="submit" className="op-button green">
          <Translate text="Save settings" />
        </button>
      </div>
    </form>
  )
}

const SettingsFormRedux = reduxForm<FormValues>({
  form: 'settings',
})(SettingsFormComponent)

export default connect<{}, {}>(
  (state: any) => {
    const { id, type } = state.settings.list.APP_DEFAULT_HOME
    const uiSettings = getUISettings(state)

    const initialValues = {
      APP_UI_SETTINGS: {
        AUTOEXPAND_PROFILE_EDITOR_SEARCHLINES: uiSettings && uiSettings['AUTOEXPAND_PROFILE_EDITOR_SEARCHLINES'],
        NEW_PORTAL_SHOW_METADATA: uiSettings && uiSettings['NEW_PORTAL_SHOW_METADATA'],
        NEW_PORTAL_SEARCH_FILTERS: uiSettings && uiSettings['NEW_PORTAL_SEARCH_FILTERS'],
        NEW_PORTAL_FILTERS:
          (uiSettings && uiSettings['NEW_PORTAL_FILTERS']) ||
          keyValueSerialize(FILTERS_ORDER_DEFAULT.map((filter) => ({ key: filter, value: 'true' }))),
      },
      LANGUAGE: getOpointLocaleSetting(state),
      APP_DEFAULT_HOME: JSON.stringify({ id, type }),
      ...pick(
        [
          'COLORBAR_COLORS',
          'NEW_PORTAL_AUTOLOAD',
          'NEW_PORTAL_AUTOSHOW_SUGGESTION',
          'NEW_PORTAL_LAYOUT',
          'NEW_PORTAL_RELATIVE_DATE_LIMIT',
          'TRANSLATE_TO',
          'TRANSLATE_TYPE',
          'TRANSLATE_CFG',
          'MAX_GT_ARTICLE_LENGTH',
          'GT_QUOTA',
          'ENTITIES_SELECTED',
        ],
        state.settings.list,
      ),
    }

    return {
      profiles: getProfiles(state),
      tags: getTags(state),
      locale: getOpointLocale(state),
      remainingQuota: getAutoTranslationRemainingQuota(state),
      quota: getAutoTranslationQuota(state),
      initialValues,
      settingsForm: state.form.settings,
      choices: getSettingsChoices(state),
      canShowEntitiesHightlight: canShowEntitiesHightlight(state),
    }
  },
  buildActionCreators({
    settingsModalClose: SETTINGS_MODAL_CLOSE,
    saveSettings: SETTINGS_SAVE,
  }),
)(memo(SettingsFormRedux))

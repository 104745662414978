import PropTypes from 'prop-types'
import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'

import buildActionCreators from '../../../helpers/buildActionCreators'
import ModulePermissions from '../../common/ModulePermissions'
import Translate from '../../../Translate'
import { MODULE, MODULE_OPTION } from '../../../opoint/settings/index'
import { getActiveStatView } from '../../../selectors/statisticsSelectors'
import * as Actions from '../../../constants/actionTypes'

class EditViewPopover extends React.PureComponent<any> {
  static contextTypes = {
    destroyEditViewPopover: PropTypes.func,
    i18n: PropTypes.object,
  }

  getButtons() {
    const { i18n } = this.context

    return [
      {
        onClick: () => this.saveClickHandler(),
        placeholder: i18n.t('Save'),
      },
    ]
  }

  deleteClickHandler = () => {
    const { destroyEditViewPopover } = this.context
    const { deleteView } = this.props

    deleteView()
    destroyEditViewPopover()
  }

  saveClickHandler = () => {
    const { destroyEditViewPopover } = this.context
    const { saveView } = this.props

    saveView()
    destroyEditViewPopover()
  }

  render() {
    const buttons = this.getButtons()

    const { i18n } = this.context

    return (
      <div className="op-tag-edit">
        <div className="row">
          <div className="col-md-12">
            <div className="col-md-6">
              <div className="row">
                <label htmlFor="name">
                  <Translate i18nString="Name" />
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <Field component="input" id="name" name="name" placeholder={i18n.t('Statistic view name')} />
              </div>
            </div>
          </div>
        </div>

        <ModulePermissions module={MODULE.STATISTICS} permissions={[MODULE_OPTION.ON]}>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="button-wrapper">
                  {buttons.map((button, key) => (
                    <button className="op-button" key={key} onClick={button.onClick} type="button">
                      {button.placeholder}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </ModulePermissions>
      </div>
    )
  }
}

// @ts-ignore
EditViewPopover = translate([], { wait: true })(EditViewPopover)

// @ts-ignore
EditViewPopover = reduxForm({
  form: 'view.edit',
  keepDirtyOnReinitialize: false,
  enableReinitialize: true,
  // @ts-ignore
})(EditViewPopover)

export default connect(
  (state) => ({
    initialValues: { name: (getActiveStatView(state) && getActiveStatView(state).name) || '' },
  }),
  buildActionCreators({
    saveView: Actions.STATISTICS_VIEW_SAVE,
  }),
)(EditViewPopover)

import React from 'react'
import moment from 'moment'
import classNames from 'classnames'

import OpointDate from '../../opoint/common/time'
import Translate from '../../Translate'

export const TextHeader = ({ text, type }: { text: string; type: string }) => (
  <span className={classNames({ 'mod-danger': type && type === 'warning' })}>{text}</span>
)

type SearchSummaryProps = {
  documentsCount: number
  rangeStart: number
  rangeEnd: number
  openDatepicker: () => void
  statistics: boolean
}

export const SearchSummary = (props: SearchSummaryProps) => {
  const { documentsCount, rangeStart, rangeEnd, openDatepicker, statistics } = props
  if (rangeStart === null || rangeEnd === null) {
    return null
  }
  const hasEndTime = Math.abs(moment(rangeEnd).diff(moment()) / 60000) > 5
  const rangeStartHumanFormat = OpointDate.humanFormat(moment(rangeStart))
  const rangeEndHumanFormat = OpointDate.humanFormat(moment(rangeEnd))

  return (
    <span>
      <i
        style={{ lineHeight: '16px', verticalAlign: 'top' }}
        className="op-icon-calendar icon-clickable"
        onClick={openDatepicker}
      />
      {!statistics && <span>≈ </span>}
      {hasEndTime ? (
        <Translate
          i18nString="{{documentsCount}} articles from {{rangeStartHumanFormat}} to {{rangeEndHumanFormat}}"
          context={{ documentsCount, rangeStartHumanFormat, rangeEndHumanFormat }}
        />
      ) : (
        <Translate
          i18nString="{{documentsCount}} articles since {{rangeStartHumanFormat}}"
          context={{ documentsCount, rangeStartHumanFormat }}
        />
      )}
    </span>
  )
}

import React from 'react'
import classNames from 'classnames'
import R from 'ramda'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'

import buildActionCreators from '../../helpers/buildActionCreators'
import entityFilter from '../common/entityFilter'
import EntityHeader from '../common/EntityHeader'
import Translate from '../../Translate'
import TrashItem from './TrashItem'
import { MODULE } from '../../opoint/settings/index'
import { getUISetting } from '../../selectors/settingsSelectors'
import { getVisibleTrashTagsInListing } from '../../selectors/tagsSelectors'
import * as ActionTypes from '../../constants/actionTypes'
import type { Trash } from '../../opoint/flow'

type TrashListingProps = {
  list: Array<Trash>
  toggleCategory: () => {}
  expanded: boolean
  i18n: any
}

class TrashListing extends React.PureComponent<TrashListingProps> {
  render() {
    const { list: trashTags, expanded, i18n } = this.props

    return (
      <div className="col-md-12">
        <div className="row">
          <div className="entity">
            <EntityHeader
              newEntityTitle={i18n.t('Create new tag')}
              module={MODULE.TRASH}
              newButton={false}
              isExpanded={expanded}
              {...this.props}
            />
            <div
              className={classNames('entity__body', 'entity__body--marginBottom2', {
                'entity_body--hidden': !expanded,
              })}
            >
              {!trashTags.length && <Translate i18nString="No tags" />}
              {trashTags && !!trashTags.length && (
                <ul>
                  {trashTags.map((tag) => (
                    <TrashItem key={tag.id} tag={tag} />
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const TrashListingWrappedConnect = connect(
  (state, props: any) => {
    const { i18n } = props
    const list = getVisibleTrashTagsInListing(state)
    const newList = list.map((tag) => (tag.globalTrash ? R.evolve({ name: R.always(i18n.t(tag.name)) }, tag) : tag))
    return {
      list: newList,
      expanded: getUISetting(props.category)(state),
    }
  },
  buildActionCreators({
    toggleCategory: ActionTypes.TOGGLE_UI_SETTING,
  }),
)(entityFilter(TrashListing))

const TrashListingWrapped = translate([], { wait: true })(TrashListingWrappedConnect)

export default TrashListingWrapped

// @ts-nocheck
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import { translate } from 'react-i18next'

import buildActionCreators from '../../../helpers/buildActionCreators'
import TemplateSelector from '../../templates/TemplateSelector'
import { getAlertTemplatePreview } from '../../../selectors/alertsSelectors'
import { getGroupedTemplates, getTemplateEditName, isPreviewModalOpen } from '../../../selectors/templatesSelectors'
import * as ActionTypes from '../../../constants/actionTypes'
import type { Template } from '../../../opoint/flow'

type Props = {
  // activeTemplate: Template,
  // closeModal: () => void,
  groupedTemplates: {
    [key: number]: Array<Template>
  }
  input: {
    value: Template
    onChange: () => void
  }
  isPreviewModalOpen: boolean
  // previewModalTitle: string,
  // previewModalContent: any,
  // onTemplateChange: () => void,
  openEditorModal: () => void
  preview: any
  fetchTemplate: () => void
  fetchTemplateDetail: () => void
  updateActiveTemplate: () => void
}

function getNewTemplate(props) {
  const { fetchTemplate, template, clearTemplate } = props
  clearTemplate()
  fetchTemplate({ template })
}

class TemplateForm extends React.Component<Props> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.template !== nextProps.template) {
      getNewTemplate(nextProps)
    }
  }

  componentWillUnmount() {
    this.props.clearTemplate()
  }

  onTemplateChange = (template: Template) => {
    const {
      fetchTemplateDetail,
      updateActiveTemplate,
      input: { onChange },
    } = this.props
    const { id, type, readonly } = template

    updateActiveTemplate({ activeTemplate: { id, type, readonly } })
    fetchTemplateDetail({ id })
    onChange(template)
  }

  render() {
    const { i18n } = this.context
    const {
      groupedTemplates,
      input: { value },
      closePreviewModal,
      openPreviewModal,
      isPreviewModalOpen /* eslint-disable-line no-shadow */,
      preview,
      openEditorModal,
      deleteTemplate,
      templateEditName,
    } = this.props

    return (
      <TemplateSelector
        activeTemplate={value}
        closePreviewModal={closePreviewModal}
        groupedTemplates={groupedTemplates}
        previewModalContent={preview}
        isPreviewModalOpen={isPreviewModalOpen}
        previewModalTitle={i18n.t('Preview template')}
        onTemplateChange={this.onTemplateChange}
        openPreviewModal={openPreviewModal}
        showPreviewButtonTitle={i18n.t('Preview')}
        showTemplateSettingsButtonTitle={i18n.t('Edit Template')}
        showDeleteButtonTitle={i18n.t('Delete Template')}
        openEditorModal={openEditorModal}
        deleteTemplate={deleteTemplate}
        templateEditName={templateEditName}
        isEditable
      />
    )
  }
}

TemplateForm = translate([], { wait: true })(TemplateForm)

TemplateForm = connect(
  (state) => {
    const alertAddSelector = formValueSelector('alert.add')
    const alertEditSelector = formValueSelector('alert.edit')

    return {
      groupedTemplates: getGroupedTemplates(state),
      isPreviewModalOpen: isPreviewModalOpen(state),
      preview: getAlertTemplatePreview(state),
      template: alertAddSelector(state, 'template') || alertEditSelector(state, 'template'),
      templateEditName: getTemplateEditName(state),
    }
  },
  buildActionCreators({
    closePreviewModal: ActionTypes.TEMPLATE_PREVIEW_MODAL_CLOSE,
    openPreviewModal: ActionTypes.TEMPLATE_PREVIEW_MODAL_OPEN,
    fetchTemplate: ActionTypes.TEMPLATE_PREVIEW_FETCH,
    clearTemplate: ActionTypes.ALERT_CLEAR_TEMPLATE,
    openEditorModal: ActionTypes.TEMPLATE_EDITOR_FORM_MODAL_OPEN,
    deleteTemplate: ActionTypes.TEMPLATE_DELETE,
    fetchTemplateDetail: ActionTypes.FETCH_TEMPLATE_DETAIL,
    updateActiveTemplate: ActionTypes.UPDATE_ACTIVE_TEMPLATE,
  }),
)(TemplateForm)

export default TemplateForm

import React from 'react'
import PropTypes from 'prop-types'
import R from 'ramda'

import OpointConfig from './opoint/common/config'

export const GetStringContext = React.createContext({ getString: null, URLService: {}, getPlural: null })

export default class GetStringProvider extends React.PureComponent<any> {
  static childContextTypes = {
    getString: PropTypes.func,
    getPlural: PropTypes.func,
    URLService: PropTypes.object,
  }

  getChildContext() {
    return {
      getString: R.identity,
      getPlural: (number, singular, plural) => (number === 1 ? singular : plural),
      URLService: {
        getFullImageUrl,
        getVideoUrl,
        getVideoPosterUrl,
        getPreviewThumbnailUrl,
        getThumbnailUrl,
        getAppleIconUrl,
      },
    }
  }

  render() {
    return <GetStringContext.Provider value={this.getChildContext()}>{this.props.children}</GetStringContext.Provider>
  }
}

function getAppleIconUrl(icon) {
  return OpointConfig.getUrl(`/imageproxy/?path=${icon}`)
}

function getPreviewThumbnailUrl(url, width) {
  return OpointConfig.getUrl(
    `/imageproxy/?opoint_max=true&url=${btoa(unescape(encodeURIComponent(url)))}&opoint_x=${width}`,
  )
}

function getThumbnailUrl(url, width, height) {
  let outputUrl = OpointConfig.getUrl(`/imageproxy/?opoint_crop=true&url=${url}`)
  if (width > 0) {
    outputUrl += `&opoint_x=${width}`
  }
  if (height > 0) {
    outputUrl += `&opoint_y=${height}`
  }
  return outputUrl
}

function getFullImageUrl(url) {
  return OpointConfig.getUrl(`/imageproxy/?url=${btoa(unescape(encodeURIComponent(url)))}`)
}

function getVideoPosterUrl() {
  return 'http://portal.opoint.com/media/images/player'
}

function getVideoUrl() {
  return 'https://m360.opoint.com/recordings_data'
}

import React from 'react'

import Translate from '../../Translate'

type Props = {
  handleFilterToggle: () => void
}

const FiltersDropDownButton = ({ handleFilterToggle }: Props) => (
  <div className="op-content-top-header-rest-user-list-item user-options user-options__filters">
    <div
      className="op-content-top-header-rest-searchbar-button_wrapper-filters util_center-parent  mod-full-height  mod-margin-center u-flex"
      onClick={handleFilterToggle}
    >
      <div className="op-button-search util_center-child">
        <i className="op-icon-filter" />
        <span className="u-margin-left-10">
          <Translate i18nString="Filters" />
        </span>
      </div>
    </div>
  </div>
)

export default FiltersDropDownButton

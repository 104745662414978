import { keyValueParse } from './common'
import { Option } from '../types/settings'

interface OptionWithCheckedState extends Option {
  checked: boolean
}

interface Values {
  [key: string]: any
}

export const createOptionsFromSetting = (options: Option[], setting: string): OptionWithCheckedState[] => {
  return keyValueParse(setting).map(({ key, value }) => ({
    name: getOptionNameByValue(key, options),
    value: key,
    checked: value === 'true',
  }))
}

export const getOptionNameByValue = (value: string, options: Option[]): string =>
  options.find((item) => item.value === value)?.name

// function returns object with only changed values
export const getChangedValues = (prevValues: Values, newValues: Values): Values =>
  Object.keys(newValues)
    .filter((value) => newValues[value] !== prevValues[value])
    .reduce((prev, curr) => ({ ...prev, [curr]: newValues[curr] }), {})

import PropTypes from 'prop-types'
import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import ArticleSourceLineSource from './ArticleSourceLineSource'
import Translate from '../../../Translate'
import { ArticleSourceLineDate } from './ArticleSourceLineDate'
import type { Article } from '../../../opoint/flow'
import { articleId } from '../../../opoint/articles'
import OpointTextWithMatchesAndEntities from '../../../new-components/articles/OpointTextWithMatchesAndEntities'

type Props = {
  article: Article
  showAuthor: boolean
}

export default class ArticleSourceLine extends React.PureComponent<any> {
  static propTypes = {
    dateFormat: PropTypes.string.isRequired,

    sourceIsLink: PropTypes.bool,
    article: PropTypes.object.isRequired,

    showBadge: PropTypes.bool,
    /* eslint-disable-next-line */
    similarity: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    append: PropTypes.string,
  }

  render() {
    const {
      article,
      article: { author },
      dateFormat,
      sourceIsLink,
      showBadge,
      append,
      similarity,
      relative,
      type,
      showSign,
      showAuthor = false,
    } = this.props

    return (
      <div className="source-line">
        <ul className="source-line__list">
          <li>
            <ArticleSourceLineDate
              dateFormat={dateFormat}
              article={article}
              relative={relative}
              type={type}
              showSign={showSign}
            />
          </li>
          <li>
            <ArticleSourceLineSource sourceIsLink={sourceIsLink} article={article} badge={showBadge} />
          </li>
          <li>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-${articleId(article)}`}>
                  <Translate i18nString="{{word_count}} words" context={{ word_count: article.word_count }} />
                </Tooltip>
              }
            >
              <span style={{ background: 'rgb(226, 226, 226)', borderRadius: '10px', padding: '0 5px' }}>
                {article.word_count}
              </span>
            </OverlayTrigger>
          </li>
          {showAuthor && article.author && (
            <li className="source-line__item source-line__item--append">
              {/* For some reason nbsp has to be there, otherwise space will not be added... */}
              <Translate i18nString="Author" />
              :&nbsp;
              <span>
                <OpointTextWithMatchesAndEntities text={author} />
              </span>
            </li>
          )}
          {append && <li className="source-line__item source-line__item--append">{append}</li>}
          {similarity && <li className="source-line__item source-line__item--similarity">{similarity}</li>}
        </ul>
      </div>
    )
  }
}

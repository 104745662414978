import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { DragSource, DropTarget } from 'react-dnd'
import { push } from 'react-router-redux'
import { translate } from 'react-i18next'

import buildActionCreators from '../../helpers/buildActionCreators'
import ModulePermissions from '../common/ModulePermissions'
import { isTagSelected } from '../../selectors/searchSelectors'
import { MODULE, MODULE_OPTION } from '../../opoint/settings/index'
import { searchContext } from '../../selectors/routingSelectors'
import { searchRouteHelper } from '../../helpers/searchRouteHelper'
import { TAG_VISIBILITY, toggleVisibility } from '../../opoint/tags'
import * as Actions from '../../constants/actionTypes'

class TagItem extends React.Component<any, any> {
  static contextTypes = {
    i18n: PropTypes.object,
    showEditTagPopover: PropTypes.func,
    router: PropTypes.object,
  }

  tagRef: any

  constructor(props) {
    super(props)
    this.tagRef = null
  }

  editTagHandler = (e) => {
    const { showEditTagPopover } = this.context
    const { setLastUsedTagId, tag } = this.props

    setLastUsedTagId({ tag })
    showEditTagPopover(e)
  }

  visibilityToggleHandler = () => {
    const { editTag, tag, setHideAllTags } = this.props

    editTag({ tag: toggleVisibility(tag) })
    setHideAllTags(false)
  }

  checkboxClickHandler = () => {
    const { tag, searchContext, push } = this.props
    const { filters } = this.context.router.location.query
    push(`/${searchContext}/?filters=${searchRouteHelper(filters, `tag:${tag.id}`)}`)
  }

  labelClickHandler = () => {
    const { tag, onLabelClick } = this.props

    onLabelClick('search', tag.id)
  }

  render() {
    const {
      tag,
      isChecked,
      dragDir,
      connectDragSource,
      connectDropTarget,
      isDragging,
      isOver,
      canDrop,
      isActive,
    } = this.props

    const { i18n } = this.context

    // TODO: move to different file (opoint?)
    const getTagNameWithCombo = (namedTag) => {
      const re = new RegExp(`(${namedTag.combo})`, 'i')
      return { __html: namedTag.name.replace(re, '<u>$1</u>') }
    }

    const isVisible = tag.visibility === TAG_VISIBILITY.ALWAYS

    if (isDragging) {
      return <li />
    }

    return connectDragSource(
      connectDropTarget(
        <li
          title={tag.name}
          ref={(ref) => {
            this.tagRef = ref
          }}
          className={classNames(
            { 'mod-dnd-hover': isOver && canDrop && !isDragging },
            {
              bottom: isOver && canDrop && !isDragging && dragDir,
              top: isOver && canDrop && !isDragging && !dragDir,
            },
          )}
        >
          <div className="checkbox-container">
            <div>
              <div className="op-checkbox">
                <input type="checkbox" id={tag.id} checked={isChecked} readOnly />
                <label htmlFor={tag.id} onClick={this.checkboxClickHandler} className={tag.color || 'none'} />
              </div>

              <span
                className="checkbox-desc"
                onClick={this.labelClickHandler}
                dangerouslySetInnerHTML={getTagNameWithCombo(tag)}
              />
            </div>
            {isActive && <span style={{ flexShrink: 0, marginRight: 5 }}>(active)</span>}
            <ModulePermissions module={MODULE.TAG} permissions={[MODULE_OPTION.ON, MODULE_OPTION.READ_ONLY]}>
              <ul className={classNames('entity__entryList', { 'entity__entryList--visible-last-icon': isVisible })}>
                <li title={i18n.t('Visibility of tag')} onClick={this.visibilityToggleHandler}>
                  <i className={isVisible ? 'op-icon-eye' : 'op-icon-striked-eye'} />
                </li>
              </ul>
            </ModulePermissions>
          </div>
        </li>,
      ),
    )
  }
}

/**
 * DnD setup
 */
// region Drag'n'Drop implementation
const tagSource = {
  beginDrag(props) {
    return {
      key: props.tag.id,
      index: props.index,
      origin: props.tag.index,
      type: props.tag.type,
    }
  },
  endDrag(props, monitor, component) {
    const {
      tag: { id, index },
    } = props
    const dropTarget = monitor.getDropResult()
    const {
      props: { dragDir },
    } = component

    if (dropTarget) {
      props.dropHandler({
        id,
        oldIndex: index,
        newIndex: dropTarget.newIndex,
        direction: dragDir,
      })
    }
  },
}

const tagTarget = {
  drop(props) {
    const { tag } = props
    return { newIndex: tag.index, name: tag.name }
  },
  canDrop(props, monitor) {
    return props.tag.type === monitor.getItem().type
  },
  hover(props, monitor) {
    const { dragHoverHandler, setDirection, prevOffset, dragDir } = props
    const offset = monitor.getClientOffset()

    if (offset.y <= prevOffset + 5 && offset.y >= prevOffset - 5) {
      return
    }

    const newDir = offset ? offset.y - prevOffset > 0 : null
    if (newDir !== null) {
      if (dragDir === newDir) {
        dragHoverHandler(offset.y)
      } else {
        dragHoverHandler(offset.y)
        setDirection(newDir)
      }
    }
  },
}

function tagDragCollect(connection, monitor) {
  return {
    connectDragSource: connection.dragSource(),
    isDragging: monitor.isDragging(),
  }
}

function tagDropCollect(connection, monitor) {
  return {
    connectDropTarget: connection.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  }
}
// endregion

// @ts-ignore
TagItem = translate([], { wait: true })(TagItem)

/* eslint-disable-next-line import/no-mutable-exports */
let DndTagItem = DragSource('TAG_ITEM', tagSource, tagDragCollect)(TagItem)
DndTagItem = DropTarget('TAG_ITEM', tagTarget, tagDropCollect)(DndTagItem)

DndTagItem = connect(
  // @ts-ignore
  (state, { tag: { id } }) => ({
    isChecked: isTagSelected(id)(state),
    searchContext: searchContext(state),
  }),
  {
    push,
    onLabelClick: (context, id) => push(`/${context}/?filters=tag:${id}`),
    ...buildActionCreators({
      setLastUsedTagId: Actions.SET_LAST_USED_TAG_ID,
      setHideAllTags: Actions.SET_GLOBAL_TAG_VISIBILITY,
      editTag: Actions.EDIT_TAG,
    }),
  },
)(DndTagItem)

export default DndTagItem

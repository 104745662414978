import React, { Component } from 'react'

import OpointDatepicker from '../OpointDatepicker'

class PopperElement extends Component<any> {
  componentDidMount() {
    document.addEventListener('click', this.props.clickOutside, false)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.props.clickOutside, false)
  }

  render() {
    return <OpointDatepicker {...this.props.datePickerProps} />
  }
}

export default PopperElement

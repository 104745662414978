// @ts-nocheck
import React from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'
import moment from 'moment'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'

import buildActionCreators from '../../helpers/buildActionCreators'
import ReportsNavigator from './ReportsNavigator'
import ReportsContent from './ReportsContent/ReportsContent'
import ReportsTemplate from './ReportsTemplate'
import ReportsSort from './ReportsSort/ReportsSort'
import ReportsCreate from './ReportsCreate'
import Translate from '../../Translate'
import ReportsDownloadShare from './ReportDownloadShare'
import { getActiveTemplate, getGroupedTemplates } from '../../selectors/templatesSelectors'
import { getAnalyticsTags } from '../../selectors/analytics'
import { getOpointLocale, getLastReportDate } from '../../selectors/settingsSelectors'
import { getProfiles } from '../../selectors/profilesSelectors'
import { getSelectedArticlesIds } from '../../selectors/articlesSelectors'
import { getTags } from '../../selectors/tagsSelectors'
import { isReportModalOpen } from '../../selectors/uiSelectors'
import {
  getArticles,
  getCompactMode,
  getDefaultSource,
  getDeletedArticles,
  getDisabledSteps,
  getEndDate,
  getFilterMode,
  getGroups,
  getHistory,
  getLoading,
  getReportObject,
  getSaving,
  getSearchContext,
  getShowDeleted,
  getSource,
  getStartDate,
  getStep,
  getTitle,
  isReportFormValid,
  isXlsTemplate,
  useReportHistory,
} from '../../selectors/reportsSelector'
import * as ActionTypes from '../../constants/actionTypes'
import type { Template, Article } from '../../opoint/flow'

class ReportsModal extends React.Component<any, any> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  constructor(props, context) {
    super(props, context)
    const { i18n } = context
    this.state = {
      includeAllText: false,
      reportsStep: [
        {
          label: (
            <div>
              <span>1</span>
              <strong>
                <Translate i18nString="Select Content" />
              </strong>
            </div>
          ),
        },
        {
          label: (
            <div>
              <span>2</span>
              <strong>
                <Translate i18nString="Set Template" />
              </strong>
            </div>
          ),
        },
        {
          label: (
            <div>
              <span>3</span>
              <strong>
                <Translate i18nString="Sort Content" />
              </strong>
            </div>
          ),
        },
        {
          label: (
            <div>
              <span>4</span>
              <strong>
                <Translate i18nString="Create" />
              </strong>
            </div>
          ),
        },
        {
          label: (
            <div>
              <span>5</span>
              <strong>
                <Translate i18nString="Download/Share" />
              </strong>
            </div>
          ),
        },
      ],
      activeSort: { value: 'date.asc', label: 'Date', group: 'Ascending' },
      sortOptions: [
        { value: 'sortBy', label: i18n.t('Sort by') },
        { value: 'date.asc', label: i18n.t('Date'), group: i18n.t('Ascending') },
        { value: 'priority.asc', label: i18n.t('Priority'), group: i18n.t('Ascending') },
        { value: 'date.desc', label: i18n.t('Date'), group: i18n.t('Descending') },
        { value: 'priority.desc', label: i18n.t('Priority'), group: i18n.t('Descending') },
      ],
    }
  }

  componentDidUpdate(prevProps) {
    // If report was just opened, initialize title and source
    if (!prevProps.isOpen && this.props.isOpen) {
      const { i18n } = this.context
      const {
        defaultSource: { type, id },
        setSource,
      } = this.props
      setSource({ type, id, i18n })
    }
  }

  onTemplateChange = ({ id: templateId }: Template) => {
    const { setTemplate } = this.props

    setTemplate({ templateId })
  }

  onStartDateChange = (date) => {
    const { reportsDateStartChange, startDate } = this.props
    const isSame = moment(startDate).isSame(moment(date))

    // Keep same sort content if no changes
    if (isSame) {
      return
    }

    reportsDateStartChange(date)
  }

  onEndDateChange = (date) => {
    const { reportsDateEndChange, endDate } = this.props
    const isSame = moment(endDate).isSame(moment(date))

    // Keep same sort content if no changes
    if (isSame) {
      return
    }

    reportsDateEndChange(date)
  }

  setStep = (step = 0) => {
    const { setStep } = this.props
    setStep({
      step,
    })
  }

  setTitle = (title: string) => {
    const { setTitleFn } = this.props

    setTitleFn({
      title,
    })
  }

  setPreface = (preface: string) => {
    const { setPrefaceFn } = this.props

    setPrefaceFn({
      preface,
    })
  }

  setFooter = (footer: string) => {
    const { setFooterFn } = this.props

    setFooterFn({
      footer,
    })
  }

  loadArticles = (context) => {
    const { loadArticles, isXlsTemplate } = this.props
    loadArticles({
      context,
      groupidentical: isXlsTemplate ? false : !this.state.includeAllText,
    })
  }

  loadInitialArticles = () => {
    const { loadInitialArticles, isXlsTemplate } = this.props
    loadInitialArticles({
      groupidentical: isXlsTemplate ? false : !this.state.includeAllText,
    })
  }

  clearData = () => {
    this.props.clearData()
    this.props.clearRecipients()
  }

  sourceChange = (newSource) => {
    const { i18n } = this.context
    if (!newSource) {
      return
    }
    const { setSource } = this.props
    const [type, id] = newSource.split(':')

    setSource({ type, id: parseInt(id, 10), i18n })
  }

  markToDelete = ({ id_site, id_article }: Article) => {
    const { markToDelete } = this.props

    markToDelete({
      article: {
        id_site /* eslint-disable-line camelcase */,
        id_article /* eslint-disable-line camelcase */,
      },
    })
  }

  editArticle = (document) => {
    const { previewArticleOpen } = this.props
    return previewArticleOpen({ article: document, preview: false })
  }

  previewArticle = (document) => {
    const { previewArticleOpen } = this.props
    return previewArticleOpen({ article: document, preview: true })
  }

  move = (weight, draggedId, hoveredId) => {
    const { manualSort } = this.props

    manualSort({
      weight,
      draggedId,
      hoveredId,
    })
  }

  tagSort = (value) => {
    if (value !== 'sortBy') {
      const { tagSort, isXlsTemplate } = this.props
      const [sortType, sortOrder] = value.split('.')
      tagSort({
        sortOrder,
        sortType,
        groupidentical: isXlsTemplate || !this.state.includeAllText,
      })
    }

    this.setState(({ sortOptions }) => ({
      activeSort: sortOptions.find(({ value: v }) => v === value),
    }))
  }

  useHistory = () => {
    const { reportsUseHistory } = this.props
    return (t) => reportsUseHistory(t === 'history')
  }

  reportShare = () => {
    const { reportShareFn } = this.props
    // this should be refactored with the Share Report module

    reportShareFn({ source: 'REPORTS_MODAL' })
  }

  jumpToNextStep = () => {
    const { step } = this.props

    this.setStep(step + 1)
  }

  includeAllTextToggle = () => {
    this.setState({ includeAllText: !this.state.includeAllText }, this.loadInitialArticles)
  }

  initActiveTemplate = () => {
    const { groupedTemplates, updateActiveTemplate, fetchTemplate } = this.props

    if (groupedTemplates && Object.values(groupedTemplates)[0]) {
      const { id, type, readonly } = Object.values(groupedTemplates)[0][0]
      updateActiveTemplate({ activeTemplate: { id, type, readonly } })
      this.onTemplateChange({ id })
      fetchTemplate({ id })
    }
  }

  render() {
    const {
      activeTemplate,
      analyticsTags,
      changeCompactMode,
      changeFilterMode,
      checkReportHistory,
      compactMode,
      context,
      createFn,
      disabledSteps,
      endDate,
      fetchTemplate,
      filterMode,
      groupedArticles,
      groups,
      isAttachmentShareChecked,
      isOpen,
      isSelected,
      isXlsTemplate /* eslint-disable-line no-shadow */,
      language,
      lastReportDate,
      loading,
      profiles,
      reportObject,
      reportsClose,
      reportsHistory,
      saving,
      selectedShareContacts,
      setMessageShareFn,
      shareReportMessage,
      showDeleted,
      showDeleteToggle,
      source,
      startDate,
      step,
      stepShareNumber,
      tags,
      title,
      toggleAttachmentShareFn,
      toggleShowDeleted,
      updateActiveTemplate,
      updateShareStepFn,
      useReportHistory /* eslint-disable-line no-shadow */,
      valid,
    } = this.props
    const { sortOptions, activeSort, reportsStep, includeAllText } = this.state

    return (
      <Modal show={isOpen} onHide={reportsClose}>
        <Modal.Header>
          <button type="button" className="close" onClick={reportsClose}>
            ×
          </button>
          <h4 className="modal-title">
            <Translate i18nString="Reports" />
          </h4>
        </Modal.Header>
        <Modal.Body className="op-modal-settings">
          <ReportsNavigator
            reportSteps={reportsStep}
            click={this.setStep}
            activeStep={step}
            disabledSteps={disabledSteps}
          />
          {
            [
              <ReportsContent
                analyticsTags={analyticsTags}
                onStartDateChange={this.onStartDateChange}
                onEndDateChange={this.onEndDateChange}
                startDate={moment(startDate)} // why moment instad of string
                endDate={moment(endDate)}
                locale={language}
                sourceChange={this.sourceChange}
                type={useReportHistory ? 'history' : 'date'}
                selectValue={source || {}}
                profiles={profiles}
                tags={tags}
                reportsHistory={reportsHistory}
                reportsHistoryCheck={checkReportHistory}
                changeType={this.useHistory()}
                isSelected={isSelected}
                error={!valid}
                lastReportDate={lastReportDate}
                changeActiveTemplate={this.initActiveTemplate}
              />,
              <ReportsTemplate
                activeTemplate={activeTemplate}
                fetchTemplate={fetchTemplate}
                updateActiveTemplate={updateActiveTemplate}
                onTemplateChange={this.onTemplateChange}
              />,
              <ReportsSort
                editArticle={this.editArticle}
                previewArticle={this.previewArticle}
                loadArticles={this.loadArticles}
                markToDelete={this.markToDelete}
                tagSort={this.tagSort}
                move={this.move}
                groupedArticles={groupedArticles}
                groups={groups}
                loading={loading}
                context={context}
                filterMode={filterMode}
                compactMode={compactMode}
                changeCompactMode={changeCompactMode}
                showDeleted={showDeleted}
                changeFilterMode={changeFilterMode}
                toggleShowDeleted={toggleShowDeleted}
                sortOptions={sortOptions}
                activeSort={activeSort}
                source={source}
                saving={saving}
                isXlsTemplate={isXlsTemplate}
                showDeleteToggle={showDeleteToggle}
                includeAllTextToggle={this.includeAllTextToggle}
                includeAllText={includeAllText}
              />,
              <ReportsCreate
                error={!valid}
                reportObject={reportObject}
                title={title}
                setTitleFn={this.setTitle}
                setPrefaceFn={this.setPreface}
                setFooterFn={this.setFooter}
                loadArticles={this.loadArticles}
                groupedArticles={groupedArticles}
                activeTemplate={activeTemplate}
                createFn={createFn}
                includeAllText={includeAllText}
              />,
              <ReportsDownloadShare
                reportObject={reportObject}
                shareReportMessage={shareReportMessage}
                setMessageShareFn={setMessageShareFn}
                toggleAttachmentShareFn={toggleAttachmentShareFn}
                selectedShareContacts={selectedShareContacts}
                isAttachmentShareChecked={isAttachmentShareChecked}
                updateShareStepFn={updateShareStepFn}
                stepShareNumber={stepShareNumber}
                reportShareFn={this.reportShare}
                clearData={this.clearData}
                reportsClose={reportsClose}
                reportCreate
              />,
            ][step]
          }
          {step !== 3 && step !== 4 && (
            <div className="op-modal-reports-next">
              <button
                disabled={disabledSteps.includes(step + 1) || !valid} // is the next step disabled?
                onClick={this.jumpToNextStep}
                className="op-button green"
              >
                <Translate i18nString="Next" />
              </button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    )
  }
}

ReportsModal = translate([], { wait: true })(ReportsModal)

ReportsModal = connect(
  (state) => {
    const selectedArticles = getSelectedArticlesIds(state) // TODO refactor*

    return {
      activeTemplate: getActiveTemplate(state),
      analyticsTags: getAnalyticsTags(state),
      compactMode: getCompactMode(state),
      context: getSearchContext(state),
      defaultSource: getDefaultSource(state),
      disabledSteps: getDisabledSteps(state),
      endDate: getEndDate(state),
      filterMode: getFilterMode(state),
      groupedArticles: getArticles(state),
      groupedTemplates: getGroupedTemplates(state),
      groups: getGroups(state),
      isAttachmentShareChecked: state.reports.shareReportData.attachment,
      isOpen: isReportModalOpen(state),
      isSelected: selectedArticles && !!selectedArticles.length, // TODO refactor*
      isXlsTemplate: isXlsTemplate(state),
      language: getOpointLocale(state),
      lastReportDate: getLastReportDate(state),
      loading: getLoading(state),
      profiles: getProfiles(state),
      reportObject: getReportObject(state),
      reportsHistory: getHistory(state),
      saving: getSaving(state),
      selectedShareContacts: state.contacts.shareReportContacts, // TODO selectors
      shareReportMessage: state.reports.shareReportData.shareReportMessage,
      showDeleted: getShowDeleted(state),
      showDeleteToggle: !!getDeletedArticles(state).length,
      source: getSource(state),
      startDate: getStartDate(state),
      step: getStep(state),
      stepShareNumber: state.reports.shareReportData.stepNumber,
      tags: getTags(state),
      title: getTitle(state),
      useReportHistory: useReportHistory(state),
      valid: isReportFormValid(state),
    }
  },
  buildActionCreators({
    changeCompactMode: ActionTypes.REPORTS_COMPACT_LISTING_TOGGLE,
    changeFilterMode: ActionTypes.REPORTS_FILTER_MODE,
    checkReportHistory: ActionTypes.REPORTS_HISTORY_CHECK_TOGGLE,
    clearData: ActionTypes.CLEAR_SHARE_REPORT_DATA,
    clearRecipients: ActionTypes.CLEAR_SHARE_REPORT_RECIPIENTS,
    createFn: ActionTypes.REPORTS_CREATE,
    fetchTemplate: ActionTypes.FETCH_TEMPLATE_DETAIL,
    loadArticles: ActionTypes.REPORTS_ARTICLES,
    loadInitialArticles: ActionTypes.REPORTS_LOAD_INITIAL_ARTICLES,
    manualSort: ActionTypes.REPORTS_ARTICLE_SORT,
    markToDelete: ActionTypes.REPORTS_ARTICLE_MARK_TO_DELETE,
    previewArticleOpen: ActionTypes.EDIT_ARTICLE_MODAL_OPEN,
    reportsClose: ActionTypes.REPORTS_MODAL_CLOSE,
    reportsDateEndChange: ActionTypes.REPORTS_DATE_END,
    reportsDateStartChange: ActionTypes.REPORTS_DATE_START,
    reportShareFn: ActionTypes.SHARE_REPORT,
    reportsUseHistory: ActionTypes.REPORTS_REPORT_USE_HISTORY,
    setFooterFn: ActionTypes.REPORTS_FOOTER,
    setMessageShareFn: ActionTypes.SHARE_REPORT_CHANGE_MESSAGE,
    setPrefaceFn: ActionTypes.REPORTS_PREFACE,
    setSource: ActionTypes.REPORTS_SOURCE,
    setStep: ActionTypes.REPORTS_STEP,
    setTemplate: ActionTypes.REPORTS_TEMPLATE,
    setTitleFn: ActionTypes.REPORTS_TITLE,
    tagSort: ActionTypes.REPORTS_SORT_TAG,
    toggleAttachmentShareFn: ActionTypes.SHARE_REPORT_TOGGLE_ATTACHMENT,
    toggleShowDeleted: ActionTypes.REPORTS_SHOW_DELETED_TOGGLE,
    updateActiveTemplate: ActionTypes.UPDATE_ACTIVE_TEMPLATE,
    updateShareStepFn: ActionTypes.SHARE_REPORT_UPDATE_STEP,
  }),
)(ReportsModal)

export default ReportsModal

// @ts-nocheck
import React from 'react'

import Translate from '../../../Translate'
import { stripHTML } from '../../../opoint/common'
import { getHeaderUrls, getLangCodes } from '../../../opoint/articles'
import { languages } from '../../../opoint/common/constants'
import OpointTextWithMatchesAndEntities from '../../../new-components/articles/OpointTextWithMatchesAndEntities'

export default class ArticleHeaderText extends React.PureComponent<any> {
  onChange = (event) => {
    const { onContentChange } = this.props

    onContentChange({ header: { text: event.target.value } })
  }

  render() {
    const { article, editable } = this.props

    const urls: { primary: string; secondary: string } = getHeaderUrls(article)
    const langCodes: { orig: string; dest: string } = getLangCodes(article)
    const otherLanguage = languages[article.translated ? langCodes.orig : langCodes.dest]

    return (
      <div>
        {editable ? (
          <textarea
            className="articleHeader-text"
            type="text"
            value={stripHTML(article.header.text)}
            onChange={this.onChange}
          />
        ) : (
          <h2 className="op-content-article-listing-article-headline">
            <a href={urls.primary || urls.secondary} target="_blank">
              <OpointTextWithMatchesAndEntities text={article.header.text} />
            </a>
          </h2>
        )}
        {article.GTranslate_url && (
          <div className="op-content-article-listing-post-headline-info">
            <a href={urls.secondary} target="_blank" rel="noopener noreferrer">
              (
              <Translate i18nString="In {{lang}}" context={{ lang: otherLanguage }} /> <i className="op-icon-expand" />)
            </a>
          </div>
        )}
      </div>
    )
  }
}

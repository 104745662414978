// @ts-nocheck
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import { Modal } from 'react-bootstrap'

import buildActionCreators from '../../../helpers/buildActionCreators'
import * as ActionTypes from '../../../constants/actionTypes'
import { isLeaveEditAlertModalOpen } from '../../../selectors/uiSelectors'
import Translate from '../../../Translate'

type Props = {
  isOpen: boolean
  closeModal: () => void
  saveAlertChangesAndContinue: () => void
  discardAlertChangesAndContinue: () => void
}

class LeaveEditedAlertModal extends React.PureComponent<Props> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  render() {
    const { isOpen, closeModal, saveAlertChangesAndContinue, discardAlertChangesAndContinue } = this.props
    const { i18n } = this.context

    return (
      <Modal show={isOpen} onHide={closeModal} className="modal-dialog-warning">
        <Modal.Header>
          <button type="button" title={i18n.t('Back to alert')} className="close" onClick={closeModal}>
            ×
          </button>
          <h4 className="modal-title">
            <Translate i18nString="You have unsaved changes" />
          </h4>
        </Modal.Header>
        <Modal.Body>
          <button type="button" className="op-button green" onClick={saveAlertChangesAndContinue}>
            <Translate i18nString="Save changes and continue" />
          </button>
          <button type="button" className="op-button red" onClick={discardAlertChangesAndContinue}>
            <Translate i18nString="Discard changes and continue" />
          </button>
        </Modal.Body>
      </Modal>
    )
  }
}

LeaveEditedAlertModal = translate([], { wait: true })(LeaveEditedAlertModal)

LeaveEditedAlertModal = connect(
  (state) => ({
    isOpen: isLeaveEditAlertModalOpen(state),
  }),
  buildActionCreators({
    closeModal: ActionTypes.ASK_SAVE_EDITED_ALERT_CLOSE,
    saveAlertChangesAndContinue: ActionTypes.SAVE_ALERTS_CHANGES_CONTINUE,
    discardAlertChangesAndContinue: ActionTypes.DISCARD_ALERTS_CHANGES_CONTINUE,
  }),
)(LeaveEditedAlertModal)

export default LeaveEditedAlertModal

import { createSelector } from 'reselect'

import { getRouting as getState } from './rootSelectors'

export const getRoutSearch = createSelector(getState, (routingState) => routingState.locationBeforeTransitions.search)

export const searchContext = createSelector(getState, (routingState) =>
  routingState.locationBeforeTransitions.pathname.startsWith('/statistics/') ? 'statistics' : 'search',
)

export const getPathname = createSelector(getState, (routingState) => routingState.locationBeforeTransitions.pathname)

export const getAlertId = createSelector(getState, (routingState) => {
  const data = routingState.locationBeforeTransitions.pathname.split('/')
  const type = data[1]
  const id = data[2]
  return type === 'alerts' ? parseInt(id, 10) : undefined
})

export const getLocationBeforeTransitions = createSelector(
  getState,
  (routingState) => routingState.locationBeforeTransitions,
)

export const getLocationBeforeTransitionsSearch = createSelector(
  getLocationBeforeTransitions,
  (locationBeforeTransitions) => locationBeforeTransitions.search,
)

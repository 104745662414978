import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classnames from 'classnames'

import buildActionCreators from '../../../../helpers/buildActionCreators'
import Translate from '../../../../Translate'
import { MODULE, MODULE_OPTION } from '../../../../opoint/settings/index'
import { getUserPermissions, getMaxProfiles } from '../../../../selectors/settingsSelectors'
import { getProfileTree, isNewProfile } from '../../../../selectors/profilesSelectors'
import * as ActionTypes from '../../../../constants/actionTypes'
import Tooltip from '../../../../new-components/common/Tooltip'

class SaveProfileButton extends React.Component<any, any> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  profileEditorSaveProfile = () => {
    this.props.profileEditorSaveProfile()
  }

  render() {
    const {
      saveInProgress,
      isPermitted,
      profileName,
      isFromPopover,
      profileTree,
      maxProfiles,
      isNewProfile,
    } = this.props
    const { i18n } = this.context

    const isProfilesLimitReached = profileTree.length >= maxProfiles
    const isNewProfileAndLimitReached = isNewProfile && isProfilesLimitReached

    const limitReachedTooltip = (
      <Tooltip content={`${i18n.t('You have reached your maximum of')} ${maxProfiles} ${i18n.t('profiles')}`}>
        <div className="toolip-trigger" />
      </Tooltip>
    )

    const isDisabled = !profileName || saveInProgress || !isPermitted || isNewProfileAndLimitReached

    let title
    if (!isPermitted) {
      title = i18n.t('You do not have necessary permissions')
    } else if (!profileName) {
      title = i18n.t('Please enter profile name')
    } else if (saveInProgress) {
      title = `${i18n.t('Saving')}...`
    } else {
      title = i18n.t('Save profile')
    }

    return isFromPopover ? (
      <li className={classnames('actionLine-popover-child', { disabled: isDisabled })} title={title}>
        {isNewProfileAndLimitReached && limitReachedTooltip}
        <a onClick={isDisabled ? undefined : this.profileEditorSaveProfile}>
          <i className="op-icon-attachment" />
          <Translate i18nString="Save" />
        </a>
      </li>
    ) : (
      <span title={title} style={{ position: 'relative' }}>
        {isNewProfileAndLimitReached && limitReachedTooltip}
        <button className="op-button-toolbar" onClick={this.profileEditorSaveProfile} disabled={isDisabled}>
          <Translate i18nString="Save" />
          <i className="op-icon-attachment" />
        </button>
      </span>
    )
  }
}

export default connect(
  (state: any) => {
    const userPermissions = getUserPermissions(MODULE.PROFILE)(state)
    const isPermitted = MODULE_OPTION.ON === userPermissions
    return {
      saveInProgress: state.profiles.profileEditorSaveInProgress,
      isPermitted,
      profileName:
        state.form &&
        state.form.profileEditor &&
        state.form.profileEditor.values &&
        state.form.profileEditor.values.profileName,
      isNewProfile: isNewProfile(state),
      profileTree: getProfileTree(state),
      maxProfiles: getMaxProfiles(state),
    }
  },
  buildActionCreators({
    profileEditorSaveProfile: ActionTypes.PROFILE_EDITOR_SAVE_PROFILE,
  }),
)(SaveProfileButton)

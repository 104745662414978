import React from 'react'
import classNames from 'classnames'
import R from 'ramda'
import pretty from 'prettysize'

import OpointDate from '../../opoint/common/time'
import ReportActions from '../common/ReportActions'
import Translate from '../../Translate'
import { TEMPLATE_TYPES } from '../../opoint/templates/index'
import Item from '../reports/ReportsSort/ReportsSortItem'

type HistoryListingItemState = {
  deleteConfirmation: boolean
}

class HistoryListing extends React.Component<any, HistoryListingItemState> {
  constructor(props) {
    super(props)
    this.state = {
      deleteConfirmation: false,
    }
  }

  onDeleteClick = () => {
    const { deleteReport, report } = this.props
    deleteReport({ id: report.id })
  }

  setActiveReport = () => {
    const { setActiveReport, report } = this.props
    setActiveReport({ reportObject: report })
  }

  confirmDelete = () => {
    this.setState({ deleteConfirmation: true })
  }

  cancelDelete = () => {
    this.setState({ deleteConfirmation: false })
  }

  render() {
    const {
      report,
      selectedShareContacts,
      activeReport,
      shareReportMessage,
      setMessageShareFn,
      stepShareNumber,
      isAttachmentShareChecked,
      shareReport,
      updateStep,
      clearData,
      toggleAttachment,
    } = this.props
    const time = OpointDate.longFormat(report.timeFinished * 1000)
    const type = R.compose(
      // @ts-ignore
      R.keys(),
      // @ts-ignore
      R.filter((value, key) => value === report.type),
      // @ts-ignore
    )(TEMPLATE_TYPES)[0]
    return (
      <div className="report-history__item" onClick={this.setActiveReport}>
        <div className="report-history__info">
          <div className="report-history__left">
            <div className="report-history__icon">
              <svg viewBox="0 0 64 64">
                {type === 'XSL' && <use xlinkHref="#icon-xsl" />}
                {type === 'DOC' && <use xlinkHref="#icon-doc" />}
                {type === 'PDF' && <use xlinkHref="#icon-pdf" />}
                {type === 'HTML' && <use xlinkHref="#icon-html" />}
                {type === 'PPT' && <use xlinkHref="#icon-ppt" />}
              </svg>
            </div>
            <div>
              <h3 className="report-history__name">{report.title}</h3>
              <div>
                <Translate i18nString="Pages" /> {report.pdfPages}
              </div>
              <div>
                <span>
                  <Translate i18nString="Size" />:
                </span>{' '}
                {pretty(report.pdfBytes)}{' '}
              </div>
            </div>
          </div>
          <span className="report-history__date">{time}</span>
        </div>
        {activeReport && activeReport.id === report.id && (
          <div className="report-history__edit">
            <div className="report-history__editWrapper">
              <ReportActions
                reportOpened={report}
                selectedContacts={selectedShareContacts}
                shareReportMessage={shareReportMessage}
                changeMessage={setMessageShareFn}
                step={stepShareNumber}
                isAttachmentChecked={isAttachmentShareChecked}
                sendReport={shareReport}
                updateStep={updateStep}
                toggleAttachment={toggleAttachment}
                clearData={clearData}
              />
              <div
                className={classNames('modal-delete modal-delete-history', {
                  'is-show-confirm': this.state.deleteConfirmation,
                })}
              >
                <div className="modal-delete-confirm">
                  <span>
                    <Translate i18nString="Do you really want to delete this report?" />
                  </span>
                  <button className="op-button" onClick={this.cancelDelete}>
                    <Translate i18nString="Cancel" />
                  </button>
                  <button className="op-button" onClick={this.onDeleteClick}>
                    <Translate i18nString="Delete report" />
                  </button>
                </div>
                <div className="modal-delete-button">
                  <button className="op-button" onClick={this.confirmDelete}>
                    <Translate i18nString="Delete report" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default HistoryListing

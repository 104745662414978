import React from 'react'

import { loader } from './index.module.scss'

const Loader = () => (
  <div className={loader}>
    <div />
    <div />
    <div />
  </div>
)

export default Loader

// @ts-nocheck

import React from 'react'

// todo move this to src/componensts/

export type keyMap = {
  [combo: string]: () => void
}

export const KEYS = {
  backspace: 8,
  delete: 46,
  tab: 9,
  enter: 13,
  escape: 27,
  space: 32,
  up: 38,
  down: 40,
  left: 37,
  right: 39,
  comma: 188,
  ctrl: 17,
  alt: 18,
  leftCmd: 91,
  rightCmd: 93,
}

function executeShortcut(fn) {
  typeof fn === 'function' && fn()
}

export class Shortcuts extends React.PureComponent<{ keyMap: keydMap }> {
  componentDidMount() {
    document.body.addEventListener('keydown', this.onKeyDown)
  }

  componentWillUnmount() {
    document.body.removeEventListener('keydown', this.onKeyDown)
  }

  onKeyDown = (e) => {
    const { activeElement } = document
    if (
      activeElement &&
      (activeElement.hasAttribute('tabindex') || ['TEXTAREA', 'INPUT'].includes(document.activeElement.tagName))
    ) {
      return
    }

    const { keyMap } = this.props
    const keyCode = e.which || e.keyCode

    switch (keyCode) {
      case KEYS.space:
      case KEYS.left:
      case KEYS.right:
        executeShortcut(keyMap[keyCode])
        e.preventDefault()
        return false /* eslint-disable-line consistent-return */
      default:
        executeShortcut(keyMap[e.key])
    }
  }

  render() {
    return null
  }
}

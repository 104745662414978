// @ts-nocheck

import React from 'react'

import { highlightWithSuggestionServerHint } from '../common/highlight'
import './AutocompleteItem.css'
import type { Filter } from '../flow'

type Props = {
  suggestion: Filter
  onClickHandler: () => void
}

class AutocompleteItem extends React.PureComponent<Props> {
  onClickHandler = () => {
    const { onClickHandler, suggestion } = this.props
    onClickHandler(suggestion)
  }

  render() {
    const { suggestion } = this.props
    const { typeName, name, hl, url, hlu, match } = suggestion

    const suggestionHtml = { __html: highlightWithSuggestionServerHint(name, hl) }

    return (
      <span className="auto-item" onClick={this.onClickHandler}>
        <span className="auto-item__type">{typeName}:</span>

        <span className="auto-item__name" dangerouslySetInnerHTML={suggestionHtml} />
        {match.includes(2) && (
          <span className="auto-item__url">
            <span> - </span>
            <span
              className="auto-item__url"
              dangerouslySetInnerHTML={{ __html: highlightWithSuggestionServerHint(url, hlu) }}
            />
          </span>
        )}
      </span>
    )
  }
}

export default AutocompleteItem

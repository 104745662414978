import PropTypes from 'prop-types'
import React from 'react'

class EditStatisticViewButton extends React.Component<any, any> {
  static contextTypes = {
    showEditViewPopover: PropTypes.func,
  }

  onClickHandler = (onClick) => (e) => {
    this.props.hidePopover()
    onClick(e)
  }

  statisticsSaveView = (e) => {
    const { showEditViewPopover } = this.context
    showEditViewPopover(e)
  }

  render() {
    const { isFromPopover, keyRef, name } = this.props
    if (isFromPopover) {
      return (
        <li className="actionLine-popover-child" key={keyRef} onClick={this.onClickHandler(this.statisticsSaveView)}>
          <a>
            <i className="op-icon-pencil" />
            {name}
          </a>
        </li>
      )
    }
    return (
      <span>
        <button className="op-button-toolbar" onClick={this.statisticsSaveView}>
          <span>{name}</span>
          <i className="op-icon-pencil" />
        </button>
      </span>
    )
  }
}

export default EditStatisticViewButton

// @ts-nocheck

import R from 'ramda'

import * as ActionTypes from '../constants/actionTypes'
import { TAG_TYPES } from '../opoint/tags'
import { SPECIAL_ASPECTS_IDS, DEFAULT_ASPECT_GROUPS_TO_COMPUTE } from '../opoint/statistics/aspects'
import { EXACT_MODE, FUZZY_MODE } from '../opoint/common/constants'
import type { Action, StatisticAspect } from '../opoint/flow'

type StatisticsState = {
  // TODO flow details @dmytro
  aspects: Array<StatisticAspect>
  documents: Array<any>
  filters: any | {}
  countBy: 'count' | 'circulation' | 'reach' | 'adprice'
  loading: boolean
  computedAspectGroup: number
  aspectsRequested: any
  list: Array<any>
  activeStatView: any | null
  showFilteredArticles: boolean
  filteredArticles: Array<any>
  changedAspectsType: any
  changedAspectsCountBy: any
  extendedStartDate: any | void
  extendedCount: any | void
  exportPDFtrigger: Boolean
}

export const initialState: StatisticsState = {
  aspects: [],
  documents: [],
  filters: {},
  countBy: 'count',
  loading: false,

  computedAspectGroup: DEFAULT_ASPECT_GROUPS_TO_COMPUTE,
  aspectsRequested: [],
  list: [],
  activeStatView: null,
  showFilteredArticles: false,
  filteredArticles: [],
  changedAspectsType: {},
  changedAspectsCountBy: {},
  extendedStartDate: undefined,
  extendedCount: undefined,

  exportPDFtrigger: false,
}

/**
 * This reducer controls how we retrieve statistic results from Opoint's backend.
 */
const statisticsReducer = (state: StatisticsState = initialState, { type, payload }: Action<any>) => {
  switch (type) {
    case ActionTypes.FETCH_STATISTICS: {
      return R.assoc('loading', true)(state)
    }

    case ActionTypes.STATISTICS_SHOW_FILTERED: {
      return R.assoc('showFilteredArticles', true)(state)
    }

    case ActionTypes.STATISTICS_HIDE_FILTERED: {
      return R.assoc('showFilteredArticles', false)(state)
    }

    case ActionTypes.FETCH_STATISTICS_SUCCESS: {
      let {
        response: {
          searchresult: {
            document: documents,
            aspectset: { aspect: aspects },
          },
        },
        preserveAspects /* eslint-disable-line prefer-const */,
      } = payload
      const { isStatView, changedAspectsType, changedAspectsCountBy } = payload

      // process documents:

      /**
       * convert array of aspectId-partId pairs from api format to object
       * example: [[111, 1], [222, 2], [111, 4]] -> {111: [1,4], 222: [2]}
       */
      const aspectsById = (docsAspects) => {
        const aspectsById = {}
        aspects.forEach(({ id }) => {
          // initiate empty arrays first
          aspectsById[id] = []
        })
        docsAspects.forEach(([aspId, partId]) => {
          if (!aspectsById[aspId]) {
            /* eslint-disable-next-line no-console */
            console.error('Bad API response. Aspect %i not in aspectset', aspId)
            aspectsById[aspId] = []
          }
          aspectsById[aspId].push(partId) // add to array
        })
        return aspectsById
      }

      /**
       * Return all names of aspectPart with given id in given aspect
       * @param aspect
       * @param partId
       */
      const namesOfPartById = (aspect, partId) => {
        const aspectPart = aspect.aspectpart.find(({ id }) => +id === +partId)
        if (!aspectPart) {
          /* eslint-disable-next-line no-console */
          console.error('Bad API response. Part %i of aspect %i not found.', partId, aspect.id)
          return []
        }
        return aspectPart.name
      }

      /**
       * Replace multiple aspect part with one aspect parts with same name set
       * eg. if doc has aspect 13 with parts [802, 803] and
       * those parts have names ["B:279626"] and ["B:279625"]
       * and there also exists part 804 which has names ["B:279626", "B:279625"]
       * then replace parts with [804]
       * @param aspectsById
       * @returns {*}
       */

      const mergeMultipleAspectParts = (aspectsById) => {
        R.toPairs(aspectsById).forEach(([aspId, aspectPartIds]) => {
          if (aspectPartIds.length > 1) {
            const aspect = aspects.find(({ id }) => +id === +aspId)
            const isSameSet = (namesA, namesB) =>
              namesA.length === namesB.length && namesA.every((name) => namesB.includes(name))
            const currentAspectPartNames = R.flatten(
              aspectPartIds.map((partId) => {
                const names = namesOfPartById(aspect, partId)
                if (names.length > 1) {
                  throw Error('Cannot merge Aspect with multiple names')
                }
                return names
              }),
            )

            // if some unused aspectPart containing all of aspectParts names exist, use it instead
            const equivalentPart = aspect.aspectpart.find(({ name }) => isSameSet(name, currentAspectPartNames))
            if (equivalentPart) {
              aspectsById[aspId] = [equivalentPart.id]
            }
          }
        })
        return aspectsById
      }

      documents = (documents || []).map((doc) => ({
        ...doc,
        // convert timestamp to Date
        date: new Date(doc.unix_timestamp * 1000),
        // normalize fields by which can articles be summed
        count: 1,
        reach: doc.reach || 0,
        adprice: doc.adprice || 0,
        circulation: doc.circulation || 0,
        // append aspect directly to doc under its id
        ...mergeMultipleAspectParts(aspectsById(doc.aspects)),
      }))

      // process aspects:

      const toObjById = R.indexBy(R.prop('id'))
      const renameProp = (from, to) => (a) => {
        a[to] = a[from]
        delete a[from]
        return a
      }

      const modifyAspects = R.map(
        R.compose(
          (a) => (isStatView ? a : R.assoc('selected', isAspectComputed(a) && isSepEnough(a))(a)),
          (a) => R.assoc('overlap', Math.abs(a.combo || 0))(a),
          (a) => R.assoc('overlapMode', Math.sign(a.combo) || FUZZY_MODE)(a),
          R.assoc('dirty', false),
          R.evolve({
            // modify aspectpart
            aspectpart: R.compose(toObjById, R.map(renameProp('name', 'names'))),
          }),
        ),
      )

      const sortAspects = R.sortWith([
        R.descend(R.prop('sep')), // ...Separation Coefficient (greater is better)
        R.ascend(R.prop('group')), // ...by group (lower is better)
        R.ascend(R.prop('label')), // ...by label alphabetically
      ])

      const computedAspectGroup = aspects.reduce(
        (group, aspect) =>
          /* eslint-disable-next-line no-bitwise */
          group | (isAspectComputed(aspect) ? aspect.group : 0),
        0,
      )

      // this is here only to prevent historically wrongly saved charts from throwing error

      const deselectUncomputedSavedAspects = (aspects) =>
        isStatView ? R.map((a) => R.assoc('selected', isAspectComputed(a) && a.selected)(a))(aspects) : aspects

      aspects = R.compose(deselectUncomputedSavedAspects, sortAspects, modifyAspects)(aspects)

      const preservePreviousProps = (newAspects) => (oldAspects) =>
        newAspects.map((aspect) => {
          const oldAspect = oldAspects.find(({ id }) => id === aspect.id)
          return oldAspect
            ? {
                ...aspect,
                tagLikeEntities: oldAspect.tagLikeEntities,
                selected: oldAspect.selected || wasRequested(state, oldAspect),
              }
            : aspect
        })

      return R.evolve({
        aspects: preserveAspects ? preservePreviousProps(aspects) : R.always(aspects),
        documents: R.always(documents),
        changedAspectsType: R.always(changedAspectsType || {}),
        changedAspectsCountBy: R.always(changedAspectsCountBy || {}),
        /* eslint-disable-next-line no-bitwise */
        computedAspectGroup: (group) => group | computedAspectGroup,
      })(state)
    }

    case ActionTypes.FETCH_STATISTICS_FAILURE: {
      return R.assoc('loading', false)(state)
    }

    case ActionTypes.STATISTICS_EXTEND_TIME_RANGE: {
      const { count, startDate } = payload
      return R.compose(R.assoc('extendedStartDate', startDate), R.assoc('extendedCount', count))(state)
    }

    case ActionTypes.STATISTICS_ASPECT_OVERLAP_CHANGE: {
      const { aspectId, overlap } = payload
      const aspectPos = state.aspects.indexOf(state.aspects.find((asp) => asp.id === aspectId))
      return R.compose(
        R.assocPath(['aspects', aspectPos, 'dirty'], true),
        R.assocPath(['aspects', aspectPos, 'overlap'], overlap),
      )(state)
    }

    case ActionTypes.STATISTICS_ASPECT_OVERLAP_MODE_TOGGLE: {
      const { aspectId } = payload
      const aspectPos = state.aspects.indexOf(state.aspects.find((asp) => asp.id === aspectId))
      // toggle between exact and fuzzy mode
      const toggleMode = (mode) => (mode === EXACT_MODE ? FUZZY_MODE : EXACT_MODE)
      return R.compose(
        R.assocPath(['aspects', aspectPos, 'dirty'], true),
        R.over(R.lensPath(['aspects', aspectPos, 'overlapMode']), toggleMode),
      )(state)
    }

    case ActionTypes.STATISTICS_ASPECT_TOGGLE: {
      const { aspectId, selected } = payload
      return R.evolve({
        aspectsRequested: R.ifElse(() => selected, R.append(aspectId), R.without([aspectId])),
        aspects: R.map(R.when(R.propEq('id', aspectId), R.assoc('selected', selected))),
      })(state)
    }

    case ActionTypes.STATISTICS_CLEAR_REQUESTED_ASPECTS: {
      return R.evolve({
        aspectsRequested: R.always([]),
      })(state)
    }

    case ActionTypes.STATISTICS_CLEAR_EXTENDED_TIME_RANGE:
    case ActionTypes.SEARCH_CHANGE_DATERANGE: {
      return R.compose(R.dissoc('extendedStartDate'), R.dissoc('extendedCount'))(state)
    }

    // filter changed directly by some chart
    case ActionTypes.STATISTICS_FILTER_CHANGED: {
      const { id, filter } = payload

      return (R.isEmpty(filter) ? R.dissocPath(['filters', `${id}`]) : R.assocPath(['filters', id], R.clone(filter)))(
        state,
      )
    }

    case ActionTypes.STATISTICS_CLEAR_FILTERS: {
      return R.evolve({
        filters: R.always({}),
      })(state)
    }

    // filter resetting by reset link in FilterReset
    case ActionTypes.STATISTICS_FILTER_RESET: {
      const { id } = payload
      return R.dissocPath(['filters', `${id}`])(state)
    }

    // all filters resetting by reset filters button in command line
    case ActionTypes.STATISTICS_FILTER_RESET_ALL: {
      return R.evolve({
        filters: R.always({}),
      })(state)
    }

    case ActionTypes.STATISTICS_COUNT_BY_CHANGED: {
      const { by } = payload

      return R.assoc('countBy', by)(state)
    }

    /* case for special aspects (tags, sentiments, profiles and analytics) */
    case ActionTypes.STATISTICS_TAG_LISTS: {
      const { aspects } = state
      let { allTags, profiles, analysis } = payload /* eslint-disable-line prefer-const */

      const tags = allTags.filter(({ type }) => type === TAG_TYPES.KEYWORD).map(toAspectTag)

      const sentiments = allTags.filter(({ type }) => type === TAG_TYPES.MENTOMETER).map(toAspectTag)

      profiles = profiles.map(toAspectTag)
      analysis = analysis.map(toAspectTag)
      const mergeListsById = (newList) => (oldList) =>
        R.values(
          R.merge(
            R.indexBy(R.prop('id'))(newList),
            // old should replace new, because previously selected
            // tags should remain selected
            R.indexBy(R.prop('id'))(oldList),
          ),
        )

      // tag-like-entities should be auto preselected only if more profiles/tags are selected
      // if it is just one -> deselect it
      const deselectSingle = (tagLikeEntities) => {
        const selectedCount = R.countBy(R.prop('selected'))(tagLikeEntities).true
        return R.when(() => selectedCount === 1, R.map(R.assoc('selected', false)))(tagLikeEntities)
      }

      const getTagLikeEntitiesByAspectId = (aspectId) =>
        ({
          [SPECIAL_ASPECTS_IDS.TAG]: tags,
          [SPECIAL_ASPECTS_IDS.PROFILE]: profiles,
          [SPECIAL_ASPECTS_IDS.SENTIMENT]: sentiments,
          [SPECIAL_ASPECTS_IDS.ANALYSIS]: analysis,
        }[aspectId])

      const filterAspects = R.filter((aspect) => {
        switch (aspect.id) {
          case SPECIAL_ASPECTS_IDS.TAG:
            return tags.length > 0
          case SPECIAL_ASPECTS_IDS.SENTIMENT:
            return sentiments.length > 0
          case SPECIAL_ASPECTS_IDS.PROFILE:
            return profiles.length > 0
          case SPECIAL_ASPECTS_IDS.ANALYSIS:
            return analysis.length > 0
          default:
            return true
        }
      })

      aspects.forEach((aspect, i) => {
        // TODO refactor
        if (R.values(SPECIAL_ASPECTS_IDS).includes(aspect.id)) {
          if (aspect.tagLikeEntities) {
            aspects[i] = R.evolve({
              tagLikeEntities: R.compose(mergeListsById, deselectSingle, getTagLikeEntitiesByAspectId)(aspect.id),
            })(aspect)
          } else {
            aspect.tagLikeEntities = R.compose(deselectSingle, getTagLikeEntitiesByAspectId)(aspect.id)
          }
        }
      })
      return R.evolve({
        loading: R.always(false),
        // TODO only clone four special aspect not all of them
        aspects: R.always(R.clone(filterAspects(aspects))),
      })(state)
    }

    case ActionTypes.STATISTICS_ASPECT_TAG_TOGGLED: {
      const { aspectId, tagId } = payload
      const { aspects } = state

      const aspect = aspects.find(({ id }) => id === aspectId)
      const tag = aspect.tagLikeEntities.find(({ id }) => id === tagId)

      tag.selected = !tag.selected

      const aspectIndex = R.indexOf(aspect)(aspects)

      return R.compose(
        R.assocPath(['aspects', aspectIndex, 'tagLikeEntities'], R.clone(aspect.tagLikeEntities)),
        R.assocPath(['aspects', aspectIndex, 'dirty'], true),
      )(state)
    }

    case ActionTypes.STATISTIC_VIEWS_FETCH_SUCCESS:
      return R.assoc('list', payload, state)

    case ActionTypes.STATISTICS_VIEWS_SET_ACTIVE: {
      const { id } = payload
      return R.evolve({
        activeStatView: R.always(id),
      })(state)
    }

    case ActionTypes.STATISTICS_VIEWS_OPEN:
      return R.evolve({
        loading: R.always(true),
      })(state)

    case ActionTypes.STATISTICS_UPDATE_FILTERED_ARTICLES: {
      const { articles } = payload
      return R.evolve({
        filteredArticles: R.always(articles),
      })(state)
    }

    case ActionTypes.STATISTICS_CLEAR_FILTERED_ARTICLES: {
      return R.evolve({
        filteredArticles: R.always([]),
      })(state)
    }

    case ActionTypes.STATISTICS_UPDATE_FILTER_TYPE: {
      const { type, name } = payload
      return R.assocPath(['changedAspectsType', name], type)(state)
    }

    case ActionTypes.STATISTICS_UPDATE_FILTER_COUNTBY: {
      const { countBy, name } = payload
      return R.assocPath(['changedAspectsCountBy', name], countBy)(state)
    }

    case ActionTypes.STATISTICS_VIEW_EXPORT_PDF_TRIGGER: {
      return R.compose(R.assoc('exportPDFtrigger', true), R.assoc('exportPDFloading', true))(state)
    }

    case ActionTypes.STATISTICS_VIEW_EXPORT_PDF_SUCCESS:
    case ActionTypes.STATISTICS_VIEW_EXPORT_PDF_FAILURE: {
      return R.assoc('exportPDFloading', false, state)
    }

    case ActionTypes.STATISTICS_VIEW_EXPORT_PDF_RESET_TRIGGER: {
      return R.assoc('exportPDFtrigger', false, state)
    }

    default:
      return state
  }

  function toAspectTag(aspect) {
    return R.pick(['id', 'name', 'selected'])(aspect)
  }

  function isAspectComputed(aspect) {
    return aspect.comp_part > -1
  }
  function isSepEnough(aspect) {
    return aspect.sep > 30
  }

  function wasRequested(state, aspect) {
    return state.aspectsRequested.includes(aspect.id)
  }
}

export default statisticsReducer

import React from 'react'

type ToolbarHeaderProsp = {
  header: Function
  headerProps: Object
}

export default class ToolbarHeader extends React.PureComponent<any> {
  props: ToolbarHeaderProsp

  render() {
    const { header: HeaderComponent, headerProps } = this.props

    return (
      <div className="row">
        <div className="op-content-toolbar-articles-selected-data">
          <HeaderComponent {...headerProps} />
        </div>
      </div>
    )
  }
}

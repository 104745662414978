import React from 'react'

type Props = {
  content: string | number
  onClick: () => void
}

export default class ButtonSquare extends React.PureComponent<Props> {
  render() {
    const { content, onClick } = this.props

    return (
      <button className="op-button-square" onClick={onClick} type="button">
        <span className="info">{content}</span>
      </button>
    )
  }
}

// @ts-nocheck
import React from 'react'
import R from 'ramda'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'

import { getLanguages, getTimezones } from '../../../opoint/common/constants'
import WhenForm from './WhenForm'
import ToggleCheckbox from '../../common/ToggleCheckbox'
import CopyButton from '../../common/CopyButton'
import ArticlesGroupingLabel from '../../common/ArticlesGroupingLabel'
import TemplateForm from './TemplateForm'
import ProfileInput from './ProfileInput'
import contactsForm from '../../contacts/ContactsInput'
import Translate from '../../../Translate'
import { MAX_AGE_OPTIONS } from '../../../opoint/alerts/index'
// import type { ContactFilter } from '../../../opoint/flow'

const AlertsContactsInput = contactsForm('editedAlertEmail', 2)
const AlertsSmsInput = contactsForm('editedAlertSms', 3)
const reverseValue = (value: any) => !value

type Props = {
  // TODO props types. @dmytro (It's really giant)
  initForm: () => void
  // alertId: number,
  // alertsSms: Array<ContactFilter>,
  autoTranslateOn: any
  autoTranslateReport: any
  autoTranslateLanguage: string
  clearForm: () => void
  clearRecipients: () => void
  copyContentToClipboard: () => void
  copyEmails: () => void
  copySms: () => void
  emailsSuccessfullyCopied: boolean
  smsSuccessfullyCopied: boolean
  contentSuccessfullyCopied: boolean
  recipientsCount: number
  maxAlertRecipients: number
}

class AlertForm extends React.PureComponent<Props> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  componentWillMount() {
    const { initForm } = this.props
    initForm()
  }

  componentWillUnmount() {
    const { clearForm, clearRecipients } = this.props
    clearForm()
    clearRecipients()
  }

  render() {
    const { i18n } = this.context
    const {
      autoTranslateOn: globalAutoTranslateOn,
      autoTranslateReport,
      autoTranslateLanguage,
      copyContentToClipboard,
      copyEmails,
      copySms,
      emailsSuccessfullyCopied,
      smsSuccessfullyCopied,
      contentSuccessfullyCopied,
      recipientsCount,
      maxAlertRecipients,
    } = this.props

    const isRecipientsLimitReached = recipientsCount > maxAlertRecipients

    const limitReachedMessage = (
      <span className="error-message">{`${i18n.t('You can only enter')} ${maxAlertRecipients} ${i18n.t(
        'recipients.',
      )}`}</span>
    )

    return (
      <div className="op-content-article_wrapper util_col-centered mod-height-toolbar-fix">
        <div className="col-md-12 mod-alert-full-height mod-alerts">
          <div className="row">
            <div className="col-md-12 op-content-article-listing">
              <form className="op-content-center-alert-edit mod-alert-full-height">
                <div className="row">
                  <div className="col-lg-5">
                    <div className="row">
                      <div className="form-group col-md-12">
                        <h3>
                          <Translate i18nString="Alert subject" />
                        </h3>
                        <Field
                          className="op-input"
                          component="input"
                          name="subject"
                          placeholder={i18n.t('Enter alert subject')}
                          tabIndex={1}
                          type="text"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <h3>
                          <Translate i18nString="Active" />
                        </h3>
                        <Field name="active" component={ToggleCheckbox} />
                      </div>
                      <div className="form-group col-md-6">
                        <h3>
                          <Translate i18nString="Editable" />
                        </h3>
                        <Field name="editable" component={ToggleCheckbox} />
                      </div>
                    </div>
                    <div className="form-group">
                      <h3>
                        <Translate i18nString="Recipients" />
                      </h3>
                      <label htmlFor="email">
                        <Translate i18nString="Email" />
                      </label>
                      <CopyButton
                        titleHint={i18n.t('Copy filled recipients emails')}
                        onClickHandler={copyEmails}
                        copied={emailsSuccessfullyCopied}
                      />
                      <AlertsContactsInput error={isRecipientsLimitReached} />
                      <label htmlFor="sms" style={{ marginTop: '5px' }}>
                        <Translate i18nString="SMS" />
                      </label>
                      <CopyButton
                        titleHint={i18n.t('Copy filled recipients numbers')}
                        onClickHandler={copySms}
                        copied={smsSuccessfullyCopied}
                      />
                      <AlertsSmsInput error={isRecipientsLimitReached} />
                    </div>
                    {isRecipientsLimitReached && limitReachedMessage}
                    <div className="form-group">
                      <h3>
                        <span>
                          <Translate i18nString="Content" />
                        </span>
                        <CopyButton
                          titleHint={i18n.t('Copy filled content names')}
                          onClickHandler={copyContentToClipboard}
                          copied={contentSuccessfullyCopied}
                        />
                      </h3>
                      <Field
                        className="op-input"
                        component={ProfileInput}
                        name="profiles"
                        placeholder={i18n.t('Enter a profile or tag')}
                        tabIndex={4}
                      />
                    </div>
                    <div className="form-group">
                      <h3>
                        <Translate i18nString="Alert language" />
                      </h3>
                      <Field className="op-select" component="select" name="language" tabIndex={5}>
                        {/*
                         If backend provides some locale frontend doesn't understand,
                         let's show an empty value
                        */}
                        {this.props.initialValues.language === undefined && <option value="" />}
                        {getLanguages().map((language, key) => (
                          <option key={key} value={language}>
                            {language}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <div className="form-group">
                      <h3>
                        <Translate i18nString="Timezone" />
                      </h3>
                      <Field className="op-select" component="select" name="timeZone" tabIndex={7}>
                        {getTimezones().map((timeZone, key) => (
                          <option key={key} value={timeZone}>
                            {timeZone}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <div className="form-group">
                      <h3>
                        <Translate i18nString="Max article age" />
                      </h3>
                      <Field className="op-select" component="select" name="max_age" tabIndex={9} type="number">
                        {MAX_AGE_OPTIONS.map(({ count, seconds, name }, key) => (
                          <option key={seconds} value={seconds}>
                            {i18n.t(name, { count })}
                          </option>
                        ))}
                      </Field>
                    </div>
                    {globalAutoTranslateOn || autoTranslateReport ? (
                      <div className="form-group">
                        <h3>
                          <Translate i18nString="Auto translate articles in report" />
                        </h3>
                        <div>
                          <Field component={ToggleCheckbox} id="translate" name="translate" tabIndex={8} />
                          <div>
                            {autoTranslateReport ? (
                              <Translate
                                i18nString="Will translate to {{lang}}"
                                context={{ lang: autoTranslateLanguage }}
                              />
                            ) : (
                              <Translate i18nString="Will not translate" />
                            )}
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="form-group">
                      <h3>
                        <ArticlesGroupingLabel />
                      </h3>
                      <Field
                        name="groupidentical"
                        component={ToggleCheckbox}
                        format={reverseValue}
                        normalize={reverseValue}
                      />
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <h3>
                      <Translate i18nString="Template" />
                    </h3>
                    <Field name="template" component={TemplateForm} />
                    <h3>
                      <Translate i18nString="When" />
                    </h3>
                    <Field name="time" component={WhenForm} />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AlertForm

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'

import AlertListing from './alerts/listing'
import AlertTagListing from './alerts/AlertTagListing'
import AnalyticsListing from './analytics/Listing'
import buildActionCreators from '../helpers/buildActionCreators'
import FeedListing from './feeds/FeedListing'
import ModulePermissions from './common/ModulePermissions'
import ProfileListing from './profiles/listing'
import StatisticListing from './statistics/StatisticsViews/Listing'
import TagListing from './tags/listing'
import TrashListing from './tags/TrashListing'
import { TAG_TYPES } from '../opoint/tags'
import { MODULE_OPTION, MODULE } from '../opoint/settings/index'
import * as ActionTypes from '../constants/actionTypes'
import { getProfiles } from '../selectors/profilesSelectors'

type EntityListingProps = {
  fetchRecipients: () => void
}

class EntityListing extends React.Component<EntityListingProps> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  componentDidMount = () => {
    this.props.fetchRecipients()
  }

  render() {
    const { i18n } = this.context

    return (
      <div className="col-md-2  op-content-left_wrapper">
        <div className="row entityListing">
          <ModulePermissions module={MODULE.PROFILE} permissions={[MODULE_OPTION.ON, MODULE_OPTION.READ_ONLY]}>
            <ProfileListing showImportantSetting="DISPLAY_IMPORTANT_PROFILES" />
          </ModulePermissions>
          <div>
            <StatisticListing categoryName={i18n.t('Statistic views')} category="STATISTICS_CATEGORY" />
            <ModulePermissions module={MODULE.TAG} permissions={[MODULE_OPTION.ON, MODULE_OPTION.READ_ONLY]}>
              <TagListing
                type={TAG_TYPES.KEYWORD}
                categoryName={i18n.t('Tags')}
                category="TAGS_CATEGORY"
                showImportantSetting="DISPLAY_IMPORTANT_TAGS"
              />
            </ModulePermissions>

            <ModulePermissions module={MODULE.TAG} permissions={[MODULE_OPTION.ON, MODULE_OPTION.READ_ONLY]}>
              <TagListing
                type={TAG_TYPES.MENTOMETER}
                newButton={false}
                categoryName={i18n.t('Sentiment tags')}
                category="MENTOMETER_CATEGORY"
                showImportantSetting="DISPLAY_IMPORTANT_MENT_TAGS"
              />
            </ModulePermissions>

            <AnalyticsListing categoryName={i18n.t('Analytics')} category="FEED_CATEGORY" />

            <ModulePermissions module={MODULE.FEED} permissions={[MODULE_OPTION.ON, MODULE_OPTION.READ_ONLY]}>
              <FeedListing categoryName={i18n.t('Feeds')} category="FEED_CATEGORY" />
            </ModulePermissions>

            <ModulePermissions module={MODULE.ALERT} permissions={[MODULE_OPTION.ON, MODULE_OPTION.READ_ONLY]}>
              {/*
              // @ts-ignore */}
              <AlertListing />
            </ModulePermissions>

            <ModulePermissions module={MODULE.ALERT} permissions={[MODULE_OPTION.ON, MODULE_OPTION.READ_ONLY]}>
              <AlertTagListing
                // @ts-ignore
                categoryName={i18n.t('Edit alert content')}
                newButton={false}
                category="ALERTTAG_CATEGORY"
              />
            </ModulePermissions>

            <ModulePermissions module={MODULE.TRASH} permissions={[MODULE_OPTION.ON, MODULE_OPTION.READ_ONLY]}>
              <TrashListing categoryName={i18n.t('Trash')} category="TRASH_CATEGORY" />
            </ModulePermissions>
          </div>
        </div>
      </div>
    )
  }
}

// @ts-ignore
EntityListing = translate([], { wait: true })(EntityListing)

export default connect(
  null,
  buildActionCreators({
    fetchRecipients: ActionTypes.ALERTS_FETCH_RECIPIENTS,
  }),
  // @ts-ignore
)(EntityListing)

import React from 'react'

import ReportActions from '../common/ReportActions'
import Translate from '../../Translate'
import { REPORT_STATUS } from '../../opoint/reports/index'

type Props = {
  reportObject: any
  toggleAttachmentShareFn: () => void
  setMessageShareFn: () => void
  updateShareStepFn: () => void
  reportShareFn: () => void
  clearData: () => void
  isAttachmentShareChecked: boolean
  selectedShareContacts: Object
  shareReportMessage: string
  stepShareNumber: number
  reportsClose: any
}

export default class ReportsDownloadShare extends React.PureComponent<any> {
  props: Props

  render() {
    const {
      reportObject,
      isAttachmentShareChecked,
      setMessageShareFn,
      toggleAttachmentShareFn,
      selectedShareContacts,
      shareReportMessage,
      stepShareNumber,
      updateShareStepFn,
      reportShareFn,
      clearData,
      reportsClose,
    } = this.props

    return (
      <div className="op-modal-report-body-content">
        {reportObject && reportObject.status === REPORT_STATUS.FINISHED && (
          <div className="create__form">
            <h3>
              <Translate i18nString="Your report is ready" />!
            </h3>
            <ReportActions
              reportOpened={reportObject}
              selectedContacts={selectedShareContacts}
              shareReportMessage={shareReportMessage}
              changeMessage={setMessageShareFn}
              step={stepShareNumber}
              isAttachmentChecked={isAttachmentShareChecked}
              sendReport={reportShareFn}
              updateStep={updateShareStepFn}
              toggleAttachment={toggleAttachmentShareFn}
              clearData={clearData}
              reportsClose={reportsClose}
              reportCreate
            />
          </div>
        )}
      </div>
    )
  }
}

import * as React from 'react'

import EditStatisticViewButton from '../components/statistics/ToolbarButtons/EditStatisticViewButton'
import GroupingButton from '../components/toolbars/buttons/groupingButton/GroupingButton'
import HideFilteredButton from '../components/statistics/ToolbarButtons/HideFilteredButton'
import ProfileEditorSaveProfileButton from '../components/toolbars/buttons/profileEditorSaveProfileButton/ProfileEditorSaveProfileButton'
import RemoveArticleButton from '../components/common/RemoveArticleButton'
import SaveAsProfile from '../components/toolbars/buttons/saveAsProfile'
import ShowFilteredButton from '../components/statistics/ToolbarButtons/ShowFilteredButton'
import TagArticles from '../components/toolbars/buttons/tag/index'
import ToggleAutoTranslateButton from '../components/toolbars/buttons/toggleAutoTranslateButton/ToggleAutoTranslateButton'
import ToggleEditTagPopover from '../components/toolbars/buttons/tag/ToggleEditTagPopover'
import Translate from '../Translate'
import { MODULE, MODULE_OPTION } from '../opoint/settings/index'
import * as Actions from '../constants/actionTypes'
import type { PortalAction, ToolbarOnClickAction } from '../opoint/flow'

const saveAsProfileWrapper = (SaveAsComponent, shortLabel: boolean = false) => (props: ToolbarOnClickAction) => {
  const newProps = { ...props, shortLabel }
  return <SaveAsComponent {...newProps} />
}

// TODO - needs to be deleted along with conditions and everything,
// it's not solved through action line anymore
export const TAG_ACTIONS: Array<PortalAction> = [
  {
    id: 1,
    name: <Translate i18nString="Keyword" />,
    icon: 'op-icon-arrow-down',
    onClickAction: '',
    priority: 1,
  },
  {
    id: 2,
    name: <Translate i18nString="Save" />,
    icon: 'op-icon-attachment',
    onClickAction: '',
    priority: 2,
  },
  {
    id: 3,
    name: <Translate i18nString="Hide" />,
    icon: 'op-icon-delete',
    onClickAction: '',
    priority: 3,
  },
  {
    id: 4,
    name: <Translate i18nString="Delete" />,
    icon: 'op-icon-delete',
    onClickAction: '',
    priority: 4,
  },
  {
    id: 5,
    name: <Translate i18nString="Cancel" />,
    icon: 'op-icon-cross',
    onClickAction: Actions.CLOSE_EDITED_TAG,
    priority: 5,
  },
]

export const PROFILE_ACTIONS: Array<PortalAction> = [
  {
    id: 1,
    name: <Translate i18nString="Preview" />,
    icon: 'op-icon-search',
    onClickAction: Actions.PROFILE_EDITOR_PREVIEW,
    priority: 1,
  },
  {
    id: 2,
    name: <Translate i18nString="Save" />,
    icon: 'op-icon-attachment',
    onClickAction: ProfileEditorSaveProfileButton,
    priority: 2,
  },
  {
    id: 6,
    name: <Translate i18nString="Cancel" />,
    icon: 'op-icon-cross',
    onClickAction: Actions.PROFILE_EDITOR_BACK_TO_SEARCH,
    priority: 5,
  },
]

export const EDITING_PROFILE_ACTIONS: Array<PortalAction> = [
  {
    id: 3,
    name: <Translate i18nString="Save as" />,
    icon: 'op-icon-attachment',
    onClickAction: saveAsProfileWrapper(SaveAsProfile, true), // shortLabel = true
    priority: 3,
  },
  {
    id: 4,
    name: <Translate i18nString="Delete" />,
    icon: 'op-icon-bin',
    onClickAction: Actions.PROFILE_EDITOR_DELETE_CONFIRMATION_OPEN,
    priority: 4,
    module: MODULE.PROFILE,
    hasPermissions: (permission) => permission === MODULE_OPTION.ON,
  },
]

export const EDIT_PROFILE_ACTION: PortalAction = {
  id: 3,
  name: <Translate i18nString="Edit profile" />,
  icon: 'op-icon-pencil',
  onClickAction: Actions.SEARCH_GO_TO_EDIT_PROFILE,
  priority: 3,
  module: MODULE.PROFILE,
  hasPermissions: (permission) => permission === MODULE_OPTION.ON || permission === MODULE_OPTION.READ_ONLY,
}

export const SAVE_AS_PROFILE_ACTION: Array<PortalAction> = [
  {
    id: 3,
    onClickAction: saveAsProfileWrapper(SaveAsProfile),
    priority: 3,
    module: MODULE.PROFILE,
    hasPermissions: (permission) => permission === MODULE_OPTION.ON,
    hideOnNoPermission: true,
  },
]

export const EDIT_TAG_ACTION: PortalAction = {
  id: 3,
  onClickAction: ToggleEditTagPopover,
  priority: 3,
  module: MODULE.TAG,
  hasPermissions: (permission) => permission === MODULE_OPTION.ON,
}

export const ARTICLE_BASIC_ACTIONS: Array<PortalAction> = [
  {
    id: 1,
    name: <Translate i18nString="Create report" />,
    icon: 'op-icon-file',
    onClickAction: Actions.REPORTS_MODAL_OPEN,
    priority: 1,
    module: MODULE.REPORT,
    hasPermissions: (permission) => permission === MODULE_OPTION.ON,
  },
  {
    id: 2,
    name: <Translate i18nString="Statistics" />,
    icon: 'op-icon-statistics',
    onClickAction: Actions.SEARCH_GO_TO_STATISTICS,
    priority: 2,
    module: MODULE.STATISTICS,
    hasPermissions: (permission) => permission === MODULE_OPTION.ON,
  },
  // Third one is skipped on purposes, it is Save as profile / Edit profile / Edit tag action.
  {
    id: 4,
    name: <Translate i18nString="Create alert" />,
    icon: 'op-icon-bell',
    onClickAction: Actions.CREATE_ALERT_ACTIONLINE,
    priority: 4,
    module: MODULE.ALERT,
    hasPermissions: (permission) => permission === MODULE_OPTION.ON,
    isNewAlertAction: true,
  },
  {
    id: 5,
    name: <Translate i18nString="Create feed" />,
    icon: 'op-icon-feed',
    onClickAction: Actions.GO_TO_NEW_FEED_ACTIONLINE,
    priority: 5,
    module: MODULE.FEED,
    hasPermissions: (permission) => permission === MODULE_OPTION.ON,
  },
  {
    id: 6,
    name: <Translate i18nString="Upload content to M360" />,
    icon: 'op-icon-pencil',
    onClickAction: Actions.ADD_ARTICLE_MODAL_OPEN,
    priority: 6,
    module: MODULE.CREATE_ARTICLE,
    hasPermissions: (permission) => permission === MODULE_OPTION.ON,
  },
  {
    id: 7,
    icon: 'op-icon-translate',
    onClickAction: GroupingButton,
    priority: 7,
  },
  {
    id: 8,
    icon: 'op-icon-translate',
    onClickAction: ToggleAutoTranslateButton,
    priority: 8,
  },
]

export const ARTICLE_CHECK_ACTIONS: Array<PortalAction> = [
  {
    id: 1,
    name: <Translate i18nString="Create report" />,
    icon: 'op-icon-file',
    onClickAction: Actions.REPORTS_MODAL_OPEN,
    priority: 1,
    module: MODULE.REPORT,
    hasPermissions: (permission) => permission === MODULE_OPTION.ON,
  },
  {
    id: 2,
    name: <Translate i18nString="Share" />,
    icon: 'op-icon-attachment',
    onClickAction: Actions.SHARE_ARTICLE_MODAL_OPEN,
    priority: 2,
  },
  {
    id: 3,
    onClickAction: TagArticles,
    priority: 3,
  },
  {
    id: 4,
    name: <Translate i18nString="Delete globally" />,
    icon: 'op-icon-pencil',
    onClickAction: Actions.DELETE_ARTICLES_GLOBALLY_TOOLBAR,
    priority: 4,
    module: MODULE.TRASH,
    hasPermissions: (permission) => permission === MODULE_OPTION.ON,
  },
  {
    id: 5,
    name: <Translate i18nString="Delete from profiles" />,
    icon: 'op-icon-pencil',
    onClickAction: RemoveArticleButton,
    priority: 5,
    module: MODULE.TRASH,
    hasPermissions: (permission) => permission === MODULE_OPTION.ON,
  },
  {
    id: 6,
    name: <Translate i18nString="Statistics" />,
    icon: 'op-icon-statistics',
    onClickAction: Actions.SEARCH_GO_TO_STATISTICS,
    priority: 6,
    module: MODULE.STATISTICS,
    hasPermissions: (permission) => permission === MODULE_OPTION.ON,
  },
  {
    id: 7,
    name: <Translate i18nString="Unselect" />,
    icon: 'op-icon-cross',
    onClickAction: Actions.UNCHECK_ALL_ARTICLES,
    priority: 7,
  },
]

export const FEED_ACTIONS: Array<PortalAction> = [
  {
    id: 1,
    name: <Translate i18nString="Save" />,
    icon: 'op-icon-attachment',
    onClickAction: Actions.FEED_SAVE,
    priority: 1,
    module: MODULE.FEED,
    hasPermissions: (permission) => permission === MODULE_OPTION.ON,
  },
  {
    id: 2,
    name: <Translate i18nString="Delete" />,
    icon: 'op-icon-bin',
    onClickAction: Actions.FEED_DELETE,
    priority: 2,
    module: MODULE.FEED,
    hasPermissions: (permission) => permission === MODULE_OPTION.ON,
  },
  {
    id: 3,
    name: <Translate i18nString="Cancel" />,
    icon: 'op-icon-cross',
    onClickAction: Actions.PROFILE_EDITOR_BACK_TO_SEARCH,
    priority: 3,
    module: MODULE.FEED,
    hasPermissions: (permission) => permission === MODULE_OPTION.ON,
  },
]

export const DELETE_FEED_ACTIONS: Array<PortalAction> = [
  {
    id: 1,
    name: <Translate i18nString="Delete" />,
    icon: 'op-icon-bin',
    onClickAction: Actions.FEED_DELETE_CONFIRM,
    priority: 1,
  },
  {
    id: 2,
    name: <Translate i18nString="Cancel" />,
    icon: 'op-icon-cross',
    onClickAction: Actions.FEED_DELETE_CANCEL,
    priority: 2,
  },
]

export const NEW_FEED_ACTIONS: Array<PortalAction> = [
  {
    id: 1,
    name: <Translate i18nString="Save" />,
    icon: 'op-icon-attachment',
    onClickAction: Actions.FEED_NEW,
    priority: 1,
    module: MODULE.FEED,
    hasPermissions: (permission) => permission === MODULE_OPTION.ON,
  },
  {
    id: 2,
    name: <Translate i18nString="Cancel" />,
    icon: 'op-icon-cross',
    onClickAction: Actions.PROFILE_EDITOR_BACK_TO_SEARCH,
    priority: 2,
    module: MODULE.FEED,
    hasPermissions: (permission) => permission === MODULE_OPTION.ON,
  },
]

export const NEW_ALERT_ACTIONS: Array<PortalAction> = [
  {
    id: 1,
    name: <Translate i18nString="Save" />,
    icon: 'op-icon-attachment',
    onClickAction: Actions.SAVE_ALERT,
    priority: 1,
    module: MODULE.ALERT,
    hasPermissions: (permission) => permission === MODULE_OPTION.ON,
    isNewAlertAction: true,
    isSaveAlertAction: true,
  },
  {
    id: 2,
    name: <Translate i18nString="Cancel" />,
    icon: 'op-icon-cross',
    onClickAction: Actions.ALERT_ADD_CANCEL,
    priority: 2,
  },
]

export const EDIT_ALERT_ACTIONS: Array<PortalAction> = [
  {
    id: 1,
    name: <Translate i18nString="Save" />,
    icon: 'op-icon-attachment',
    onClickAction: Actions.SAVE_ALERT,
    priority: 1,
    module: MODULE.ALERT,
    hasPermissions: (permission) => permission === MODULE_OPTION.ON,
    isSaveAlertAction: true,
  },
  {
    id: 2,
    name: <Translate i18nString="Delete" />,
    icon: 'op-icon-bin',
    onClickAction: Actions.DELETE_ALERT,
    priority: 2,
    module: MODULE.ALERT,
    hasPermissions: (permission) => permission === MODULE_OPTION.ON,
  },
  {
    id: 3,
    name: <Translate i18nString="Cancel" />,
    icon: 'op-icon-cross',
    onClickAction: Actions.ALERT_EDIT_CANCEL,
    priority: 3,
  },
]

export const DELETE_ALERT_ACTIONS: Array<PortalAction> = [
  {
    id: 1,
    name: <Translate i18nString="Delete" />,
    icon: 'op-icon-bin',
    onClickAction: Actions.DELETE_ALERT_CONFIRM,
    priority: 1,
  },
  {
    id: 2,
    name: <Translate i18nString="Cancel" />,
    icon: 'op-icon-cross',
    onClickAction: Actions.ALERT_DELETE_CANCEL,
    priority: 2,
  },
]

export const OPEN_ALERT_PREVIEW_ACTIONS: Array<PortalAction> = [
  {
    id: 1,
    name: <Translate i18nString="Edit alert" />,
    icon: 'op-icon-pencil',
    onClickAction: Actions.EDIT_ALERT,
    priority: 1,
    module: MODULE.ALERT,
    hasPermissions: (permission) => permission === MODULE_OPTION.ON || permission === MODULE_OPTION.READ_ONLY,
  },
  {
    id: 2,
    name: <Translate i18nString="Remove articles" />,
    icon: 'op-icon-bin',
    onClickAction: Actions.ALERT_REMOVE_ARTICLE_OPEN,
    priority: 2,
    module: MODULE.ALERT,
    hasPermissions: (permission) => permission === MODULE_OPTION.ON,
  },
  {
    id: 3,
    name: <Translate i18nString="Send now" />,
    icon: 'op-icon-attachment',
    onClickAction: Actions.SEND_ALERT_NOW,
    priority: 3,
  },
  {
    id: 4,
    name: <Translate i18nString="Send again" />,
    icon: 'op-icon-attachment',
    onClickAction: Actions.ALERT_CHANGE_RECIPIENTS_OPEN,
    priority: 4,
  },
]

export const STATISTICS_ACTIONS: Array<PortalAction> = [
  {
    id: 4, // TODO probably create HOC or smth like that to avoid the code repeat @dmytro
    name: <Translate i18nString="Return to news" />,
    icon: 'op-icon-file',
    onClickAction: Actions.SEARCH_GO_TO_NEWS,
    priority: 4,
  },
  {
    id: 3,
    name: <Translate i18nString="Save chart" />,
    icon: 'op-icon-statistics',
    onClickAction: EditStatisticViewButton,
    priority: 3,
    module: MODULE.STATISTICS,
    hasPermissions: (permission) => permission === MODULE_OPTION.ON,
  },
  {
    id: 6,
    name: <Translate i18nString="Export as PDF" />,
    icon: 'op-icon-file',
    onClickAction: Actions.STATISTICS_VIEW_EXPORT_PDF_OPEN_MODAL,
    priority: 6,
  },
]
export const STATISTICS_VIEWS_ACTIONS: Array<PortalAction> = [
  {
    id: 6,
    name: <Translate i18nString="Export as PDF" />,
    icon: 'op-icon-file',
    onClickAction: Actions.STATISTICS_VIEW_EXPORT_PDF_OPEN_MODAL,
    priority: 6,
  },
  {
    id: 3,
    name: <Translate i18nString="Save chart" />,
    icon: 'op-icon-pencil',
    onClickAction: EditStatisticViewButton,
    priority: 3,
    module: MODULE.REPORT,
    hasPermissions: (permission) => permission === MODULE_OPTION.ON,
  },
  {
    id: 4,
    name: <Translate i18nString="Delete chart" />,
    icon: 'op-icon-bin',
    onClickAction: Actions.STATISTICS_VIEW_DELETE,
    priority: 4,
    module: MODULE.STATISTICS,
    hasPermissions: (permission) => permission === MODULE_OPTION.ON,
  },
  {
    id: 5, // TODO probably create HOC or smth like that to avoid the code repeat @dmytro
    name: <Translate i18nString="Return to news" />,
    icon: 'op-icon-file',
    onClickAction: Actions.SEARCH_GO_TO_NEWS,
    priority: 5,
  },
]
export const STATISTICS_FILTER_ACTIONS: Array<PortalAction> = [
  {
    id: 1,
    name: 'Show filtered articles',
    icon: 'op-icon-filters',
    onClickAction: ShowFilteredButton,
    priority: 1,
  },
  {
    id: 2,
    name: <Translate i18nString="Reset filters" />,
    icon: 'op-icon-cross',
    onClickAction: Actions.STATISTICS_FILTER_RESET_ALL,
    priority: 2,
  },
]

export const STATISTICS_FILTERED_ARTICLES: Array<PortalAction> = [
  {
    id: 3,
    name: <Translate i18nString="Go back to statistics" />,
    icon: 'op-icon-statistics',
    onClickAction: HideFilteredButton,
    priority: 3,
  },
]

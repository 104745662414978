import React from 'react'
import R from 'ramda'

type Props = {
  options: Array<{ value: string; label: string; group: string }>
  value: string
  onChange: () => void
  className?: string
}

export default class Select extends React.PureComponent<Props> {
  onChangeHandler = ({ target: { value } }) => {
    // @ts-ignore
    this.props.onChange(value)
  }

  render() {
    const { options, value: selectedValue, className } = this.props
    const groupedOptions = R.groupBy(({ group }) => group, options)
    const getOptions = (group) =>
      group.map(({ label, value }) => (
        <option key={value} label={label} value={value}>
          {label}
        </option>
      ))

    return (
      <select className={className} value={selectedValue} onChange={this.onChangeHandler}>
        {Object.keys(groupedOptions).map((v) =>
          v !== 'undefined' ? (
            <optgroup key={v} label={v}>
              {getOptions(groupedOptions[v])}
            </optgroup>
          ) : (
            getOptions(groupedOptions[v])
          ),
        )}
      </select>
    )
  }
}

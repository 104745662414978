import React from 'react'
import Lightbox from 'react-images'

export default class OpointLightBox extends React.Component<any, any> {
  componentDidMount() {
    document.body.setAttribute('stop-propagation', 'true')
  }

  componentWillUnmount() {
    document.body.removeAttribute('stop-propagation')
  }

  render() {
    return <Lightbox {...this.props} />
  }
}

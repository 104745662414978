import React from 'react'

import Loader from '../common/Loader'
import StatisticsContainer from './StatisticsContainer'
import TimeSliderSelect from './TimeSliderSelect'
import FilteredArticles from './FilteredArticles'

class Statistics extends React.PureComponent<any> {
  fetchStatistics = (startDate, count) => {
    const { extendTimeRangeAndFetch, meta, searchChangeDateRange } = this.props
    // update search line filter for timePeriod according to the selected
    // time range in TimeSlider component
    searchChangeDateRange({ startDate, endDate: meta.rangeEnd })
    extendTimeRangeAndFetch({ count, startDate, preserveAspects: true })
  }

  render() {
    const { loading, meta, showFilteredArticles, searchInProgress } = this.props

    if (loading) {
      return (
        <div className="statistics">
          <Loader />
        </div>
      )
    }

    if (showFilteredArticles) {
      return (
        <div className="statistics row mod-full-height u-overflow--hidden">
          <FilteredArticles />
        </div>
      )
    }

    return (
      <div className="statistics">
        {meta.receivedDocumentsCount < meta.foundDocumentsCount ? (
          <TimeSliderSelect
            articlesCount={meta.receivedDocumentsCount}
            rangeCount={meta.foundDocumentsCount}
            rangeStartRequested={meta.rangeStart}
            rangeStartDelivered={meta.lastTimestamp}
            // rangeEnd is often 0 (now), in that case, substitute
            rangeEnd={meta.rangeEnd || meta.firstTimestamp}
            onSelect={this.fetchStatistics}
          />
        ) : null}
        {!searchInProgress && <StatisticsContainer />}
      </div>
    )
  }
}

export default Statistics

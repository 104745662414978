import React, { useEffect, useState } from 'react'

import { keyValueSerialize } from '../../helpers/common'
import { Option } from '../../types/settings'
import Checkbox from '../../common/Checkbox'
import { createOptionsFromSetting } from '../../helpers/settings'
import { list } from './index.module.scss'

type Props = {
  allOptions: Option[]
  input: {
    value: string
    onChange: (val: string) => void
  }
}

const SettingToOptions = ({ allOptions, input: { value, onChange } }: Props) => {
  const [options, setOptions] = useState(createOptionsFromSetting(allOptions, value))

  useEffect(() => {
    onChange(
      keyValueSerialize(
        options.map(({ value, checked }) => ({
          key: value,
          value: checked ? 'true' : 'false',
        })),
      ),
    )
  }, [options])

  const onChangeHandler = (name: string) => {
    setOptions(options.map((item) => (item.name === name ? { ...item, checked: !item.checked } : item)))
  }

  return (
    <ul className={list}>
      {options.map(({ checked, name, value }, i) => (
        <li key={i}>
          <Checkbox checked={checked} label={name} onChange={() => onChangeHandler(name)} value={value} />
        </li>
      ))}
    </ul>
  )
}

export default SettingToOptions

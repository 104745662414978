import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, InjectedFormProps, WrappedFieldProps } from 'redux-form'
import { useTranslation } from 'react-i18next-latest'

import Modal from '../../common/Modal'
import { getActiveStatViewName } from '../../../selectors/statisticsSelectors'
import * as ActionTypes from '../../../constants/actionTypes'
import buildActionCreators from '../../../helpers/buildActionCreators'
import { trimPostLastDotSubsctring } from '../../../helpers/common'
import Loader from '../../common/Loader'
import Translate from '../../common/Translate'
import styles from './index.module.scss'

const validate = (values) => {
  const errors: { fileName?: string; title?: string } = {}
  if (!values.fileName) {
    errors.fileName = 'Required'
  } else if (values.fileName.length < 3) {
    errors.fileName = 'Must be more than 3 characters'
  }
  if (!values.title || !values.title.trim()) {
    errors.title = 'Required'
  }
  return errors
}

const CustomField = ({
  input,
  placeholder,
  type,
  meta: { touched, error, warning },
}: { placeholder: string; type: string } & WrappedFieldProps) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <input {...input} placeholder={placeholder} type={type} className="op-input_wrapper" />
      {touched &&
        ((error && <span className={styles.error}>{t(error)}</span>) ||
          (warning && <span className={styles.error}>{t(warning)}</span>))}
    </React.Fragment>
  )
}

interface FormValues {
  fileName: string
  title: string
  description: string
}

interface Props {
  isOpen: boolean
  loading: boolean
  statisticsViewName: string
  closeModal: () => void
  exportPdf: () => void
  updateFilename: (obj: { updatedFilenameValue: string }) => void
  updateTitle: (obj: { updatedTitleValue: string }) => void
}

type ExportPdfModalProps = InjectedFormProps<FormValues, Props> & Props

const ExportPdfModal: React.FC<ExportPdfModalProps> = ({
  updateTitle,
  statisticsViewName,
  exportPdf,
  updateFilename,
  isOpen,
  closeModal,
  loading,
  handleSubmit,
}: ExportPdfModalProps) => {
  const { t } = useTranslation()

  const updateTitleValue = () => {
    updateTitle({
      updatedTitleValue: statisticsViewName,
    })
  }

  useEffect(updateTitleValue, [statisticsViewName])

  const onFilenameBlurHandler = (e, newVal) => {
    if (newVal) {
      e.preventDefault()

      updateFilename({
        updatedFilenameValue: `${trimPostLastDotSubsctring(newVal).trim()}.pdf`,
      })

      e.target.blur()
    }
  }

  const onFilenameFocusHandler = (e) => {
    const fileName = e.currentTarget?.value
    updateFilename({
      // trim file extension
      updatedFilenameValue: trimPostLastDotSubsctring(fileName).trim(),
    })
  }

  return (
    <Modal isOpen={isOpen} onHide={closeModal} title="Export PDF file">
      <form onSubmit={handleSubmit(exportPdf)} className={styles.form}>
        <div className="form-group op-form-group">
          <label>
            <Translate text="File name" />
          </label>
          <Field
            className="op-input_wrapper"
            type="text"
            name="fileName"
            component={CustomField}
            onBlur={onFilenameBlurHandler}
            onFocus={onFilenameFocusHandler}
            placeholder={t('statistics-export.pdf')}
          />
        </div>
        <div className="form-group op-form-group">
          <label>
            <Translate text="Title" />
          </label>
          <Field
            className="op-input_wrapper"
            type="text"
            name="title"
            component={CustomField}
            placeholder={t('Statistics export pdf')}
          />
        </div>
        <div className="form-group op-form-group">
          <label>
            <Translate text="Description" />
          </label>
          <Field
            className="op-input_wrapper"
            type="text"
            name="description"
            component="textarea"
            placeholder={t('Description')}
          />
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className={styles.submitWrapper}>
            <button type="submit" className="op-button green">
              <Translate text="Export PDF" />
            </button>
          </div>
        )}
      </form>
    </Modal>
  )
}

const ExportPdfModalForm = reduxForm<FormValues>({
  form: 'statisticsExportPdf',
  initialValues: {
    fileName: '',
    title: '',
    description: '',
  },
  validate,
})(ExportPdfModal)

export default connect<{}, {}>(
  (state: any) => ({
    isOpen: state.ui.statisticsExportPdfModal,
    loading: state.statistics.exportPDFloading,
    statisticsViewName: getActiveStatViewName(state),
  }),
  buildActionCreators({
    closeModal: ActionTypes.STATISTICS_VIEW_EXPORT_PDF_CLOSE_MODAL,
    exportPdf: ActionTypes.STATISTICS_VIEW_EXPORT_PDF_TRIGGER,
    updateFilename: ActionTypes.STATISTICS_VIEW_EXPORT_PDF_UPDATE_FILENAME,
    updateTitle: ActionTypes.STATISTICS_VIEW_EXPORT_PDF_UPDATE_TITLE,
  }),
)(ExportPdfModalForm)

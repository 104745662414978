import React from 'react'
import { connect } from 'react-redux'

import buildActionCreators from '../../helpers/buildActionCreators'
import * as ActionTypes from '../../constants/actionTypes'
import Translate from '../../Translate'

class FilterReset extends React.PureComponent<any> {
  getFilterNames() {
    const { filter, getAspPartName } = this.props
    return filter ? filter.map((partId) => getAspPartName(partId)) : []
  }

  resetFilter = () => {
    const { aspect, resetFilter } = this.props

    resetFilter({
      id: aspect.id,
    })
  }

  render() {
    const filters = this.getFilterNames()

    if (filters.length > 0) {
      return (
        <span>
          <a onClick={this.resetFilter}>
            <Translate i18nString="Reset" />
            {': '}
          </a>
          {filters.join(', ')}
        </span>
      )
    }
    return null
  }
}

// @ts-ignore
FilterReset = connect(
  (state) => ({}),
  buildActionCreators({
    resetFilter: ActionTypes.STATISTICS_FILTER_RESET,
  }),
)(FilterReset)

export default FilterReset

// @ts-nocheck
import React from 'react'
import copy from 'copy-to-clipboard'
import R from 'ramda'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'

import AlertForm from './AlertForm/AlertForm'
import buildActionCreators from '../../helpers/buildActionCreators'
import { getAlertEmail, getAlertSms } from '../../selectors/contactSelectors'
import {
  getAlertsDetails,
  isAutoTranslateReport,
  getEditedAlertContentNamesString,
} from '../../selectors/alertsSelectors'
import { getRecipientsNumbersString } from '../../opoint/contacts/index'
import { isAutoTranslationsOn, getAutoTranslationLanguageName } from '../../selectors/settingsSelectors'
import { locales } from '../../opoint/common/constants'
import { timeConfigurationToWhen } from '../../opoint/alerts/apiWhen'
import * as ActionTypes from '../../constants/actionTypes'
import type { CommonRecipientFilter } from '../../opoint/flow'

type Props = {
  alertFetch: (obj: { alertId: number; toBeEdited: true }) => void
  alertId: number
  clearForm: () => void
  initForm: () => void
  // TODO: Alert type @honza
  initialValues: any
  timeConfiguration: Object
  alertsSms: Array<CommonRecipientFilter>
  emailRecipients: Array<CommonRecipientFilter>
  contentString: string
  clearRecipients: () => void
}

type State = {
  emailsSuccessfullyCopied: boolean
  smsSuccessfullyCopied: boolean
  contentSuccessfullyCopied: boolean
}

class EditAlertContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      emailsSuccessfullyCopied: false,
      smsSuccessfullyCopied: false,
      contentSuccessfullyCopied: false,
    }
  }

  componentDidMount() {
    this.fetchAlert()
  }

  componentDidUpdate(prevProps: Props) {
    const { alertId: oldAlertId } = prevProps
    const { alertId: newAlertId } = this.props

    oldAlertId !== newAlertId && this.fetchAlert()
  }

  fetchAlert() {
    const { alertId, alertFetch } = this.props

    alertFetch({
      alertId,
      toBeEdited: true,
    })
  }

  copiedSuccessfully = (what: string) => {
    const newState = {}
    newState[what] = true

    this.setState(newState)

    setTimeout(() => {
      newState[what] = false
      this.setState(newState)
    }, 4000)
  }

  copyEmails = () => {
    const { emailRecipients } = this.props
    getRecipientsNumbersString(emailRecipients, 'email') && this.copiedSuccessfully('emailsSuccessfullyCopied')
  }

  copySms = () => {
    const { alertsSms } = this.props
    getRecipientsNumbersString(alertsSms, 'mobile') && this.copiedSuccessfully('smsSuccessfullyCopied')
  }

  copyContentToClipboard = () => {
    const { contentString } = this.props
    copy(contentString) && this.copiedSuccessfully('contentSuccessfullyCopied')
  }

  render() {
    const { clearForm, initForm, clearRecipients } = this.props
    return (
      <AlertForm
        clearForm={clearForm}
        initForm={initForm}
        clearRecipients={clearRecipients}
        copyEmails={this.copyEmails}
        copySms={this.copySms}
        copyContentToClipboard={this.copyContentToClipboard}
        {...this.props}
        {...this.state}
      />
    )
  }
}

EditAlertContainer = reduxForm({
  form: 'alert.edit',
  keepDirtyOnReinitialize: false,
  enableReinitialize: true,
})(EditAlertContainer)

EditAlertContainer = connect(
  (state, ownProps) => {
    const alertId = parseInt(ownProps.params.alertId, 10)
    const alert = getAlertsDetails(state)[alertId]
    const time = alert ? timeConfigurationToWhen(alert.schedule.timeConfiguration) : undefined
    const localeValues = Object.values(locales)
    const locale = alert && localeValues.find(R.propEq('alertsLocale', alert.locale))

    // There might be a locale not found in our locales connection that backend sends.
    // We should set some default instead of failing.
    const language = locale ? locale.englishName : undefined

    const timeZone = alert && alert.schedule.timeZone
    const translate = alert && alert.translate

    return {
      alertId,
      initialValues: {
        ...alert,
        time,
        language,
        timeZone,
        translate,
      },
      emailRecipients: getAlertEmail(state),
      alertsSms: getAlertSms(state),
      contentString: getEditedAlertContentNamesString(state),
      autoTranslateOn: isAutoTranslationsOn(state), // global settings
      autoTranslateLanguage: getAutoTranslationLanguageName(state),
      autoTranslateReport: isAutoTranslateReport(state), // setting for this alert
    }
  },
  buildActionCreators({
    alertFetch: ActionTypes.ALERT_FETCH,
    clearForm: ActionTypes.CLEAR_FORM,
    initForm: ActionTypes.INIT_FORM,
    clearRecipients: ActionTypes.ALERT_CLEAR_RECIPIENTS,
  }),
)(EditAlertContainer)

export default EditAlertContainer

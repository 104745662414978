import React from 'react'
import classNames from 'classnames'
import R from 'ramda'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { translate } from 'react-i18next'

import buildActionCreators from '../../helpers/buildActionCreators'
import entityFilter from '../common/entityFilter'
import FilterMessage from '../common/FilterMessage'
import ModulePermissions from '../common/ModulePermissions'
import SubProfileListing from './SubProfileListing'
import Translate from '../../Translate'
import { MODULE, MODULE_OPTION } from '../../opoint/settings/index'
import { getProfiles, getProfileTree } from '../../selectors/profilesSelectors'
import { getProfilesMapping, getWatchQueries } from '../../selectors/watchIndexesSelectors'
import { getSelectedProfilesIds } from '../../selectors/searchSelectors'
import { getUISetting, getMaxProfiles } from '../../selectors/settingsSelectors'
import { searchContext } from '../../selectors/routingSelectors'
import * as ActionTypes from '../../constants/actionTypes'
import Tooltip from '../../new-components/common/Tooltip'

class ProfileListing extends React.Component<any, any> {
  isExpanded = () => {
    const { expanded, filterExpression } = this.props
    return expanded || !!filterExpression
  }

  toggleCategoryHandler = () => {
    this.props.toggleCategory('PROFILES_CATEGORY')
  }

  render() {
    const {
      filterExpression,
      list,
      profileTree,
      onNewProfileClicked,
      openManageProfilesModal,
      i18n,
      numberOfFiltered,
      resetFilter,
      displayImportant,
      showImportantSetting,
      toggleCategory,
      watchQueries,
      profilesToWatchIds,
      maxProfiles,
    } = this.props

    let profiles
    let profilesLength
    if (filterExpression && filterExpression.length) {
      profiles = list
      profilesLength = list.length
    } else if (displayImportant) {
      // @ts-ignore
      profiles = R.compose(R.take(10), R.sort(R.ascend(R.prop('score'))))(profileTree)
      profilesLength = profileTree.length
    } else {
      profiles = profileTree
      profilesLength = profileTree.length
    }

    const isProfilesLimitReached = profileTree.length >= maxProfiles

    const limitReachedTooltip = (
      <Tooltip content={`${i18n.t('You have reached your maximum of')} ${maxProfiles} ${i18n.t('profiles')}`}>
        <div className="toolip-trigger" />
      </Tooltip>
    )

    return (
      <div>
        <div
          className="op-content-left-profiles_wrapperList"
          style={{ overflow: 'hidden', position: 'relative', paddingTop: 20 }}
        >
          <div className="op-content-left-profiles-header_wrapper">
            <i
              className={classNames('op-icon-arrow-down', { 'mod-expanded': this.isExpanded() })}
              onClick={this.toggleCategoryHandler}
            />
            <h3 onClick={this.toggleCategoryHandler}>
              <Translate i18nString="Profiles" />
            </h3>
            <ModulePermissions module={MODULE.PROFILE} permissions={[MODULE_OPTION.ON]}>
              <div className="profiles_options">
                <button
                  className="op-button-square"
                  title={i18n.t('Manage profiles')}
                  onClick={openManageProfilesModal}
                >
                  <span className="op-icon-gear" />
                </button>
                <div>
                  {/* Disabled buttons don't trigger hover effects. This is a work-around. */}
                  {isProfilesLimitReached && limitReachedTooltip}
                  <button
                    className="op-button-square"
                    title={i18n.t('Create new profile')}
                    onClick={onNewProfileClicked}
                    disabled={isProfilesLimitReached}
                  >
                    <span className="op-icon-plus" />
                  </button>
                </div>
              </div>
            </ModulePermissions>
          </div>
        </div>

        <div
          className={classNames('op-content-left-profiles-profile-list_wrapper', { 'is-hidden': !this.isExpanded() })}
        >
          {!list.length && !filterExpression && <Translate i18nString="No profiles" />}
          <SubProfileListing
            profiles={profiles}
            searchContext={this.props.searchContext}
            profileIds={this.props.profileIds}
            isTree={!(filterExpression && filterExpression.length)}
            watchQueries={watchQueries}
            profilesToWatchIds={profilesToWatchIds}
          />
          <FilterMessage
            filterExpression={filterExpression}
            listLength={profilesLength}
            listName="profiles"
            numberOfFiltered={numberOfFiltered}
            resetFilter={resetFilter}
            displayImportant={displayImportant}
            showImportantSetting={showImportantSetting}
            toggleCategory={toggleCategory}
          />
        </div>
      </div>
    )
  }
}

// @ts-ignore
ProfileListing = entityFilter(ProfileListing)

// @ts-ignore
ProfileListing = connect(
  (state, props: any) => ({
    list: getProfiles(state),
    searchContext: searchContext(state),
    expanded: getUISetting('PROFILES_CATEGORY')(state),
    displayImportant: getUISetting(props.showImportantSetting)(state),
    // TODO remove this ui state with modal state
    profileTree: getProfileTree(state),
    profileIds: getSelectedProfilesIds(state),
    profilesToWatchIds: getProfilesMapping(state),
    watchQueries: getWatchQueries(state),
    maxProfiles: getMaxProfiles(state),
  }),
  {
    onNewProfileClicked: () => push('/search/new'),
    ...buildActionCreators({
      openManageProfilesModal: ActionTypes.MANAGE_PROFILES_MODAL_OPEN,
      toggleCategory: ActionTypes.TOGGLE_UI_SETTING,
      resetFilter: ActionTypes.ENTITY_FILTER_CHANGED,
      deleteProfiles: ActionTypes.PROFILE_DELETE,
      onProfileReorder: ActionTypes.PROFILE_REORDER,
    }),
  },
)(ProfileListing)

// @ts-ignore
ProfileListing = translate([], { wait: true })(ProfileListing)

export default ProfileListing

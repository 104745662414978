import React from 'react'
import moment from 'moment'
import * as d3 from 'd3'
import { connect } from 'react-redux'

import * as ActionTypes from '../../constants/actionTypes'
import buildActionCreators from '../../helpers/buildActionCreators'
import OpointDate from '../../opoint/common/time'
import Translate from '../../Translate'

const MAX_ARTICLES = 50000
const ABSOLUTE_MAX_ARTICLES = 60000

class TimeSliderSelect extends React.PureComponent<any> {
  componentDidMount() {
    const translateHelpInline = document.getElementById('translate-help-inline')
    if (translateHelpInline) {
      translateHelpInline.addEventListener('click', this.props.continueWithCurrent)
    }
  }

  componentWillUnmount() {
    const translateHelpInline = document.getElementById('translate-help-inline')
    if (translateHelpInline) {
      translateHelpInline.removeEventListener('click', this.props.continueWithCurrent)
    }
  }

  /*
    Change was requested based on issues:
    https://projects.m-brain.com/issues/11656,
    https://projects.m-brain.com/issues/11223
    to remove time 00:00 from the timeline
  */
  filterMidnight = (timestamp: string | number) => {
    const time = OpointDate.shortTimeFormat(timestamp)
    return time === '00:00' ? null : time
  }

  /**
   * Compute Time segments of time line selector
   * @param {Number} rangeStartRequested [ms]
   * @param {Number} rangeStartDelivered [ms]
   * @param {Number} rangeEnd [ms]
   * @param {Number} articlesCount
   * @param {Number} rangeCount
   * @returns {Array}
   */
  computeTimeSegments = (rangeStartRequested, rangeStartDelivered, rangeEnd, articlesCount, rangeCount, onSelect) => {
    const estimatedTime = estimateRangeMinDate(rangeStartDelivered, rangeEnd, articlesCount, MAX_ARTICLES)
    // Min range point estimates
    // If estimated_min_range <= requested_date, use requested date as min boundary
    const rangeStartMin = Math.max(
      rangeStartRequested,
      // this estimate should be only used in case MAX_ARTICLES limit could be potentially crossed
      estimatedTime,
    )

    let timeSegments = []
    const factors = [0.0, 0.25, 0.5, 0.75]
    const [minRange, maxRange] = [0, 100]

    const timeScale = d3.time
      .scale()
      .domain([new Date(rangeStartMin), new Date(rangeEnd)])
      .nice(d3.time.day)
      .rangeRound([minRange, maxRange])

    const [from, to] = [moment(timeScale.invert(minRange)), moment(timeScale.invert(maxRange))]
    const diffDays = to.diff(from, 'days')

    /*
     * Intermediate range points based on factors to be shown if they are
     * a. older than rangeStartDelivered
     * b. countEstimate smaller than absolute limit
     * these must be rounded explicitly
     * pushed from left to right (oldest to latest)
     */
    factors.forEach((factor, i) => {
      const dateEstimate = timeScale.invert(factor * maxRange)

      // round/ceil to beginning of day if the period is over 4 days and if is not first segment
      if (diffDays > 4 && i > 0) {
        // round down to whole day (eg. 2015-06-18 15:30:19 => 2015-06-18 00:00:00)
        dateEstimate.setHours(0, 0, 0, 0)
      }

      // this estimate is used only for tooltip info and is smaller than requested count
      let countEstimate

      // first segment should ask for all available articles,
      // so we use rounded range_count as estimate
      if (factor === 0.0) {
        countEstimate = Math.ceil(rangeCount / 1000) * 1000
      } else {
        countEstimate = estimateRangeMinCount(+dateEstimate, rangeStartDelivered, rangeEnd, articlesCount, 1)
      }

      if (+dateEstimate < rangeStartDelivered) {
        timeSegments.push({
          delivered: false,
          timestamp: +dateEstimate,
          count: Math.min(countEstimate, MAX_ARTICLES),
          percent: 0,
          onClick: requestMore,
        })
      }
    })

    /*
     * Delivered point estimates
     * no count estimate needed, we add rangeStartDelivered
     */
    timeSegments.push({
      delivered: true,
      timestamp: rangeStartDelivered,
      count: articlesCount,
      percent: 0,
      onClick: requestMore,
    })

    /*
     * Max range point estimates
     * no count estimate needed, width === 0, already rounded by d3 .nice()
     */
    timeSegments.push({
      delivered: true,
      timestamp: rangeEnd,
      count: 0,
      percent: 0,
      onClick: requestMore,
    })

    // calculate width percentage for each slider cell
    const totalRangeSize = to.diff(from)
    timeSegments.forEach((segment, i, segments) => {
      // the last point (latest/right most) should have no width
      if (i === segments.length - 1) {
        segment.percent = 0
        return
      }
      // rest should have width computed from its date
      const segmentRangeSize = segments[i + 1].timestamp - segments[i].timestamp
      segment.percent = Math.floor((segmentRangeSize / totalRangeSize) * 100)
      // with only one range we must not use all the available space: assign 99
      if (segment.percent === 100) {
        segment.percent -= 1
      }
    })

    // get rid of small segments: add to previous percentage
    // but only if they are not in the last two places
    timeSegments = timeSegments.filter((segment, i, segments) => {
      if (segment.percent < 10 && i < segments.length - 2) {
        const prevSegment = segments[i - 1]
        if (prevSegment && prevSegment.percent) {
          prevSegment.percent += segment.percent
          return false
        }
      }
      return true
    })

    // get rid of small first of two segments
    // the reason why segmentsCount should be 3
    // to pass this condition is that
    // there is always one last segment
    // with 0 persent
    const segmentsCount = timeSegments.length
    if (segmentsCount === 3 && timeSegments[0].percent < 10 && timeSegments[segmentsCount - 1].percent === 0) {
      timeSegments[0].percent = 10
      timeSegments[1].percent = 89
    }

    // bind onClicks
    timeSegments.forEach((segment) => {
      segment.onClick = segment.onClick.bind(segment)
    })

    return timeSegments

    function requestMore() {
      if (!this.delivered) {
        // always ask for max even if count estimate is much lower,
        // it will be limited by date anyway
        onSelect(+this.timestamp, ABSOLUTE_MAX_ARTICLES)
      }
    }

    /**
     * Compute date that would return maxCount articles
     * Data: end, start, count. Get difference between end and start dates.
     * Assuming a linear interpolation, a simple proportion estimates a date
     * in the past that approximates maxArticles.
     * @param {Number} start
     * @param {Number} end
     * @param {Number} count
     * @param {Number} maxCount
     * @returns {number}
     */
    function estimateRangeMinDate(start, end, count, maxCount) {
      /*      diff ~ count
       *         x ~ count * factor
       *         x = ((count * factor) * diff) / count
       * start - x = estimate
       */
      const factor = maxCount / count
      return Math.floor(start - (count * factor * (end - start)) / count)
    }

    /**
     * Simple proportion to estimate how many articles we should ask for
     * a given min start date (not yet used).
     * Data: current start and end dates (range) and number of articles we got.
     * @param {Number} min
     * @param {Number} start
     * @param {Number} end
     * @param {Number} count
     * @param factor
     * @returns {Number}
     */
    function estimateRangeMinCount(min, start, end, count, factor) {
      /* end - start ~ count
       * start - min ~ x
       * ->        x = ((start - min) * count) / (end -start)
       */
      let estimate = Math.floor(((start - min) * count) / (end - start))
      // add estimate to current count
      estimate += count
      // make estimate bigger than it is to help searchd
      estimate *= factor
      // round up to nearest 1000
      estimate = Math.ceil(estimate / 1000) * 1000
      return estimate
    }
  }

  render() {
    const { articlesCount, rangeCount, rangeEnd, rangeStartRequested, rangeStartDelivered, onSelect } = this.props

    const timeSegments = this.computeTimeSegments(
      rangeStartRequested,
      rangeStartDelivered,
      rangeEnd,
      articlesCount,
      rangeCount,
      onSelect,
    )

    return (
      <div>
        <p className="help-inline">
          <span>
            <Translate isDangerous i18nString="Only some articles from originally requested range were included." />
          </span>
        </p>
        <p className="help-inline">
          <span>
            <Translate
              isDangerous
              i18nString="You may {{- htmlTagStart}}continue to explore these articles{{- htmlTagEnd}}, or include more by selecting a longer range on the timeline below."
              context={{
                htmlTagStart: "<a id='translate-help-inline'>",
                htmlTagEnd: '</a>',
              }}
            />
          </span>
        </p>
        <p className="help-inline">
          <span>
            <Translate i18nString="Including many articles may take some time." />
          </span>
        </p>
        <ul className="time-slider-select">
          {timeSegments.map((timeSegment, i) => (
            <li
              key={i}
              className={timeSegment.delivered ? 'delivered' : ''}
              onClick={timeSegment.onClick}
              title={
                timeSegment.delivered
                  ? `${OpointDate.shortFormat(timeSegment.timestamp)} - ${timeSegment.count} articles`
                  : `${OpointDate.shortFormat(timeSegment.timestamp)} - Approximately ${timeSegment.count} articles`
              }
              style={{
                width: `${timeSegment.percent}%`,
              }}
            >
              <div className="time-label">
                <span className="time-slider-date">{OpointDate.shortDateFormat(timeSegment.timestamp)}</span>
                <span className="time-slider-hour">{this.filterMidnight(timeSegment.timestamp)}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

// @ts-ignore
TimeSliderSelect = connect(
  (state) => ({}),
  buildActionCreators({
    continueWithCurrent: ActionTypes.SEARCH_CHANGE_DATERANGE_TO_CURRENT,
  }),
)(TimeSliderSelect)

export default TimeSliderSelect

import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { isAuthenticated } from '../../opoint/auth/index'
import * as ActionTypes from '../../constants/actionTypes'
import buildAction from '../../helpers/buildAction'
import { getUser } from '../../selectors/authSelectors'
import { getSettingsFetched } from '../../selectors/settingsSelectors'

export default function requireAuthentication(ComposedComponent) {
  class AuthenticatedComponent extends React.Component<any, any> {
    componentWillMount() {
      this.checkAuth()
    }

    componentWillReceiveProps() {
      this.checkAuth()
    }

    checkAuth() {
      const {
        dispatch,
        location: {
          query: { h: token },
        },
      } = this.props

      if (token) {
        dispatch(buildAction(ActionTypes.LOG_IN_WITH_TOKEN, { token }))
        return
      }

      if (!isAuthenticated()) {
        const redirectAfterLogin = this.props.location.pathname
        dispatch(push(`/login?next=${redirectAfterLogin}`))
      }
    }

    render() {
      const { dispatch } = this.props

      if (!this.props.user || !this.props.settingsFetched) {
        return null
      }

      if (this.props.location.pathname === '/') {
        dispatch({ type: ActionTypes.GO_TO_DEFAULT_PROFILE })
      }

      return <ComposedComponent {...this.props} />
    }
  }

  return connect((state) => ({
    user: getUser(state),
    settingsFetched: getSettingsFetched(state),
  }))(AuthenticatedComponent)
}

import React from 'react'
import { connect } from 'react-redux'

import buildActionCreators from '../../helpers/buildActionCreators'
import Translate from '../../Translate'
import { getSearchFilters } from '../../selectors/searchSelectors'
import { getUserPermissions } from '../../selectors/settingsSelectors'
import { MODULE, MODULE_OPTION } from '../../opoint/settings/index'
import * as ActionTypes from '../../constants/actionTypes'

class RemoveArticleButton extends React.Component<any, any> {
  onClickHandler = (onClick) => () => {
    this.props.hidePopover()
    onClick()
  }

  deleteTagsFromArticle = (tags) => () => this.props.removeTagsFromArticle({ tags })

  render() {
    const { deleteArticlesFromProfile, searchFilters, isFromPopover, isPermitted, keyRef } = this.props
    const filters: any = Object.values(searchFilters)
    if (!filters.length) {
      return null
    }
    const isProfileSearch = filters[0].type === 'profile'
    const name = isProfileSearch ? (
      <Translate i18nString="Delete from profiles" />
    ) : (
      <Translate i18nString="Remove from tags" />
    )
    const onClick = isProfileSearch ? deleteArticlesFromProfile : this.deleteTagsFromArticle(filters)
    if (isFromPopover) {
      return (
        <li className="actionLine-popover-child" key={keyRef} onClick={this.onClickHandler(onClick)}>
          <a>
            <i className="op-icon-pencil" />
            {name}
          </a>
        </li>
      )
    }
    return (
      <span>
        {isProfileSearch ? (
          <button className="op-button-toolbar" onClick={onClick} disabled={!isPermitted}>
            {name}
            <i className="op-icon-pencil" />
          </button>
        ) : (
          <button className="op-button-toolbar" disabled={!isPermitted} onClick={onClick}>
            {name}
            <i className="op-icon-pencil" />
          </button>
        )}
      </span>
    )
  }
}

export default connect(
  (state) => {
    // @ts-ignore
    const userPermissions = getUserPermissions(MODULE.TRASH)(state)
    const isPermitted = MODULE_OPTION.ON === userPermissions
    return {
      searchFilters: getSearchFilters(state),
      isPermitted,
    }
  },
  buildActionCreators({
    deleteArticlesFromProfile: ActionTypes.DELETE_ARTICLES_PROFILES_TOOLBAR,
    removeTagsFromArticle: ActionTypes.REMOVE_TAGS_FROM_ARTICLES,
  }),
)(RemoveArticleButton)

import React, { ReactNode } from 'react'
import { default as ReactModal } from 'react-modal'
import { useTranslation } from 'react-i18next-latest'

import Translate from '../Translate'
import styles from './index.module.scss'

export interface Props {
  title: string
  isOpen: boolean
  onHide: () => void
  children: ReactNode
  footer?: ReactNode
}

const Modal = ({ isOpen, onHide, title, children, footer }: Props) => {
  const { t } = useTranslation()

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onHide}
      contentLabel={title}
      className={styles.modal}
      overlayClassName={styles.overlay}
      closeTimeoutMS={300}
    >
      <div className={styles.header}>
        <h2>
          <Translate text={title} />
        </h2>
        <button type="button" aria-label={t('Close')} onClick={onHide}>
          ×
        </button>
      </div>
      <div className={styles.content}>{children}</div>
      {footer && <div className={styles.footer}>{footer}</div>}
    </ReactModal>
  )
}

export default Modal

import React from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import buildActionCreators from '../../../helpers/buildActionCreators'
import Loader from '../../common/Loader'
import Translate from '../../../Translate'
import * as ActionTypes from '../../../constants/actionTypes'

type Props = {
  // TODO: Proper flowtype @honza
  alertId: number
  history: any
  next: any
  onHistoryItemClick: (alertId: number, historyId: number, historyTimestamp: number) => void
  onNextAlertClick: (o: { alertId: number }) => void
}

type State = {
  activeItem?: number | null
}

class TemplateHistory extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      activeItem: null,
    }
  }

  onNextAlertClickHandler = () => {
    const { alertId, onNextAlertClick } = this.props

    this.setState({ activeItem: null })
    onNextAlertClick({ alertId })
  }

  onHistoryItemClickHandler = (historyId: number, historyTimetamp: number) => () => {
    const { alertId, onHistoryItemClick } = this.props

    this.setState({ activeItem: historyTimetamp })
    onHistoryItemClick(alertId, historyId, historyTimetamp)
  }

  formatTime = (time: string) => moment(time).format('LLLL')
  itemIsActive = (itemId: number) => itemId === this.state.activeItem

  render() {
    const { history, next } = this.props
    const { activeItem } = this.state

    if (!history) {
      return <Loader />
    }

    return (
      <div className="col-md-3 op-content-right_wrapper mod-alerts">
        <div className="op-content-right_wrapper__row row">
          {next && !!next.count && (
            <div className="col-md-10 op-content-right-section_wrapper">
              <div>
                <div className="row op-content-right-section-header op-content-right-section-header-title--wrapper">
                  <div className="op-content-right-section-header-title">
                    <Translate i18nString="Next alert" />
                  </div>
                </div>
                <div
                  className={classNames('row op-content-right-section-header', { 'is-active': !activeItem })}
                  onClick={this.onNextAlertClickHandler}
                >
                  <div>
                    <span>
                      {next.count === 1 ? (
                        <Translate i18nString="Contains 1 article" />
                      ) : (
                        <Translate
                          i18nString="Contains {{articleCount}} articles"
                          context={{
                            articleCount: next.count,
                          }}
                        />
                      )}
                    </span>
                    &nbsp;
                    {next.nextPossibleSend && (
                      <span>
                        <Translate i18nString="and will be sent" />
                        &nbsp;
                        {this.formatTime(next.nextPossibleSend)}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="col-md-10 op-content-right-section_wrapper">
            {/* eslint-disable-next-line max-len */}
            <div className="row op-content-right-section-header op-content-right-section-header-title--wrapper">
              <div className="op-content-right-section-header-title">
                <Translate i18nString="Sent alerts" />
              </div>
            </div>
            {history.results.map((item, key) => (
              <div
                className={classNames('row op-content-right-section-header', {
                  'is-active': this.itemIsActive(item.stimestamp) && !item.failed,
                  'is-disabled': item.failed,
                })}
                key={key}
                onClick={!item.failed ? this.onHistoryItemClickHandler(item.id, item.stimestamp) : undefined}
              >
                <div>
                  {item.failed && (
                    <span style={{ color: 'red', marginRight: '1em' }}>
                      <Translate i18nString="Failed" />
                    </span>
                  )}
                  <span>{this.formatTime(item.date)}</span>
                </div>
              </div>
            ))}
          </div>
          {!history.count && (
            <div className="op-content-right-alert-no-history text-center">
              <Translate i18nString="This alert has no history" />
            </div>
          )}
          {history.next && (
            <div className="op-content-right-info animate">
              <button className="op-button-load-more">
                <Translate i18nString="Load more" />
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default connect(
  (state: any, ownProps: any) => {
    const { alertId } = ownProps.params
    const locale = moment.locale()
    return {
      alertId,
      next: state.alerts.next[alertId],
      history: state.alerts.history[alertId], // TODO selectors
      locale,
    }
  },
  {
    onHistoryItemClick: (alertId, historyId, historyTimestamp) =>
      push({
        pathname: `/alerts/${alertId}`,
        query: {
          historyId,
          historyTimestamp,
        },
      }),
    ...buildActionCreators({
      onNextAlertClick: ActionTypes.GO_TO_ALERT,
    }),
  },
)(TemplateHistory)

import React from 'react'
// @ts-ignore
import { Modal } from 'react-bootstrap'

import Translate from '../../../Translate'

type Props = {
  isOpen: boolean
  closeModal: () => void
  discardAndClose: () => void
}

class LeaveEditedtModal extends React.PureComponent<Props> {
  render() {
    const { isOpen, closeModal, discardAndClose } = this.props

    return (
      <Modal show={isOpen} onHide={closeModal} className="modal-dialog-warning">
        <Modal.Header>
          <h4 className="modal-title">
            <Translate i18nString="You have unsaved changes" />
          </h4>
        </Modal.Header>
        <Modal.Body>
          <div className="flex">
            <button type="button" className="op-button green" onClick={closeModal}>
              <Translate i18nString="Continue editing" />
            </button>
            <button type="button" className="op-button red" onClick={discardAndClose}>
              <Translate i18nString="Discard changes and close" />
            </button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

export default LeaveEditedtModal

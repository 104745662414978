// @ts-nocheck
import React from 'react'
import R from 'ramda'
import Rx from 'rx-dom'
import { connect } from 'react-redux'
import { Tooltip } from 'react-bootstrap'

import Loader from './common/Loader'
import ArticleReactComponent from '../components/articles/preview'
import ArticlesShortcuts from './articles/ArticlesShortcuts'
import BaseArticle from './articles/BaseArticle'
import buildActionCreators from '../helpers/buildActionCreators'
import LoadMore from './search/LoadMore'
import SimpleListingArticle from '../components/articles/simple'
import Translate from '../Translate'
import { getActiveTrashTags, getAllTagLikeEntities } from '../selectors/tagsSelectors'
import {
  getArticleListingStyle,
  getArticleMetadata,
  getUISetting,
  getActiveLanguage,
} from '../selectors/settingsSelectors'
import { getShowFilteredArticles } from '../selectors/statisticsSelectors'
import { isSearchNotEmpty } from '../selectors/searchSelectors'
import { LISTING_STYLES } from '../opoint/settings'
import { getPreviewArticles, getPreviewIdentical, noPreviewArticlesFound } from '../selectors/profilesSelectors'
import { articleId, crossScrollTreshold, getActiveArticleNode, getIdenticalArticle } from '../opoint/articles/index'
import {
  getActive,
  getArticles,
  getCheckedArticles,
  getIdenticalArticles,
  noArticlesFound,
} from '../selectors/articlesSelectors'
import * as ActionTypes from '../constants/actionTypes'
import type {
  SetActiveArticle,
  Article,
  AppLocation,
  // AppRoute,
} from '../opoint/flow'
import ArticlePlaceholder from '../new-components/articles/ArticlePlaceholder'

const editTooltip = (
  <Tooltip id="editTooltip">
    <Translate i18nString="Edit article" />
  </Tooltip>
)
const shareTooltip = (
  <Tooltip id="shareTooltip">
    <Translate i18nString="Share this article" />
  </Tooltip>
)

const ArticleReactComponentExtended = BaseArticle(ArticleReactComponent)
const SimpleListingArticleExtended = BaseArticle(SimpleListingArticle)

export const NoArticles = ({ isSearch, mod, shorted }: { isSearch: boolean; mod: string; shorted: boolean }) => (
  <div className={`op-content-article-listing-no-hits ${mod}`}>
    <div className="util_center-parent">
      <div className="util_center-child">
        {isSearch ? (
          <span>
            <Translate i18nString="Sorry, this search did not return any hits" />.
          </span>
        ) : (
          <span>
            {shorted ? (
              <Translate i18nString="Use search field to search for news" />
            ) : (
              <Translate i18nString="Use search field to search for news or click on one of the profiles in left column" />
            )}
            .
          </span>
        )}
      </div>
    </div>
  </div>
)

type ArticlesProps = {
  // identical: { [key: string]: number },
  // params: any,
  // route: AppRoute,
  // routeParams: any,
  // router: any,
  // routes: Array<AppRoute>,
  active: SetActiveArticle
  articleListingStyle: number
  articles: Array<Article>
  articleSource: string
  checkedArticles: { [key: string]: boolean }
  children: any
  fetchArticles: () => void
  identicalArticles: { [key: string]: number }
  isProfileEditor: boolean
  isSearch: boolean
  locale: string
  location: AppLocation
  noArticlesFound: boolean
  onCheckedToggle: (article: Article) => void
  onEditArticleModalOpen: ({ article: Article }) => void
  searchInProgress: boolean
  setActiveArticle: ({ index: number, source: string }) => void
  shareArticle: (article: Article) => void
  shouldHideTags: any
  tagsFn: (article: Article) => void
  visibleTrashTagsFn: (article: Article) => void
}

class Articles extends React.Component<ArticlesProps> {
  constructor(props) {
    super(props)

    this.previewRef = null
    this.articlesRefs = {}
    this.scrollWithThrottle$ = null
    this.scrollWithoutThrottle$ = null
  }

  componentDidMount() {
    const { fetchArticles } = this.props
    fetchArticles()

    this.setStickyFooterAndSidePanel()

    this.scrollWithoutThrottle$ = Rx.Observable.fromEvent(this.previewRef, 'scroll').subscribe(() => {
      this.setStickyFooterAndSidePanel()
    })

    this.scrollWithThrottle$ = Rx.Observable.fromEvent(this.previewRef, 'scroll')
      .throttle(20)
      .subscribe(() => {
        this.activeArticleChange(this.previewRef, this.articlesRefs)
      })
  }

  componentWillUpdate(nextProps) {
    if (nextProps.location) {
      const {
        location: { query: nextQuery },
      } = nextProps
      const {
        location: { query },
        fetchArticles,
      } = this.props
      if (R.not(R.equals(query, nextQuery))) {
        fetchArticles()
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { articles, active, noArticlesFound /* eslint-disable-line no-shadow */ } = this.props

    // After successfully fetching articles, setStickyFooter
    // there is currently not better way of doing this
    if (prevProps.noArticlesFound && !noArticlesFound) {
      this.setStickyFooterAndSidePanel()
    }

    if (articles[active.index] === undefined || prevProps.active.index === active.index) {
      return
    }

    if (['click', 'keyPress'].includes(active.source)) {
      const activeArticle = getActiveArticleNode(this.articlesRefs, articles[active.index])
      this.previewRef.scrollTop = 0
      this.previewRef.scrollTop =
        activeArticle.getBoundingClientRect().top - this.previewRef.getBoundingClientRect().top
    }
  }

  componentWillUnmount() {
    this.scrollWithThrottle$.dispose()
    this.scrollWithoutThrottle$.dispose()
  }

  // TODO: consider creating callback function to be sent down to article
  // components in order to retrigger footer check from single article
  // eg. when the article is expanded.
  // Current behavior is in line with m360 but it would provide better UX
  setStickyFooterAndSidePanel = () => {
    const { articles, articleListingStyle, active } = this.props

    if (articleListingStyle === LISTING_STYLES.ARCHIVE_LEFT || articleListingStyle === LISTING_STYLES.ARCHIVE_RIGHT) {
      if (articles[active.index]) {
        const id = articleId(articles[active.index])
        const article = this.articlesRefs[id].articleRef
        article.checkStickySidePanel && article.checkStickySidePanel()
      }
      return
    }

    // TODO: perf optimalization - on keyPress we are moving only
    // one article up or down, there shouldn't be necessary to set index to
    // 0 and calculate unnecessary articles
    //
    // TODO: we have to check all articles due to various forms of scrolling, this may affect
    // performance, maybe it would be better to keep some map of active sticky footers to
    // switch them off selectively instead of brute force
    let i = 0

    while (i < articles.length) {
      const id = articleId(articles[i])

      // we need to go 2 lvls deep with refs as first ref is baseArticle, second
      // is ArticlePreviewComponent
      const article = this.articlesRefs[id].articleRef

      // set sticky footers (or remove them to ensure only one sticky
      // footer is active)
      article.checkSticky && article.checkSticky()
      article.checkStickySidePanel && article.checkStickySidePanel()

      i += 1
    }
  }

  previewRef?: HTMLDivElement
  articlesRefs: { [key: string]: React.DOM }
  scrollWithThrottle$?: Object
  scrollWithoutThrottle$?: Object

  activeArticleChange = (container, articlesRefs) => {
    const { setActiveArticle, active, articles } = this.props
    const willChangeActive = crossScrollTreshold(container, articles, articlesRefs, active.index)

    if (willChangeActive !== null) {
      setActiveArticle({ index: willChangeActive, source: 'scroll' })
    }
  }

  render() {
    const {
      active,
      articleListingStyle,
      articles,
      articleSource,
      checkedArticles,
      identicalArticles,
      isProfileEditor,
      isSearch,
      locale,
      noArticlesFound /* eslint-disable-line no-shadow */,
      onCheckedToggle,
      onEditArticleModalOpen,
      shareArticle,
      shouldHideTags,
      tagsFn,
      visibleTrashTagsFn,
      searchInProgress,
      articleMetadata,
    } = this.props

    if (articleListingStyle === LISTING_STYLES.ARCHIVE_LEFT || articleListingStyle === LISTING_STYLES.ARCHIVE_RIGHT) {
      return (
        <div
          className="col-md-12 op-content-article-listing"
          id="article-previews"
          ref={(ref) => {
            this.previewRef = ref
          }}
        >
          {searchInProgress && <Loader />}
          <ArticlesShortcuts />
          {/* Profile editor is a sub state of articles BEGIN */}
          {this.props.children}
          {articles[active.index] && (
            <ArticleReactComponentExtended
              active
              allowExpanded={false}
              activeArticle={getIdenticalArticle(identicalArticles, articles[active.index])}
              article={articles[active.index]}
              articleSource={articleSource}
              checkedArticles={checkedArticles}
              editTooltip={editTooltip}
              locale={locale}
              shareTooltip={shareTooltip}
              identical={identicalArticles[articleId(articles[active.index])]}
              key={articleId(articles[active.index])}
              onCheckedToggle={onCheckedToggle}
              onEditArticleModalOpen={onEditArticleModalOpen}
              ref={(ref) => {
                this.articlesRefs[articleId(articles[active.index])] = ref
              }}
              articleMetadata={articleMetadata}
              shareArticle={shareArticle}
              shouldHideTags={shouldHideTags}
              tagsFn={tagsFn}
              visibleTrashTagsFn={visibleTrashTagsFn}
            />
          )}
          {!active.index && active.index !== 0 && (
            <ArticlePlaceholder alignLeft={articleListingStyle === LISTING_STYLES.ARCHIVE_LEFT} />
          )}
        </div>
      )
    }

    if (articleListingStyle === LISTING_STYLES.SEARCH_ENGINE) {
      return (
        <div
          className="col-md-12 op-content-article-listing"
          id="article-previews"
          ref={(ref) => {
            this.previewRef = ref
          }}
        >
          {searchInProgress && <Loader />}
          <ArticlesShortcuts />
          {/* Profile editor is a sub state of articles BEGIN */}
          {this.props.children}
          {/* Profile editor is a sub state of articles END */}
          {noArticlesFound && !isProfileEditor ? (
            <NoArticles isSearch={isSearch} />
          ) : (
            articles.map((article) => (
              <SimpleListingArticleExtended
                activeArticle={getIdenticalArticle(identicalArticles, article)}
                article={article}
                articleSource={articleSource}
                checkedArticles={checkedArticles}
                editTooltip={editTooltip}
                locale={locale}
                identical={identicalArticles[articleId(article)]}
                key={articleId(article)}
                onCheckedToggle={onCheckedToggle}
                onEditArticleModalOpen={onEditArticleModalOpen}
                ref={(ref) => {
                  this.articlesRefs[articleId(article)] = ref
                }}
                articleMetadata={articleMetadata}
                shareArticle={shareArticle}
                shareTooltip={shareTooltip}
                shouldHideTags={shouldHideTags}
                tagsFn={tagsFn}
                visibleTrashTagsFn={visibleTrashTagsFn}
              />
            ))
          )}
        </div>
      )
    }

    return (
      <div
        className="col-md-12 op-content-article-listing"
        id="article-previews"
        ref={(ref) => {
          this.previewRef = ref
        }}
      >
        {searchInProgress && <Loader />}
        <ArticlesShortcuts />
        {/* Profile editor is a sub state of articles BEGIN */}
        {this.props.children}
        {/* Profile editor is a sub state of articles END */}
        {noArticlesFound && !isProfileEditor ? (
          <NoArticles isSearch={isSearch} />
        ) : (
          <div>
            {articles.map((article, index) => (
              <ArticleReactComponentExtended
                active={active.index === index}
                activeArticle={getIdenticalArticle(identicalArticles, article)}
                article={article}
                articleSource={articleSource}
                checkedArticles={checkedArticles}
                editTooltip={editTooltip}
                shareTooltip={shareTooltip}
                locale={locale}
                identical={identicalArticles[articleId(article)]}
                key={articleId(article)}
                onCheckedToggle={onCheckedToggle}
                onEditArticleModalOpen={onEditArticleModalOpen}
                ref={(ref) => {
                  this.articlesRefs[articleId(article)] = ref
                }}
                articleMetadata={articleMetadata}
                shareArticle={shareArticle}
                shouldHideTags={shouldHideTags}
                tagsFn={tagsFn}
                visibleTrashTagsFn={visibleTrashTagsFn}
              />
            ))}
            <div className="op-content-article-listing-info">
              <LoadMore />
            </div>
          </div>
        )}
      </div>
    )
  }
}

export function articleConnect(state: any) {
  const {
    routing: {
      locationBeforeTransitions: { pathname },
    },
  } = state
  let isProfileEditor = false
  const shouldHideTags = getUISetting('HIDE_ALL_TAGS')(state)
  // TODO we do this check in header.js as well, needs refactor
  if (pathname === '/search/edit/' || pathname === '/search/new') {
    const previewArticles = getPreviewArticles(state)
    const previewIdentical = getPreviewIdentical(state)
    // TODO preview will show old articles if preview API call returns 0 articles.
    // The solution is to fetch metadata from searchd and identify the succerss of a request
    // using searchd's metadata instead of number of articles
    isProfileEditor = true
    if (!R.isEmpty(previewArticles)) {
      return {
        active: getActive(state),
        articles: previewArticles,
        articleSource: 'profilePreview',
        checkedArticles: getCheckedArticles(state),
        identical: previewIdentical,
        identicalArticles: getIdenticalArticles(state),
        isProfileEditor,
        articleMetadata: getArticleMetadata(state),
        isSearch: isSearchNotEmpty(state),
        locale: getActiveLanguage(state),
        noArticlesFound: noPreviewArticlesFound(state),
        showFilteredArticles: getShowFilteredArticles(state),
        tagsFn: getAllTagLikeEntities(state),
        visibleTrashTagsFn: getActiveTrashTags(state),
        searchInProgress: state.search.searchInProgress,
      }
    }
  }

  return {
    active: getActive(state),
    articleListingStyle: getArticleListingStyle(state),
    articles: getArticles(state),
    articleSource: 'articles',
    checkedArticles: getCheckedArticles(state),
    identical: getIdenticalArticles(state),
    identicalArticles: getIdenticalArticles(state),
    isProfileEditor,
    articleMetadata: getArticleMetadata(state),
    isSearch: isSearchNotEmpty(state),
    locale: getActiveLanguage(state),
    noArticlesFound: noArticlesFound(state),
    shouldHideTags,
    tagsFn: getAllTagLikeEntities(state),
    visibleTrashTagsFn: getActiveTrashTags(state),
    searchInProgress: state.search.searchInProgress,
  }
}

const ArticlesConnect = connect(
  articleConnect,
  buildActionCreators({
    fetchArticles: ActionTypes.FETCH_ARTICLES,
    onCheckedToggle: ActionTypes.CHECK_ARTICLE_TOGGLE,
    onEditArticleModalOpen: ActionTypes.EDIT_ARTICLE_MODAL_OPEN,
    setActiveArticle: ActionTypes.SET_ACTIVE_ARTICLE,
    shareArticle: ActionTypes.SHARE_ARTICLE_MODAL_OPEN,
  }),
)(Articles)

export default ArticlesConnect

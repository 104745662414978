import React from 'react'
import PropTypes from 'prop-types'
import R from 'ramda'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'

import buildActionCreators from '../../../helpers/buildActionCreators'
import ManageTagForm from './ManageTagForm'
import * as Actions from '../../../constants/actionTypes'

const addTagStoreLens = R.lensPath(['form', 'tag', 'add', 'values'])

class AddTagPopover extends React.Component<any, any> {
  static contextTypes = {
    destroyAddTagPopover: PropTypes.func,
    i18n: PropTypes.object,
  }

  getButtons() {
    const { i18n } = this.context

    return [
      {
        onClick: this.addTagClickHandler,
        placeholder: i18n.t('Save'),
      },
    ]
  }

  addTagClickHandler = () => {
    const { destroyAddTagPopover } = this.context
    const { addTag, tag } = this.props

    addTag({ tag })
    destroyAddTagPopover()
  }

  render() {
    const buttons = this.getButtons()

    return <ManageTagForm buttons={buttons} />
  }
}

// @ts-ignore
AddTagPopover = translate([], { wait: true })(AddTagPopover)

// @ts-ignore
AddTagPopover = reduxForm({
  form: 'tag.add',
  initialValues: {
    color: 'red',
    type: 1,
    visibility: 2,
  },
  keepDirtyOnReinitialize: false,
  enableReinitialize: true,
  // @ts-ignore
})(AddTagPopover)

export default connect(
  (state) => ({
    tag: R.view(addTagStoreLens, state),
  }),
  buildActionCreators({
    addTag: Actions.ADD_TAG,
  }),
)(AddTagPopover)

import React from 'react'
import { translate } from 'react-i18next'

import ReactTagsAutocomplete from '../../react-tags-autocomplete/src/ReactTagsAutocomplete'
import { filtersTypeEq, compareFilters } from '../../opoint/search/index'

class SearchInput extends React.PureComponent<any> {
  render() {
    return (
      <ReactTagsAutocomplete
        // @ts-ignore
        sortingFunction={compareFilters}
        // @ts-ignore
        typeComparator={filtersTypeEq}
        {...this.props}
      />
    )
  }
}

export default translate([], { wait: true })(SearchInput)

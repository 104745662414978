// @ts-nocheck
import React from 'react'
import classNames from 'classnames'
import PopperJS from 'popper.js'
import R from 'ramda'

import MultiSelectDropdown from './MultiSelectDropdown'

class MultiSelect extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      selecting: false,
      firstVisited: {},
      lastVisited: {},
      popper: null,
      hours: R.range(0, 24).map((index) => ({ key: index })),
    }
  }

  componentWillMount() {
    this.loadData(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.loadData(nextProps)
  }

  getDataFormat(interval, key) {
    const { data } = this.props
    const newData = R.filter(
      (value) => value !== null,
      data.map((value) => {
        if (value.interval.includes(key)) {
          if (interval.interval.length >= 1) {
            value = interval /* eslint-disable-line no-param-reassign */
          } else {
            value = null /* eslint-disable-line no-param-reassign */
          }
        }
        return value
      }),
    )
    return newData
  }

  expandSelection(event) {
    const { lastVisited, firstVisited, hours } = this.state
    const { onChange, data } = this.props

    const oldData = data.map((eachData) => (eachData.interval.includes(firstVisited.key) ? eachData : null))

    // Group of hours that we started selected from
    const previouslySelected = R.filter((eachData) => eachData !== null, oldData).pop()
    const previousIntervalSelected = previouslySelected.interval

    // All groups of hours except the one that we are currently changing
    const newData = data.map((eachData) => (!eachData.interval.includes(firstVisited.key) ? eachData : null))

    const newSelection = R.filter((eachData) => eachData !== null, newData)

    // cycle that goes through selected values (first to last, i.e. 4-7, 10-6...)
    // and unhighlights them, plus pushes them into array
    const currentlySelected = []
    if (firstVisited.key < lastVisited.key) {
      for (let i = firstVisited.key; i <= lastVisited.key; i++) {
        currentlySelected.push(i)
        hours[i].preselected = false
      }
    } else {
      for (let i = lastVisited.key; i <= firstVisited.key; i++) {
        currentlySelected.push(i)
        hours[i].preselected = false
      }
    }

    // Sorted union of old values of group, with added new values
    const finallySelected = R.sort(R.subtract, R.union(previousIntervalSelected, currentlySelected))

    // If there is already something added of those values, screw adding new things.
    const previouslySelectedHours = R.unnest(newSelection.map((value) => value.interval))
    if (R.intersection(previouslySelectedHours)(currentlySelected).length) {
      return
    }

    const hoursToBeDeleted = R.intersection(previousIntervalSelected, currentlySelected)

    // If we are collapsing and not expanding, lets do this
    if (
      hoursToBeDeleted.length !== 1 &&
      (firstVisited.key === previousIntervalSelected[0] ||
        firstVisited.key === previousIntervalSelected[previousIntervalSelected.length - 1])
    ) {
      this.collapseSelection(event, previouslySelected, currentlySelected)
      return
    }

    this.setState({
      firstVisited: {},
      lastVisited: {},
    })
    // Finally, update data structure
    const newInterval = {
      ...previouslySelected,
      interval: finallySelected,
    }
    onChange(this.getDataFormat(newInterval, firstVisited.key))
    if (finallySelected.length > 1) {
      this.showPopover(event, newInterval)
    } else if (finallySelected.length === 1) {
      this.showPopover(event, newInterval)
    }
  }

  collapseSelection(event, previouslySelected, currentlySelected) {
    const { lastVisited, firstVisited } = this.state
    const { onChange } = this.props
    const previousIntervalSelected = previouslySelected.interval

    let notDeleted = R.difference(previousIntervalSelected, currentlySelected)
    if (previousIntervalSelected.includes(lastVisited.key)) {
      notDeleted.push(lastVisited.key)
    }
    notDeleted = R.sort(R.subtract, notDeleted)

    const newInterval = {
      ...previouslySelected,
      interval: notDeleted,
    }

    if (notDeleted.length > 1) {
      this.showPopover(event, newInterval)
    } else if (notDeleted.length === 1) {
      this.showPopover(event, newInterval)
    } else {
      this.setState({
        popper: null,
      })
    }

    // Finally, update data structure
    onChange(this.getDataFormat(newInterval, firstVisited.key))

    this.setState({
      firstVisited: {},
      lastVisited: {},
    })
  }

  handleMouseDown = (hour) => () => {
    this.setState({
      selecting: true,
      firstVisited: hour,
      lastVisited: hour,
      popper: null,
    })
    hour.preselected = true
  }

  resolveSelected(event) {
    const { lastVisited, firstVisited, hours } = this.state

    const { onChange, data } = this.props
    const currentlySelected = []

    // Checks if first selected was in some group of hours already,
    // if yes, pass it to another handler
    const reduxData = data || []
    const oldData = reduxData.map((eachData) => {
      if (eachData.interval.includes(firstVisited.key)) {
        return eachData
      }
      return null
    })

    // Checks if first selected was in some group of hours already,
    // if yes, pass it to another handler
    if (R.filter((eachData) => eachData !== null, oldData).length) {
      this.expandSelection(event)
      return
    }

    // cycle that goes through selected values (first to last, i.e. 4-7, 10-6...)
    // and unhighlights them, plus pushes them into array
    if (firstVisited.key < lastVisited.key) {
      for (let i = firstVisited.key; i <= lastVisited.key; i++) {
        currentlySelected.push(i)
        hours[i].preselected = false
      }
    } else {
      for (let i = lastVisited.key; i <= firstVisited.key; i++) {
        currentlySelected.push(i)
        hours[i].preselected = false
      }
    }

    // If there is already something added of those values, screw adding new things.
    const previouslySelectedHours = R.unnest(reduxData.map((value) => value.interval))
    if (R.intersection(previouslySelectedHours)(currentlySelected).length) {
      return
    }

    // update store
    reduxData.push({ interval: currentlySelected })
    onChange(reduxData)
    if (currentlySelected.length > 1) {
      this.showPopover(event, reduxData[reduxData.length - 1])
    } else {
      this.showPopover(event, reduxData[reduxData.length - 1])
    }

    this.setState({
      firstVisited: {},
      lastVisited: {},
    })
  }

  handleMouseUp = (event) => {
    const { selecting } = this.state
    if (selecting) {
      this.setState({ selecting: false })
      this.resolveSelected(event)
    }
  }

  handleMouseEnter = (hour) => () => {
    const { lastVisited, firstVisited, selecting } = this.state
    const isLeftToRight = this.state.lastVisited.key - this.state.firstVisited.key > 0
    if (selecting) {
      // Condition checking, if we are selecting more values, or going 'back' and unselecting values
      if (
        (isLeftToRight && lastVisited.key < hour.key) ||
        (!isLeftToRight && lastVisited.key > hour.key) ||
        lastVisited.key - firstVisited.key === 0
      ) {
        hour.preselected = true
      } else if (lastVisited.key !== hour.key) {
        lastVisited.preselected = false
      }
      this.setState({
        lastVisited: hour,
      })
    }
  }

  handleMouseLeave = () => {
    const { lastVisited, firstVisited, hours, selecting } = this.state

    // cycle that goes through selected values (first to last, i.e. 4-7, 10-6...)
    // and unhighlights them
    if (selecting) {
      if (firstVisited.key < lastVisited.key) {
        for (let i = firstVisited.key; i <= lastVisited.key; i++) {
          hours[i].preselected = false
        }
      } else {
        for (let i = lastVisited.key; i <= firstVisited.key; i++) {
          hours[i].preselected = false
        }
      }
      this.setState({
        selecting: false,
        firstVisited: {},
        lastVisited: {},
      })
    }
  }

  showPopover(event, newInterval) {
    this.setState({
      interval: newInterval,
      popper: new PopperJS(event.target, this.intervalSelectorRef, {
        placement: 'bottom',
        modifiers: {
          flip: {
            behavior: ['left', 'bottom', 'top', 'right'],
          },
        },
      }),
    })
  }

  formatHours = (d) => (d < 10 ? `0${d.toString()}` : d.toString())

  clickOutside = ({ target }: Object) => {
    if (!(this.rootRef && this.rootRef.contains(target))) {
      this.setState({
        popper: null,
      })
    }
  }

  clickSaveButton = () => {
    this.setState({
      popper: null,
    })
  }

  updateSpecifiedData = (newInterval, key) => {
    const { onChange } = this.props
    onChange(this.getDataFormat(newInterval, key))
  }

  deleteInterval = (key) => {
    const { data, onChange } = this.props
    const newData = R.filter(
      (value) => value !== null,
      data.map((value) => {
        if (value.interval.includes(key)) {
          return null
        }
        return value
      }),
    )
    onChange(newData)
    this.setState({
      popper: null,
    })
  }

  loadData(newProps) {
    const { data } = newProps
    const { hours } = this.state
    hours.map((hour) => {
      hours[hour.key].selected = false
      hours[hour.key].first = false
      hours[hour.key].last = false
      return true
    })
    if (data.length) {
      data.map((eachData) => {
        eachData.interval.map((key) => {
          if (hours[key]) {
            hours[key].selected = true
            hours[key].first = false
            hours[key].last = false
            return true
          }
          return false
        })
        hours[eachData.interval[0]].first = true
        // This is just quick fix. This entire component needs some MAJOR refactoring because there
        // is a lot of mutations and unexpected behaviour risk.
        const probablyLastItem = hours[eachData.interval[eachData.interval.length - 1]]
        if (probablyLastItem) {
          probablyLastItem.last = true
          return true
        }
        hours[eachData.interval[eachData.interval.length - 2]].last = true
        return true
      })
    }
  }

  render() {
    const { popper, interval, hours } = this.state
    return (
      <div
        ref={(ref) => {
          this.rootRef = ref
        }}
        className="op-multiselect"
        onMouseLeave={this.handleMouseLeave}
      >
        {hours.map((hour) => (
          <div
            key={hour.key}
            className={classNames('op-multiselect-point', {
              'is-selected': hour.preselected,
              'is-edit': hour.selected,
              'is-grouped': hour.selected,
              'mod-first': hour.first && !hour.last,
              'mod-last': !hour.first && hour.last,
              'is-single': hour.first && hour.last,
            })}
            onMouseDown={this.handleMouseDown(hour)}
            onMouseUp={this.handleMouseUp}
            onMouseMove={this.handleMouseEnter(hour)}
          >
            <span className={classNames('op-multiselect-point-label')}>{this.formatHours(hour.key)}</span>
          </div>
        ))}
        <div
          ref={(ref) => {
            this.intervalSelectorRef = ref
          }}
          className="popupWrapper"
        >
          {!!popper && (
            <MultiSelectDropdown
              clickOutside={this.clickOutside}
              clickSaveButton={this.clickSaveButton}
              interval={interval}
              deleteInterval={this.deleteInterval}
              formatHours={this.formatHours}
              updateSpecifiedData={this.updateSpecifiedData}
            />
          )}
        </div>
      </div>
    )
  }
}

export default MultiSelect

import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import buildActionCreators from '../../../helpers/buildActionCreators'
import ModuleWrapper from './ModuleWrapper'
import Translate from '../../../Translate'
import {
  getActiveTemplate,
  getTemplateInitialValues,
  getTemplateMaxLevel,
  getTemplateModules,
} from '../../../selectors/templatesSelectors'
import * as ActionTypes from '../../../constants/actionTypes'
import type { ActiveTemplate, TemplateModule } from '../../../opoint/flow'

type Props = {
  activeTemplate: ActiveTemplate
  modules: Array<TemplateModule>
  deleteTemplate: () => void
  saveTemplate: ({ isNewTemplate: boolean }) => void
  maxLevel: any
}

type State = {
  deleteTemplateConfirmation: boolean
}

class TemplateEditorForm extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      deleteTemplateConfirmation: false,
    }
  }

  confirmDelete = () => {
    this.setState({ deleteTemplateConfirmation: true })
  }

  cancelDelete = () => {
    this.setState({ deleteTemplateConfirmation: false })
  }

  handleDelete = (e) => {
    e.preventDefault()
    const { deleteTemplate } = this.props
    deleteTemplate()
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { saveTemplate } = this.props
    saveTemplate({ isNewTemplate: false })
  }

  handleCreate = (e) => {
    e.preventDefault()
    const { saveTemplate } = this.props
    saveTemplate({ isNewTemplate: true })
  }

  render() {
    const { activeTemplate, modules, maxLevel } = this.props
    const { deleteTemplateConfirmation } = this.state

    return (
      <form id="op-form-settings">
        {/*
        @ts-ignore */}
        <ModuleWrapper activeTemplate={activeTemplate} modules={modules} maxLevel={maxLevel} />
        {activeTemplate && (
          <div className="op-settings-submit">
            {deleteTemplateConfirmation ? (
              <div>
                <Translate i18nString="Do you really want to delete this template?" />
                <button type="button" className="op-button black" onClick={this.cancelDelete}>
                  <Translate i18nString="Cancel" />
                </button>
                <button type="button" className="op-button red" onClick={this.handleDelete}>
                  <Translate i18nString="Delete" />
                </button>
              </div>
            ) : (
              <div className="template-editor-actions">
                {!activeTemplate.readonly && (
                  <div>
                    <button
                      type="button"
                      className="op-button red delete-template-confirm"
                      onClick={this.confirmDelete}
                    >
                      <Translate i18nString="Delete" />
                    </button>
                    <button type="button" className="op-button green" onClick={this.handleSubmit}>
                      <Translate i18nString="Save settings" />
                    </button>
                  </div>
                )}
                <button type="button" className="op-button green" onClick={this.handleCreate}>
                  <Translate i18nString="Save as new" />
                </button>
              </div>
            )}
          </div>
        )}
      </form>
    )
  }
}

// @ts-ignore
TemplateEditorForm = reduxForm({
  form: 'templateEditor',
  keepDirtyOnReinitialize: false,
  enableReinitialize: true,
  // @ts-ignore
})(TemplateEditorForm)

// @ts-ignore
TemplateEditorForm = connect(
  (state) => {
    const activeTemplate = getActiveTemplate(state)
    const modules = activeTemplate && getTemplateModules(activeTemplate.id)(state)
    const initialValues = modules && getTemplateInitialValues(activeTemplate.id)(state)
    const maxLevel = activeTemplate && getTemplateMaxLevel(activeTemplate.id)(state)

    return {
      activeTemplate,
      initialValues,
      modules,
      maxLevel,
    }
  },
  buildActionCreators({
    deleteTemplate: ActionTypes.TEMPLATE_DELETE,
    saveTemplate: ActionTypes.TEMPLATE_SAVE,
  }),
)(TemplateEditorForm)

export default TemplateEditorForm

import React from 'react'
import PropTypes from 'prop-types'
import R from 'ramda'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'

import buildActionCreators from '../../../helpers/buildActionCreators'
import ManageTagForm from './ManageTagForm'
import { getLastUsedTag } from '../../../selectors/tagsSelectors'
import { toggleVisibility } from '../../../opoint/tags/index'
import * as Actions from '../../../constants/actionTypes'

const editTagStoreLens = R.lensPath(['form', 'tag', 'edit', 'values'])

class EditTagPopover extends React.PureComponent<any> {
  static contextTypes = {
    destroyEditTagPopover: PropTypes.func,
    i18n: PropTypes.object,
  }

  getButtons() {
    const { initialValues: tag } = this.props
    const { i18n } = this.context
    let visibilityButtonPlaceholder = null

    if (!tag || tag.visibility === 1) {
      visibilityButtonPlaceholder = i18n.t('Show')
    } else {
      visibilityButtonPlaceholder = i18n.t('Hide')
    }

    return [
      {
        onClick: () => this.deleteClickHandler(),
        placeholder: i18n.t('Delete'),
      },
      {
        onClick: () => this.visibilityClickHandler(),
        placeholder: visibilityButtonPlaceholder,
      },
      {
        onClick: () => this.saveClickHandler(),
        placeholder: i18n.t('Save'),
      },
    ]
  }

  deleteClickHandler = () => {
    const { destroyEditTagPopover } = this.context
    const { initialValues: tag, deleteTag } = this.props

    deleteTag({ tagId: tag.id })
    destroyEditTagPopover()
  }

  visibilityClickHandler = () => {
    const { initialValues, tag, editTag } = this.props

    editTag({ tag: toggleVisibility({ ...initialValues, ...tag }) })
  }

  saveClickHandler = () => {
    const { destroyEditTagPopover } = this.context
    const { initialValues, tag, editTag } = this.props

    editTag({ tag: { ...initialValues, ...tag } })
    destroyEditTagPopover()
  }

  render() {
    const buttons = this.getButtons()
    return (
      <ManageTagForm
        buttons={buttons}
        hasStoredSearchPermission={this.props.hasStoredSearchPermission}
        storedSearchId={this.props.storedSearchId}
      />
    )
  }
}

// @ts-ignore
EditTagPopover = translate([], { wait: true })(EditTagPopover)

// @ts-ignore
EditTagPopover = reduxForm({
  form: 'tag.edit',
  keepDirtyOnReinitialize: false,
  enableReinitialize: true,
  // @ts-ignore
})(EditTagPopover)

export default connect(
  (state) => {
    const lastUsedTag = getLastUsedTag(state)
    return {
      tag: R.view(editTagStoreLens, state),
      initialValues: {
        ...lastUsedTag,
        stored_search: lastUsedTag.stored_search && lastUsedTag.stored_search.active,
      },
      storedSearchId: lastUsedTag.stored_search && lastUsedTag.stored_search.id,
      hasStoredSearchPermission: lastUsedTag.stored_search !== undefined,
    }
  },
  buildActionCreators({
    deleteTag: Actions.DELETE_TAG,
    editTag: Actions.EDIT_TAG,
  }),
)(EditTagPopover)

import React from 'react'
import R from 'ramda'
import { connect } from 'react-redux'

import buildActionCreators from '../../helpers/buildActionCreators'
import FilterTag from '../../opoint/search/FilterTag'
import { filterIsDetailsSupported, filterIsTimePeriod } from '../../opoint/search/index'
import { getFilterDetail } from '../../selectors/entitiesSelectors'
import * as ActionTypes from '../../constants/actionTypes'
import type { Filter } from '../../opoint/flow'

type Props = {
  deleteHandler: (filter: Filter) => void
  fetchFilter: (obj: { id: string; type: string }) => void
  filter: Filter
  filterDetail?: Filter
  invertFilter: (obj: { id?: number; filter: Filter }) => void
  parentId: number | void
  separator: string
  showDatePicker: () => void
  showFilterMetasDetail: ({ filter: Filter }) => void
  recalculateIndent: () => void
}

class FilterComponent extends React.Component<Props> {
  componentDidMount() {
    setTimeout(() => {
      this.fetchFilter()
    }, 100)
  }

  shouldComponentUpdate(nextProps: Props): boolean {
    return (
      nextProps.filter !== this.props.filter ||
      nextProps.filterDetail !== this.props.filterDetail ||
      nextProps.separator !== this.props.separator
    )
  }

  componentDidUpdate(prevProps) {
    const { filterDetail, recalculateIndent } = this.props

    this.fetchFilter()
    if ((prevProps.filterDetail && prevProps.filterDetail.name) !== (filterDetail && filterDetail.name)) {
      recalculateIndent()
    }
  }

  onClickHandler = (): void => {
    const { filter, filterDetail, showFilterMetasDetail, showDatePicker } = this.props
    const resultFilter = filterDetail || filter

    // @ts-ignore
    resultFilter.type && filterIsDetailsSupported(resultFilter) && showFilterMetasDetail({ filter: resultFilter })

    filterIsTimePeriod(resultFilter) && showDatePicker()
  }

  invertFilterHandler = (filter: Filter): void => {
    const { invertFilter, parentId } = this.props
    if (parentId !== undefined) {
      // @ts-ignore
      invertFilter({ id: parentId, filter })
    } else {
      invertFilter({ filter })
    }
  }

  fetchFilter(): void {
    const {
      filter: { name, id, type },
      filterDetail,
      fetchFilter,
    } = this.props
    if (filterDetail === undefined && fetchFilter && type !== 'chart' && type !== 'list' && !name) {
      // @ts-ignore
      fetchFilter({ id, type })
    }
  }

  render() {
    const { filter, filterDetail, separator, deleteHandler } = this.props
    const resultFilter = filterDetail || filter

    if (
      !resultFilter ||
      (R.any(R.equals(resultFilter.type), ['tag', '-tag', 'profile', '-profile', 'trash']) && !filterDetail)
    ) {
      // In case filter has name, everything is all right even when filterDetail is not present.
      // This makes condition consistent with fetchFilter condition
      if (!filter.name) {
        return null
      }
    }

    return (
      <FilterTag
        deleteHandler={deleteHandler}
        filter={resultFilter}
        separator={separator}
        invertHandler={this.invertFilterHandler}
        onClickHandler={this.onClickHandler}
      />
    )
  }
}

export const SearchFilterComponent = connect(
  (state, { filter }: any) => ({
    filterDetail: getFilterDetail(filter)(state),
  }),
  buildActionCreators({
    fetchFilter: ActionTypes.FETCH_FILTER_DETAIL,
    invertFilter: ActionTypes.INVERT_FILTER,
    showFilterMetasDetail: ActionTypes.FILTER_METAS_SHOW_DETAIL,
    showDatePicker: ActionTypes.DATEPICKER_MODAL_OPEN,
  }),
  // @ts-ignore
)(FilterComponent)

export const ProfileEditorFilterComponent = connect(
  // @ts-ignore
  (state, { filter }) => ({
    filterDetail: getFilterDetail(filter)(state),
  }),
  buildActionCreators({
    fetchFilter: ActionTypes.FETCH_FILTER_DETAIL,
    invertFilter: ActionTypes.PROFILE_EDITOR_FILTER_INVERTED,
    showFilterMetasDetail: ActionTypes.FILTER_METAS_SHOW_DETAIL,
  }),
  // @ts-ignore
)(FilterComponent)

export const FeedEditorFilterComponent = FilterComponent
export const AlertEditorFilterComponent = FilterComponent

import React from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'

import Translate from '../../Translate'
import { getFullnameLanguage } from '../../opoint/common/constants'
import type { SiteMetasProps } from '../../opoint/flow'

type Props = {
  details: Array<SiteMetasProps>
}

class SiteFilterDetail extends React.PureComponent<Props> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  render() {
    const { details } = this.props
    const { i18n } = this.context

    return (
      <div>
        {details.map((meta, index) => (
          <div key={index} className="modal-body-dialog op-modal-site-details">
            <div>
              <div className="single-meta-data">
                <Translate i18nString="Website" />
                {': '}
                <a href={meta.site.url} target="_blank">
                  {meta.site.url}
                </a>
              </div>
              <div className="single-meta-data">
                <Translate i18nString="Country" />
                {': '}
                <Translate i18nString={meta.site.country} />
              </div>
              <div className="single-meta-data">
                <Translate i18nString="Language" />
                {`: ${
                  meta.site.lang.length > 0
                    ? getFullnameLanguage(meta.site.lang) // @ts-ignore
                        .map((lang) => i18n.t(lang))
                        .join(', ')
                    : '-'
                }`}
              </div>
              <div className="single-meta-data">
                <Translate i18nString="Rank" />
                {`: ${meta.rank}`}
              </div>
            </div>
            <div>
              <div className="single-meta-data">
                <Translate i18nString="Number of articles in the last seven days" />
                {': '}
                <span className="metas-counts">{meta.totalCountLastDays}</span>
              </div>
              {meta.sortedDates.map(([date, count]) => (
                <div key={date} className="single-meta-data">
                  {`${date}: `}
                  <span className="metas-counts">{count}</span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    )
  }
}

// @ts-ignore
SiteFilterDetail = translate([], { wait: true })(SiteFilterDetail)

export default SiteFilterDetail

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import buildActionCreators from '../../../../helpers/buildActionCreators'
import Translate from '../../../../Translate'
import { isAutoTranslationsOn, getAutoTranslationLanguageName } from '../../../../selectors/settingsSelectors'
import * as ActionTypes from '../../../../constants/actionTypes'

class ToggleAutoTranslateButton extends React.Component<any, any> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  toggleTranslateAuto = () => {
    const { hidePopover, toggleTranslateAuto, isFromPopover } = this.props
    toggleTranslateAuto()
    isFromPopover && hidePopover()
  }

  render() {
    const { globalAutoTranslateOn, translateAuto, translateToLang, isFromPopover } = this.props
    const icon = <i className="op-icon-translate mod_bigger" />
    const { i18n } = this.context
    const title = !translateAuto ? (
      <span>
        <Translate i18nString="Translate to" /> {i18n.t(translateToLang)}
      </span>
    ) : (
      <Translate i18nString="Don't translate" />
    )

    if (!globalAutoTranslateOn) {
      return null
    }

    return isFromPopover ? (
      <li className="actionLine-popover-child">
        <a onClick={this.toggleTranslateAuto}>
          {icon}
          {title}
        </a>
      </li>
    ) : (
      <button className="op-button-toolbar" onClick={this.toggleTranslateAuto}>
        {title}
        {icon}
      </button>
    )
  }
}

export default connect(
  (state: any) => ({
    translateAuto: state.settings.list.TRANSLATE_AUTO, // TODO selector @dmytro
    translateToLang: getAutoTranslationLanguageName(state),
    globalAutoTranslateOn: isAutoTranslationsOn(state), // global settings
  }),
  buildActionCreators({
    toggleTranslateAuto: ActionTypes.SEARCH_TOGGLE_TRANSLATE_AUTO,
  }),
)(ToggleAutoTranslateButton)

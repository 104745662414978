import * as R from 'ramda'

import { MEDIA_TYPES } from '../opoint/common/constants'

export const returnFirst = (a) => a

export function emptyFunction() {}

export function compareProps(props, nextProps) {
  const color = (c) => (c ? '#53D836' : '#FF5733')

  Object.entries(props).map(([key]) => {
    if (props[key] !== nextProps[key]) {
      const isShallowEqual = props[key] === nextProps[key]
      const isDeepEqual = R.equals(props[key], nextProps[key])

      /* eslint-disable-next-line no-console */
      console.log(`Property name: ${key}`)
      /* eslint-disable-next-line no-console */
      console.log('this.props value |X| nextProps value')
      /* eslint-disable-next-line no-console */
      console.log(props[key], nextProps[key])
      /* eslint-disable-next-line no-console */
      console.log(`Is deep equal: %c${isDeepEqual}`, `background: ${color(isDeepEqual)}`)
      /* eslint-disable-next-line no-console */
      console.log(`Is shallow equal: %c${isShallowEqual}`, `background: ${color(isShallowEqual)}`)
    }

    /* eslint-disable-next-line no-console */
    console.log('........................................')

    return undefined
  })

  /* eslint-disable-next-line no-console */
  console.warn('----------------------------------------')
}

// Given prevProps and this.props, function writes to console
// which props did not pass shallow compare
// -- lighter version of previous function
export function shallowCompareCheck(prevProps, newProps) {
  let counter = 0
  const messages = Object.keys(prevProps).map((propName) => {
    const passedSC = prevProps[propName] === newProps[propName]
    if (passedSC) {
      return () => undefined
    }
    counter += 1
    /* eslint-disable-next-line no-console */
    return () => console.warn(`PROPERTY ${propName} DID NOT PASS SHALLOW COMPARE`)
  })
  if (counter) {
    messages.forEach((message) => message())
  } else {
    /* eslint-disable-next-line no-console */
    console.log('All props passed shallow compare')
  }
}

export const sillyAscendSort = (a, b) => a - b

export const trimPostLastDotSubsctring = (fileName) => fileName.replace(/\.[^/.]+$/, '')

export const isArticleMedia = (mediaType) => MEDIA_TYPES.indexOf(mediaType) !== -1

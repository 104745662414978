import React from 'react'
import classNames from 'classnames'

import { TEMPLATE_TYPES } from '../../opoint/templates/index'

type Props = {
  isActive: boolean
  templateTypeKey: number
  select: (number, any) => () => void
}

class TemplateSelectorTemplateType extends React.PureComponent<Props> {
  selectTemplateType = () => {
    const { select, templateTypeKey } = this.props
    const selectTemplate = select(templateTypeKey, null)
    selectTemplate()
  }

  render() {
    const { isActive, templateTypeKey } = this.props

    return (
      <li
        className={classNames('op-template-type-list-item', { 'is-active': isActive })}
        onClick={this.selectTemplateType}
      >
        <svg id="icon-menu">
          <use
            xlinkHref={
              {
                [TEMPLATE_TYPES.HTML]: '#icon-html',
                [TEMPLATE_TYPES.PDF]: '#icon-pdf',
                [TEMPLATE_TYPES.XLS]: '#icon-xsl',
                [TEMPLATE_TYPES.DOC]: '#icon-doc',
                [TEMPLATE_TYPES.PPT]: '#icon-ppt',
                [TEMPLATE_TYPES.RSS]: '#icon-rss',
                [TEMPLATE_TYPES.XML]: '#icon-xml',
                [TEMPLATE_TYPES.JSON]: '#icon-blank-doc',
              }[templateTypeKey]
            }
          />
        </svg>
      </li>
    )
  }
}

export default TemplateSelectorTemplateType

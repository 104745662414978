export const LOGOS = {
  mia: '/api/logo/mia.svg',
  m360: '/api/logo/m360.svg',
  'mia-dev': '/api/logo/mia.svg',
  'm360-dev': '/api/logo/m360.svg',
  easynews: '/api/logo/easynews.svg',
  'easynews-dev': '/api/logo/easynews.svg',
  fallback: 'images/m360.svg',
}

export const IMAGES = {
  article_not_selected: '/images/article_placeholder.svg',
  search_icon: '/images/search.svg',
}

export const MONTHS = {
  JANUARY: 0,
  FEBRUARY: 1,
  MARCH: 2,
  APRIL: 3,
  MAY: 4,
  JUNE: 5,
  JULY: 6,
  AUGUST: 7,
  SEPTEMBER: 8,
  OCTOBER: 9,
  NOVEMBER: 10,
  DECEMBER: 11,
}

export const CONTENT_HEIGHT_THRESHOLD = 400

import React from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'

import buildActionCreators from '../../../helpers/buildActionCreators'
import Translate from '../../../Translate'
import contactsForm from '../../contacts/ContactsInput'
import * as ActionTypes from '../../../constants/actionTypes'
import { getAlertChangeRecipientsOpen } from '../../../selectors/uiSelectors'

const AlertsContactsInput = contactsForm('editedAlertEmail', 2)
const AlertsSmsInput = contactsForm('editedAlertSms', 3)

class PickRecipientsModal extends React.PureComponent<any> {
  render() {
    const { isOpen, close, sendAgain } = this.props

    return (
      <Modal show={isOpen} onHide={close} dialogClassName="op-modal-leave-alert-send-again">
        <Modal.Header>
          <button type="button" className="close" onClick={close}>
            ×
          </button>
          <h4 className="modal-title">
            <Translate i18nString="Send to" />
          </h4>
        </Modal.Header>
        <Modal.Body className="op-modal-manage-profiles">
          <div className="form-group">
            <h3>
              <Translate i18nString="Recipients" />
            </h3>
            <label htmlFor="email">
              <Translate i18nString="Email" />
            </label>
            {/* @ts-ignore */}
            <AlertsContactsInput />
            <label htmlFor="sms" style={{ marginTop: '5px' }}>
              <Translate i18nString="SMS" />
            </label>
            {/* @ts-ignore */}
            <AlertsSmsInput />
            <button className="op-button green" onClick={sendAgain}>
              <Translate i18nString="Send" />
            </button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

// @ts-ignore
PickRecipientsModal = connect(
  (state) => ({
    isOpen: getAlertChangeRecipientsOpen(state),
  }),
  buildActionCreators({
    close: ActionTypes.ALERT_CHANGE_RECIPIENTS_CLOSE,
    sendAgain: ActionTypes.SEND_ALERT_HISTORY_ITEM_AGAIN,
  }),
)(PickRecipientsModal)

export default PickRecipientsModal

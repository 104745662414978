// @ts-nocheck
import Rx from 'rx-dom'
import R from 'ramda'
import moment from 'moment'

import type {
  Article,
  SearchResult,
  Filter,
  FiltersMap,
  LocaleSuggestionLocale,
  MultipleSuggestions,
  Profile,
  SearchItem,
  Searchline,
  SuggestionType,
  Tag,
  TimePeriod,
  FilterType,
} from '../flow'
import config from '../common/config'
import { getReadableSeconds } from '../common/time'
import { getIntlTimeZone, handleErrors } from '../common/index'
import { MONTHS } from '../../constants'

export const filterTblistType = ['tblist', 'list']

export const filterDetailsSupportedTypes = ['site', 'geo', 'content', 'media', 'lang', ...filterTblistType]

export const allFilterTypes = ['tag', 'trash', 'profile', 'chart', 'timePeriod', ...filterDetailsSupportedTypes]

/**
 * Once filters is added, it should be inserted to the right location.
 * This Object defines an order in which filters should be sorted.
 *
 * Please make sure all values are bigger than 0.
 *
 * @type Object
 */
export const filtersOrder = {
  profile: 8,
  tag: 8,
  content: 7,
  media: 6,
  geo: 5,
  lang: 4,
  cov: 3,
  site: 2,
  timePeriod: 1,
}

// default searchD params
export const defaultParams = {
  requestedarticles: 20,
  icon_resolution: 49,
  main: {
    header: 2,
    summary: 2,
    text: 2,
    quotes: 2,
    colorbar: 1,
    matches: 1,
    shortsummarylength: 1932,
    shortbodylength: -1932,
  },
  identical: { inherit: true },
  colorbaropt: { pixels: 0, max_color: 8 },
  groupidentical: true,
  use_short_links: 1,
  picture_types: '2,4',
  include_equalgroup_id: 1,
  mark_matches_url: 1,
  sort_oldest_first: false,
  watch_id: -1,
  different_colors: 8,
}
// default searchD params
export const defaultStatParams = {
  ...defaultParams,
  requestedarticles: 500,
  acceptedcacheage: -1,
  main: {
    header: 0,
    summary: 0,
    text: 0,
    quotes: 0,
    colorbar: 0,
  },
  include_equalgroup_id: 0,
  picture_types: '',
  groupidentical: 0,
  mark_matches_url: 0,
  watch_id: 0,
  identical: undefined,
}
export const NUMBER_OF_SUGGESTIONS = 10

export const tagProfileTypeList = ['tag', '-tag', 'profile', '-profile']

export function filterId({ id, type }: Filter): string {
  return `${type}:${id}`
}

export const eqFilters = R.eqBy(filterId)
export const cutFiltersTypeMinus = R.when(R.compose(R.equals('-'), R.head), R.slice(1, Infinity))

export const filterIsTag = R.propEq('type', 'tag')
export const filterIsTrashTag = R.propEq('type', 'trash')
export const filterIsProfile = R.propEq('type', 'profile')
export const filterIsChart = R.propEq('type', 'chart')
export const filterIsTimePeriod = R.propEq('type', 'timePeriod')
export const filterIsTBList = R.compose(
  /* eslint-disable-next-line no-underscore-dangle */
  R.contains(R.__, filterTblistType),
  // @ts-ignore
  R.prop('type'),
)
export const filterIsDetailsSupported = R.compose(
  /* eslint-disable-next-line no-underscore-dangle */
  R.contains(R.__, filterDetailsSupportedTypes),
  // @ts-ignore
  cutFiltersTypeMinus,
  R.prop('type'),
)

export const filtersToFiltersMap: (arr: Array<Filter>) => FiltersMap = R.indexBy(filterId)

/**
 * Given a list of suggestions, it returns a string that should be sent to a suggestion server.
 * @param filters
 * @returns {string}
 */
export function filtersToSuggestionServerString(filters: Array<Filter>) {
  // @ts-ignore
  return R.equals({}, filters)
    ? ''
    : R.values(filters)
        .map(({ id, type }) => `${type}:${id}`)
        .join(',')
}

/**
 * Given searchterm and filters, it sends a request to a suggestion server and returns a promise.
 * @param searchterm
 * @param filters
 * @returns {Promise}
 */
const getSuggestionsFromSuggestionServer = R.curry(
  (
    type: string,
    searchterm: string,
    filters,
    suggestionLocale,
    defaultSearchScope: Array<Filter>,
    numberOfSuggestions: number,
    accessParameter: number,
  ) => {
    const searchFiltersWithDefaultFilters = {
      ...filters,
      ...filtersToFiltersMap(defaultSearchScope),
    }

    const requestHeaders = R.merge(config.getRequestHeaders(), {
      // Trainling slash at the end of url is been required.
      // That because without trailing slash, loads a file.
      // If search query ends on `.js`, cross-domain request will be handled.
      // This will lead to error exception: `XMLHttpRequest has been blocked by CORS policy`.
      url: config.getUrl(
        `/suggest/${suggestionLocale}:UTC+1/${type}/${
          numberOfSuggestions || NUMBER_OF_SUGGESTIONS
        }/0/0/2147483647:${accessParameter}/${filtersToSuggestionServerString(searchFiltersWithDefaultFilters)}/${
          encodeURIComponent(searchterm) || '%20'
        }`,
      ),
    })
    return Rx.DOM.ajax(requestHeaders)
      .toPromise()
      .then(({ response }) => response)
  },
)

const getSuggestions = R.curry(
  (
    type: string,
    searchterm: string,
    filters: Filter[] = [],
    { suggestionLocale = 'en_GB_1' } = {},
    defaultSearchScope: any,
    numberOfSuggestions: number,
    accessParameter: number,
  ) => {
    const tbList = getTbListSuggestionFromExpression(searchterm)
    // @ts-ignore
    if (tbList !== null && type === 0) {
      return Promise.resolve({ results: [tbList] })
    }
    return getSuggestionsFromSuggestionServer(
      type,
      searchterm,
      filters,
      suggestionLocale,
      defaultSearchScope,
      numberOfSuggestions,
      accessParameter,
    )
  },
)

export const getSimpleSuggestions = getSuggestions(0)
export const getMultipleSuggestions = getSuggestions('multiple')

export function getSuggestionDetail(id: string, type: string, locale: LocaleSuggestionLocale) {
  const timeZone = getIntlTimeZone('-')

  const requestHeaders = R.merge(config.getRequestHeaders(), {
    url: config.getUrl(`/suggest/${locale}:${timeZone}/getids/${type}:${id}`),
  })
  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export const entityToSearchItems = (id: number, type: 'tag' | 'profile'): Array<SearchItem> => [
  {
    searchline: {
      filters: [{ type, id: `${id}` }],
    },
    linemode: 'R',
  },
]

export const searchDataToExpression = R.curry(
  (searchData): Array<SearchItem> => [
    {
      searchline: searchData,
      linemode: 'R',
    },
  ],
)

export const tagToSearchItems = (id: number) => entityToSearchItems(id, 'tag')

export const FILTERS_ORDER_DEFAULT: Array<FilterType> = [
  'timeperiod',
  'geo',
  'lang',
  'media',
  'content',
  'site',
  'rank',
  'cov',
  'profiles',
]

export const profileToSearchItems = (id: number) => entityToSearchItems(id, 'profile')

// TODO - this method should be deprecated and this should move to component
const processArticle = (article: Article) => {
  /**
   * Creating _videoMatches for easy access to matched words in Radio / TV
   */
  if (
    article.mediatype &&
    article.media_file === 1 &&
    (article.mediatype.text === 'TV' || article.mediatype.text === 'RADIO' || article.mediatype.timemap)
  ) {
    /* eslint-disable-next-line no-underscore-dangle */
    article._videoMatches = R.compose(R.sortBy(R.prop('seconds')), (matches) =>
      matches.map((match, id) => {
        const { play_offset: seconds, color, text: matchText } = match
        return {
          id,
          color,
          matchText,
          seconds,
          readableSeconds: getReadableSeconds(seconds),
        }
      }),
    )(article.matches)
  }
  return article
}

export const processSearch = R.compose(
  normalizeTimestamps,
  processSearchMeta,
  // @ts-ignore
  R.evolve({
    searchresult: {
      document: R.map(processArticle),
    },
  }),
)

// TODO add flow type for searchd params
export function search(
  expressions: Array<SearchItem>,
  searchParams: any = {},
  params: any = {},
  locale: string = 'en-GB',
): Promise<any> {
  const requestHeaders = R.evolve({
    headers: {
      'accept-language': R.always(locale),
    },
  })(config.getRequestHeaders())

  return fetch(config.getUrl('/api/search/'), {
    ...requestHeaders,
    method: 'POST',
    body: JSON.stringify({
      params: { ...defaultParams, ...searchParams },
      expressions,
      ...params,
    }),
  })
    .then(handleErrors)
    .then((respond) => respond.json())
    .then(processSearch)
}

export function searchStatistics(
  expressions: Array<SearchItem>,
  searchParams: any = {},
  params: any = {},
  locale: string = 'en-GB',
): Promise<any> {
  const body = JSON.stringify({
    params: R.mergeAll([defaultStatParams, params, searchParams]),
    expressions,
  })
  const requestHeaders = R.evolve({
    headers: {
      'accept-language': R.always(locale),
    },
  })(config.getRequestHeaders())

  return fetch(config.getUrl('/api/search/'), {
    ...requestHeaders,
    method: 'POST',
    body,
  })
    .then(handleErrors)
    .then((respond) => respond.json())
    .then(processSearch)
}

export function verifyProfile(expressions: Array<SearchItem>): Promise<SearchResult> {
  // We do not need to check if filters are entered correctly
  // also backend returns buggy things if we do
  const expressionsWithoutFilters = expressions.map((exp) => R.assocPath(['searchline', 'filters'], [], exp))
  return fetch(config.getUrl('/api/search/'), {
    ...config.getRequestHeaders(),
    method: 'POST',
    body: JSON.stringify({
      params: { requestedarticles: 1 },
      expressions: expressionsWithoutFilters,
    }),
  })
    .then(handleErrors)
    .then((respond) => respond.json())
}

export function searchTimestamps(
  { stimestamps, tagId }: { stimestamps: Array<number>; tagId: number },
  searchParams: any = {},
  params: any = {},
): Promise<any> {
  // TODO flow @dmytro
  return fetch(config.getUrl(`/api/search/tag/${tagId}/reports/`), {
    ...config.getRequestHeaders(),
    method: 'POST',
    body: JSON.stringify({
      params: { ...defaultParams, ...searchParams },
      stimestamps,
      ...params,
    }),
  })
    .then(handleErrors)
    .then((respond) => respond.json())
    .then(processSearchMeta)
}

export function getSuggestionOfType(type: SuggestionType, data: MultipleSuggestions) {
  return data[type]?.suggest
}

/**
 * Returns true if filters is inverted, false otherwise
 * @param filter
 * @returns {boolean}
 */
export function filterIsInverted(filter: Filter) {
  return filter && filter.type && filter.type[0] === '-'
}

export const invertFilter = (filter: Filter) =>
  filterIsInverted(filter)
    ? R.assoc('type', filter.type.substring(1), filter)
    : R.assoc('type', `-${filter.type}`, filter)

/**
 * Function takes object fo filters and return updated object with new filter added.
 *
 * If type of new filter is defined to be presetn only once in filters, then other
 * instances of that filter type will be removed
 * @param {String} filterId - id of new filter, eg. profile:1314
 * @param {Filter} filterToBeAdded - filter object to be added to filters
 * @param {FiltersMap} filters - object of all active filters
 * @return {FiltersMap} - updated filters
 */
export function addOneOfKind(filterId: string, filterToBeAdded: Filter, filters: FiltersMap): FiltersMap {
  // array of filter types which should be present only once in filters
  const ONLY_ONE = ['timePeriod']

  const filterType = filterToBeAdded.type
  let purgedFilters = filters

  if (ONLY_ONE.includes(filterType)) {
    purgedFilters = R.filter((f) => f.type !== filterType, filters)
  }

  return R.assoc(filterId, filterToBeAdded, purgedFilters)
}

/**
 * Following method takes a filter from the suggestion server and
 * a string as parameters.
 *
 * Based on the filter's matched property, it modifies a string so that
 * unmatched part of the string is kept and user can use this string
 * to filter again.
 *
 * Example:
 *
 * string - `dagbladet vg`
 * if the filter `vg` is selected:
 *   returns `dagbladet`
 *
 * @param {Object} filter - suggestion server's filter
 * @param {String} string - string to be modified
 * @returns {String} modified string
 */
export const getUnmatchedString = R.curry((filter, string) => {
  if (filter.type === 'timePeriod') {
    return string
  }

  const tokens = string.split(' ')

  if (filter.hasOwnProperty('match') && filter.match !== '') {
    const matches = filter.match.split('')

    let newExpression = ''
    for (let i = 0; i < tokens.length; i++) {
      if (matches[i] === '0') {
        newExpression += ` ${tokens[i]}`
      }
    }
    return newExpression.replace(/^\s+|\s+$/, '')
  }
  return string
})

/**
 * If filter is inverted (type begins with '-')
 * returns type name without '-'
 * Used when sorting filters in the search field
 * and in op-search-input plugin
 *
 * @param {Object} filter whose type should be normalized
 * @returns {String} normalized type name
 */
export function normalizedType(filter: Filter) {
  if (filter && filter.type) {
    if (tagProfileTypeList.includes(filter.type)) {
      return 'filter-tag'
    }
    return filter.type[0] === '-' ? filter.type.substring(1) : filter.type
  }
  return 'profile'
}

/**
 * Returns true if two filters are of a same type. In case one of them is inverted it
 * treats it as a same type.
 * @param filter1
 * @param filter2
 */
export const filtersTypeEq: (Filter, Filter) => boolean = R.eqBy(normalizedType)

export function compareFilters(x: Filter, y: Filter) {
  const xType = normalizedType(x)
  const yType = normalizedType(y)
  const firstOrder = filtersOrder[xType] || 1
  const secondOrder = filtersOrder[yType] || 1

  const differentType = secondOrder - firstOrder
  if (differentType === 0) {
    return parseInt(x.id, 10) - parseInt(y.id, 10)
  }
  return differentType
}

/**
 *
 * @param String expression where to look for TB List
 * @returns null when no TB List expression in format
 * "list:999" is found
 * @return Object filter with TB list ID
 */
function getTbListSuggestionFromExpression(expr: string) {
  // TODO needs tests
  // Add TB list to suggestions if there is one in expression
  if (/list:\d+/.test(expr)) {
    const wordCount = expr.split(/\s+/).length
    const match = Array(wordCount).join('0')
    return {
      id: expr.match(/list:(.[0-9]*)/)[1],
      type: 'list',
      typeName: 'TB list', // TODO needs translation
      name: `list:${expr.match(/list:(.[0-9]*)/)[1]}`,
      match: `${match}1`,
    }
  }
  return null
}

// TODO: write test
function processSearchMeta(response) {
  // converts timestamp 0 to actual time, otherwise start of epoch will be displayed
  const convertToNow = (timestamp) => timestamp || moment().unix()

  return R.evolve({
    searchresult: {
      range_start: (t) => convertToNow(t),
      range_end: (t) => convertToNow(t),
    },
  })(response)
}

// TODO: write test
type TimePeriodParams = {
  newest?: number
  oldest: number
}

/**
 * Process timePeriod filter to format usable in search api call
 */

export function parseTimeFilterToTimeStamps(filterId: string): TimePeriodParams {
  const dates = {}

  function getStartOfToday() {
    const now = new Date()
    return new Date(now.getFullYear(), now.getMonth(), now.getDate())
  }

  function getStartOfYear() {
    const now = new Date()
    return new Date(now.getFullYear(), 0, 1)
  }

  function getStartOfMonth() {
    const now = new Date()
    return new Date(now.getFullYear(), now.getMonth(), 0)
  }

  function getStartOfWeek() {
    const now = new Date()
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1)
  }
  // TODO - write getStartOfQuarter & getEndOfPreviousQuarter using modulo & functional approach
  /**
   * This functions returns start of quarter in which is user currently in.
   * Alternatively, it returns previous quarter
   * if supplied with optional parameter isPrevQuarter
   * @param isPrevQuarter - if true, it returns previous quarter
   *   instead of quarter that user is currently in
   * @returns object in the format of {oldest: Integer}
   */
  function getStartOfQuarter(isPrevQuarter: false) {
    const now = new Date()
    const month = isPrevQuarter ? now.getMonth() - 3 : now.getMonth()
    let date
    switch (month) {
      case MONTHS.JANUARY:
      case MONTHS.FEBRUARY:
      case MONTHS.MARCH:
        date = new Date(now.getFullYear(), MONTHS.JANUARY, 1)
        break
      case MONTHS.APRIL:
      case MONTHS.MAY:
      case MONTHS.JUNE:
        date = new Date(now.getFullYear(), MONTHS.APRIL, 1)
        break
      case MONTHS.JULY:
      case MONTHS.AUGUST:
      case MONTHS.SEPTEMBER:
        date = new Date(now.getFullYear(), MONTHS.JULY, 1)
        break
      // In case that in January (0) months we substract 3
      // we get negative number even though we should get 4th quartal
      default:
        date = new Date(now.getFullYear(), MONTHS.OCTOBER, 1)
    }
    return date
  }
  /**
   * This functions returns end of previous quarter
   * @returns object in the format of {newest: Integer}
   */
  function getEndOfPreviousQuarter() {
    const now = new Date()
    const month = now.getMonth()
    let date
    switch (month) {
      case MONTHS.JANUARY:
      case MONTHS.FEBRUARY:
      case MONTHS.MARCH:
        date = new Date(now.getFullYear(), MONTHS.JANUARY, 0)
        break
      case MONTHS.APRIL:
      case MONTHS.MAY:
      case MONTHS.JUNE:
        date = new Date(now.getFullYear(), MONTHS.APRIL, 0)
        break
      case MONTHS.JULY:
      case MONTHS.AUGUST:
      case MONTHS.SEPTEMBER:
        date = new Date(now.getFullYear(), MONTHS.JULY, 0)
        break
      case MONTHS.OCTOBER:
      case MONTHS.NOVEMBER:
      case MONTHS.DECEMBER:
        date = new Date(now.getFullYear(), MONTHS.OCTOBER, 0)
        break
      default:
    }

    return date
  }
  // we're searching within the last day
  switch (filterId) {
    case 'D':
      // day
      dates.oldest = parseInt(getStartOfToday().getTime() / 1000, 10)
      break
    case 'Y':
      // this year
      dates.oldest = parseInt(getStartOfYear().getTime() / 1000, 10)
      break
    case 'M':
      // this month
      dates.oldest = parseInt(getStartOfMonth().getTime() / 1000, 10)
      break
    case 'W':
      // this week
      dates.oldest = parseInt(getStartOfWeek().getTime() / 1000, 10)
      break
    case 'D-1,D':
      // yesterday
      dates.newest = parseInt(getStartOfToday().getTime() / 1000, 10)
      dates.oldest = dates.newest - 24 * 3600
      break
    case 'd-1':
      // last 24 hours
      dates.oldest = parseInt(new Date().getTime() / 1000, 10) - 24 * 3600
      break
    case 'W-1,W':
      // previous week
      dates.newest = parseInt(getStartOfWeek().getTime() / 1000, 10)
      dates.oldest = dates.newest - 24 * 3600 * 7
      break
    case 'Q-1,Q':
      // previous kvartal
      dates.newest = parseInt(getEndOfPreviousQuarter().getTime() / 1000, 10)
      dates.oldest = parseInt(getStartOfQuarter(true).getTime() / 1000, 10)
      break
    case 'Q':
      // this kvartal
      dates.oldest = parseInt(getStartOfQuarter().getTime() / 1000, 10)
      break
    case 'd-30':
      // last 30 days
      dates.oldest = parseInt(getStartOfToday() / 1000, 10) - 24 * 3600 * 30
      break
    case 'd-7':
      // last 7 days
      dates.oldest = parseInt(getStartOfToday() / 1000, 10) - 24 * 3600 * 7
      break
    default:
      /* eslint-disable-line no-case-declarations */
      const d = filterId.split('-')
      if (d[0] !== 'undefined') {
        dates.oldest = parseInt(d[0], 10)
      }
      if (d[1] !== 'undefined') {
        dates.newest = parseInt(d[1], 10)
      }
      break
  }
  return dates
}

// Decide whether or not filter can be inverted
export function isNotInvertable(filter: Filter): boolean {
  return filter.type === 'trash' || filter.type === 'timePeriod'
}

export function normalizeTimestamps(response) {
  let {
    range_end: rangeEnd,
    range_start: rangeStart,
    last_timestamp: lastTimestamp,
    first_timestamp: firstTimestamp,
  } = response.searchresult

  // normalize timestamps, so millisecond are always used instead of seconds
  rangeStart *= 1000
  rangeEnd *= 1000
  lastTimestamp *= 1000
  firstTimestamp *= 1000

  return R.evolve({
    searchresult: R.always({
      // might be equal to number of documents,
      // in which case all requested documents were delivered
      // or it might be bigger number which means not all requested
      // were delivered and time range selector should be shown
      rangeStart, // requested range start
      rangeEnd, // requested range end - might be 0 which means now
      lastTimestamp, // oldest - delivered range start
      firstTimestamp, // newest
      ...R.omit(['range_end', 'range_start', 'last_timestamp', 'first_timestamp'], response.searchresult),
    }),
  })(response)
}

export function firstSearchFilter(searchItems: Array<SearchItem>): Filter | false {
  if (!Array.isArray(searchItems)) {
    return false
  }

  if (searchItems.length !== 1) {
    // we search for more than one profileline
    return false
  }

  let searchline = searchItems[0]

  if (searchline.linemode !== 'R') {
    return false
  }
  searchline = searchline.searchline /* eslint-disable-line */

  if (searchline.searchterm === undefined) {
    return false
  }

  if (searchline.searchterm.trim().length > 0) {
    // if we have a searchterm => we don't search for a profile only,
    // but we search within a profile
    return false
  }

  const timePeriodFiltered = searchline.filters.filter((filter) => filter.type !== 'timePeriod')
  // if there is more than one filters, we don't search for just one profile
  if (timePeriodFiltered.length !== 1) {
    return false
  }

  return R.head(timePeriodFiltered)
}

// This functions returns false if search is not profile search and
// profile id in case it is profile search
// export function isProfileSearch (searchItems: Array<SearchItem>): boolean | number {
export function getSearchIdByType(searchItems: Array<SearchItem>, type: 'profile' | 'tag'): false | number {
  const filter = firstSearchFilter(searchItems)
  if (filter && filter.type === type) {
    return parseInt(filter.id, 10)
  }
  return false
}

export const isProfileSearch = R.partialRight(getSearchIdByType, ['profile'])
export const isTagSearch = R.partialRight(getSearchIdByType, ['tag'])

/**
 * Decides whether a searchline can be saved as a profile
 * @param searchline
 * @returns {*|boolean}
 */
export function canSaveSearchlineAsProfile(searchline: Searchline): boolean {
  return (
    R.none(filterIsTBList, searchline.filters) &&
    R.none(filterIsTag, searchline.filters) &&
    !isProfileSearch([
      {
        searchline,
        linemode: 'R',
      },
    ])
  )
}

export function getFilterProfiles(profiles: Array<Profile>, searchterm: string) {
  const search = searchterm ? searchterm.toLowerCase() : ''
  return profiles.reduce((acc, profile) => {
    if (acc.length >= 10) {
      return acc
    }
    if (profile.name.toLowerCase().indexOf(search) !== -1) {
      return R.append(profile, acc)
    }
    return acc
  }, [])
}

export function getFilterTags(tags: Array<Tag>, searchterm: string) {
  const search = searchterm ? searchterm.toLowerCase() : ''
  return tags.reduce((acc, tag) => {
    if (acc.length >= 10) {
      return acc
    }
    if (tag.name.toLowerCase().indexOf(search) !== -1) {
      return R.append(tag, acc)
    }
    return acc
  }, [])
}

export const isSearchInputNotEmpty = (searchline: Searchline) => !R.isEmpty(searchline.filters) || searchline.searchterm

export const checkIfSuggestedFilterInverted = (filterIdString: string, searchFilters: Array<Filter>): string => {
  const invertedFilterId = `-${filterIdString}`
  const doesFiltersArrayContainsTheInvertedFilter = R.find(R.equals(invertedFilterId), R.keys(searchFilters))
  return doesFiltersArrayContainsTheInvertedFilter ? invertedFilterId : filterIdString
}

/*
  Parse unix start and end timestamp from id in format {START}-{END}
*/
export function getStartAndEndFromId(timePeriod: { id: string }): TimePeriod {
  const [startDate, endDate] = R.map(
    (unixTime) => moment.unix(unixTime).toISOString(),
    R.split('-', R.prop('id', timePeriod)),
  )
  return { startDate, endDate }
}

export function defaultStartEndDates(): TimePeriod {
  return {
    startDate: moment().subtract(1, 'week').toISOString(),
    endDate: moment().toISOString(),
  }
}

import React from 'react'

import ProfileListItem from './ProfileListItem'
import type { Profile } from '../../opoint/flow'

/**
 * Takes profile tree and a condition function and returns true
 * if one of the children fullfills this condition
 * @param profiles
 * @param fn
 */
const walkNestedProfiles = (profiles, fn) =>
  profiles.reduce((isFound, profile) => isFound || fn(profile) || walkNestedProfiles(profile.children, fn), false)

export default class SubProfileListing extends React.PureComponent<any> {
  // TODO we could make this in constant time
  profileIsSelected = (profile: Profile) => {
    const { profileIds } = this.props
    return !!profileIds.find((id) => profile.id === id)
  }

  render() {
    const {
      searchContext /* eslint-disable-line no-shadow */,
      profiles,
      profileIds,
      isTree,
      watchQueries,
      profilesToWatchIds,
    } = this.props
    return (
      <ul className="op-content-left-profiles-profile-list">
        {profiles.map((profile) => (
          <li key={profile.id} title={profile.name} className="op-content-left-profiles-profile-list-item">
            <ProfileListItem
              profile={profile}
              searchContext={searchContext}
              checked={this.profileIsSelected(profile)}
              isTree={isTree}
              watchQuery={watchQueries[profilesToWatchIds[profile.id]]}
            />
            {
              // show another nested profile listing
              profile.children && // if profile has children
                (this.profileIsSelected(profile) || // and if it's selected
                  // or any of it's children is selected
                  walkNestedProfiles(profile.children, this.profileIsSelected)) && (
                  <SubProfileListing
                    profiles={profile.children}
                    searchContext={searchContext}
                    profileIds={profileIds}
                    isTree={isTree}
                    watchQueries={watchQueries}
                    profilesToWatchIds={profilesToWatchIds}
                  />
                )
            }
          </li>
        ))}
      </ul>
    )
  }
}

// @ts-nocheck

import moment from 'moment'
import R from 'ramda'
import { createEpicMiddleware } from 'redux-observable'

import configureEpics from './epics/configureEpics'
import errorEpics from './epics/web/errorEpics'
import i18next from './i18nextInit'
import navigationEpics from './epics/web/navigationEpics'
import uiEpics from './epics/web/uiEpics'
import { locales } from './opoint/common/constants'
import OpointDate from './opoint/common/time'
import { sillyAscendSort } from './helpers/common'
import buildAction from './helpers/buildAction'
import * as ActionTypes from './constants/actionTypes'
import { getOpointLocale } from './selectors/settingsSelectors'
import { toAPITimeFormat } from './opoint/alerts/apiTime'

const webEpics = [...errorEpics, ...navigationEpics, ...uiEpics]

const rootEpic = configureEpics(
  {
    // ... platform deps in a future can go here
    i18n: i18next,
  },
  webEpics,
)

export const epicMiddleware = createEpicMiddleware(rootEpic)

// This is a performance optimization, when we nagigate from one search
// to another, we clear article listing so that articles are not re-rendered
// before route change
export const beforeRouteChangesMiddleware = (store) => (next) => (action) => {
  if (action.type === ActionTypes.ROUTER_LOCATION_CHANGE) {
    const state = store.getState()
    const currentPathname = state.routing.locationBeforeTransitions && state.routing.locationBeforeTransitions.pathname // sth like `/search/`

    // we ignore this on initial route
    if (currentPathname) {
      const { search: newSearch, pathname: newPathname } = action.payload
      const { search, pathname } = state.routing.locationBeforeTransitions
      const isNewLocation =
        newSearch !== search || (!newPathname.startsWith(pathname) && !pathname.startsWith(newPathname))

      if (isNewLocation) {
        store.dispatch(buildAction(ActionTypes.CLEAR_ARTICLES))
      }

      if (
        !action.payload.query.next && // If don't logout
        pathname.search('alert') !== -1 && // If path we move from id is alert/{id}/edit or alert/new
        (pathname.search('edit') !== -1 || pathname.search('new') !== -1) &&
        !state.ui.leaveEditAlertModalOpen && // If asking modal is not open // If we don't just back to preview.
        // It happense in case we do regular alert save or cancel the editor
        action.payload.pathname !==
          `/alerts/${(state.form.alert.edit && state.form.alert.edit.values.id) || state.alerts.justSavedAlertId}`
      ) {
        const {
          alerts: {
            activeAlert,
            editedAlert: { schedule },
          },
          contacts: {
            editedAlertEmail,
            editedAlertSms,
            alertEditorInitValues: {
              recipients: { recipients: emailRecipients, smsRecipients },
              mifluzIdLists,
            },
          },
          form: { alert },
        } = state

        const { values, initial: initialValues } = alert.edit || alert.add

        const propsToOmit = ['profiles', 'baskets', 'recipients']

        const edited = R.omit(propsToOmit, {
          ...values,
          mifluzIdLists: activeAlert.mifluzIdLists && R.sort(sillyAscendSort, activeAlert.mifluzIdLists),
          emailRecipients: editedAlertEmail,
          smsRecipients: editedAlertSms,
        })
        // In case user create new alert it will be enough to compare time prop,
        // So we have no need to compare schedule
        const editedValues = alert.edit
          ? R.assoc(
              'schedule',
              R.evolve({
                timeConfiguration: R.always(toAPITimeFormat(schedule.timeConfiguration)),
              })(schedule),
              edited,
            )
          : edited

        const initValues = R.omit(propsToOmit, {
          ...initialValues,
          emailRecipients,
          smsRecipients,
          mifluzIdLists: R.sort(sillyAscendSort, mifluzIdLists),
        })

        if (
          pathname.search('new') !== -1 &&
          ((!editedValues.subject && R.isEmpty([...editedAlertEmail, ...edited.mifluzIdLists, ...editedAlertSms])) ||
            !state.alerts.list.length)
        ) {
          // If nothing changed let a user just leave
          return next(action)
        }

        if (R.isEmpty([...editedAlertEmail, ...editedAlertSms, ...edited.mifluzIdLists, ...[editedValues.subject]])) {
          let error
          switch (true) {
            case !editedValues.subject:
              error = 'You have edited the alert, but did not fill subject field'
              break
            case R.isEmpty(editedAlertEmail):
            case R.isEmpty(editedAlertSms):
              error = 'You have edited the alert, but did not select any recipient'
              break
            case R.isEmpty(edited.mifluzIdLists):
              error = 'You have edited the alert, but did not select any content'
              break
            default:
              break
          }

          return store.dispatch(buildAction(ActionTypes.LEAVE_ALERT_FAILURE, { error }))
        }

        // In case alert was changed and user try to change rout - show him a warning modal
        if (!R.equals(editedValues, initValues)) {
          return store.dispatch(buildAction(ActionTypes.ASK_SAVE_EDITED_ALERT_OPEN, { action }))
        }
      }
    }
  }
  return next(action)
}

export const changeLanguageMiddleware = (store) => (next) => (action) => {
  const result = next(action)
  const opointLang = getOpointLocale(store.getState())
  if (opointLang && opointLang !== i18next.language) {
    const language = locales[opointLang]
    if (language) {
      moment.locale(language.momentLocale)
      OpointDate.setLanguage(language.momentLocale)
    }
    i18next.changeLanguage(opointLang)
  }
  return result
}

// @ts-nocheck
import React from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'
import classNames from 'classnames'
import Dropzone from 'react-dropzone'
import moment from 'moment'
import R from 'ramda'
import { connect } from 'react-redux'
// import { DragSource, DropTarget } from 'react-dnd'
import { reduxForm, Field, formValueSelector } from 'redux-form'

import ModulePermissions from '../../common/ModulePermissions'
import { MODULE, MODULE_OPTION } from '../../../opoint/settings'
import ArticleTag from '../../tags/ArticleTag'
import Loader from '../../common/Loader'
import buildActionCreators from '../../../helpers/buildActionCreators'
// import GroupCheckbox from '../../common/GroupCheckbox'
import OpointDatePickerPopper from '../../common/OpointDatePickerPopper'
import SourceLine from './SourceLine'
import CountryLine from './CountryLine'
import LanguageLine from './LanguageLine'
import Translate from '../../../Translate'
// import { FileItemTypes } from '../../../constants/dndItemTypes'
import { getOpointLocale, getLastReportDate } from '../../../selectors/settingsSelectors'
import { getTags, getLastUsedTag } from '../../../selectors/tagsSelectors'
// import { locales } from '../../../opoint/common/constants'
import OpointInput from '../../common/OpointInput'
import { OpointTextarea } from '../../common/OpointTextarea'
import { urlRegExp } from '../../../opoint/articles'
import {
  getAddArticleTagFilter,
  getAddArticleTags,
  getAddArticleValues,
  getAddArticleCountryFilter,
} from '../../../selectors/articlesSelectors'
import * as ActionTypes from '../../../constants/actionTypes'
import type { Tag, Suggestion, LocaleKey, Action, Filter } from '../../../opoint/flow'

const AddArticleField = ({ id, name, type, label, component, validate, errorMsg, disabled }) => (
  <Field
    id={id}
    name={name}
    type={type}
    label={label}
    component={component}
    validate={validate}
    errorMsg={errorMsg}
    disabled={disabled}
  />
)

// const fileSource = {
//   beginDrag(props) {
//     return { index: props.index }
//   }
// }

// function dragCollect(dragConnect, monitor) {
//   return {
//     connectDragSource: dragConnect.dragSource(),
//     connectDragPreview: dragConnect.dragPreview(),
//     isDragging: monitor.isDragging()
//   }
// }

// const fileTarget = {
//   hover(props, monitor) {
//     const dragIndex = monitor.getItem().index
//     const hoverIndex = props.index

//     if (dragIndex === hoverIndex) {
//       return
//     }

//     const payload = {
//       oldIndex: dragIndex,
//       newIndex: hoverIndex
//     }
//     props.handler(payload)

//     monitor.getItem().index = hoverIndex
//   }
// }

// function dropCollect(dropConnect) {
//   return {
//     connectDropTarget: dropConnect.dropTarget()
//   }
// }

// const FileItem = ({
//   connectDropTarget,
//   connectDragPreview,
//   connectDragSource,
//   isDragging,
//   file,
//   onDelete
// }) =>
//   connectDropTarget(
//     connectDragPreview(
//       <li
//         className={classNames("op-modal-add-article-upload-files-list-item")}
//         style={isDragging ? { opacity: 0 } : null}
//       >
//         <div className="wrap">
//           {connectDragSource(
//             <div className="handle">
//               <i className="op-icon-drag" />
//             </div>
//           )}
//           <div className="filename">{file}</div>
//           <div className="op-modal-add-article-upload-files-list-item__delete-button">
//             <button className="op-button-square" onClick={onDelete({ file })}>
//               <span className="op-icon-bin" />
//             </button>
//           </div>
//         </div>
//       </li>
//     )
//   )

// let DndFileItem = DragSource(
//   FileItemTypes.FILE,
//   fileSource,
//   dragCollect
// )(FileItem)
// DndFileItem = DropTarget(
//   FileItemTypes.FILE,
//   fileTarget,
//   dropCollect
// )(DndFileItem)

const FileListItem = ({ file, onDelete }) => (
  <li className={classNames('op-modal-add-article-upload-files-list-item')}>
    <div className="wrap">
      <div className="filename">{file}</div>
      <div className="op-modal-add-article-upload-files-list-item__delete-button">
        <button className="op-button-square" onClick={onDelete({ file })}>
          <span className="op-icon-bin" />
        </button>
      </div>
    </div>
  </li>
)

const FileList = ({ files, handler, onItemDelete }) => {
  const output =
    files && files.length ? (
      <ul className="op-modal-add-article-upload-files-list">
        {files.map((file, index) => (
          <FileListItem
            key={`uploaded-file-${file}`}
            file={file}
            // index={index}
            // handler={handler}
            onDelete={onItemDelete}
          />
        ))}
      </ul>
    ) : null

  return output
}

type AddArticleProps = {
  addArticle: () => void
  addArticleModalClose: () => void
  countryTouched: boolean
  files: Array<Object>
  geo: Array<Suggestion>
  isSaving: boolean
  lastReportDate: number
  locale: LocaleKey
  onCountryBlur: () => void
  onDateChange: Action<string>
  onFileReorder: (file: Object) => void
  onFileUpload: (obj: Object) => Action<Object>
  onSourceBlur: () => void
  searchCountryTerm: string
  searchGeoFilter: { [filterId: string]: Filter }
  searchSourceTerm: string
  site: Array<Suggestion>
  sourceTouched: boolean
  suggestionsCountry: Array<Suggestion>
  suggestionsSource: Array<Suggestion>
  tags: Array<Tag>
  time: string
  toggleTag: (value: string) => void
}

type AddArticleState = {
  isUploadingInProgress: boolean
  sourceType: string
}

class AddArticleForm extends React.PureComponent<AddArticleProps, AddArticleState> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  state = {
    isUploadingInProgress: false,
    sourceType: 'url',
  }

  componentDidUpdate(prevProps) {
    const { setIsFormChanged, dirty, articleTags, files, geo, site, lang, time } = this.props

    // Checking for any change
    if (
      prevProps.time !== time ||
      (articleTags && articleTags.length) ||
      (files && files.length) ||
      (geo && geo.length) ||
      (site && site.length) ||
      (lang && lang.length) ||
      dirty
    ) {
      setIsFormChanged(true)
    } else {
      setIsFormChanged(false)
    }
  }

  onDrop = (acceptedFiles) => {
    const { onFileUpload } = this.props
    // FormData can carry Blob and File
    const file = new FormData()

    // we do not support multiple files uploading here
    const acceptedFile = acceptedFiles[0]
    if (acceptedFile) {
      this.setState({ isUploadingInProgress: true })
      file.append('file', acceptedFile)
      onFileUpload({
        file,
        onProcessEndedCallback: this.finishUploadingProgress,
      })
    }
  }

  onDropRejectedHandler = () => {
    const { unsupportedFileError } = this.props
    this.finishUploadingProgress()
    unsupportedFileError()
  }

  onItemDelete = (file) => () => {
    const { deleteUploadedItem } = this.props
    deleteUploadedItem(file)
  }

  finishUploadingProgress = () => this.setState({ isUploadingInProgress: false })

  handleSubmit = (e) => {
    e.preventDefault()

    this.props.addArticle()
  }

  handleRadioChange = (event, sourceType) => this.setState({ sourceType: sourceType || event.target.value })

  render() {
    const {
      addArticleModalClose,
      articleTags,
      countryTouched,
      decrementTagWeight,
      files,
      geo,
      incrementTagWeight,
      isSaving,
      lang,
      lastReportDate,
      lastUsedTag,
      locale,
      onCountryBlur,
      onDateChange,
      onFileReorder,
      onSourceBlur,
      searchCountryTerm,
      searchGeoFilter,
      searchLangTerm,
      searchSourceTerm,
      setActiveTag,
      setOwnSource,
      site,
      sourceTouched,
      suggestionsCountry,
      suggestionsLang,
      suggestionsSource,
      tags,
      time,
      title,
      toggleTag,
    } = this.props
    const { i18n } = this.context
    const { isUploadingInProgress, sourceType } = this.state
    // validations
    const required = (value) => (value ? undefined : 'Required')
    const isURL = (value) => (urlRegExp.test(value) || !value ? undefined : 'notUrl')

    // combine redux-form validations and custom validations
    const formValid = title && site && !!site.length && geo && !!geo.length && lang && lang.length

    const defaultSite = {
      // ?country
      // country: "se",
      // name: "ex_swe"
      name: searchSourceTerm,
    }
    return (
      <div className="row">
        <div className="op-modal-add-article-form">
          <form onSubmit={this.handleSubmit} encType="multipart/form-data">
            <div className="row">
              <div className="col-md-6">
                <AddArticleField
                  id="title"
                  name="title"
                  type="text"
                  label={i18n.t('Title')}
                  component={OpointInput}
                  validate={required}
                  errorMsg={i18n.t('This field is required')}
                />
              </div>
              <div className="col-md-6">
                <OpointDatePickerPopper
                  startDate={time ? moment(time) : moment()}
                  onStartDateChange={onDateChange}
                  dateRange={false}
                  timePicker
                  locale={locale}
                  maxDate={moment()}
                  lastReportDate={lastReportDate}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="op-form-group">
                  <label htmlFor="country">
                    <Translate i18nString="Country" />
                  </label>
                  <CountryLine
                    searchterm={searchCountryTerm}
                    suggestions={suggestionsCountry}
                    filters={geo}
                    onBlurHandler={onCountryBlur}
                    suggestionType="geo"
                  />
                  {searchCountryTerm && countryTouched && !geo.length && !searchCountryTerm.length && (
                    <p className="help-block help-block--error">
                      <Translate i18nString="This field is required" />
                    </p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="op-form-group">
                  <label htmlFor="sources">
                    <Translate i18nString="Publisher" />
                  </label>
                  {/* TODO: Support for validation of React Tags Autocomplete */}
                  <SourceLine
                    searchterm={searchSourceTerm}
                    suggestions={suggestionsSource}
                    filters={site}
                    onBlurHandler={() => {
                      onSourceBlur()
                      if (!site.length && searchSourceTerm) {
                        setOwnSource([defaultSite])
                      }
                    }}
                    suggestionType="site"
                    isDisabled={R.isEmpty(searchGeoFilter)}
                  />
                  {searchSourceTerm && sourceTouched && !site.length && !searchSourceTerm.length && (
                    <p className="help-block help-block--error">
                      <Translate i18nString="This field is required" />
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="op-checkbox_wrapper">
                  <label className="op-modal-add-article-form__original-doc">
                    <Translate i18nString="Original document location: " />
                  </label>
                  <div className="op-radio">
                    <input
                      id="url"
                      type="radio"
                      name="sourceType"
                      value="url"
                      checked={sourceType === 'url'}
                      onChange={this.handleRadioChange}
                    />
                    <label htmlFor="url" />
                  </div>
                  <span
                    className="radio-desc"
                    style={{ marginRight: '10px' }}
                    onClick={() => this.handleRadioChange(null, 'url')}
                  >
                    {' '}
                    URL
                  </span>
                  <div className="op-radio">
                    <input
                      id="uploaded-files"
                      type="radio"
                      name="sourceType"
                      value="file"
                      checked={sourceType === 'file'}
                      onChange={this.handleRadioChange}
                    />
                    <label htmlFor="uploaded-files" />
                  </div>
                  <span className="radio-desc" onClick={() => this.handleRadioChange(null, 'file')}>
                    {' '}
                    <Translate i18nString="PDF file" />
                  </span>
                </div>
                {sourceType === 'url' ? (
                  <AddArticleField
                    id="url"
                    name="url"
                    type="url"
                    component={OpointInput}
                    validate={isURL}
                    errorMsg={i18n.t('Not a valid URL')}
                  />
                ) : (
                  <div style={{ marginTop: '5px', marginBottom: '10px' }}>
                    <Dropzone
                      onDrop={this.onDrop}
                      className={classNames('op-modal-add-article-upload-files', {
                        'is-disabled': files && files.length,
                      })}
                      multiple={false}
                      disabled={files && files.length}
                      accept="application/pdf, image/jpeg"
                      onDropRejected={this.onDropRejectedHandler}
                    >
                      {isUploadingInProgress ? <Loader /> : <Translate i18nString="Add a file to upload" />}
                    </Dropzone>
                    <FileList files={files} handler={onFileReorder} onItemDelete={this.onItemDelete} />
                  </div>
                )}
              </div>
              <div className="col-md-6">
                <div className="op-form-group">
                  <label htmlFor="language">
                    <Translate i18nString="Language" />
                  </label>
                  <LanguageLine
                    searchterm={searchLangTerm}
                    suggestions={suggestionsLang}
                    filters={lang}
                    onBlurHandler={onSourceBlur}
                    suggestionType="lang"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 op-modal-add-article-form__lead">
                <AddArticleField id="summary" name="summary" label={i18n.t('Lead')} component={OpointTextarea} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 op-modal-add-article-form__body">
                <AddArticleField id="text" name="text" label={i18n.t('Body')} component={OpointTextarea} />
              </div>
            </div>
            <div className="row">
              <div className="op-form-group col-md-12">
                <ModulePermissions module={MODULE.TAG} permissions={[MODULE_OPTION.ON]}>
                  <React.Fragment>
                    <div className="op-modal-add-article-form__tags">
                      {tags.map((tag, index) => (
                        <ArticleTag
                          decrementTagWeightFn={decrementTagWeight}
                          incrementTagWeightFn={incrementTagWeight}
                          setTagFn={toggleTag}
                          key={index}
                          tag={tag}
                          setActiveTag={setActiveTag}
                          lastUsedTag={lastUsedTag}
                          article={{ tags: articleTags || [] }}
                        />
                      ))}
                    </div>
                    {!Object.keys(articleTags || {}).length && (
                      <p className="help-block">
                        <Translate i18nString="Tagging the content makes it easy to find it later" />
                      </p>
                    )}
                  </React.Fragment>
                </ModulePermissions>
              </div>
            </div>

            {/* <div className="row">
              <div className="col-md-12">
                <div className="op-form-group inline-checkbox">
                  <div className="op-checkbox mod-bordered">
                    <Field
                      id="visible-for-all-customers"
                      name="isGlobal"
                      type="checkbox"
                      component="input"
                    />
                    <label htmlFor="visible-for-all-customers" />
                  </div>
                  <label htmlFor="visible-for-all-customers">
                    <Translate i18nString="Visible for all customers" />
                  </label>
                </div>
              </div>
            </div> */}

            <div className="row u-row-reverse">
              <button
                className={classNames('op-button bordered-gray', {
                  'simple-blue': !formValid || isSaving,
                  'simple-green': !(!formValid || isSaving),
                })}
                type="submit"
                disabled={!formValid || isSaving}
              >
                {isSaving && (
                  <span>
                    <Translate i18nString="Uploading" />
                    ...
                  </span>
                )}
                {!isSaving && <Translate i18nString="Upload" />}
              </button>
              <button
                className="op-button bordered-gray simple-red"
                type="button"
                disabled={isSaving}
                onClick={addArticleModalClose}
              >
                <Translate i18nString="Cancel" />
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

AddArticleForm = translate([], { wait: true })(AddArticleForm)

AddArticleForm = reduxForm({
  form: 'addArticle',
})(AddArticleForm)

const formSelector = formValueSelector('addArticle')
export default connect(
  (state) => ({
    title: formSelector(state, 'title'),
    articleTags: getAddArticleTags(state),
    countryTouched: getAddArticleValues(state).countryTouched,
    files: getAddArticleValues(state).fileNames,
    geo: getAddArticleValues(state).geo,
    isSaving: getAddArticleValues(state).isSaving,
    lastReportDate: getLastReportDate(state),
    lastUsedTag: getLastUsedTag(state),
    locale: getOpointLocale(state),
    searchCountryTerm: getAddArticleValues(state).searchCountryTerm,
    searchLangTerm: getAddArticleValues(state).searchLangTerm,
    searchGeoFilter: getAddArticleCountryFilter(state),
    searchSourceTerm: getAddArticleValues(state).searchSourceTerm,
    site: getAddArticleValues(state).site,
    lang: getAddArticleValues(state).language,
    sourceTouched: getAddArticleValues(state).sourceTouched,
    suggestionsCountry: getAddArticleValues(state).suggestionsCountry,
    suggestionsLang: getAddArticleValues(state).suggestionsLang,
    suggestionsSource: getAddArticleValues(state).suggestionsSource,
    tagFilter: getAddArticleTagFilter(state),
    tags: getTags(state),
    time: getAddArticleValues(state).time,
    initialValues: {
      isGlobal: false,
    },
  }),
  buildActionCreators({
    addArticle: ActionTypes.ADD_ARTICLE,
    decrementTagWeight: ActionTypes.ADD_ARTICLE_DECREMENT_TAG_WEIGHT,
    deleteUploadedItem: ActionTypes.ADD_ARTICLE_FILE_UPLOAD_DELETE_ITEM,
    incrementTagWeight: ActionTypes.ADD_ARTICLE_INCREMENT_TAG_WEIGHT,
    onCountryBlur: ActionTypes.ADD_ARTICLE_COUNTRY_TOUCHED,
    onDateChange: ActionTypes.ADD_ARTICLE_DATE_CHANGE,
    onFileReorder: ActionTypes.ADD_ARTICLE_FILE_REORDER,
    onFileUpload: ActionTypes.ADD_ARTICLE_FILE_UPLOAD,
    onSourceBlur: ActionTypes.ADD_ARTICLE_SOURCE_TOUCHED,
    setOwnSource: ActionTypes.SET_OWN_ARTICLE_SOURCE,
    setActiveTag: ActionTypes.SET_LAST_USED_TAG_ID,
    toggleTag: ActionTypes.ADD_ARTICLE_TOGGLE_TAG,
    unsupportedFileError: ActionTypes.ADD_ARTICLE_FILE_UNSUPPORTED_FILE_ERROR,
  }),
)(AddArticleForm)

import React from 'react'
import moment from 'moment'

import OpointDatepicker from '../common/OpointDatepicker'
import { isAlertPauseTimeStampValid } from '../../opoint/contacts'

export const DatePickerField = ({ input: { onChange, value }, locale }) => {
  const startDate = isAlertPauseTimeStampValid(value) ? moment.unix(value) : moment()
  const onStartDateChange = (date) => onChange(moment(date).unix())
  return (
    <OpointDatepicker // stop date allow only dates into future also time
      startDate={startDate}
      onStartDateChange={onStartDateChange}
      timePicker
      dateRange={false}
      locale={locale}
      maxDate={moment().add(5, 'y')}
      startDatePickerProps={{
        minDate: moment(),
      }}
      hideLastReportDate
    />
  )
}

import React from 'react'
import PropTypes from 'prop-types'

import ArticleSummary from './ArticleSummary'
import DisplayMedia from './DisplayMedia'
import MediaBodyComponent from './MediaBodyComponent'
import EditableText from './EditableText'
import { isArticleMedia } from '../../../helpers/common'
import OpointTextWithMatchesAndEntities from '../../../new-components/articles/OpointTextWithMatchesAndEntities'

export default class ArticleBody extends React.PureComponent<any> {
  static propTypes = {
    editable: PropTypes.bool.isRequired,

    onContentChange: PropTypes.func,
  }

  static contextTypes = {
    URLService: PropTypes.object,
  }

  render() {
    const {
      article,
      editable,
      onContentChange,
      article: {
        mediatype: { text: mediaType },
      },
    } = this.props

    /*
     Show VIDEO / RADIO content based on media type
     */
    if (isArticleMedia(mediaType)) {
      return <MediaBodyComponent {...this.props} />
    }

    return (
      <div className="op-content-article-listing-article-content-text">
        <ArticleSummary {...this.props} article={article} />
        <div className="article-body">
          {!this.props.editable && <DisplayMedia article={article} editable={editable} />}
          {this.props.editable ? (
            <EditableText article={article} onContentChange={onContentChange} />
          ) : (
            <div id={`article-${this.props.article.trackByProperty}-body`}>
              <div>
                <OpointTextWithMatchesAndEntities text={article.body.text} />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

import React from 'react'
import { connect } from 'react-redux'

import buildActionCreators from '../../../helpers/buildActionCreators'
import { getFilteredArticles } from '../../../selectors/statisticsSelectors'
import * as ActionTypes from '../../../constants/actionTypes'

class HideFilteredButton extends React.Component<any, any> {
  hideFilteredArticles = () => {
    const { isFromPopover, hidePopover, hideFilteredArticles } = this.props
    if (isFromPopover) {
      hidePopover()
    }
    hideFilteredArticles()
  }

  render() {
    const { isFromPopover, keyRef, name } = this.props
    if (isFromPopover) {
      return (
        <li key={keyRef} onClick={this.hideFilteredArticles}>
          <a>
            <i className="op-icon-pencil" />
            {name}
          </a>
        </li>
      )
    }
    return (
      <span>
        <button className="op-button-toolbar" onClick={this.hideFilteredArticles}>
          <span>{name}</span>
          <i className="op-icon-statistics" />
        </button>
      </span>
    )
  }
}
export default connect(
  (state) => ({
    filteredArticles: getFilteredArticles(state),
  }),
  buildActionCreators({
    hideFilteredArticles: ActionTypes.STATISTICS_HIDE_FILTERED,
  }),
)(HideFilteredButton)

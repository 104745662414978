import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'

import buildActionCreators from '../../helpers/buildActionCreators'
import FeedSuggestion from './FeedSuggestion'
import SearchInput from '../../components/search/SearchInput'
import { FeedEditorFilterComponent } from '../search/FilterComponent'
import { emptyFunction, returnFirst } from '../../helpers/common'
import * as ActionTypes from '../../constants/actionTypes'

type FeedEditorLineProps = {
  onSearchFilterAdded: () => {}
  onSearchFilterToggled: () => {}
  onSearchFilterRemoved: () => {}
  onClearAll: () => {}
}

class FeedEditorLine extends React.PureComponent<FeedEditorLineProps> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  render() {
    const { onSearchFilterAdded, onSearchFilterToggled, onSearchFilterRemoved, onClearAll } = this.props
    const { i18n } = this.context

    return (
      <SearchInput
        placeholder={i18n.t('Enter tag or profile')}
        filterComponent={FeedEditorFilterComponent}
        suggestionComponent={FeedSuggestion}
        onLoadSuggestions={emptyFunction}
        tagSeparator={false}
        onFilterAdded={onSearchFilterAdded}
        onFilterToggled={onSearchFilterToggled}
        removeFilter={onSearchFilterRemoved}
        clearAllHandler={onClearAll}
        autoSuggestion
        triggerSearch={emptyFunction}
        sortingFunction={returnFirst}
        typeComparator={returnFirst}
        {...this.props}
      />
    )
  }
}

// @ts-ignore
FeedEditorLine = translate([], { wait: true })(FeedEditorLine)

export default connect(
  null,
  buildActionCreators({
    onSearchFilterAdded: ActionTypes.FEED_ADD_FILTER,
    onSearchFilterToggled: ActionTypes.FEED_TOGGLE_FILTER,
    onSearchFilterRemoved: ActionTypes.FEED_REMOVE_FILTER,
    onClearAll: ActionTypes.FEED_CLEAR_ALL,
  }),
  // @ts-ignore
)(FeedEditorLine)

import React from 'react'

import Translate from '../common/Translate'
import Icon from '../common/Icon'
import { backButtonWrapper, titleWrapper } from './index.module.scss'

interface Props {
  onClick: () => void
  title: string
}

export default function BackButton({ onClick, title }: Props) {
  return (
    <div className={backButtonWrapper}>
      <button onClick={onClick}>
        <Icon name="ifm-arrow-left" verticalCenter />
        <Translate text="Back" />
      </button>
      <h3 className={titleWrapper}>
        <Translate text={title} />
      </h3>
    </div>
  )
}

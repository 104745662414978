import PropTypes from 'prop-types'
import classNames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { translate } from 'react-i18next'

import buildActionCreators from '../../../../helpers/buildActionCreators'
import Translate from '../../../../Translate'
import { getSaveAsProfilePopperHide } from '../../../../selectors/uiSelectors'
import * as ActionTypes from '../../../../constants/actionTypes'

class SaveAsProfilePopover extends React.Component<any, any> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  componentWillMount() {
    const { initPopper } = this.props

    initPopper()
  }

  componentDidMount() {
    document.addEventListener('click', this.props.clickOutside, false)
  }

  componentDidUpdate() {
    const { hidePopper, shouldHidePopper } = this.props

    shouldHidePopper && hidePopper()
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.props.clickOutside, false)
  }

  onClick = () => {
    const { saveAsProfile } = this.props

    saveAsProfile()
  }

  render() {
    const { i18n } = this.context

    return (
      <div className={classNames('op-popover')}>
        <div className="popover-content">
          <div className="wrapper">
            <div className="op-tag-edit">
              <div className="row">
                <div className="col-md-12">
                  <div className="col-md-6">
                    <div className="row">
                      <label htmlFor="name">
                        <Translate i18nString="Profile name" />
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <Field component="input" name="name" placeholder={i18n.t('Name')} id="name" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="button-wrapper">
                      <button type="button" onClick={this.onClick} className="op-button">
                        <Translate i18nString="Save" />
                      </button>
                      <button type="button" onClick={this.props.hidePopper} className="op-button">
                        <Translate i18nString="Cancel" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

// @ts-ignore
SaveAsProfilePopover = translate([], { wait: true })(SaveAsProfilePopover)

// @ts-ignore
SaveAsProfilePopover = reduxForm({
  form: 'profile.saveAs',
  // @ts-ignore
})(SaveAsProfilePopover)

export default connect(
  (state) => ({
    shouldHidePopper: getSaveAsProfilePopperHide(state),
  }),
  buildActionCreators({
    initPopper: ActionTypes.SAVE_AS_PROFILE_INIT_POPPER,
    saveAsProfile: ActionTypes.SAVE_AS_PROFILE,
  }),
)(SaveAsProfilePopover)

// @ts-nocheck
/* eslint-disable */
import React, { Component } from 'react'
 /* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types'
 /* eslint-disable-next-line import/no-extraneous-dependencies */
import classNames from 'classnames'

import './ReactTagsAutocomplete.css'

import type { Filter, Suggestion } from '../../opoint/flow'

const KEY_CODE_BACKSPACE = 8
const KEY_CODE_TAB = 9
const KEY_CODE_ENTER = 13
const KEY_CODE_ESCAPE = 27
const KEY_CODE_SPACE = 32
const KEY_CODE_ARROW_LEFT = 37
const KEY_CODE_ARROW_UP = 38
const KEY_CODE_ARROW_RIGHT = 39
const KEY_CODE_ARROW_DOWN = 40
const KEY_CODE_DELETE = 46
const KEY_CODE_PLUS = 107
const KEY_CODE_MINUS = 109

const styles = {
  wrapper: {
    position: 'relative',
    boxSizing: 'border-box',
    width: '100%',
  },
  textarea: {
    display: 'block',
    resize: 'none',
    overflow: 'hidden',
    width: '100%',
    top: '0',
    left: '0',
    padding: '0',
    border: '0',
    boxSizing: 'content-box',
  },
  filtersWrapper: {
    position: 'absolute',
    pointerEvents: 'none',
    top: '0',
    left: '0',
    width: '100%',
    textAlign: 'left',
    boxSizing: 'border-box',
  },
  textareaWrapper: {
    position: 'relative',
    top: '0',
    left: '0',
    boxSizing: 'border-box',
  },
  filters: {
    listStyleType: 'none',
    padding: '0',
    margin: '0',
  },
  filtersItem: {
    display: 'inline-block',
    pointerEvents: 'auto',
  },
  filterDefaultComponent: {},
  suggestionsWrapper: {
    position: 'absolute',
    marginTop: '3px',
    padding: '0',
    left: '0',
    right: '0',
    listStyleType: 'none',
    maxHeight: '21em',
    overflow: 'auto',
  },
  selectAllContent: {
    position: 'absolute',
    marginTop: '5px',
    padding: '0px',
    right: '10px',
    height: '20px',
    width: '45px',
    overflow: 'auto',
    fontSize: '12px',
  },
  suggestion: {
    padding: '10px',
    borderBottom: '1px solid rgba(49, 136, 6, 0.5)',
    borderRight: '1px solid rgba(49, 136, 6, 0.5)',
    borderLeft: '1px solid rgba(49, 136, 6, 0.5)',
  },
}

type ReactTagsAutocompleteProps = {
  alwaysShowClearAll: boolean,
  autoSuggestion: boolean,
  clearAllHandler: (arr: Array<Filter>) => void,
  filterComponent: React.DOM,
  filterId: (filter: Filter | Suggestion) => number,
  filters: Array<Filter>,
  id?: number,
  isExpressionEmpty: () => boolean,
  onBlurHandler: () => void,
  onFilterAdded: (filter: Filter, value: string) => void,
  onFilterToggled: (filter: Filter) => void,
  onFocus: () => void,
  onFocusHandler: () => void,
  onLoadSuggestions: () => void,
  onSearchTermChanged: (searchterm: string) => void,
  placeholder: string,
  placeholderIndent: number,
  removeFilter: (filter: Filter) => void,
  searchterm: string,
  showInlineSuggestions: boolean,
  suggestionComponent: React.DOM,
  suggestionWrapperStyle: Object,
  suggestions: Array<Suggestion>,
  tabIndex: number,
  tagSeparator: boolean,
  triggerSearch: () => void,
  typeComparator: (filter1: Filter, filter2: Filter) => Filter,
  noSugestionLabel: any,
  onEscapeKeyClicked: () => void,
  sortingFunction: (arr: Array<Filter>) => Array<Filter>;
  hint: string,
  suggestionProvider: Function,
  excludeHandler: (filter: Filter) => void,
  includeHandler: (filter: Filter) => void,
  profileTagList: Array<Filter>,
  selectAllIcon: string,
  isDisabled?: boolean,
  closeOnFilterAdded?: boolean,
  error?: boolean
}

type ReactTagsAutocompleteState = {
  indent: string,
  textAreaHeight: string,
  textAreaPadding: string,
  activeSuggestion?: number,
  isFocused: boolean,
}

class ReactTagsAutocomplete extends
  Component<ReactTagsAutocompleteProps, ReactTagsAutocompleteState> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  inputRef: HTMLTextAreaElement;
  wrapperRef: HTMLDivElement;
  filtersRefs: Array<HTMLLIElement>;
  emptyFilter: HTMLLIElement;
  suggestionsRef: HTMLUListElement;
  filtersWrapperRef: HTMLUListElement;

  constructor (props: ReactTagsAutocompleteProps) {
    super(props)
    this.state = {
      indent: '0px',
      textAreaHeight: '0px', // TODO need some refactoring for that. Seems it isn't used. Also investigate it for other sizes.
      textAreaPadding: '0px',
      activeSuggestion: null,
      isFocused: false,
    }

    this.filtersRefs = []

    // const { onLoadSuggestions } = this.props
    // onLoadSuggestions()
    // const {suggestionProvider} = this.props;
    // suggestionProvider(this.props.onSearchTermChanged(), this.props.filters, this.props.locale).then(suggestions => {
    //   this.setState({suggestions})
    // });
  }

  static propTypes = {
    /**
     * Array of filters.
     */
    filters: PropTypes.array,
    /**
     * tabindex that is passed to an editable field.
     */

    tabIndex: PropTypes.number,
    /**
     * Function called once filter should be added.
     */

    onFilterAdded: PropTypes.func,
    /**
     * Function called once filter should be toggled.
     */

    onFilterToggled: PropTypes.func,
    /**
     * Custom function to be called on focus event
     */
    onFocusHandler: PropTypes.func,
    /**
     * Custom function to be called on blur event
     */
    onBlurHandler: PropTypes.func,
    /**
     * Triggered once a searchterm is changed
     */

    onSearchTermChanged: PropTypes.func,
    /**
     * Clicked once an ESC key is clicked and suggestion list is hidden.
     */

    onEscapeKeyClicked: PropTypes.func,
    /**
     * Function that is called on enter
     */

    triggerSearch: PropTypes.func,
    /**
     * Defines whether suggestion box is triggered automatically or on keydown
     */
    autoSuggestion: PropTypes.bool,
    /**
     * Function called to removed a filter
     */

    removeFilter: PropTypes.func,
    /**
     * Type comparator
     */
    typeComparator: PropTypes.func.isRequired,
    /**
     * Function provided to sort groups
     */

    sortingFunction: PropTypes.func,
    /**
     * Placeholder to be displayed.
     * You can pass either a string or a function that generates
     * placeholder given an array of filters.
     */

    placeholder: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string,
    ]),

    /**
     * Function that returns an unique id for a filter
     */
    filterId: PropTypes.func,

    /**
     * Indent of placeholder from tags (in px)
     */
    placeholderIndent: PropTypes.number,
    /**
     * A function that returns a promise with suggestions.
     */

    suggestionProvider: PropTypes.func,
    /**
     * A function that returns a suggestion item markup.
     */
    suggestionComponent: PropTypes.func,
    /**
     * A function that returns a filter item markup.
     */
    filterComponent: PropTypes.func,
    /**
     * A function that clears all Filters.
     */

    clearAllHandler: PropTypes.func,
    /**
     * A function that returns a filter item markup.
     */

    noSugestionLabel: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string,
    ]),
    /**
     * Whether inline suggestions should be shown or not
     */
    showInlineSuggestions: PropTypes.bool,
    alwaysShowClearAll: PropTypes.bool,
    // TODO: refactor onFocus and onBlur methods in the component, it should allow
    onFocus: PropTypes.func,

    /**
     * Whether component should display separator between FilterComponents
     */
    tagSeparator: PropTypes.bool,

    /**
     * List of suggestions
     */
    suggestions: PropTypes.array,
    /**
     * Finction returns expression state
     */
    isExpressionEmpty: PropTypes.func,

    /**
     * Whether component should hide suggestions after one of them was added
     */
    closeOnFilterAdded: PropTypes.bool,
  }

  static defaultProps = {
    /**
     * Default filter component just shows a name
     * @param name
     */
    filterComponent: (
      { filter: { name } }: { filter: Filter }
    ) => <span style={styles.filterDefaultComponent}>{name}</span>,
    suggestionComponent: (
      { suggestion }: { suggestion: Suggestion }
    ) => <span>{suggestion.name}</span>,
    autoSuggestion: false,
    placeholderIndent: 0,
    showInlineSuggestions: true,
    filters: [],
    alwaysShowClearAll: false,
    onFocus: () => {},
    tagSeparator: true,
    onFocusHandler: () => {},
    onBlurHandler: () => {},
    suggestions: [],
    filterId: (x: Filter): string | number => x.id,
  }

  componentDidMount() {
    // #TODO Rethink the way we recalculate this upon mounting
    setTimeout(() => {
      this.recalculateLayout()
      this.recalculateIndent()
    }, 150)
    window.addEventListener('click', this.suggestionsDisplay)
  }

  componentWillUpdate() {
    this.filtersRefs = []
  }

  componentDidUpdate() {
    setTimeout(() => {
      this.recalculateIndent()
    }, 100)
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.suggestionsDisplay)
  }

  onFocusHandler = () => {
    this.setState({ isFocused: true })
    this.removeNoMatchesClass()
    this.setActiveClass()
    this.props.onLoadSuggestions()
    this.props.onFocusHandler()
    this.showSuggestions()
  }

  onBlurHandler = () => {
    this.setState({ isFocused: false })
    this.removeActiveClass()
    this.props.onBlurHandler()
  }

  inputRef: HTMLInputElement
  wrapperRef: HTMLDivElement
  filtersRefs: Array<HTMLLIElement>
  emptyFilter: HTMLLIElement
  suggestionsRef: HTMLUListElement
  filtersWrapperRef: HTMLUListElement

  recalculateLayout = () => {
    if (!this.wrapperRef) {
      return
    }
    const {
      paddingTop, paddingBottom, paddingLeft, paddingRight,
    } = window.getComputedStyle(this.wrapperRef)
    const horizontalPadding = parseInt(paddingLeft, 10) + parseInt(paddingRight, 10)
    const inputWrapper: React.DOM = this.inputRef.parentNode

    this.filtersWrapperRef.style.paddingTop = paddingTop
    this.filtersWrapperRef.style.paddingRight = paddingRight
    this.filtersWrapperRef.style.paddingBottom = paddingBottom
    this.filtersWrapperRef.style.paddingLeft = paddingLeft

    inputWrapper.style.marginTop = '0px'
    inputWrapper.style.marginRight = `-${paddingRight}`
    inputWrapper.style.marginBottom = `-${paddingBottom}`
    inputWrapper.style.marginLeft = `-${paddingLeft}`

    this.inputRef.style.paddingBottom = paddingBottom
    this.inputRef.style.paddingLeft = paddingLeft
    this.inputRef.style.paddingRight = paddingRight

    this.inputRef.style.width = `calc(100% - ${horizontalPadding}px)`
  }

  recalculateIndent = () => {
    if (!this.wrapperRef) {
      return
    }
    const filtersRefs = this.getFilterRefs()
    const { paddingTop, paddingLeft } = window.getComputedStyle(this.wrapperRef)
    const lastFilter: React.DOM = filtersRefs[filtersRefs.length - 1]
    const textAreaPaddingBottom = this.inputRef.style.paddingBottom

    if (lastFilter) {
      const clientRect = lastFilter.getBoundingClientRect()
      const parentClientRect = lastFilter.parentNode.getBoundingClientRect()

      const textAreaPadding = parseInt(clientRect.top, 10) - parseInt(parentClientRect.top, 10)
      const textAreaLineHeight = parseInt(clientRect.height, 10)
      const indent =
        parseInt(clientRect.right - parentClientRect.left - parseInt(paddingLeft, 10), 10)

      this.inputRef.style.paddingTop = `${textAreaPadding}px`
      this.inputRef.style.lineHeight = `${textAreaLineHeight}px`
      this.inputRef.style.textIndent = `${indent}px`

      // TODO the reason why we expand is bottom padding,
      // we set height to 0 so that we calculate a minimal
      // necessary scrollHeight
      this.inputRef.style.height = 0
      const textAreaHeight =
        parseFloat(
          parseFloat(this.inputRef.scrollHeight) -
          parseFloat(textAreaPadding) -
          parseFloat(textAreaPaddingBottom, 10)
        )
      this.inputRef.style.height = `${+textAreaHeight}px`
    } else {
      this.inputRef.style.lineHeight = '23px'
      this.inputRef.style.paddingTop = '3px'
      this.inputRef.style.textIndent = '0px'

      this.inputRef.style.height = 0
      const textAreaHeight =
        parseFloat(
          parseFloat(this.inputRef.scrollHeight) -
          parseFloat(paddingTop) -
          parseFloat(textAreaPaddingBottom)
        )
      this.inputRef.style.height = `${textAreaHeight}px`
    }
  }

  suggestionsDisplay = (event: SyntheticMouseEvent<HTMLElement>) => {
    if (this.suggestionsRef instanceof HTMLElement &&
      !this.suggestionsRef.contains(event.target) &&
      this.inputRef !== event.target
    ) {
      this.autoCompleteSuggestionsHandler()
      this.setState({ activeSuggestion: null });
      (this.props.isExpressionEmpty && !this.props.isExpressionEmpty()) && this.setNoMatchesClass()
    }
  }

  autoCompleteSuggestionsHandler = () => {
    const { suggestions } = this.props
    const firstSuggestion = suggestions[0]

    if (firstSuggestion && (firstSuggestion.type === 'email' || firstSuggestion.type === 'phoneNumber')) {
      const splittedValues = firstSuggestion.entity.value.split(' ')

      if (splittedValues.length < 2) {
        this.autoCompleteOnBlur(firstSuggestion)
      } else {
        const newVal = splittedValues.map((value: string) => ({
          type: firstSuggestion.type,
          entity: {
            id: value,
            value,
          },
        }))
        this.autoCompleteOnBlur(newVal)
      }
    }
  }

  setNoMatchesClass = () => {
    const { i18n } = this.context
    this.wrapperRef.title = i18n.t('Unknown data in the input field')
    this.wrapperRef.classList.add('rta-textarea--no-suggestions-match')
  }

  setActiveClass = () => {
    if (!this.wrapperRef) {
      return
    }
    this.wrapperRef.classList.add('rta-textarea--active')
  }

  removeNoMatchesClass = () => {
    this.wrapperRef.title = ''
    this.wrapperRef.classList.remove('rta-textarea--no-suggestions-match')
  }

  autoCompleteOnBlur = (toBeToggled: Array<Filter> | Filter) => {
    const { onFilterAdded } = this.props
    if (!toBeToggled.length) {
      return onFilterAdded(toBeToggled, this.inputRef.value)
    }
    return toBeToggled.forEach(filter => onFilterAdded(filter, this.inputRef.value))
  }

  removeActiveClass = () => {
    this.wrapperRef.classList.remove('rta-textarea--active')
  }

  focusTextArea = (focusEnd: boolean = true) => {
    this.inputRef.focus()
    focusEnd && this.inputRef.setSelectionRange(this.inputRef.value.length, this.inputRef.value.length)
  }

  focusPrevious = () => {
    const filtersRefs = this.filtersRefs
    const nextActiveElementIndex = filtersRefs.indexOf(document.activeElement) - 1
    if (filtersRefs[nextActiveElementIndex]) {
      filtersRefs[nextActiveElementIndex].focus()
    }
  }

  focusNext = () => {
    const filtersRefs = this.filtersRefs
    const nextActiveElementIndex = filtersRefs.indexOf(document.activeElement) + 1
    if (filtersRefs[nextActiveElementIndex]) {
      filtersRefs[nextActiveElementIndex].focus()
    } else if (nextActiveElementIndex === filtersRefs.length) {
      this.inputRef.focus()
    }
  }

  dispatchFilterAction = (index: number) => (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const { keyCode } = event
    const {
      removeFilter, onFilterToggled, triggerSearch, onFilterAdded, showInlineSuggestions,
    } = this.props
    const { activeSuggestion } = this.state
    switch (keyCode) {
      case KEY_CODE_ENTER:
        if (activeSuggestion === null || activeSuggestion === -1) {
          triggerSearch()
        } else if (!showInlineSuggestions) {
          onFilterAdded(this.getActiveSuggestion(), event.target.value)
        }
        this.setState({activeSuggestion: null})
        event.preventDefault()
        break
      case KEY_CODE_ARROW_RIGHT:
        this.focusNext()
        event.preventDefault()
        return false
      case KEY_CODE_ARROW_LEFT:
        this.focusPrevious()
        event.preventDefault()
        return false
      case KEY_CODE_ARROW_UP:
        this.selectPreviousSuggestion()
        break
      case KEY_CODE_ARROW_DOWN:
        this.selectNextSuggestion()
        break
      case KEY_CODE_ESCAPE:
        this.onEscapeKeyClicked()
        event.preventDefault()
        event.stopPropagation()
        return false
      case KEY_CODE_SPACE:
        if (!showInlineSuggestions) {
          break
        }
        if (this.getActiveSuggestion() && onFilterToggled(this.getActiveSuggestion(), event.target.value)) {
          this.onBlurHandler()
          this.setState({ activeSuggestion: null })
        }
        event.preventDefault()
        break
      case KEY_CODE_BACKSPACE:
        event.preventDefault()
        index === 0 && index === this.props.filters.length - 1 && this.focusTextArea(false)
        removeFilter(this.props.filters[index])
        this.focusPrevious()
        break
      case KEY_CODE_DELETE:
        index === this.props.filters.length - 1 && this.focusTextArea(false)
        removeFilter(this.props.filters[index])
        event.preventDefault()
        break
      case KEY_CODE_PLUS:
      case KEY_CODE_MINUS:
        break
      default:
        // console.log(keyCode);
        // this.focusTextArea();
    }
  }

  onKeyPressHandler = (filter: Filter) => (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const ch = String.fromCharCode(event.which)
    switch (ch) {
      case '-':
        this.props.excludeHandler(filter)
        this.props.onLoadSuggestions()
        event.preventDefault()
        return false
      case '+':
        this.props.includeHandler(filter)
        this.props.onLoadSuggestions()
        event.preventDefault()
        return false
      default:
        if (ch.toUpperCase() !== ch.toLowerCase()) {
          this.focusTextArea(true)
        }
    }
  }

  getFilterRefs() {
    return this.filtersRefs.filter(x => x !== null)
  }

  getActiveSuggestion() {
    const { suggestions } = this.props
    const { activeSuggestion } = this.state
    return suggestions[activeSuggestion]
  }

  focusLastFilter = () => {
    const filtersRefs = this.getFilterRefs()
    if (filtersRefs && filtersRefs.length) {
      filtersRefs[filtersRefs.length - 1].focus()
    }
  }

  selectPreviousSuggestion = () => {
    const { activeSuggestion } = this.state
    const prevSuggestion = this.suggestionsRef && this.suggestionsRef.children
      ? this.suggestionsRef.children[activeSuggestion - 1] || this.suggestionsRef.children[0]
      : null

    if (!prevSuggestion) return

    const prevSuggestionScrollDelta = prevSuggestion.getBoundingClientRect().top - this.suggestionsRef.getBoundingClientRect().top

    if (activeSuggestion === -1 || activeSuggestion === null) {
      // if manual suggestion mode is on, we allow to hide the suggestions manually
      this.setState({ activeSuggestion: null })
    } else {
      if (prevSuggestion && (prevSuggestionScrollDelta < 0)) {
        this.suggestionsRef.scrollTop = this.suggestionsRef.scrollTop + prevSuggestionScrollDelta
      }
      this.setState({ activeSuggestion: Math.max(-1, activeSuggestion - 1) })
    }
  }

  selectNextSuggestion = () => {
    const { suggestions } = this.props
    const { activeSuggestion } = this.state

    if (activeSuggestion === null) {
      this.setState({ activeSuggestion: -1 })
      return
    }

    if (this.suggestionsRef) {
      const nextSuggestion = this.suggestionsRef.children[activeSuggestion + 1] ||
        this.suggestionsRef.children[0]
      const nextSuggestionScrollDelta = nextSuggestion.getBoundingClientRect().bottom - this.suggestionsRef.getBoundingClientRect().bottom

      if (nextSuggestion && (nextSuggestionScrollDelta > 0)) {
        this.suggestionsRef.scrollTop = this.suggestionsRef.scrollTop + nextSuggestionScrollDelta
      }

      this.setState({ activeSuggestion: Math.min(suggestions.length - 1 || 0, activeSuggestion + 1) })
    }
  }

  onEscapeKeyClicked() {
    const { onEscapeKeyClicked } = this.props
    if (this.state.activeSuggestion === null) {
      onEscapeKeyClicked && onEscapeKeyClicked()
    } else {
      this.setState({ activeSuggestion: null })
    }
  }

  dispatchInputAction = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const { keyCode } = event
    const { activeSuggestion } = this.state
    const {
      onFilterAdded, onFilterToggled, triggerSearch, showInlineSuggestions,
    } = this.props

    switch (keyCode) {
      case KEY_CODE_ENTER:
        if (activeSuggestion === null || activeSuggestion === -1) {
          triggerSearch()
        } else {
          onFilterAdded(this.getActiveSuggestion(), event.target.value)
        }
        this.setState({ activeSuggestion: null })
        event.preventDefault()
        return false
      case KEY_CODE_ESCAPE:
        this.onEscapeKeyClicked()
        event.preventDefault()
        event.stopPropagation()
        return false
      case KEY_CODE_BACKSPACE:
      case KEY_CODE_ARROW_LEFT:
        // focus last filter if we're at the 0 position in textarea and the text is not selected
        if (this.inputRef.selectionStart === 0 && this.inputRef.selectionEnd === 0) {
          this.focusLastFilter()
          event.preventDefault()
          return false
        }
        break
      case KEY_CODE_SPACE:
        if (!showInlineSuggestions) {
          break
        }
        if (activeSuggestion !== null && activeSuggestion !== -1) {
          if (onFilterToggled(this.getActiveSuggestion(), event.target.value)) {
            this.onBlurHandler()
            this.setState({ activeSuggestion: null })
          }
          event.preventDefault()
          return false
        }
        break
      case KEY_CODE_ARROW_UP:
        this.selectPreviousSuggestion()
        event.preventDefault()
        return false
      case KEY_CODE_ARROW_DOWN:
        this.selectNextSuggestion()
        event.preventDefault()
        return false
      case KEY_CODE_TAB:
        setTimeout(() => {
          this.onBlurHandler()
          this.setState({ activeSuggestion: null })
        }, 0)
        return false
      default:
    }
  }

  onTextChanged = ({ target: { value } }: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const { onSearchTermChanged, searchterm } = this.props
    if (searchterm !== value) { // Why we need this?? @dmytro
      this.showSuggestions()
      onSearchTermChanged && onSearchTermChanged(value)
    }
  }

  showSuggestions = () => {
    const { autoSuggestion } = this.props
    if (autoSuggestion) {
      this.setState({ activeSuggestion: -1 })
    } else {
      const nextActiveSuggestionState = this.state.activeSuggestion === null ? null : -1
      this.setState({ activeSuggestion: nextActiveSuggestionState })
    }
    this.props.onLoadSuggestions()
  }

  isSuggestionSelected = (filters: Array<Filter>, suggestion: Suggestion) => {
    const { filterId } = this.props
    return filters.some(filter => filterId(filter) === filterId(suggestion))
  }

  clearButtonOnClickHandler = (e) => {
    e.preventDefault()
    this.props.clearAllHandler(this.props.filters)
  }

  // #TODO For some weird reason if there is not prevent default to onClick on clearButton handler, it changes route to
  // complete nonsense. Needs more looking into.
  preventDefault = (e) => {
    e.preventDefault()
  }

  onSuggestionClickHandler = (toBeToggled: Filter) => {
    // if (this.props.closeOnFilterAdded) {
    //   this.onBlurHandler()
    //   this.setState({ activeSuggestion: null })
    // }

    // @ts-ignore
    if (this.props.onFilterToggled(toBeToggled, this.inputRef.value)) {
      this.onBlurHandler()
      this.setState({ activeSuggestion: null })
    }
  }

  onSelectAllContentHandler = () => {
    const { suggestions, onFilterAdded } = this.props
    suggestions.forEach(contentItem => onFilterAdded(contentItem, this.inputRef.value))
  }

  onFilterFocus = (key: number) => () => {
    this.props.onFocus()
    this.filtersRefs[key].classList.add('is-focused')
  }

  onFilterBlur = (key: number) => () => {
    this.filtersRefs[key].classList.remove('is-focused')
  }

  deleteFilterHandler(key: number) {
    const { removeFilter, filters } = this.props
    return () => {
      removeFilter(filters[key])
    }
  }

  render() {
    const {
      filters,
      suggestions,
      id,
      placeholder,
      tabIndex,
      sortingFunction,
      typeComparator,
      showInlineSuggestions,
      tagSeparator,
      hint,
      searchterm,
      selectAllIcon,
      filterComponent: FilterComponent,
      suggestionComponent: SuggestionComponent,
      suggestionWrapperStyle,
      isDisabled,
      error = false
    } = this.props
    const { activeSuggestion, isFocused } = this.state
    const { i18n } = this.context

    let placeholderString
    if (filters && filters.length) {
      placeholderString = ''
    } else if (typeof placeholder === 'function') {
      placeholderString = placeholder(filters)
    } else if (typeof placeholder === 'string') {
      placeholderString = placeholder
    } else {
      placeholderString = `${i18n.t('Open filters and start typing to filter results')}...`
    }

    return (
      <div
        style={styles.wrapper}
        className=""
        className={classNames("rta-textarea", {
          "rta-textarea--disabled": isDisabled
        }, {error})}
        ref={ref => (this.wrapperRef = ref)}
        onFocus={this.onFocusHandler}
        onBlur={this.onBlurHandler}
      >
        <div style={styles.textareaWrapper}>
          <textarea
            id={id}
            className="rta-textarea__text"
            ref={ref => (this.inputRef = ref)}
            disabled={isDisabled}
            style={{
              ...styles.textarea,
              textIndent: this.state.indent,
              height: this.state.textAreaHeight,
              // lineHeight: `${this.state.textAreaLineHeight}px`, // The one isn't in the state
              paddingTop: this.state.textAreaPadding
            }}
            tabIndex={tabIndex}
            onChange={this.onTextChanged}
            onKeyDown={this.dispatchInputAction}
            onFocus={this.props.onFocus}
            placeholder={placeholderString}
            value={searchterm}
          />
          {showInlineSuggestions && activeSuggestion !== null ? (
            <div>
              <ul
                style={Object.assign(
                  {},
                  styles.suggestionsWrapper,
                  suggestionWrapperStyle
                )}
                className="rta-suggestions"
                ref={ref => (this.suggestionsRef = ref)}
              >
                {selectAllIcon && (
                  <span
                    className="rta-suggestions__select-all-button"
                    style={styles.selectAllContent}
                    onClick={this.onSelectAllContentHandler}
                    title={i18n.t("Select all")}
                  >
                    <span className={selectAllIcon} />
                    <span
                      className="op-icon-menu"
                      style={{ marginLeft: "5px" }}
                    />{" "}
                    {/* It is a temporary solutin because of icomoon bugs */}
                  </span>
                )}
                {suggestions &&
                  suggestions.length > 0 &&
                  suggestions.map((suggestion, key) => (
                    <li
                      key={key}
                      className={classNames(
                        "rta-suggestion",
                        { "rta-suggestion-active": key === activeSuggestion },
                        {
                          "is-selected": this.isSuggestionSelected(
                            filters,
                            suggestion
                          )
                        }
                      )}
                    >
                      <SuggestionComponent
                        suggestion={suggestion}
                        hint={hint}
                        onClickHandler={this.onSuggestionClickHandler}
                      />
                    </li>
                  ))}
                {suggestions.length === 0 && (
                  <li className="rta-suggestion rta-suggestion--empty">
                    {this.props.noSugestionLabel || i18n.t("No suggestions")}
                  </li>
                )}
              </ul>
            </div>
          ) : (
            undefined
          )}
          {(filters.length > 0 ||
            (this.inputRef && this.inputRef.value) ||
            this.props.alwaysShowClearAll) && (
            <button
              className="rta-textarea__clear"
              onMouseDown={this.clearButtonOnClickHandler}
              onClick={this.preventDefault}
            >
              <i className="op-icon-cross" />
            </button>
          )}
        </div>
        <div style={styles.filtersWrapper}>
          <ul
            style={styles.filters}
            ref={ref => (this.filtersWrapperRef = ref)}
          >
            {filters &&
              (sortingFunction ? filters.sort(sortingFunction) : filters).map(
                (filter, key, filters) => {
                  let sep = null;
                  if (tagSeparator) {
                    // Filter is last in filters array AND no subsequent searchterm AND searchfield is not focused
                    if (
                      key === filters.length - 1 &&
                      searchterm.length === 0 &&
                      !isFocused
                    ) {
                      sep = "last";
                    } else {
                      sep = typeComparator(filters[key + 1], filter)
                        ? "filterSeparator"
                        : "categorySeparator";
                    }
                  }
                  return (
                    <li
                      style={styles.filtersItem}
                      key={key}
                      className="rta-filters__filter"
                      ref={ref => (this.filtersRefs[key] = ref)}
                      onFocus={this.onFilterFocus(key)}
                      onBlur={this.onFilterBlur(key)}
                      tabIndex="-1"
                      onKeyDown={this.dispatchFilterAction(key)}
                      onKeyPress={this.onKeyPressHandler(filter)}
                    >
                      <FilterComponent
                        parentId={this.props.id}
                        filter={filter}
                        separator={sep}
                        deleteHandler={this.deleteFilterHandler(key)}
                        recalculateIndent={this.recalculateIndent}
                      />
                    </li>
                  );
                }
              )}
            {filters.length === 0 && (
              <li
                className="rta-filters__empty"
                ref={ref => (this.emptyFilter = ref)}
              />
            )}
          </ul>
        </div>
      </div>
    );
  }
}

export default (ReactTagsAutocomplete)

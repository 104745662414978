import * as React from 'react'
import ReactCountryFlag from 'react-country-flag'

import Icon from '../../common/Icon'
import i18n from '../../../i18nextInit'
import styles from './ArticleMetadataBox.module.scss'
import { LocaleKey } from '../../types/locale'
import { Article, ArticleMetadataType } from '../../types/article'

export type Props = {
  article: Article
  type: ArticleMetadataType
  locale: LocaleKey
}

export const ARTICLE_METADATA_CONFIG: {
  [key in ArticleMetadataType]: {
    title: (article?: Article) => string
    icon: (article: Article) => React.ReactElement
    value: (article: Article, locale?: LocaleKey) => React.ReactNode
  }
} = {
  country: {
    title: () => i18n.t('Country'),
    icon: ({ countrycode }) => {
      if (!countrycode) {
        return null
      }
      return <ReactCountryFlag countryCode={countrycode} className={styles.countryFlag} />
    },
    value: ({ countryname }) => countryname,
  },
  rank_global: {
    title: () => i18n.t('Global rank'),
    icon: () => <Icon name="earth" />,
    value: ({ site_rank }, locale) => site_rank?.rank_global?.toLocaleString(locale),
  },
  rank_country: {
    title: () => i18n.t('Country rank'),
    icon: () => <Icon name="location" />,
    value: ({ site_rank }, locale) => site_rank?.rank_country?.toLocaleString(locale),
  },
  page_per_visit: {
    title: () => i18n.t('Pages per visit'),
    icon: () => <Icon name="eye-alt" />,
    value: ({ similarweb }) => similarweb?.page_per_visit?.toFixed(2),
  },
  bounce_rate: {
    title: () => i18n.t('Bounce rate'),
    icon: () => <Icon name="enter" />,
    value: ({ similarweb }) => {
      if (!similarweb?.bounce_rate) {
        return null
      }
      return `${(similarweb.bounce_rate * 100).toFixed(1)}%`
    },
  },
  words: {
    title: () => i18n.t('Words'),
    icon: () => <Icon name="file-text" />,
    value: ({ word_count }, locale) => word_count?.toLocaleString(locale),
  },
  readers_so_far: {
    title: (article) => {
      if (!article) {
        // value returned in Settings
        return i18n.t('Readers so far')
      }
      const stdExpectedPValue = article.similarweb?.article_readership?.StdExpectedPValue

      if (!stdExpectedPValue) {
        return i18n.t('Readers')
      }

      return i18n.t('Readers so far')
    },
    icon: () => <Icon name="user" />,
    value: ({ similarweb }, locale) => similarweb?.article_readership?.value?.toLocaleString(locale),
  },
  new_readers_p_day: {
    title: () => i18n.t('New readers/hour'),
    icon: () => <Icon name="users" />,
    value: ({ similarweb }, locale) => similarweb?.article_readership?.DeltaPValue?.toLocaleString(locale),
  },
  average_readership: {
    title: () => i18n.t('Average readership'),
    icon: () => <Icon name="users" />,
    value: ({ similarweb }, locale) => similarweb?.readership?.value?.toLocaleString(locale),
  },
  mvisit: {
    title: () => i18n.t('Monthly Visits'),
    icon: () => <Icon name="users" />,
    value: ({ similarweb }, locale) => similarweb?.mvisit?.toLocaleString(locale),
  },
  unique_mobile_visit: {
    title: () => i18n.t('Unique Mobile Visits'),
    icon: () => <Icon name="users" />,
    value: ({ similarweb }, locale) => similarweb?.unique_mobile_visit?.toLocaleString(locale),
  },
  unique_desktop_visit: {
    title: () => i18n.t('Unique Desktop Visits'),
    icon: () => <Icon name="users" />,
    value: ({ similarweb }, locale) => similarweb?.unique_desktop_visit?.toLocaleString(locale),
  },
  unique_visit: {
    title: () => i18n.t('Unique Visits'),
    icon: () => <Icon name="users" />,
    value: ({ similarweb }, locale) => similarweb?.unique_visit?.toLocaleString(locale),
  },
  time_on_site: {
    title: () => i18n.t('Time on Site'),
    icon: () => <Icon name="alarm" />,
    value: ({ similarweb }, locale) =>
      similarweb?.time_on_site
        ? i18n.t('{{ timeOnSite }} sec', {
            timeOnSite: similarweb?.time_on_site?.toLocaleString(locale),
          })
        : '',
  },
  final_readers: {
    title: () => i18n.t('Final readers'),
    icon: () => <Icon name="user-plus" />,
    value: ({ similarweb }, locale) => {
      const stdExpectedPValue = similarweb?.article_readership?.StdExpectedPValue

      const expectedValue = similarweb?.article_readership?.ExpectedPValue

      const suffix = !!stdExpectedPValue ? ` ± ${stdExpectedPValue?.toLocaleString(locale)}` : ''

      if (!expectedValue) {
        return null
      }

      return `${expectedValue?.toLocaleString(locale)}${suffix}`
    },
  },
}

const ArticleMetadataBox = ({ article, type, locale }: Props) => {
  const data = ARTICLE_METADATA_CONFIG[type]
  const value = data?.value(article, locale)
  if (!value) {
    return null
  }
  return (
    <li className={styles.listItem}>
      <div className={styles.wrapper}>
        <span className={styles.title}>{data.title(article)}</span>
        <span className={styles.content}>
          {data.icon(article)}
          <span className={styles.value}>{value}</span>
        </span>
      </div>
    </li>
  )
}

export default ArticleMetadataBox

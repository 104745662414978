import React from 'react'
import classNames from 'classnames'
import PopperJS from 'popper.js'

import ArticleTagPopover from './Popover'
import Translate from '../../../../Translate'

type Props = {
  keyRef: any
  buttonsCnt: number
  isFromPopover: any
}

export default class TagArticles extends React.Component<Props, any> {
  popperRef: any
  rootRef: any

  constructor(props) {
    super(props)

    this.state = {
      popper: null,
    }

    this.popperRef = null
    this.rootRef = null
  }

  buttonClickHandler = (e) => {
    const { popper } = this.state

    popper ? this.hidePopper() : this.showPopper(e)
  }

  showPopper = (e) => {
    this.setState({
      popper: new PopperJS(e.target, this.popperRef, {
        placement: 'bottom',
      }),
    })
  }

  hidePopper = () => {
    const { popper: popperInstance } = this.state

    this.setState({
      popper: null,
    })

    popperInstance.destroy()
  }

  clickOutside = ({ target }) => {
    if (!(this.rootRef && this.rootRef.contains(target))) {
      this.hidePopper()
    }
  }

  render() {
    const { isFromPopover, buttonsCnt, keyRef: key } = this.props
    const { popper } = this.state

    return (
      <span
        ref={(ref) => {
          this.rootRef = ref
        }}
      >
        {isFromPopover ? (
          <li className="actionLine-popover-child">
            <a onClick={this.buttonClickHandler}>
              <i className="op-icon-attachment" />
              <Translate i18nString="Tag" />
            </a>
          </li>
        ) : (
          <button
            className={classNames(
              'op-button-toolbar',
              { 'actionLine__button--hidden': key >= buttonsCnt },
              { 'actionLine__button--last': key === buttonsCnt - 1 },
            )}
            onClick={this.buttonClickHandler}
          >
            <Translate i18nString="Tag" />
            <i className="op-icon-pencil" />
          </button>
        )}
        <span
          ref={(ref) => {
            this.popperRef = ref
          }}
        >
          {popper && <ArticleTagPopover clickOutside={this.clickOutside} />}
        </span>
      </span>
    )
  }
}

import React, { memo } from 'react'
import { connect } from 'react-redux'

import Modal from '../common/Modal'
import buildActionCreators from '../../helpers/buildActionCreators'
import Translate from '../common/Translate'
import { getOpointLocale } from '../../selectors/settingsSelectors'
import { locales } from '../../opoint/common/constants'
import { SOURCE_LIST_OPEN, SOURCE_LIST_CLOSE } from '../../constants/actionTypes'
import type { LocaleSourceListLocale } from '../types/locale'
import { list } from './index.module.scss'

interface Props {
  isOpen: boolean
  sourceListModalClose: () => void
  locale: LocaleSourceListLocale
}

const SourceListModal = ({ isOpen, sourceListModalClose, locale }: Props) => {
  const SourceLists = [
    {
      url: `http://kilder.opoint.com/Opointkilder_Norway_${locale}.pdf`,
      name: <Translate text="Web sources Norway" />,
    },
    {
      url: `http://kilder.opoint.com/Opointkilder_Print_no_${locale}.pdf`,
      name: <Translate text="Print sources Norway" />,
    },
    {
      url: `http://kilder.opoint.com/Opointkilder_Broadcast_no_${locale}.pdf`,
      name: <Translate text="Radio/TV sources Norway" />,
    },

    {
      url: `http://kilder.opoint.com/Opointkilder_Sweden_${locale}.pdf`,
      name: <Translate text="Web sources Sweden" />,
    },
    {
      url: `http://kilder.opoint.com/Opointkilder_Print_se_${locale}.pdf`,
      name: <Translate text="Print sources Sweden" />,
    },
    {
      url: `http://kilder.opoint.com/Opointkilder_Broadcast_se_${locale}.pdf`,
      name: <Translate text="Radio/TV sources Sweden" />,
    },

    {
      url: `http://kilder.opoint.com/Opointkilder_All_${locale}.pdf`,
      name: <Translate text="All sources worldwide" />,
    },
    {
      url: `http://kilder.opoint.com/Opointkilder_${locale}.html`,
      name: <Translate text="Country based source lists" />,
    },
  ]

  return (
    <Modal isOpen={isOpen} onHide={sourceListModalClose} title="Source lists">
      <ul className={list}>
        {SourceLists.map(({ url, name }) => (
          <li key={url}>
            <a href={url} target="_blank">
              {name}
            </a>
          </li>
        ))}
      </ul>
    </Modal>
  )
}

export default connect(
  (state: any) => {
    const opointLocale = getOpointLocale(state)
    return {
      isOpen: state.ui.sourceListModalOpen,
      locale: opointLocale ? locales[opointLocale].sourceListLocale : 'en',
    }
  },
  buildActionCreators({
    sourceListModalOpen: SOURCE_LIST_OPEN,
    sourceListModalClose: SOURCE_LIST_CLOSE,
  }),
)(memo(SourceListModal))

import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import OpointDate from '../../opoint/common/time'
import { notificationsTypes } from '../../constants/notificationsTypes'
import type { Notification } from '../../opoint/flow'

type NotificationProps = {
  notification: Notification
  onClickHandler: (param: any) => void
}

export default class NotificationComponent extends React.PureComponent<NotificationProps> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  onClickHandler = () => {
    const { onClickHandler, notification } = this.props
    onClickHandler({ report: notification.object.value, notificationId: notification.id })
  }

  render() {
    const { notification } = this.props
    const { i18n } = this.context
    const nType = notification.object.value.status.toString(10)

    return (
      <div className="col-md-12">
        <div className="row">
          <div className="op-notifications-item_wrapper">
            <div className="col-md-3  util_col-vertical-center">
              <div className="row">
                <div
                  className={classNames('op-notifications-item-icon', 'util_center-parent', {
                    [notificationsTypes[nType].class]: true,
                  })}
                >
                  <div className="util_center-child">
                    <i className={notificationsTypes[nType].icon} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9  util_col-vertical-center">
              <div className="row">
                <button
                  className={classNames('op-notifications-item-text', { [notificationsTypes[nType].class]: true })}
                  onClick={this.onClickHandler}
                  disabled={nType !== '1'}
                >
                  <span className="op-notifications-item-text-title">{notification.title}</span>
                  <span className="op-notifications-item-text-date">
                    {OpointDate.longFormat(notification.object.value.timeFinished * 1000)}
                    <br />
                    <span>{i18n.t(notificationsTypes[nType].label)}</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

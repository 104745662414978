import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'

import OpointDatepicker from '../../common/OpointDatepicker'
import OpointDate from '../../../opoint/common/time'
import Translate from '../../../Translate'
import type { Action, LocaleKey } from '../../../opoint/flow'
import { getArticleAgeLimit } from '../../../selectors/settingsSelectors'

export type Props = {
  onStartDateChange: () => Action<string>
  onEndDateChange: () => Action<string>
  startDate: Date
  endDate: Date
  locale: LocaleKey
  lastReportDate: number
}

const ProfileSource = (props: Props) => {
  const {
    locale,
    startDate,
    endDate,
    onStartDateChange,
    onEndDateChange,
    lastReportDate,
    // @ts-ignore
    articleAgeLimit: { to: toSecondsOffset, from: fromSecondsOffset },
  } = props

  return (
    <div className="op-modal-report-body-content-section">
      <div className="content__profile">
        <div>
          <div className="op-modal-datepicker__header">
            <div className="op-modal-datepicker__heading">
              <h4>
                <Translate i18nString="Start date" />
              </h4>
              {/*
               @ts-ignore */}
              <span>{OpointDate.humanFormat(moment(startDate))}</span>
            </div>
            <div className="op-modal-datepicker__heading">
              <h4>
                <Translate i18nString="End date" />
              </h4>
              {/*
               @ts-ignore */}
              <span>{OpointDate.humanFormat(moment(endDate))}</span>
            </div>
          </div>
          <OpointDatepicker
            startDate={startDate}
            endDate={endDate}
            maxDate={toSecondsOffset && moment().subtract(toSecondsOffset, 'second')}
            minDate={fromSecondsOffset && moment().subtract(fromSecondsOffset, 'second')}
            onStartDateChange={onStartDateChange}
            onEndDateChange={onEndDateChange}
            locale={locale}
            lastReportDate={lastReportDate}
          />
        </div>
      </div>
    </div>
  )
}

export default connect((state) => ({
  articleAgeLimit: getArticleAgeLimit(state),
}))(ProfileSource)

// @ts-nocheck

import { applyMiddleware, createStore, compose } from 'redux'
import { browserHistory } from 'react-router'
import { routerMiddleware } from 'react-router-redux'
import createLogger from 'redux-logger'
import createRavenMiddleware from 'raven-for-redux'
import Raven from 'raven-js'

import reducers from './reducers'
import { beforeRouteChangesMiddleware, epicMiddleware, changeLanguageMiddleware } from './redux-middleware'

Raven.config('https://d812db9a7d9a4712833bff77eda13946@sentry.io/148433', {
  debug: process.env.NODE_ENV === 'development',
  shouldSendCallback: () => process.env.NODE_ENV === 'production',
}).install()

// TODO https://github.com/zalmoxisus/redux-devtools-extension#usage
const composeEnhancers =
  (process.env.NODE_ENV !== 'production' &&
    /* eslint-disable-next-line no-underscore-dangle */
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

const enhancers = [
  beforeRouteChangesMiddleware,
  epicMiddleware,
  changeLanguageMiddleware,
  routerMiddleware(browserHistory),
  createRavenMiddleware(Raven),
]

// if diff is set to true, we are running into performance issues with large objects
const logger = createLogger({
  collapsed: true,
  diff: false,
})

if (process.env.NODE_ENV === 'development') {
  enhancers.push(logger)
}

const store = createStore(reducers, {}, composeEnhancers(applyMiddleware(...enhancers)))

// for cypress tests
if (process.env.REACT_APP_REDUX_IN_WINDOW === 'true') {
  window.reduxStore = store
}

export default store

// @ts-nocheck

import R from 'ramda'

import * as Actions from '../constants/actionTypes'

const initialState = {
  addArticleModalOpen: false,
  alertPreviewOpen: false,
  contactModalOpen: false,
  deleteProfilesModeEnabled: false,
  editArticleModalOpen: false,
  entityFilter: '',
  filterDetailModalOpen: false,
  filtersPanelOpen: false,
  isEditorFormModalOpen: false,
  isEditorModalOpen: false,
  isSortableModuleModalOpen: false,
  leaveEditAlertModalOpen: false,
  manageProfilesModalOpen: false,
  notificationDownloadModalOpen: false,
  saveAsProfilePopperHide: false,
  settingsModalOpen: false,
  shareArticleModalOpen: false,
  showBadBrowserPopup: false,
  userLoggedOut: false,
  statisticsExportPdfModal: false,
  newVersionNotification: false,
  deleteProfileConfirmationOpen: false,
  releaseNotesModalOpen: false,
}

const uiReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case Actions.SEARCH:
      return R.assoc('filtersPanelOpen', false, state)

    case Actions.SHOW_NEW_VERSION_NOTIFICATION:
      return R.assoc('newVersionNotification', true, state)

    case Actions.HIDE_NEW_VERSION_NOTIFICATION:
      return R.assoc('newVersionNotification', false, state)

    case Actions.FILTER_METAS_SHOW_DETAIL:
      return R.assoc('filterDetailModalOpen', true, state)

    case Actions.FILTER_METAS_HIDE_DETAIL:
      return R.assoc('filterDetailModalOpen', false, state)

    case Actions.SETTINGS_MODAL_OPEN:
      return R.assoc('settingsModalOpen', true, state)

    case Actions.SETTINGS_MODAL_CLOSE:
      return R.assoc('settingsModalOpen', false, state)

    case Actions.MANAGE_PROFILES_MODAL_OPEN:
      return R.assoc('manageProfilesModalOpen', true, state)

    case Actions.MANAGE_PROFILES_MODAL_CLOSE:
      return R.assoc('manageProfilesModalOpen', false, state)

    case Actions.REPORTS_HISTORY_OPEN:
      return R.assoc('reportsHistoryOpen', true, state)

    case Actions.REPORTS_HISTORY_CLOSE:
      return R.assoc('reportsHistoryOpen', false, state)

    case Actions.SOURCE_LIST_OPEN:
      return R.assoc('sourceListModalOpen', true, state)

    case Actions.SOURCE_LIST_CLOSE:
      return R.assoc('sourceListModalOpen', false, state)

    case Actions.EDIT_ARTICLE_MODAL_OPEN:
      return R.assoc('editArticleModalOpen', true, state)

    case Actions.EDIT_ARTICLE_MODAL_CLOSE:
      return R.assoc('editArticleModalOpen', false, state)

    case Actions.USER_LOGGED_OUT_IN_ANOTHER_TAB:
      return R.assoc('userLoggedOut', true, state)

    case Actions.SHARE_ARTICLE_MODAL_OPEN: {
      const article = payload
      return R.compose(R.assoc('shareArticleModalOpen', true), R.assoc('clickedArticle', article))(state)
    }

    case Actions.SHARE_ARTICLE_MODAL_CLOSE:
      return R.compose(R.assoc('shareArticleModalOpen', false), R.dissoc('clickedArticle'))(state)

    case Actions.CONTACT_MODAL_OPEN:
      return R.assoc('contactModalOpen', true, state)

    case Actions.CONTACT_MODAL_CLOSE:
      return R.compose(R.assoc('contactModalOpen', false), R.assoc('contactDeleteWarning', null))(state)

    case Actions.TEMPLATE_EDITOR_MODAL_OPEN:
      return R.assoc('isEditorModalOpen', true, state)

    case Actions.TEMPLATE_EDITOR_MODAL_CLOSE:
      return R.assoc('isEditorModalOpen', false, state)

    case Actions.TEMPLATE_EDITOR_FORM_MODAL_OPEN:
      return R.assoc('isEditorFormModalOpen', true, state)

    case Actions.TEMPLATE_EDITOR_FORM_MODAL_CLOSE:
      return R.assoc('isEditorFormModalOpen', false, state)

    case Actions.TEMPLATE_EDITOR_MODULE_SORT_MODAL_OPEN:
      return R.assoc('sortableModuleModalOpen', true, state)

    case Actions.TEMPLATE_EDITOR_MODULE_SORT_MODAL_CLOSE:
      return R.assoc('sortableModuleModalOpen', false, state)

    case Actions.ALERT_REMOVE_ARTICLE_OPEN:
      return R.assoc('alertRemoveArticleOpen', true, state)

    case Actions.ALERT_REMOVE_ARTICLE_CLOSE:
      return R.assoc('alertRemoveArticleOpen', false, state)

    case Actions.ALERT_PREVIEW_OPEN:
      return R.assoc('alertPreviewOpen', true, state)

    case Actions.ALERT_PREVIEW_CLOSE:
      return R.assoc('alertPreviewOpen', false, state)

    case Actions.ALERT_CHANGE_RECIPIENTS_OPEN:
      return R.assoc('alertChangeRecipientsOpen', true, state)

    case Actions.ALERT_CHANGE_RECIPIENTS_CLOSE:
    case Actions.ALERT_HISTORY_SEND_AGAIN_SUCCESS:
      return R.assoc('alertChangeRecipientsOpen', false, state)

    case Actions.ASK_SAVE_EDITED_ALERT_OPEN:
      return R.assoc('leaveEditAlertModalOpen', true, state)

    case Actions.ASK_SAVE_EDITED_ALERT_CLOSE:
      return R.assoc('leaveEditAlertModalOpen', false, state)

    case Actions.HELP_MODAL_OPEN:
      return R.assoc('helpModalOpen', true, state)

    case Actions.HELP_MODAL_CLOSE:
      return R.assoc('helpModalOpen', false, state)

    case Actions.FILTERS_PANEL_OPEN:
      return R.assoc('filtersPanelOpen', true, state)

    case Actions.FILTERS_PANEL_CLOSE:
    case Actions.ROUTER_LOCATION_CHANGE:
      return R.assoc('filtersPanelOpen', false, state)

    case Actions.FILTERS_PANEL_TOGGLE:
      return R.assoc('filtersPanelOpen', !state.filtersPanelOpen, state)

    case Actions.DELETE_PROFILES_MODE_ENABLE:
      return R.assoc('deleteProfilesModeEnabled', true, state)

    case Actions.DELETE_PROFILES_MODE_DISABLE:
      return R.evolve(
        {
          deleteProfilesModeEnabled: R.always(false),
        },
        state,
      )

    case Actions.DELETE_PROFILES_MODE_TOGGLE:
      return R.evolve(
        {
          deleteProfilesModeEnabled: R.always(!state.deleteProfilesModeEnabled),
        },
        state,
      )

    case Actions.ENTITY_FILTER_CHANGED:
      return R.assoc('entityFilter', payload, state)

    case Actions.ADD_ARTICLE_MODAL_OPEN:
      return R.assoc('addArticleModalOpen', true, state)

    case Actions.ADD_ARTICLE_SUCCESS:
    case Actions.ADD_ARTICLE_MODAL_CLOSE:
      return R.assoc('addArticleModalOpen', false, state)

    case Actions.NOTIFICATION_DOWNLOAD_MODAL_OPEN: {
      const { report, notificationId } = payload
      return R.compose(
        R.assoc('notificationDownloadModalOpen', true),
        R.assoc('reportOpened', report),
        R.assoc('notificationId', notificationId),
      )(state)
    }

    case Actions.NOTIFICATION_DOWNLOAD_MODAL_CLOSE:
      return R.compose(R.dissoc('reportOpened'), R.assoc('notificationDownloadModalOpen', false))(state)

    case Actions.REPORTS_MODAL_CLOSE:
      return R.assoc('reportsModalOpen', false, state)

    case Actions.REPORTS_MODAL_OPEN:
      return R.assoc('reportsModalOpen', true, state)

    case Actions.DATEPICKER_MODAL_OPEN:
      return R.assoc('datepickerModalOpen', true, state)

    case Actions.DATEPICKER_MODAL_CLOSE:
      return R.assoc('datepickerModalOpen', false, state)

    case Actions.SAVE_AS_PROFILE_INIT_POPPER:
      return R.assoc('saveAsProfilePopperHide', false, state)

    case Actions.SAVE_AS_PROFILE_SUCCESS:
      return R.assoc('saveAsProfilePopperHide', true, state)

    case Actions.PROFILE_DELETE_CONFIRMATION_OPEN:
      return R.assoc('deleteProfileConfirmationOpen', 'manage', state)

    case Actions.PROFILE_EDITOR_DELETE_CONFIRMATION_OPEN:
      return R.assoc('deleteProfileConfirmationOpen', 'editor', state)

    case Actions.PROFILE_DELETE_CONFIRMATION_CLOSE:
      return R.assoc('deleteProfileConfirmationOpen', false, state)

    case Actions.SHOW_BAD_BROWSER_POPUP:
      return R.assoc('showBadBrowserPopup', true, state)

    case Actions.HIDE_BAD_BROWSER_POPUP:
      return R.assoc('showBadBrowserPopup', false, state)

    case Actions.STATISTICS_VIEW_EXPORT_PDF_OPEN_MODAL:
      return R.assoc('statisticsExportPdfModal', true, state)

    case Actions.STATISTICS_VIEW_EXPORT_PDF_CLOSE_MODAL:
      return R.assoc('statisticsExportPdfModal', false, state)

    case Actions.STATISTICS_VIEW_EXPORT_PDF_SUCCESS:
      return R.assoc('statisticsExportPdfModal', false, state)

    case Actions.RELEASE_NOTES_MODAL_OPEN:
      return R.assoc('releaseNotesModalOpen', true, state)

    case Actions.RELEASE_NOTES_MODAL_HIDE:
      return R.assoc('releaseNotesModalOpen', false, state)

    default:
      return state
  }
}

export default uiReducer

import React from 'react'
import classNames from 'classnames'
import R from 'ramda'

import ShareContentInput from './ShareContentInput'
import Loader from '../common/Loader'
import Translate from '../../Translate'
import { reportsActionStep } from '../../opoint/reports/index'
import { TEMPLATE_TYPES } from '../../opoint/templates/index'

/*
  todo Flow is missing; this should be refactored – maybe we should make
  another smart component, because this component itself
  is connected to store = the abstraction here doesn't make sense,
  see reports/Modal.js, I copied a lots of code from notifications/Modal.js
 */
export default class ReportActions extends React.Component<any, any> {
  componentWillMount() {
    this.props.updateStep({ stepNumber: reportsActionStep.ACTION_SELECTION })
  }

  /**
   * TODO - refactor this hack
   * Checks if only property changed is selectedContacts, if yes, don't rerender component -
   * if you rerender component, contact input loses focus on input
   * which results in really terrible UX.
   */
  shouldComponentUpdate(nextProps) {
    const oldContacts = R.pick(['selectedContacts'], this.props)
    const newContacts = R.pick(['selectedContacts'], nextProps)
    const ommitedProps = R.omit(['selectedContacts'], this.props)
    const ommitedNextProps = R.omit(['selectedContacts'], nextProps)
    return !(oldContacts !== newContacts && R.equals(ommitedProps, ommitedNextProps))
  }

  componentWillUnmount() {
    this.props.clearData()
  }

  onShareClickHandler = () => {
    const { updateStep } = this.props
    updateStep({ stepNumber: reportsActionStep.SHARE_ACTION })
  }

  handleMessageChange = (e) => {
    const { changeMessage } = this.props
    changeMessage({ message: e.target.value })
  }

  /**
   * Sends user back to Download/Share step
   */
  backToActions = () => {
    const { updateStep } = this.props
    updateStep({ stepNumber: reportsActionStep.ACTION_SELECTION })
  }

  closeReports = () => {
    const { reportsClose } = this.props
    reportsClose()
  }

  render() {
    const {
      reportOpened,
      shareReportMessage,
      step,
      isAttachmentChecked,
      updateStep,
      toggleAttachment,
      sendReport,
      reportCreate,
    } = this.props
    return (
      <div className="modal-body-actions">
        {(step === reportsActionStep.SHARING_REPORT ||
          step === reportsActionStep.SHARE_REPORT_SUCCESS ||
          step === reportsActionStep.SHARE_REPORT_FAILURE) && (
          <div className="modal-body-action-info">
            <div className="util_center-parent mod-full-all">
              <div className="util_center-child">
                {step === reportsActionStep.SHARING_REPORT && <Loader />}
                {step === reportsActionStep.SHARE_REPORT_SUCCESS && (
                  <h2 className="action-info-margin">
                    <Translate i18nString="Your report was successfully shared." />
                  </h2>
                )}
                {step === reportsActionStep.SHARE_REPORT_FAILURE && (
                  <h2 className="action-info-margin">
                    <Translate i18nString="Error occurred, please try again later." />
                  </h2>
                )}
                {(step === reportsActionStep.SHARE_REPORT_SUCCESS ||
                  step === reportsActionStep.SHARE_REPORT_FAILURE) && (
                  <div>
                    <div className="button-group">
                      <button className="op-button mod-login" onClick={this.backToActions}>
                        <Translate i18nString="Back" />
                      </button>
                      {reportCreate && (
                        <button className="op-button mod-login" onClick={this.closeReports}>
                          <Translate i18nString="Close" />
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="modal-body-actions-view">
          <div
            className={classNames('modal-body-actions-action text-center', {
              'is-active': step === reportsActionStep.ACTION_SELECTION,
            })}
          >
            <div className="util_center-parent mod-full-all">
              <div className="util_center-child">
                <a
                  className="op-button mod-login"
                  target={
                    !reportOpened ||
                    reportOpened.type === TEMPLATE_TYPES.PDF ||
                    reportOpened.type === TEMPLATE_TYPES.HTML
                      ? '_blank'
                      : '_self'
                  }
                  rel="noopener noreferrer"
                  href={reportOpened ? reportOpened.download : null}
                  tabIndex={1}
                >
                  <Translate i18nString="Download" />
                </a>
                <button className="op-button mod-login" onClick={this.onShareClickHandler} tabIndex={2}>
                  <Translate i18nString="Share" />
                </button>
              </div>
            </div>
          </div>
          <ShareContentInput
            shareMessage={shareReportMessage}
            updateStep={updateStep}
            handleMessageChange={this.handleMessageChange}
            toggleAttachment={toggleAttachment}
            isAttachmentChecked={isAttachmentChecked}
            sendReport={sendReport}
            stepNumber={step}
            formName="shareReportContacts"
            suggestionWrapperStyle={{ maxHeight: '10em' }}
          />
        </div>
      </div>
    )
  }
}

import React from 'react'
import classNames from 'classnames'

import ModulePermissions from './ModulePermissions'
import { MODULE_OPTION } from '../../opoint/settings/index'

class EntityHeader extends React.PureComponent<any> {
  static defaultProps = {
    newButton: true,
  }

  onClick = () => {
    const { category, toggleCategory } = this.props
    toggleCategory(category)
  }

  render() {
    const {
      categoryName,
      newButton,
      isExpanded,
      onNewButtonClicked,
      module,
      newEntityTitle,
      toggleTagVisibilityButton,
    } = this.props

    return (
      <div className="entity__header">
        <i className={classNames('op-icon-arrow-down', { 'title--expanded': isExpanded })} onClick={this.onClick} />
        <h3 onClick={this.onClick}>{categoryName}</h3>
        {newButton && (
          <ModulePermissions module={module} permissions={[MODULE_OPTION.ON]}>
            <div className="entity__options">
              <button className="op-button-square" title={newEntityTitle} onClick={onNewButtonClicked}>
                <span className="op-icon-plus" />
              </button>
              {toggleTagVisibilityButton}
            </div>
          </ModulePermissions>
        )}
      </div>
    )
  }
}

export default EntityHeader

// @ts-nocheck
import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { translate } from 'react-i18next'

import buildActionCreators from '../../helpers/buildActionCreators'
import entityFilter from '../common/entityFilter'
import EntityHeader from '../common/EntityHeader'
import FilterMessage from '../common/FilterMessage'
import ListingSubTree from './ListingSubTree'
import { getAlertTags } from '../../selectors/alertsSelectors'
import { getUISetting } from '../../selectors/settingsSelectors'
import { searchContext } from '../../selectors/routingSelectors'
import * as ActionTypes from '../../constants/actionTypes'
import type { AlertTag } from '../../opoint/flow'

type AlertTagListingProps = {
  expanded: boolean
  filterExpression: string
  list: Array<AlertTag>
  displayImportant: string
  numberOfFiltered: number
  toggleCategory: () => {}
}

class AlertTagListing extends React.Component<AlertTagListingProps> {
  isExpanded = () => {
    const { expanded, filterExpression } = this.props
    return expanded || !!filterExpression
  }

  render() {
    const { list: tags, filterExpression, numberOfFiltered, resetFilter, i18n, toggleCategory } = this.props

    if (!tags.length) {
      return null
    }

    return (
      <div className="col-md-12">
        <div className="row">
          <div className="entity">
            <EntityHeader newEntityTitle={i18n.t('Create new tag')} isExpanded={this.isExpanded()} {...this.props} />
            <div className={classNames('entity__body', { 'entity_body--hidden': !this.isExpanded() })}>
              {tags && !!tags.length && <ListingSubTree {...this.props} entities={tags} />}
              <FilterMessage
                filterExpression={filterExpression}
                listLength={tags.length}
                listName="tags"
                numberOfFiltered={numberOfFiltered}
                resetFilter={resetFilter}
                toggleCategory={toggleCategory}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

AlertTagListing = entityFilter(AlertTagListing)

AlertTagListing = connect(
  (state, props) => ({
    list: getAlertTags(state),
    expanded: getUISetting('ALERTTAG_CATEGORY')(state),
    searchContext: searchContext(state),
  }),
  {
    onClickHandler: (searchRoute, id) => push(`/${searchRoute}/?filters=tag:${id}`),
    ...buildActionCreators({
      expandTag: ActionTypes.ALERT_TAG_EXPAND,
      toggleCategory: ActionTypes.TOGGLE_UI_SETTING,
      resetFilter: ActionTypes.ENTITY_FILTER_CHANGED,
    }),
  },
)(AlertTagListing)

AlertTagListing = translate([], { wait: true })(AlertTagListing)

export default AlertTagListing

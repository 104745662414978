import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { translate } from 'react-i18next'

import buildActionCreators from '../../../helpers/buildActionCreators'
import entityFilter from '../../common/entityFilter'
import EntityHeader from '../../common/EntityHeader'
import FilterMessage from '../../common/FilterMessage'
import { getUISetting } from '../../../selectors/settingsSelectors'
import { getStatisticsList, getActiveViewId } from '../../../selectors/statisticsSelectors'
import { MODULE } from '../../../opoint/settings/index'
import * as ActionTypes from '../../../constants/actionTypes'

type StatViewsListingProps = {
  statViewId: string
  displayImportant: string
  expanded?: boolean
  filterExpression?: string
  list: Array<any>
  numberOfFiltered: number
  setActiveView: () => void
  toggleCategory: () => void
  goToStatView: (id: any) => void
  resetFilter: () => void
  i18n: any
}

class StatisticViewsListingComponent extends React.PureComponent<StatViewsListingProps> {
  static contextTypes = {
    getString: PropTypes.func,
  }

  onOpenPageClickHandler = (id) => () => {
    this.props.goToStatView(id)
  }

  isExpanded = () => {
    const { expanded, filterExpression } = this.props
    return expanded || !!filterExpression
  }

  render() {
    const {
      filterExpression,
      list: views,
      numberOfFiltered,
      resetFilter,
      statViewId,
      i18n,
      toggleCategory,
    } = this.props
    const { getString } = this.context

    // If there are no statistic views, we don't display listing in the left column
    if (!views.length) {
      return <div />
    }

    return (
      <div className="col-md-12">
        <div className="row">
          <div className="entity">
            <EntityHeader
              module={MODULE.STATISTICS}
              getString={getString}
              newButton={false}
              isExpanded={this.isExpanded()}
              newEntityTitle={i18n.t('Create new tag')}
              {...this.props}
            />
            <div
              className={classNames('entity__body', 'entity__body--marginBottom2', {
                'entity_body--hidden': !this.isExpanded(),
              })}
            >
              {views && !!views.length && (
                <ul>
                  {views.map((view) => (
                    <li key={view.id} title={view.name}>
                      <div className="radio-container">
                        <div>
                          <div className="op-radio left-column-radio">
                            <input type="radio" id={view.id} checked={view.id === statViewId} readOnly />
                            <label htmlFor={view.id} onClick={this.onOpenPageClickHandler(view.id)} />
                          </div>
                          <span className="radio-desc" onClick={this.onOpenPageClickHandler(view.id)}>
                            {view.name}
                          </span>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
              <FilterMessage
                filterExpression={filterExpression}
                listLength={views.length}
                listName="views"
                numberOfFiltered={numberOfFiltered}
                resetFilter={resetFilter}
                toggleCategory={toggleCategory}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const ConnectedStatisticViewsListingConnect = connect(
  (state, props: any) => ({
    list: getStatisticsList(state),
    expanded: getUISetting(props.category)(state),
    statViewId: getActiveViewId(state),
  }),
  {
    goToStatView: (statisticViewId) => push(`/statistics/search?filters=chart:${statisticViewId}`),
    ...buildActionCreators({
      toggleCategory: ActionTypes.TOGGLE_UI_SETTING,
      resetFilter: ActionTypes.ENTITY_FILTER_CHANGED,
      setActiveView: ActionTypes.STATISTICS_VIEWS_SET_ACTIVE,
    }),
  },
)(entityFilter(StatisticViewsListingComponent))

const ConnectedStatisticViewsListing = translate([], { wait: true })(ConnectedStatisticViewsListingConnect)

export default ConnectedStatisticViewsListing

import React from 'react'
import classNames from 'classnames'
import PopperJS from 'popper.js'
import { connect } from 'react-redux'

import SaveAsProfilePopover from './Popover'
import Translate from '../../../../Translate'
import { getUserPermissions } from '../../../../selectors/settingsSelectors'
import { MODULE, MODULE_OPTION } from '../../../../opoint/settings/index'

type Props = {
  keyRef: any
  buttonsCnt: number
  isFromPopover: any
  isPermitted: any
  shortLabel: any
}

class SaveAsProfile extends React.Component<Props, any> {
  popperRef: any
  rootRef: any
  popperArrowRef: any

  constructor(props) {
    super(props)

    this.state = {
      popper: null,
    }

    this.popperRef = null
    this.rootRef = null
    this.popperArrowRef = null
  }

  buttonClickHandler = (e) => {
    const { popper } = this.state

    popper ? this.hidePopper() : this.showPopper(e)
  }

  centerPopperArrow = (offsets) => {
    const { popper, reference } = offsets
    const PADDING = 11
    const MAGIC_NUMBER = 3 // used to fix few pixels off the true center

    const newPos = reference.left - PADDING + (reference.width + PADDING * 2) / 2 - popper.left - MAGIC_NUMBER

    this.popperArrowRef.style.left = `${newPos}px`
  }

  showPopper = (e) => {
    this.setState({
      popper: new PopperJS(e.target, this.popperRef, {
        placement: 'bottom',
        onCreate: (data) => this.centerPopperArrow(data.offsets),
        onUpdate: (data) => this.centerPopperArrow(data.offsets),
        modifiers: {
          arrow: {
            enabled: true,
            element: '.popper__arrow',
          },
        },
      }),
    })
  }

  hidePopper = () => {
    const { popper: popperInstance } = this.state

    this.setState({
      popper: null,
    })

    popperInstance.destroy()
  }

  clickOutside = ({ target }) => {
    if (!(this.rootRef && this.rootRef.contains(target))) {
      this.hidePopper()
    }
  }

  render() {
    const { buttonsCnt, keyRef: key, isFromPopover, isPermitted, shortLabel } = this.props
    const { popper } = this.state
    const label = shortLabel ? <Translate i18nString="Save as" /> : <Translate i18nString="Save as profile" />

    return (
      <span
        ref={(ref) => {
          this.rootRef = ref
        }}
      >
        {isFromPopover ? (
          <li className="actionLine-popover-child">
            <a onClick={this.buttonClickHandler}>
              <i className="op-icon-attachment" />
              {label}
            </a>
          </li>
        ) : (
          <button
            className={classNames(
              'op-button-toolbar',
              { 'actionLine__button--hidden': key >= buttonsCnt },
              { 'actionLine__button--last': key === buttonsCnt - 1 },
            )}
            onClick={this.buttonClickHandler}
            disabled={!isPermitted}
          >
            {label}
            <i className="op-icon-attachment" />
          </button>
        )}
        <span
          className="popper"
          ref={(ref) => {
            this.popperRef = ref
          }}
        >
          {popper && (
            <div
              className="popper__arrow"
              x-arrow="true"
              ref={(ref) => {
                this.popperArrowRef = ref
              }}
            />
          )}
          {popper && <SaveAsProfilePopover clickOutside={this.clickOutside} hidePopper={this.hidePopper} />}
        </span>
      </span>
    )
  }
}

export default connect((state) => {
  const userPermissions = getUserPermissions(MODULE.PROFILE)(state)
  const isPermitted = MODULE_OPTION.ON === userPermissions
  return {
    isPermitted,
  }
})(SaveAsProfile)

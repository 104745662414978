import React, { SyntheticEvent, useMemo } from 'react'
import classNames from 'classnames'

import { getSocialMediaLogo } from '../../helpers/article'
import Image from '../../image'
import { GetStringContext } from '../../../getStringProvider'
import { Article } from '../../types/article'

type Props = {
  active?: boolean
  activeArticle?: null
  article: Article
  articleSource?: string
  checked?: boolean
  editTooltip?: boolean
  identical?: boolean
  imagePosition?: string
  imageSize?: string
  index?: number
  onCheckedToggle?: () => void
  onClickHandler?: () => void
  onEditArticleModalOpen?: () => void
  shareArticle?: () => void
  shareTooltip?: boolean
  shouldHideTags?: boolean
  showShortSummary?: boolean
  tagsFn?: (article: Article) => void
  visibleTrashTagsFn?: (article: Article) => void
  URLService?: any
}

const ImageComponent = (props: Props) => {
  const context = React.useContext(GetStringContext)

  const { article, URLService: artificiallyThrownURLService } = props
  const { URLService = artificiallyThrownURLService } = context

  const saveSitename = useMemo(() => article.first_source.sitename.trim().replace(/\s\s+/g, ' '), [article])

  const isSocial = useMemo(() => article.mediatype.text === 'SOCIAL', [article])

  const hasImage = (): boolean =>
    !!(article.articleimages && article.articleimages.articleimage && article.articleimages.count)
  const hasIcon = (): boolean => article.icon !== undefined

  const getImageIcon = () => {
    if (hasImage()) {
      return URLService.getThumbnailUrl(article.articleimages.articleimage[0].url, 100, 100)
    }
    if (hasIcon()) {
      return URLService.getAppleIconUrl(article.icon)
    }
    if (isSocial) {
      return getSocialMediaLogo(article.first_source.sitename)
    }
    return null
  }

  const imageUrl = useMemo(() => getImageIcon(), [article])

  const handleMissingImage = (event: SyntheticEvent<HTMLImageElement, Event>): void => {
    const newSrc = getSocialMediaLogo(article?.first_source?.sitename)
    if (newSrc !== null) {
      event.currentTarget.src = newSrc
    }
  }

  const placeholder = useMemo(
    () => ({
      title: saveSitename
        ? saveSitename
            .split(' ')
            .slice(0, 2)
            .map((x) => x[0].toUpperCase())
            .join('')
        : '',
      background: saveSitename.length % 5,
    }),
    [saveSitename],
  )

  const placeholderClass = useMemo(() => `mod-placeholder-${placeholder.background}`, [placeholder])

  return (
    <div
      className={classNames({
        'op-content-right-section-content-left-holder': true,
        'is-placeholder-disabled': !!imageUrl,
        [placeholderClass]: !isSocial || !imageUrl,
      })}
      data-placeholder-title={placeholder.title}
      title={article.first_source.sitename}
    >
      <Image imageUrl={imageUrl} handleMissingImage={handleMissingImage} />
    </div>
  )
}

export default ImageComponent

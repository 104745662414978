import React from 'react'
import { Field } from 'redux-form'

import Checkbox from '../common/Checkbox'
import { checkedGroupProp } from '../../opoint/contacts'

export const CheckboxWrapper = ({ input: { value, onChange, checked }, ...props }) => (
  <Checkbox
    onChangeHandler={onChange}
    value={value}
    isChecked={checked}
    id={`contact-group-id-${props.index}`}
    classNameMod="bordered"
  >
    {props.label}
  </Checkbox>
)

export const GroupCheckbox = ({ input, ...props }) => (
  <Field name={input.name} type="checkbox" component={CheckboxWrapper} {...props} />
)

export const GroupsCheckboxes = ({ fields }) => (
  <div>
    {fields.map((group, index) => (
      <li key={index} className="op-checkbox-list-input-list-item">
        <Field
          component={GroupCheckbox}
          name={`${group}.${checkedGroupProp}`}
          label={fields.get(index).name}
          index={index}
        />
      </li>
    ))}
  </div>
)

import * as React from 'react'
import classNames from 'classnames'
import { DragSource, DropTarget } from 'react-dnd'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import OpointDate from '../../../opoint/common/time'
import Translate from '../../../Translate'
import { ITEM_TYPE } from './index'
import type { Article, ReportGroup } from '../../../opoint/flow'

const itemSource = {
  beginDrag({ index, group: { weight } }) {
    return {
      weight, // id of group = API naming stuff
      index,
    }
  },
}

const itemTarget = {
  hover({ index, move, ...props }, monitor, component) {
    // This is not Kumar, this is Dan Abramov
    // https://github.com/react-dnd/react-dnd/blob/master/examples/04%20Sortable/Simple/Card.js

    if (!monitor.canDrop(props, monitor)) {
      return
    }

    const { index: dragIndex, weight: sourceWeight } = monitor.getItem()
    const hoverIndex = index

    if (dragIndex === hoverIndex) {
      return
    }

    move(sourceWeight, dragIndex, hoverIndex)

    monitor.getItem().index = hoverIndex
  },
  canDrop({ group, group: { weight } }, monitor) {
    return weight === monitor.getItem().weight
  },
}

type Props = {
  document: Article
  documentPriority: number | boolean
  index: number
  group: ReportGroup
  deleted: boolean
  connectDragSource: (el: React.ReactElement) => React.ReactElement
  connectDropTarget: (el: React.ReactElement) => React.ReactElement
  connectDragPreview: (el: React.ReactElement) => React.ReactElement
  isDragging: boolean
  highlighted: boolean
  newSinceLastSorted: boolean
  editArticleFn: () => void
  previewArticleFn: () => void
  markToDelete: (weight: number, index: number) => void
  filter: string
}

class Item extends React.PureComponent<Props> {
  markToDelete = (weight, index) => {
    const { markToDelete } = this.props
    return () => markToDelete(weight, index)
  }

  highlight = (text, query) => {
    const regex = new RegExp(`(${query})`, 'gi')
    return text.replace(regex, '<span class="sort__item--highlighted">$1</span>')
  }

  render() {
    const {
      deleted,
      group: { weight },
      index,
      connectDragSource,
      connectDropTarget,
      connectDragPreview,
      isDragging,
      filter,
      highlighted,
      newSinceLastSorted,
      editArticleFn,
      previewArticleFn,
      documentPriority,
      document: { header: { text } = {}, unix_timestamp: timestamp, first_source: { name: sourceName } = {} },
    } = this.props

    return connectDropTarget(
      connectDragPreview(
        <div
          className={classNames('sort__item', {
            'sort__item--dragging': isDragging,
            'sort__item--new': newSinceLastSorted,
            'sort__item--deleted': deleted,
          })}
        >
          {connectDragSource(
            <div className="sort__itemHandle">
              <i className="op-icon-drag" />
            </div>,
          )}
          <div className="sort__itemBody" onClick={previewArticleFn}>
            <div className="sort__itemDocument">
              <div
                className="sort__itemHeader"
                dangerouslySetInnerHTML={{ __html: highlighted ? this.highlight(text, filter) : text }}
              />

              <div
                className="sort__itemSource"
                dangerouslySetInnerHTML={{ __html: highlighted ? this.highlight(sourceName, filter) : sourceName }}
              />
            </div>
            <div className="sort__itemDate">{OpointDate.longFormat(timestamp * 1000)}</div>
          </div>
          <div className="sort__itemOptions">
            {documentPriority && <button className="op-button-square">{documentPriority}</button>}
            <OverlayTrigger
              placement="top"
              trigger={['hover', 'focus']}
              overlay={
                deleted ? (
                  <Tooltip id="tooltip">
                    <Translate i18nString="Restore the article" />
                  </Tooltip>
                ) : (
                  <Tooltip id="tooltip">
                    <Translate i18nString="Delete the article" />
                  </Tooltip>
                )
              }
            >
              <button className="op-button-square" onClick={this.markToDelete(weight, index)}>
                {deleted ? <i className="op-icon-update" /> : <i className="op-icon-bin" />}
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              trigger={['hover', 'focus']}
              overlay={
                <Tooltip id="tooltip">
                  <Translate i18nString="Edit the article" />
                </Tooltip>
              }
            >
              <button className="op-button-square" onClick={editArticleFn}>
                <i className="op-icon-pencil" />
              </button>
            </OverlayTrigger>
          </div>
        </div>,
      ),
    )
  }
}

// @ts-ignore
Item = DragSource(ITEM_TYPE.ITEM, itemSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
}))(Item)

// @ts-ignore
Item = DropTarget(ITEM_TYPE.ITEM, itemTarget, (connect) => ({
  connectDropTarget: connect.dropTarget(),
}))(Item)

export default Item

// @ts-nocheck
import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'

import ListingSubTree from './ListingSubTree'
import { isTagSelected } from '../../selectors/searchSelectors'
import type { AlertTag } from '../../opoint/flow'

type AlertTagItemProps = {
  tag: AlertTag
  searchContext: string
  isChecked: boolean
  expandTag: () => {}
  onClickHandler: () => {}
}

class AlertTagItem extends React.PureComponent<AlertTagItemProps> {
  handleClick = () => {
    const { tag, searchContext } = this.props

    if (tag.children) {
      tag.children.length > 1
        ? this.props.expandTag(tag.id)
        : this.props.onClickHandler(searchContext, tag.children[0].id)
    } else {
      this.props.onClickHandler(searchContext, tag.id)
    }
  }

  render() {
    const { tag, isChecked } = this.props
    const { id, children, subject, name, expanded } = tag

    return (
      <li key={id} title={subject || name} className="op-content-left-profiles-profile-list-item">
        <div className="op-content-left-profiles-profile-list-item_wrapper">
          <div className={classNames('op-checkbox', { 'mod-profile-has-children': children && children.length > 1 })}>
            <input type="checkbox" id={`profile-list-checkbox-${id}`} checked={isChecked} readOnly />
            <label htmlFor={`profile-list-checkbox-${id}`} onClick={this.handleClick} />
          </div>
          <span className="op-content-left-profiles-profile-name" onClick={this.handleClick} data-test="profiles">
            {subject || name}
          </span>
        </div>
        {children && children.length > 1 && expanded && <ListingSubTree {...this.props} entities={children} />}
      </li>
    )
  }
}

AlertTagItem = connect((state, props) => ({
  isChecked: isTagSelected(props.tag.id)(state),
}))(AlertTagItem)

export default AlertTagItem

// @ts-nocheck
import React from 'react'
import R from 'ramda'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'

import Checkbox from '../../common/Checkbox'
import Loader from '../../common/Loader'
import ShareArticleInputWrapper from './ShareArticleInputWrapper'
import TemplateSelector from '../../templates/TemplateSelector'
import Translate from '../../../Translate'
import buildActionCreators from '../../../helpers/buildActionCreators'
import { articleId } from '../../../opoint/articles/index'
import { getClickedArticle, isShareArticleModalOpen } from '../../../selectors/uiSelectors'
import { getShareArticlesContacts } from '../../../selectors/contactSelectors'
import { getGroupedTemplates, getTemplatePreview, isPreviewModalOpen } from '../../../selectors/templatesSelectors'
import {
  getSelectedArticles,
  getShareArticleAttachmentFlag,
  getShareArticleMessage,
  getShareArticleXHRStatus,
} from '../../../selectors/articlesSelectors'
import * as ActionTypes from '../../../constants/actionTypes'
import OpointTextWithMatchesAndEntities from '../../../new-components/articles/OpointTextWithMatchesAndEntities'

const TemplateSelectorTranslate = ({
  activeTemplate,
  closePreviewModal,
  groupedTemplates,
  modalTitle,
  onTemplateChange,
  openPreviewModal,
  isPreviewModalOpen,
  modalContent /* eslint-disable-line no-shadow */,
  showPreviewButtonTitle,
}) => (
  <TemplateSelector
    activeTemplate={activeTemplate}
    closePreviewModal={closePreviewModal}
    groupedTemplates={groupedTemplates}
    modalTitle={modalTitle}
    onTemplateChange={onTemplateChange}
    openPreviewModal={openPreviewModal}
    isPreviewModalOpen={isPreviewModalOpen}
    previewModalContent={modalContent}
    showPreviewButtonTitle={showPreviewButtonTitle}
  />
)

class ShareArticleModal extends React.PureComponent<any> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  constructor(props) {
    super(props)

    this.state = {
      customizeTemplate: false,
      sendIdentical: false,
    }
  }

  state = {
    activeTemplate: undefined,
    customizeTemplate: false,
  }

  componentWillReceiveProps(nextProps) {
    const { groupedTemplates, isOpen } = nextProps
    const { activeTemplate } = this.state

    if (groupedTemplates && Object.values(groupedTemplates)[0] && activeTemplate === undefined) {
      const { id, type } = Object.values(groupedTemplates)[0]
      this.templateChange({ id, type })
    }
    if (!isOpen) {
      this.setState({
        customizeTemplate: false,
        activeTemplate: undefined,
      })
    }
  }

  onCustomizeClick = () => {
    this.setState({
      customizeTemplate: !this.state.customizeTemplate,
    })
  }

  onIdenticalClick = () => {
    this.setState({
      sendIdentical: !this.state.sendIdentical,
    })
  }

  templateChange = ({ id, type }) => {
    this.setState({
      activeTemplate: { id, type },
    })
  }

  shareAttachment = (e) => {
    e.preventDefault()

    const {
      selectedArticles,
      selectedContacts,
      shareArticles,
      shareArticleMessage,
      shareArticleAttachmentFlag,
      clickedArticle,
    } = this.props
    const { activeTemplate, sendIdentical } = this.state
    const articles = clickedArticle ? [clickedArticle] : selectedArticles
    const almostIdentical = sendIdentical
      ? R.unnest(
          articles.map((article) => {
            if (article.almost_identical) {
              return article.almost_identical
            }
            return [article]
          }),
        )
      : []
    const allArticles = articles.concat(almostIdentical)
    const uniqueArticles = R.uniqBy((article) => articleId(article), allArticles)
    shareArticles({
      articles: uniqueArticles.map(({ id_site, id_article }) => ({ id_site, id_article })),
      message: shareArticleMessage,
      shareAttachment: shareArticleAttachmentFlag,
      recipients: selectedContacts.map(({ type, entity: { id } }) => ({ id, type })),
      templateId: activeTemplate.id,
      title: shareArticleMessage,
    })
  }

  handleMessageChange = (e) => {
    const { changeMessage } = this.props
    changeMessage({ message: e.target.value })
  }

  render() {
    const {
      isOpen,
      closeModal,
      selectedArticles,
      sharingInProgress,
      shareArticleMessage,
      shareArticleAttachmentFlag,
      toggleAttachment,
      clickedArticle,
      isPreviewModalOpen /* eslint-disable-line no-shadow */,
      groupedTemplates,
      openPreviewModal,
      closePreviewModal,
      templatePreview,
    } = this.props
    const { i18n } = this.context

    const { activeTemplate } = this.state
    let shareArticlePlural
    if (clickedArticle) {
      shareArticlePlural = <Translate i18nString="Share article" />
    } else if (selectedArticles.length > 1) {
      shareArticlePlural = <Translate i18nString="Share articles" />
    } else {
      shareArticlePlural = <Translate i18nString="Share article" />
    }

    return (
      <Modal
        keyboard={false}
        show={isOpen}
        onHide={closeModal}
        className="op-modal-notification mod-articles modal top"
      >
        <form onSubmit={this.shareAttachment}>
          <Modal.Header>
            <button type="button" className="close" onClick={closeModal}>
              ×
            </button>
            <h4 className="modal-title">{shareArticlePlural}</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-body-actions">
              {sharingInProgress === 'IN_PROGRESS' || sharingInProgress === 'SUCCESS' ? (
                <div className="modal-body-actions-status">
                  <div className="util_center-parent mod-full-all">
                    <div className="util_center-child">
                      {sharingInProgress === 'IN_PROGRESS' && <Loader />}
                      {sharingInProgress === 'SUCCESS' && (
                        <h3>
                          <Translate i18nString="Your report was successfully shared" />
                        </h3>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <ShareArticleInputWrapper
                    shareMessage={shareArticleMessage}
                    handleMessageChange={this.handleMessageChange}
                    toggleAttachment={toggleAttachment}
                    formName="shareArticlesContacts"
                    stepNumber={2}
                    simpleForm
                    isAttachmentChecked={shareArticleAttachmentFlag}
                  />
                  <ul className="modal-body-actions-list-of-articles">
                    {clickedArticle ? (
                      <li key={articleId(clickedArticle)}>
                        <OpointTextWithMatchesAndEntities text={clickedArticle.header.text} />
                      </li>
                    ) : (
                      selectedArticles &&
                      selectedArticles.map((article) => (
                        <li key={articleId(article)}>
                          <OpointTextWithMatchesAndEntities text={article.header.text} />
                        </li>
                      ))
                    )}
                  </ul>
                  <div className="clearfix" />
                  <div
                    className="modal-body-actions-action"
                    style={{ padding: 15, paddingTop: 0, position: 'relative' }}
                  >
                    <div className="customize-template">
                      <Checkbox
                        id="sendIdentical"
                        name="sendIdentical"
                        isChecked={this.state.sendIdentical}
                        onChangeHandler={this.onIdenticalClick}
                      >
                        <Translate i18nString="Send all identical articles" />
                      </Checkbox>
                      <Checkbox
                        id="customizeTemplate"
                        name="customizeTemplate"
                        isChecked={this.state.customizeTemplate}
                        onChangeHandler={this.onCustomizeClick}
                      >
                        <Translate i18nString="Customize sharing template" />
                      </Checkbox>
                    </div>
                  </div>
                  <div style={{ padding: 15, paddingTop: 0 }}>
                    {this.state.customizeTemplate && (
                      <TemplateSelectorTranslate
                        activeTemplate={activeTemplate}
                        closePreviewModal={closePreviewModal}
                        groupedTemplates={groupedTemplates}
                        modalTitle={i18n.t('Template preview')}
                        onTemplateChange={this.templateChange}
                        openPreviewModal={openPreviewModal}
                        isPreviewModalOpen={isPreviewModalOpen}
                        modalContent={templatePreview}
                        showPreviewButtonTitle={i18n.t('Preview')}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            {sharingInProgress === 'SUCCESS' ? (
              <button
                onClick={(e) => {
                  e.preventDefault()
                  closeModal()
                }}
                className="op-button mod-login"
              >
                <Translate i18nString="Close" />
              </button>
            ) : (
              <button type="submit" className="op-button mod-login" tabIndex="4">
                <Translate i18nString="Send" />
              </button>
            )}
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

ShareArticleModal = translate([], { wait: true })(ShareArticleModal)

ShareArticleModal = connect(
  (state) => ({
    selectedContacts: getShareArticlesContacts(state),
    sharingInProgress: getShareArticleXHRStatus(state),
    shareArticleMessage: getShareArticleMessage(state),
    shareArticleAttachmentFlag: getShareArticleAttachmentFlag(state),
    templatePreview: getTemplatePreview(state),
    isPreviewModalOpen: isPreviewModalOpen(state),
    groupedTemplates: getGroupedTemplates(state),
    isOpen: isShareArticleModalOpen(state),
    selectedArticles: getSelectedArticles(state),
    clickedArticle: getClickedArticle(state),
  }),
  buildActionCreators({
    openPreviewModal: ActionTypes.TEMPLATE_PREVIEW_MODAL_OPEN,
    closePreviewModal: ActionTypes.TEMPLATE_PREVIEW_MODAL_CLOSE,
    closeModal: ActionTypes.SHARE_ARTICLE_MODAL_CLOSE,
    shareArticles: ActionTypes.SHARE_ARTICLES,
    toggleAttachment: ActionTypes.SHARE_ARTICLES_TOGGLE_ATTACHMENT_TOGGLE,
    changeMessage: ActionTypes.SHARE_ARTICLES_CHANGE_MESSAGE,
  }),
)(ShareArticleModal)

export default ShareArticleModal

// @ts-nocheck

import R from 'ramda'

import { preprocessArticle, eqArticles, articleId, articleIdenticalIds } from '../opoint/articles/index'
import { REPORT_STATUS, REPORT_STEP_DOWNLOAD_SHARE } from '../opoint/reports/index'
import * as Actions from '../constants/actionTypes'
import type { Article, Template, Action } from '../opoint/flow'

type ReportsState = {
  template?: Template
  source?: {
    type: 'profile' | 'tag' | 'current_search' | 'selected_articles'
    id?: number
  }
  dateTimeStart?: Date
  dateTimeEnd?: Date
  useReportHistory: boolean
  contentFilter?: string
  filterMode: 'filter' | 'highlight'
  compactListing: boolean
  showDeleted: boolean
  search: {
    articles?: {
      [key: string]: Array<Article>
    }
    groups?: Array<Object>
    context?: string
    loading: boolean
    savingSort: boolean
  }
  reportObject?: Object // report object from API todo
  title: string
  preface: string
  footer: string
  step: number
  shareReportData: {
    shareReportMessage: string
    stepNumber?: number
    attachment: boolean
  }
  reportsHistory?: Array<{ [key: string]: { id: number; timestamp: Date; stimestampUsed: number } }>
  autoTranslate: boolean
  backup: {
    // there will be preserved metadata of unfished report
    order?: { [weight: string]: Array<Array<string>> } // nested Array is for ids of identicals
    toDelete?: { [weight: string]: Array<Array<string>> } // nested Array is for ids of identicals
  }
}

export const initialState: ReportsState = {
  template: null,
  source: null,
  dateTimeStart: null,
  dateTimeEnd: null,
  useReportHistory: false,
  search: {
    articles: null,
    groups: null,
    context: null,
    loading: false,
    savingSort: false,
  },
  title: '',
  preface: '',
  footer: '',
  step: 0,
  reportObject: null,
  contentFilter: null,
  filterMode: 'filter',
  compactListing: false,
  showDeleted: false,
  reportsHistory: null,
  shareReportData: {
    shareReportMessage: '',
    stepNumber: null,
    attachment: false,
  },
  autoTranslate: false,
  backup: {
    order: null,
    toDelete: null,
  },
}

const reportsReducer = (state: ReportsState = initialState, { type, payload }: Action<any>) => {
  switch (type) {
    case Actions.REPORTS_RESET: {
      if (!payload.soft || !state.search.articles) {
        return initialState
      }
      // backup metadata if modal is closed before generating report (soft reset)
      // to persist ordering and `deleted` marking

      // return 3 dimensional array: group > article > identicalArticle
      const toIdsLists = R.map(R.map(({ document }) => articleIdenticalIds(document)))

      return R.evolve({
        showDeleted: R.always(state.showDeleted),
        backup: {
          order: R.always(toIdsLists(state.search.articles)),
          toDelete: R.always(toIdsLists(R.map(R.filter(R.prop('deleted')))(state.search.articles))),
        },
      })(initialState)
    }
    case Actions.REPORTS_TEMPLATE: {
      const { templateId } = payload
      return R.assoc('template', templateId, state)
    }
    case Actions.REPORTS_SOURCE: {
      const source = R.omit(['i18n'], payload) // i18n is needed in some epic, but unwanted here
      return R.evolve({
        source: R.always(source),
        search: {
          articles: R.always(null),
        },
      })(state)
    }
    case Actions.REPORTS_DATE_START:
      return R.evolve({
        search: {
          articles: R.always(null),
        },
        dateTimeStart: R.always(payload),
        useReportHistory: R.always(false),
      })(state)
    case Actions.REPORTS_DATE_END:
      return R.evolve({
        search: {
          articles: R.always(null),
        },
        dateTimeEnd: R.always(payload),
        useReportHistory: R.always(false),
      })(state)
    case Actions.REPORTS_REPORT_USE_HISTORY: // todo fix test
      return R.evolve({
        search: {
          articles: R.always(null),
        },
        useReportHistory: R.always(payload), // todo fix naming
      })(state)
    case Actions.REPORTS_CHANGE_AUTO_TRANSLATE:
      return R.evolve({
        autoTranslate: R.always(payload),
        search: {
          articles: R.always(null),
        },
      })(state)
    case Actions.REPORTS_ARTICLES:
      return R.assocPath(['search', 'loading'], true, state)

    case Actions.REPORTS_LOAD_INITIAL_ARTICLES:
      return R.assocPath(['search', 'articles'], null, state)

    case Actions.REPORTS_ARTICLES_SUCCESS: {
      // todo test
      const {
        searchresult: {
          context = null,
          document = [],
          sort_groups = [{name: 'Global', group: -1, weight: undefined}], // eslint-disable-line
        },
        alreadyPreprocessed = false,
      } = payload

      const groups = R.compose(
        R.indexBy(R.prop('weight')),
        R.filter(({ hidden }) => !hidden),
      )(sort_groups)

      // if article has group which is not supported by backend, set the group to default one
      const groupId = ({ groupId: id }) => (groups[id] ? id : undefined)

      const preprocesedDocuments = alreadyPreprocessed
        ? document
        : R.map((a) => R.over(R.lensPath(['document']), preprocessArticle, a), document)

      let articles = R.groupBy(groupId, preprocesedDocuments)

      if (state.backup) {
        // apply saved `delete` marks
        const isMarked = (weight, id) => {
          const marksOfGroup = R.path(['toDelete', weight])(state.backup) || []
          return R.any(R.contains(id))(marksOfGroup)
        }
        articles = R.mapObjIndexed((list, weight) =>
          R.map((item) => ({
            ...item,
            ...(isMarked(weight, articleId(item.document)) ? { deleted: true } : {}),
          }))(list),
        )(articles)

        // apply saved order
        const articlePosition = (weight, id) => {
          const orderOfGroup = R.path(['order', weight])(state.backup) || []
          return R.findIndex(R.contains(id))(orderOfGroup)
        }
        articles = R.mapObjIndexed((list, weight) =>
          R.sortBy((item) => articlePosition(weight, articleId(item.document)))(list),
        )(articles)
      }

      return R.evolve({
        search: {
          loading: R.always(false),
          articles: R.ifElse(
            R.isNil,
            R.always(articles),
            /* eslint-disable-next-line no-underscore-dangle */
            R.mergeWith(R.concat, R.__, articles),
          ),
          context: R.always(context || null),
          groups: R.ifElse(R.isNil, R.always(groups), R.merge(groups)),
        },
      })(state)
    }
    case Actions.REPORTS_ARTICLE_SORT: {
      const { weight, draggedId, hoveredId } = payload
      return R.over(R.lensPath(['search', 'articles', weight]), (a) => {
        const t = a[draggedId]

        a[draggedId] = a[hoveredId]
        a[hoveredId] = t
        return a
      })(state)
    }
    case Actions.REPORTS_ARTICLE_MARK_TO_DELETE: {
      const { article } = payload
      return R.evolve({
        search: {
          articles: R.map(
            // map over groups
            R.map(R.when(R.compose(eqArticles(article), R.path(['document'])), R.over(R.lensPath(['deleted']), R.not))),
          ),
        },
      })(state)
    }
    case Actions.REPORTS_ARTICLES_FAILURE:
      return R.evolve({
        search: {
          articles: R.always([]),
          loading: R.always(false),
        },
      })(state)
    case Actions.REPORTS_FILTER_MODE:
      if (typeof payload !== 'string' || !['filter', 'highlight'].includes(payload)) {
        throw new Error('Wrong payload')
      }

      return R.assoc('filterMode', payload, state)
    case Actions.REPORTS_COMPACT_LISTING_TOGGLE:
      return R.evolve({
        compactListing: R.not,
      })(state)
    case Actions.REPORTS_SHOW_DELETED_TOGGLE:
      return R.evolve({
        showDeleted: R.not,
      })(state)
    case Actions.REPORTS_HISTORY_FETCH:
      return R.assoc('reportsHistory', null, state)
    case Actions.REPORTS_HISTORY_FETCH_SUCCESS: {
      const historyList = payload // refactor to {historyList}

      const preselectedHistory = R.compose(
        R.when(R.none(R.propEq('selected', true)), R.adjust(R.assoc('selected', true), 0)),
        R.map(R.when(R.propEq('timestamp', 0), R.assoc('selected', true))),
      )(historyList)

      const useReportHistory = R.any(R.prop('selected'), preselectedHistory)

      const historyIndexedByStimestamps = R.indexBy(R.prop('stimestampUsed'), preselectedHistory)

      return R.evolve({
        reportsHistory: R.always(historyIndexedByStimestamps),
        useReportHistory: R.always(useReportHistory),
      })(state)
    }
    case Actions.REPORTS_HISTORY_CHECK_TOGGLE: {
      const id = payload // todo refactor this into {id}
      return R.evolve({
        search: {
          articles: R.always(null),
        },
        useReportHistory: R.always(true),
        reportsHistory: R.over(R.lensPath([id, 'selected']), R.not),
      })(state)
    }
    case Actions.REPORTS_SORT_TAG: {
      // to persist ordering and `deleted` marking
      // return 3 dimensional array: group > article > identicalArticle
      const toIdsLists = R.map(R.map(({ document }) => articleIdenticalIds(document)))
      return R.evolve({
        search: {
          articles: R.always(null),
        },
        backup: {
          order: R.always(null), // order will be determined by fetched data -> discard prev. saved
          toDelete: R.always(
            // ... but deleted marks should be preserved
            toIdsLists(R.map(R.filter(R.prop('deleted')))(state.search.articles)),
          ),
        },
      })(state)
    }
    // backup metadata if modal is closed before generating report (soft reset)
    case Actions.REPORTS_STEP_SUCCESS: {
      const { step } = payload
      return R.evolve({
        step: R.always(step),
      })(state)
    }
    case Actions.REPORTS_CUSTOM_SORT:
      return R.assocPath(['search', 'savingSort'], true, state)
    case Actions.REPORTS_CUSTOM_SORT_SUCCESS:
      return R.assocPath(['search', 'savingSort'], false, state)
    case Actions.REPORTS_TITLE: {
      const { title } = payload
      return R.assoc('title', title, state)
    }
    case Actions.REPORTS_PREFACE: {
      const { preface } = payload
      return R.assoc('preface', preface, state)
    }
    case Actions.REPORTS_FOOTER: {
      const { footer } = payload
      return R.assoc('footer', footer, state)
    }
    case Actions.REPORTS_CREATE:
      return R.assocPath(['reportObject', 'status'], REPORT_STATUS.IN_PROGRESS, state)
    case Actions.REPORTS_CREATE_SUCCESS: {
      const { reportObject } = payload
      return R.evolve({
        reportObject: R.always(reportObject),
        step: R.always(REPORT_STEP_DOWNLOAD_SHARE),
        backup: R.always(initialState.backup),
      })(state)
    }
    case Actions.REPORTS_CREATE_FAILURE:
      return R.evolve({
        reportObject: R.assoc('status', REPORT_STATUS.ERROR),
      })(state)
    case Actions.REPORTS_CREATE_IN_PROGRESS: {
      const { reportObject } = payload
      return R.evolve({
        search: {
          articles: R.compose(R.groupBy(R.prop('groupId')), R.reject(R.prop('deleted')), R.unnest, R.values),
        },
        reportObject: R.always(reportObject),
      })(state)
    }
    case Actions.EDIT_ARTICLE_SUCCESS: {
      // todo test
      const { article } = payload
      return R.evolve({
        search: {
          articles: R.when(
            R.complement(R.isNil), // when we have the articles
            R.map(
              // map over groups
              R.map(
                // map over reportsArticle object
                R.over(
                  R.lensPath(['document']), // original searchD intercepted document
                  R.when(eqArticles(article), R.always(article)),
                ),
              ),
            ),
          ),
        },
      })(state)
    }
    case Actions.SHARE_REPORT_CHANGE_MESSAGE: {
      const { message } = payload
      return R.assocPath(['shareReportData', 'shareReportMessage'], message, state)
    }
    case Actions.SHARE_REPORT_UPDATE_STEP: {
      const { stepNumber } = payload
      return R.assocPath(['shareReportData', 'stepNumber'], stepNumber, state)
    }
    case Actions.SHARE_REPORT_TOGGLE_ATTACHMENT: {
      return R.assocPath(['shareReportData', 'attachment'], !state.shareReportData.attachment, state)
    }

    case Actions.CLEAR_SHARE_REPORT_DATA: {
      return R.evolve(
        {
          shareReportData: {
            attachment: R.always(false),
            shareReportMessage: R.always(''),
          },
        },
        state,
      )
    }

    default:
      return state
  }
}

export default reportsReducer

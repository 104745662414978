import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'

import buildActionCreators from '../../helpers/buildActionCreators'
import OpointDate from '../../opoint/common/time'
import OpointDatepicker from './OpointDatepicker'
import Translate from '../../Translate'
import { getOpointLocale, getLastReportDate, getArticleAgeLimit } from '../../selectors/settingsSelectors'
import { getSearchDates, getSearchDatepicker } from '../../selectors/searchSelectors'
import { isDatepickerModalOpen } from '../../selectors/uiSelectors'
import * as ActionTypes from '../../constants/actionTypes'

class SearchDateRangeModal extends React.PureComponent<any> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  onSelectDate = () => {
    const { searchDatePicker, saveNewDateRange } = this.props
    const { startDate, endDate } = searchDatePicker
    saveNewDateRange({ startDate, endDate })
  }

  render() {
    const {
      datepickerModalClose,
      isOpen,
      lastReportDate,
      locale,
      onEndDateChange,
      onStartDateChange,
      searchDates,
      articleAgeLimit: { to: toSecondsOffset, from: fromSecondsOffset },
    } = this.props

    const { startDate, endDate } = searchDates
    const { i18n } = this.context

    const isDisabled = moment(endDate).isBefore(startDate)
    // @ts-ignore
    const startDateHumanFormat = OpointDate.humanFormat(moment(startDate))
    const endDateHumanFormat = OpointDate.humanFormat(moment(endDate), i18n)

    return (
      <Modal show={isOpen} onHide={datepickerModalClose} className="op-modal-datepicker">
        <Modal.Header>
          {}
          <button type="button" className="close" onClick={datepickerModalClose}>
            &times;
          </button>

          <h4 className="modal-title">
            <Translate i18nString="Select date range" />
          </h4>
        </Modal.Header>
        <Modal.Body className="op-modal-source-lists">
          <div className="op-modal-datepicker__header">
            <div className="op-modal-datepicker__heading">
              <h4>
                <Translate i18nString="Start date" />
              </h4>
              <span>{startDateHumanFormat}</span>
            </div>

            <div className="op-modal-datepicker__heading">
              <h4>
                <Translate i18nString="End date" />
              </h4>
              <span>{endDateHumanFormat}</span>
            </div>
          </div>
          <OpointDatepicker
            startDate={moment(startDate)}
            endDate={moment(endDate)}
            onStartDateChange={onStartDateChange}
            onEndDateChange={onEndDateChange}
            locale={locale}
            maxDate={toSecondsOffset && moment().subtract(toSecondsOffset, 'second')}
            minDate={fromSecondsOffset && moment().subtract(fromSecondsOffset, 'second')}
            lastReportDate={lastReportDate}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="col-md-8 util_col-vertical-center text-left">
            <Translate i18nString="From" />
            <span> {startDateHumanFormat} </span>
            <Translate i18nString="to" />
            <span> {endDateHumanFormat}</span>
          </div>
          <div className="col-md-4 util_col-vertical-center">
            <button type="button" className="op-button green" disabled={isDisabled} onClick={this.onSelectDate}>
              <Translate i18nString="Select date" />
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    )
  }
}

// @ts-ignore
SearchDateRangeModal = connect(
  (state) => ({
    isOpen: isDatepickerModalOpen(state),
    locale: getOpointLocale(state),
    searchDates: getSearchDates(state),
    lastReportDate: getLastReportDate(state),
    searchDatePicker: getSearchDatepicker(state),
    articleAgeLimit: getArticleAgeLimit(state),
  }),
  buildActionCreators({
    datepickerModalClose: ActionTypes.DATEPICKER_MODAL_CLOSE,
    onStartDateChange: ActionTypes.DATEPICKER_MODAL_SET_START_DATE,
    onEndDateChange: ActionTypes.DATEPICKER_MODAL_SET_END_DATE,
    saveNewDateRange: ActionTypes.SEARCH_CHANGE_DATERANGE,
  }),
)(SearchDateRangeModal)

export default SearchDateRangeModal

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { translate } from 'react-i18next'

import buildActionCreators from '../../helpers/buildActionCreators'
import CommonFilterDetail from './CommonFilterDetail'
import SiteFilterDetail from './SiteFilterDetail'
import TBListFilterDetail from './TBListFilterDetail'
import { getFilterDetailModalOpen } from '../../selectors/uiSelectors'
import {
  getClickedFilterName,
  getClickedFilterType,
  getFilterMetadata,
  getFilterMetadataFetchStatus,
  isFilterMetadataFetchInProgress,
} from '../../selectors/profilesSelectors'
import * as ActionTypes from '../../constants/actionTypes'

class FilterDetailModal extends React.PureComponent<any> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  render() {
    const {
      isOpen,
      clickedFilterName,
      clickedFilterType,
      filterDetailModalClose,
      filterMetadata,
      filterMetadataFetchInProgress,
      filterMetadataFetchStatus,
    } = this.props

    const { i18n } = this.context
    let header
    let content
    if (filterMetadataFetchInProgress) {
      header = `${i18n.t('Loading')}...`
    } else {
      switch (filterMetadataFetchStatus) {
        case 404:
          header = i18n.t('Not found')
          content = i18n.t("Filter's metadata's not found")
          break
        case 200:
          header = filterMetadata.name || clickedFilterName
          content = (() => {
            switch (clickedFilterType) {
              case 'list':
              case 'tblist':
                return <TBListFilterDetail tbList={filterMetadata} />
              case 'site':
                return <SiteFilterDetail details={filterMetadata} />
              default:
                return <CommonFilterDetail details={filterMetadata} />
            }
          })()
          break
        default:
          header = i18n.t('Permission error')
          content = i18n.t('You have no permissions to access this metadata')
      }
    }
    return (
      <Modal show={isOpen} onHide={filterDetailModalClose}>
        <Modal.Header>
          <button type="button" className="close" onClick={filterDetailModalClose}>
            ×
          </button>
          <h4 className="modal-title">{header}</h4>
        </Modal.Header>
        <Modal.Body>
          <div>{content}</div>
        </Modal.Body>
      </Modal>
    )
  }
}

// @ts-ignore
FilterDetailModal = translate([], { wait: true })(FilterDetailModal)

// @ts-ignore
FilterDetailModal = connect(
  (state) => ({
    isOpen: getFilterDetailModalOpen(state),
    clickedFilterName: getClickedFilterName(state),
    clickedFilterType: getClickedFilterType(state),
    filterMetadata: getFilterMetadata(state),
    filterMetadataFetchStatus: getFilterMetadataFetchStatus(state),
    filterMetadataFetchInProgress: isFilterMetadataFetchInProgress(state),
  }),
  buildActionCreators({
    filterDetailModalClose: ActionTypes.FILTER_METAS_HIDE_DETAIL,
  }),
)(FilterDetailModal)

export default FilterDetailModal

import React from 'react'
import PropTypes from 'prop-types'
import R from 'ramda'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'

import buildActionCreators from '../../../helpers/buildActionCreators'
import MultiSelect from './MultiSelect'
import { DAY_CONST } from '../../../opoint/alerts/weekdays'
import { getSelectedDays } from '../../../selectors/alertsSelectors'
import * as ActionTypes from '../../../constants/actionTypes'
import AdvancedForm from '../AlertForm/AdvancedForm'

const multiselectRuleDelete = ({ onClick, i18n }) => (
  <div className="alert-schedule__action">
    <div
      className="alert-schedule__button alert-schedule__button--delete"
      title={i18n.t('Delete whole rule')}
      onClick={onClick}
    >
      <i className="op-icon-delete" />
    </div>
  </div>
)
const MultiselectRuleDelete = translate([], { wait: true })(multiselectRuleDelete)

class MultiSelectRule extends React.Component<any> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  onClickHandler = () => {
    const { deleteRule, id } = this.props

    deleteRule(id)
  }

  onChangeHandler = (data) => {
    this.updateStoreData(data)
  }

  getReduxData() {
    const { getSelectedDays /* eslint-disable-line no-shadow */, id } = this.props
    const reduxData = R.prop(id, getSelectedDays)
    return reduxData || []
  }

  updateStoreData(data) {
    const { id, updateInterval } = this.props
    let timeConfiguration = {}
    timeConfiguration = {
      day: id,
      intervalData: data,
    }
    updateInterval(timeConfiguration)
  }

  render() {
    const { id } = this.props
    const reduxData = this.getReduxData()
    const { i18n } = this.context
    return (
      <div className="alert-schedule__rule">
        <MultiselectRuleDelete onClick={this.onClickHandler} />
        <div className="alert-schedule__content">
          {i18n.t(DAY_CONST[id].displayName)}
          <MultiSelect onChange={this.onChangeHandler} data={reduxData} />
        </div>
      </div>
    )
  }
}

// @ts-ignore
MultiSelectRule = connect(
  (state) => ({
    getSelectedDays: getSelectedDays(state),
  }),
  buildActionCreators({
    deleteScheduleEntry: ActionTypes.DELETE_SCHEDULE_ENTRY,
    updateInterval: ActionTypes.UPDATE_SCHEDULE_INTERVAL,
  }),
)(MultiSelectRule)

export default MultiSelectRule

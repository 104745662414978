// @ts-nocheck
import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'

import CheckboxIndeterminate from '../../../common/CheckboxIndeterminate'
import buildActionCreators from '../../../../helpers/buildActionCreators'
import ButtonSquare from '../../../common/ButtonSquare'
import { getTagById, getTags } from '../../../../selectors/tagsSelectors'
import {
  getSelectedArticleTagWeight,
  getSelectedArticles,
  getSelectedArticlesTagCount,
  selectedArticlesHaveSameTagWeight,
} from '../../../../selectors/articlesSelectors'
import * as ActionTypes from '../../../../constants/actionTypes'
import type { Tag } from '../../../../opoint/flow'

type Props = {
  getTagById: (string) => Tag
  incrementTag: Function
  selectedArticles: Array<Object>
  tagArticles: Function
  tags: Array<Tag>
  untagArticles: Function
}

class TagArticlesPopover extends React.Component<Props> {
  constructor(props) {
    super(props)
    this.checkboxClickHandler = this.checkboxClickHandler.bind(this)
    this.buttonClickHandler = this.buttonClickHandler.bind(this)
  }

  componentDidMount() {
    document.addEventListener('click', this.props.clickOutside, false)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.props.clickOutside, false)
  }

  checkboxClickHandler = (tagId: string, weight = 0) => () => {
    const {
      getSelectedArticlesTagCount /* eslint-disable-line no-shadow */,
      getTagById /* eslint-disable-line no-shadow */,
      selectedArticles,
      tagArticles,
      untagArticles,
    } = this.props

    const tag = getTagById(tagId)
    const tagsCount = getSelectedArticlesTagCount(tagId)

    if (tagsCount >= 0 && tagsCount < selectedArticles.length) {
      tagArticles({
        tag,
        articles: selectedArticles,
        weight,
      })
    } else {
      untagArticles({
        tag,
        articles: selectedArticles,
      })
    }
  }

  checkboxValue = (tagId: string): boolean => {
    // @ts-ignore
    const { selectedArticles, getSelectedArticlesTagCount } = this.props
    const tagsCount = getSelectedArticlesTagCount(tagId)

    if (tagsCount === 0) {
      return false
    }
    if (tagsCount === selectedArticles.length) {
      return true
    }
    return null
  }

  buttonClickHandler = (tagId: string) => () => {
    const { getTagById, incrementTag, selectedArticles } = this.props

    incrementTag({
      articles: selectedArticles,
      tag: getTagById(tagId),
    })
  }

  buttonValue = (tagId: string): number | string => {
    const {
      getSelectedArticlesTagCount /* eslint-disable-line no-shadow */,
      getSelectedArticleTagWeight /* eslint-disable-line no-shadow */,
      selectedArticlesHaveSameTagWeight /* eslint-disable-line no-shadow */,
    } = this.props

    const tagsCount = getSelectedArticlesTagCount(tagId)

    if (tagsCount === 0) {
      return 0
    }
    if (tagsCount === 1 || selectedArticlesHaveSameTagWeight(tagId)) {
      return getSelectedArticleTagWeight(tagId)
    }
    return '-'
  }

  render() {
    const { tags } = this.props

    return (
      <div
        style={{ backgroundColor: 'white' }}
        className={classNames('op-popover', 'op-tag-as', { 'first-tag': !tags.length })}
      >
        <div className="arrow" />
        <div className="popover-content">
          <div className="wrapper">
            {tags.length && (
              <div className="tag-as-list">
                {tags.map((tag) => (
                  <div key={tag.id} className="tag-as-list-item">
                    <div className="pull-left">
                      {/* TODO: Style marginLeft -17px has to go away @honza @jakub */}
                      <CheckboxIndeterminate
                        checked={false}
                        id={tag.id}
                        isChecked={this.checkboxValue(tag.id)}
                        name={tag.id}
                        onChange={this.checkboxClickHandler(tag.id)}
                        style={{ marginLeft: '-17px' }}
                        type="checkbox"
                        value={tag.id}
                      />
                      <span className="tag-as-list-item-label">{tag.name}</span>
                    </div>
                    <div className="pull-right">
                      <ButtonSquare content={this.buttonValue(tag.id)} onClick={this.buttonClickHandler(tag.id)} />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    getSelectedArticlesTagCount: (tagId) => getSelectedArticlesTagCount(tagId)(state),
    getSelectedArticleTagWeight: (tagId) => getSelectedArticleTagWeight(tagId)(state),
    getTagById: (tagId) => getTagById(tagId)(state),
    selectedArticles: getSelectedArticles(state),
    selectedArticlesHaveSameTagWeight: (tagId) => selectedArticlesHaveSameTagWeight(tagId)(state),
    tags: getTags(state),
  }),
  buildActionCreators({
    incrementTag: ActionTypes.INCREMENT_TAG_WEIGHT_FOR_ARTICLE,
    tagArticles: ActionTypes.TAG_ARTICLES,
    untagArticles: ActionTypes.UNTAG_ARTICLES,
  }),
)(TagArticlesPopover)

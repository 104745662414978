import React from 'react'

type ReportsErrorProps = {
  error: string
  active: boolean
}

const ReportsError = ({ error, active }: ReportsErrorProps) => {
  if (!active) {
    return <div />
  }

  return (
    <div className="op-modal-report-body-content-error">
      <span>{error}</span>
    </div>
  )
}

export default ReportsError

import React from 'react'
import PropTypes from 'prop-types'

import ArticleImages from './ArticleImages'
import Screenshot from './Screenshot'

const DisplayMedia = (props) => (props.article.articleimages ? <ArticleImages {...props} /> : <Screenshot {...props} />)
DisplayMedia.propTypes = {
  article: PropTypes.object.isRequired,
  editable: PropTypes.bool,
}
DisplayMedia.defaultProps = {
  editable: false,
}

export default DisplayMedia

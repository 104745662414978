// @ts-nocheck
import PropTypes from 'prop-types'
import React from 'react'
import { translate } from 'react-i18next'

import FilterReset from './FilterReset'
import Graph from './Graph'
import { colorScale } from '../../opoint/common/colors'

const CHART_HEIGHT = 250
const NARROW_CHART_WIDTH = 275
const WIDE_CHART_WIDTH = 570

class SwitchableGraph extends React.Component<any, any> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  menu: any

  constructor(props, context) {
    super(props)

    const { i18n } = context
    this.context = context

    this.menu = [
      [
        [i18n.t('Pie'), this.switchTo('pieChart', 'pie')],
        [i18n.t('Row'), this.switchTo('rowChart', 'row')],
        [i18n.t('Pie (legends)'), this.switchTo('pieChart', 'widepie', undefined, true)],
        [i18n.t('Freq. table'), this.switchTo('customDataTable', 'freqtable')],
      ],
      [
        [i18n.t('Series curves'), this.switchTo('lineChart', 'seriesline', 'overlapped', true)],
        [i18n.t('Series area'), this.switchTo('lineChart', 'seriesarea', 'stacked', true)],
        [i18n.t('Series bars'), this.switchTo('barChart', 'seriesbar', 'stacked', true)],
        [i18n.t('Time table'), this.switchTo('customDataTable', 'timetable', 'overlapped', true)],
      ],
      [
        [i18n.t('Export SVG'), (graph) => graph.saveFile('svg'), 'chart'],
        [i18n.t('Export PNG'), (graph) => graph.saveFile('png'), 'chart'],
        [i18n.t('Export CSV'), (graph) => graph.saveFile('csv'), 'table'],
      ],
      [
        [i18n.t('Count n. of articles'), this.setCountByTo('count')],
        [i18n.t('Count circulation'), this.setCountByTo('circulation')],
        [i18n.t('Count reach'), this.setCountByTo('reach')],
        [i18n.t('Count adprice'), this.setCountByTo('adprice')],
      ],
    ]

    this.chartType = this.chooseBestChartType
  }

  componentDidMount() {
    if (this.props.aspectType) {
      const {
        aspectType: { type, apiType, series, showLegend },
      } = this.props
      this.switchToFunction(type, apiType, series, showLegend)
    }

    if (this.props.aspectCountBy) {
      this.setCountByToFn(this.props.aspectCountBy)
    }

    if (this.typeChart !== 'function') {
      this.forceUpdate()
    }
  }

  shouldComponentUpdate(nextProps) {
    const { filter, countBy } = this.props
    return filter !== nextProps.filter || countBy !== nextProps.countBy
  }

  componentWillUpdate(nextProps) {
    const { countBy } = nextProps
    if (this.props.countBy !== countBy) {
      this.countBy = countBy
    }
  }

  onFilterChange = (filter) => {
    const { onFilterChange, aspect } = this.props
    if (onFilterChange) {
      onFilterChange({
        id: aspect.id,
        filter,
      })
    }
  }

  getTitle = (graph) => {
    const { name, count, maxCount } = graph
    const { countBy } = this
    const { i18n } = this.context

    return (
      `${name} (${count > maxCount ? `${i18n.t('top')} ` : ''}${Math.min(count, maxCount)})` +
      `${countBy !== 'count' ? `: ${i18n.t(countBy)}` : ''}`
    )
  }

  setCountByTo(countBy) {
    return () => {
      this.setCountByToFn(countBy)
    }
  }

  setCountByToFn(countBy) {
    this.countBy = countBy
    this.props.updateFilterCountBy({ name: this.props.aspect.name, countBy })
    this.forceUpdate()
  }

  getAspPartName = (partId) => {
    // TODO move this logic to reducer?
    const { aspect } = this.props
    const part = !String(partId).match(',')
      ? aspect.aspectpart[partId]
      : partId.split(',').reduce(
          (fakePart, thisPartId) => ({
            // sometimes (in case of stacked chart) partId is concatenation of multiple part ids…
            ...fakePart,
            ...aspect.aspectpart[thisPartId],
            names: [...fakePart.names, ...(aspect.aspectpart[thisPartId] || {}).names],
          }),
          { names: [] },
        ) // …so we create fake aspectpart obj by merging existing aspectparts

    // map parts names form tag ids (eg 'P:289919') to theirs textual names (eg 'NHL2')
    const getTagNames = (tagLikeEntitiesIds) =>
      tagLikeEntitiesIds.map((unparsedId) => {
        const matchedId = unparsedId.match(/^[A-Z]:(\d+)$/)
        if (matchedId) {
          const tagId = Number(matchedId[1])
          const tag = aspect.tagLikeEntities.find(({ id }) => id === tagId)
          return tag.name
        }
        return unparsedId // this might be eg 'No profile'
      })

    // joins array of part names to single string
    const joinPartNames = (names) => {
      const { i18n } = this.context

      if (names.length === 2) {
        return i18n.t('Both {{first}} and {{second}}', { first: names[0], second: names[1] })
      }
      if (names.length > 2) {
        return part.fuzzy !== undefined
          ? i18n.t('At least 2 of {{list}}', { list: names.join(', ') })
          : i18n.t('All of {{list}}', { list: names.join(', ') })
      }
      return names.join(', ')
    }

    switch (true) {
      case !part:
        return partId
      case !aspect.tagLikeEntities:
        return joinPartNames(part.names)
      default:
        return joinPartNames(getTagNames(part.names) || part.names)
    }
  }

  getBestChartType = (graph) => {
    switch (true) {
      case graph.count <= 10:
        return 'pieChart'
      case graph.count <= 100:
        return 'rowChart'
      default:
        return 'customDataTable'
    }
  }

  switchTo(type, apiType, series = undefined, showLegend = undefined) {
    return () => {
      this.switchToFunction(type, apiType, series, showLegend)
    }
  }

  switchToFunction(type, apiType, series, showLegend) {
    this.chartType = type
    this.seriesType = series
    this.showLegend = showLegend
    this.props.updateFilterType({ name: this.props.aspect.name, type: apiType })
    this.forceUpdate()
  }

  fallBackToLegend = () => {
    this.showLegend = true
    this.forceUpdate()
  }

  chooseBestChartType = (graph) => {
    const chartType = this.getBestChartType(graph)
    this.chartType = chartType
    return chartType
  }

  render() {
    const { aspect, values, filter, commonDimension, getPdfExportData } = this.props
    const { countBy, chartType, seriesType, menu, showLegend } = this

    return (
      <React.Fragment>
        <Graph
          className={`aspect-chart ${showLegend ? ' doubleChart' : ' narrowChart'}`}
          chartGroup="statistics"
          chartType={chartType}
          fallBackToLegend={this.fallBackToLegend}
          showLegend={showLegend}
          name={aspect.name}
          getTitle={this.getTitle}
          values={values}
          dimensionBy={seriesType ? 'date' : aspect.id}
          series={seriesType && { type: seriesType, by: aspect.id }}
          countBy={countBy}
          commonDimension={commonDimension}
          isArray
          filter={filter}
          onFilterChange={this.onFilterChange}
          menu={menu}
          height={CHART_HEIGHT}
          width={showLegend ? WIDE_CHART_WIDTH : NARROW_CHART_WIDTH}
          keyToName={this.getAspPartName}
          colors={colorScale}
          getPdfExportData={getPdfExportData}
        >
          <div className="subtitle">
            <FilterReset aspect={aspect} filter={filter} getAspPartName={this.getAspPartName} />
          </div>
        </Graph>

        {/**
         * Render Graph Clone with constant rows for PDF exporting
         * @param hidden - is required, to prepare table for further pdf export
         */}
        {this.chartType === 'customDataTable' && (
          <Graph
            className={`aspect-chart ${showLegend ? ' doubleChart' : ' narrowChart'} sr-only`}
            chartGroup="statistics"
            chartType={chartType}
            fallBackToLegend={this.fallBackToLegend}
            showLegend={showLegend}
            name={aspect.name}
            getTitle={this.getTitle}
            values={values}
            dimensionBy={seriesType ? 'date' : aspect.id}
            series={seriesType && { type: seriesType, by: aspect.id }}
            countBy={countBy}
            commonDimension={commonDimension}
            isArray
            filter={filter}
            onFilterChange={this.onFilterChange}
            menu={menu}
            height={CHART_HEIGHT}
            width={showLegend ? WIDE_CHART_WIDTH : NARROW_CHART_WIDTH}
            keyToName={this.getAspPartName}
            colors={colorScale}
            getPdfExportData={getPdfExportData}
            hidden
          >
            <div className="subtitle">
              <FilterReset aspect={aspect} filter={filter} getAspPartName={this.getAspPartName} />
            </div>
          </Graph>
        )}
      </React.Fragment>
    )
  }
}

export default translate([], { wait: true })(SwitchableGraph)

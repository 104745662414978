import React from 'react'
import PropTypes from 'prop-types'
import R from 'ramda'
import ReactTagsAutocomplete from 'react-tag-autocomplete'

import Tag from './Tag'
import { differenceById } from '../../opoint/common/index'

type Props = {
  values: Array<{ name: string; id: number | string }>
  options?: Array<{ name: string; id: number | string }>
  onChange?: (any) => any
  id?: string | number
  placeholder?: string
}

class MultipleInput extends React.Component<Props, any> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.object).isRequired,

    values: PropTypes.arrayOf(PropTypes.object),

    onChange: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.state = {
      selected: props.values,
      suggestions: [],
    }
  }

  componentWillMount() {
    // this has to be done here instead of constructor, because otherwise is context undefined
    const { i18n } = this.context
    const { options, values } = this.props

    this.setState({
      // @ts-ignore
      suggestions: R.map(({ name, id }) => ({ name: i18n.t(name), id }), differenceById(options, values)),
    })
  }

  componentWillUpdate({ values, options }) {
    if (this.props.values !== values || this.props.options !== options) {
      // we need to refactor this!
      /* eslint-disable-next-line */
      this.setState({
        selected: values,
        suggestions: differenceById(options, values),
      })
    }
  }

  onChange = (value) => {
    const { onChange } = this.props
    onChange && onChange(value)
  }

  handleAddition = (option) => {
    const { selected, suggestions } = this.state

    if (!option) {
      return
    }
    this.setState(
      {
        selected: [...selected, option],
        suggestions: suggestions.filter((opt) => opt.id !== option.id),
      },
      () => {
        this.onChange(this.state.selected)
      },
    )
  }

  handleDelete = (i) => {
    const { selected, suggestions } = this.state

    this.setState(
      {
        selected: selected.filter((opt) => opt.id !== selected[i].id),
        suggestions: R.sortBy(R.prop('name'))([...suggestions, selected[i]]),
      },
      () => {
        this.onChange(this.state.selected)
      },
    )
  }

  render() {
    const { selected, suggestions } = this.state
    return (
      <div className="multiple-input">
        <ReactTagsAutocomplete
          tagComponent={Tag}
          tags={selected}
          suggestions={suggestions}
          handleAddition={this.handleAddition}
          handleDelete={this.handleDelete}
          minQueryLength={0}
          maxSuggestionsLength={Infinity}
          autofocus={false}
          {...this.props}
        />
      </div>
    )
  }
}

export default MultipleInput

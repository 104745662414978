import React from 'react'

import { ARTICLE_COLORBAR_OFFSET } from './article-colorbar-offset'
import { ColorbarPoint } from './ColorbarPoint'
import { Colorbar as ColorbarType } from '../../../opoint/flow'

type Props = {
  colorbar: ColorbarType
}

// TODO - tests
export const Colorbar = ({ colorbar }: Props) => (
  <ul className="op-content-right-section-content-options-colorbar">
    {colorbar.item.map((point, index) => {
      if (!point.quote || !point.quote.text || point.color <= ARTICLE_COLORBAR_OFFSET) {
        return null
      }

      return <ColorbarPoint key={index} point={point} />
    })}
  </ul>
)

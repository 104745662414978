// @ts-nocheck
import React from 'react'
import PropTypes from 'prop-types'

import Translate from '../../../Translate'

export default class DeletedArticle extends React.Component<any, any> {
  static propTypes = {
    article: PropTypes.object.isRequired,
    destroyArticle: PropTypes.func.isRequired,
  }

  onClick() {
    const { article, destroyArticle } = this.props

    destroyArticle(article)
  }

  render() {
    const { article, destroyArticle } = this.props
    return (
      <article className="op-content-article-listing-article-deleted">
        <span className="op-content-article-listing-article-deleted-text" translate>
          <Translate i18nString="You Will No Longer See This Article" />
        </span>
        <span className="op-content-article-listing-article-deleted-hide" onClick={destroyArticle}>
          <i className="op-icon-delete" />
        </span>
        <span className="op-content-article-listing-article-deleted-undo" onClick={this.onClick} translate>
          <Translate i18nString="Undo" />
        </span>
      </article>
    )
  }
}

// @ts-nocheck

import * as R from 'ramda'
import moment from 'moment'

import * as Actions from '../constants/actionTypes'
import {
  addOneOfKind,
  filterId,
  filterIsProfile,
  filterIsTag,
  filterIsTrashTag,
  getUnmatchedString,
  invertFilter,
  checkIfSuggestedFilterInverted,
} from '../opoint/search/index'
import type { Action, Filter, MultipleSuggestions, Suggestion } from '../opoint/flow'

type SearchState = {
  searchFilters: { [filterId: string]: Filter }
  trashTagIds: Array<number>
  profileTagIds: Array<number>
  selectedTagIds: Array<number>
  suggestions: Array<Suggestion>
  suggestionsMultiple: MultipleSuggestions
  searchInProgress: boolean
  searchIsTakingTooLong: boolean
  loadMoreSearchInProgress: boolean
  meta: {
    foundDocumentsCount: number
    rangeStart?: number
    rangeEnd?: number
    context?: string
    lastTimestamp: number | typeof undefined
    receivedDocumentsCount: number | typeof undefined
  }
  searchDatepicker?: {
    startDate: string
    endDate: string
  }
  searchterm: string
  wikinames: {
    [key in number]: string
  }
  wikidescriptions: {
    [key in number]: string
  }
}

export const initialState: SearchState = {
  searchFilters: {},
  filtersShowMore: [],
  // We store trash-tags on two places so that we don't need to re-render articles
  // each time filter is added. This is a performance optimisation.
  // PLEASE BE CAREFUL WHEN PLAYING AROUND WITH FILTERS AND ALWAYS CHECK A PERFORMANCE OF THE APP
  trashTagIds: [],
  profileTagIds: [],
  selectedTagIds: [],
  // END
  suggestions: [],
  suggestionsMultiple: {},
  searchInProgress: false,
  searchIsTakingTooLong: false,
  loadMoreSearchInProgress: false,
  meta: {
    foundDocumentsCount: 0,
    rangeStart: null,
    rangeEnd: null,
    context: null,
    lastTimestamp: undefined,
    receivedDocumentsCount: undefined,
  },
  searchDatepicker: null,
  searchterm: '',
  wikinames: {},
  wikidescriptions: {},
}

/**
 * This reducer controls how we retrieve suggestions from Opoint's backend.
 * It takes care of toggling filters, inverting them, setting search terms etc.
 *
 * NOTE: It doesn't contain information about complex handling of filters in a profile editor.
 * @see profileReducer for more information how profile editor actions are handled.
 */
const searchReducer = (state: SearchState = initialState, { type, payload }: Action<any>) => {
  switch (type) {
    /**
     * In case a location changed, we want to have the search field load the latest data.
     */
    case Actions.ROUTER_SEARCH_DATA_CHANGE: {
      const { parsedFilters, expression } = payload

      return R.compose(
        R.assoc('searchterm', R.or(expression, '')),
        R.assoc('searchFilters', R.indexBy(filterId, parsedFilters)),
        R.assoc(
          'trashTagIds',
          R.filter(filterIsTrashTag, parsedFilters).map((x) => parseInt(x.id, 10)),
        ),
        R.assoc(
          'profileTagIds',
          R.filter(filterIsProfile, parsedFilters).map((x) => parseInt(x.id, 10)),
        ),
        R.assoc(
          'selectedTagIds',
          R.filter(filterIsTag, parsedFilters).map((x) => parseInt(x.id, 10)),
        ),
      )(state)
    }
    case Actions.CLEAR_FORM:
    case Actions.FEED_REMOVE_ACTIVE:
      return R.assoc('abandonedSearchLine', [], state)

    /**
     * Clears the global search terms.
     */
    case Actions.SEARCHDATA_CLEAR:
      return R.assoc('searchFilters', [], state)

    /**
     * Invert filter given in an payload.
     */
    case Actions.INVERT_FILTER: {
      const { filter } = payload
      return R.evolve({
        searchFilters: R.compose(
          R.dissoc(filterId(filter)),
          R.assoc(filterId(invertFilter(filter)), invertFilter(filter)),
        ),
      })(state)
    }
    case Actions.UPDATE_SEARCHTERM_SUCCESS: {
      const { searchterm } = payload
      return R.assoc('searchterm', searchterm, state)
    }

    /**
     * Assoc. filters once they are successfully retrieved.
     */
    case Actions.FILTERS_FETCH_SUCCESS:
      return R.assoc('suggestions', payload, state)

    /**
     * Assoc. filters in a multiple mode.
     */
    case Actions.FILTERS_FETCH_MULTIPLE_SUCCESS:
      return R.assoc('suggestionsMultiple', payload, state)

    /**
     * Filters - show more of filter type
     */
    case Actions.FILTERS_FETCH_MULTIPLE_OF_TYPE_SUCCESS:
      return R.assoc('filtersShowMore', payload, state)

    case Actions.FILTERS_POP:
      return R.assoc('filtersShowMore', state.filtersShowMore.slice(0, state.filtersShowMore.length - 1), state)

    /**
     * Remove filter received in an payload
     */
    case Actions.SEARCHFILTER_REMOVED: {
      const filterToBeRemoved: Filter = payload
      return R.evolve(
        {
          searchFilters: R.dissoc(filterId(filterToBeRemoved)),
        },
        state,
      )
    }

    /**
     * Once a search filter is added to a search.
     */
    case Actions.SEARCHFILTER_ADDED: {
      const filterToBeAdded: Filter = payload
      return R.evolve(
        {
          searchFilters: R.unless(
            R.prop(filterId(filterToBeAdded)),
            R.curry(addOneOfKind)(filterId(filterToBeAdded), filterToBeAdded),
          ),
          searchterm: getUnmatchedString(filterToBeAdded),
        },
        state,
      )
    }

    /**
     * Add or remove given filter based on current filters state.
     */
    case Actions.SEARCHFILTER_TOGGLED: {
      // return state
      const filterToBeToggled: Filter = payload
      const idFilter: string = checkIfSuggestedFilterInverted(filterId(filterToBeToggled), state.searchFilters)
      return R.evolve(
        {
          searchFilters: R.ifElse(
            R.prop(idFilter),
            R.dissoc(idFilter),
            R.curry(addOneOfKind)(idFilter, filterToBeToggled),
          ),
          searchterm: getUnmatchedString(payload),
        },
        state,
      )
    }

    case Actions.FETCH_MORE_ARTICLES: {
      return R.assoc('loadMoreSearchInProgress', true, state)
    }

    /**
     * Shrink left side of date range to the point based on currently fetched documents
     */
    case Actions.SEARCH_CHANGE_DATERANGE_TO_CURRENT: {
      return R.evolve({
        meta: {
          rangeStart: R.always(state.meta.lastTimestamp),
          foundDocumentsCount: R.always(state.meta.receivedDocumentsCount),
        },
      })(state)
    }

    /**
     * Functions controlling the state of article's promise.
     */
    case Actions.FETCH_ARTICLES: {
      return R.compose(R.assoc('searchInProgress', true), R.assoc('meta', initialState.meta))(state)
    }

    case Actions.SEARCH_IS_EMPTY: {
      return R.evolve({
        searchInProgress: R.F,
        meta: {
          context: R.always(''),
        },
      })(state)
    }

    case Actions.PROFILE_EDITOR_PREVIEW: {
      return R.assoc('searchInProgress', true, state)
    }

    case Actions.PROFILE_EDITOR_PREVIEW_SUCCESS:
    case Actions.PROFILE_EDITOR_PREVIEW_FAILURE: {
      return R.assoc('searchInProgress', false, state)
    }

    case Actions.FETCH_MORE_ARTICLES_SUCCESS:
    case Actions.FETCH_ARTICLES_SUCCESS:
    case Actions.FETCH_STATISTICS_SUCCESS: {
      const {
        documents: receivedDocumentsCount,
        range_count: foundDocumentsCount,
        context,
        rangeStart,
        rangeEnd,
        lastTimestamp,
        firstTimestamp,
        wikinames,
        wikidescriptions,
        debug,
      } = payload.response.searchresult

      return R.evolve({
        searchInProgress: R.always(false),
        searchIsTakingTooLong: R.always(false),
        loadMoreSearchInProgress: R.always(false),
        meta: R.always({
          receivedDocumentsCount,
          foundDocumentsCount, // (range count)
          // might be equal to number of documents, in which case all
          // requested documents were delivered
          // or it might be bigger number which means not all requested
          // were delivered and time range selector should be shown
          rangeStart, // requested range start
          rangeEnd, // requested range end - might be 0 which means now
          lastTimestamp, // oldest - delivered range start
          firstTimestamp, // newest
          context,
        }),
        searchterm: R.always(debug && debug.lines ? debug.lines[0].query : state.searchterm),
        wikinames: R.always(wikinames),
        wikidescriptions: R.always(wikidescriptions),
      })(state)
    }
    case Actions.FETCH_MORE_ARTICLES_FAILURE:
    case Actions.FETCH_ARTICLES_FAILURE:
      return R.evolve({
        loadMoreSearchInProgress: R.always(false),
        searchIsTakingTooLong: R.always(false),
        searchInProgress: R.always(false),
      })(state)

    case Actions.DATEPICKER_MODAL_OPEN: {
      const {
        meta: { rangeEnd, rangeStart },
      } = state
      // Unix timestamp must be converted to iso string since OpointDatepicker
      // is returning iso string
      // When ranges are not set dont change state otherwise moment will set
      // start/end date as 'Invalid date'
      return rangeEnd && rangeStart
        ? R.compose(
            R.assocPath(['searchDatepicker', 'endDate'], moment(rangeEnd, 'x').toISOString()),
            R.assocPath(['searchDatepicker', 'startDate'], moment(rangeStart, 'x').toISOString()),
          )(state)
        : state
    }

    case Actions.DATEPICKER_MODAL_CLOSE:
      return R.assoc('searchDatepicker', null, state)

    case Actions.DATEPICKER_MODAL_SET_START_DATE:
      return R.assocPath(['searchDatepicker', 'startDate'], payload, state)

    case Actions.DATEPICKER_MODAL_SET_END_DATE:
      return R.assocPath(['searchDatepicker', 'endDate'], payload, state)

    case Actions.SEARCH_IS_TAKING_TOO_LONG:
      return R.assoc('searchIsTakingTooLong', true, state)

    case Actions.CANCEL_SEARCH:
      return R.evolve({
        searchInProgress: R.always(false),
        searchIsTakingTooLong: R.always(false),
      })(state)

    case Actions.STORE_CURRENT_SEARCHLINE: {
      const { searchLine } = payload
      return R.assoc('abandonedSearchLine', searchLine, state)
    }

    default:
      return state
  }
}

export default searchReducer

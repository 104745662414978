import React from 'react'
import { connect } from 'react-redux'

import buildActionCreators from '../../../helpers/buildActionCreators'
import Translate from '../../../Translate'
import { getFilteredArticles } from '../../../selectors/statisticsSelectors'
import * as ActionTypes from '../../../constants/actionTypes'

// TODO translate @danad02
class ShowFilteredButton extends React.Component<any, any> {
  onClickHandler = (onClick) => {
    this.props.hidePopover()
    this.props.showFilteredArticles()
  }

  render() {
    const { showFilteredArticles, filteredArticles, isFromPopover, keyRef } = this.props
    if (!filteredArticles) {
      return null
    }
    if (isFromPopover) {
      return (
        <li key={keyRef} onClick={this.onClickHandler}>
          <a>
            <i className="op-icon-pencil" />
            <Translate
              i18nString="Show {{count}} filtered articles"
              context={{
                count: filteredArticles.length,
              }}
            />
          </a>
        </li>
      )
    }
    return (
      <span>
        <button className="op-button-toolbar" onClick={showFilteredArticles}>
          <span>
            <Translate
              i18nString="Show {{count}} filtered articles"
              context={{
                count: filteredArticles.length,
              }}
            />
          </span>
          <i className="op-icon-filters" />
        </button>
      </span>
    )
  }
}
export default connect(
  (state) => ({
    filteredArticles: getFilteredArticles(state),
  }),
  buildActionCreators({
    showFilteredArticles: ActionTypes.STATISTICS_SHOW_FILTERED,
  }),
)(ShowFilteredButton)

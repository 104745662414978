export const notificationsTypes = {
  '-1': {
    class: 'mod-fail',
    icon: 'op-icon-cancel',
    label: 'Failed to generate',
  },
  0: {
    class: 'mod-in-progress',
    icon: 'op-icon-update',
    label: 'In progress',
  },
  1: {
    class: 'mod-done',
    icon: 'op-icon-file',
    label: 'Finished generating',
  },
}

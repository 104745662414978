import React from 'react'
import R from 'ramda'

import Translate from '../../Translate'

export default class ProfileEditorTipsComponent extends React.PureComponent<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      tips: [
        {
          id: 0,
          text: (
            <span>
              <Translate i18nString="You can open filters and start typing to filter results" />.
            </span>
          ),
        },
        {
          id: 1,
          text: (
            <span>
              <Translate i18nString="By default you search for all words" />.{' '}
              <Translate i18nString="Use OR between words to change that" />.
            </span>
          ),
        },
        {
          id: 2,
          text: (
            <span>
              <Translate i18nString="Use quotes to search for exact phrases" />.
            </span>
          ),
        },
        {
          id: 3,
          text: (
            <span>
              <Translate i18nString="Uppercase letters match only uppercase, lowercase match both cases" />.
            </span>
          ),
        },
        {
          id: 4,
          text: (
            <span>
              <Translate
                i18nString="Use {{headerExample}} to search for all words starting with sun"
                context={{
                  headerExample: '"sun*"',
                  interpolation: { escapeValue: false },
                }}
              />
              .
            </span>
          ),
        },
        {
          id: 5,
          text: (
            <span>
              <Translate
                i18nString="Use {{headerExample}} to search for {{example}} only within headline"
                context={{
                  headerExample: '"header:sun"',
                  example: '"sun"',
                  interpolation: { escapeValue: false },
                }}
              />
              .
            </span>
          ),
        },
        {
          id: 6,
          text: (
            <span>
              <Translate
                i18nString="Use {{headerExample}} to find articles containing sun but not sea"
                context={{
                  headerExample: '"sun -sea"',
                  interpolation: { escapeValue: false },
                }}
              />
              .
            </span>
          ),
        },
        {
          id: 7,
          text: (
            <span>
              <Translate i18nString="Use the filter categories below to limit search scope" />.
            </span>
          ),
        },
        {
          id: 8,
          text: (
            <span>
              <Translate
                i18nString="AND bind stronger than OR, use parentheses to override - {{example}}"
                context={{
                  example: '"(sea OR salt) AND (sun OR sky)"',
                  interpolation: { escapeValue: false },
                }}
              />
              .
            </span>
          ),
        },
        {
          id: 9,
          text: (
            <span>
              <Translate
                i18nString="Use {{example}} to search for articles where there is less than 10 words between sun and sea or boat"
                context={{
                  example: '"SPAN/10(sun, sea boat)"',
                  interpolation: { escapeValue: false },
                }}
              />
            </span>
          ),
        },
      ],
      activeTip: 0,
    }
  }

  onClickHandler = () => {
    this.setState(
      R.evolve({
        activeTip: R.compose(
          R.modulo(R.__, this.state.tips.length) /* eslint-disable-line no-underscore-dangle */,
          R.inc,
        ),
      }),
    )
  }

  // TODO animate transition from one tip to another
  render() {
    return (
      <div className="op-tip" onClick={this.onClickHandler}>
        <i className="op-icon-arrow-right right-float" />
        <ul>
          <li key={this.state.activeTip} className="active">
            {this.state.tips[this.state.activeTip].text}
          </li>
        </ul>
        <span>
          <Translate i18nString="Tips" />:
        </span>
      </div>
    )
  }
}

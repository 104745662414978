// @ts-nocheck

import R from 'ramda'
import Rx from 'rx-dom'
import { saveAs } from 'file-saver'

import config from '../common/config'

export const apiToLocaleTypes = {
  pie: { type: 'pieChart', apiType: 'pie' },
  row: { type: 'rowChart', apiType: 'row' },
  widepie: { type: 'pieChart', apiType: 'widepie', showLegend: true },
  freqtable: { type: 'customDataTable', apiType: 'freqtable' },
  seriesline: {
    type: 'lineChart',
    apiType: 'seriesline',
    series: 'overlapped',
    showLegend: true,
  },
  seriesarea: {
    type: 'lineChart',
    apiType: 'seriesarea',
    series: 'stacked',
    showLegend: true,
  },
  seriesbar: {
    type: 'barChart',
    apiType: 'seriesbar',
    series: 'stacked',
    showLegend: true,
  },
  timetable: {
    type: 'customDataTable',
    apiType: 'timetable',
    series: 'overlapped',
    showLegend: true,
  },
}

export const reduceSumName = {
  count: 'n. of articles',
  circulation: 'circulation',
  reach: 'reach',
  adprice: 'adprice',
}

export function getStatisticViews() {
  const requestHeaders = R.merge(config.getRequestHeaders(), {
    url: config.getUrl('/api/statistics/'),
    method: 'GET',
  })
  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function getStatisticViewData(id: number | string) {
  const requestHeaders = R.merge(config.getRequestHeaders(), {
    url: config.getUrl(`/api/statistics/${id}/`),
    method: 'GET',
  })
  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function deleteStatisticsView(statViewId: string) {
  const requestHeaders = R.merge(config.getRequestHeaders(), {
    url: config.getUrl(`/api/statistics/${statViewId}/`),
    method: 'DELETE',
    responseType: 'text',
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function saveStatisticView(statView, id) {
  const requestHeaders = R.merge(config.getRequestHeaders(), {
    url: id ? config.getUrl(`/api/statistics/${id}/`) : config.getUrl('/api/statistics/'),
    method: id ? 'PATCH' : 'POST',
    body: JSON.stringify(statView),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export const exportPDF = ({ activeStatisticsViewName, metaString, description, title, fileName, SVGs }) => {
  const url = config.getUrl('/export-statistics/')

  // dev url:
  // const url = 'http://localhost:3001/export-statistics/'

  return fetch(url, {
    headers: {
      accept: 'application/pdf',
      'accept-language': 'en-GB',
      'content-type': 'application/json',
      'accept-charset': 'utf-8',
    },
    responseType: 'blob',
    method: 'POST',
    body: JSON.stringify({
      SVGsArray: SVGs,
      docTitle: title,
      docDescription: description,
      exportInfo: {
        metaString,
        statisticsViewName: activeStatisticsViewName || '',
        // TODO
        // statisticsProfiles: 'USA + Trump',
      },
    }),
  }).then((res) => {
    if (res.ok) {
      res.blob().then((PDFdocument) => saveAs(PDFdocument, fileName || 'statistics-export-pdf.pdf'))
    }

    return activeStatisticsViewName
  })
}

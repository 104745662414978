// @ts-nocheck

import type { HelpVideo, HelpPdf, LocaleKey } from '../flow'
import OpointConfig from '../common/config'

// TODO - move strings (name) to transifex once we support other languages
const NORWEGIAN_VIDEOS: Array<HelpVideo> = [
  { name: 'Introduksjon', link: 'https://www.youtube.com/embed/bzj8d0eYSWI' },
  { name: 'Varsler', link: 'https://www.youtube.com/embed/2QbgvOmFrkE' },
  { name: 'Tags', link: 'https://www.youtube.com/embed/PpXxGNPFsZk' },
  { name: 'Statistikk', link: 'https://www.youtube.com/embed/8YtA1D1EgSE' },
  { name: 'Søkeprofiler', link: 'https://www.youtube.com/embed/zFQZSTnbiXQ' },
  { name: 'Rapport', link: 'https://www.youtube.com/embed/BA1pHRw5dpo' },
  { name: 'Fritekstsøk', link: 'https://www.youtube.com/embed/LW4H2NiXszo' },
  { name: 'Feeds', link: 'https://www.youtube.com/embed/N9cFPnBXJ9E' },
]

const SWEDISH_VIDEOS: Array<HelpVideo> = [
  { name: 'Statistik i M360', link: 'https://www.youtube.com/embed/l3GNPJFwLtA' },
  { name: 'Arbeta med taggar i M360', link: 'https://youtube.com/embed/4ZCB2l2F_5M' },
  { name: 'Göra en arkivsökning i M360', link: 'https://youtube.com/embed/Hvt01urBWyk' },
  { name: 'Skapa nyhetsbrev I M360', link: 'https://youtube.com/embed/sEDU7tDKMCQ' },
  { name: 'Skapa rapporter I M360', link: 'https://youtube.com/embed/wSrV-DcxNJA' },
  { name: 'Skapa RSSer I M360', link: 'https://youtube.com/embed/hwXaLq22pAc' },
]

type HelpVideosType = { [key: LocaleKey]: Array<HelpVideo> }
export const HELP_VIDEOS: HelpVideosType = {
  'nb-NO': NORWEGIAN_VIDEOS,
  'nn-NO': NORWEGIAN_VIDEOS,
  'sv-SE': SWEDISH_VIDEOS,
}

type HelpPdfType = { [key: LocaleKey]: HelpPdf }
export const HELP_PDFS: HelpPdfType = {
  'en-GB': { link: OpointConfig.getUrl('/api/static/manuals/user_manual_en.pdf') },
  'sv-SE': { link: OpointConfig.getUrl('/api/static/manuals/user_manual_sv.pdf') },
  'nb-NO': { link: OpointConfig.getUrl('/api/static/manuals/user_manual_no.pdf') },
  'nn-NO': { link: OpointConfig.getUrl('/api/static/manuals/user_manual_no.pdf') },
}

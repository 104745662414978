// @ts-nocheck
import React from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'
import * as R from 'ramda'

import Select from '../../common/Select'
import ReportsError from '../ReportsError'
import Translate from '../../../Translate'
import ProfileSource, { Props as ProfileProps } from './ProfileSource'
import TagSource, { Props as TagProps } from './TagSource'
import type { Tag, Profile } from '../../../opoint/flow'

type Props = ProfileProps &
  TagProps & {
    tags: Array<Tag>
    profiles: Array<Profile>
    sourceChange: () => void
    selectValue: { type: 'profile' | 'tag'; id: number }
    error: boolean
    lastReportDate: number
    changeActiveTemplate: () => void
  }

class ReportsContent extends React.Component<Props> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  componentWillMount() {
    const { startDate, endDate, onStartDateChange, onEndDateChange, reportsHistory, changeActiveTemplate } = this.props

    if (!reportsHistory) {
      onStartDateChange(startDate)
      onEndDateChange(endDate)
    }

    changeActiveTemplate()
  }

  render() {
    const {
      analyticsTags,
      changeType,
      endDate,
      error,
      isSelected,
      lastReportDate,
      onEndDateChange,
      onStartDateChange,
      profiles,
      reportsHistory,
      reportsHistoryCheck,
      selectValue,
      sourceChange,
      startDate,
      tags,
      type,
    } = this.props

    const { i18n } = this.context

    const currentSearch = [
      {
        value: 'current_search',
        type: 'current_search',
        label: i18n.t('Current search'),
        group: i18n.t('General'),
      },
    ]

    const generalOptions = isSelected
      ? [
          ...currentSearch,
          {
            value: 'selected_articles',
            type: 'selected_articles',
            label: i18n.t('Selected articles'),
            group: i18n.t('General'),
          },
        ]
      : currentSearch

    const selectOptions = generalOptions
      .concat(
        tags.map(({ id, name }) => ({
          value: `tag:${id}`,
          type: 'tag',
          label: name,
          group: i18n.t('Tags'),
        })),
      )
      .concat(
        profiles.map(({ id, name }) => ({
          value: `profile:${id}`,
          type: 'profile',
          label: name,
          group: i18n.t('Profiles'),
        })),
      )
      .concat(
        analyticsTags.map(({ id, name }) => ({
          value: `tag:${id}`,
          type: 'tag',
          label: name,
          group: i18n.t('Analytics tags'),
        })),
      )

    const selectedOption = selectOptions.find(
      ({ value }) => value === selectValue.type || value === `${selectValue.type}:${selectValue.id}`,
    )

    return (
      <div className="op-modal-report-body-content">
        <div className="content__header">
          <h3>
            {selectedOption && selectedOption.type === 'tag' && (
              <span>
                <i className="op-icon-tag" />
                <Translate i18nString="Tag" />
              </span>
            )}
            {selectedOption && selectedOption.type === 'profile' && (
              <span>
                <i className="op-icon-search" />
                <Translate i18nString="Profile" />
              </span>
            )}
            {selectedOption && selectedOption.type === 'current_search' && (
              <span>
                <i className="op-icon-search" />
              </span>
            )}
            {selectedOption && selectedOption.type === 'selected_articles' && (
              <span>
                <i className="op-icon-news" />
              </span>
            )}
          </h3>
          <Select
            className="op-select"
            value={selectedOption && selectedOption.value}
            onChange={sourceChange}
            options={selectOptions}
          />
        </div>
        {selectedOption && selectedOption.type === 'selected_articles' && (
          <p>
            <Translate i18nString="Report will contain the articles you have checked" />
          </p>
        )}
        {reportsHistory && (
          <ReportsError
            active={error}
            error={
              R.isEmpty(reportsHistory)
                ? i18n.t('There are no recently tagged articles')
                : i18n.t('Please choose at least one group of articles')
            }
          />
        )}
        {selectedOption && selectedOption.type === 'tag' && (
          <TagSource
            changeType={changeType}
            endDate={endDate}
            lastReportDate={lastReportDate}
            locale="en-GB"
            onEndDateChange={onEndDateChange}
            onStartDateChange={onStartDateChange}
            reportsHistory={reportsHistory}
            reportsHistoryCheck={reportsHistoryCheck}
            startDate={startDate}
            type={type}
          />
        )}
        {selectedOption && ['profile', 'current_search'].includes(selectedOption.type) && (
          <ProfileSource
            endDate={endDate}
            lastReportDate={lastReportDate}
            locale="en-GB"
            onEndDateChange={onEndDateChange}
            onStartDateChange={onStartDateChange}
            startDate={startDate}
          />
        )}
      </div>
    )
  }
}

export default translate([], { wait: true })(ReportsContent)

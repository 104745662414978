import * as R from 'ramda'

export const locales = {
  'en-GB': {
    name: 'English',
    englishName: 'English',
    suggestionLocale: 'en_GB_1',
    alertsLocale: 'gb_EN',
    country: 'United Kingdom',
    countryCode: 'GB_EN',
    timezone: 'Europe/London',
    browserLocale: 'en',
    sourceListLocale: 'en',
    holidaysLocale: 'GB_EN',
    momentLocale: 'en',
    data: {
      email: 'support@infomedia.no',
      phone: '(+47) 21 56 97 50',
    },
    dFormat: {
      long: 'MMM Do, Y',
      short: 'MMM Do',
      days: 'dddd',
      dayTime: 'dddd, H:mm',
    },
  },
  'nb-NO': {
    name: 'Norsk (Bokmål)',
    englishName: 'Norwegian (Bokmål)',
    suggestionLocale: 'nb_NO_1',
    alertsLocale: 'nb_NO',
    country: 'Norway',
    countryCode: 'NO',
    timezone: 'Europe/Oslo',
    browserLocale: 'no',
    sourceListLocale: 'nb',
    holidaysLocale: 'NO',
    momentLocale: 'nb',
    data: {
      email: 'support@infomedia.no',
      phone: '(+47) 21 56 97 50',
    },
    dFormat: {
      long: 'Do MMM, Y',
      short: 'Do MMM',
      days: 'dddd',
      dayTime: 'dddd, H:mm',
    },
  },
  'nn-NO': {
    name: 'Norsk (Nynorsk)',
    englishName: 'Norwegian (Nynorsk)',
    suggestionLocale: 'nn_NO_1',
    alertsLocale: 'nn_NO',
    country: 'Norway',
    countryCode: 'NO',
    timezone: 'Europe/Oslo',
    browserLocale: 'nn',
    sourceListLocale: 'nn',
    holidaysLocale: 'NO',
    momentLocale: 'nn',
    data: {
      email: 'support@infomedia.no',
      phone: '(+47) 21 56 97 50',
    },
    dFormat: {
      long: 'Do MMM, Y',
      short: 'Do MMM',
      days: 'dddd',
      dayTime: 'dddd, H:mm',
    },
  },
  'sv-SE': {
    name: 'Svenska',
    englishName: 'Swedish',
    suggestionLocale: 'sv_SE_1',
    alertsLocale: 'sv_SE',
    country: 'Sweden',
    countryCode: 'SE',
    timezone: 'Europe/Stockholm',
    browserLocale: 'sv',
    sourceListLocale: 'sv',
    holidaysLocale: 'SE',
    momentLocale: 'sv',
    data: {
      email: 'support@infomedia.no',
      phone: null,
    },
    dFormat: {
      long: 'Do MMM, Y',
      short: 'Do MMM',
      days: 'dddd',
      dayTime: 'dddd, H:mm',
    },
  },
  'da-DK': {
    name: 'Dansk',
    englishName: 'Danish',
    suggestionLocale: 'da_DK_1',
    alertsLocale: 'da_DK',
    country: 'Denmark',
    countryCode: 'DK',
    timezone: 'Europe/Copenhagen',
    browserLocale: 'da',
    sourceListLocale: 'da',
    holidaysLocale: 'DK',
    momentLocale: 'da',
    data: {
      email: 'support@infomedia.dk',
      phone: '(+47) 21 56 97 50',
    },
    dFormat: {
      long: 'Do MMM, Y',
      short: 'Do MMM',
      days: 'dddd',
      dayTime: 'dddd, H:mm',
    },
  },
  'et-EE': {
    name: 'Eesti',
    englishName: 'Estonian',
    suggestionLocale: 'et_EE_1',
    alertsLocale: 'et_EE',
    country: 'Estonia',
    countryCode: 'EE',
    timezone: 'Europe/Tallinn',
    browserLocale: 'et',
    sourceListLocale: 'et',
    holidaysLocale: 'EE',
    momentLocale: 'et',
    data: {
      email: 'support@infomedia.no',
      phone: '(+47) 21 56 97 50',
    },
    dFormat: {
      long: 'Do MMM, Y',
      short: 'Do MMM',
      days: 'dddd',
      dayTime: 'dddd, H:mm',
    },
  },
  'fi-FI': {
    name: 'Finnish',
    englishName: 'Finnish',
    suggestionLocale: 'fi_FI_1',
    alertsLocale: 'fi_FI',
    country: 'Finland',
    countryCode: 'FI_FI',
    timezone: 'Europe/Helsinki',
    browserLocale: 'fi',
    sourceListLocale: 'fi',
    momentLocale: 'fi',
    holidaysLocale: 'FI',
    data: {
      email: 'support@infomedia.no',
      phone: '(+47) 21 56 97 50',
    },
    dFormat: {
      long: 'Do MMM, Y',
      short: 'Do MMM',
      days: 'dddd',
      dayTime: 'dddd, H:mm',
    },
  },
  'zh-CN': {
    name: 'Chinese',
    englishName: 'Chinese',
    suggestionLocale: 'zh_CN_1',
    alertsLocale: 'zh_CN',
    country: 'China',
    countryCode: 'CN',
    timezone: 'Asia/Beijing',
    browserLocale: 'zh',
    sourceListLocale: 'zh',
    momentLocale: 'zh',
    holidaysLocale: 'CN',
    data: {
      email: 'support@infomedia.no',
      phone: '(+47) 21 56 97 50',
    },
    dFormat: {
      long: 'MMMM Do YYYY',
      short: 'MMM Do YY',
      days: 'dddd',
      dayTime: 'dddd, h:mm',
    },
  },
}

export const SUPPORTED_LOCALES = Object.keys(locales)

// @ts-ignore
export const getCountries = () => R.compose(R.uniq(), R.values(), R.map(R.prop('country')))(locales)

// @ts-ignore
export const getLanguages = () => R.compose(R.values(), R.map(R.prop('englishName')))(locales)

// @ts-ignore
export const getTimezones = () => R.compose(R.uniq(), R.values(), R.map(R.prop('timezone')))(locales)

export const getDefaultCountry = (locale) => locales[locale].country
export const getDefaultTimezone = (locale) => locales[locale].timezone
export const getDefaultLanguage = (locale) => locales[locale].englishName
export const javaLocaleToBaseLocaleId = (locale) => locale.split('-').join('_')

export function browserLocaleToOpointLocale(browserLocaleToFind) {
  const localeEntry = Object.entries(locales).find(
    ([opointLocale, { browserLocale }]) => browserLocale === browserLocaleToFind,
  )
  return localeEntry ? localeEntry[0] : 'en-GB'
}

/* auto translations */

export const TRANSLATE_ARTICLE = 1
export const TRANSLATION_LINK = 2

// ISO 639-1 codes supported by opoint's auto translate
export const autoTranslateLanguageCodes = [
  'en',
  'no',
  'sv',
  'de',
  'zh',
  'es',
  'it',
  'ar',
  'fr',
  'ru',
  'tr',
  'pl',
  'nl',
  'vi',
  'ja',
  'pt',
  'ko',
  'el',
  'id',
  'ro',
  'bs',
  'da',
  'fi',
  'hi',
  'fa',
  'hr',
  'th',
  'sq',
  'hu',
  'cs',
  'sl',
  'mk',
  'bg',
  'sk',
  'bn',
  'lt',
  'ca',
  'uk',
  'lv',
  'ms',
  'pa',
  'ml',
  'ta',
  'et',
  'sr',
  'az',
  'gu',
  'gl',
  'kn',
  'am',
  'ka',
  'mr',
  'ur',
  'hy',
  'te',
  'is',
  'eu',
  'kk',
  'mt',
  'cy',
  'my',
  'si',
  'af',
  'km',
  'so',
  'ga',
  'sw',
  'be',
  'ht',
  'mi',
]

// all languages supported by google translate
// (https://cloud.google.com/translate/docs/languages) - note: zh-CN + zh-TW => zn; ma => pa
const mainLanguages = {
  cs: 'Czech',
  da: 'Danish',
  en: 'English',
  et: 'Estonian',
  fi: 'Finnish',
  fr: 'French',
  de: 'German',
  it: 'Italian',
  no: 'Norwegian',
  pl: 'Polish',
  es: 'Spanish',
  sv: 'Swedish',
  zh: 'Chinese',
}

const allLanguages = {
  af: 'Afrikaans',
  sq: 'Albanian',
  am: 'Amharic',
  ar: 'Arabic',
  hy: 'Armenian',
  az: 'Azeerbaijani',
  eu: 'Basque',
  be: 'Belarusian',
  bn: 'Bengali',
  bs: 'Bosnian',
  bg: 'Bulgarian',
  ca: 'Catalan',
  ceb: 'Cebuano',
  ny: 'Chichewa',
  zh: 'Chinese',
  co: 'Corsican',
  hr: 'Croatian',
  cs: 'Czech',
  da: 'Danish',
  nl: 'Dutch',
  en: 'English',
  eo: 'Esperanto',
  et: 'Estonian',
  tl: 'Filipino',
  fi: 'Finnish',
  fr: 'French',
  fy: 'Frisian',
  gl: 'Galician',
  ka: 'Georgian',
  de: 'German',
  el: 'Greek',
  gu: 'Gujarati',
  ht: 'Haitian',
  ha: 'Hausa',
  haw: 'Hawaiian',
  iw: 'Hebrew',
  hi: 'Hindi',
  hmn: 'Hmong',
  hu: 'Hungarian',
  is: 'Icelandic',
  ig: 'Igbo',
  id: 'Indonesian',
  ga: 'Irish',
  it: 'Italian',
  ja: 'Japanese',
  jw: 'Javanese',
  kn: 'Kannada',
  kk: 'Kazakh',
  km: 'Khmer',
  ko: 'Korean',
  ku: 'Kurdish',
  ky: 'Kyrgyz',
  lo: 'Lao',
  la: 'Latin',
  lv: 'Latvian',
  lt: 'Lithuanian',
  lb: 'Luxembourgish',
  mk: 'Macedonian',
  mg: 'Malagasy',
  ms: 'Malay',
  ml: 'Malayalam',
  mt: 'Maltese',
  mi: 'Maori',
  mr: 'Marathi',
  mn: 'Mongolian',
  my: 'Burmese',
  ne: 'Nepali',
  no: 'Norwegian',
  ps: 'Pashto',
  fa: 'Persian',
  pl: 'Polish',
  pt: 'Portuguese',
  pa: 'Punjabi',
  ro: 'Romanian',
  ru: 'Russian',
  sm: 'Samoan',
  gd: 'Scots',
  sr: 'Serbian',
  st: 'Sesotho',
  sn: 'Shona',
  sd: 'Sindhi',
  si: 'Sinhala',
  sk: 'Slovak',
  sl: 'Slovenian',
  so: 'Somali',
  es: 'Spanish',
  su: 'Sundanese',
  sw: 'Swahili',
  sv: 'Swedish',
  tg: 'Tajik',
  ta: 'Tamil',
  te: 'Telugu',
  th: 'Thai',
  tr: 'Turkish',
  uk: 'Ukrainian',
  ur: 'Urdu',
  uz: 'Uzbek',
  vi: 'Vietnamese',
  cy: 'Welsh',
  xh: 'Xhosa',
  yi: 'Yiddish',
  yo: 'Yoruba',
  zu: 'Zulu',
}

// for example taken 'en' or 'en:cy' returns ['English']
export const getFullnameLanguage = (codes: Array<string>) => R.props(R.split(':', codes[0]))(allLanguages) || 'Unknown'

// pick only those languages whose codes are in autoTranslateLanguageCodes
export const languages: Object = R.pick(autoTranslateLanguageCodes, mainLanguages)

// We need this incase we translate to main languages
// from language that has not support for translating to
export const translatedFromLanguages: Object = R.pick(autoTranslateLanguageCodes, allLanguages)

// overlaps modes represented by signs
export const EXACT_MODE = -1
export const FUZZY_MODE = +1

export const COUNTRIES_CODES = [
  1000,
  1001,
  1002,
  1003,
  1004,
  1005,
  1006,
  1007,
  1008,
  1009,
  1010,
  1011,
  1012,
  1013,
  1014,
  1015,
  1016,
  1017,
  1018,
  1019,
  1020,
  1021,
  1022,
  1023,
  1024,
  1025,
  1026,
  1027,
  1028,
  1029,
  1030,
  1031,
  1032,
  1033,
  1034,
  1035,
  1036,
  1037,
  1038,
  1039,
  1040,
  1041,
  1042,
  1043,
  1044,
  1045,
  1046,
  1047,
  1049,
  1050,
  1051,
  1052,
  1053,
  1054,
  1073,
  1074,
  1088,
  1089,
  1090,
  1091,
  1092,
  1094,
  1095,
  1096,
  1097,
  1098,
  1099,
  1100,
  1101,
  1102,
  1103,
  1105,
  1106,
  1119,
  1120,
  1121,
  1122,
  1123,
  1124,
  1125,
  1126,
  1127,
  1128,
  1129,
  1130,
  1131,
  1132,
  1133,
  1134,
  1135,
  1136,
  1137,
  1138,
  1139,
  1140,
  1141,
  1142,
  1143,
  1144,
  1145,
  1146,
  1147,
  1148,
  1149,
  1150,
  1151,
  1152,
  1153,
  1154,
  1155,
  1156,
  1157,
  1158,
  1159,
  1160,
  1161,
  1162,
  1163,
  1164,
  1165,
  1166,
  1167,
  1168,
  1169,
  1170,
  1171,
  1172,
  1173,
  1174,
  1175,
  1176,
  1177,
  1178,
  1179,
  1180,
  1181,
  1182,
  1183,
  1184,
  1185,
  1186,
  1187,
  1188,
  1189,
  1190,
  1191,
  1192,
  1193,
  1194,
  1195,
  1196,
  1197,
  1198,
  1199,
  1200,
  1201,
  1202,
  1203,
  1221,
  1222,
  1224,
  1225,
  1226,
  1227,
  1228,
  1229,
  1230,
  1231,
  1232,
  1233,
  1234,
  1235,
  1236,
  1237,
  1238,
  1239,
  1240,
  1241,
  1242,
  1243,
  1244,
  1245,
  1246,
  1247,
  1248,
  1249,
  1269,
  1270,
  1271,
  1272,
  1273,
  1274,
  1275,
  1276,
  1277,
  1278,
  1279,
  1281,
  1282,
  1283,
  1284,
  1285,
  1286,
  1287,
  1288,
  1289,
  1290,
  1291,
  1292,
  1293,
  1295,
  1296,
  1297,
  1298,
  1299,
  1300,
  1301,
  1304,
  1354,
  1355,
  1356,
  1357,
  1358,
  1359,
  1360,
  1361,
  1362,
  1363,
  1364,
  1365,
  1366,
  1368,
  1369,
  1371,
  1383,
  1384,
  1385,
  1386,
  1387,
  1388,
  1389,
  1390,
]

export const MEDIA_TYPES = ['TV', 'RADIO', 'WEB_RADIO']

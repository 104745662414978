import React from 'react'
import PropTypes from 'prop-types'

export default class AvatarComponent extends React.Component<any, any> {
  static contextTypes = {
    URLService: PropTypes.object,
  }

  render() {
    const { name } = this.props
    const { URLService } = this.context
    // make sure, that API returns valid name. :-)
    const label = name.trim().replace(/\s\s+/g, ' ')

    const previewStyles = {
      height: 57,
      width: 57,
    }

    const placeholder = {
      title: label
        ? label
            .split(' ')
            .slice(0, 2)
            .map((x) => x[0].toUpperCase())
            .join('')
        : '',
      background: label.length % 5,
    }

    return (
      <React.Fragment>
        <div className={`avatar mod-placeholder-${placeholder.background}`}>{placeholder.title}</div>
      </React.Fragment>
    )
  }
}

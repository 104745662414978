// @ts-nocheck
import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'

import ArticleListingReactComponent from '../components/articles/listing'
import BaseArticle from './articles/BaseArticle'
import buildActionCreators from '../helpers/buildActionCreators'
import LoadMore from './search/LoadMore'
import { articleConnect } from './Articles'
import { LISTING_STYLES } from '../opoint/settings'
import { articleId, getActiveArticleNode, getIdenticalArticle } from '../opoint/articles/index'
import * as ActionTypes from '../constants/actionTypes'

const ArticleListingReactComponentExtended = BaseArticle(ArticleListingReactComponent)

class ArticleListingComponent extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.sidebarContainer = null
    this.articlesRefs = {}
  }

  componentDidUpdate(prevProps) {
    const { articles, active, articleListingStyle } = this.props
    if (
      articleListingStyle &&
      articleListingStyle !== LISTING_STYLES.SEARCH_ENGINE &&
      prevProps.active.index !== active.index
    ) {
      this.adjustScroll(articles, active.index)
    }
  }

  onClickHandler = (index) => () =>
    index !== this.props.active.index && this.props.setActiveArticle({ index, source: 'click' })

  adjustScroll = (articles, active) => {
    if (articles[active] === undefined) {
      return
    }
    const scrollParent = this.sidebarContainer.parentElement
    const activeArticle = getActiveArticleNode(this.articlesRefs, articles[active])
    const activePadding = 20

    if (activeArticle.getBoundingClientRect().bottom > scrollParent.getBoundingClientRect().bottom) {
      scrollParent.scrollTop =
        (active + 1) * activeArticle.getBoundingClientRect().height -
        scrollParent.getBoundingClientRect().height +
        activePadding
    }

    if (activeArticle.getBoundingClientRect().top < scrollParent.getBoundingClientRect().top) {
      scrollParent.scrollTop = active * activeArticle.getBoundingClientRect().height - activePadding
    }
  }

  render() {
    const {
      active,
      articleListingStyle,
      articles,
      articleSource,
      checkedArticles,
      identicalArticles,
      noArticlesFound,
      onCheckedToggle,
      onEditArticleModalOpen,
      shareArticle,
      shouldHideTags,
      tagsFn,
      visibleTrashTagsFn,
    } = this.props
    // TODO: change markup for time based grouping, only one group is displayed now
    // TODO: scroll container should be in this component, not parent component,
    // refactor when redesigning/rewriting render components

    if (articleListingStyle && articleListingStyle === LISTING_STYLES.SEARCH_ENGINE) {
      return null
    }

    if (noArticlesFound) {
      return null
    }

    return (
      <div className="col-md-3 op-content-right_wrapper">
        <div
          ref={(ref) => {
            this.sidebarContainer = ref
          }}
        >
          <div
            className={classNames('col-md-12 op-content-right-section_wrapper', {
              'some-checkbox-selected': Object.keys(checkedArticles).length > 0,
            })}
          >
            <div>
              {articles.map((article, index) => (
                <ArticleListingReactComponentExtended
                  active={active.index === index}
                  activeArticle={getIdenticalArticle(identicalArticles, article)}
                  article={article}
                  articleSource={articleSource}
                  checkedArticles={checkedArticles}
                  identical={identicalArticles[articleId(article)]}
                  index={index}
                  key={articleId(article)}
                  onCheckedToggle={onCheckedToggle}
                  onClickHandler={this.onClickHandler(index)}
                  onEditArticleModalOpen={onEditArticleModalOpen}
                  ref={(ref) => {
                    this.articlesRefs[articleId(article)] = ref
                  }}
                  shareArticle={shareArticle}
                  shouldHideTags={shouldHideTags}
                  tagsFn={tagsFn}
                  visibleTrashTagsFn={visibleTrashTagsFn}
                  imageSize={
                    articleListingStyle === LISTING_STYLES.ARCHIVE_LEFT ||
                    articleListingStyle === LISTING_STYLES.ARCHIVE_RIGHT ||
                    articleListingStyle === LISTING_STYLES.INTRO
                      ? 'big'
                      : 'normal'
                  }
                  showShortSummary={
                    articleListingStyle === LISTING_STYLES.ARCHIVE_LEFT ||
                    articleListingStyle === LISTING_STYLES.ARCHIVE_RIGHT ||
                    articleListingStyle === LISTING_STYLES.INTRO
                  }
                  imagePosition={articleListingStyle === LISTING_STYLES.ARCHIVE_LEFT ? 'right' : undefined}
                />
              ))}
            </div>
          </div>
          <div className="op-content-right-info">
            <LoadMore />
          </div>
        </div>
      </div>
    )
  }
}

const ArticleListing = connect(
  articleConnect,
  buildActionCreators({
    setActiveArticle: ActionTypes.SET_ACTIVE_ARTICLE,
    fetchArticles: ActionTypes.FETCH_ARTICLES,
    onCheckedToggle: ActionTypes.CHECK_ARTICLE_TOGGLE,
    onEditArticleModalOpen: ActionTypes.EDIT_ARTICLE_MODAL_OPEN,
    shareArticle: ActionTypes.SHARE_ARTICLE_MODAL_OPEN,
  }),
)(ArticleListingComponent)

export default ArticleListing

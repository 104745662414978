import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'

import Checkbox from './Checkbox'
import contactsForm from '../contacts/ContactsInput'
import Translate from '../../Translate'

/*
  todo Flow missing; wrong abstraction see comment in notifications/Modal.js.
  We have to refactor this component and remove the buttons, this should be only
  the from without connection to rest of Modal
 */
class ShareContentInput extends React.PureComponent<any> {
  static getDefaultProps = {
    simpleForm: false,
  }

  static contextTypes = {
    i18n: PropTypes.object,
  }

  constructor(props) {
    super(props)
    // @ts-ignore
    this.ShareArticleContactsInput = contactsForm(props.formName)
  }

  cancelShare = () => {
    const { updateStep } = this.props
    updateStep({ stepNumber: 1 })
  }

  sendReport = () => {
    const { updateStep, sendReport } = this.props
    updateStep({ stepNumber: 3 })
    sendReport()
  }

  render() {
    const {
      shareMessage,
      isAttachmentChecked,
      toggleAttachment,
      handleMessageChange,
      stepNumber,
      simpleForm,
      styles,
      suggestionWrapperStyle,
    } = this.props
    const { i18n } = this.context
    return (
      <div className={classNames('modal-body-actions-action', { 'is-active': stepNumber === 2 })} style={styles}>
        <div className="util_center-parent mod-full-all">
          <div className="util_center-child">
            <div className="modal-body-actions-action_wrapper">
              <div className="op-form-group">
                <label htmlFor="to">
                  <Translate i18nString="To" />
                </label>
                {/*
                // @ts-ignore */}
                <this.ShareArticleContactsInput id="to" suggestionWrapperStyle={suggestionWrapperStyle} />
              </div>
              <div className="op-form-group">
                <label htmlFor="message">
                  <Translate i18nString="Subject" />
                </label>
                <input
                  id="message"
                  type="text"
                  placeholder={i18n.t('Message to recipients')}
                  required
                  className="op-input"
                  value={shareMessage}
                  onChange={handleMessageChange}
                />
              </div>
              <div className="send-as-attachment">
                <Checkbox
                  id="sendAsAttachment"
                  name="sendAsAttachment"
                  isChecked={isAttachmentChecked}
                  onChangeHandler={toggleAttachment}
                >
                  <Translate i18nString="Send as an attachment" />
                </Checkbox>
              </div>
              {!simpleForm && (
                <div className="modal-body-actions-action share-button-group">
                  <button className="op-button mod-login" onClick={this.cancelShare} tabIndex={5}>
                    <i className="op-icon-arrow-left" />
                  </button>

                  <button className="op-button mod-login" onClick={this.sendReport} tabIndex={6}>
                    <Translate i18nString="Send" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default translate([], { wait: true })(ShareContentInput)

import { changeImageUrlBase } from './common'
import type { Article, ArticleScreenshot } from '../types/article'

export const articleWithCorrectedImagePaths = (article: Article) => ({
  ...article,
  screenshots: article.screenshots?.map((screenshot: ArticleScreenshot) => ({
    ...screenshot,
    text: changeImageUrlBase(screenshot.text),
  })),
})

export const getSocialMediaLogo = (source: string) => {
  const sourceTransformed = source.toLowerCase()
  switch (sourceTransformed) {
    case 'twitter':
    case 'youtube':
    case 'facebook':
    case 'instagram':
      return `/images/${sourceTransformed}.svg`
    default:
      return null
  }
}

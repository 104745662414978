import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { translate } from 'react-i18next'

import buildActionCreators from '../../helpers/buildActionCreators'
import EntityHeader from '../common/EntityHeader'
import entityFilter from '../common/entityFilter'
import FilterMessage from '../common/FilterMessage'
import { getUISetting } from '../../selectors/settingsSelectors'
import { getFeeds, getActiveFeedId } from '../../selectors/feedsSelector'
import { MODULE } from '../../opoint/settings/index'
import * as ActionTypes from '../../constants/actionTypes'
import type { Feed } from '../../opoint/flow'

type FeedListingProps = {
  activeFeedId: string
  displayImportant: string
  expanded?: boolean
  filterExpression?: string
  list: Array<Feed>
  numberOfFiltered: number
  onNewFeedClicked: () => {}
  openFeedPage: (id: number) => {}
  toggleCategory: () => {}
  setActiveFeed: (param: any) => {}
}

class FeedListingComponent extends React.Component<FeedListingProps> {
  onOpenPageClickHandler = (id) => () => {
    const { openFeedPage, setActiveFeed } = this.props
    openFeedPage(id)
    setActiveFeed({ id })
  }

  onNewFeedClickHandler = () => {
    const { setActiveFeed, onNewFeedClicked } = this.props
    setActiveFeed({})
    onNewFeedClicked()
  }

  isExpanded = () => {
    const { expanded, filterExpression } = this.props
    return expanded || !!filterExpression
  }

  render() {
    const {
      activeFeedId,
      filterExpression,
      list: feeds,
      numberOfFiltered,
      // @ts-ignore
      resetFilter,
      // @ts-ignore
      i18n,
      toggleCategory,
    } = this.props

    // If there are no feeds, we don't display listing in the left column
    if (!feeds.length) {
      return <div />
    }

    return (
      <div className="col-md-12">
        <div className="row">
          <div className="entity">
            <EntityHeader
              module={MODULE.FEED}
              onNewButtonClicked={this.onNewFeedClickHandler}
              newEntityTitle={i18n.t('Create new feed')}
              {...this.props}
              isExpanded={this.isExpanded()}
            />
            <div
              className={classNames('entity__body', 'entity__body--marginBottom2', {
                'entity_body--hidden': !this.isExpanded(),
              })}
            >
              {feeds && !!feeds.length && (
                <ul>
                  {feeds.map((feed) => (
                    <li key={feed.id} title={feed.name}>
                      <div className="radio-container">
                        <div>
                          <div className="op-radio left-column-radio">
                            <input type="radio" id={feed.id} checked={feed.id === activeFeedId} readOnly />
                            <label htmlFor={feed.id} onClick={this.onOpenPageClickHandler(feed.id)} />
                          </div>
                          <span className="radio-desc" onClick={this.onOpenPageClickHandler(feed.id)}>
                            {feed.name}
                          </span>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
              <FilterMessage
                filterExpression={filterExpression}
                listLength={feeds.length}
                listName="feeds"
                numberOfFiltered={numberOfFiltered}
                resetFilter={resetFilter}
                toggleCategory={toggleCategory}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const ConnectedFeedListing = connect(
  (state, props) => ({
    list: getFeeds(state),
    // @ts-ignore
    expanded: getUISetting(props.category)(state),
    activeFeedId: getActiveFeedId(state),
  }),
  {
    openFeedPage: (feedId) => push(`/feeds/${feedId}`),
    ...buildActionCreators({
      onNewFeedClicked: ActionTypes.GO_TO_NEW_FEED,
      setActiveFeed: ActionTypes.FEED_SET_ACTIVE,
      toggleCategory: ActionTypes.TOGGLE_UI_SETTING,
      resetFilter: ActionTypes.ENTITY_FILTER_CHANGED,
    }),
  },
)(entityFilter(FeedListingComponent))

const FeedListing = translate([], { wait: true })(ConnectedFeedListing)

export default FeedListing

import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import translate from 'react-i18next/dist/es/translate'

import ColorSelector from '../../common/ColorSelector'
import ModulePermissions from '../../common/ModulePermissions'
import Translate from '../../../Translate'
import ToggleCheckbox from '../../common/ToggleCheckbox'
import { MODULE, MODULE_OPTION } from '../../../opoint/settings/index'

export const wrappedColorSelector = (props) => (
  <ColorSelector onClick={props.input.onChange} value={props.input.value} />
)

const storedSearch = ({ i18n }) => (
  <Field
    name="stored_search" // not in camelCase so we are consistent with backend
    component={ToggleCheckbox}
    type="text"
    placeholder={i18n.t('Store search')}
    className="op-input"
  />
)
const StoredSearch = translate([], { wait: true })(storedSearch)

export default class ManageTagForm extends React.PureComponent<any> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  render() {
    const { buttons, hasStoredSearchPermission, storedSearchId } = this.props
    const { i18n } = this.context

    return (
      <div className="op-tag-edit">
        <div className="row">
          <div className="col-md-12">
            <div className="col-md-6">
              <div className="row">
                <label htmlFor="name">
                  <Translate i18nString="Name" />
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <Field component="input" id="name" name="name" placeholder={i18n.t('Tag name')} tabIndex={1} />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="col-md-6">
              <div className="row">
                <label htmlFor="interval">
                  <Translate i18nString="Key shortcut" />
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <Field component="input" id="combo" name="combo" placeholder={i18n.t('Key Shortcut')} tabIndex={2} />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="col-md-6">
              <div className="row">
                <label htmlFor="type">
                  <Translate i18nString="Type" />
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <Field component="select" id="type" name="type" tabIndex={4}>
                  <option value={1}>{i18n.t('Keyword')}</option>
                  <option value={2}>{i18n.t('Tendency')}</option>
                </Field>
              </div>
            </div>
          </div>
        </div>
        {hasStoredSearchPermission && (
          <div className="row">
            <div className="col-md-12">
              <div className="col-md-6">
                <div className="row">
                  <label htmlFor="type">
                    <Translate i18nString="Store search" />
                    {
                      // If it is null, show just store search, otherwise show ID
                      storedSearchId && ` (${storedSearchId})`
                    }
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <StoredSearch />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-12">
            <div className="col-md-6">
              <div className="row">
                <label htmlFor="color">
                  <Translate i18nString="Color" />
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <Field component={wrappedColorSelector} id="color" name="color" tabIndex="3" />
              </div>
            </div>
          </div>
        </div>

        <ModulePermissions module={MODULE.TAG} permissions={[MODULE_OPTION.ON]}>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="button-wrapper">
                  {buttons.map((button, key) => (
                    <button className="op-button" key={key} onClick={button.onClick} type="button">
                      {button.placeholder}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </ModulePermissions>
      </div>
    )
  }
}

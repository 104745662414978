// @ts-nocheck

import React from 'react'
import PropTypes from 'prop-types'

import ArticleImagesPreview from './ArticleImagesPreview'
import OpointLightBox from './OpointLightBox'
import type { Article } from '../../../opoint/flow'

type Props = {
  article: Article
  editable: boolean
}

type State = {
  isOpen?: number
}

export default class ArticleImages extends React.Component<Props, State> {
  static propTypes = {
    article: PropTypes.object.isRequired,
    editable: PropTypes.bool.isRequired,
  }

  static contextTypes = {
    getString: PropTypes.func,
    URLService: PropTypes.object,
  }

  constructor(props: Props, context: Object) {
    super(props, context)
    this.state = {
      isOpen: undefined,
    }

    const { article } = this.props
    const { URLService } = context
    this.images = article.articleimages.articleimage.map((image) => ({
      src: URLService.getFullImageUrl(image.url),
    }))
  }

  onClose = () => this.setState({ isOpen: undefined })

  onOpenImagePreview = (index: number) => this.setState({ isOpen: index })

  goToNext = () => this.setState({ isOpen: this.state.isOpen + 1 })

  goToPrevious = () => this.setState({ isOpen: this.state.isOpen - 1 })

  render() {
    const { isOpen } = this.state

    return (
      <div>
        {!isNaN(isOpen) && (
          <OpointLightBox
            images={this.images}
            showImageCount={false}
            enableKeyboardInput
            isOpen={isOpen !== undefined}
            currentImage={isOpen}
            onClose={this.onClose}
            onClickPrev={this.goToPrevious}
            onClickNext={this.goToNext}
          />
        )}
        <ArticleImagesPreview {...this.props} onOpenImagePraview={this.onOpenImagePreview} />
      </div>
    )
  }
}

import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import buildActionCreators from '../../../../helpers/buildActionCreators'
import * as ActionTypes from '../../../../constants/actionTypes'
import { getGroupingEnabled } from '../../../../selectors/settingsSelectors'
import Translate from '../../../../Translate'

class GroupingButton extends React.Component<any, any> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  toggleGrouping = () => {
    const { hidePopover, toggleGrouping, isFromPopover } = this.props
    toggleGrouping()
    isFromPopover && hidePopover()
  }

  render() {
    const { isFromPopover, isEnabled } = this.props
    const icon = <i className="op-icon-link mod_bigger" />

    const title = isEnabled ? <Translate i18nString="Disable grouping" /> : <Translate i18nString="Enable grouping" />

    return isFromPopover ? (
      <li className="actionLine-popover-child">
        <a onClick={this.toggleGrouping}>
          {icon}
          {title}
        </a>
      </li>
    ) : (
      <button className="op-button-toolbar" onClick={this.toggleGrouping}>
        {title}
        {icon}
      </button>
    )
  }
}

export default connect(
  (state) => ({
    isEnabled: getGroupingEnabled(state),
  }),
  buildActionCreators({
    toggleGrouping: ActionTypes.SETTINGS_TOGGLE_GROUPING,
  }),
)(GroupingButton)

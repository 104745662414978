import React from 'react'
import classNames from 'classnames'

import { TAG_COLORS } from '../../opoint/tags/index'

type ColorSelectorColor = {
  id: string
  code: string
}

type ColorCircleProps = {
  color: string
  isActive: boolean
  onClick: (string) => void
  value: string
}

type ColorSelectorProps = {
  colors: Array<ColorSelectorColor>
  onClick: Function
  value: number
}

const ColorCircle = ({ isActive, color, onClick, value }: ColorCircleProps) => (
  <div
    // @ts-ignore
    onClick={onClick(value)}
    className={classNames('colorCircle', { _active: isActive })}
    style={{ backgroundColor: color }}
  />
)

export default class ColorSelector extends React.PureComponent<ColorSelectorProps> {
  static defaultProps = {
    colors: TAG_COLORS,
    value: 'red',
  }

  onClickHandler = (value: string) => {
    return () => this.props.onClick(value)
  }

  render() {
    const { colors, value } = this.props

    return (
      <div className="colorSelector">
        {colors.map(({ id, code }, key) => (
          // @ts-ignore
          <ColorCircle key={key} isActive={id === value} color={code} onClick={this.onClickHandler} value={id} />
        ))}
      </div>
    )
  }
}

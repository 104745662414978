import React from 'react'

import { tagStyle } from './common'
import { shouldDisplayTagWeight, getTaggedIdenticalArticlesCount } from '../../opoint/tags/index'
import type { TagComponentProps } from '../../opoint/flow'

export default class TrashTag extends React.PureComponent<TagComponentProps> {
  onIncrementClickHandler = () => {
    const { article, tag, incrementTagWeightFn } = this.props
    incrementTagWeightFn(tag, article)
  }

  onSetClickHandler = () => {
    const { article, tag, setTagFn } = this.props
    setTagFn(tag, article)
  }

  setActiveTagHandler = () => {
    // @ts-ignore
    const { tag, setActiveTag, showIdenticalArticlesPopup } = this.props
    setActiveTag({ tag })
    showIdenticalArticlesPopup(this.taggedIdentArticlesCnt)
  }

  taggedIdentArticlesCnt = {}

  render() {
    const { article, checked, tag, hideWeightControlls, lastUsedTag } = this.props

    return (
      // @ts-ignore
      <div title={tag.name} className={`op-tag ${tagStyle(tag, article, checked, lastUsedTag)}`}>
        <span
          className="op-tag__tag-name"
          onClick={this.onSetClickHandler}
          dangerouslySetInnerHTML={{ __html: tag.name }}
        />

        <span
          className="op-tag-identicals"
          onClick={this.setActiveTagHandler}
          ref={(ref) => {
            this.taggedIdentArticlesCnt = ref
          }}
        >
          {getTaggedIdenticalArticlesCount(article, tag)}
        </span>

        {shouldDisplayTagWeight(article, tag.id) && !hideWeightControlls && (
          <span className="op-tag-button" onClick={this.onIncrementClickHandler}>
            {article && article.tags[tag.id] && article.tags[tag.id].weight}
          </span>
        )}
      </div>
    )
  }
}

// @ts-nocheck
import React from 'react'
import { connect } from 'react-redux'

import TimeSchedule from './TimeSchedule'
import { getAlertTimeConfiguration } from '../../../selectors/alertsSelectors'

type Props = {
  timeConfiguration: Object
  whenAlert: number
}

const TimeScheduleEditAlertComponent = ({ timeConfiguration, whenAlert }: Props) =>
  !timeConfiguration || !Object.keys(timeConfiguration).length ? null : (
    <TimeSchedule timeConfiguration={timeConfiguration} whenAlert={whenAlert} />
  )

const TimeScheduleEditAlert = connect((state) => ({
  timeConfiguration: getAlertTimeConfiguration(state),
  whenAlert:
    state.form.alert && state.form.alert.edit && state.form.alert.edit.values
      ? state.form.alert.edit.values.time
      : undefined,
}))(TimeScheduleEditAlertComponent)

export default TimeScheduleEditAlert

import { createSelector } from 'reselect'

import { getFeeds as getState, getForm } from './rootSelectors'

export const getFeeds = createSelector(getState, (feeds) => feeds.list)

export const getEditedFeed = createSelector(getState, (feeds) => feeds.activeFeed)

export const getFeedFormData = createSelector(getForm, (data) => data.feedEditor.values)

export const getDeleteFeedInProgress = createSelector(getState, (feeds) => feeds.deleteInProgress)

export const getFeedById = (feedId: string) =>
  createSelector(getFeeds, (feeds) => feeds.find(({ id }) => id === feedId))

export const getFeedUrlById = (feedId: string) => createSelector(getFeedById(feedId), (feed) => feed && feed.url)

export const getActiveFeedId = createSelector(
  getEditedFeed,
  // TODO check if it is necessary to return null @dmytro
  (activeFeed) => (activeFeed ? activeFeed.id : null),
)

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'

import buildActionCreators from '../../../helpers/buildActionCreators'
import MultiSelectRule from '../MultiSelect/MultiSelectRule'
import { getAlertTimeConfiguration } from '../../../selectors/alertsSelectors'
import { DAY_CONST } from '../../../opoint/alerts/weekdays'
import * as ActionTypes from '../../../constants/actionTypes'

type AdvancedFormProps = {
  deleteScheduleEntry: (id: number) => void
  initScheduleEntry: (option: string) => void
  timeConfiguration: Object
}

type AdvancedFormState = {
  option: string
  options: Object
}

class AdvancedForm extends React.Component<AdvancedFormProps, AdvancedFormState> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  constructor(props) {
    super(props)

    /*
     * TODO: This is temporary, for picker development
     * Needs to be somehow unified with 'day' constants defined in Alerts index
     */
    this.state = {
      option: 'monday',
      options: {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true,
        alldays: true,
        weekdays: true,
        weekends: true,
      },
    }

    this.onOptionChange = this.onOptionChange.bind(this)
    this.onSchedulerAdd = this.onSchedulerAdd.bind(this)
  }

  onSchedulerAdd = () => {
    const { initScheduleEntry } = this.props
    this.setState(
      (state) => ({
        options: {
          ...state.options,
          [state.option]: false,
        },
      }),
      () => {
        initScheduleEntry(this.state.option)
        const toBeSelected = Object.entries(this.state.options).filter(([_, value]) => value)
        if (toBeSelected) {
          this.setState((state) => ({
            option: toBeSelected[0][0],
          }))
        }
      },
    )
  }

  onOptionChange = (event) => {
    this.setState({ option: event.target.value })
  }

  deleteRule = (id) => {
    const { deleteScheduleEntry } = this.props

    this.setState((state) => ({
      options: {
        ...state.options,
        [id]: true,
      },
    }))
    deleteScheduleEntry(id)
  }

  render() {
    const { timeConfiguration } = this.props
    const { option, options } = this.state
    const { i18n } = this.context

    return (
      <div>
        {Object.entries(timeConfiguration).map(([key, rule]) => (
          <MultiSelectRule key={key} id={key} deleteRule={this.deleteRule} />
        ))}

        <div className="alert-schedule__rule">
          <div className="alert-schedule__action">
            <button type="button" className="alert-schedule__button" onClick={this.onSchedulerAdd}>
              <i className="op-icon-plus" />
            </button>
          </div>
          <div className="alert-schedule__content">
            <select className="op-select mod-profile-editor full-width" onChange={this.onOptionChange} value={option}>
              {Object.entries(options)
                .filter(([_, value]) => value)
                .map(([value, _]) => (
                  <option key={value} value={value}>
                    {i18n.t(DAY_CONST[value].displayName)}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>
    )
  }
}

// @ts-ignore
AdvancedForm = translate([], { wait: true })(AdvancedForm)

// @ts-ignore
AdvancedForm = connect(
  (state) => ({
    timeConfiguration: getAlertTimeConfiguration(state),
  }),
  buildActionCreators({
    initScheduleEntry: ActionTypes.INIT_SCHEDULE_ENTRY,
    deleteScheduleEntry: ActionTypes.DELETE_SCHEDULE_ENTRY,
  }),
)(AdvancedForm)

export default AdvancedForm

// @ts-nocheck
import React from 'react'
import { connect } from 'react-redux'

import AdvancedForm from './AdvancedForm'
import buildActionCreators from '../../../helpers/buildActionCreators'
import Translate from '../../../Translate'
import { WHEN_ALERT } from '../../../opoint/alerts/index'
import * as ActionTypes from '../../../constants/actionTypes'

type Props = {
  input: {
    // TODO: Find out how to integrate flowtype + constants
    onChange: (number) => void
    value: number
  }
  setInterval: (Object) => void
  deleteScheduleEntry: (string) => void
}

class WhenForm extends React.Component<Props> {
  componentWillMount() {
    const {
      input: { value },
    } = this.props

    this.updateTimeConfig(value)
  }

  onClickHandler = (timeConfig) => () => this.updateTimeConfig(timeConfig)

  updateTimeConfig(value) {
    const {
      input: { onChange },
      setInterval,
      deleteScheduleEntry,
    } = this.props

    const timeConfiguration: any = {
      day: 'weekdays',
      intervalData: [],
    }

    deleteScheduleEntry('weekdays')
    deleteScheduleEntry('alldays')

    switch (value) {
      case WHEN_ALERT.WEEKDAYS_AT_8:
        timeConfiguration.intervalData.push({
          interval: [8],
        })
        break
      case WHEN_ALERT.WEEKDAYS_AT_8_AND_2:
        timeConfiguration.intervalData.push({
          interval: [8],
        })
        timeConfiguration.intervalData.push({
          interval: [14],
        })
        break
      case WHEN_ALERT.AS_SOON_AS_POSSIBLE:
        timeConfiguration.day = 'alldays'
        timeConfiguration.intervalData.push({
          interval: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
        })
        break
      case WHEN_ALERT.ADVANCED:
        timeConfiguration.day = 'alldays'
        break
      default:
        break
    }

    onChange(value)
    setInterval(timeConfiguration)
  }

  render() {
    const {
      input: { value },
    } = this.props

    return (
      <div className="op-content-center-alerts-right-schedule form-group">
        <ul className="op-when-alert-list">
          <li className="op-when-alert-list-item" onClick={this.onClickHandler(WHEN_ALERT.WEEKDAYS_AT_8)}>
            <div className="op-checkbox_wrapper">
              <div className="op-radio">
                <input checked={value === WHEN_ALERT.WEEKDAYS_AT_8} id="schedule-radio-8am" name="time" type="radio" />
                <label htmlFor="schedule-radio-8am" />
              </div>
            </div>
            <label htmlFor="schedule-radio-8am" className="name alert-name">
              <Translate i18nString="Weekdays at 8am" />
            </label>
          </li>
          <li className="op-when-alert-list-item" onClick={this.onClickHandler(WHEN_ALERT.WEEKDAYS_AT_8_AND_2)}>
            <div className="op-checkbox_wrapper">
              <div className="op-radio">
                <input
                  checked={value === WHEN_ALERT.WEEKDAYS_AT_8_AND_2}
                  id="schedule-radio-8am-2pm"
                  name="time"
                  type="radio"
                />
                <label htmlFor="schedule-radio-8am-2pm" />
              </div>
            </div>
            <label htmlFor="schedule-radio-8am-2pm" className="name alert-name">
              <Translate i18nString="Weekdays at 8am and 2pm" />
            </label>
          </li>
          <li className="op-when-alert-list-item" onClick={this.onClickHandler(WHEN_ALERT.AS_SOON_AS_POSSIBLE)}>
            <div className="op-checkbox_wrapper">
              <div className="op-radio">
                <input
                  checked={value === WHEN_ALERT.AS_SOON_AS_POSSIBLE}
                  id="schedule-radio-continuous"
                  name="time"
                  type="radio"
                />
                <label htmlFor="schedule-radio-continuous" />
              </div>
            </div>
            <label htmlFor="schedule-radio-continuous" className="name alert-name">
              <Translate i18nString="As it happens" />
            </label>
          </li>
          <li className="op-when-alert-list-item" onClick={this.onClickHandler(WHEN_ALERT.ADVANCED)}>
            <div className="op-checkbox_wrapper">
              <div className="op-radio">
                <input type="radio" id="schedule-radio-custom" name="time" checked={value === WHEN_ALERT.ADVANCED} />
                <label htmlFor="schedule-radio-custom" />
              </div>
            </div>
            <label htmlFor="schedule-radio-custom" className="name alert-name">
              <Translate i18nString="Advanced" />
            </label>
          </li>
        </ul>
        {value === WHEN_ALERT.ADVANCED && <AdvancedForm />}
      </div>
    )
  }
}

WhenForm = connect(
  null,
  buildActionCreators({
    deleteScheduleEntry: ActionTypes.DELETE_SCHEDULE_ENTRY,
    setInterval: ActionTypes.SET_SCHEDULE_ENTRY,
  }),
)(WhenForm)

export default WhenForm

import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'

import OpointDate from '../../opoint/common/time'
import Translate from '../../Translate'
import { HISTORY_TYPE } from '../../opoint/profiles/index'
import type { ProfileHistoryFromApi, ProfileHistoryResultToShow } from '../../opoint/flow'

type ProfileHistoryProps = {
  profileHistory: ProfileHistoryFromApi
  setOldProfileVersion: (param: any) => void
  showMoreProfileHistory: () => void
  allHistoryResultsLength: number
  showMoreHistorySegment: number
  historyType: string
}

class ProfileHistory extends React.Component<ProfileHistoryProps> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  setOldVersion = ({ target: { id } }) => {
    const { setOldProfileVersion } = this.props
    const timestamp = +id
    setOldProfileVersion({ timestamp })
  }

  render() {
    const { profileHistory, allHistoryResultsLength, showMoreHistorySegment, historyType } = this.props

    const { i18n } = this.context

    let subject
    let moreButtonTitleShowMore
    let moreButtonTitleNoMore
    let hoverTitle

    switch (historyType) {
      case HISTORY_TYPE.DELETED_PROFILES:
        subject = <Translate i18nString="Deleted profiles" />
        moreButtonTitleShowMore = <Translate i18nString="Show more deleted profiles" />
        moreButtonTitleNoMore = <Translate i18nString="No more deleted profiles" />
        hoverTitle = (date: string, name: string): string =>
          `${i18n.t('Click to revert to deleted profile {{date}}', {
            date,
          })}\n${i18n.t('Deleted profile name: {{name}}', { name })}`
        break
      default:
        subject = <Translate i18nString="Current profile changes" />
        moreButtonTitleShowMore = <Translate i18nString="Show more profile history" />
        moreButtonTitleNoMore = <Translate i18nString="No more profile history" />
        hoverTitle = (date: string, name: string): string =>
          `${i18n.t('Click to revert to version saved {{date}}', { date })}\n${i18n.t(
            'Profile version name: {{name}}',
            { name },
          )}`
        break
    }
    const dateFormat = (timestamp: number): string => OpointDate.longFormat(moment.unix(timestamp))

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="op-profile-history row">
            <div className="op-profile-history__meta-line col-md-12">
              <span className="op-profile-history__meta">
                {subject}
                {`: ${profileHistory.count}`}
              </span>
            </div>
            <div className="col-md-12 op-profile-history__content-changes-wrapper">
              {profileHistory.results.map(({ name, timestamp }: ProfileHistoryResultToShow) => {
                const date = dateFormat(timestamp)
                const titlePop = hoverTitle(date, name)
                return (
                  <span
                    className="op-profile-history__content-changes"
                    key={timestamp}
                    // @ts-ignore
                    id={timestamp}
                    // @ts-ignore
                    onClick={this.setOldVersion}
                    title={titlePop}
                  >
                    <span className="op-profile-history__content-changes__date--profiles-history">
                      {historyType === HISTORY_TYPE.DELETED_PROFILES ? name : date}
                    </span>
                  </span>
                )
              })}
            </div>
          </div>
        </div>
        <div className="col-md-12 op-profile-history__load-more">
          <button
            onClick={this.props.showMoreProfileHistory}
            type="button"
            className="op-button-load-more op-button-load-more--on-white-phone"
          >
            {showMoreHistorySegment !== allHistoryResultsLength ? moreButtonTitleShowMore : moreButtonTitleNoMore}
          </button>
        </div>
      </div>
    )
  }
}

// @ts-ignore
ProfileHistory = translate([], { wait: true })(ProfileHistory)

export default ProfileHistory

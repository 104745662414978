import * as React from 'react'
import { connect } from 'react-redux'

import buildActionCreators from '../../helpers/buildActionCreators'
import Statistics from './index'
import { getRoutSearch } from '../../selectors/routingSelectors'
import { getSearchMeta, isSearchInProgress } from '../../selectors/searchSelectors'
import { isStatLoading, getShowFilteredArticles } from '../../selectors/statisticsSelectors'
import * as ActionTypes from '../../constants/actionTypes'

type Props = {
  // TODO flow
  fetchStatistics: () => void
  hideFiltered: () => void
  clearFilteredArticles: () => void
  clearFilters: () => void
  clearRequestedAspects: () => void
  clearExtendedTimeRange: () => void
  children: React.ReactElement
  routeSearch: string
  showFilteredArticles: () => void
  meta: any
  loading: boolean
}

class StatisticsWrapper extends React.PureComponent<Props> {
  componentDidMount() {
    const { fetchStatistics } = this.props
    fetchStatistics()
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.routeSearch !== prevProps.routeSearch) {
      const { fetchStatistics } = this.props
      fetchStatistics()
    }
  }

  componentWillUnmount() {
    const {
      hideFiltered,
      clearFilteredArticles,
      clearFilters,
      clearRequestedAspects,
      clearExtendedTimeRange,
    } = this.props
    hideFiltered()
    clearFilteredArticles()
    clearFilters()
    clearRequestedAspects()
    clearExtendedTimeRange()
  }

  render() {
    return (
      <div className="col-md-12 mod-full-height">
        {/* Profile editor is a sub state of articles BEGIN */}
        {this.props.children}
        {/* Profile editor is a sub state of articles END */}
        <Statistics {...this.props} />
      </div>
    )
  }
}

// @ts-ignore
StatisticsWrapper = connect(
  (state) => ({
    routeSearch: getRoutSearch(state),
    meta: getSearchMeta(state),
    loading: isStatLoading(state),
    showFilteredArticles: getShowFilteredArticles(state),
    searchInProgress: isSearchInProgress(state),
  }),
  buildActionCreators({
    fetchStatistics: ActionTypes.FETCH_STATISTICS,
    extendTimeRangeAndFetch: ActionTypes.STATISTICS_EXTEND_TIME_RANGE,
    hideFiltered: ActionTypes.STATISTICS_HIDE_FILTERED,
    clearFilteredArticles: ActionTypes.STATISTICS_CLEAR_FILTERED_ARTICLES,
    clearFilters: ActionTypes.STATISTICS_CLEAR_FILTERS,
    clearRequestedAspects: ActionTypes.STATISTICS_CLEAR_REQUESTED_ASPECTS,
    clearExtendedTimeRange: ActionTypes.STATISTICS_CLEAR_EXTENDED_TIME_RANGE,
    searchChangeDateRange: ActionTypes.SEARCH_CHANGE_DATERANGE,
  }),
  // @ts-ignore
)(StatisticsWrapper)

export default StatisticsWrapper

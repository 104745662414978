import PropTypes from 'prop-types'
import React from 'react'
import R from 'ramda'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'

import buildActionCreators from '../../helpers/buildActionCreators'
import GetStringProvider from '../../getStringProvider'
import TemplateSelector from '../templates/TemplateSelector'
import Translate from '../../Translate'
import { isAutoTranslationsOn, getAutoTranslationLanguageName } from '../../selectors/settingsSelectors'
import { getAutoTranslate } from '../../selectors/reportsSelector'
import {
  getGroupedTemplates,
  getTemplateEditName,
  getTemplatePreview,
  isPreviewModalOpen,
} from '../../selectors/templatesSelectors'
import * as ActionTypes from '../../constants/actionTypes'
import { TEMPLATE_TYPES } from '../../opoint/templates'
import type { Props as TemplateSelectorProps } from '../templates/TemplateSelector'
import Item from './ReportsSort/ReportsSortItem'

type Props = TemplateSelectorProps

class ReportsTemplate extends React.Component<any> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  changeAutoTranslate = ({ target: { checked } }) => {
    this.props.changeAutoTranslate(checked)
  }

  templateChange = ({ id, type, readonly }: { id: number; type: number; readonly: boolean }) => {
    const { updateActiveTemplate, onTemplateChange, fetchTemplate } = this.props

    updateActiveTemplate({ activeTemplate: { id, type, readonly } })
    onTemplateChange({ id })
    fetchTemplate({ id })
  }

  render() {
    const {
      activeTemplate,
      autoTranslateLanguage,
      autoTranslateOn,
      autoTranslateReport,
      closePreviewModal,
      isEditorModalOpen,
      groupedTemplates,
      isPreviewModalOpen /* eslint-disable-line no-shadow */,
      openEditorModal,
      openPreviewModal,
      templatePreview,
      deleteTemplate,
      templateEditName,
    } = this.props
    const { i18n } = this.context

    const filteredTemplates = R.omit([TEMPLATE_TYPES.RSS.toString()], groupedTemplates)

    return (
      <div className="op-modal-report-body-content">
        {activeTemplate && (
          <GetStringProvider>
            {/*
            @ts-ignore */}
            <TemplateSelector
              activeTemplate={activeTemplate}
              closePreviewModal={closePreviewModal}
              groupedTemplates={filteredTemplates}
              // @ts-ignore
              modalTitle={i18n.t('Template preview')}
              onTemplateChange={this.templateChange}
              openPreviewModal={openPreviewModal}
              isPreviewModalOpen={isPreviewModalOpen}
              previewModalContent={templatePreview}
              showPreviewButtonTitle={i18n.t('Preview')}
              showTemplateSettingsButtonTitle={i18n.t('Edit Template')}
              showDeleteButtonTitle={i18n.t('Delete Template')}
              openEditorModal={openEditorModal}
              isEditorModalOpen={isEditorModalOpen}
              deleteTemplate={deleteTemplate}
              templateEditName={templateEditName}
              isReportsTemplate
              isEditable
            />
          </GetStringProvider>
        )}
        {autoTranslateOn && (
          <div className="op-modal-report-body-item">
            <div className="checkbox__wrapper">
              <div className="op-checkbox">
                <input
                  id="autoTranslateReport"
                  name="autoTranslateReport"
                  type="checkbox"
                  onChange={this.changeAutoTranslate}
                  checked={autoTranslateReport}
                />
                <label htmlFor="autoTranslateReport" />
              </div>
            </div>
            <label className="name" htmlFor="autoTranslateReport">
              <Translate
                i18nString="Automatically translate articles to {{lang}}"
                context={{ lang: autoTranslateLanguage }}
              />
            </label>
          </div>
        )}
      </div>
    )
  }
}

// @ts-ignore
ReportsTemplate = translate([], { wait: true })(ReportsTemplate)

// @ts-ignore
export default ReportsTemplate = connect(
  (state) => ({
    autoTranslateLanguage: getAutoTranslationLanguageName(state),
    autoTranslateOn: isAutoTranslationsOn(state), // global settings
    // @ts-ignore
    autoTranslateReport: getAutoTranslate(state), // setting for this report
    groupedTemplates: getGroupedTemplates(state),
    isPreviewModalOpen: isPreviewModalOpen(state),
    templatePreview: getTemplatePreview(state),
    templateEditName: getTemplateEditName(state),
  }),
  buildActionCreators({
    changeAutoTranslate: ActionTypes.REPORTS_CHANGE_AUTO_TRANSLATE,
    deleteTemplate: ActionTypes.TEMPLATE_DELETE,
    closePreviewModal: ActionTypes.TEMPLATE_PREVIEW_MODAL_CLOSE,
    openPreviewModal: ActionTypes.TEMPLATE_PREVIEW_MODAL_OPEN,
    openEditorModal: ActionTypes.TEMPLATE_EDITOR_FORM_MODAL_OPEN,
  }),
)(ReportsTemplate)

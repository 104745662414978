// @ts-nocheck
import Rx from 'rxjs'
import R from 'ramda'

import buildAction from '../helpers/buildAction'
import { getWatchQueries } from '../selectors/watchIndexesSelectors'
import { isProfileSearch } from '../opoint/search/index'
import { logOutOnExpiredToken, serverIsDown } from './configureEpics'
import { updateWatchIndexes } from '../opoint/watchIndex'
import * as ActionTypes from '../constants/actionTypes'

export const updateWatchIndexesEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.UPDATE_WATCH_INDEXES).switchMap(({ payload: { watchId } }) => {
    const state = getState()

    const watchQueries = Object.keys(getWatchQueries(state))

    const watchIds = watchId && !watchQueries.includes(watchId) ? R.append(watchId, watchQueries) : watchQueries

    if (!watchIds.length) {
      return Rx.Observable.of()
    }

    return Rx.Observable.fromPromise(updateWatchIndexes(watchIds))

      .switchMap((watchQueries) =>
        Rx.Observable.of(buildAction(ActionTypes.UPDATE_WATCH_INDEXES_SUCCESS, { watchQueries })),
      )
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.UPDATE_WATCH_INDEXES_FAILURE)))
      .takeUntil(action$.ofType(ActionTypes.CLEAR_ARTICLES))
  })

const addWatchIndexesEpic = (action$: any) =>
  action$.ofType(ActionTypes.FETCH_ARTICLES_SUCCESS).switchMap(
    ({
      payload: {
        response: {
          searchresult: { watch_id: watchId },
        },
        searchItem,
      },
    }) => {
      // If search for some reason didn't return watch ID, don't update the store
      if (!watchId) {
        return Rx.Observable.of()
      }
      const profileId = isProfileSearch([searchItem])
      // We update watch ID as a string so we have consistent data types
      // for watch ids throughout the store
      const watchIdString = watchId.toString()
      return profileId
        ? Rx.Observable.of(
            buildAction(ActionTypes.UPDATE_CURRENT_WATCH_ID, { watchId: watchIdString }),
            buildAction(ActionTypes.UPDATE_WATCH_INDEXES_MAPPING, { profileId, watchId: watchIdString }),
          )
        : Rx.Observable.of(buildAction(ActionTypes.UPDATE_CURRENT_WATCH_ID, { watchId: watchIdString }))
    },
  )

export default [addWatchIndexesEpic, updateWatchIndexesEpic]

import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import R from 'ramda'

import ArticleSourceLineSource from './ArticleSourceLineSource'
import ArticleSourceLine from './ArticleSourceLine'
import ArticleTag from '../../tags/ArticleTag'
import buildActionCreators from '../../../helpers/buildActionCreators'
import OpointDate from '../../../opoint/common/time'
import Translate from '../../../Translate'
import { ArticleSourceLineDate } from './ArticleSourceLineDate'
import { articleId, TYPE_PREVIEW, TYPE_LISTING } from '../../../opoint/articles/index'
import * as ActionTypes from '../../../constants/actionTypes'
import { TAG_TYPES } from '../../../opoint/tags/index'

class IdenticalArticles extends React.Component<any, any> {
  static propTypes = {
    // dateFormat: PropTypes.oneOf(['listing', 'preview']),
    article: PropTypes.object.isRequired,
    activeArticle: PropTypes.object.isRequired,
    setActiveIdentical: PropTypes.func.isRequired,
    initiallyShown: PropTypes.number.isRequired,
    autoExpand: PropTypes.bool,
    type: PropTypes.oneOf([TYPE_PREVIEW, TYPE_LISTING]).isRequired,
  }

  static contextTypes = {
    getString: PropTypes.func,
    getPlural: PropTypes.func,
  }

  static defaultProps = {
    autoExpand: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      identicalShow: this.props.autoExpand,
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      articleId(this.props.activeArticle) !== articleId(nextProps.activeArticle) ||
      this.state.identicalShow !== nextState.identicalShow ||
      !R.equals(this.props.lastUsedTag, nextProps.lastUsedTag) ||
      !R.equals(R.path(['originalArticle', 'tags'], nextProps), R.path(['originalArticle', 'tags'], this.props))
    )
  }

  onClick = () => {
    this.setState({ identicalShow: !this.state.identicalShow })
  }

  getSim = (identicalArticle) => {
    const { activeArticle } = this.props

    if (activeArticle === identicalArticle) {
      return false
    }

    if (!activeArticle.almost_identical) {
      return (
        <span>
          <Translate i18nString="Identical" />
        </span>
      )
    }

    const almostIdentical = activeArticle.almost_identical.filter(
      (data) => data.id_article === identicalArticle.id_article && data.id_site === identicalArticle.id_site,
    )

    if (!almostIdentical.length) {
      return (
        <span>
          <Translate i18nString="Identical" />
        </span>
      )
    }

    return (
      <span>
        {almostIdentical[0].sim}
        %
        <Translate i18nString="similar" />
      </span>
    )
  }

  // @ts-ignore
  fullDate = (date) => OpointDate.format(date.unix_timestamp * 1000, 'FULL_DATE')

  clickOnIdenticalArticle = (article, identicalArticle, index) => () => {
    if (this.props.activeArticle === identicalArticle && !this.props.autoExpand) {
      this.setState({ identicalShow: !this.state.identicalShow })
    } else {
      this.props.setActiveIdentical({ article, index })
    }
  }

  toggleIdenticalArticle = (tag, article) => {
    const { toggleIdentArticleTag, originalArticle } = this.props

    toggleIdentArticleTag({ tag, article, originalArticle })
    this.forceUpdate()
  }

  decrementIdenticalArticle = (tag, article) => {
    const { decrementIdentArticleTag, originalArticle } = this.props

    decrementIdentArticleTag({ tag, article, originalArticle })
    this.forceUpdate()
  }

  incrementIdenticalArticle = (tag, article) => {
    const { incrementIdentArticleTag, originalArticle } = this.props

    incrementIdentArticleTag({ tag, article, originalArticle })
    this.forceUpdate()
  }

  render() {
    const {
      article,
      activeArticle,
      type,
      // originalArticle,
      originalArticle: { identical_documents: { document: identicalDocuments = [] } = {} } = {},
      initiallyShown,
      lastUsedTag,
      showActiveTag,
    } = this.props

    const identicalCount = identicalDocuments.length
    // no identical article
    if (identicalCount <= 0) {
      return (
        <div className="identical-articles">
          <span className="identical-articles  identical-articles--no-similar">
            <div className="identical-articles__article  is-active">
              <ArticleSourceLine
                article={activeArticle}
                dateFormat="VERY_SHORT"
                sourceIsLink={false}
                badge={false}
                relative
              />
            </div>
          </span>
        </div>
      )
    }

    const articles = identicalDocuments
    const identicalHiddenCount = identicalCount - initiallyShown
    // const isArticldTaggedByActiveTag = lastUsedTag && originalArticle.tags[lastUsedTag.id]

    if (this.state.identicalShow) {
      return (
        <div
          className={classNames('identical-articles  is-show', {
            'identical-articles--listing': type === TYPE_LISTING,
            'identical-articles--preview': type === TYPE_PREVIEW,
          })}
        >
          <ul className="identical-articles__list">
            {articles.map((identicalArticle, index) => {
              let isActive = false
              // an active one is the one we get from activeIdenticalArticle field
              // or the first one if it's undefined
              if (identicalArticle === activeArticle || (activeArticle === undefined && index === 0)) {
                isActive = true
              }

              const similarity = this.getSim(identicalArticle)

              return (
                <li
                  key={articleId(identicalArticle)}
                  className={classNames('identical-articles__article', { 'is-active': isActive })}
                  title={`${identicalArticle.first_source.name} - ${this.fullDate(identicalArticle)}`}
                >
                  <span
                    className="cursor-pointer identical-articles__article__article-summary"
                    onClick={this.clickOnIdenticalArticle(article, identicalArticle, index)}
                  >
                    <ArticleSourceLineDate
                      article={identicalArticle === activeArticle ? activeArticle : identicalArticle}
                      // @ts-ignore
                      baseDate={identicalArticle === activeArticle ? null : activeArticle.unix_timestamp * 1000}
                      showSign={identicalArticle !== activeArticle}
                      dateFormat="VERY_SHORT"
                      type={type}
                      relative
                    />
                    <ArticleSourceLineSource
                      article={identicalArticle === activeArticle ? activeArticle : identicalArticle}
                      badge={type === TYPE_PREVIEW}
                    />
                    {type === TYPE_PREVIEW && similarity && (
                      <div className="source-line__item source-line__item--similarity">{similarity}</div>
                    )}
                  </span>
                  {lastUsedTag && lastUsedTag.type === TAG_TYPES.KEYWORD && showActiveTag && (
                    // isArticldTaggedByActiveTag &&
                    <span className="identical-articles__article__active-tag">
                      <ArticleTag
                        article={identicalArticle}
                        tag={lastUsedTag}
                        setTagFn={this.toggleIdenticalArticle}
                        incrementTagWeightFn={this.incrementIdenticalArticle}
                        decrementTagWeightFn={this.decrementIdenticalArticle}
                        hideWeightControlls
                      />
                    </span>
                  )}
                </li>
              )
            })}
          </ul>
        </div>
      )
    }

    return (
      <div
        className={classNames('identical-articles  identical-articles--first-elem-border', {
          'is-show': this.state.identicalShow,
        })}
      >
        <ul className="identical-articles__list">
          <li
            key={articleId(activeArticle)}
            onClick={this.onClick}
            className={classNames('identical-articles__article has-append is-active')}
            title={`${activeArticle.first_source.name} - ${this.fullDate(activeArticle)}`}
          >
            <ArticleSourceLine
              article={activeArticle}
              dateFormat="VERY_SHORT"
              relative
              type={type}
              sourceIsLink={false}
              showBadge={type === TYPE_PREVIEW}
              similarity={type === TYPE_PREVIEW ? this.getSim(activeArticle) : undefined}
              append={
                type === TYPE_LISTING ? `(${this.state.identicalShow ? '-' : '+'}${identicalHiddenCount})` : undefined
              }
            />
          </li>
        </ul>
      </div>
    )
  }
}

export default connect(
  null,
  buildActionCreators({
    setActiveIdentical: ActionTypes.SET_ACTIVE_IDENTICAL,
    toggleIdentArticleTag: ActionTypes.TOGGLE_TAG_IDENTICAL_ARTICLE,
    // setIdentArticleTrashTag: ActionTypes.TOGGLE_DELETE_ARTICLES,
    incrementIdentArticleTag: ActionTypes.INCREMENT_TAG_WEIGHT_FOR_SINGLE_ARTICLE,
    decrementIdentArticleTag: ActionTypes.DECREMENT_TAG_WEIGHT_FOR_SINGLE_ARTICLE,
  }),
)(IdenticalArticles)

// @ts-nocheck
import React from 'react'

import AlertTagItem from './AlertTagItem'
import type { AlertTag } from '../../opoint/flow'

type ListingTreeProps = {
  category: string
  categoryName: string
  entities: Array<AlertTag>
  expandTag: Function
  expanded: boolean
  filterExpression: string
}

export default class ListingSubTree extends React.PureComponent<ListingTreeProps> {
  render() {
    const { entities, ...props } = this.props

    return (
      <ul className="op-content-left-profiles-profile-list">
        {entities && entities.map((entity) => <AlertTagItem key={entity.id} {...props} tag={entity} />)}
      </ul>
    )
  }
}

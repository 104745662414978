import React from 'react'

import Translate from '../../Translate'
import type { ContactDeleteWarningType } from '../../opoint/flow'

type Props = {
  contactDeleteWarning: ContactDeleteWarningType
}

export default class ContactDeleteWarning extends React.PureComponent<Props> {
  render() {
    const {
      contactDeleteWarning: { message, alerts },
    } = this.props

    return (
      <div className="row op-modal-contacts-right-message-list simple-red">
        <div className="col-md-5 col-md-offset-6 op-modal-contacts-right-message-list-item contact-delete-warning">
          {message}
          <div>
            <Translate i18nString="Alerts" />
            {': '}
            <span className="alerts">{alerts}</span>
          </div>
          <div>
            <Translate i18nString="Are you sure you want to delete the contact?" />
          </div>
        </div>
      </div>
    )
  }
}

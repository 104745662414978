import R from 'ramda'

const apiWeekdaysAt8 = {
  timeConfiguration: {
    monday: [
      {
        start: 28800,
      },
    ],
    thursday: [
      {
        start: 288000,
      },
    ],
    wednesday: [
      {
        start: 201600,
      },
    ],
    tuesday: [
      {
        start: 115200,
      },
    ],
    friday: [
      {
        start: 374400,
      },
    ],
  },
}

const apiWeekdaysAt8And2 = {
  timeConfiguration: {
    monday: [
      {
        start: 28800,
      },
      {
        start: 50400,
      },
    ],
    thursday: [
      {
        start: 288000,
      },
      {
        start: 309600,
      },
    ],
    wednesday: [
      {
        start: 201600,
      },
      {
        start: 223200,
      },
    ],
    tuesday: [
      {
        start: 115200,
      },
      {
        start: 136800,
      },
    ],
    friday: [
      {
        start: 374400,
      },
      {
        start: 396000,
      },
    ],
  },
}

const apiAsSoonAsPossible = {
  timeConfiguration: {
    tuesday: [
      {
        start: 86400,
        end: 172800,
        interval: 0,
      },
    ],
    sunday: [
      {
        start: 518400,
        end: 604800,
        interval: 0,
      },
    ],
    monday: [
      {
        start: 0,
        end: 86400,
        interval: 0,
      },
    ],
    thursday: [
      {
        start: 259200,
        end: 345600,
        interval: 0,
      },
    ],
    wednesday: [
      {
        start: 172800,
        end: 259200,
        interval: 0,
      },
    ],
    saturday: [
      {
        start: 432000,
        end: 518400,
        interval: 0,
      },
    ],
    friday: [
      {
        start: 345600,
        end: 432000,
        interval: 0,
      },
    ],
    holidays: [
      {
        start: 604800,
        end: 691200,
        interval: 0,
      },
    ],
  },
}

export const timeConfigurationToWhen = (timeConfiguration: Object) =>
  R.cond([
    [R.equals(apiWeekdaysAt8.timeConfiguration), R.always(1)],
    [R.equals(apiWeekdaysAt8And2.timeConfiguration), R.always(2)],
    [R.equals(apiAsSoonAsPossible.timeConfiguration), R.always(3)],
    [R.T, R.always(4)],
  ])(timeConfiguration)

import React from 'react'
import PropTypes from 'prop-types'
import className from 'classnames'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { translate } from 'react-i18next'

import buildActionCreators from '../../helpers/buildActionCreators'
import Logo from '../common/Logo'
import Translate from '../../Translate'
import * as ActionTypes from '../../constants/actionTypes'
import { LOGOS } from '../../constants'
import LoginForm from './LoginForm'
import AvatarComponent from '../articles/AvatarComponent'

class LoginByIpForm extends React.PureComponent<any> {
  state = {
    loginWithUsernameMode: false,
  }

  static contextTypes = {
    i18n: PropTypes.object,
  }

  componentDidMount() {
    this.checkForSingleUserAutoLogin()
  }

  componentWillReceiveProps() {
    this.checkForSingleUserAutoLogin()
  }

  checkForSingleUserAutoLogin = () => {
    const { usersLoginInfo, onLogin } = this.props

    const notAutoLogin = window.localStorage.getItem('not_auto_login')

    if (usersLoginInfo.length === 1 && usersLoginInfo[0].auto_login && !notAutoLogin) {
      onLogin({ username: usersLoginInfo[0].username })
    }
  }

  handleSubmit = (values) => {
    const { onLogin } = this.props

    if (!values.username) {
      return false
    }

    return onLogin({ username: values.username })
  }

  onBaseLogin = () => {
    this.setState({
      loginWithUsernameMode: true,
    })
  }

  render() {
    const {
      loginError,
      loginByIpForm: {
        fields: {
          username: fieldUsername = {
            touched: false,
          },
        } = {},
      },
      username,
      usersLoginInfo,
      handleSubmit,
    } = this.props

    const { i18n } = this.context
    const displayBranding = usersLoginInfo !== undefined && usersLoginInfo.logoUrl && usersLoginInfo.users.length > 0

    return (
      <div className="op-login-main-wrapper">
        {displayBranding && (
          <h1>
            Login to your <br /> Infomedia account
          </h1>
        )}
        <div className="op-login-sub-wrapper">
          <div className={className('op-login', { 'animation-shake': loginError })}>
            {!displayBranding && (
              <div className="op-login-logo">
                <Logo />
              </div>
            )}

            <div className="op-login-container">
              <div className="op-login-container-form">
                {this.state.loginWithUsernameMode && usersLoginInfo.users && usersLoginInfo.users.length > 0 && (
                  <button
                    className="op-login-back-button"
                    onClick={() => {
                      this.setState({ loginWithUsernameMode: false })
                    }}
                  >
                    Back
                  </button>
                )}

                {displayBranding ? (
                  <p>
                    <h3>
                      <Translate i18nString="IP-based Accounts" />
                    </h3>
                    <Translate i18nString="You can access following accounts from this IP address:" />
                  </p>
                ) : (
                  <h3>
                    <Translate i18nString="Log in to Your Account" />
                  </h3>
                )}

                {loginError && (
                  <div className="op-login-container-form-error-message  animate">
                    <Translate i18nString="The login information you entered does not match an account in our records." />
                  </div>
                )}

                {this.state.loginWithUsernameMode || (usersLoginInfo.users && usersLoginInfo.users.length === 0) ? (
                  <div>
                    <LoginForm />
                  </div>
                ) : (
                  <form onSubmit={handleSubmit(this.handleSubmit)}>
                    {usersLoginInfo.users &&
                      usersLoginInfo.users.map(({ username: infoUsername, loginmessage, logo }) => (
                        <div
                          key={infoUsername}
                          className={className('form-group', {
                            'has-error': fieldUsername.touched && !username,
                          })}
                        >
                          <label
                            className={className('op-login-radio', {
                              'op-login-radio--checked': username === infoUsername,
                            })}
                          >
                            <AvatarComponent name={infoUsername} />
                            <Field name="username" component="input" type="radio" value={infoUsername} />
                            {logo && (
                              <figure className="op-login-radio__logo">
                                <img src={logo} alt="logo" />
                              </figure>
                            )}
                            <span>{loginmessage || infoUsername}</span>
                          </label>
                        </div>
                      ))}

                    <button type="submit" className="op-button  mod-login" id="submit" disabled={!username}>
                      <Translate i18nString="Submit" />
                    </button>

                    <button type="button" className="op-button simple-gray simple-mod-login" onClick={this.onBaseLogin}>
                      <Translate i18nString="Log in as another user" />
                    </button>

                    {document.location.origin === 'https://m360-dev.opoint.com' && (
                      <div className="op-login-container-form-error-message animate" style={{ marginTop: 25 }}>
                        <Translate i18nString="Warning: You are about to log into the development version of M360. Use this version only if you have problems with the production version or if you want to help us testing. The production version can be found on m360.opoint.com." />
                      </div>
                    )}

                    {document.location.origin === 'https://m360-alpha.opoint.com' && (
                      <div className="op-login-container-form-error-message animate" style={{ marginTop: 25 }}>
                        <Translate i18nString="Warning: You are about to log into an obsolete version of M360. Use this version only if you have problems with the production version. The production version can be found on m360.opoint.com." />
                      </div>
                    )}
                  </form>
                )}
              </div>
            </div>
          </div>
          {displayBranding && (
            <div className="op-login-customer-branding">
              <img src={usersLoginInfo.logoUrl} width={330} />
              {!!usersLoginInfo.title && (
                <h2 className="op-login-customer-branding-description">{usersLoginInfo.title}</h2>
              )}
              <hr />
              <p>Powered by</p>
              <img src={LOGOS.fallback} width={200} />
            </div>
          )}
        </div>
      </div>
    )
  }
}

// @ts-ignore
LoginByIpForm = translate([], { wait: true })(LoginByIpForm)

// @ts-ignore
LoginByIpForm = connect(
  (state: any) => ({
    user: state.auth.user,
    loginError: state.auth.loginError,
    usersLoginInfo: state.auth.usersLoginInfo,
    isLogout: state.auth.isLogout,
    loginByIpForm: state.form.loginByIp,
    username: state.form.loginByIp.values.username,
  }),
  buildActionCreators({
    onLogin: ActionTypes.LOG_IN_BY_IP,
    // onBaseLogin: ActionTypes.OPEN_BASE_LOG_IN_FORM,
    setUserName: ActionTypes.SET_AUTOLOGIN_USERNAME,
  }),
)(LoginByIpForm)

// @ts-ignore
LoginByIpForm = reduxForm({
  form: 'loginByIp',
  initialValues: {
    // username: '',
  },
  // @ts-ignore
})(LoginByIpForm)

export default LoginByIpForm

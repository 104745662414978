// @ts-nocheck
import React from 'react'
import R from 'ramda'

import { TEMPLATE_TYPES } from '../../opoint/templates'
import TemplatePreviewModal from './TemplatePreviewModal'
import TemplateReportsOptions from './TemplateReportsOptions'
import TemplateSelectorTemplateType from './TemplateSelectorTemplateType'
import Translate from '../../Translate'
import { EXCLUDED_TEMPLATES, getGroupedTemplatesKeys } from '../../opoint/templates/index'
import type { ActiveTemplate, Template } from '../../opoint/flow'
import TemplateEditorFormWrapper from './TemplateEditor/FormWrapper'

export type Props = {
  activeTemplate: ActiveTemplate
  groupedTemplates: {
    [key: string]: Array<Template>
  }
  previewModalContent: any
  isPreviewModalOpen: boolean
  previewModalTitle: string
  onTemplateChange: (template?: Template) => void
  openPreviewModal: (templateId: number) => void
  closePreviewModal: () => void
  openEditorModal: () => void
  isEditable: boolean
  showPreviewButtonTitle: string
  showTemplateSettingsButtonTitle: string
  showDeleteButtonTitle: string
  deleteTemplate: () => void
  modalTitle: string
  isEditorModalOpen: boolean
  templateEditName: string
  isReportsTemplate: boolean
}

export default class TemplateSelector extends React.PureComponent<Props> {
  getActiveTemplateId = (): number => {
    const {
      groupedTemplates,
      activeTemplate: { id, type },
    } = this.props
    const templateType = type || this.getActiveTemplateType()

    // $FlowFixMe
    return id || Object.values(groupedTemplates[templateType])[0].id
  }

  getActiveTemplateType = (): number => {
    const {
      groupedTemplates,
      activeTemplate: { type },
    } = this.props

    return type || Number(R.head(getGroupedTemplatesKeys(groupedTemplates)))
  }

  // @ts-ignore
  selectTemplate = (type: number, id?: number): void => () => {
    const { groupedTemplates, onTemplateChange } = this.props
    // $FlowFixMe
    const template = id || Object.values(groupedTemplates[type])[0].id
    const templateObject = groupedTemplates[type].find((t) => t.id === template)

    onTemplateChange(templateObject)
  }

  templateTypeIsActive = (key: number): boolean => this.getActiveTemplateType() === key

  templateIsActive = (key: number): boolean => this.getActiveTemplateId() === key

  render() {
    const {
      closePreviewModal,
      groupedTemplates,
      previewModalContent,
      openEditorModal,
      isPreviewModalOpen,
      previewModalTitle,
      openPreviewModal,
      isEditable,
      showPreviewButtonTitle,
      // @ts-ignore
      isReportsTemplate,
      showTemplateSettingsButtonTitle,
      showDeleteButtonTitle,
      deleteTemplate,
    } = this.props
    const activeTemplateType = this.getActiveTemplateType()

    return (
      <div>
        <div className="op-template-select">
          <ul className="op-template-type-list">
            {getGroupedTemplatesKeys(groupedTemplates).map((tcKey) => {
              const tcKeyInt = Number(tcKey)
              if (tcKeyInt === TEMPLATE_TYPES.RSS && isReportsTemplate) {
                return null
              }
              return (
                <TemplateSelectorTemplateType
                  key={tcKeyInt}
                  templateTypeKey={tcKeyInt}
                  // @ts-ignore
                  select={this.selectTemplate}
                  isActive={this.templateTypeIsActive(tcKeyInt)}
                />
              )
            })}
          </ul>
          <ul className="op-modal-report-body-list">
            {
              // $FlowFixMe
              groupedTemplates[activeTemplateType] &&
                Object.values(groupedTemplates[activeTemplateType]).map((template: Template) => (
                  <li
                    className="op-modal-report-body-list-item"
                    key={template.id}
                    // @ts-ignore
                    onClick={this.selectTemplate(activeTemplateType, Number(template.id))}
                  >
                    <div className="checkbox__wrapper">
                      <div className="op-radio">
                        <input checked={this.templateIsActive(template.id)} name="template" readOnly type="radio" />
                        <label htmlFor={template.id} />
                      </div>
                    </div>
                    <label htmlFor={template.id} className="name">
                      {template.readonly ? <Translate i18nString={template.name} /> : <span>{template.name}</span>}
                    </label>
                    {isEditable && !R.contains(template.id, EXCLUDED_TEMPLATES) && (
                      <TemplateReportsOptions
                        deleteTemplate={deleteTemplate}
                        openEditorModal={openEditorModal}
                        showDeleteButtonTitle={showDeleteButtonTitle}
                        showTemplateSettingsButtonTitle={showTemplateSettingsButtonTitle}
                        template={template}
                      />
                    )}
                    <div
                      className="op-template-select-icon"
                      onClick={() => openPreviewModal(template.id)}
                      title={showPreviewButtonTitle}
                    >
                      <i className="op-icon-eye" />
                    </div>
                  </li>
                ))
            }
          </ul>
        </div>
        <TemplatePreviewModal
          close={closePreviewModal}
          content={previewModalContent}
          isOpen={isPreviewModalOpen}
          open={openPreviewModal}
          title={previewModalTitle}
        />
      </div>
    )
  }
}

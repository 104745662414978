import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next-latest'

import Modal from '../../common/Modal'
import buildActionCreators from '../../../helpers/buildActionCreators'
import { getDeleteProfileConfirmationModalOpen } from '../../../selectors/uiSelectors'
import * as ActionTypes from '../../../constants/actionTypes'
import { getEditedProfile, getProfilesToDelete, getProfiles } from '../../../selectors/profilesSelectors'
import { wrapper, warningsWrapper } from './index.module.scss'
import type { Dependencies, Profile } from '../../types/profile'

type Props = {
  isOpen: boolean
  profiles: Profile[]
  allProfiles: Profile[]
  getDependencies: ({ profiles }: { profiles: Profile[] }) => void
  close: () => void
  dependencies: {
    [key: number]: {
      deps: Dependencies
    }
  }
  openedFrom: 'manage' | 'editor'
  deleteProfiles: (payload: any) => void
  deleteProfileEditor: () => void
}

const ProfileDeleteConfirmationModal = ({
  isOpen,
  close,
  profiles,
  allProfiles,
  dependencies,
  getDependencies,
  openedFrom,
  deleteProfiles,
  deleteProfileEditor,
}: Props) => {
  const { t } = useTranslation()

  useEffect(() => {
    getDependencies({ profiles })
  }, [isOpen])

  useEffect(() => {
    if (!profiles.length) {
      close()
    }
  }, [allProfiles])

  const deleteProfile = (id) => () => {
    switch (openedFrom) {
      case 'manage':
        deleteProfiles({ profileIds: [id], force: true })
        break
      case 'editor':
        deleteProfileEditor()
        break
      default:
        break
    }
  }

  const renderDependencies = (profileDependencies: { deps: Dependencies }) => {
    const { deps } = profileDependencies

    return (
      <div className={warningsWrapper}>
        {!!(deps.alerts.length || deps.feeds.length || deps.profiles.length) && (
          <div>
            {t('Profile is used in:')}
            <ul>
              {!!deps.alerts.length && (
                <li>
                  <span>{t('Alerts: ')}</span>
                  {deps.alerts.map((item) => item.name).join(', ')}
                </li>
              )}
              {!!deps.feeds.length && (
                <li>
                  <span>{t('Feeds: ')}</span>
                  {deps.feeds.map((item) => item.name).join(', ')}
                </li>
              )}
              {!!deps.profiles.length && (
                <li>
                  <span>{t('Profiles: ')}</span>
                  {deps.profiles.map((item) => item.name).join(', ')}
                </li>
              )}
            </ul>
          </div>
        )}
        {deps.parent && <p>{t('Profile is a parent. If you delete it, child profiles will be moved one level up.')}</p>}
      </div>
    )
  }

  return (
    <Modal isOpen={isOpen} onHide={close} title="Are you sure you want to delete listed profiles?">
      <ul className={wrapper}>
        {profiles.map((profile, index) => {
          const profileDependencies = dependencies[profile.id]

          return (
            <li key={index}>
              <div>
                <h3>{profile.name}</h3>
                {profileDependencies && renderDependencies(profileDependencies)}
              </div>
              <button
                className="op-button-toolbar profile-to-delete__confirm-controll"
                onClick={deleteProfile(profile.id)}
              >
                Confirm deletion
              </button>
            </li>
          )
        })}
      </ul>
    </Modal>
  )
}

export default connect(
  (state: any) => {
    let profiles = []
    const editedProfile = getEditedProfile(state)
    const allProfiles = getProfiles(state)
    const profilesToDelete = getProfilesToDelete(state)
    const selectedProfiles = allProfiles.filter(({ id }) => profilesToDelete.indexOf(id) !== -1)
    const openedFrom = getDeleteProfileConfirmationModalOpen(state)

    if (selectedProfiles && selectedProfiles.length && openedFrom === 'manage') {
      profiles = selectedProfiles
    } else if (editedProfile && openedFrom === 'editor') {
      profiles = [editedProfile]
    }

    return {
      allProfiles,
      profiles,
      dependencies: state.profiles.profilesToDeleteDeps,
      openedFrom,
      isOpen: !!openedFrom,
    }
  },
  buildActionCreators({
    close: ActionTypes.PROFILE_DELETE_CONFIRMATION_CLOSE,
    getDependencies: ActionTypes.PROFILES_GET_DEPENDENCIES,
    deleteProfiles: ActionTypes.PROFILE_DELETE,
    deleteProfileEditor: ActionTypes.PROFILE_EDITOR_DELETE_CONFIRM,
  }),
)(ProfileDeleteConfirmationModal)

import React from 'react'
import PropTypes from 'prop-types'
import Rx from 'rxjs'

import Translate from '../../../Translate'
import VideoMatchesReactComponent from './Videomatches'
import { articleId } from '../../../opoint/articles/index'

/**
 * This component renders RADIO/TV content
 */
export default class MediaBodyComponent extends React.Component<any, any> {
  static contextTypes = {
    URLService: PropTypes.object,
  }

  static stopVideosObserver = new Rx.Subject()

  componentDidMount() {
    const myArticleId = articleId(this.props.article)
    // @ts-ignore
    this.stopVideosSubscriber = MediaBodyComponent.stopVideosObserver.subscribe((senderArticleId) => {
      if (myArticleId !== senderArticleId) {
        // @ts-ignore
        this.mediaElementRef.pause()
      }
    })

    /**
     * because of using this.mediaElementRef in render
     * as a prop (see `videoContainer={this.mediaElementRef}`)
     * for first time the component was mount
     * there is no such a ref as mediaElementRef
     * so we have to forceUpdate it one more time
     * to get an acces to the ref in the render method
     */
    this.forceUpdate()
  }

  componentWillUnmount() {
    // @ts-ignore
    this.stopVideosSubscriber.unsubscribe()
  }

  onPlay = () => {
    const { article } = this.props
    MediaBodyComponent.stopVideosObserver.next(articleId(article))
  }

  mediaElementRef = null

  render() {
    const {
      article,
      article: {
        mediatype: { text: mediaType },
      },
    } = this.props
    const { URLService } = this.context

    const video = `${URLService.getVideoUrl()}/${article.first_source.id}-${article.unix_timestamp}.mp4`
    const poster = `${URLService.getVideoPosterUrl()}/${article.id_site}_${article.first_source.id}.png`

    return (
      <div className="op-content-article-listing-article-content-text">
        <div className="article-body">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              {mediaType === 'TV' ? (
                <div className="op-content-article-listing-article-content-text-video">
                  <video
                    poster={poster}
                    controls
                    onPlay={this.onPlay}
                    preload="none"
                    ref={(ref) => {
                      // @ts-ignore
                      this.mediaElementRef = ref
                    }}
                  >
                    <source src={video} type="video/mp4" />
                    <Translate i18nString="Your browser does not support the video tag." />
                  </video>
                </div>
              ) : (
                <div className="article-content__audio">
                  <audio
                    controls
                    onPlay={this.onPlay}
                    preload="none"
                    ref={(ref) => {
                      // @ts-ignore
                      this.mediaElementRef = ref
                    }}
                  >
                    <source src={video} type="video/mp4" />
                    <Translate i18nString="Your browser does not support the video tag." />
                  </audio>
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <VideoMatchesReactComponent
                stopVideos={this.onPlay}
                videoContainer={this.mediaElementRef}
                article={article}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

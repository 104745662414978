import React from 'react'

import type { Template } from '../../opoint/flow'
import Translate from '../../Translate'

type Props = {
  template: Template
  openEditorModal: () => void
  showTemplateSettingsButtonTitle: string
  showDeleteButtonTitle: string
  deleteTemplate: (id: number) => void
}

export default class TemplateReportsOptions extends React.PureComponent<Props> {
  state = {
    deleteTemplateConfirmation: false,
  }

  confirmDelete = () => {
    this.setState({ deleteTemplateConfirmation: true })
  }

  cancelDelete = () => {
    this.setState({ deleteTemplateConfirmation: false })
  }

  handleDelete = () => {
    const { deleteTemplate, template } = this.props
    deleteTemplate(template.id)
  }

  handleTemplateEditorOpen = () => {
    this.props.openEditorModal()
  }

  render() {
    const { showTemplateSettingsButtonTitle, showDeleteButtonTitle, template } = this.props

    const { deleteTemplateConfirmation } = this.state

    return (
      <div>
        {deleteTemplateConfirmation ? (
          <div className="reports-template-confirm-delete">
            <Translate i18nString="Do you really want to delete this template?" />
            <div className="op-template-select-icon" onClick={this.cancelDelete}>
              {/* title={showTemplateSettingsButtonTitle} */}
              <i className="op-icon-cancel" />
            </div>
            <div className="op-template-danger-icon" onClick={this.handleDelete} title={showDeleteButtonTitle}>
              <i className="op-icon-bin" />
            </div>
          </div>
        ) : (
          <div>
            <div
              className="op-template-select-icon"
              onClick={this.handleTemplateEditorOpen}
              title={showTemplateSettingsButtonTitle}
            >
              <i className="op-icon-gear" />
            </div>
            {!template.readonly && (
              <div className="op-template-select-icon" onClick={this.confirmDelete} title={showDeleteButtonTitle}>
                <i className="op-icon-bin" />
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Overlay, Popover } from 'react-bootstrap'

import buildActionCreators from '../../helpers/buildActionCreators'
import Translate from '../../Translate'
import * as ActionTypes from '../../constants/actionTypes'

type Props = {
  visible: boolean
  hide: () => any
}

class BadBrowserPopover extends PureComponent<Props> {
  hide = () => {
    const { hide } = this.props
    hide()
  }

  render() {
    const { visible } = this.props
    const popup = (
      <Popover
        id="bad-browser-popup"
        className="bad-browser-popup"
        title={
          <span>
            <i className="op-icon-info" /> <Translate i18nString="We are sorry" />
          </span>
        }
      >
        <Translate i18nString="Your browser does not support this functionality, please use recent one." />
      </Popover>
    )

    return (
      <div className="bad-browser-popup">
        <Overlay show={visible} onHide={this.hide} onClick={this.hide} container={this} rootClose>
          <div className="bad-browser-overlay">{popup}</div>
        </Overlay>
      </div>
    )
  }
}

// @ts-ignore
BadBrowserPopover = connect(
  (state: any) => ({
    visible: state.ui.showBadBrowserPopup, // TODO selector @dmytro
  }),
  buildActionCreators({
    hide: ActionTypes.HIDE_BAD_BROWSER_POPUP,
  }),
)(BadBrowserPopover)

export default BadBrowserPopover

// @ts-nocheck
import React from 'react'
import { connect } from 'react-redux'

import { getEntityFilter } from '../../selectors/uiSelectors'

export default function entityFilter(Component) {
  const EntityFilter = (props) => <Component {...props} />

  return connect((state, props) => {
    const { list: entities } = props
    const filter = getEntityFilter(state)

    const re = new RegExp(filter, 'i')

    const newProps = {
      filterExpression: filter,
    }

    if (filter) {
      const filtered = entities.filter(
        (entity) => re.test(entity.name) || re.test(entity.subject) || re.test(entity.allRecipientsContacts),
      )

      newProps.list = filtered
      newProps.numberOfFiltered = entities.length - filtered.length
    }

    return newProps
  })(EntityFilter)
}

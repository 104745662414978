import React from 'react'
import { connect } from 'react-redux'

import config from '../../opoint/common/config'
import { firstToUpperCase } from '../../opoint/common'
import { LOGOS } from '../../constants'

// TODO: This is platform specific component
class Logo extends React.Component<any, any> {
  getLogoUrl(logoName: string) {
    const { portalLogo, user } = this.props
    let returnLogo = LOGOS.fallback

    // portalLogo has absolute path
    if (user && portalLogo) {
      returnLogo = portalLogo
    }
    // return relative path from LOGOS or fallback logo in case of undefined
    if (!user || !portalLogo) {
      returnLogo = LOGOS[logoName] ? config.getUrl(LOGOS[logoName]) : LOGOS.fallback
    }

    return returnLogo
  }

  render() {
    const host = window.location.host.split('.')[0]

    return <img alt={`${firstToUpperCase(host)}'s logo`} className={this.props.className} src={this.getLogoUrl(host)} />
  }
}

export default connect((state: any) => ({
  portalLogo: state.settings.list.NEW_PORTAL_LOGO,
  user: state.auth.user,
}))(Logo)

import React from 'react'
import R from 'ramda'
import { connect } from 'react-redux'

import buildActionCreators from '../../../helpers/buildActionCreators'
import Loader from '../../common/Loader'
import NextAlert from './NextAlert'
import Translate from '../../../Translate'
import PickRecipientsModal from './PickRecipientsModal'
import { isNumeric } from '../../../opoint/common/index'
import {
  getAlertHistoryItems,
  getAlertRecipientsNames,
  getAlertsDetails,
  getAlertsNext,
  getAlertsNextPreview,
  getAlertsRequestPending,
} from '../../../selectors/alertsSelectors'
import * as ActionTypes from '../../../constants/actionTypes'
import type { TemplatePreview } from '../../../opoint/flow'

type fetchAlertPayload = {
  alertId: number
  toBeEdited: boolean
}
type fetchAlertHistoryItemPayload = {
  alertId: number
  historyId: string
  historyTimestamp: string
}

type Props = {
  alertFetch: (payload: fetchAlertPayload) => void
  alertFetchHistoryItem: (payload: fetchAlertHistoryItemPayload) => void
  alertId: number
  hasNext: boolean
  location: any
  preview: TemplatePreview
  recipients: Array<string>
  requestPending: boolean
  requestFailed: boolean
  previewOpen: () => void
  previewClose: () => void
  clearRecipients: () => void
  clearAlertPreview: Function
}

class NextAlertContainer extends React.Component<Props> {
  componentWillMount() {
    this.props.previewOpen()
  }

  componentDidMount() {
    this.fetchAlert()
    this.fetchAlertHistoryItem()
  }

  componentDidUpdate(prevProps: Props) {
    const {
      alertId: oldAlertId,
      location: {
        query: { historyTimestamp: oldHistoryTimestamp },
      },
    } = prevProps

    const {
      alertId: newAlertId,
      location: {
        query: { historyTimestamp: newHistoryTimestamp },
      },
    } = this.props

    if (isNumeric(newAlertId)) {
      if (oldAlertId !== newAlertId) {
        this.fetchAlert()
      }

      if (oldHistoryTimestamp !== newHistoryTimestamp) {
        this.fetchAlertHistoryItem()
      }
    }
  }

  componentWillUnmount() {
    this.props.previewClose()
    this.props.clearRecipients()
    this.props.clearAlertPreview({ id: this.props.alertId })
  }

  fetchAlertHistoryItem() {
    const {
      alertId,
      alertFetchHistoryItem,
      location: {
        query: { historyId, historyTimestamp },
      },
    } = this.props

    if (historyId && historyTimestamp) {
      alertFetchHistoryItem({
        alertId,
        historyId,
        historyTimestamp,
      })
    }
  }

  fetchAlert() {
    const { alertId, alertFetch } = this.props

    alertFetch({
      alertId,
      toBeEdited: false,
    })
  }

  render() {
    const { hasNext, preview, recipients, requestPending, requestFailed, alertId } = this.props

    if (requestFailed) {
      return (
        <div className="mod-alerts mod-alert-full-height">
          <div className="op-content-article-listing-no-hits">
            <div className="util_center-parent">
              <div className="util_center-child">
                <Translate i18nString="Oops, something went wrong" />
              </div>
            </div>
          </div>
        </div>
      )
    }
    if (requestPending || !preview) {
      return (
        <div className="mod-alerts mod-full-height">
          <Loader />
        </div>
      )
    }
    return (
      <div className="mod-alerts mod-alert-full-height">
        <NextAlert hasNext={hasNext} preview={preview} recipients={recipients} alertId={alertId} />
        <PickRecipientsModal />
      </div>
    )
  }
}

export default connect(
  (state: any, ownProps: any) => {
    const { historyId, historyTimestamp } = ownProps.location.query
    const alertId = parseInt(ownProps.params.alertId, 10)

    const next = getAlertsNext(state)[alertId]
    const alertsRequestPending = getAlertsRequestPending(state)

    const historyItems = getAlertHistoryItems(state)
    const historyItemLens = R.lensPath([alertId, historyId, historyTimestamp])
    const historyItem: any = R.view(historyItemLens, historyItems)

    let props: any = {
      alertId,
      hasNext: next && !!next.count,
      requestPending: alertsRequestPending,
    }

    if (historyItem) {
      const requestFailed = historyItem === -1

      props = {
        ...props,
        preview: !requestFailed && historyItem.content,
        recipients: !requestFailed && historyItem.sentTo,
        requestFailed,
      }
    } else {
      const alert = getAlertsDetails(state)[alertId]
      const nextPreview = getAlertsNextPreview(alertId)(state)

      props = {
        ...props,
        preview: nextPreview,
        recipients: alert && getAlertRecipientsNames(alertId)(state),
      }
    }

    return props
  },
  buildActionCreators({
    alertFetch: ActionTypes.ALERT_FETCH,
    clearRecipients: ActionTypes.ALERT_CLEAR_RECIPIENTS,
    alertFetchHistoryItem: ActionTypes.ALERT_FETCH_HISTORY_ITEM,
    previewOpen: ActionTypes.ALERT_PREVIEW_OPEN,
    previewClose: ActionTypes.ALERT_PREVIEW_CLOSE,
    clearAlertPreview: ActionTypes.ALERT_PREVIEW_CLEAR,
  }),
)(NextAlertContainer)

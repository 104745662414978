export const BOOTSTRAP = 'BOOTSTRAP'
export const SHOW_NEW_VERSION_NOTIFICATION = 'SHOW_NEW_VERSION_NOTIFICATION'
export const HIDE_NEW_VERSION_NOTIFICATION = 'HIDE_NEW_VERSION_NOTIFICATION'
export const PORTAL_ERROR = 'PORTAL_ERROR'
export const GET_SERVER_TIME = 'GET_SERVER_TIME'
export const GET_SERVER_TIME_ONCE = 'GET_SERVER_TIME_ONCE'
export const GET_SERVER_TIME_FAILURE = 'GET_SERVER_TIME_FAILURE'

export const LOG_IN = 'LOG_IN'
export const LOG_IN_WITH_TOKEN = 'LOG_IN_WITH_TOKEN'
export const LOG_IN_WITH_TOKEN_SUCCESS = 'LOG_IN_WITH_TOKEN_SUCCESS'
export const LOG_IN_WITH_TOKEN_FAILURE = 'LOG_IN_WITH_TOKEN_FAILURE'
export const USER_TOKEN_CHANGED = 'USER_TOKEN_CHANGED'
export const USER_LOGGED_OUT_IN_ANOTHER_TAB = 'USER_LOGGED_OUT_IN_ANOTHER_TAB'
export const TOKEN_EXPIRED = 'TOKEN_EXPIRED'
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS'
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE'

export const LOG_IN_BY_IP = 'LOG_IN_BY_IP'
export const USERS_LOGIN_INFO = 'USERS_LOGIN_INFO'
export const USERS_LOGIN_INFO_SUCCESS = 'USERS_LOGIN_INFO_SUCCESS'
export const OPEN_BASE_LOG_IN_FORM = 'OPEN_BASE_LOG_IN_FORM'
export const SET_AUTOLOGIN_USERNAME = 'SET_AUTOLOGIN_USERNAME'

export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS'
export const LOG_IN_ERROR = 'LOG_IN_ERROR'
export const LOG_IN_ERROR_TIMEOUT = 'LOG_IN_ERROR_TIMEOUT'
export const LOGOUT = 'LOGOUT'
export const SERVER_NOT_RESPONDING = 'SERVER_NOT_RESPONDING'

export const SEARCH = 'SEARCH'
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS'
export const SEARCH_FAILURE = 'SEARCH_FAILURE'
export const SEARCH_IS_TAKING_TOO_LONG = 'SEARCH_IS_TAKING_TOO_LONG'
export const CANCEL_SEARCH = 'CANCEL_SEARCH'
export const SEARCH_IS_EMPTY = 'SEARCH_IS_EMPTY'

export const SEARCH_FETCH = 'SEARCH_FETCH'

export const SEARCHTERM_CHANGED = 'SEARCHTERM_CHANGED'
export const UPDATE_SEARCHTERM = 'UPDATE_SEARCHTERM'
export const UPDATE_SEARCHTERM_SUCCESS = 'UPDATE_SEARCHTERM_SUCCESS'
export const SEARCHTERM_CHANGED_PROFILE_EDITOR = 'SEARCHTERM_CHANGED_PROFILE_EDITOR'
export const SEARCHFILTER_ADDED = 'SEARCHFILTER_ADDED'
export const SEARCHFILTER_REMOVED = 'SEARCHFILTER_REMOVED'
export const SEARCHFILTER_TOGGLED = 'SEARCHFILTER_TOGGLED'
export const SEARCHDATA_CLEAR = 'SEARCHDATA_CLEAR'
export const SEARCHDATA_CLEAR_PROFILE_EDITOR = 'SEARCHDATA_CLEAR_PROFILE_EDITOR'
export const SEARCH_CHANGE_DATERANGE = 'SEARCH_CHANGE_DATERANGE'
export const SEARCH_CHANGE_DATERANGE_SUCCESS = 'SEARCH_CHANGE_DATERANGE_SUCCESS'
export const SEARCH_CHANGE_DATERANGE_FAILURE = 'SEARCH_CHANGE_DATERANGE_FAILURE'
export const SEARCH_CHANGE_DATERANGE_TO_CURRENT = 'SEARCH_CHANGE_DATERANGE_TO_CURRENT'
export const ADD_PROFILE_EDITOR_LINE = 'ADD_PROFILE_EDITOR_LINE'
export const PROFILE_EDITOR_FOCUSED_LINE_CHANGED = 'PROFILE_EDITOR_FOCUSED_LINE_CHANGED'
export const PROFILE_EDITOR_FILTERS_FETCH_SIMPLE_SUCCESS = 'PROFILE_EDITOR_FILTERS_FETCH_SIMPLE_SUCCESS'
export const PROFILE_EDITOR_FILTERS_FETCH_SIMPLE_FAILURE = 'PROFILE_EDITOR_FILTERS_FETCH_SIMPLE_FAILURE'
export const PROFILE_EDITOR_FILTERS_FETCH_MULTIPLE_SUCCESS = 'PROFILE_EDITOR_FILTERS_FETCH_MULTIPLE_SUCCESS'
export const PROFILE_EDITOR_FILTERS_FETCH_MULTIPLE_FAILURE = 'PROFILE_EDITOR_FILTERS_FETCH_MULTIPLE_FAILURE'

export const PROFILE_EDITOR_FILTERS_POP = 'PROFILE_EDITOR_FILTERS_POP'

export const PROFILE_EDITOR_FILTERS_FETCH_MULTIPLE_OF_TYPE_SUCCESS =
  'PROFILE_EDITOR_FILTERS_FETCH_MULTIPLE_OF_TYPE_SUCCESS'

export const PROFILE_EDITOR_PREVIEW = 'PROFILE_EDITOR_PREVIEW'
export const PROFILE_EDITOR_PREVIEW_SUCCESS = 'PROFILE_EDITOR_PREVIEW_SUCCESS'
export const PROFILE_EDITOR_PREVIEW_FAILURE = 'PROFILE_EDITOR_PREVIEW_FAILURE'

export const PROFILE_EDITOR_SAVE_PROFILE = 'PROFILE_EDITOR_SAVE_PROFILE'
export const PROFILE_EDITOR_SAVE_PROFILE_SUCCESS = 'PROFILE_EDITOR_SAVE_PROFILE_SUCCESS'
export const PROFILE_EDITOR_SAVE_PROFILE_FAILURE = 'PROFILE_EDITOR_SAVE_PROFILE_FAILURE'
export const PROFILE_EDITOR_INVALID_SEARCHLINE = 'PROFILE_EDITOR_INVALID_SEARCHLINE'
export const PROFILE_EDITOR_CLEAR_DEBUG = 'PROFILE_EDITOR_CLEAR_DEBUG'
export const PROFILE_EDITOR_FIX_SEARCHTERM = 'PROFILE_EDITOR_FIX_SEARCHTERM'

// export const PROFILE_EDITOR_DELETE_PROFILE = 'PROFILE_EDITOR_DELETE_PROFILE'
export const PROFILE_EDITOR_DELETE_PROFILE_SUCCESS = 'PROFILE_EDITOR_DELETE_PROFILE_SUCCESS'

export const PROFILE_EDITOR_FILTERS_TOGGLE = 'PROFILE_EDITOR_FILTERS_TOGGLE'

export const FETCH_PROFILE_EDITOR_MORE_FILTERS = 'FETCH_PROFILE_EDITOR_MORE_FILTERS'

export const PROFILE_EDITOR_SEARCHFILTER_TOGGLE = 'PROFILE_EDITOR_SEARCHFILTER_TOGGLE'
export const PROFILE_EDITOR_FILTER_ADDED = 'PROFILE_EDITOR_FILTER_ADDED'
export const PROFILE_EDITOR_FILTER_REMOVED = 'PROFILE_EDITOR_FILTER_REMOVED'
export const PROFILE_EDITOR_FILTER_INVERTED = 'PROFILE_EDITOR_FILTER_INVERTED'

export const LEAVE_PROFILE_EDITOR = 'LEAVE_PROFILE_EDITOR'

export const SAVE_AS_PROFILE = 'SAVE_AS_PROFILE'
export const SAVE_AS_PROFILE_SUCCESS = 'SAVE_AS_PROFILE_SUCCESS'
export const SAVE_AS_PROFILE_FAILURE = 'SAVE_AS_PROFILE_FAILURE'
export const SAVE_AS_PROFILE_INIT_POPPER = 'SAVE_AS_PROFILE_INIT_POPPER'

export const PROFILE_REORDER = 'PROFILE_REORDER'
export const PROFILES_REORDER_SUCCESS = 'PROFILES_REORDER_SUCCESS'

export const FILTER_METAS_FETCH = 'FILTER_METAS_FETCH'
export const FILTER_METAS_FETCH_SUCCESS = 'FILTER_METAS_FETCH_SUCCESS'
export const FILTER_METAS_FETCH_FAILURE = 'FILTER_METAS_FETCH_FAILURE'

export const FETCH_FILTER_DETAIL = 'FETCH_FILTER_DETAIL'
export const FETCH_FILTER_DETAIL_SUCCESS = 'FETCH_FILTER_DETAIL_SUCCESS'
export const FETCH_FILTER_DETAIL_FAILURE = 'FETCH_FILTER_DETAIL_FAILURE'
export const FETCH_FILTER_DETAIL_UNKNOWN = 'FETCH_FILTER_DETAIL_UNKNOWN'

export const FETCH_MORE_FILTERS = 'FETCH_MORE_FILTERS'

export const TEMPLATES_FETCH = 'TEMPLATES_FETCH'
export const TEMPLATES_FETCH_SUCCESS = 'TEMPLATES_FETCH_SUCCESS'
export const TEMPLATES_FETCH_FAILURE = 'TEMPLATES_FETCH_FAILURE'

export const FETCH_TEMPLATE_DETAIL = 'FETCH_TEMPLATE_DETAIL'
export const FETCH_TEMPLATE_DETAIL_SUCCESS = 'FETCH_TEMPLATE_DETAIL_SUCCESS'
export const FETCH_TEMPLATE_DETAIL_FAILURE = 'FETCH_TEMPLATE_DETAIL_FAILURE'
export const UPDATE_ACTIVE_TEMPLATE = 'UPDATE_ACTIVE_TEMPLATE'

export const CLEAR_ARTICLES = 'CLEAR_ARTICLES'
export const FETCH_ARTICLES = 'FETCH_ARTICLES'
export const FETCH_ARTICLES_SUCCESS = 'FETCH_ARTICLES_SUCCESS'
export const FETCH_ARTICLES_FAILURE = 'FETCH_ARTICLES_FAILURE'

export const FETCH_MORE_ARTICLES = 'FETCH_MORE_ARTICLES'
export const FETCH_MORE_ARTICLES_SUCCESS = 'FETCH_MORE_ARTICLES_SUCCESS'
export const FETCH_MORE_ARTICLES_FAILURE = 'FETCH_MORE_ARTICLES_FAILURE'

export const FETCH_STATISTICS = 'FETCH_STATISTICS'
export const FETCH_STATISTICS_SUCCESS = 'FETCH_STATISTICS_SUCCESS'
export const FETCH_STATISTICS_FAILURE = 'FETCH_STATISTICS_FAILURE'

export const STATISTICS_SHOW = 'STATISTICS_SHOW'
export const STATISTIC_VIEWS_FETCH_SUCCESS = 'STATISTIC_VIEWS_FETCH_SUCCESS'
export const STATISTICS_VIEWS_OPEN = 'STATISTICS_VIEWS_OPEN'
export const STATISTICS_VIEWS_SET_ACTIVE = 'STATISTICS_VIEWS_SET_ACTIVE'
export const STATISTICS_VIEW_DELETE = 'STATISTICS_VIEW_DELETE'
export const STATISTICS_VIEW_DELETE_SUCCESS = 'STATISTICS_VIEW_DELETE_SUCCESS'
export const STATISTICS_VIEW_DELETE_FAILURE = 'STATISTICS_VIEW_DELETE_FAILURE'
export const STATISTICS_VIEW_SAVE = 'STATISTICS_VIEW_SAVE'
export const STATISTICS_VIEW_SAVE_SUCCESS = 'STATISTICS_VIEW_SAVE_SUCCESS'
export const STATISTICS_VIEW_SAVE_FAILURE = 'STATISTICS_VIEW_SAVE_FAILURE'
export const STATISTICS_UPDATE_FILTER_TYPE = 'STATISTICS_UPDATE_FILTER_TYPE'
export const STATISTICS_UPDATE_FILTER_COUNTBY = 'STATISTICS_UPDATE_FILTER_COUNTBY'
export const STATISTICS_EXTEND_TIME_RANGE = 'STATISTICS_EXTEND_TIME_RANGE'
export const STATISTICS_CLEAR_EXTENDED_TIME_RANGE = 'STATISTICS_CLEAR_EXTENDED_TIME_RANGE'

export const STATISTICS_VIEW_EXPORT_PDF_TRIGGER = 'STATISTICS_VIEW_EXPORT_PDF_TRIGGER'
export const STATISTICS_VIEW_EXPORT_PDF = 'STATISTICS_VIEW_EXPORT_PDF'
export const STATISTICS_VIEW_EXPORT_PDF_RESET_TRIGGER = 'STATISTICS_VIEW_EXPORT_PDF_RESET_TRIGGER'
export const STATISTICS_VIEW_EXPORT_PDF_OPEN_MODAL = 'STATISTICS_VIEW_EXPORT_PDF_OPEN_MODAL'
export const STATISTICS_VIEW_EXPORT_PDF_CLOSE_MODAL = 'STATISTICS_VIEW_EXPORT_PDF_CLOSE_MODAL'
export const STATISTICS_VIEW_EXPORT_PDF_SUCCESS = 'STATISTICS_VIEW_EXPORT_PDF_SUCCESS'
export const STATISTICS_VIEW_EXPORT_PDF_FAILURE = 'STATISTICS_VIEW_EXPORT_PDF_FAILURE'
export const STATISTICS_VIEW_EXPORT_PDF_UPDATE_FILENAME = 'STATISTICS_VIEW_EXPORT_PDF_UPDATE_FILENAME'
export const STATISTICS_VIEW_EXPORT_PDF_UPDATE_TITLE = 'STATISTICS_VIEW_EXPORT_PDF_UPDATE_TITLE'

export const INVERT_FILTER = 'INVERT_FILTER'

export const FEED_SET_ACTIVE = 'FEED_SET_ACTIVE'
export const FEED_SET_ACTIVE_SUCCESS = 'FEED_SET_ACTIVE_SUCCESS'
export const FEED_SET_ACTIVE_FAILURE = 'FEED_SET_ACTIVE_FAILURE'
export const FEED_REMOVE_ACTIVE = 'FEED_REMOVE_ACTIVE'
export const FEED_SAVE = 'FEED_SAVE'
export const FEED_SAVE_SUCCESS = 'FEED_SAVE_SUCCESS'
export const FEED_SAVE_ERROR = 'FEED_SAVE_ERROR'
export const FEED_NEW = 'FEED_NEW'
export const FEED_NEW_SUCCESS = 'FEED_NEW_SUCCESS'
export const FEED_NEW_ERROR = 'FEED_NEW_ERROR'
export const FEED_DELETE = 'FEED_DELETE'
export const FEED_DELETE_CONFIRM = 'FEED_DELETE_CONFIRM'
export const FEED_DELETE_SUCCESS = 'FEED_DELETE_SUCCESS'
export const FEED_DELETE_ERROR = 'FEED_DELETE_ERROR'
export const FEED_DELETE_CANCEL = 'FEED_DELETE_CANCEL'
export const FEED_SEARCHTERM_CHANGED = 'FEED_SEARCHTERM_CHANGED'
export const FEED_ADD_FILTER = 'FEED_ADD_FILTER'
export const FEED_REMOVE_FILTER = 'FEED_REMOVE_FILTER'
export const FEED_TOGGLE_FILTER = 'FEED_TOGGLE_FILTER'
export const FEED_CLEAR_ALL = 'FEED_CLEAR_ALL'
export const FEED_TOGGLE_SETTING = 'FEED_TOGGLE_SETTING'
export const GO_TO_NEW_FEED = 'GO_TO_NEW_FEED'
export const GO_TO_NEW_FEED_ACTIONLINE = 'GO_TO_NEW_FEED_ACTIONLINE'
export const SAVE_FEED_VALID_FAILURE = 'SAVE_FEED_VALID_FAILURE'

export const STORE_CURRENT_SEARCHLINE = 'STORE_CURRENT_SEARCHLINE'

export const EDIT_ARTICLE_MODAL_OPEN = 'EDIT_ARTICLE_MODAL_OPEN'
export const EDIT_ARTICLE_MODAL_CLOSE = 'EDIT_ARTICLE_MODAL_CLOSE'

export const TEMPLATE_SAVE = 'TEMPLATE_SAVE'
export const TEMPLATE_SAVE_SUCCESS = 'TEMPLATE_SAVE_SUCCESS'
export const TEMPLATE_SAVE_FAILURE = 'TEMPLATE_SAVE_FAILURE'
export const TEMPLATE_NEW_SUCCESS = 'TEMPLATE_NEW_SUCCESS'
export const TEMPLATE_NEW_FAILURE = 'TEMPLATE_NEW_FAILURE'
export const TEMPLATE_DELETE = 'TEMPLATE_DELETE'
export const TEMPLATE_DELETE_SUCCESS = 'TEMPLATE_DELETE_SUCCESS'
export const TEMPLATE_DELETE_FAILURE = 'TEMPLATE_DELETE_FAILURE'

export const CONTACTS_FETCH = 'CONTACTS_FETCH'
export const CONTACTS_FETCH_SUCCESS = 'CONTACTS_FETCH_SUCCESS'
export const CONTACTS_FETCH_FAILURE = 'CONTACTS_FETCH_FAILURE'
export const CONTACTS_ACTIVE = 'CONTACTS_ACTIVE'
export const CONTACTS_GROUP_ACTIVE = 'CONTACTS_GROUP_ACTIVE'
export const CONTACT_SET_ACTIVE = 'CONTACT_SET_ACTIVE'
export const CONTACT_SET_ACTIVE_SUCCESS = 'CONTACT_SET_ACTIVE_SUCCESS'
export const CONTACT_SET_ACTIVE_FAILURE = 'CONTACT_SET_ACTIVE_FAILURE'
export const CONTACT_REMOVE_ACTIVE = 'CONTACT_REMOVE_ACTIVE'
export const CONTACT_DELETE_CONFIRM = 'CONTACT_DELETE_CONFIRM'
export const CONTACT_SAVE = 'CONTACT_SAVE'
export const CONTACT_DELETE = 'CONTACT_DELETE'
export const CONTACT_DELETE_CANCEL = 'CONTACT_DELETE_CANCEL'
export const CONTACT_SAVE_SUCCESS = 'CONTACT_SAVE_SUCCESS'
export const CONTACT_SAVE_ERROR = 'CONTACT_SAVE_ERROR'
export const CONTACT_INACTIVE_RESET = 'CONTACT_INACTIVE_RESET'
export const CONTACT_INACTIVE_RESET_SUCCESS = 'CONTACT_INACTIVE_RESET_SUCCESS '
export const CONTACT_INACTIVE_RESET_ERROR = 'CONTACT_INACTIVE_RESET_ERROR'
export const CONTACT_DELETE_ERROR = 'CONTACT_DELETE_ERROR'
export const CONTACT_USED_DELETE_ERROR = 'CONTACT_USED_DELETE_ERROR'
export const CONTACT_DELETE_SUCCESS = 'CONTACT_DELETE_SUCCESS'
export const CONTACT_GROUPS_CHECKED = 'CONTACT_GROUPS_CHECKED'
export const CONTACT_DETAIL_FETCH_SUCCESS = 'CONTACT_DETAIL_FETCH_SUCCESS'
export const CONTACT_DETAIL = 'CONTACT_DETAIL'
export const CONTACT_DETAILED_ERROR = 'CONTACT_DETAILED_ERROR'
export const ON_CONTACT_FILTER_TOGGLED = 'ON_CONTACT_FILTER_TOGGLED'
export const ON_CONTACT_FILTER_ADDED = 'ON_CONTACT_FILTER_ADDED'
export const ON_CONTACT_FILTER_REMOVED = 'ON_CONTACT_FILTER_REMOVED'
export const ON_CONTACTS_FILTER_EMPTIED = 'ON_CONTACTS_FILTER_EMPTIED'
export const GROUP_CONTACTS_IDS = 'GROUP_CONTACTS_IDS'
export const CONTACT_INIT_NEW = 'CONTACT_INIT_NEW'
export const CONTACT_FILTER_CHANGE = 'CONTACT_FILTER_CHANGE'
export const CLEAR_CONTACT_FILTER = 'CLEAR_CONTACT_FILTER'

export const GROUPS_FETCH = 'GROUPS_FETCH'
export const GROUPS_FETCH_SUCCESS = 'GROUPS_FETCH_SUCCESS'
export const GROUPS_FETCH_FAILURE = 'GROUPS_FETCH_FAILURE'
export const GROUP_SET_ACTIVE = 'GROUP_SET_ACTIVE'
export const GROUP_SET_ACTIVE_SUCCESS = 'GROUP_SET_ACTIVE_SUCCESS'
export const GROUP_SET_ACTIVE_FAILURE = 'GROUP_SET_ACTIVE_FAILURE'
export const GROUP_DETAIL_FETCH_SUCCESS = 'GROUP_DETAIL_FETCH_SUCCESS'
export const GROUP_REMOVE_ACTIVE = 'GROUP_REMOVE_ACTIVE'
export const GROUP_SAVE = 'GROUP_SAVE'
export const GROUP_SAVE_SUCCESS = 'GROUP_SAVE_SUCCESS'
export const GROUP_SAVE_ERROR = 'GROUP_SAVE_ERROR'
export const GROUP_INIT_NEW = 'GROUP_INIT_NEW'
export const GROUP_DELETE_SUCCESS = 'GROUP_DELETE_SUCCESS'
export const GROUP_DELETE_ERROR = 'GROUP_DELETE_ERROR'
export const GROUP_DELETE_CONFIRM = 'GROUP_DELETE_CONFIRM'
export const GET_GROUPS_DETAIL = 'GET_GROUPS_DETAIL'
export const GET_GROUPS_DETAIL_SUCCESS = 'GET_GROUPS_DETAIL_SUCCESS'
export const GET_GROUPS_DETAIL_FAILURE = 'GET_GROUPS_DETAIL_FAILURE'

/**
 * Filters are fetched from a suggestion server
 */
export const FILTERS_FETCH_SUCCESS = 'FILTERS_FETCH_SUCCESS'
export const FILTERS_FETCH_MULTIPLE_SUCCESS = 'FILTERS_FETCH_MULTIPLE_SUCCESS'
export const FILTERS_FETCH_MULTIPLE_OF_TYPE_SUCCESS = 'FILTERS_FETCH_MULTIPLE_OF_TYPE_SUCCESS'
export const FILTERS_POP = 'FILTERS_POP'
export const REFRESH_FILTERS_NAME = 'REFRESH_FILTERS_NAME'

export const PROFILES_FETCH = 'PROFILES_FETCH'
export const PROFILES_FETCH_SUCCESS = 'PROFILES_FETCH_SUCCESS'
export const PROFILES_FETCH_FAILURE = 'PROFILES_FETCH_FAILURE'

export const LOAD_EDITED_PROFILE = 'LOAD_EDITED_PROFILE'
export const LOAD_EDITED_PROFILE_SUCCESS = 'LOAD_EDITED_PROFILE_SUCCESS'
export const LOAD_EDITED_PROFILE_FAILURE = 'LOAD_EDITED_PROFILE_FAILURE'

export const TAGS_FETCH = 'TAGS_FETCH'
export const TAGS_FETCH_SUCCESS = 'TAGS_FETCH_SUCCESS'
export const TAGS_FETCH_FAILURE = 'TAGS_FETCH_FAILURE'
export const TAGS_REORDER = 'TAGS_REORDER'
export const TAGS_REORDER_SUCCESS = 'TAGS_REORDER_SUCCESS'

export const ANALYSIS_TAGS_FETCH = 'ANALYSIS_TAGS_FETCH'
export const ANALYSIS_TAGS_FETCH_SUCCESS = 'ANALYSIS_TAGS_FETCH_SUCCESS'
export const ANALYSIS_TAGS_FETCH_FAILURE = 'ANALYSIS_TAGS_FETCH_FAILURE'

export const SET_LAST_USED_TAG_ID = 'SET_LAST_USED_TAG_ID'
export const RESET_LAST_USED_TAG_ID = 'RESET_LAST_USED_TAG_ID'

export const ADD_TAG = 'ADD_TAG'
export const ADD_TAG_SUCCESS = 'ADD_TAG_SUCCESS'
export const ADD_TAG_FAILURE = 'ADD_TAG_FAILURE'

export const EDIT_TAG_POPOVER_OPEN = 'EDIT_TAG_POPOVER_OPEN'
export const EDIT_TAG = 'EDIT_TAG'
export const EDIT_TAG_SUCCESS = 'EDIT_TAG_SUCCESS'
export const EDIT_TAG_FAILURE = 'EDIT_TAG_FAILURE'

export const DELETE_TAG = 'DELETE_TAG'
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS'
export const DELETE_TAG_FAILURE = 'DELETE_TAG_FAILURE'

export const FEEDS_FETCH = 'FEEDS_FETCH'
export const FEEDS_FETCH_SUCCESS = 'FEEDS_FETCH_SUCCESS'
export const FEEDS_FETCH_FAILURE = 'FEEDS_FETCH_FAILURE'

export const COUNTRY_CODE_FETCH_SUCCESS = 'COUNTRY_CODE_FETCH_SUCCESS'

export const SETTINGS_FETCH = 'SETTINGS_FETCH'
export const SETTINGS_FETCH_SUCCESS = 'SETTINGS_FETCH_SUCCESS'
export const SETTINGS_FETCH_FAILURE = 'SETTINGS_FETCH_FAILURE'

export const SETTINGS_SAVE = 'SETTINGS_SAVE'
export const SETTINGS_SAVE_SUCCESS = 'SETTINGS_SAVE_SUCCESS'
export const SETTINGS_SAVE_FAILURE = 'SETTINGS_SAVE_FAILURE'

export const SETTINGS_TOGGLE_GROUPING = 'SETTINGS_TOGGLE_GROUPING'

export const ALERTS_FETCH = 'ALERTS_FETCH'
export const ALERTS_FETCH_SUCCESS = 'ALERTS_FETCH_SUCCESS'
export const ALERTS_FETCH_FAILURE = 'ALERTS_FETCH_FAILURE'

export const ALERTS_FETCH_RECIPIENTS = 'ALERTS_FETCH_RECIPIENTS'
export const ALERTS_FETCH_RECIPIENTS_SUCCESS = 'ALERTS_FETCH_RECIPIENTS_SUCCESS'
export const ALERTS_FETCH_RECIPIENTS_FAILURE = 'ALERTS_FETCH_RECIPIENTS_FAILURE'

export const ALERT_FETCH = 'ALERT_FETCH'
export const ALERT_FETCH_SUCCESS = 'ALERT_FETCH_SUCCESS'
export const ALERT_FETCH_FAILURE = 'ALERT_FETCH_FAILURE'

export const ALERT_FETCH_NEXT = 'ALERT_FETCH_NEXT'
export const ALERT_FETCH_NEXT_SUCCESS = 'ALERT_FETCH_NEXT_SUCCESS'
export const ALERT_FETCH_NEXT_FAILURE = 'ALERT_FETCH_NEXT_FAILURE'

export const ALERT_FETCH_NEXT_PREVIEW = 'ALERT_FETCH_NEXT_PREVIEW'
export const ALERT_FETCH_NEXT_PREVIEW_SUCCESS = 'ALERT_FETCH_NEXT_PREVIEW_SUCCESS'
export const ALERT_FETCH_NEXT_PREVIEW_FAILURE = 'ALERT_FETCH_NEXT_PREVIEW_FAILURE'

export const ALERT_FETCH_HISTORY = 'ALERT_FETCH_HISTORY'
export const ALERT_FETCH_HISTORY_SUCCESS = 'ALERT_FETCH_HISTORY_SUCCESS'
export const ALERT_FETCH_HISTORY_FAILURE = 'ALERT_FETCH_HISTORY_FAILURE'

export const ALERT_FETCH_HISTORY_ITEM = 'ALERT_FETCH_HISTORY_ITEM'
export const ALERT_FETCH_HISTORY_ITEM_SUCCESS = 'ALERT_FETCH_HISTORY_ITEM_SUCCESS'
export const ALERT_FETCH_HISTORY_ITEM_FAILURE = 'ALERT_FETCH_HISTORY_ITEM_FAILURE'

// Alert template preview related actions
export const ALERT_TEMPLATE_FETCH_SUCCESS = 'ALERT_TEMPLATE_FETCH_SUCCESS'
export const ALERT_CLEAR_TEMPLATE = 'ALERT_CLEAR_TEMPLATE'
export const ALERT_TEMPLATE_FETCH_FAILURE = 'ALERT_TEMPLATE_FETCH_FAILURE'
export const ALERT_PREVIEW_CLEAR = 'ALERT_PREVIEW_CLEAR'

// Alert Suggestions
export const ALERT_ADD_FILTER = 'ALERT_ADD_FILTER'
export const ALERT_REMOVE_FILTER = 'ALERT_REMOVE_FILTER'
export const ALERT_TOGGLE_FILTER = 'ALERT_TOGGLE_FILTER'
export const ALERT_CLEAR_ALL = 'ALERT_CLEAR_ALL'
export const ALERT_SEARCHTERM_CHANGED = 'ALERT_SEARCHTERM_CHANGED'
export const ALERT_REMOVE_ACTIVE = 'ALERT_REMOVE_ACTIVE'

export const ALERT_TAG_EXPAND = 'ALERT_TAG_EXPAND'

export const TOGGLE_UI_SETTING = 'TOGGLE_UI_SETTING'
export const SET_GLOBAL_TAG_VISIBILITY = 'SET_GLOBAL_TAG_VISIBLITY'
export const ENTITY_FILTER_CHANGED = 'ENTITY_FILTER_CHANGED'

export const FILTERS_REORDER = 'FILTERS_REORDER'

/**
 * UI router related actions
 */
export const SETTINGS_MODAL_OPEN = 'SETTINGS_MODAL_OPEN'
export const SETTINGS_MODAL_CLOSE = 'SETTINGS_MODAL_CLOSE'

export const MANAGE_PROFILES_MODAL_OPEN = 'MANAGE_PROFILES_MODAL_OPEN'
export const MANAGE_PROFILES_MODAL_CLOSE = 'MANAGE_PROFILES_MODAL_CLOSE'

export const REPORTS_HISTORY_OPEN = 'REPORTS_HISTORY_OPEN'
export const REPORTS_HISTORY_CLOSE = 'REPORTS_HISTORY_CLOSE'

export const SOURCE_LIST_OPEN = 'SOURCE_LIST_OPEN'
export const SOURCE_LIST_CLOSE = 'SOURCE_LIST_CLOSE'

export const HELP_MODAL_OPEN = 'HELP_MODAL_OPEN'
export const HELP_MODAL_CLOSE = 'HELP_MODAL_CLOSE'

export const CONTACT_MODAL_OPEN = 'CONTACT_MODAL_OPEN'
export const CONTACT_MODAL_CLOSE = 'CONTACT_MODAL_CLOSE'

export const FILTERS_PANEL_OPEN = 'FILTERS_PANEL_OPEN'
export const FILTERS_PANEL_CLOSE = 'FILTERS_PANEL_CLOSE'
export const FILTERS_PANEL_TOGGLE = 'FILTERS_PANEL_TOGGLE'

export const TOOLBAR_CHANGE = 'TOOLBAR_CHANGE'
export const TOOLBAR_PREVIOUS = 'TOOLBAR_PREVIOUS'
export const TOOLBAR_CHANGE_SUCCESS = 'TOOLBAR_CHANGE_SUCCESS'

export const FILTER_METAS_SHOW_DETAIL = 'FILTER_METAS_SHOW_DETAIL'
export const FILTER_METAS_HIDE_DETAIL = 'FILTER_METAS_HIDE_DETAIL'

export const TEMPLATE_PREVIEW_MODAL_OPEN = 'TEMPLATE_PREVIEW_MODAL_OPEN'
export const TEMPLATE_PREVIEW_MODAL_CLOSE = 'TEMPLATE_PREVIEW_MODAL_CLOSE'

export const TEMPLATE_PREVIEW_FETCH = 'TEMPLATE_PREVIEW_FETCH'
export const TEMPLATE_PREVIEW_FETCH_SUCCESS = 'TEMPLATE_PREVIEW_FETCH_SUCCESS'
export const TEMPLATE_PREVIEW_FETCH_FAILURE = 'TEMPLATE_PREVIEW_FETCH_FAILURE'

export const TEMPLATE_EDITOR_MODAL_OPEN = 'TEMPLATE_EDITOR_MODAL_OPEN'
export const TEMPLATE_EDITOR_MODAL_CLOSE = 'TEMPLATE_EDITOR_MODAL_CLOSE'

export const TEMPLATE_EDITOR_FORM_MODAL_OPEN = 'TEMPLATE_EDITOR_FORM_MODAL_OPEN'
export const TEMPLATE_EDITOR_FORM_MODAL_CLOSE = 'TEMPLATE_EDITOR_FORM_MODAL_CLOSE'

export const TEMPLATE_EDITOR_MODULE_SORT_MODAL_OPEN = 'TEMPLATE_EDITOR_MODULE_SORT_MODAL_OPEN'
export const TEMPLATE_EDITOR_MODULE_SORT_MODAL_CLOSE = 'TEMPLATE_EDITOR_MODULE_SORT_MODAL_CLOSE'
export const TEMPLATE_EDITOR_MODULE_SORT_MOVE_SETTING = 'TEMPLATE_EDITOR_MODULE_SORT_MOVE_SETTING'
/**
 * Alerts forms & scheduler
 */
export const INIT_FORM = 'INIT_FORM'
export const GO_TO_ALERT = 'GO_TO_ALERT'
export const GO_TO_ALERT_FAILURE = 'GO_TO_ALERT_FAILURE'
export const CLEAR_FORM = 'CLEAR_FORM'
export const INIT_SCHEDULE_ENTRY = 'INIT_SCHEDULE_ENTRY'
export const SET_SCHEDULE_ENTRY = 'SET_SCHEDULE_ENTRY'
export const DELETE_SCHEDULE_ENTRY = 'DELETE_SCHEDULE_ENTRY'
export const UPDATE_SCHEDULE_INTERVAL = 'UPDATE_SCHEDULE_INTERVAL'
export const SAVE_ALERT = 'SAVE_ALERT'
export const SAVE_ALERT_SUCCESS = 'SAVE_ALERT_SUCCESS'
export const SAVE_ALERT_FAILURE = 'SAVE_ALERT_FAILURE'
export const SAVE_ALERT_VALID_FAILURE = 'SAVE_ALERT_VALID_FAILURE'
export const ALERT_PREVIEW_OPEN = 'ALERT_PREVIEW_OPEN'
export const ALERT_PREVIEW_CLOSE = 'ALERT_PREVIEW_CLOSE'
export const ALERT_ADD_CANCEL = 'ALERT_ADD_CANCEL'
export const ALERT_EDIT_CANCEL = 'ALERT_EDIT_CANCEL'
export const DELETE_ALERT = 'DELETE_ALERT'
export const DELETE_ALERT_CONFIRM = 'DELETE_ALERT_CONFIRM'
export const ALERT_DELETE_SUCCESS = 'ALERT_DELETE_SUCCESS'
export const ALERT_DELETE_FAILURE = 'ALERT_DELETE_FAILURE'
export const ALERT_DELETE_CANCEL = 'ALERT_DELETE_CANCEL'
export const EDIT_ALERT = 'EDIT_ALERT'
export const SEND_ALERT_NOW = 'SEND_ALERT_NOW'
export const SEND_ALERT_HISTORY_ITEM_AGAIN = 'SEND_ALERT_HISTORY_ITEM_AGAIN'
export const ALERT_SEND_SUCCESS = 'ALERT_SEND_SUCCESS'
export const ALERT_HISTORY_SEND_AGAIN_SUCCESS = 'ALERT_HISTORY_SEND_AGAIN_SUCCESS'
export const ALERT_SEND_FAILURE = 'ALERT_SEND_FAILURE'
export const ALERT_INIT_MIFLUZ = 'ALERT_INIT_MIFLUZ'
export const CREATE_ALERT = 'CREATE_ALERT'
export const CREATE_ALERT_ACTIONLINE = 'CREATE_ALERT_ACTIONLINE'
export const CREATE_ALERT_FAILURE = 'CREATE_ALERT_FAILURE'
export const ALERT_REMOVE_ARTICLE_OPEN = 'ALERT_REMOVE_ARTICLE_OPEN'
export const ALERT_REMOVE_ARTICLE_CLOSE = 'ALERT_REMOVE_ARTICLE_CLOSE'
export const ALERT_FETCH_ARTICLES = 'ALERT_FETCH_ARTICLES'
export const ALERT_FETCH_ARTICLES_SUCCESS = 'ALERT_FETCH_ARTICLES_SUCCESS'
export const ALERT_FETCH_ARTICLES_FAILURE = 'ALERT_FETCH_ARTICLES_FAILURE'
export const ALERT_INIT_RECIPIENTS = 'ALERT_INIT_RECIPIENTS'
export const ALERT_CLEAR_RECIPIENTS = 'ALERT_CLEAR_RECIPIENTS'
export const LEAVE_ALERT_FAILURE = 'LEAVE_ALERT_FAILURE'
export const ALERT_CHANGE_RECIPIENTS_OPEN = 'ALERT_CHANGE_RECIPIENTS_OPEN'
export const ALERT_CHANGE_RECIPIENTS_CLOSE = 'ALERT_CHANGE_RECIPIENTS_CLOSE'

export const ALERT_FORM_STORE_INIT_VALUES = 'ALERT_FORM_STORE_INIT_VALUES'
export const ALERT_INITIALIZE_VALUES = 'ALERT_INITIATE_VALUES'
export const ASK_SAVE_EDITED_ALERT_OPEN = 'ASK_SAVE_EDITED_ALERT_OPEN'
export const ASK_SAVE_EDITED_ALERT_CLOSE = 'ASK_SAVE_EDITED_ALERT_CLOSE'
export const DISCARD_ALERTS_CHANGES_CONTINUE = 'DISCARD_ALERTS_CHANGES_CONTINUE'
export const SAVE_ALERTS_CHANGES_CONTINUE = 'SAVE_ALERTS_CHANGES_CONTINUE'

/**
 * Alerts - removal of articles related actions
 */
export const ALERT_DESTROY_ARTICLES = 'ALERT_DESTROY_ARTICLES'
export const ALERT_TRASH_ARTICLE = 'ALERT_TRASH_ARTICLE'
export const ALERT_UNTRASH_ARTICLE = 'ALERT_UNTRASH_ARTICLE'
export const ALERT_REMOVE_TRASHED_ARTICLES = 'ALERT_REMOVE_TRASHED_ARTICLES'
export const ALERT_REMOVE_TRASHED_ARTICLES_SUCCESS = 'ALERT_REMOVE_TRASHED_ARTICLES_SUCCESS'
export const ALERT_REMOVE_TRASHED_ARTICLES_FAILURE = 'ALERT_REMOVE_TRASHED_ARTICLES_FAILURE'
export const CLEAR_TRASHED_ARTICLES = 'CLEAR_TRASHED_ARTICLES'

/**
 * Reports
 */
export const REPORTS_MODAL_OPEN = 'REPORTS_MODAL_OPEN'
export const REPORTS_MODAL_CLOSE = 'REPORTS_MODAL_CLOSE'
export const REPORTS_RESET = 'REPORTS_RESET'
export const REPORTS_TEMPLATE = 'REPORTS_TEMPLATE'
export const REPORTS_SOURCE = 'REPORTS_SOURCE'
export const REPORTS_DATE_START = 'REPORTS_DATE_START'
export const REPORTS_DATE_END = 'REPORTS_DATE_END'
export const REPORTS_CHANGE_AUTO_TRANSLATE = 'REPORTS_CHANGE_AUTO_TRANSLATE'
export const REPORTS_ARTICLES = 'REPORTS_ARTICLES'
export const REPORTS_ARTICLES_SUCCESS = 'REPORTS_ARTICLES_SUCCESS'
export const REPORTS_ARTICLES_FAILURE = 'REPORTS_ARTICLES_FAILURE'
export const REPORTS_LOAD_INITIAL_ARTICLES = 'REPORTS_LOAD_INITIAL_ARTICLES'
export const REPORTS_REPORT_USE_HISTORY = 'REPORTS_REPORT_USE_HISTORY'
export const REPORTS_SHOW_DELETED_TOGGLE = 'REPORTS_SHOW_DELETED_TOGGLE'
export const REPORTS_COMPACT_LISTING_TOGGLE = 'REPORTS_COMPACT_LISTING_TOGGLE'
export const REPORTS_FILTER_MODE = 'REPORTS_FILTER_MODE'
export const REPORTS_HISTORY_FETCH = 'REPORTS_HISTORY_FETCH'
export const REPORTS_HISTORY_FETCH_SUCCESS = 'REPORTS_HISTORY_FETCH_SUCCESS'
export const REPORTS_HISTORY_FETCH_FAILURE = 'REPORTS_HISTORY_FETCH_FAILURE'
export const REPORTS_HISTORY_CHECK_TOGGLE = 'REPORTS_HISTORY_CHECK_TOGGLE'
export const REPORTS_ARTICLE_SORT = 'REPORTS_ARTICLES_SORT'
export const REPORTS_ARTICLE_MARK_TO_DELETE = 'REPORTS_ARTICLE_MARK_TO_DELETE'
export const REPORTS_SORT_TAG = 'REPORTS_SORT_TAG'
export const REPORTS_STEP = 'REPORTS_STEP'
export const REPORTS_STEP_SUCCESS = 'REPORTS_STEP_SUCCESS'
export const REPORTS_CUSTOM_SORT = 'REPORTS_CUSTOM_SORT'
export const REPORTS_CUSTOM_SORT_SUCCESS = 'REPORTS_CUSTOM_SORT_SUCCESS'
export const REPORTS_CREATE = 'REPORTS_CREATE'
export const REPORTS_CREATE_FAILURE = 'REPORTS_CREATE_FAILURE'
export const REPORTS_CREATE_IN_PROGRESS = 'REPORTS_CREATE_IN_PROGRESS'
export const REPORTS_CREATE_SUCCESS = 'REPORTS_CREATE_SUCCESS'
export const REPORTS_TITLE = 'REPORTS_TITLE'
export const REPORTS_PREFACE = 'REPORTS_PREFACE'
export const REPORTS_FOOTER = 'REPORTS_FOOTER'

export const SHARE_REPORT_CHANGE_MESSAGE = 'SHARE_REPORT_CHANGE_MESSAGE'
export const SHARE_REPORT_UPDATE_STEP = 'SHARE_REPORT_UPDATE_STEP'
export const SHARE_REPORT_TOGGLE_ATTACHMENT = 'SHARE_REPORT_TOGGLE_ATTACHMENT'
export const SHARE_REPORT = 'SHARE_REPORT'
export const DELETE_REPORT_NOTIFICATION = 'DELETE_REPORT_NOTIFICATION'
export const DELETE_REPORT_NOTIFICATION_SUCCESS = 'DELETE_REPORT_NOTIFICATION_SUCCESS'
export const DELETE_REPORT_NOTIFICATION_FAILURE = 'DELETE_REPORT_NOTIFICATION_FAILURE'

export const DELETE_REPORT = 'DELETE_REPORT'
export const DELETE_REPORT_SUCCESS = 'DELETE_REPORT_SUCCESS'
export const DELETE_REPORT_FAILURE = 'DELETE_REPORT_FAILURE'

export const REPORTS_SHARE_SUCCESS = 'REPORTS_SHARE_SUCCESS'
export const REPORTS_SHARE_FAILURE = 'REPORTS_SHARE_FAILURE'
export const REPORTS_VALID_SHARE_FAILURE = 'REPORTS_VALID_SHARE_FAILURE'

// Modal report history from popover
export const REPORTHISTORY_FETCH = 'REPORTHISTORY_FETCH'
export const REPORTHISTORY_FETCH_SUCCESS = 'REPORTHISTORY_FETCH_SUCCESS'
export const REPORTHISTORY_FETCH_FAILURE = 'REPORTHISTORY_FETCH_FAILURE'
export const REPORTHISTORY_SET_ACTIVE = 'REPORTHISTORY_SET_ACTIVE'
export const REPORTHISTORY_CLEAR = 'REPORTHISTORY_CLEAR'
export const CLEAR_SHARE_REPORT_RECIPIENTS = 'CLEAR_SHARE_REPORT_RECIPIENTS'
export const CLEAR_SHARE_REPORT_DATA = 'CLEAR_SHARE_REPORT_DATA'

/**
 * Router actions
 */

/**
 * Epics now handles this ROUTER_LOCATION_CHANGE - findsout if location was changed to chart,
 * if yes, handle search change in different action,
 * because we take search data from API. If it is normal
 * location change (profile, tag, etc),
 * just call ROUTER_SEARCH_DATA_CHANGE and fill store with data from query
 */
export const ROUTER_LOCATION_CHANGE = '@@router/LOCATION_CHANGE'
export const ROUTER_SEARCH_DATA_CHANGE = 'ROUTER_SEARCH_DATA_CHANGE'
export const ROUTE_CHANGE_FAILURE = 'ROUTE_CHANGE_FAILURE'

export const EDIT_ARTICLE = 'EDIT_ARTICLE'
export const EDIT_ARTICLE_SUCCESS = 'EDIT_ARTICLE_SUCCESS'
export const EDIT_ARTICLE_FAILURE = 'EDIT_ARTICLE_FAILURE'

export const SHARE_ARTICLES = 'SHARE_ARTICLES'
export const SHARE_ARTICLES_SUCCESS = 'SHARE_ARTICLES_SUCCESS'
export const SHARE_ARTICLES_FAILURE = 'SHARE_ARTICLES_FAILURE'

export const SHARE_ARTICLES_CHANGE_MESSAGE = 'SHARE_ARTICLES_CHANGE_MESSAGE'
export const SHARE_ARTICLES_TOGGLE_ATTACHMENT_TOGGLE = 'SHARE_ARTICLES_TOGGLE_ATTACHMENT_TOGGLE'

export const SHARE_ARTICLE_MODAL_OPEN = 'SHARE_ARTICLE_MODAL_OPEN'
export const SHARE_ARTICLE_MODAL_CLOSE = 'SHARE_ARTICLE_MODAL_CLOSE'

export const EDIT_ARTICLE_PREVIEW_MODE_ON = 'EDIT_ARTICLE_PREVIEW_MODE_ON'
export const EDIT_ARTICLE_PREVIEW_MODE_OFF = 'EDIT_ARTICLE_PREVIEW_MODE_OFF'

export const DESTROY_ARTICLE = 'DESTROY_ARTICLE'
export const UNDELETE_ARTICLE = 'UNDELETE_ARTICLE'

export const TAG_ARTICLES = 'TAG_ARTICLES'
export const TAG_ARTICLES_FAILURE = 'TAG_ARTICLES_FAILURE'
export const TAG_SINGLE_ARTICLE = 'TAG_SINGLE_ARTICLE'
export const UNTAG_ARTICLES_FAILURE = 'UNTAG_ARTICLES_FAILURE'
export const UNTAG_ARTICLES = 'UNTAG_ARTICLES'
export const UNTAG_SINGLE_ARTICLE = 'UNTAG_SINGLE_ARTICLE'
export const TOGGLE_TAG_ARTICLE = 'TOGGLE_TAG_ARTICLE'
export const TOGGLE_TAG_IDENTICAL_ARTICLE = 'TOGGLE_TAG_IDENTICAL_ARTICLE'
export const INCREMENT_TAG_WEIGHT_FOR_ARTICLE = 'INCREMENT_TAG_WEIGHT_FOR_ARTICLE'
export const DECREMENT_TAG_WEIGHT_FOR_ARTICLE = 'DECREMENT_TAG_WEIGHT_FOR_ARTICLE'
export const INCREMENT_TAG_WEIGHT_FOR_SINGLE_ARTICLE = 'INCREMENT_TAG_WEIGHT_FOR_SINGLE_ARTICLE'
export const DECREMENT_TAG_WEIGHT_FOR_SINGLE_ARTICLE = 'DECREMENT_TAG_WEIGHT_FOR_SINGLE_ARTICLE'
export const TAG_VISIBILITY_TOGGLE = 'TAG_VISIBILITY_TOGGLE'
export const REMOVE_TAGS_FROM_ARTICLES = 'REMOVE_TAGS_FROM_ARTICLES'

export const NEXT_ACTIVE_ARTICLE = 'NEXT_ACTIVE_ARTICLE'
export const PREVIOUS_ACTIVE_ARTICLE = 'PREVIOUS_ACTIVE_ARTICLE'

export const NEXT_IDENTICAL = 'NEXT_IDENTICAL'
export const PREVIOUS_IDENTICAL = 'PREVIOUS_IDENTICAL'
export const SET_ACTIVE_IDENTICAL = 'SET_ACTIVE_IDENTICAL'

export const UNCHECK_ALL_ARTICLES = 'UNCHECK_ALL_ARTICLES'
export const CHECK_ARTICLE_TOGGLE = 'CHECK_ARTICLE_TOGGLE'

export const ON_PROFILE_FILTER_TOGGLED = 'ON_PROFILE_FILTER_TOGGLED'
export const DELETE_PROFILES_MODE_ENABLE = 'DELETE_PROFILES_MODE_ENABLE'
export const DELETE_PROFILES_MODE_DISABLE = 'DELETE_PROFILES_MODE_DISABLE'
export const DELETE_PROFILES_MODE_TOGGLE = 'DELETE_PROFILES_MODE_TOGGLE'
export const PROFILE_MARK_FOR_DELETE_MODE = 'PROFILE_MARK_FOR_DELETE_MODE'
export const PROFILE_DELETE = 'PROFILE_DELETE'
export const PROFILE_DELETE_SUCCESS = 'PROFILE_DELETE_SUCCESS'
export const PROFILE_DELETE_FAILURE = 'PROFILE_DELETE_FAILURE'
export const PROFILE_EDITOR_DELETE_CONFIRM = 'PROFILE_EDITOR_DELETE_CONFIRM'
export const PROFILE_DELETE_CANCEL = 'PROFILE_DELETE_CANCEL'
export const PROFILE_DELETE_CONFIRMATION_OPEN = 'PROFILE_DELETE_CONFIRMATION_OPEN'
export const PROFILE_EDITOR_DELETE_CONFIRMATION_OPEN = 'PROFILE_EDITOR_DELETE_CONFIRMATION_OPEN'
export const PROFILE_DELETE_CONFIRMATION_CLOSE = 'PROFILE_DELETE_CONFIRMATION_CLOSE'
export const PROFILES_GET_DEPENDENCIES = 'PROFILES_GET_DEPENDENCIES'
export const PROFILES_GET_DEPENDENCIES_SUCCESS = 'PROFILES_GET_DEPENDENCIES_SUCCESS'

export const PROFILE_EXPAND_HISTORY = 'PROFILE_EXPAND_HISTORY'
export const PROFILE_SHRINK_HISTORY = 'PROFILE_SHRINK_HISTORY'
export const PROFILE_HISTORY_FETCH = 'PROFILE_HISTORY_FETCH'
export const PROFILE_HISTORY_FETCH_SUCCESS = 'PROFILE_HISTORY_FETCH_SUCCESS'
export const PROFILE_HISTORY_FETCH_FAILURE = 'PROFILE_HISTORY_FETCH_FAILURE'
export const DELETED_PROFILES_EXPAND = 'DELETED_PROFILES_EXPAND'
export const DELETED_PROFILES_HISTORY_FETCH = 'DELETED_PROFILES_HISTORY_FETCH'
export const DELETED_PROFILES_HISTORY_FETCH_SUCCESS = 'DELETED_PROFILES_HISTORY_FETCH_SUCCESS'
export const DELETED_PROFILES_HISTORY_FETCH_FAILURE = 'DELETED_PROFILES_HISTORY_FETCH_FAILURE'
export const PROFILE_SET_OLD_VERSION = 'PROFILE_SET_OLD_VERSION'
export const PROFILE_HISTORY_SHOW_MORE = 'PROFILE_HISTORY_SHOW_MORE'

export const TOGGLE_DELETE_ARTICLES = 'TOGGLE_DELETE_ARTICLES'
export const DELETE_ARTICLES = 'DELETE_ARTICLES'
export const DELETE_ARTICLES_FAILURE = 'DELETE_ARTICLES_FAILURE'
export const UNDELETE_ARTICLES = 'UNDELETE_ARTICLES'
export const DELETE_ARTICLES_GLOBALLY_TOOLBAR = 'DELETE_ARTICLES_GLOBALLY_TOOLBAR'
export const DELETE_ARTICLES_PROFILES_TOOLBAR = 'DELETE_ARTICLES_PROFILES_TOOLBAR'
export const UNDELETE_ARTICLES_FAILURE = 'UNDELETE_ARTICLES_FAILURE'
export const DELETE_ARTTICLE_PERMANENTLY = 'DELETE_ARTICLE_PERMANENTLY'
export const SET_ACTIVE_ARTICLE = 'SET_ACTIVE_ARTICLE'
export const ADD_ARTICLE_MODAL_OPEN = 'ADD_ARTICLE_MODAL_OPEN'
export const ADD_ARTICLE_MODAL_CLOSE = 'ADD_ARTICLE_MODAL_CLOSE'
export const ADD_ARTICLE_INIT = 'ADD_ARTICLE_INIT'
export const ADD_ARTICLE = 'ADD_ARTICLE'
export const ADD_ARTICLE_SUCCESS = 'ADD_ARTICLE_SUCCESS'
export const ADD_ARTICLE_FAILURE = 'ADD_ARTICLE_FAILURE'
export const ADD_ARTICLE_FILTER_CHANGE = 'ADD_ARTICLE_FILTER_CHANGE'
export const ADD_ARTICLE_TOGGLE_TAG = 'ADD_ARTICLE_TOGGLE_TAG'
export const ADD_ARTICLE_SEARCHTERM_CHANGED = 'ADD_ARTICLE_SEARCHTERM_CHANGED'
export const ADD_ARTICLE_SEARCHTERM_COUNTRY_CHANGED = 'ADD_ARTICLE_SEARCHTERM_COUNTRY_CHANGED'
export const ADD_ARTICLE_TRIGGER_SUGGESTION = 'ADD_ARTICLE_TRIGGER_SUGGESTION'
export const ADD_ARTICLE_TRIGGER_COUNTRY_SUGGESTION = 'ADD_ARTICLE_TRIGGER_COUNTRY_SUGGESTION'
export const ADD_ARTICLE_SEARCHDATA_CLEAR = 'ADD_ARTICLE_SEARCHDATA_CLEAR'
export const ADD_ARTICLE_SEARCHDATA_COUNTRY_CLEAR = 'ADD_ARTICLE_SEARCHDATA_COUNTRY_CLEAR'
export const ADD_ARTICLE_SUGGESTIONS_SUCCESS = 'ADD_ARTICLE_SUGGESTIONS_SUCCESS'
export const ADD_ARTICLE_SUGGESTIONS_COUNTRY_SUCCESS = 'ADD_ARTICLE_SUGGESTIONS_COUNTRY_SUCCESS'
export const ADD_ARTICLE_SUGGESTIONS_FAILURE = 'ADD_ARTICLE_SUGGESTIONS_FAILURE'
export const ADD_ARTICLE_SUGGESTIONS_COUNTRY_FAILURE = 'ADD_ARTICLE_SUGGESTIONS_COUNTRY_FAILURE'
export const GET_GROUPS_MEDIATYPES = 'GET_GROUPS_MEDIATYPES'
export const GET_GROUPS_COUNTRY_MEDIATYPES = 'GET_GROUPS_COUNTRY_MEDIATYPES'
export const ADD_ARTICLE_SEARCHFILTER_ADDED = 'ADD_ARTICLE_SEARCHFILTER_ADDED'
export const ADD_ARTICLE_SEARCHFILTER_COUNTRY_ADDED = 'ADD_ARTICLE_SEARCHFILTER_COUNTRY_ADDED'
export const ADD_ARTICLE_SEARCHFILTER_REMOVED = 'ADD_ARTICLE_SEARCHFILTER_REMOVED'
export const ADD_ARTICLE_SEARCHFILTER_COUNTRY_REMOVED = 'ADD_ARTICLE_SEARCHFILTER_COUNTRY_REMOVED'
export const ADD_ARTICLE_SEARCHFILTER_TOGGLED = 'ADD_ARTICLE_SEARCHFILTER_TOGGLED'
export const ADD_ARTICLE_SEARCHFILTER_COUNTRY_TOGGLED = 'ADD_ARTICLE_SEARCHFILTER_COUNTRY_TOGGLED'
export const ADD_ARTICLE_SOURCE_TOUCHED = 'ADD_ARTICLE_SOURCE_TOUCHED'
export const SET_OWN_ARTICLE_SOURCE = 'SET_OWN_ARTICLE_SOURCE'
export const ADD_ARTICLE_COUNTRY_TOUCHED = 'ADD_ARTICLE_COUNTRY_TOUCHED'
export const ADD_ARTICLE_DATE_CHANGE = 'ADD_ARTICLE_DATE_CHANGE'
export const ADD_ARTICLE_FILE_UPLOAD = 'ADD_ARTICLE_FILE_UPLOAD'
export const ADD_ARTICLE_FILE_UPLOAD_SUCCESS = 'ADD_ARTICLE_FILE_UPLOAD_SUCCESS'
export const ADD_ARTICLE_FILE_UPLOAD_FAILURE = 'ADD_ARTICLE_FILE_UPLOAD_FAILURE'
export const ADD_ARTICLE_FILE_REORDER = 'ADD_ARTICLE_FILE_REORDER'
export const ADD_ARTICLE_FILE_UNSUPPORTED_FILE_ERROR = 'ADD_ARTICLE_FILE_UNSUPPORTED_FILE_ERROR'
export const ADD_ARTICLE_FILE_UPLOAD_DELETE_ITEM = 'ADD_ARTICLE_FILE_UPLOAD_DELETE_ITEM'
export const ADD_ARTICLE_INCREMENT_TAG_WEIGHT = 'ADD_ARTICLE_INCREMENT_TAG_WEIGHT'
export const ADD_ARTICLE_DECREMENT_TAG_WEIGHT = 'ADD_ARTICLE_DECREMENT_TAG_WEIGHT'

export const ADD_ARTICLE_TRIGGER_LANG_SUGGESTION = 'ADD_ARTICLE_TRIGGER_LANG_SUGGESTION'
export const ADD_ARTICLE_SEARCHTERM_LANG_CHANGED = 'ADD_ARTICLE_SEARCHTERM_LANG_CHANGED'
export const ADD_ARTICLE_SEARCHDATA_LANG_CLEAR = 'ADD_ARTICLE_SEARCHDATA_LANG_CLEAR'
export const ADD_ARTICLE_SEARCHFILTER_LANG_ADDED = 'ADD_ARTICLE_SEARCHFILTER_LANG_ADDED'
export const ADD_ARTICLE_LANG_ADDED = 'ADD_ARTICLE_LANG_ADDED'
export const ADD_ARTICLE_SEARCHFILTER_LANG_REMOVED = 'ADD_ARTICLE_SEARCHFILTER_LANG_REMOVED'
export const ADD_ARTICLE_SEARCHFILTER_LANG_TOGGLED = 'ADD_ARTICLE_SEARCHFILTER_LANG_TOGGLED'
export const GET_GROUPS_LANG_MEDIATYPES = 'GET_GROUPS_LANG_MEDIATYPES'
export const ADD_ARTICLE_SUGGESTIONS_LANG_FAILURE = 'ADD_ARTICLE_SUGGESTIONS_LANG_FAILURE'
export const ADD_ARTICLE_SUGGESTIONS_LANG_SUCCESS = 'ADD_ARTICLE_SUGGESTIONS_LANG_SUCCESS'

export const NOTIFICATIONS_COUNT_FETCH_SUCCESS = 'NOTIFICATIONS_COUNT_FETCH_SUCCESS'
export const NOTIFICATIONS_FETCH = 'NOTIFICATIONS_FETCH'
export const NOTIFICATIONS_FETCH_SUCCESS = 'NOTIFICATIONS_FETCH_SUCCESS'
export const NOTIFICATIONS_FETCH_FAILURE = 'NOTIFICATIONS_FETCH_FAILURE'
export const NOTIFICATIONS_SOCKET_SUCCESS = 'NOTIFICATIONS_SOCKET_SUCCESS'
export const NOTIFICATIONS_INC_COUNT = 'NOTIFICATIONS_INC_COUNT'
export const NOTIFICATION_DOWNLOAD_MODAL_OPEN = 'NOTIFICATION_DOWNLOAD_MODAL_OPEN'
export const NOTIFICATION_DOWNLOAD_MODAL_CLOSE = 'NOTIFICATION_DOWNLOAD_MODAL_CLOSE'
export const NOTIFICATIONS_OPEN = 'NOTIFICATIONS_OPEN'

export const TRASH_FETCH = 'TRASH_FETCH'
export const TRASH_FETCH_SUCCESS = 'TRASH_FETCH_SUCCESS'
export const TRASH_FETCH_FAILURE = 'TRASH_FETCH_FAILURE'
export const TRASH_TOGGLE_VISIBILITY = 'TRASH_TOGGLE_VISIBILITY'

export const STATISTICS_ASPECT_TRY_TOGGLE = 'STATISTICS_ASPECT_TRY_TOGGLE'
export const STATISTICS_ASPECT_TOGGLE = 'STATISTICS_ASPECT_TOGGLE'
export const STATISTICS_ASPECT_OVERLAP_CHANGE = 'STATISTICS_ASPECT_OVERLAP_CHANGE'
export const STATISTICS_ASPECT_OVERLAP_MODE_TOGGLE = 'STATISTICS_ASPECT_OVERLAP_MODE_TOGGLE'
export const STATISTICS_FILTER_CHANGED = 'STATISTICS_FILTER_CHANGED'
export const STATISTICS_FILTER_RESET = 'STATISTICS_FILTER_RESET'
export const STATISTICS_FILTER_RESET_ALL = 'STATISTICS_FILTER_RESET_ALL'
export const STATISTICS_COUNT_BY_CHANGED = 'STATISTICS_COUNT_BY_CHANGED'
export const STATISTICS_TAG_LISTS = 'STATISTICS_TAG_LISTS'
export const STATISTICS_ASPECT_TAG_TOGGLED = 'STATISTICS_ASPECT_TAG_TOGGLED'
export const STATISTICS_ASPECT_RESEND = 'STATISTICS_ASPECT_RESEND'

export const CLOSE_EDITED_TAG = 'CLOSE_EDITED_TAG'

export const STATISTICS_SHOW_FILTERED = 'STATISTICS_SHOW_FILTERED'
export const STATISTICS_HIDE_FILTERED = 'STATISTICS_HIDE_FILTERED'
export const STATISTICS_UPDATE_FILTERED_ARTICLES = 'STATISTICS_UPDATE_FILTERED_ARTICLES'
export const STATISTICS_CLEAR_FILTERED_ARTICLES = 'STATISTICS_CLEAR_FILTERED_ARTICLES'
export const STATISTICS_CLEAR_FILTERS = 'STATISTICS_CLEAR_FILTERS'
export const STATISTICS_CLEAR_REQUESTED_ASPECTS = 'STATISTICS_CLEAR_REQUESTED_ASPECTS'

/**
 * Routing related actions
 */
export const PROFILE_EDITOR_BACK_TO_SEARCH = 'PROFILE_EDITOR_BACK_TO_SEARCH'
export const SEARCH_GO_TO_STATISTICS = 'SEARCH_GO_TO_STATISTICS'
export const SEARCH_GO_TO_NEWS = 'SEARCH_GO_TO_NEWS'
export const SEARCH_REPLACE_FILTERS = 'SEARCH_REPLACE_FILTERS'
export const SEARCH_GO_TO_EDIT_PROFILE = 'SEARCH_GO_TO_EDIT_PROFILE'

export const DATEPICKER_MODAL_OPEN = 'DATEPICKER_MODAL_OPEN'
export const DATEPICKER_MODAL_CLOSE = 'DATEPICKER_MODAL_CLOSE'
export const DATEPICKER_MODAL_SET_START_DATE = 'DATEPICKER_MODAL_SET_START_DATE'
export const DATEPICKER_MODAL_SET_END_DATE = 'DATEPICKER_MODAL_SET_END_DATE'

export const NO_DEFAULT_HOME_PAGE = 'NO_DEFAULT_HOME_PAGE'

export const SEARCH_TOGGLE_TRANSLATE_AUTO = 'SEARCH_TOGGLE_TRANSLATE_AUTO'

/**
 * Watch index
 */
export const UPDATE_WATCH_INDEXES = 'UPDATE_WATCH_INDEXES'
export const UPDATE_WATCH_INDEXES_SUCCESS = 'UPDATE_WATCH_INDEXES_SUCCESS'
export const UPDATE_WATCH_INDEXES_FAILURE = 'UPDATE_WATCH_INDEXES_FAILURE'

export const UPDATE_WATCH_INDEXES_MAPPING = 'UPDATE_WATCH_INDEXES_MAPPING'
export const UPDATE_CURRENT_WATCH_ID = 'UPDATE_CURRENT_WATCH_ID'
export const RESET_WATCH_INDEX_FOR_PROFILE = 'RESET_WATCH_INDEX_FOR_PROFILE'

export const FETCH_ARTICLES_WITH_WATCH_ID = 'FETCH_ARTICLES_WITH_WATCH_ID'
export const FETCH_ARTICLES_WITH_WATCH_ID_CANCEL = 'FETCH_ARTICLES_WITH_WATCH_ID_CANCEL'
export const FETCH_ARTICLES_WITH_WATCH_ID_SUCCESS = 'FETCH_ARTICLES_WITH_WATCH_ID_SUCCESS'
export const FETCH_ARTICLES_WITH_WATCH_ID_FAILURE = 'FETCH_ARTICLES_WITH_WATCH_ID_FAILURE'

/**
 * UI related actions
 */
export const GO_TO_DEFAULT_PROFILE = 'GO_TO_DEFAULT_PROFILE'
export const SCROLL_TO_TOP = 'SCROLL_TO_TOP'

export const SHOW_BAD_BROWSER_POPUP = 'SHOW_BAD_BROWSER_POPUP'
export const HIDE_BAD_BROWSER_POPUP = 'HIDE_BAD_BROWSER_POPUP'
export const RELEASE_NOTES_MODAL_OPEN = 'RELEASE_NOTES_MODAL_OPEN'
export const RELEASE_NOTES_MODAL_HIDE = 'RELEASE_NOTES_MODAL_HIDE'

export const SEND_ARTICLE_PAYMENT_INFO = 'SEND_ARTICLE_PAYMENT_INFO'
export const SEND_ARTICLE_PAYMENT_INFO_SUCCESS = 'SEND_ARTICLE_PAYMENT_INFO_SUCCESS'

import React, { ReactNode } from 'react'

import styles from './Match.module.scss'

export interface Props {
  children: ReactNode
  color?: '4' | '5' | '6' | '7' | '8' | '9' | '10'
}

export default function Match({ children, color = '4' }: Props) {
  return (
    <span className={styles.wrapper}>
      <span color={color}>{children}</span>
    </span>
  )
}

import React from 'react'
import PropTypes from 'prop-types'

import { getArticleBadge } from './ArticleBadge'

export default class ArticleSourceLineSource extends React.PureComponent<any> {
  static propTypes = {
    sourceIsLink: PropTypes.bool,
    badge: PropTypes.bool,
    article: PropTypes.object.isRequired,
  }

  static defaultProps = {
    badge: false,
    sourceIsLink: false,
  }

  getSourceLine = () => {
    const { article } = this.props
    return article.first_source.name || article.first_source.sitename || ''
  }

  render() {
    const { article, sourceIsLink, badge } = this.props
    const sourceLink =
      !sourceIsLink || (article.orig_url && article.mediatype.text === 'PRINT') ? (
        <span>{this.getSourceLine()}</span>
      ) : (
        <a href={article.first_source.url} target="_blank" rel="noopener noreferrer">
          {this.getSourceLine()}
          <i className="op-icon-expand" />
        </a>
      )

    return (
      <div className="source-line__item">
        <div className="source-line__item source-line__item--source">{sourceLink}</div>
        {badge && getArticleBadge(article) && (
          <div className="source-line__item source-line__item--badge">{getArticleBadge(article)}</div>
        )}
      </div>
    )
  }
}

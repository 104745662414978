// @ts-nocheck

import R from 'ramda'
import Rx from 'rx-dom'
import copy from 'copy-to-clipboard'
import moment from 'moment'

import config from '../common/config'
import type { Contact, Group, ContactDetails, Recipient, ContactFilter, CommonRecipientFilter } from '../flow'

// @sig Contact -> Contact -> boolean
export const eqContacts = R.eqBy(R.prop('id'))

// @sig ContactFilter -> ContactFilter -> boolean
export const eqContactFilters = R.allPass([R.eqBy(R.prop('type')), R.eqBy(R.path(['entity', 'id']))])

// @sig ContactFilter -> string
export const getContactFilterName = R.cond([
  [R.propEq('type', 'person'), ({ entity: contact }) => getContactFullName(contact)],
  [R.propEq('type', 'group'), R.path(['entity', 'name'])],
  [R.propEq('type', 'email'), R.path(['entity', 'value'])],
  [R.propEq('type', 'phoneNumber'), R.path(['entity', 'value'])],
  [R.T, R.always('Unknown type')],
])

export const getContactTypeAndName = R.cond([
  [R.propEq('type', 'person'), (contact) => ({ name: getContactFullName(contact.entity), type: 'Contact' })],
  [R.propEq('type', 'group'), (group) => ({ name: R.path(['entity', 'name'], group), type: 'Group' })],
  [R.propEq('type', 'email'), (email) => ({ name: R.path(['entity', 'value'], email), type: 'Email' })],
  [
    R.propEq('type', 'phoneNumber'),
    (phoneNumber) => ({ name: R.path(['entity', 'value'], phoneNumber), type: 'Phone number' }),
  ],
  [R.T, R.always({ name: 'Unknown type', type: 'Unknown type' })],
])

export const contactRecipientDataTransformToFilter = (
  recipients: Recipient,
  listByType: { person: Array<ContactDetails>; group: Array<Group> },
) =>
  R.map(
    (recipient) => ({
      type: recipient.type,
      entity: listByType[recipient.type]
        ? R.find(R.propEq('id', recipient.id))(listByType[recipient.type])
        : {
            ...recipient,
            id: recipient.value,
          },
    }),
    recipients,
  )

/**
 * Retrieves contacts from API and transforms them into a Promise.
 * @returns {*}
 */
export function getContacts() {
  const requestHeaders = R.merge(config.getRequestHeaders(), {
    url: config.getUrl('/api/contacts/'),
  })
  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function getContactFullName(contact: Contact) {
  return `${contact.firstName} ${contact.lastName}`
}

export function getContactDetail(contactId: number) {
  const requestHeaders = R.merge(config.getRequestHeaders(), {
    url: config.getUrl(`/api/contacts/${contactId}/`),
  })
  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}
export function saveContact(contactId: number, updatedData: Contact) {
  const requestHeaders = R.merge(config.getRequestHeaders(), {
    url: contactId ? config.getUrl(`/api/contacts/${contactId}/`) : config.getUrl('/api/contacts/'),
    method: contactId ? 'PUT' : 'POST',
    body: JSON.stringify(updatedData),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function createContact(newContact: Contact) {
  const requestHeaders = R.merge(config.getRequestHeaders(), {
    url: config.getUrl('/api/contacts/'),
    method: 'POST',
    body: JSON.stringify(newContact),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function deleteContact(contactId: number, locale: string = 'en-GB', forceFlag: boolean = false) {
  const requestHeaders = R.merge(config.getRequestHeaders(), {
    url: config.getUrl(`/api/contacts/${contactId}${forceFlag ? '/?force=force/' : '/'}/`),
    method: 'DELETE',
    responseType: 'text',
  })
  const requestHeadersWithLanguage = R.assocPath(['headers', 'accept-language'], locale, requestHeaders)

  return Rx.DOM.ajax(requestHeadersWithLanguage)
    .toPromise()
    .then(({ response }) => response)
}

export function getGroups() {
  const requestHeaders = R.merge(config.getRequestHeaders(), {
    url: config.getUrl('/api/contact-groups/'),
  })
  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function getGroupDetail(groupId: number) {
  const requestHeaders = R.merge(config.getRequestHeaders(), {
    url: config.getUrl(`/api/contact-groups/${groupId}/`),
  })
  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function saveGroup(groupId: number, updatedData: Group) {
  const requestHeaders = R.merge(config.getRequestHeaders(), {
    url: groupId ? config.getUrl(`/api/contact-groups/${groupId}/`) : config.getUrl('/api/contact-groups/'),
    method: groupId ? 'PUT' : 'POST',
    body: JSON.stringify(updatedData),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

export function deleteGroup(groupId: number, locale: string = 'en-GB') {
  const requestHeaders = R.merge(config.getRequestHeaders(), {
    url: config.getUrl(`/api/contact-groups/${groupId}/`),
    method: 'DELETE',
    responseType: 'text',
  })
  const requestHeadersWithLanguage = R.assocPath(['headers', 'accept-language'], locale, requestHeaders)

  return Rx.DOM.ajax(requestHeadersWithLanguage)
    .toPromise()
    .then(({ response }) => response)
}

/*
Create initial values for redux form
*/
export function createEmptyContactForm() {
  return {
    firstName: null,
    lastName: null,
    email: null,
    mobile: null,
    pause: null,
    groups: [],
  }
}

export const checkedGroupProp = 'checked' // name of group prop for redux form checkbox(True/False)

export function checkGroup(group: Group) {
  return R.assoc(checkedGroupProp, true, group)
}

export const sortByProp: (propToBeSortBy: string, groupList: Array<Group>) => Array<Group> = R.curry((prop, list) =>
  R.sortBy(
    R.compose(
      R.when(
        R.is(String),
        R.toLower, // upper case props won't be before lowercase props
      ),
      R.prop(prop),
    ),
    list,
  ),
)

/*
  Add all existing groups into empty contact
*/
export function createEmptyContactWithGroups(groups: Array<Group>) {
  return R.assoc('groups', groups, createEmptyContactForm())
}

/*
  Concat all existing groups with groups that belogs to specific contact, without duplicates
*/
export function joinCheckGroupsWithRest(groupList: Array<Group>, contactGroups: Array<Group> = []) {
  const checkedGroups = R.map(checkGroup, contactGroups) // set check for contact specific groups
  const uncheckedGroups = R.differenceWith((x, y) => R.eqProps('id', x, y), groupList, checkedGroups)
  return R.concat(checkedGroups, uncheckedGroups)
}

/*
  Add all correct(checked/unchecked) groups into contact object
 */
export function addCheckedGroupsToContact(contactDetails: ContactDetails, groupsList: Array<Group>): Array<Group> {
  return R.assoc(
    'groups',
    sortByProp('id', joinCheckGroupsWithRest(groupsList, R.propOr([], 'groups', contactDetails))),
    contactDetails,
  )
}

/*
  Replace every checked group with it's ID, other groups are filtered

  Back-end takes array of group IDs in order to identify to which group
  contact belongs to.
*/
export function replaceCheckedGroupsWithId(contact: Contact) {
  return R.evolve(
    {
      groups: R.compose(
        R.pluck('id'),
        R.filter((group) => R.prop('checked', group)),
      ),
    },
    contact,
  )
}

/*
  Check if timestamp value is validly defined and in the future, because if
  pause timestamp is in the past it's not active
*/
export function isAlertPauseTimeStampValid(timestamp: number): boolean {
  if (timestamp) {
    const timestampToMoment = moment.unix(timestamp)
    return timestampToMoment.isValid() && timestampToMoment.isAfter(moment())
  }
  return false
}

// Copy recipients info to clipboard
export const getRecipientsNumbersString = (recipients: Array<ContactFilter | CommonRecipientFilter>, info: string) => {
  const groupsIds = []
  const numbers = []
  recipients.forEach((recipient) => {
    switch (recipient.type) {
      case 'person':
        numbers.push(recipient.entity[info])
        break
      case 'phoneNumber': // TODO constants for that @dmytro
      case 'email':
        numbers.push(recipient.entity.value)
        break
      case 'group':
        groupsIds.push(recipient.entity.id)
        break
      default:
        break
    }
  })

  Promise.all(groupsIds.map(getGroupDetail)).then((res) => {
    res.forEach((group) => {
      group.contacts.forEach((contact) => numbers.push(contact[info]))
    })

    copy(R.uniq(numbers).join(', '))
  })

  return numbers.length > 0 && groupsIds.length === 0
}

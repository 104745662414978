import React from 'react'
import { connect } from 'react-redux'

import ArticleListing from '../ArticleListing'
import Aspects from './aspects'

const SidebarComponent = ({ showFilteredArticles }) => (showFilteredArticles ? <ArticleListing /> : <Aspects />)

const Sidebar = connect((state: any) => ({
  showFilteredArticles: state.statistics.showFilteredArticles, // TODO selector @dmytro
}))(SidebarComponent)

export default Sidebar

// @ts-nocheck
import React from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import PropTypes from 'prop-types'
import TimePicker from 'rc-time-picker'
import { translate } from 'react-i18next'

import Translate from '../../Translate'
import type { LocaleKey, Action } from '../../opoint/flow'

type OpointDatepickerProps = {
  startDate: Object
  endDate?: Object
  onStartDateChange: (key: string) => Action<string>
  onEndDateChange?: (key: string) => Action<string>
  timePicker: boolean
  dateRange: boolean
  maxDate?: Object
  minDate?: Object
  locale: LocaleKey
  lastReportDate: number
  startDatePickerProps: Object
  hideLastReportDate: boolean
}

type State = {
  startTime: any
  endTime: any
  isInvalid: boolean
}
// Display placeholder for start and end of the day or time for all other values
function displayTime(time, isEndOfDay: boolean = false) {
  const start = time.clone()
  const end = time.clone()

  if (isEndOfDay) {
    return time.isBetween(start.hour(23).minute(59).startOf('minute'), end.endOf('day'), null, '[]') ? null : time
  }
  return time.isBetween(start.startOf('day'), end.hour(0).minute(0).endOf('minute'), null, '[]') ? null : time
}

function handleTimeChange(currentTime: any, changeHandler: Function, isEndOfDay: boolean = false) {
  return (change: any) => {
    // ignore time change on close
    if (!(currentTime && change)) {
      return
    }

    // Clone moment objects to prevent mutation
    const cT = currentTime.clone()
    const ch = isEndOfDay ? change.clone().endOf('minute') : change.clone().startOf('minute')
    const chDate = change.clone().startOf('day')

    const newDate = cT.startOf('day').add(ch.diff(chDate), 'milliseconds')
    changeHandler(newDate.toISOString())
  }
}

function onRenderClickHandler(time: Object, change: Object, clickHandler: Function, isEndOfDay: boolean, panel: any) {
  return () => {
    handleTimeChange(time, clickHandler, isEndOfDay)(change)
    panel.close()
  }
}

const handleTodayButtonClick = (changeHandler: Function, date: any) => (event) => {
  event.preventDefault()
  changeHandler(date.toISOString())
}

const isToday = (date) => moment(date).isSame(new Date(), 'day')

// TODO: write test for time conversion logic
class OpointDatepicker extends React.PureComponent<OpointDatepickerProps, State> {
  static defaultProps = {
    timePicker: true,
    dateRange: true,
  }

  static contextTypes = {
    i18n: PropTypes.object,
  }

  constructor(props: OpointDatepickerProps) {
    super(props)
    this.renderTimepickerControls = this.renderTimepickerControls.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.onTimeChange = this.onTimeChange.bind(this)
    this.onCloseChangeTime = this.onCloseChangeTime.bind(this)

    this.state = {
      startTime: null,
      endTime: null,
      isInvalid: false,
    }
  }

  componentDidUpdate(prevProps) {
    const { startDate, endDate } = this.props
    const { startDate: prevStart, endDate: prevEnd } = prevProps

    // Skip invalid date range check if there is not date range
    if (endDate === undefined) {
      return
    }

    // Skip if there is not change in date range
    if (prevStart === startDate && prevEnd === endDate) {
      return
    }

    const isInvalid = moment(endDate).isBefore(startDate)
    // eslint-disable-next-line
    this.setState({isInvalid})
  }

  onTimeChange(isEndTime: boolean = false) {
    return (change: Object) => {
      isEndTime ? this.setState({ endTime: change }) : this.setState({ startTime: change })
    }
  }

  onCloseChangeTime() {
    this.setState({ endTime: null, startTime: null })
  }

  // onClick handler changes startDate = lastReportDate
  sinceLastReportDateChange = () => {
    const { lastReportDate, onStartDateChange } = this.props

    onStartDateChange(moment.unix(lastReportDate))
  }

  handleDateChange(changeHandler: Function, isEndOfDay: boolean = false) {
    return (change: Object) => {
      const { timePicker } = this.props

      if (!timePicker) {
        return changeHandler(change)
      }

      const ch = change.clone()
      const newDate = isEndOfDay ? ch.endOf('day') : ch.startOf('day')

      return changeHandler(newDate.toISOString())
    }
  }

  // Render start/end day reset button in timepicker
  renderTimepickerControls(
    change: Object,
    clickHandler: Function,
    isEndOfDay: boolean = false,
    hideLastReportDate: boolean = false,
  ) {
    return (panel: any) => {
      const { endDate, startDate, lastReportDate } = this.props
      const { i18n } = this.context
      const label: string = isEndOfDay ? i18n.t('End of day') : i18n.t('Start of day')
      const time: Object = isEndOfDay && endDate ? endDate : startDate

      const selectTime: Object = isEndOfDay ? this.state.endTime : this.state.startTime

      return (
        <div className="opoint-timepicker__addon">
          <button
            className="opoint-timepicker__button"
            onClick={onRenderClickHandler(time, change, clickHandler, isEndOfDay, panel)}
          >
            {label}
          </button>
          {!isEndOfDay && !hideLastReportDate && (
            <button
              className="opoint-timepicker__button ifDisabled"
              onClick={this.sinceLastReportDateChange}
              disabled={!lastReportDate}
            >
              <Translate i18nString="Since last report" />
            </button>
          )}
          <button
            className="opoint-timepicker__button opoint-timepicker__button--green"
            onClick={onRenderClickHandler(time, selectTime, clickHandler, isEndOfDay, panel)}
          >
            <Translate i18nString="Select" />
          </button>
        </div>
      )
    }
  }

  render() {
    const {
      startDate,
      endDate,
      onStartDateChange,
      onEndDateChange,
      dateRange,
      timePicker,
      maxDate,
      minDate,
      locale,
      startDatePickerProps,
      hideLastReportDate,
    } = this.props
    const { i18n } = this.context

    return (
      <div className="opoint-datepicker">
        <div className="opoint-datepicker__picker opoint-datepicker__picker--start">
          <DatePicker
            selected={startDate.clone().startOf('day')}
            onChange={this.handleDateChange(onStartDateChange)}
            startDate={dateRange ? startDate.clone().startOf('day') : null}
            endDate={dateRange && endDate ? endDate.clone().endOf('day') : null}
            selectsStart={dateRange ? true : null}
            showMonthDropdown
            showYearDropdown
            inline
            locale={locale || 'en-gb'}
            minDate={minDate}
            maxDate={maxDate || moment().startOf('day')}
            {...startDatePickerProps}
          />
          {timePicker && (
            <div className="opoint-datepicker__picker__modal-buttons">
              <button
                className={
                  isToday(startDate) ? 'opoint-timepicker__button--today' : 'opoint-timepicker__button--today--shaded'
                }
                onClick={handleTodayButtonClick(onStartDateChange, moment().startOf('day'))}
                title={!isToday(startDate) ? i18n.t('Click to select today') : i18n.t('Today is selected')}
              >
                {i18n.t('Today ')}
              </button>
              <TimePicker
                placeholder={i18n.t('Start of day')}
                value={this.state.startTime || displayTime(startDate)}
                defaultOpenValue={startDate.clone().startOf('day')}
                onChange={this.onTimeChange(false)}
                onClose={this.onCloseChangeTime}
                showSecond={false}
                addon={this.renderTimepickerControls(
                  moment().startOf('day'),
                  onStartDateChange,
                  false,
                  hideLastReportDate,
                )}
                placement="bottomLeft"
                align={{
                  points: ['bc', 'bc'],
                  offset: [0, 0],
                }}
              />
            </div>
          )}
        </div>
        {dateRange && endDate && onEndDateChange && (
          <div className="opoint-datepicker__picker opoint-datepicker__picker--end">
            <DatePicker
              selected={endDate.clone().endOf('day')}
              onChange={this.handleDateChange(onEndDateChange, true)}
              startDate={startDate.clone().startOf('day')}
              endDate={endDate.clone().endOf('day')}
              selectsEnd
              showMonthDropdown
              showYearDropdown
              inline
              locale={locale || 'en-gb'}
              minDate={minDate}
              maxDate={maxDate || moment().startOf('day')}
            />
            {timePicker && (
              <div className="opoint-datepicker__picker__modal-buttons">
                <button
                  className={
                    isToday(endDate) ? 'opoint-timepicker__button--today' : 'opoint-timepicker__button--today--shaded'
                  }
                  onClick={handleTodayButtonClick(onEndDateChange, moment().endOf('day'))}
                  title={!isToday(endDate) ? i18n.t('Click to select today') : i18n.t('Today is selected')}
                >
                  {i18n.t('Today ')}
                </button>
                <TimePicker
                  placeholder={i18n.t('End of day')}
                  value={this.state.endTime || displayTime(endDate, true)}
                  defaultOpenValue={endDate.clone().endOf('day')}
                  onChange={this.onTimeChange(true)}
                  onClose={this.onCloseChangeTime}
                  showSecond={false}
                  addon={this.renderTimepickerControls(moment().endOf('day'), onEndDateChange, true)}
                  placement="bottomLeft"
                  align={{
                    points: ['bc', 'bc'],
                    offset: [0, 0],
                  }}
                />
              </div>
            )}
          </div>
        )}
        {this.state.isInvalid && (
          <span className="opoint-datepicker__error">
            {i18n.t('This date range is invalid - end date can not be before start date')}
          </span>
        )}
      </div>
    )
  }
}

export default translate([], { wait: true })(OpointDatepicker)

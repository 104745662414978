import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { translate } from 'react-i18next'

import buildActionCreators from '../../../helpers/buildActionCreators'
import Translate from '../../../Translate'
import TemplateEditorForm from './Form'
import { getTemplateEditName } from '../../../selectors/templatesSelectors'
import { isEditorFormModalOpen } from '../../../selectors/uiSelectors'
import * as ActionTypes from '../../../constants/actionTypes'

type Props = {
  isOpen: boolean
  templateEditName: string
  closeEditorModal: () => void
}

class EditTemplateModal extends React.PureComponent<Props> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  render() {
    const { isOpen, closeEditorModal, templateEditName } = this.props
    const { i18n } = this.context

    return (
      <Modal show={isOpen} onHide={closeEditorModal}>
        <Modal.Header>
          <button type="button" className="close" onClick={closeEditorModal}>
            ×
          </button>
          <h4 className="modal-title">
            <Translate i18nString="Edit template" />
            {`: ${templateEditName || `${i18n.t('Loading')}...`}`}
          </h4>
        </Modal.Header>
        <Modal.Body className="op-modal-settings">
          <div className="op-modal-settings">
            {/*
            @ts-ignore */}
            <TemplateEditorForm />
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

// @ts-ignore
EditTemplateModal = translate([], { wait: true })(EditTemplateModal)

// @ts-ignore
EditTemplateModal = connect(
  (state) => ({
    isOpen: isEditorFormModalOpen(state),
    templateEditName: getTemplateEditName(state),
  }),
  buildActionCreators({
    closeEditorModal: ActionTypes.TEMPLATE_EDITOR_FORM_MODAL_CLOSE,
  }),
)(EditTemplateModal)

export default EditTemplateModal

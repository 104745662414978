import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import classNames from 'classnames'

import * as ActionTypes from '../../constants/actionTypes'
import buildActionCreators from '../../helpers/buildActionCreators'
import {
  getContactsSortedByName,
  getGroups,
  getEditedGroup,
  getExpandedContacts,
  getActiveContactData,
  getExpandedGroups,
} from '../../selectors/contactSelectors'
import ContactEditor from './ContactEditor'
import GroupEditor from './GroupEditor'
import Translate from '../../Translate'
import type { Contact } from '../../opoint/flow'

type ContactProps = {
  sortedContacts: Array<Contact>
  expandContacts: () => any
  onSaveContact: () => {}
}

export class ContactsFormComponent extends React.PureComponent<any> {
  setActiveContactHandler = (contact) => () => this.props.setActiveContact(contact)
  setActiveGroupHandler = (group) => () => this.props.setActiveGroup(group)

  handleSubmit = (e) => {
    e.preventDefault()
    const { onSaveContact, expandedContacts, onSaveGroup } = this.props
    expandedContacts ? onSaveContact() : onSaveGroup()
  }

  render() {
    const {
      activeContact,
      activeGroup,
      expandContacts,
      expandGroup,
      expandedContacts,
      expandedGroups,
      groups,
      initNewContact,
      initNewGroup,
      sortedContacts,
      userPermissions,
    } = this.props

    return (
      <form style={{ height: '100%' }} onSubmit={this.handleSubmit}>
        <div className="op-modal-contacts-left">
          <div className="op-modal-contacts-panels">
            <div className={classNames('op-modal-contacts-panels-panel', { 'is-active': expandedContacts })}>
              <div
                className="op-modal-contacts-panels-panel-heading"
                data-test="contacts-show"
                onClick={expandContacts}
              >
                <Translate i18nString="Contacts" />
                <i className="op-icon-circle-plus" data-test="contact-add" onClick={initNewContact} />
              </div>
              <div className="op-modal-contacts-panels-panel-list-loader_wrapper" />
              {!sortedContacts.length && (
                <div className="op-modal-contacts-panels-panel-list-no-content">
                  <Translate i18nString="No contacts." />
                </div>
              )}
              <ul className="op-modal-contacts-panels-panel-list">
                {sortedContacts.map((contact) => (
                  <li
                    className={classNames('op-modal-contacts-panels-panel-list-item', {
                      'is-active': activeContact && activeContact.id === contact.id,
                    })}
                    onClick={this.setActiveContactHandler(contact)}
                    key={contact.id}
                    title={contact.firstName}
                  >
                    {`${contact.firstName} ${contact.lastName}`}
                  </li>
                ))}
              </ul>
            </div>
            <div className={classNames('op-modal-contacts-panels-panel', { 'is-active': expandedGroups })}>
              <div className="op-modal-contacts-panels-panel-heading" onClick={expandGroup}>
                <Translate i18nString="Groups" />
                <i data-test="contact-add" onClick={initNewGroup} className="op-icon-circle-plus" />
              </div>
              <div className="op-modal-contacts-panels-panel-list-loader_wrapper" />
              {!groups.length && (
                <div className="op-modal-contacts-panels-panel-list-no-content">
                  <Translate i18nString="No groups" />.
                </div>
              )}
              <ul className="op-modal-contacts-panels-panel-list">
                {groups.map((group) => (
                  <li
                    className={classNames('op-modal-contacts-panels-panel-list-item', {
                      'is-active': activeGroup && activeGroup.id === group.id,
                    })}
                    onClick={this.setActiveGroupHandler(group)}
                    key={group.id}
                    title={group.name}
                  >
                    {group.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <fieldset disabled={userPermissions === 'C'} className="op-modal-contacts-right">
          {expandedContacts ? <ContactEditor /> : <GroupEditor />}
        </fieldset>
      </form>
    )
  }
}

const ContactsReduxForm = reduxForm({
  form: 'contacts',
  // @ts-ignore
})(ContactsFormComponent)

const ContactsForm = connect(
  (state) => ({
    sortedContacts: getContactsSortedByName(state),
    groups: getGroups(state),
    activeGroup: getEditedGroup(state),
    activeContact: getActiveContactData(state),
    expandedContacts: getExpandedContacts(state),
    expandedGroups: getExpandedGroups(state),
  }),
  buildActionCreators({
    expandContacts: ActionTypes.CONTACTS_ACTIVE,
    expandGroup: ActionTypes.CONTACTS_GROUP_ACTIVE,
    setActiveContact: ActionTypes.CONTACT_SET_ACTIVE,
    setActiveGroup: ActionTypes.GROUP_SET_ACTIVE,
    onSaveContact: ActionTypes.CONTACT_SAVE,
    onSaveGroup: ActionTypes.GROUP_SAVE,
    initNewContact: ActionTypes.CONTACT_INIT_NEW,
    initNewGroup: ActionTypes.GROUP_INIT_NEW,
  }),
  // @ts-ignore
)(ContactsReduxForm)

export default ContactsForm

import React from 'react'
import PropTypes from 'prop-types'
import { OverlayTrigger, Popover } from 'react-bootstrap'

class GraphControls extends React.PureComponent<any> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  popoverRef: any

  onClickHandler = (action) => {
    const { graph } = this.props
    return () => {
      action(graph)
      this.popoverRef.hide()
    }
  }

  setDropdownHeight = (dropdown: Element): void => {
    const SPACING = 20
    const { height, top } = dropdown.getBoundingClientRect()
    const bodyH = document.body.clientHeight

    // set lower height of dropdown in case of page overflow or leave original height
    // @ts-ignore
    dropdown.style.height = `${Math.min(bodyH - (top + SPACING), height)}px`
  }

  render() {
    const { menu } = this.props
    const { i18n } = this.context

    const overlayMenu = (
      <Popover id="graph-menu-popover" bsClass="dropdown-menu">
        {menu.map((menuSection, i) => (
          <div key={i}>
            {menuSection.map(([name, action, type], index) =>
              // filter out items for different type of graph
              !type || type === this.props.type ? (
                <li key={index} onClick={this.onClickHandler(action)} className="btn btn-link">
                  {name}
                </li>
              ) : null,
            )}
            {i + 1 < menu.length && <li className="divider" />}
          </div>
        ))}
      </Popover>
    )
    return (
      <div className="control-container">
        <OverlayTrigger
          rootClose
          trigger="click"
          placement="bottom"
          overlay={overlayMenu}
          onEntering={this.setDropdownHeight}
          ref={(ref) => {
            this.popoverRef = ref
          }}
        >
          <a>
            <i className="op-icon-circle-plus" title={i18n.t('Open chart menu')} />
          </a>
        </OverlayTrigger>
      </div>
    )
  }
}

export default GraphControls

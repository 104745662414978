import React from 'react'
import PropTypes from 'prop-types'
import R from 'ramda'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'

import buildActionCreators from '../../helpers/buildActionCreators'
import {
  getLoadMoreSearchInProgress,
  getReceivedDocumentsCount,
  isSearchInProgress,
  searchIsFinished,
} from '../../selectors/searchSelectors'
import * as ActionTypes from '../../constants/actionTypes'

class LoadMoreComponent extends React.PureComponent<any> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  onClickHandler = () => {
    const { fetchMoreArticles, searchIsFinished } = this.props
    !searchIsFinished && fetchMoreArticles()
  }

  render() {
    const { searchInProgress, loadMoreSearchInProgress, searchIsFinished, receivedDocumentsCount } = this.props

    if (receivedDocumentsCount === 0) {
      return null
    }

    const { i18n } = this.context

    if (searchInProgress) {
      return null
    }
    return (
      <div className="op-button-load-more" onClick={this.onClickHandler}>
        <span>
          {R.cond([
            [R.always(searchIsFinished), R.always(i18n.t('No more articles'))],
            [R.always(loadMoreSearchInProgress), R.always(`${i18n.t('Loading more articles')}...`)],
            [R.T, R.always(i18n.t('Load more'))],
          ])()}
        </span>
      </div>
    )
  }
}

const LoadMoreTranslate = translate([], { wait: true })(LoadMoreComponent)

const LoadMore = connect(
  (state) => ({
    searchIsFinished: searchIsFinished(state),
    searchInProgress: isSearchInProgress(state),
    loadMoreSearchInProgress: getLoadMoreSearchInProgress(state),
    receivedDocumentsCount: getReceivedDocumentsCount(state),
  }),
  buildActionCreators({
    fetchMoreArticles: ActionTypes.FETCH_MORE_ARTICLES,
  }),
)(LoadMoreTranslate)

export default LoadMore

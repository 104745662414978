import React from 'react'

const Loader = () => (
  <div className="loader">
    <div className="bounce1" />
    <div className="bounce2" />
    <div className="bounce3" />
  </div>
)

export default Loader

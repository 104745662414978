//
// THIS FILE WAS GENERATED BY SCRIPT, DO NOT MODIFY IT MANUALLY!
// INSTEAD RUN:
// yarn fetch-strings
// Usage - this file DOES NOT return correct translated string, but english string
// If you just render label, it will NEVER change language because i18n init is second init, so it is always
// set to default (english) language. To use it, you need to call this string in an i18n.t() function (it will be
// translated, because this file will ensure that it is added on transifex)
//
// If anything remains unclear, ask @oliver
//

import i18n from './i18nextInit'

const GENERATED_BACKEND_TRANSLATIONS = {
  templates: {
    RT_XLS_TEMPLATE: i18n.t('Template'),
    RT_INC_PDF: i18n.t('Include original sources'),
    RT_INC_PDF_THUMB: i18n.t('Include PDF thumbnail'),
    RT_INC_PDF_SINGLE: i18n.t('Include PDF single page'),
    RT_GEOM_COLUMNS: i18n.t('Columns per page'),
    RT_GEOM_COLUMNS_1: i18n.t('1 column'),
    RT_GEOM_COLUMNS_2: i18n.t('2 columns'),
    RT_INC_LOGO: i18n.t('Include logo'),
    RT_INC_HTML_LOGO: i18n.t('Include logo'),
    RT_INC_FRONT_PAGE: i18n.t('Include front page'),
    RT_INC_TOC: i18n.t('Include TOC'),
    RT_INC_TOC_LIST: i18n.t('List of articles'),
    RT_INC_TOC_DATE: i18n.t('Dates'),
    RT_INC_TOC_MATCHES: i18n.t('Matches'),
    RT_INC_TOC_ORIG_LINK: i18n.t('Original article links'),
    RT_INC_TOC_QUOTES: i18n.t('Quotes'),
    RT_INC_TOC_SOURCE: i18n.t('Sources'),
    RT_TEXT_ONLY: i18n.t('Create as plain text'),
    RT_INC_GENERATE_DATE: i18n.t('Creation date'),
    RT_INC_REGISTER: i18n.t('Include index'),
    RT_INC_BODY: i18n.t('Include body'),
    RT_INC_METADATA: i18n.t('Include metadata'),
    RT_INC_THUMB: i18n.t('Include thumbnails'),
    RT_INC_SCREENSHOT_THUMB: i18n.t('Include screenshot thumbnails'),
    RT_INC_SCREENSHOT: i18n.t('Include article screenshots'),
    RT_INC_ARTPIC: i18n.t('Include article images'),
    RT_INC_IDENTICAL: i18n.t('Include identical articles'),
    RT_INC_IDENTICAL_1: i18n.t('Grouped articles'),
    RT_INC_IDENTICAL_2: i18n.t('All articles'),
    RT_INC_IDENTICAL_0: i18n.t('Unique articles'),
    RT_INC_SINGLE: i18n.t('Page breaks'),
    RT_INC_HEADER_LABEL: i18n.t('Include header'),
    RT_INC_ARTICLE_CNT: i18n.t('Number of articles'),
    RT_INC_MATCHES: i18n.t('List matches'),
    RT_INC_QUOTES: i18n.t('Context'),
    RT_INC_AUTHOR: i18n.t('Author'),
    RT_INC_SUMMARY: i18n.t('Summary'),
    RT_GEOM_PAPER_TYPE: i18n.t('Paper type'),
    RT_GEOM_PAPER_TYPE_A3PAPER: i18n.t('A3'),
    RT_GEOM_PAPER_TYPE_B3PAPER: i18n.t('B3'),
    RT_GEOM_PAPER_TYPE_A4PAPER: i18n.t('A4'),
    RT_GEOM_PAPER_TYPE_B4PAPER: i18n.t('B4'),
    RT_GEOM_PAPER_TYPE_A5PAPER: i18n.t('A5'),
    RT_GEOM_PAPER_TYPE_B5PAPER: i18n.t('B5'),
    RT_GEOM_PAPER_TYPE_LETTERPAPER: i18n.t('Letter'),
    RT_GEOM_PAPER_SIDES: i18n.t('Paper sides'),
    RT_GEOM_PAPER_SIDES_1: i18n.t('1 side'),
    RT_GEOM_PAPER_SIDES_2: i18n.t('2 sides'),
    RT_GEOM_ORIENTATION: i18n.t('Paper orientation'),
    RT_GEOM_ORIENTATION_PORTRAIT: i18n.t('Portrait'),
    RT_GEOM_ORIENTATION_LANDSCAPE: i18n.t('Landscape'),
    RT_GEOM_LINE_SPACING: i18n.t('Line spacing'),
    RT_INC_QR: i18n.t('Include QR codes'),
    RT_INC_QR_1: i18n.t('For report'),
    RT_INC_QR_2: i18n.t('For articles'),
    RT_INC_QR_0: i18n.t('Include QR codes'),
    RT_INC_PAGE_NUMBER: i18n.t('Page numbers'),
    RT_INC_LINK: i18n.t('Readable link'),
    RT_INC_LINK_TYPE: i18n.t('Link type'),
    RT_INC_HREF: i18n.t('Clickable links'),
    RT_INC_SITE_NAME: i18n.t('Use site name'),
    RT_INC_LINK_TOP: i18n.t('Include top link'),
    RT_LINK_UNDERLINE: i18n.t('Underline article links'),
    RT_INC_SUBSCR_LINK: i18n.t('Include subscribe/unsubscribe links'),
    RT_INC_LINK_BROWSER: i18n.t('Include link to online report'),
    RT_INC_LOGIN_LINK: i18n.t('Include login link'),
    RT_INC_DATE: i18n.t('Include dates'),
    RT_INC_OVERLAY: i18n.t('Include overlay frame'),
    RT_USE_COLORS: i18n.t('Use colors'),
    RT_XML_SPLIT: i18n.t('Split XML'),
    RT_COMPRESSION: i18n.t('Optimize PDF for'),
    RT_COMPRESSION_0: i18n.t('Speed'),
    RT_COMPRESSION_2: i18n.t('Printer'),
    RT_COMPRESSION_3: i18n.t('Screen'),
    RT_COMPRESSION_4: i18n.t('E-book'),
    RT_COMPRESSION_6: i18n.t('Safe printing'),
    RT_IMAGE_POS: i18n.t('Image position'),
    RT_IMAGE_POS_FALSE: i18n.t('No image'),
    RT_IMAGE_POS_RIGHT: i18n.t('Right'),
    RT_IMAGE_POS_LEFT: i18n.t('Left'),
    RT_INC_REDIR: i18n.t('Include redirection link'),
    RT_INC_MARK_MATCHES: i18n.t('Highlight matches in text'),
    RT_INC_MARK_MATCHES_BF: i18n.t('Bold text'),
    RT_INC_MARK_MATCHES_BL: i18n.t('Blue text'),
    RT_INC_MARK_MATCHES_HL: i18n.t('Gray background'),
    RT_INC_MARK_MATCHES_IT: i18n.t('Italic text'),
    RT_INC_MARK_MATCHES_NO: i18n.t('None'),
    RT_INC_MARK_MATCHES_TR: i18n.t('Letter spacing'),
    RT_INC_MARK_MATCHES_UL: i18n.t('Underline'),
    RT_INC_PDF_GROUP: i18n.t('Group for include original sources'),
    RT_INC_IMAGES_GROUP: i18n.t('Include images'),
    RT_GEOM_FONT_FAMILY: i18n.t('Font Family'),
    RT_GEOM_FONT_FAMILY_ARIAL: i18n.t('Arial'),
    RT_GEOM_FONT_FAMILY_AVANT: i18n.t('Avant'),
    RT_GEOM_FONT_FAMILY_CALIBRI: i18n.t('Calibri'),
    RT_GEOM_FONT_FAMILY_CHARTER: i18n.t('Charter'),
    RT_GEOM_FONT_FAMILY_COMPUTER_MODERN: i18n.t('Computer modern'),
    RT_GEOM_FONT_FAMILY_DROID_MONO: i18n.t('Droid mono'),
    RT_GEOM_FONT_FAMILY_HELVETICA: i18n.t('Helvetica'),
    RT_GEOM_FONT_SIZE: i18n.t('Font Size'),
    RT_GEOM_MARGIN: i18n.t('Margin'),
    RT_DELAY: i18n.t('Delay of used articles'),
    RT_DELAY_0: i18n.t('No delay'),
    RT_DELAY_1800: i18n.t('30 minutes'),
    RT_DELAY_3600: i18n.t('1 hour'),
    RT_DELAY_10800: i18n.t('3 hours'),
    RT_DELAY_43200: i18n.t('12 hours'),
    RT_GEOM_WIDTH: i18n.t('Page width'),
    RT_GEOM_TITLE_SIZE: i18n.t('Title size'),
    RT_GEOM_IMAGE_WIDTH: i18n.t('Image width'),
    RT_INC_COL_DATE: i18n.t('Date'),
    RT_INC_COL_SOURCE: i18n.t('Source'),
    RT_INC_COL_SOURCE_DEPTH: i18n.t('Source depth'),
    RT_INC_COL_LANGUAGE: i18n.t('Language'),
    RT_INC_COL_COUNTRY_CODE: i18n.t('Country code'),
    RT_INC_COL_CAPTION: i18n.t('Caption'),
    RT_INC_COL_PICTURE_URL: i18n.t('Picture url'),
    RT_INC_COL_OGAE: i18n.t('OGAE'),
    RT_INC_COL_HEADLINE: i18n.t('HEADLINE'),
    RT_INC_COL_SUMMARY: i18n.t('Summary'),
    RT_INC_COL_ARTICLE_TEXT: i18n.t('Article text'),
    RT_INC_COL_ARTICLE_URL: i18n.t('Article url'),
    RT_INC_COL_REDIR: i18n.t('Redir url'),
    RT_INC_SENTIMENT: i18n.t('Include sentiment tags and weights'),
    RT_INC_COL_ID_SITE: i18n.t('Id of site'),
    RT_INC_COL_ID_SOURCE: i18n.t('Id of source'),
    RT_INC_COL_ID_ARTICLE: i18n.t('Id of article'),
    RT_INC_COL_SITENAME: i18n.t('Sitename'),
    RT_GEOM_SLIDE_CHAR_COUNT: i18n.t('Max characters per slide'),
    RT_TEMPLATE_STYLE: i18n.t('Template style'),
    RT_TEMPLATE_STYLE_MODERN: i18n.t('Modern'),
    RT_TEMPLATE_STYLE_TRADITIONAL: i18n.t('Traditional'),
    RT_INC_WORD_COUNT: i18n.t('Include word count'),
    RT_BACKGROUND_STYLE: i18n.t('Background style for html report'),
    RT_BACKGROUND_STYLE_LIGHT: i18n.t('Light'),
    RT_BACKGROUND_STYLE_DARK: i18n.t('Dark'),
    RT_BACKGROUND_STYLE_BLUE_GRADIENT: i18n.t('Blue with gradient'),
    RT_INC_COL_MEDIA_TYPE: i18n.t('Media type'),
    RT_INC_COL_COVERAGE: i18n.t('Coverage'),
    RT_INC_COL_SPEAKER: i18n.t('Speaker'),
    RT_INC_COL_CIRCULATION: i18n.t('Circulation'),
    RT_INC_COL_REACH: i18n.t('Reach'),
    RT_INC_COL_ADS_PRICE: i18n.t('Ad price'),
    RT_INC_COL_WORD_COUNT: i18n.t('Word count'),
    RT_INC_COL_AUTHOR: i18n.t('Author'),
    RT_INC_COL_SIZE: i18n.t('Size'),
    RT_INC_COL_PAGES: i18n.t('Pages'),
    RT_INC_COL_ATTENTION_SCORE: i18n.t('Attention score'),
    RT_INC_COL_MEDIA_EFFECT: i18n.t('Media effect'),
    RT_INC_COL_SECTION_NUMBER: i18n.t('Section number'),
    RT_INC_COL_GEOGRAPHY: i18n.t('Geography'),
    RT_INC_COL_MENTIONED_NAMES: i18n.t('Mentioned names'),
    RT_INC_ANALYSIS: i18n.t('Analysis data'),
    RT_INC_COL_MATCHES: i18n.t('Matches'),
    RT_INC_FB_SHARES: i18n.t('Facebook share count for editorial articles'),
    RT_INC_FB_METADATA: i18n.t('Include metadata from facebook'),
    RT_INC_TW_METADATA: i18n.t('Include twitter metadata'),
    RT_INC_COL_SM_STORY: i18n.t('Facebook story'),
    RT_INC_COL_SM_LINK: i18n.t('Facebook link'),
    RT_INC_COL_SM_SHARES: i18n.t('Facebook shares'),
    RT_INC_COL_SM_DESCRIPTION: i18n.t('Facebook description'),
    RT_INC_COL_SM_CREATED: i18n.t('Tweet/fb post created at'),
    RT_INC_COL_SM_PLACE: i18n.t('Tweet/fb post place'),
    RT_INC_COL_SM_GEO: i18n.t('Tweet geography'),
    RT_INC_COL_SM_FROM: i18n.t('Facebook poster'),
    RT_INC_COL_SM_COMMENTS: i18n.t('Facebook comments count'),
    RT_INC_COL_SM_LIKES: i18n.t('Tweet favorite count/fb post likes'),
    RT_INC_COL_SM_TYPE: i18n.t('Facebook type'),
    RT_INC_COL_SM_PAGE: i18n.t('Facebook page'),
    RT_INC_COL_SM_SOURCE: i18n.t('Tweet source'),
    RT_INC_COL_SM_RETWEETS: i18n.t('Tweet retweets'),
    RT_INC_COL_SM_USER: i18n.t('Tweet at'),
    RT_INC_COL_SM_AUTHOR: i18n.t('Tweet author'),
    RT_PAPER_HIDE_TIME: i18n.t('Hide time for paper articles'),
    FT_INC_IDENTICAL: i18n.t('Include identical articles'),
    FT_NUM_ARTICLES: i18n.t('Number of articles'),
    FT_ENABLE_REDIR: i18n.t('Enable redir links'),
    FT_INCLUDE_SOURCE_GROUP: i18n.t('Include source'),
    FT_SOURCE_TAG: i18n.t('Include source in tag'),
    FT_SOURCE_HEADER: i18n.t('Include source in header'),
    FT_SOURCE_ALTERNATE: i18n.t('Use alternate source'),
    FT_INCLUDE_IMAGES_GROUP: i18n.t('Include images'),
    FT_IMAGE_TAG: i18n.t('Include image in tag'),
    FT_IMAGE_INLINE: i18n.t('Include image inline'),
    FT_IMAGE_INLINE_TITLE: i18n.t('Include image inline in title'),
    FT_INCLUDE_MATCHES: i18n.t('Include matches'),
    FT_INCLUDE_QUOTES: i18n.t('Include context'),
    FT_CUSTOM_MATCH_CSS: i18n.t('Custom css for match tags'),
    FT_CUSTOM_IMAGE_CSS: i18n.t('Custom css for image'),
    FT_FORMAT: i18n.t('Format'),
    RT_MAX_SOURCE_REFERENCES: i18n.t('Max source references'),
    RT_INC_COLORED_ROWS: i18n.t('Include zebra rows'),
    RT_INC_COL_SM_FOLLOWERS: i18n.t('Include followers count'),
    RT_INC_SUMMARY_MATCHES: i18n.t('Matches in summary'),
    RT_INC_HEADER_MATCHES: i18n.t('Matches in header'),
    RT_INC_BODY_MATCHES: i18n.t('Matches in body'),
    RT_INC_QUOTES_MATCHES: i18n.t('Matches in quotes'),
    RT_INC_MATCHES_GROUP: i18n.t('Group for matches'),
    RT_INC_MENTIONED_NAMES: i18n.t('Include mentioned names'),
    RT_INC_LINKOBS: i18n.t('Include visibility information'),
    RT_INC_RAW: i18n.t('Include raw html'),
    RT_INC_TOPICS: i18n.t('Include topics'),
    RT_RESPONSIVE_WIDTH: i18n.t('Min width before hiding image'),
    RT_INC_TOC_SUMMARY: i18n.t('Include summary in TOC'),
    RT_INC_COL_TAGS: i18n.t('Tags'),
    RT_INC_COL_SIMILARWEB_MVISIT: i18n.t('Monthly visitors'),
    RT_INC_COL_SIMILARWEB_UVISIT: i18n.t('Calculated readership'),
    RT_SINGLE_ARTICLE_SUBJECT: i18n.t('Set subject to only article'),
    MODULE_ALERT_HEADER: i18n.t('Alert settings'),
    MODULE_GEOMETRY_HEADER: i18n.t('Layout'),
    MODULE_CONTENT_HEADER: i18n.t('Content'),
    MODULE_OPTIMIZATION_HEADER: i18n.t('Optimization'),
    MODULE_COLUMN_SETTINGS_HEADER: i18n.t('Column settings'),
    REPORT_LIST_NAME: i18n.t('Template name'),
    REPORT_LIST_HEADER: i18n.t('Template header'),
    REPORT_LIST_FOOTER: i18n.t('Template footer'),
  },
  feeds: {
    images: i18n.t('Include images'),
    source: i18n.t('Include sources'),
    sourceTitle: i18n.t('Include source in title'),
    neverRedir: i18n.t('Disable redir links'),
    alternativeSource: i18n.t('Use alternative source name'),
    includeContext: i18n.t('Include context for matches'),
  },
}

export default GENERATED_BACKEND_TRANSLATIONS

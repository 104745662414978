import React from 'react'

import Translate from '../../Translate'

type Props = {
  titleHint: string
  onClickHandler: () => void
  copied: boolean
}

const CopyButton = ({ titleHint, onClickHandler, copied }: Props) => (
  <div className="op-to-copy-button" title={titleHint} onClick={onClickHandler}>
    <div className="op-icon-file" />
    {copied && (
      <span className="copied-successfully">
        <Translate i18nString="Copied successfully!" />
      </span>
    )}
  </div>
)

export default CopyButton

import React, { InputHTMLAttributes, ReactNode } from 'react'
import classNames from 'classnames'

import styles from './index.module.scss'

export interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: ReactNode
  testid?: string
}

export default function Radio({ label, testid, ...inputProps }: RadioProps) {
  return (
    <label className={classNames(styles.wrapper, { [styles.disabled]: inputProps.disabled })}>
      <div className={styles.radioInput}>
        <input type="radio" data-testid={testid} {...inputProps} />
        <span className={styles.radioControl} />
      </div>
      {label}
    </label>
  )
}

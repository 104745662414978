import React from 'react'
import { useTranslation } from 'react-i18next-latest'

import OpointTextWithMatchesAndEntities from '../../articles/OpointTextWithMatchesAndEntities'

type Props = {
  text: string
  isHtml?: boolean
}

const Translate = ({ text, isHtml = false }: Props) => {
  const { t } = useTranslation()
  const translatedText = t(text)

  return isHtml ? <OpointTextWithMatchesAndEntities text={translatedText} /> : translatedText
}

export default Translate

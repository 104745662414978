import React from 'react'
import classNames from 'classnames'

type Props = {
  onChange: () => void
  selected: boolean
}

export default class Radio extends React.Component<Props> {
  onChangeHandler = () => {
    const { onChange, selected } = this.props
    // @ts-ignore
    onChange(!selected)
  }

  render() {
    const { selected } = this.props

    return <div className={classNames('radio', { 'radio--selected': selected })} onClick={this.onChangeHandler} />
  }
}

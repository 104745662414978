import Rx from 'rx-dom'
import R from 'ramda'
import i18n from 'i18next'

import { SUPPORTED_LOCALES } from '../common/constants'
import config from '../common/config'
import type { Setting } from '../flow'
import type { MODULES, MODULE_OPTIONS } from '../../new-components/types/permissions'

export const LISTING_STYLES = {
  MODERN: 0,
  SEARCH_ENGINE: 1,
  EMAIL: 2,
  ARCHIVE_RIGHT: 3,
  ARCHIVE_LEFT: 4,
  INTRO: 5,
}

export const LISTING_STYLE_NAMES = {
  [LISTING_STYLES.MODERN]: i18n.t('Modern'),
  [LISTING_STYLES.SEARCH_ENGINE]: i18n.t('Search engine'),
  [LISTING_STYLES.EMAIL]: i18n.t('Email'),
  [LISTING_STYLES.ARCHIVE_LEFT]: i18n.t('Archive (Articles to the left)'),
  [LISTING_STYLES.ARCHIVE_RIGHT]: i18n.t('Archive (Articles to the right)'),
  [LISTING_STYLES.INTRO]: i18n.t('Intro (default)'),
}

// TIme offset for relative times in seconds
// BEWARN - IF YOU MODIFY THIS, YOU ALSO NEED TO MODIFY STRING IN TRANSLATIONS.JS
export const TIME_OFFSET = {
  NEVER: {
    sec: 0,
    label: 'Never',
  },
  LESS_THAN_15_MINUTES: {
    sec: 900,
    label: 'Age of article is less than 15 minutes',
  },
  LESS_THAN_1_HOURS: {
    sec: 3600,
    label: 'Age of article is less than 1 hour',
  },
  LESS_THAN_6_HOURS: {
    sec: 21600,
    label: 'Age of article is less than 6 hours',
  },
  LESS_THAN_24_HOURS: {
    sec: 86400,
    label: 'Age of article is less than 24 hours',
  },
  LESS_THAN_A_WEEK: {
    sec: 604800,
    label: 'Age of article is less than a week',
  },
}

export const MODULE_OPTION: MODULE_OPTIONS = {
  OFF: 'A',
  OFF_FOR_USER: 'B',
  READ_ONLY: 'C',
  ON: 'D',
}

export const MODULE: MODULES = {
  SOURCE_LIST: 'SOURCE_LIST_MODULE',
  FEED: 'FEED_MODULE',
  ALERT: 'ALERT_MODULE',
  SETTING: 'SETTING_MODULE',
  REPORT: 'REPORT_MODULE',
  STATISTICS: 'STATISTICS_MODULE',
  TRASH: 'TRASH_MODULE',
  CREATE_ARTICLE: 'CREATE_ARTICLE_MODULE',
  PROFILE: 'PROFILE_MODULE',
  TAG: 'TAG_MODULE',
  CONTACT: 'CONTACT_MODULE',
}

const normalizeSettingValues = R.map(({ name, value, choices }) => {
  let settingValue = value
  if (name === 'LANGUAGE' && !SUPPORTED_LOCALES.includes(settingValue)) {
    settingValue = 'en-GB' /* eslint-disable-line no-param-reassign */
  }

  // due to original mistake in Chinese locale
  if (settingValue === 'zh_CN') {
    settingValue = 'zh-CN'
  }

  if (name === 'MAX_GT_ARTICLE_LENGTH') {
    settingValue = Number(settingValue) /* eslint-disable-line no-param-reassign */
  }
  return { name, value: settingValue, choices }
})

export function getSettings() {
  const requestHeaders = R.merge(config.getRequestHeaders(), {
    url: config.getUrl('/api/settings/'),
  })
  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => normalizeSettingValues(response))
}

export function saveSettings(settings: Array<Setting>) {
  const requestHeaders = R.merge(config.getRequestHeaders(), {
    url: config.getUrl('/api/settings/'),
    method: 'POST',
    body: JSON.stringify(normalizeSettingValues(settings)),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

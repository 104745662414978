import React from 'react'
import R from 'ramda'
import { connect } from 'react-redux'

import buildActionCreators from '../../../helpers/buildActionCreators'
import SearchInput from '../../../components/search/SearchInput'
import SourceLineSuggestion from './SourceLineSuggestion'
import { SearchFilterComponent } from '../../search/FilterComponent'
import type { Suggestion } from '../../../opoint/flow'
import * as ActionTypes from '../../../constants/actionTypes'

type SourceLineProps = {
  onSearchTermChanged: (searchterm: string) => {}
  searchterm: string
  showMediatypesGroups: (op: any) => void
  onSearchFilterToggled: (suggestion: Suggestion, searchterm: string) => void
  isDisabled: boolean
}

type SourceLineState = {
  pendingSuggestion: Suggestion
}

class SourceLine extends React.PureComponent<any, any> {
  onLoadSuggestions: Function = () => {
    const { onSearchTermChanged, triggerSuggestionSearch, searchterm, suggestionType } = this.props

    triggerSuggestionSearch({ searchterm, suggestionType })
    onSearchTermChanged(searchterm)
  }

  onSearchFilterToggledHandler = (suggestion, searchterm) => {
    const { showMediatypesGroups, onSearchFilterToggled } = this.props

    // In case user choose a suggestion with a few id inside the one, separeted by coma
    // we fetch media type details from api and suggest to choose one of them
    if (suggestion.id && typeof suggestion.id === 'string' && suggestion.id.search(',') > 0) {
      this.setState({ pendingSuggestion: suggestion })
      return showMediatypesGroups({ suggsIds: suggestion.id })
    }

    // When user has done a choice of media types
    // Send the suggestion he chose with replaced id
    if (suggestion.expandedMediatype) {
      const suggestionWithChosenMediatype = R.assoc('id', suggestion.id, this.state.pendingSuggestion)

      onSearchFilterToggled(suggestionWithChosenMediatype, searchterm)
      return true
    }

    onSearchFilterToggled(suggestion, searchterm)
    return true
  }

  render() {
    const { onSearchTermChanged, onSearchFilterAdded, onSearchFilterRemoved, clearSearchData } = this.props

    return (
      <SearchInput
        {...this.props}
        placeholder=""
        suggestionComponent={SourceLineSuggestion}
        filterComponent={SearchFilterComponent}
        onSearchTermChanged={onSearchTermChanged}
        onLoadSuggestions={this.onLoadSuggestions}
        onFilterAdded={onSearchFilterAdded}
        onFilterToggled={this.onSearchFilterToggledHandler}
        removeFilter={onSearchFilterRemoved}
        clearAllHandler={clearSearchData}
        autoSuggestion
      />
    )
  }
}

export default connect(
  null,
  buildActionCreators({
    clearSearchData: ActionTypes.ADD_ARTICLE_SEARCHDATA_LANG_CLEAR,
    onSearchFilterAdded: ActionTypes.ADD_ARTICLE_SEARCHFILTER_LANG_ADDED,
    onSearchFilterRemoved: ActionTypes.ADD_ARTICLE_SEARCHFILTER_LANG_REMOVED,
    onSearchFilterToggled: ActionTypes.ADD_ARTICLE_SEARCHFILTER_LANG_TOGGLED,
    onSearchTermChanged: ActionTypes.ADD_ARTICLE_SEARCHTERM_LANG_CHANGED,
    showMediatypesGroups: ActionTypes.GET_GROUPS_LANG_MEDIATYPES,
    triggerSuggestionSearch: ActionTypes.ADD_ARTICLE_TRIGGER_SUGGESTION,
  }),
)(SourceLine)

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import buildActionCreators from '../../../../helpers/buildActionCreators'
import Translate from '../../../../Translate'
import { getOneTagId } from '../../../../selectors/searchSelectors'
import { getUserPermissions } from '../../../../selectors/settingsSelectors'
import { MODULE, MODULE_OPTION } from '../../../../opoint/settings/index'
import * as ActionTypes from '../../../../constants/actionTypes'

class ToggleEditTagPopover extends React.Component<any, any> {
  static contextTypes = {
    showEditTagPopover: PropTypes.func,
  }

  editTagHandler = (e) => {
    const { showEditTagPopover } = this.context
    const { setLastUsedTagId, tagId } = this.props
    setLastUsedTagId({ tag: { id: tagId } })
    showEditTagPopover(e)
  }

  render() {
    const { isFromPopover, isPermitted } = this.props
    const icon = <i className="op-icon-pencil" />
    const title = <Translate i18nString="Edit tag" />

    return isFromPopover ? (
      <li className="actionLine-popover-child">
        <a onClick={this.editTagHandler}>
          {icon}
          {title}
        </a>
      </li>
    ) : (
      <button disabled={!isPermitted} className="op-button-toolbar" onClick={this.editTagHandler}>
        {title}
        {icon}
      </button>
    )
  }
}

export default connect(
  (state) => {
    const userPermissions = getUserPermissions(MODULE.TAG)(state)
    const isPermitted = MODULE_OPTION.ON === userPermissions
    return {
      isPermitted,
      tagId: getOneTagId(state),
    }
  },
  buildActionCreators({
    setLastUsedTagId: ActionTypes.SET_LAST_USED_TAG_ID,
  }),
)(ToggleEditTagPopover)

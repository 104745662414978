import R from 'ramda'

import * as Actions from '../constants/actionTypes'

const initialState = {
  user: null,
  loginError: false,
  usersLoginInfo: {},
  loginByIp: true,
}

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case Actions.OPEN_BASE_LOG_IN_FORM:
      return R.assoc('loginByIp', false, state)

    case Actions.USERS_LOGIN_INFO_SUCCESS:
      return R.assoc('usersLoginInfo', payload, state)

    case Actions.LOG_IN_SUCCESS:
      return R.assoc('user', payload, state)

    case Actions.LOG_IN_ERROR:
      return R.assoc('loginError', true, state)

    case Actions.LOG_IN_ERROR_TIMEOUT:
      return R.assoc('loginError', false, state)

    case Actions.LOGOUT:
      return R.assoc('user', null, state)

    default:
      return state
  }
}

export default authReducer

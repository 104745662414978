import React from 'react'

import { articleId } from '../../opoint/articles/index'

/**
 * This is a higher order component which provides underlying article component
 * with needed information from the state and actions.
 * @param ComposedArticleComponent
 */
export default function baseArticle(ComposedArticleComponent) {
  return class extends React.Component<any, any> {
    constructor(props) {
      super(props)
      // @ts-ignore
      this.articleRef = null
    }

    render() {
      const {
        tagsFn,
        visibleTrashTagsFn,
        article,
        activeArticle,
        active,
        articleSource,
        shareTooltip,
        checkedArticles,
        editTooltip,
        ...otherProps
      } = this.props

      const componentProps = {
        active,
        articleSource,
        activeArticle,
        shareTooltip,
        editTooltip,
      }
      if (activeArticle) {
        // @ts-ignore
        componentProps.article = activeArticle
        // @ts-ignore
        componentProps.originalArticle = article
      } else {
        // @ts-ignore
        componentProps.article = article
      }

      return (
        <ComposedArticleComponent
          {...componentProps}
          ref={(ref) => {
            // @ts-ignore
            this.articleRef = ref
          }}
          tagsFn={tagsFn}
          // @ts-ignore
          checked={checkedArticles[articleId(componentProps.article)]}
          visibleTrashTagsFn={visibleTrashTagsFn}
          {...otherProps}
        />
      )
    }
  }
}

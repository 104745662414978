// @ts-nocheck

import R from 'ramda'

import { isProfileSearch, canSaveSearchlineAsProfile } from '../search/index'
import { TextHeader, SearchSummary } from '../../components/toolbars/ToolbarHeaderComponents'
import * as toolbarActions from '../../constants/toolbarActions'
import type { PortalAction } from '../flow'

// Function get states and calculate necessary actions
export default function getToolbarActions(state: Object) {
  const {
    alertPreview,
    atLeastOneTagSelected,
    deleteAlertInProgress,
    deleteFeedInProgress,
    editedFeed,
    editedTag,
    getAddFormData,
    getEditFormData,
    isEditedProfile,
    isOneTagSelected,
    isSearch,
    newProfile,
    noArticles,
    openDatepicker,
    searchline,
    searchMeta,
    selectedArticlesIds,
    showFilteredArticles,
    statistics,
    statisticsFiltered,
    statisticViewId,
    i18n,
    withExpliciteDateRange,
  } = state

  const headerDefault = {
    header: SearchSummary,
    headerProps: {
      documentsCount: searchMeta.foundDocumentsCount,
      openDatepicker,
      rangeEnd: searchMeta.rangeEnd,
      rangeStart: searchMeta.rangeStart,
      statistics,
    },
  }

  let actions: Array<PortalAction> = toolbarActions.ARTICLE_BASIC_ACTIONS

  if (showFilteredArticles) {
    return {
      actions: toolbarActions.STATISTICS_FILTERED_ARTICLES,
    }
  }

  if (editedTag && editedTag !== null) {
    // TODO: define header
    return {
      actions: toolbarActions.TAG_ACTIONS,
    }
  }

  // Delete feed - delete in progress (confirmation)
  if (editedFeed && deleteFeedInProgress) {
    return {
      header: TextHeader,
      headerProps: {
        text: i18n.t('Are you sure you want to delete this feed?'),
        type: 'warning',
      },
      actions: toolbarActions.DELETE_FEED_ACTIONS,
    }
  }

  // Edit feed actions
  if (editedFeed && editedFeed.id) {
    return {
      header: TextHeader,
      headerProps: {
        text: editedFeed.name,
      },
      actions: toolbarActions.FEED_ACTIONS,
    }
  }

  // New feed actions
  if (editedFeed) {
    return {
      actions: toolbarActions.NEW_FEED_ACTIONS,
    }
  }

  // Edit profile actions
  if (isEditedProfile) {
    return {
      actions: toolbarActions.PROFILE_ACTIONS.concat(toolbarActions.EDITING_PROFILE_ACTIONS),
    }
  }

  // New profile actions
  if (newProfile) {
    return {
      actions: toolbarActions.PROFILE_ACTIONS,
    }
  }
  // Statistics actions
  if (statistics) {
    let actions = statisticViewId ? toolbarActions.STATISTICS_VIEWS_ACTIONS : toolbarActions.STATISTICS_ACTIONS

    // Additional actions for active filters on charts
    if (statisticsFiltered) {
      actions = actions.concat(toolbarActions.STATISTICS_FILTER_ACTIONS)
    }

    /**
     * ATTENTION: if you want to change something
     * in the block below
     * you should read this first:
     * https://github.com/webscopeio/m360/wiki/Toolbar
     */

    // show reduced count and range if timeSliderSelector is shown
    let [documentsCount, rangeStart, rangeEnd] = [
      searchMeta.receivedDocumentsCount,
      searchMeta.lastTimestamp,
      searchMeta.rangeEnd,
    ]

    if (withExpliciteDateRange) {
      ;[documentsCount, rangeStart, rangeEnd] =
        searchMeta.receivedDocumentsCount < searchMeta.foundDocumentsCount
          ? [searchMeta.receivedDocumentsCount, searchMeta.lastTimestamp, searchMeta.firstTimestamp]
          : [searchMeta.foundDocumentsCount, searchMeta.rangeStart, searchMeta.rangeEnd]
    }

    /**
     * End attention.
     */

    return {
      header: SearchSummary,
      headerProps: {
        documentsCount,
        openDatepicker,
        rangeEnd,
        rangeStart,
        statistics,
      },
      actions,
    }
  }

  // Delete alert - delete in progress (confirmation)
  if (deleteAlertInProgress) {
    return {
      header: TextHeader,
      headerProps: {
        text: i18n.t('Are you sure you want to delete this alert?'),
        type: 'warning',
      },
      actions: toolbarActions.DELETE_ALERT_ACTIONS,
    }
  }

  // New alert toolbar
  if (!!getAddFormData && getAddFormData !== undefined) {
    actions = toolbarActions.NEW_ALERT_ACTIONS
    return {
      actions,
    }
  }

  // Edit alert toolbar
  if (!!getEditFormData && getEditFormData !== undefined) {
    actions = toolbarActions.EDIT_ALERT_ACTIONS
    return {
      actions,
    }
  }

  // Alert preview
  if (alertPreview) {
    actions = toolbarActions.OPEN_ALERT_PREVIEW_ACTIONS
    return {
      actions,
    }
  }

  if (noArticles && !isSearch) {
    return {
      actions: [],
    }
  }

  if (atLeastOneTagSelected) {
    const tagHeaders = {
      header: SearchSummary,
      headerProps: {
        documentsCount: R.max(searchMeta.foundDocumentsCount, searchMeta.receivedDocumentsCount),
        openDatepicker,
        rangeEnd: searchMeta.rangeEnd,
        rangeStart: searchMeta.rangeStart,
        statistics,
      },
    }

    if (selectedArticlesIds && !!selectedArticlesIds.length) {
      // Articles selected while tag selected
      actions = toolbarActions.ARTICLE_CHECK_ACTIONS
    }

    // Just one tag selected
    if (isOneTagSelected) {
      // Articles are not selected while tag selected
      return {
        ...tagHeaders,
        actions: actions.concat(toolbarActions.EDIT_TAG_ACTION),
      }
    }

    return {
      ...tagHeaders,
      actions,
    }
  }

  // Additional actions for checked articles
  if (selectedArticlesIds && !!selectedArticlesIds.length) {
    actions = toolbarActions.ARTICLE_CHECK_ACTIONS
  }

  // "Save as profile" + "Edit profile"
  if (canSaveSearchlineAsProfile(searchline)) {
    actions = actions.concat(toolbarActions.SAVE_AS_PROFILE_ACTION)
  } else if (isProfileSearch([{ searchline, linemode: 'R' }])) {
    actions = actions.concat(toolbarActions.EDIT_PROFILE_ACTION)
  }

  // Should be in the end
  if (noArticles && !isSearch) {
    return {
      actions: [],
    }
  }

  return {
    ...headerDefault,
    actions,
  }
}

import React, { useRef, useState, useEffect } from 'react'
import R from 'ramda'
import { connect } from 'react-redux'
import { CSSTransitionGroup } from 'react-transition-group'
import classNames from 'classnames'

import buildActionCreators from '../../helpers/buildActionCreators'
import { FiltersPanelColumn } from './FilterColumn'
import { FiltersMoreColumn } from './FilterMoreColumn'
import { getFilterProfiles, getFilterTags } from '../../opoint/search/index'
import { getFiltersOrder } from '../../selectors/settingsSelectors'
import { getFiltersPanelOpen } from '../../selectors/uiSelectors'
import { getProfilesWithType } from '../../selectors/profilesSelectors'
import {
  getSearchterm,
  getSuggestionsMultiple,
  getSearchFilters,
  getFiltersShowMore,
} from '../../selectors/searchSelectors'
import { getTagsWithType } from '../../selectors/tagsSelectors'
import * as ActionTypes from '../../constants/actionTypes'
import type { Profile, Filter, FilterSliderResponsiveSetting, Suggestion, Tag, FilterType } from '../../opoint/flow'
import BackButton from './BackButton'
import styles from './index.module.scss'

type FilterObject = {
  action: string
  cols: number
  seq: number
  suggest: Suggestion[]
  typeName: string
}

type FiltersPanelProps = {
  profileEditorSearchterm: string
  searchterm: string
  fetchFilter: (obj: { id: string; type: string }) => void
  filtersOrder: Array<FilterType>
  isMainSearch: boolean
  onCustomTimePeriod: () => void
  onFiltersReorder: () => void
  onSearchFilterToggled: () => void
  profiles: Array<Profile>
  searchFilters: { [key: string]: Filter }
  sliderSettings: {
    responsive: Array<FilterSliderResponsiveSetting>
  }
  suggestions: {
    action: Array<{ [key: string]: number }>
    cov: FilterObject
    ent: FilterObject
    content: FilterObject
    geo: FilterObject
    hits: number
    lang: FilterObject
    media: FilterObject
    next_offset: string
    rank: FilterObject
    search: string
    site: FilterObject
    'time(ms)': number
    timePeriod: FilterObject
    tokens: number
    warnings: string
    width: Array<{ [key: string]: number }>
  }
  filtersShowMore: any
  tags: Array<Tag>
}

export class FiltersPanel extends React.Component<FiltersPanelProps> {
  render() {
    const {
      isMainSearch,
      profileEditorSearchterm,
      profiles,
      searchterm,
      sliderSettings,
      filtersOrder,
      suggestions,
      filtersShowMore,
      tags,
      ...props
    } = this.props

    // Prepare object with all filter to iterate over in correct order
    const filterProfiles = isMainSearch
      ? getFilterProfiles(profiles, searchterm)
      : getFilterProfiles(profiles, profileEditorSearchterm)
    const filterTags = isMainSearch ? getFilterTags(tags, searchterm) : getFilterTags(tags, profileEditorSearchterm)

    const orderedFilters = {
      ...suggestions,
      timeperiod: {
        ...suggestions?.timePeriod,
      },
      profiles: {
        suggest: filterProfiles.length ? R.take(10, filterProfiles) : R.take(10, profiles),
        typeName: 'Profiles',
      },
      tags: {
        suggest: filterTags.length ? R.take(10, filterTags) : R.take(10, tags),
        typeName: 'Tags',
      },
    }

    if (filtersShowMore.length > 0) {
      const lastFilter = filtersShowMore[filtersShowMore.length - 1]
      return Object.keys(lastFilter)
        .map((filterName) => lastFilter[filterName])
        .filter((item) => typeof item === 'object')
        .sort((a, b) => {
          if (a.seq < b.seq) {
            return -1
          } else if (b.seq < a.seq) {
            return 1
          } else {
            return 0
          }
        })
        .map((filter, index) => {
          return (
            <FiltersMoreColumn
              key={index}
              filterName={filter.typeName}
              filters={filter.suggest}
              index={index}
              isSingleColumn={lastFilter.isSingleColumn}
              width={filter.cols}
              action={filter.action}
              isMainSearch={isMainSearch}
              profileEditorSearchterm={profileEditorSearchterm}
              searchterm={searchterm}
              {...props}
            />
          )
        })
    }

    return filtersOrder.map((filterName, index) => {
      const filters = orderedFilters[filterName]
      if (!filters || filters.length === 0) {
        return null
      }

      return (
        <FiltersPanelColumn
          key={filterName}
          filterName={filters.typeName}
          filters={filters.suggest}
          index={index}
          isMainSearch={isMainSearch}
          profileEditorSearchterm={profileEditorSearchterm}
          searchterm={searchterm}
          action={filters.action}
          width={filters.cols}
          {...props}
        />
      )
    })
  }
}

/**
 * Wrapper
 */
const FiltersPanelSearch = (props) => {
  const [filterPanelHeight, setFilterPanelHeight] = useState(null)
  const filterPanelRef = useRef(null)

  useEffect(() => {
    if (filterPanelRef?.current) {
      setTimeout(() => {
        setFilterPanelHeight(filterPanelRef?.current?.getBoundingClientRect().height)
      }, 100)
    }
  }, [props.filtersPanelOpen])

  const hasFiltersMore = props.filtersShowMore.length > 0
  const backButtonTitle = props.filtersShowMore.map((item) => item.header).join(' > ')

  const { filtersPanelOpen, profiles, searchterm } = props
  return (
    <CSSTransitionGroup
      transitionEnter={false}
      transitionLeave={false}
      transitionLeaveTimeout={300}
      transitionName="filters"
    >
      {filtersPanelOpen && (
        <div className="op-filters" id="op-app-filters">
          <div
            ref={filterPanelRef}
            className={classNames(styles.filterPanel, { [styles.topPadding]: hasFiltersMore })}
            style={{ ...(filterPanelHeight && { minHeight: `${filterPanelHeight}px` }) }}
          >
            {hasFiltersMore && <BackButton onClick={() => props.filtersPop()} title={backButtonTitle} />}
            <FiltersPanel isMainSearch profiles={profiles} searchterm={searchterm} {...props} />
          </div>
        </div>
      )}
    </CSSTransitionGroup>
  )
}

const FiltersPanelSearchWrapped = connect(
  (state) => ({
    filtersPanelOpen: getFiltersPanelOpen(state),
    suggestions: getSuggestionsMultiple(state),
    filtersShowMore: getFiltersShowMore(state),
    searchFilters: getSearchFilters(state),
    searchterm: getSearchterm(state),
    filtersOrder: getFiltersOrder(state),
    profiles: getProfilesWithType(state),
    tags: getTagsWithType(state),
  }),
  buildActionCreators({
    fetchNewFilters: ActionTypes.FILTERS_FETCH_MULTIPLE_SUCCESS,
    fetchShowMoreFilters: ActionTypes.FILTERS_FETCH_MULTIPLE_OF_TYPE_SUCCESS,
    filtersPop: ActionTypes.FILTERS_POP,
    onSearchFilterToggled: ActionTypes.SEARCHFILTER_TOGGLED,
    onCustomTimePeriod: ActionTypes.DATEPICKER_MODAL_OPEN,
    onFiltersReorder: ActionTypes.FILTERS_REORDER,
    fetchMoreFilters: ActionTypes.FETCH_MORE_FILTERS,
  }),
)(FiltersPanelSearch)

export default FiltersPanelSearchWrapped

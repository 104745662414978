import React from 'react'
import { connect } from 'react-redux'

import buildActionCreators from '../../helpers/buildActionCreators'
import HistoryListingItem from './HistoryListingItem'
import Loader from '../common/Loader'
import Translate from '../../Translate'
import * as ActionTypes from '../../constants/actionTypes'
import Item from '../reports/ReportsSort/ReportsSortItem'

class HistoryListing extends React.Component<any, any> {
  componentWillMount() {
    this.props.fetchNextReports()
  }

  componentWillUnmount() {
    this.props.reportHistoryClear()
  }

  clearData = () => {
    this.props.clearRecipients()
    this.props.clearReportData()
  }

  shareReport = () => {
    const { shareReportFn } = this.props
    shareReportFn({ source: 'REPORTS_MODAL' })
  }

  render() {
    const { reports, loading, deleteReport, ...props } = this.props
    return (
      <div>
        {reports && !reports.length && !loading && (
          <div className="report-history__none">
            <Translate i18nString="No reports to display" />
          </div>
        )}
        <div className="report-history__list">
          {reports &&
            reports.map((report) => (
              <HistoryListingItem
                key={report.id}
                report={report}
                clearData={this.clearData}
                shareReport={this.shareReport}
                deleteReport={deleteReport}
                {...props}
              />
            ))}
          {loading && <Loader />}
        </div>
      </div>
    )
  }
}

// @ts-ignore
HistoryListing = connect(
  (state: any) => ({
    activeReport: state.reportsHistory.reportObject,
    isAttachmentShareChecked: state.reports.shareReportData.attachment,
    stepShareNumber: state.reports.shareReportData.stepNumber,
    shareReportMessage: state.reports.shareReportData.shareReportMessage,
    selectedShareContacts: state.contacts.shareReportContacts,
  }),
  buildActionCreators({
    shareReportFn: ActionTypes.SHARE_REPORT,
    setActiveReport: ActionTypes.REPORTHISTORY_SET_ACTIVE,
    updateStep: ActionTypes.SHARE_REPORT_UPDATE_STEP,
    toggleAttachment: ActionTypes.SHARE_REPORT_TOGGLE_ATTACHMENT,
    setMessageShareFn: ActionTypes.SHARE_REPORT_CHANGE_MESSAGE,
    reportHistoryClear: ActionTypes.REPORTHISTORY_CLEAR,
    clearRecipients: ActionTypes.CLEAR_SHARE_REPORT_RECIPIENTS,
    clearReportData: ActionTypes.CLEAR_SHARE_REPORT_DATA,
    deleteReport: ActionTypes.DELETE_REPORT,
  }),
)(HistoryListing)

export default HistoryListing

import React from 'react'
import Rx from 'rxjs'
import { connect } from 'react-redux'

import buildActionCreators from '../../helpers/buildActionCreators'
import {
  getSearchWatchId,
  getFetchArticlesWithWatchIdInProgress,
  getNewArticlesCount,
} from '../../selectors/watchIndexesSelectors'
import * as ActionTypes from '../../constants/actionTypes'

type Props = {
  searchWatchId: number
  newArticlesCount: number
  fetchArticlesWithWatchIdInProgress: boolean
  updateWatchIndexes: (id: any) => void
  fetchArticlesWithWatchId: (id: any) => void
}

class NewArticlesBalloon extends React.Component<Props> {
  watch$: any
  componentDidMount() {
    const { updateWatchIndexes } = this.props
    // start watching every 15 secs after first 5 secs
    this.watch$ = Rx.Observable.interval(15000)
      .delay(5000)
      .subscribe(() => updateWatchIndexes({ watchId: this.props.searchWatchId }))
  }

  componentWillUnmount() {
    this.watch$.unsubscribe()
  }

  fetchNewArticles = () => {
    const { searchWatchId, fetchArticlesWithWatchId, newArticlesCount } = this.props
    fetchArticlesWithWatchId({ watchId: searchWatchId, count: newArticlesCount })
  }

  render() {
    const { newArticlesCount, fetchArticlesWithWatchIdInProgress } = this.props

    if (!newArticlesCount) {
      return null
    }

    // Regarding to backend counting capabilities
    const limit = 20
    const count = newArticlesCount > limit ? `${limit}+` : newArticlesCount
    const number = fetchArticlesWithWatchIdInProgress ? '...' : count
    const minBalloonWidth = 20
    const incWidthPerSymbol = 5
    const balloonWidth = minBalloonWidth + incWidthPerSymbol * (`${count}`.length - 1)

    return (
      <div
        className="op-modal-new-articles-baloon"
        onClick={this.fetchNewArticles}
        style={{ width: `${balloonWidth}px` }}
      >
        <span className="op-modal-new-articles-baloon__number">{number}</span>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    searchWatchId: getSearchWatchId(state),
    newArticlesCount: getNewArticlesCount(state),
    fetchArticlesWithWatchIdInProgress: getFetchArticlesWithWatchIdInProgress(state),
  }),
  buildActionCreators({
    updateWatchIndexes: ActionTypes.UPDATE_WATCH_INDEXES,
    fetchArticlesWithWatchId: ActionTypes.FETCH_ARTICLES_WITH_WATCH_ID,
  }),
  // @ts-ignore
)(NewArticlesBalloon)

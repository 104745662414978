import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { translate } from 'react-i18next'

import buildAction from '../../helpers/buildAction'
import ModulePermissions from '../common/ModulePermissions'
import { MODULE, MODULE_OPTION } from '../../opoint/settings/index'
import { getUISetting } from '../../selectors/settingsSelectors'
import { isTrashTagSelected } from '../../selectors/searchSelectors'
import * as Actions from '../../constants/actionTypes'

// TODO: consider potentional refactor to unit components in entity listing (trash tags, tags etc.)
class TrashItem extends React.PureComponent<any> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  onClick = () => {
    const { tag, onVisibilityToggle } = this.props
    onVisibilityToggle(tag.id)
  }

  render() {
    const { tag, visibleTrashTags, onLabelClick, isChecked } = this.props

    const { i18n } = this.context

    const isVisible = visibleTrashTags && visibleTrashTags.includes(tag.id)

    return (
      <li title={tag.name}>
        <div className="checkbox-container">
          <div>
            <div className="op-checkbox mod-delete">
              <input type="checkbox" id={`trash-${tag.id}`} checked={isChecked} readOnly />
              <label htmlFor={`trash-${tag.id}`} onClick={onLabelClick} />
            </div>
            <span className="checkbox-desc" onClick={onLabelClick}>
              {tag.name}
            </span>
          </div>
          <ModulePermissions module={MODULE.TRASH} permissions={[MODULE_OPTION.ON, MODULE_OPTION.READ_ONLY]}>
            <ul className={classNames('entity__entryList', { 'entity__entryList--visible-last-icon': isVisible })}>
              <li title={i18n.t('Visibility of tag')} onClick={this.onClick}>
                <i className={isVisible ? 'op-icon-eye' : 'op-icon-striked-eye'} />
              </li>
            </ul>
          </ModulePermissions>
        </div>
      </li>
    )
  }
}

const TrashItemWrapped = connect(
  // @ts-ignore
  (state, { tag: { id } }) => ({
    isChecked: isTrashTagSelected(id)(state),
    visibleTrashTags: getUISetting('TRASH_TAGS_VISIBLE')(state),
  }),
  // @ts-ignore
  (dispatch, { tag: { id } }) => ({
    onLabelClick: (profile) => dispatch(push(`/search/?filters=trash:${id}`)),
    onVisibilityToggle: (trashId) => dispatch(buildAction(Actions.TRASH_TOGGLE_VISIBILITY, { trashId })),
  }),
)(TrashItem)

export default translate([], { wait: true })(TrashItemWrapped)

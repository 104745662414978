import React from 'react'
import R from 'ramda'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'

import Checkbox from '../common/Checkbox'
import GroupCheckbox from '../common/GroupCheckbox'
import Select from '../common/Select'
import ToggleCheckbox from '../common/ToggleCheckbox'
import Translate from '../../Translate'
import buildActionCreators from '../../helpers/buildActionCreators'
import { ASPECT_OVERLAP_OPTIONS_DEFAULT } from '../../opoint/statistics/aspects'
import { EXACT_MODE } from '../../opoint/common/constants'
import * as ActionTypes from '../../constants/actionTypes'
import { isStatLoading } from '../../selectors/statisticsSelectors'

function renderTag(tag) {
  return tag.name
}

function isTagChecked(tag) {
  return tag.selected
}

function filterTag(filter, tag) {
  return filter ? Boolean(tag.name.match(new RegExp(filter, 'i'))) : true
}

function tagValue(tag) {
  return tag.id
}

class Aspect extends React.Component<any, any> {
  static contextTypes = {
    i18n: PropTypes.object.isRequired,
  }

  aspectOverlapOptions: any

  constructor(props) {
    super(props)

    const { aspect } = this.props
    this.state = {
      listOpened: false,
      tagFilterExpr: '',
    }

    // check if searchd has calculated the max number of combined parts,
    // exclude options greater than that
    this.aspectOverlapOptions = ASPECT_OVERLAP_OPTIONS_DEFAULT.filter((v) =>
      aspect.maxcombo ? v <= aspect.maxcombo : true,
    )
      // map to format valid for Select component
      .map((v) => ({ value: v, label: String(v) }))
  }

  onFilterChange = (filter) => {
    this.setState(R.assoc('tagFilterExpr', filter))
  }

  ontoggleTag = (tagId) => {
    const { aspect, toggleTag } = this.props
    toggleTag({ aspectId: aspect.id, tagId: +tagId })
  }

  onToggleChange = (isSelected) => {
    const { aspect, toggleAspect } = this.props
    if (aspect.tagLikeEntities && isSelected) {
      if (aspect.tagLikeEntities.every(({ selected }) => !selected)) {
        this.setState(R.assoc('listOpened', true))
        return
      }
    }
    toggleAspect({
      aspectId: aspect.id,
      selected: Boolean(isSelected),
    })
  }

  changeOverlap = (overlap) => {
    const { aspect, changeOverlap } = this.props
    changeOverlap({ aspectId: aspect.id, overlap: Number(overlap) })
  }

  toggleOverlapMode = () => {
    const { aspect, toggleOverlapMode } = this.props
    toggleOverlapMode({ aspectId: aspect.id })
  }

  resendAspectHandler = () => {
    const { aspect, resendAspect } = this.props
    resendAspect({ aspect })
  }

  tagKey = (tag) => {
    const { aspect } = this.props
    return `tag${aspect.id}:${tag.id}`
  }

  toggleListOpened = () => {
    this.setState(
      R.evolve({
        listOpened: R.not,
      }),
    )
  }

  render() {
    const { i18n } = this.context
    const { aspect, interest, loading } = this.props
    const { tagFilterExpr, listOpened } = this.state

    const tags = aspect.tagLikeEntities
    const selectedTags = (tags || []).filter(({ selected }) => selected)

    return (
      <li className="op-content-right-statistics-list-item">
        <span className="op-content-right-statistics-list-item-name" title={`${i18n.t('interest')} ${interest}`}>
          <i className={`op-icon-interest-${interest}`} />
          {` ${aspect.name}`}
        </span>
        <ToggleCheckbox
          input={{
            value: Number(aspect.selected),
            onChange: this.onToggleChange,
          }}
        />
        {
          // aspect contains combined aspect parts (aspects overlaps each other)
          // so we want to display overlaps selection, but not others as in next case
          !tags && aspect.maxcombo > 0 && aspect.selected && (
            <div className="aspect-selector-controls">
              {aspect.dirty && aspect.selected && (
                <button className="list-item-button-apply" disabled={loading} onClick={this.resendAspectHandler}>
                  <Translate i18nString="Apply" />
                </button>
              )}
              {/* #TODO @dan TRANSLATE THIS */}
              <div
                className="list-item-button"
                title={i18n.t('Edit overlaps settings')}
                onClick={this.toggleListOpened}
              >
                <i className="op-icon-gear" />
              </div>
              <div className="clearfix" />
              {listOpened && (
                <div className="aspect-overlap-settings">
                  <span className="aspect-overlap">
                    <label htmlFor="aspect-combo-select">
                      <Translate i18nString="Overlaps" />
                    </label>
                    <Select
                      className="op-select mod-rounded mod-spaced"
                      value={aspect.overlap}
                      // @ts-ignore
                      onChange={this.changeOverlap}
                      options={this.aspectOverlapOptions}
                    />
                  </span>
                  {aspect.overlap > 0 && aspect.overlap < aspect.maxcombo && (
                    <span className="aspect-overlap-merge-mode" title="Overlap merge mode">
                      <Checkbox
                        id={`aspect-${aspect.id}-overlap-mode-checkbox`}
                        value={aspect.overlapMode === EXACT_MODE}
                        className="inline-checkbox"
                        onChangeHandler={this.toggleOverlapMode}
                      >
                        <Translate i18nString="Exact" />
                      </Checkbox>
                    </span>
                  )}
                </div>
              )}
            </div>
          )
        }

        {
          // aspect is one of: profile, manual sentiment, tag, analysis
          tags && (
            <div className="aspect-selector-controls">
              {aspect.dirty &&
                aspect.selected && ( // TODO && forceResendProfileAspect
                  <button className="list-item-button-apply" onClick={this.resendAspectHandler}>
                    <Translate i18nString="Apply" />
                  </button>
                )}
              <div className="list-item-button" title={i18n.t('Edit profiles to show')} onClick={this.toggleListOpened}>
                <Translate
                  i18nString="{{selectedTagsCount}} of {{tagsCount}}"
                  context={{
                    selectedTagsCount: selectedTags.length,
                    tagsCount: tags.length,
                  }}
                />
              </div>
              <div className="clearfix" />
              {listOpened && (
                <div>
                  <div className="aspect-overlap-settings">
                    <span className="aspect-overlap">
                      <label htmlFor="aspect-combo-select-profiles">
                        <Translate i18nString="Overlaps" />
                      </label>
                      <Select
                        className="op-select mod-rounded mod-spaced"
                        value={aspect.overlap}
                        // @ts-ignore
                        onChange={this.changeOverlap}
                        options={this.aspectOverlapOptions}
                      />
                    </span>
                    {aspect.overlap > 0 && aspect.overlap < aspect.maxcombo && (
                      <span className="aspect-overlap-merge-mode" title="Overlap merge mode">
                        <Checkbox
                          id={`aspect-${aspect.id}-overlap-mode-checkbox`}
                          className="inline-checkbox"
                          value={aspect.overlapMode === EXACT_MODE}
                          onChangeHandler={this.toggleOverlapMode}
                        >
                          <Translate i18nString="Exact" />
                        </Checkbox>
                      </span>
                    )}
                  </div>
                  <GroupCheckbox
                    list={tags}
                    keyFn={this.tagKey}
                    valueFn={tagValue}
                    renderComponent={renderTag}
                    filteringFn={filterTag}
                    filterExp={tagFilterExpr}
                    onFilterChange={this.onFilterChange}
                    isChecked={isTagChecked}
                    onToggle={this.ontoggleTag}
                  />
                </div>
              )}
            </div>
          )
        }
      </li>
    )
  }
}

// @ts-ignore
Aspect = translate([], { wait: true })(Aspect)

// @ts-ignore
Aspect = connect(
  (state) => ({
    loading: isStatLoading(state),
  }),
  buildActionCreators({
    toggleTag: ActionTypes.STATISTICS_ASPECT_TAG_TOGGLED,
    toggleAspect: ActionTypes.STATISTICS_ASPECT_TRY_TOGGLE,
    resendAspect: ActionTypes.STATISTICS_ASPECT_RESEND,
    changeOverlap: ActionTypes.STATISTICS_ASPECT_OVERLAP_CHANGE,
    toggleOverlapMode: ActionTypes.STATISTICS_ASPECT_OVERLAP_MODE_TOGGLE,
  }),
)(Aspect)

export default Aspect

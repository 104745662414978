import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import LoginByIpForm from './LoginByIpForm'

class Login extends React.PureComponent<any> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  componentDidMount() {
    this.checkForRedirect()
  }

  componentWillReceiveProps() {
    this.checkForRedirect()
  }

  checkForRedirect = () => {
    const { dispatch, user } = this.props

    if (user) {
      dispatch(push('/'))
    }
  }

  render() {
    return <LoginByIpForm />
  }
}

// @ts-ignore
Login = connect((state: any) => ({
  user: state.auth.user,
}))(Login)

export default Login

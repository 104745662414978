import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'

import Loader from '../common/Loader'

type TemplateProps = {
  close: () => void
  content: any
  isOpen: boolean
  title: string
}

export default class TemplatePreviewModal extends React.PureComponent<TemplateProps> {
  static contextTypes = {
    getString: PropTypes.func,
  }

  render() {
    const { getString } = this.context
    const { close, content, isOpen, title } = this.props

    return (
      <Modal show={isOpen} onHide={close}>
        <Modal.Header>
          <button type="button" className="close" onClick={close}>
            ×
          </button>
          <h4 className="modal-title">{getString(title)}</h4>
        </Modal.Header>
        <Modal.Body>
          {!content ? (
            <div className="util_center-parent mod-full-all">
              <div className="util_center-child mod-full-all">
                <Loader />
              </div>
            </div>
          ) : (
            <div className="mod-full-height">
              <div className="op-modal-template-preview">
                <div className="modal-body">
                  <div id="preview-wrapper" className="mod-full-all">
                    <iframe title="Preview" src={content} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    )
  }
}

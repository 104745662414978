import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { SortableTreeWithoutDndContext as SortableTree } from 'react-sortable-tree'
import * as R from 'ramda'

import buildActionCreators from '../../../helpers/buildActionCreators'
import GENERATED_BACKEND_TRANSLATIONS from '../../../generated-backend-translations'
import Translate from '../../../Translate'
import { getActiveModuleName, getTemplateActiveModuleWidgets } from '../../../selectors/templatesSelectors'
import { isSortableModuleModalOpen } from '../../../selectors/uiSelectors'
import * as ActionTypes from '../../../constants/actionTypes'
import type { Widget } from '../../../opoint/flow'

type Props = {
  isOpen: boolean
  moduleSettings: Array<Widget>
  moduleName: string
  moveModuleSetting: (params: any) => void
  sortableModuleModalClose: () => void
}

type State = {
  treeData: Array<Widget>
}

const makeTreeData = (settings: Array<Widget>, i18n: any) =>
  settings.map(({ name, children, ...props }) => {
    const treeData = {
      title: (
        <div>
          <span style={{ paddingLeft: 7 }}>{i18n.t(GENERATED_BACKEND_TRANSLATIONS.templates[name])}</span>
        </div>
      ),
      name,
      ...props,
    }
    return children ? R.assoc('children', makeTreeData(children, i18n), treeData) : treeData
  })

class SortableModuleModal extends React.PureComponent<Props, State> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  constructor(props, context) {
    super(props)
    const { moduleSettings } = props
    const { i18n } = context

    this.state = {
      treeData: makeTreeData(moduleSettings, i18n),
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    const { moduleSettings } = nextProps
    const { i18n } = this.context

    this.updateTree(makeTreeData(moduleSettings, i18n))
  }

  onMoveNodeHandler = ({ treeData: widgets }) => {
    const { moveModuleSetting, moduleName } = this.props
    moveModuleSetting({ moduleName, widgets })
  }

  updateTree = (treeData) => {
    this.setState({
      treeData,
    })
  }

  canDrag = ({ path }) => path.length < 2

  render() {
    const { isOpen, sortableModuleModalClose } = this.props
    const { treeData } = this.state

    return (
      <Modal show={isOpen} onHide={sortableModuleModalClose} dialogClassName="op-modal-dialog-manage-profiles">
        <Modal.Header>
          <button type="button" className="close" onClick={sortableModuleModalClose}>
            ×
          </button>
          <h4 className="modal-title">
            <Translate i18nString="Sort settings" />
          </h4>
        </Modal.Header>
        <Modal.Body className="op-modal-manage-profiles">
          <div className="op-modal-manage-profiles__tree">
            <SortableTree
              treeData={treeData}
              onChange={this.updateTree}
              onMoveNode={this.onMoveNodeHandler}
              rowHeight={30}
              canDrag={this.canDrag}
              maxDepth={1}
            />
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

// @ts-ignore
SortableModuleModal = connect(
  (state) => {
    const isOpen = isSortableModuleModalOpen(state)

    return {
      isOpen,
      moduleSettings: isOpen ? getTemplateActiveModuleWidgets(state) : [],
      moduleName: isOpen ? getActiveModuleName(state) : '',
    }
  },
  buildActionCreators({
    sortableModuleModalClose: ActionTypes.TEMPLATE_EDITOR_MODULE_SORT_MODAL_CLOSE,
    moveModuleSetting: ActionTypes.TEMPLATE_EDITOR_MODULE_SORT_MOVE_SETTING,
  }),
)(SortableModuleModal)

export default SortableModuleModal

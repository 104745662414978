// @ts-nocheck
import React from 'react'
import Blob from 'blob'
import dc from 'dc'
import R from 'ramda'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import { saveAs } from 'file-saver'
import { XMLSerializer } from 'xmldom'
import * as d3 from 'd3'
import htmlToImage from 'html-to-image'

import buildActionCreators from '../../helpers/buildActionCreators'
import GraphControls from './GraphControls'
import OpointDate from '../../opoint/common/time'
import { attachD3Tooltips } from './tooltip'
import * as ActionTypes from '../../constants/actionTypes'

// TODO move this to flow.js file
type GraphProps = {
  chartGroup: string

  values: Object // instance of crossfilter
  filter: any
  dimensionBy: string
  countBy: string
  brushOn?: boolean
  showLegend?: boolean
  isArray?: boolean

  series?: {
    by: string
    type: 'stacked' | 'overlapped'
  }

  chartType: 'lineChart' | 'barChart' | 'rowChart' | 'pieChart'
  width: number
  height: number
  colors?: any
}

const SERIES_KEY_I = 0
const DIMENSION_KEY_I = 1

// time formatting // TODO use translated locales
const customTimeFormat = getMultiFormat([
  // tighter labels for days and months
  [OpointCustomTimeFormat('HH:mm:ss.SSS'), (d) => d.millisecond() > 0],
  [OpointCustomTimeFormat('HH:mm:ss'), (d) => d.second() > 0],
  [OpointCustomTimeFormat('HH:mm'), (d) => d.minute() > 0],
  [OpointCustomTimeFormat('HH:mm'), (d) => d.hour() > 0],
  [OpointCustomTimeFormat('ddd D/M'), (d) => d.date() > 1],
  [OpointCustomTimeFormat('MMM'), (d) => d.month() > 0],
  [OpointCustomTimeFormat('YYYY'), () => true],
])

const customTimeFormatLong = getMultiFormat([
  // tighter labels for days and months
  [OpointCustomTimeFormat('HH:mm:ss.SSS'), (d) => d.millisecond() > 0],
  [OpointCustomTimeFormat('HH:mm:ss'), (d) => d.second() > 0],
  [OpointCustomTimeFormat('HH:mm'), (d) => d.minute() > 0],
  [OpointCustomTimeFormat('HH:mm'), (d) => d.hour() > 0],
  [OpointDate.shortDateFormat, (d) => d.date() > 1],
  [OpointCustomTimeFormat('MM/YY'), (d) => d.month() > 0],
  [OpointCustomTimeFormat('YYYY'), () => true],
])

class Graph extends React.PureComponent<GraphProps> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.name = props.name
    this.count = 0
    this.maxCount = 1000 // max count of lines/bars/pieces of chart to show
  }

  componentWillMount() {
    this.prepare(this.props)
  }

  componentDidMount() {
    this.create(this.rootRef, this.props, this.context.i18n)
    this.createCustomTable(this.props)
    this.exportPdfSvg()
  }

  componentWillUpdate(nextProps) {
    if (this.shouldRecreate(this.props, nextProps)) {
      this.destroy()
      this.prepare(nextProps)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.shouldRecreate(prevProps, this.props)) {
      this.create(this.rootRef, this.props, this.context.i18n)
      this.createCustomTable(this.props)
      this.exportPdfSvg()
    } else if (this.props.filter === undefined) {
      this.resetFilter()
    }
  }

  componentWillUnmount() {
    const { getPdfExportData, getTitle } = this.props

    if (!getPdfExportData) {
      return
    }

    /**
     * Returns an empty svg string to prevent the Graph export
     * This chart wouldn't be exported to pdf file but save its order in Map list
     */
    getPdfExportData(() => '', {
      title: () => (getTitle ? getTitle(this) : this.name),
      width: () => this.props.width,
      getName: () => this.name,
    })

    // TODO do not reset filter if whole statistics are unmounting
    this.destroy()
  }

  prepare(props) {
    const { values, dimensionBy, countBy, series, chartType, getTitle, isArray, showLegend, fallBackToLegend } = props

    // prepare dimension and key accessor
    let dimension
    let keyAccessor
    let seriesAccessor
    let keyRounder
    if (series) {
      dimension = values.dimension((d) => {
        if (isArray) {
          return d[series.by].map((series) => complexKey(+series, +d[dimensionBy]))
        }
        return complexKey(+d[series.by], +d[dimensionBy])
      }, isArray)

      keyAccessor = (d) => new Date(d.key[DIMENSION_KEY_I])
      seriesAccessor = (d) => d.key[SERIES_KEY_I]
      keyRounder = (round) => (oldKey) => complexKey(oldKey[SERIES_KEY_I], +round(new Date(oldKey[DIMENSION_KEY_I])))
    } else {
      dimension = values.dimension((d) => d[dimensionBy], isArray) // is array aspect
      keyAccessor = (d) => d.key
      seriesAccessor = (d) => null
      keyRounder = (round) => (key) => round(key)
    }

    function complexKey(series, dimension) {
      const key = []
      key[SERIES_KEY_I] = series
      key[DIMENSION_KEY_I] = dimension
      // using this ensures, that whole object is sortable
      /* eslint-disable-next-line func-names */
      key.valueOf = function () {
        return complexDimToSingleValue(this)
      }
      return key
    }

    dimension.type = (() => {
      let val = values.all()[0][dimensionBy]
      if (Array.isArray(val)) {
        ;[val] = val
      }
      const type = typeof val
      if (type !== 'object') {
        return type
      }
      return val instanceof Date ? 'date' : type
    })()
    dimension.by = dimensionBy
    this.dimension = dimension
    this.keyAccessor = keyAccessor
    this.seriesAccessor = seriesAccessor
    // prepare scale and units
    const { scale, units } = getScaleAndUnits(dimension, keyAccessor, keyRounder)
    this.scale = scale
    this.units = units

    // prepare group(s)
    const groups = []
    const sum = countBy ? (d) => d[countBy] || 0 : (d) => 1
    if (!series) {
      // simple graph
      const group = dimension.group(units.floor).reduceSum(sum)
      groups.push([group])
      this.count = group.size() + 1
    } else {
      // graph with series
      let keys = R.uniq(dimension.top(Infinity).map((d) => d[series.by]))

      this.count = keys.length
      this.maxCount = getMaxCountByChartType(chartType)

      if (chartType !== 'customDataTable') {
        keys = topNKeys(values, series.by, this.maxCount)
      }
      this.topKeys = keys

      switch (series.type) {
        case 'stacked': {
          keys.forEach((keys) => {
            let group = groupAndSumComplexDimension(dimension, keyRounder(units.floor), (d) =>
              String(keys) === String(d[series.by]) ? sum(d) : 0,
            )

            group = fillMissingBinsComplex(group, units)

            // filtering must be after filling to ensure number of bins is the same in each group
            group = filterGroup(
              group,
              isArray
                ? R.filter((d) => keys.some((key) => key === d.key[SERIES_KEY_I]))
                : R.filter((d) => keys.includes(d.key[SERIES_KEY_I])),
            )
            groups.push([group, String(keys)])
          })
          break
        }
        case 'overlapped': {
          // uses complex dimension key
          let group = groupAndSumComplexDimension(dimension, keyRounder(units.floor), sum)

          if (chartType !== 'customDataTable') {
            group = filterGroup(
              group,
              isArray // filter only bins with keys from top n keys
                ? R.filter((d) => keys.some((keys) => keys.includes(d.key[SERIES_KEY_I])))
                : R.filter((d) => keys.includes(d.key[SERIES_KEY_I])),
            )
            group = fillMissingBinsComplex(group, units)
          } else {
            group = compressGroupForTable(group, keyAccessor)
          }

          groups.push([group])
          break
        }
        default:
          throw new Error(`unknown series.type ${series.type}`)
      }
    }
    this.groups = groups

    if (typeof chartType === 'function') {
      this.chartType = chartType(this)
    } else {
      this.chartType = chartType
    }

    this.maxCount = getMaxCountByChartType(this.chartType)

    // decrease cap if needed in order not to have two too small consecutive slices
    if (this.chartType === 'pieChart') {
      const sum = dimension.groupAll().value()
      const [[group]] = groups
      const fractions = group
        .all()
        .map(({ value }) => value / sum)
        .sort((a, b) => b - a)
      this.maxCount = Math.min(this.maxCount, fractions.length)

      const sumLastTwo = () => fractions[this.maxCount - 1] + fractions[this.maxCount - 2]
      const sumLastOneAndOthers = () =>
        fractions.reduce((sum, fraction, i) => sum + (i >= this.maxCount - 1 ? fraction : 0), 0)

      while (this.maxCount > 2 && (sumLastTwo() < 0.05 || sumLastOneAndOthers() < 0.05)) {
        this.maxCount -= 1
      }

      // if there are still some too small slices next to each other,
      // fallback to pie chart with legends
      if (!showLegend && typeof chartType === 'function') {
        // but only in case of automatic type choosing
        if (sumLastOneAndOthers() < 0.1) {
          fallBackToLegend()
        }
      }
    }

    this.title = getTitle ? getTitle(this) : this.name
  }

  create(el, props, i18n) {
    const { chartType, keyAccessor, seriesAccessor } = this
    const {
      showLegend,
      width,
      height,
      label,
      chartGroup,
      keyToName,
      series,
      brushOn,
      filter,
      onFilterChange,
      colors,
      commonDimension,
      countBy,
    } = props

    const { dimension, groups, scale, units } = this

    if ([dimension, groups, units, scale].some((x) => !x)) {
      throw new Error('Graph.prepare() must be called before Graph.create()')
    }

    // check chartType is valid chartType
    if (!(chartType in dc)) {
      throw new Error(`${chartType} is not valid chart type`)
    }

    if (series && !['lineChart', 'barChart', 'customDataTable'].includes(chartType)) {
      throw new Error('only line and bar charts can have series')
    }

    // create chart:
    let chart
    if (series && series.type === 'overlapped' && chartType !== 'customDataTable') {
      chart = dc
        .seriesChart(el, chartGroup)
        .chart((el) => reApply(dc[chartType](el, chartGroup)) /* eslint-disable-line no-shadow */)
    } else {
      chart = dc[chartType](el, chartGroup)
    }

    chart.width(width).height(height)

    // set dimension

    if (chartType !== 'customDataTable') {
      chart.dimension(dimension)
    }

    {
      // set group(s)

      const groups = this.groups.slice(0)
      if (series) {
        // overlapped lineChart => series curves
        // stacked lineChart => series area
        // stacked barChart => series bars
        switch (series.type) {
          case 'stacked':
            while (groups.length > this.maxCount) {
              groups.pop()
            }
            chart.group(...groups.shift())
            while (groups.length) {
              chart.stack(...groups.shift())
            }
            break
          case 'overlapped':
            chart.group(...groups.pop())
            chart
              .seriesAccessor(seriesAccessor)
              .keyAccessor(keyAccessor)
              .valueAccessor((d) => d.value)
            if (chartType !== 'customDataTable') {
              chart.seriesSort(R.ascend(keyToName)) // sort series alphabetically
            }
            break
          default:
            throw new Error(`unknown series.type ${series.type}`)
        }
      } else {
        chart.group(...groups.pop())
      }
    }

    chart.filterAll()
    if (filter !== undefined) {
      chart.filter([filter])
    }

    /* set additional attributes */
    chart.keyAccessor(keyAccessor).valueAccessor((d) => d.value)

    if (chartType === 'customDataTable') {
      let columns
      if (series) {
        const seriesNames = R.uniqBy(
          String,
          dimension
            .top(Infinity)
            .map((d) => d[series.by])
            .filter((keys) => keys.length === 1),
        )
        columns = [
          // note: these will actually results to rows not columns
          {
            label: this.name,
            format: (d) => customTimeFormat(keyAccessor(d)), // first column (date)
          },
          // TODO ? hide this logic inside customDateTable implementation
          ...seriesNames
            .map((seriesKeys) => ({
              label: keyToName ? keyToName(seriesKeys) : seriesKeys,
              format: (d) => seriesKeys.map((key) => d.value[key]),
            }))
            .sort(R.ascend(R.prop('label'))),
        ]
      } else {
        columns = [
          {
            label: this.name,
            format: (d) => (keyToName ? keyToName(keyAccessor(d)) : keyAccessor(d)),
          },
          {
            label: '',
            format: (d) => d.value,
          },
        ]
      }
      chart
        .size(this.maxCount)
        .columns(columns)
        .sortBy(!series ? (d) => d.value : keyAccessor)
        .order(d3.descending)
    } else {
      // set colors
      chart.colors(colors || d3.scale.category20c())

      // set tooltips
      chart.renderTitle(false) // suppress default title
      attachD3Tooltips(
        chart,
        chartType,
        mergeGroups(groups),
        this.topKeys,
        keyAccessor,
        seriesAccessor,
        keyToName,
        customTimeFormat,
        commonDimension,
        countBy,
      ) // use custom d3 tip instead

      // set margins
      if (series) {
        chart.margins({
          top: 10,
          right: 225,
          left: 30,
          bottom: 20,
        })
      } else if (chartType === 'rowChart') {
        chart.margins({
          top: 0,
          left: 4,
          right: 4,
          bottom: 16,
        })
      }

      if (keyToName) {
        chart.title((d) => `${keyToName(keyAccessor(d))}: ${d.value}`)

        if (!series) {
          if (showLegend && chartType === 'pieChart') {
            const sum = countBy ? (d) => d[countBy] || 0 : (d) => 1
            const getSum = () => dimension.groupAll().reduceSum(sum).value()

            chart
              .label((d) => percents(d.value, getSum))
              .othersLabel((d) => percents(d.value, getSum))
              .minAngleForLabel(0) // draw all labels
              .radius(110) // make space for external labels
              .drawPaths(true)
              .externalLabels(16) // push labels outside
          } else {
            chart.label((d) => keyToName(keyAccessor(d)))
          }
        }
      }

      if (showLegend) {
        chart.legend(
          dc
            .legend()
            .x(385)
            .y(10)
            .gap(3)
            .horizontal(false)
            .itemWidth(70)
            .legendText(keyToName && ((d) => keyToName(d.name))),
        )

        // fix position for legend to fit
        if (chartType === 'pieChart') {
          chart.cx(175)
        } else {
          chart.margins({
            top: 10,
            right: 225,
            left: 30,
            bottom: 20,
          })
        }
      }
    }

    if (['pieChart', 'rowChart'].includes(chartType)) {
      chart.cap(this.maxCount).othersLabel(i18n.t('Others'))

      if (chartType === 'rowChart') {
        chart.elasticX(true).xAxis().ticks(4)
      }
      if (chartType === 'pieChart') {
        chart.innerRadius(15).externalRadiusPadding(10)
        if (!showLegend) {
          chart.minAngleForLabel(0).externalLabels(-30) // push labels far from center
        }
        // rotate in order to have less overlapping labels
        rotatePieChart(chart, 0) // TODO fix unwanted animation with 90deg or remove this entirely
      }
    }

    if (['lineChart', 'barChart'].includes(chartType)) {
      chart
        .x(scale)
        .xUnits(units.range)
        .renderHorizontalGridLines(true)
        .yAxisLabel(label)
        .brushOn(brushOn)
        .elasticY(true)
        .round(units.floor)

      chart.xAxis().tickFormat(customTimeFormatLong)

      chart.yAxis().ticks(4)

      if (!brushOn) {
        // elastic x causes problems with filtering barChart
        chart.elasticX(true).xAxisPadding(1)
      }

      setTimeout(() => {
        const tickNodes = d3.selectAll(`.${this.props.containerClassName} g.x .tick`)

        const ticksCount = tickNodes && tickNodes[0] && tickNodes[0].length
        const widthPerTickLabel = width / ticksCount

        if (widthPerTickLabel < 50) {
          const tickNodesEven = tickNodes && tickNodes.filter(':nth-child(even)')

          tickNodesEven &&
            tickNodesEven[0] &&
            tickNodesEven[0].forEach((tick) => {
              tick && tick.childNodes && tick.childNodes[0].setAttribute('transform', 'scale(1, 3)')

              tick && tick.childNodes && tick.childNodes[1].setAttribute('transform', 'translate(0, 12)')
            })
        }

        if (widthPerTickLabel < 30) {
          chart.xAxis().tickFormat(customTimeFormat)
        }
      }, 1)
    }

    // run func for chart or for each of its children
    const applyToChart = (chart, func) => {
      /* eslint-disable-line no-shadow */
      if ('children' in chart) {
        chart.children().forEach(func)
      } else {
        func(chart)
      }
    }

    // attributes to be applied now and after before redraw

    function reApply(chart) {
      /* eslint-disable-line no-shadow */

      applyToChart(chart, (chart) => {
        let maxVal
        if (chartType !== 'customDataTable') {
          maxVal = chart.group() ? d3.max(chart.group().all(), (v) => v.value) : Infinity
        }

        chart.ordering((d) => -d.value)

        if (['barChart', 'lineChart'].includes(chartType)) {
          chart.x(scale).elasticY(true).yAxis().ticks(Math.min(4, maxVal)).tickFormat(d3.format('d')) // print as decimal integers
          if (!brushOn) {
            chart.elasticX(true).xAxisPadding(1)
          }
          if (chartType === 'barChart') {
            chart.gap(2)
          }
          if (chartType === 'lineChart') {
            chart.interpolate('monotone')
            if (series && series.type === 'stacked') {
              chart.renderArea(true)
            }
          }
        }

        if (['rowChart'].includes(chartType)) {
          chart.gap(2).elasticX(true).xAxis().ticks(Math.min(5, maxVal)).tickFormat(d3.format('d')) // print as decimal integers
        }
      })
      return chart
    }

    reApply(chart)

    // render
    chart.render() // only render this chart now
    chart.chartGroup(chartGroup)

    this.chart = chart

    // handle events - should be after render to prevent unwanted initial firing
    chart.on('filtered', (chart) => {
      /* eslint-disable-line no-shadow */
      if (typeof onFilterChange === 'function') {
        onFilterChange(chart.filters())
      }
    })
    chart.on('preRedraw', (chart) => {
      /* eslint-disable-line no-shadow */
      // this is needed to be reapplied here,
      // in favor of maxVal to be recomputed after filtering is done from another chart
      // this ensure that number of marks on axes, is not greater than necessary
      reApply(chart)
    })
  }

  destroy() {
    if (this.chart) {
      const svgElement = this.chart && this.chart.svg()
      if (svgElement) {
        svgElement.remove()
      }
      this.chart.chartGroup(null) // remove chart form group so it will not be rendered in future
      this.chart = undefined
    }
    if (this.dimension) {
      this.dimension.dispose()
      this.dimension = undefined
    }
  }

  resetFilter() {
    // only reset if not already reset
    if (this.chart && !R.isEmpty(this.chart.filters())) {
      this.chart.filterAll()
      dc.redrawAll(this.props.chartGroup)
    }
  }

  /**
   * return true if props are differ in values which should cause recreation of chart
   * @param oldProps
   * @param newProps
   * @returns {Boolean}
   */
  /* eslint-disable-next-line class-methods-use-this */
  shouldRecreate(oldProps, newProps) {
    const excluded = ['filter', 'children']
    return R.any(
      /* eslint-disable-next-line no-underscore-dangle */
      R.complement(R.eqProps(R.__, oldProps, newProps)),
    )(R.without(excluded, R.keys(R.merge(oldProps, newProps))))
  }

  /**
   * exports chart as image file
   * @param {string} format of image
   */
  saveFile = (format) => {
    const filename = `${this.title}-${new Date().toISOString()}.${format}`

    switch (format) {
      case 'svg':
        this.getSvgBlob().then((blob) => saveAs(blob, filename))
        break
      case 'png':
        this.getPngBlob().then((blob) => saveAs(blob, filename))
        break
      case 'csv':
        this.getCsvBlob().then((blob) => saveAs(blob, filename))
        break
      default:
        throw new Error(`unknown image format ${format}`)
    }
  }

  /**
   * Export chart to CSV blob resolving in promise
   * @returns {Promise}
   */
  getCsvBlob() {
    const cvsString = this.toCsvString()

    return new Promise((resolve) => {
      resolve(new Blob([cvsString]))
    })
  }

  /**
   * Convert chart to SVG blob resolving in promise
   * @returns {Promise}
   */
  getSvgBlob = () => {
    const svgString = this.toSvgString()

    return new Promise((resolve) => {
      resolve(
        new Blob([svgString], {
          type: 'image/svg+xml;charset=utf-8',
        }),
      )
    })
  }

  /**
   * Convert chart to PNG blob resolving in promise
   * @returns {Promise}
   */
  getPngBlob() {
    const svgString = this.toSvgString()
    const DOMURL = window.URL || window.webkitURL || window
    const svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' })

    return new Promise((resolve) => {
      // import svg into image
      const img = document.createElement('img')

      img.onload = () => {
        const canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height

        const ctx = canvas.getContext('2d')
        ctx.fillStyle = '#fff'
        ctx.fillRect(0, 0, img.width, img.height)
        ctx.drawImage(img, 0, 0)
        try {
          canvas.toBlob(resolve, 'image/png')
        } catch (e) {
          this.props.showBadBrowserPopup()
        }
      }
      img.src = DOMURL.createObjectURL(svgBlob)
    })
  }

  createCustomTable = (props) => {
    const { getPdfExportData, hidden } = props
    const { chart, chartType } = this
    const FIXED_ROWS_AMOUNT = 12 // Limitation rows to 12 in cloned chart

    const isTablePdfExport = getPdfExportData && chartType === 'customDataTable' && hidden

    if (!isTablePdfExport) {
      return
    }

    const tbody = d3.select(chart.root().node()).select('tbody')
    const rows = tbody.selectAll('tr')

    const attachedData = R.slice(0, FIXED_ROWS_AMOUNT, rows[0]).map((row) =>
      d3
        .select(row)
        .selectAll('td')[0]
        .map((col) => col.innerText),
    )

    tbody.remove()

    const newBodyRows = d3
      .select(chart.root().node())
      .append('tbody')
      .selectAll('tr')
      .data(attachedData)
      .enter()
      .append('tr')
      .attr('class', 'dc-table-group')
    newBodyRows
      .selectAll('td')
      .data((d) => d)
      .enter()
      .append('td')
      .attr('class', 'dc-table-column')
      .attr('title', (d) => d)
      .text((d) => d)
  }

  /**
   * Make PNG snapshot of html table and return it as base64 svg string
   * Otherwise, return svg graph string
   */
  exportPdfSvg = () => {
    const { getPdfExportData, getTitle, hidden } = this.props
    const { chartType, toSvgString } = this

    const pdfExportData = (svg) =>
      getPdfExportData(svg, {
        title: () => (getTitle ? getTitle(this) : this.name),
        width: () => this.props.width,
        getName: () => this.name,
      })

    if (!getPdfExportData) {
      return
    }

    if (chartType !== 'customDataTable' && !hidden) {
      pdfExportData(toSvgString)
    } else if (chartType === 'customDataTable' && hidden) {
      htmlToImage.toPng(this.rootRef).then((data) => {
        const dimensions = this.getImageDimensions(data)

        dimensions
          .then((size) => this.datauriToSvgString(data, size))
          .then((svg) => {
            pdfExportData(() => svg)
          })
      })
    }
  }

  /**
   * Hack to convert image into svg string
   * @param {String} context - base64 data image
   * @param {width, heigth} - image dimensions
   * @returns {String}
   */
  datauriToSvgString = (context, { width, height }) => {
    let svgString = ''

    /* eslint-disable no-multi-spaces */
    svgString += `<svg width="${width}" height="${height}">`
    svgString += `<image width="${width}" height="${height}" xlink:href="${context}"/>`
    svgString += '</svg>'
    /* eslint-enable no-multi-spaces */

    return svgString
  }

  getPixelRatio = () => {
    let ratio = 1
    // To account for zoom, change to use deviceXDPI instead of systemXDPI
    if (
      window.screen.systemXDPI !== undefined &&
      window.screen.logicalXDPI !== undefined &&
      window.screen.systemXDPI > window.screen.logicalXDPI
    ) {
      // Only allow for values > 1
      ratio = window.screen.systemXDPI / window.screen.logicalXDPI
    } else if (window.devicePixelRatio !== undefined) {
      ratio = window.devicePixelRatio
    }
    return ratio
  }

  /**
   * Get image dimenstions resolving in promise
   * @param {String} file - svg string
   * @returns {Promise}
   */
  getImageDimensions = (file) =>
    new Promise((resolved, rejected) => {
      const img = new Image()
      const browserZoomLevel = this.getPixelRatio()
      const pixelRatio = 1 / browserZoomLevel

      img.src = file
      img.onload = () => {
        resolved({ width: img.width * pixelRatio, height: img.height * pixelRatio })
      }
    })

  toCsvString() {
    // actual delimiter characters for CSV format
    const colDelim = ';'
    const rowDelim = '\r\n'

    // Grab text from table into CSV formatted string
    const rows = d3.select(this.chart.root().node()).selectAll('tr')

    const rowsRaw = rows[0].map((row) => {
      const cols = d3.select(row).selectAll('td, th')
      const colsRaw = cols[0].map((col) => {
        const text = col.innerText.trim().replace(/"/g, '""') // escape double quotes
        return `"${text}"` // wrap to double quotes
      })
      return colsRaw.join(colDelim)
    })
    return rowsRaw.join(rowDelim)
  }

  toSvgString = (transformOutput = false) => {
    const svgElSelection = this.chart && this.chart.svg()

    if (!svgElSelection) {
      return null
    }

    svgElSelection
      .attr('title', this.title)
      .attr('version', 1.1)
      // .attr('id', 'dc-chart1-clip')
      .attr('xmlns', 'http://www.w3.org/2000/svg')

    let svgElement = svgElSelection.node()

    // set computed styles as style attribute
    traverseDOM(svgElement, explicitlySetStyle)

    svgElement = svgElement.cloneNode(true)

    // svgElement.children[0].transform = 'scale(0.5)'
    // if (transformOutput) {
    //   svgElement
    //     .setAttribute('transform', transformOutput)
    // }

    return new XMLSerializer().serializeToString(svgElement)
  }

  render() {
    const { hideTitle, menu, children, className, style } = this.props
    const { chartType } = this

    const isTable = chartType === 'customDataTable'

    const controls = menu ? <GraphControls menu={menu} graph={this} type={isTable ? 'table' : 'chart'} /> : null

    const title = !hideTitle ? <div className="title">{this.title}</div> : null

    return isTable ? (
      <div className={['dc-chart', className].join(' ')} style={style}>
        {title}
        {controls}
        {children}
        <div className="table-wrapper">
          <table
            ref={(ref) => {
              this.rootRef = ref
            }}
            className="table-bordered"
          />
        </div>
      </div>
    ) : (
      <div
        ref={(ref) => {
          this.rootRef = ref
        }}
        className={[className].join(' ')}
        style={style}
      >
        {title}
        {controls}
        {children}
      </div>
    )
  }
}

export default connect(
  (state) => ({}),
  buildActionCreators({
    showBadBrowserPopup: ActionTypes.SHOW_BAD_BROWSER_POPUP,
  }),
)(Graph)

/**
 * This ensures, that data with complex dimension are sorted correctly,
 * (otherwise dates are compared as strings)
 * @param dimension
 * @returns {string}
 */
function complexDimToSingleValue(dimension) {
  const zeroes = '000000000000000'
  const fillZeroes = (number) => (zeroes + number).slice(zeroes.length)
  return fillZeroes(dimension[DIMENSION_KEY_I]) + fillZeroes(dimension[SERIES_KEY_I])
}

/**
 * Create filtered fake group from given group and filter
 */
function filterGroup(group, filter) {
  return {
    all() {
      return filter(group.all().slice(0))
    },
    top(n) {
      return this.all().filter((_, i) => i < n)
    },
    size() {
      return this.all().length + 1
    },
  }
}

function topNKeys(values, by, n) {
  const tempDim = values.dimension((d) => d[by])
  const keys = tempDim
    .group()
    .top(n)
    .map((d) => d.key)
  tempDim.dispose()
  return keys
}

/**
 * Crossfilter's group method does not works properly on complex dimension,
 * so we need to group values manually emulates wanted behavior of
 * dimension.group(groupValue).reduceSum(value)
 * @param dimension
 * @param groupValue - grouping function
 * @param sumValue - sum function
 */
function groupAndSumComplexDimension(dimension, groupValue, sumValue) {
  return {
    all() {
      const rounded = dimension
        .group()
        .reduceSum(sumValue)
        .all()
        .map((d) => {
          d.key = groupValue(d.key) // we do not use R.evolve here for better performance
          return d
        })
      this.rounded = rounded

      const reduced = rounded.reduce((reducedBySeries, d) => {
        const seriesKey = d.key[SERIES_KEY_I]

        if (!reducedBySeries[seriesKey]) {
          reducedBySeries[seriesKey] = []
          reducedBySeries[seriesKey].push(d)
        } else {
          const lastInSeries = reducedBySeries[seriesKey][reducedBySeries[seriesKey].length - 1]
          if (lastInSeries.key[DIMENSION_KEY_I] === d.key[DIMENSION_KEY_I]) {
            lastInSeries.value += d.value
          } else {
            reducedBySeries[seriesKey].push(d)
          }
        }

        return reducedBySeries
      }, {})

      return R.flatten(R.values(reduced))
    },
    top(n) {
      return this.all().filter((_, i) => i < n)
    },
    size() {
      return this.rounded.length + 1
    },
  }
}

function mergeGroups(groups) {
  /* eslint-disable-next-line */
  groups = groups.map(([group, _]) => group)
  return {
    all() {
      return groups.reduce((mergedGroup, group) => mergedGroup.concat(group.all()), [])
    },
    size() {
      return groups.reduce((sumSize, group) => sumSize + group.size(), 0)
    },
  }
}

function getMaxCountByChartType(chartType) {
  return (
    {
      pieChart: 5,
      rowChart: 10,
      barChart: 10,
      lineChart: 10,
    }[chartType] || 1000
  )
}

/**
 * Recursively call func on each node element in node tree
 * @param {Node} node
 * @param {Function} func
 */
function traverseDOM(node, func) {
  if (node.nodeType === document.ELEMENT_NODE) {
    func(node)
    node = node.firstChild /* eslint-disable-line */
    while (node) {
      traverseDOM(node, func)
      node = node.nextSibling /* eslint-disable-line */
    }
  }
}

/**
 * Set computed style of given element as its style attribute
 * @param {Element} element
 */
function explicitlySetStyle(element) {
  if (element.nodeName === 'SCRIPT') {
    return
  }
  const elementStyle = window.getComputedStyle(element) // CSSStyleDeclaration
  const attrs = element.attributes // not an array, but NamedNodeMap
  const style = {} // plain object style - elementStyle is immutable so we need a copy

  for (let i = 0; i < elementStyle.length; i++) {
    const name = elementStyle[i]
    style[name] = elementStyle.getPropertyValue(name)
  }
  for (let i = 0; i < attrs.length; i++) {
    // remove those properties which are also svg attributes
    const attr = attrs[i]
    delete style[attr.name]
  }

  const styleStr = styleToCssString(style)
  element.setAttribute('style', styleStr)
}

/**
 * Create css string from given style
 * @param {Object} style
 * @returns string
 */
function styleToCssString(style) {
  return R.reduce(
    (str, key) => {
      const val = style[key]
      return str + (val ? `${key}: ${val};` : '')
    },
    '',
    R.keys(style),
  )
}

/**
 * Returns an ideal scale and units functions for given dimension
 * @param dimension
 * @param keyAccessor function(key): key
 * @returns {{scale: *, units: {round: (function(*): *), range: (function(*, *): *)}}}
 */
function getScaleAndUnits(dimension, keyAccessor, keyRounder) {
  let scale = (d) => d
  let units = {
    floor: (d) => d, // groupValue function
    range: (from, to, domain) => domain, // xUnits function
  }

  if (dimension.bottom(1).length === 0) {
    throw new Error('empty dimension')
  }
  let minDim = dimension.bottom(1)[0][dimension.by]
  let maxDim = dimension.top(1)[0][dimension.by]

  if (Array.isArray(minDim)) {
    ;[minDim] = minDim
  }
  if (Array.isArray(maxDim)) {
    ;[maxDim] = maxDim
  }

  switch (dimension.type) {
    case 'date': {
      units = computeTimeUnits(dimension, keyAccessor, keyRounder)
      minDim = new Date(+minDim)
      maxDim = new Date(+maxDim)
      scale = d3.time
        .scale()
        .domain([minDim, units.offset(maxDim, +1)])
        .nice()
      break
    }
    case 'string': {
      units.range = dc.units.ordinal

      const domain = R.uniq(dimension.top(Infinity).map((d) => d[dimension.by]))

      scale = d3.scaleOrdinal().domain(domain)

      scale.rangeBands = (interval, padding, outerPadding) => {
        scale.range(interval)
        scale.padding(padding)
        scale.paddingOuter(outerPadding)
      }
      scale.rangeBand = scale.bandwidth
      break
    }
    case 'number': {
      units.range = dc.units.integers
      scale = d3.scale.linear()
      maxDim += 1
      scale.range([minDim, maxDim])
      break
    }
    default:
      throw new Error(`unknown type of dimension value - ${typeof minDim} of dimension by ${dimension.by}`)
  }
  return { scale, units }
}

/**
 * Creates a fake group (https://github.com/dc-js/dc.js/wiki/FAQ#fake-groups)
 * to ensure, that group contains all the bins that are presented in whole data set.
 * This function is used by overlapped lineCharts with series.
 * @param sourceGroup
 * @param units
 * @returns {{all: function}}
 */
function fillMissingBinsComplex(sourceGroup, units) {
  // NOTE: this implementation expecting dimension to be of type date,
  // with dates stored as timestamp
  /**
    should be used to sort array in same way as following ramda function does
    we don't use ramda here for performance reasons
    R.sortWith([
      R.ascend(d => d.key[DIMENSION_KEY_I]),
      R.ascend(d => d.key[SERIES_KEY_I]),
      R.descend(d => d.value),
    ])
  */
  const sortCompare = (a, b) => {
    // sort by dimension key first because dimension must be correctly naturally ordered
    if (a.key[DIMENSION_KEY_I] < b.key[DIMENSION_KEY_I]) {
      return -1
    }
    if (a.key[DIMENSION_KEY_I] > b.key[DIMENSION_KEY_I]) {
      return +1
    }
    // sort by series key to ensure only consecutive items may have identical key
    // and thus we may use simple filter in next function
    // to remove duplicities instead of unique function
    if (a.key[SERIES_KEY_I] < b.key[SERIES_KEY_I]) {
      return -1
    }
    if (a.key[SERIES_KEY_I] > b.key[SERIES_KEY_I]) {
      return +1
    }
    // sort by value from largest to ensure following duplicity
    // removing leaves items with non zero values
    if (a.value < b.value) {
      return +1
    }
    if (a.value > b.value) {
      return -1
    }
    return 0
  }

  const uniqueSortedFilter = (d, i, arr) =>
    i === 0 ||
    d.key[DIMENSION_KEY_I] !== arr[i - 1].key[DIMENSION_KEY_I] ||
    d.key[SERIES_KEY_I] !== arr[i - 1].key[SERIES_KEY_I]

  return {
    all() {
      // NOTE: we mustn't modify the original array, make sure it stays intact
      let result = sourceGroup.all()

      if (result.length === 0) {
        throw new Error('group for filling missing bins has no bins')
      }

      const boundaries = new Set([
        // array of timestamps
        ...units
          .range(new Date(result[0].key[DIMENSION_KEY_I]), new Date(result[result.length - 1].key[DIMENSION_KEY_I]))
          .map((d) => +d),
        ...result.map((d) => d.key[DIMENSION_KEY_I]),
      ])

      const seriesKeys = new Set(result.map((bin) => bin.key[SERIES_KEY_I]))
      const binsToAdd = []

      boundaries.forEach((boundary) => {
        seriesKeys.forEach((seriesKey) => {
          const newKey = []
          newKey[DIMENSION_KEY_I] = boundary
          newKey[SERIES_KEY_I] = seriesKey
          /* eslint-disable-next-line func-names */
          newKey.valueOf = function () {
            return complexDimToSingleValue(this)
          }
          binsToAdd.push({ key: newKey, value: 0 }) // add zero-valued bin
        })
      })
      // concat creates new array so we can safely sort it in place in next step
      result = result.concat(binsToAdd)

      result = result.sort(sortCompare)

      result = result.filter(uniqueSortedFilter) // remove duplicities - must be sorted before

      return result
    },
    top(n) {
      return this.all().filter((_, i) => i < n)
    },
    size() {
      return this.all().length
    },
  }
}

function compressGroupForTable(sourceGroup, keyAccessor) {
  return {
    all() {
      // copy original results (we mustn't modify them)
      let result = R.sortBy(keyAccessor)(sourceGroup.all())
      result = result.reduce((compressed, { key, value }) => {
        if (R.isEmpty(compressed) || +R.last(compressed).key[DIMENSION_KEY_I] !== +key[DIMENSION_KEY_I]) {
          compressed.push({
            key: R.update(SERIES_KEY_I)(null)(key),
            value: {},
          })
        }
        R.last(compressed).value[key[SERIES_KEY_I]] = value
        return compressed
      }, [])

      return result
    },
    top(n) {
      return this.all().filter((_, i) => i < n)
    },
    size() {
      return this.all().length
    },
  }
}

/**
 * Decides which timeUnit we should use as interval for a given data set
 * so number of bins (bars in histogram) is in sane range (~15..30)
 * @param timeDimension
 * @returns timeUnit function
 */
function computeTimeUnits(timeDimension, keyAccessor, keyRounder) {
  // returns number of boundaries in interval given by timeUnit function (d3-time Interval)
  const getTimeSize = (timeUnit) => {
    const all = timeDimension.group(keyRounder(timeUnit)).all()
    if (all.length === 0) {
      return 0
    }
    const firstGroup = all[0]
    const lastGroup = all[all.length - 1]

    return timeUnit.count(new Date(keyAccessor(firstGroup)), new Date(keyAccessor(lastGroup)))
  }

  // same as timeUnit.every(number)
  // but solves issue where
  // timeUnit.every(x).count does not exist for x > 1
  const every = (number, timeUnit) => {
    if (!timeUnit.count) {
      timeUnit.count = (from, to) => timeUnit.range(from, to).length
    }
    /* eslint-disable-next-line no-underscore-dangle */
    timeUnit.range = R.curry(timeUnit.range)(R.__, R.__, number)
    return timeUnit
  }

  // TODO refactor this ifception madness

  // a reasonable count/group: we start with days: 15 to 41
  let timeUnit = every(1, d3.time.day)
  let timeSize = getTimeSize(timeUnit)

  // over 42 days, go over to weeks: 7 to 40 bins
  if (timeSize >= 42 && timeSize <= 280) {
    timeUnit = every(1, d3.time.week)
  }
  // over 280 days, go over to months, between 8 and 36
  if (timeSize > 280 && timeSize <= 1094) {
    timeUnit = every(1, d3.time.month)
  }
  // over 1094 days: go over to years, 3 and above
  if (timeSize > 1094) {
    timeUnit = every(1, d3.time.year)
  }
  // between 15 and 41 days we do nothing at all and use the default

  // 7 to 14 days, go down to 12 hour bins: 14 to 28 bins
  if (timeSize >= 7 && timeSize <= 14) {
    timeUnit = every(12, d3.time.hour)
  }
  // 4 to 6 days, go down to 6 hour bins: 16 to 24 bins
  if (timeSize >= 4 && timeSize <= 6) {
    timeUnit = every(6, d3.time.hour)
  }
  // 3 days, go down to 3 hour bins: 17 to 24 bins
  if (timeSize === 3) {
    timeUnit = every(3, d3.time.hour)
  }
  // 2 days or less: go down to hours: 12 to 48 bins
  if (timeSize <= 2) {
    timeUnit = every(1, d3.time.hour)
    timeSize = getTimeSize(timeUnit)

    // 7 to 12 hours, go down to 30 minute bins: 14 to 24 bins
    if (timeSize >= 7 && timeSize <= 12) {
      timeUnit = every(30, d3.time.minute)
    }
    // 4 to 6 hours, go down to 15 minute bins: 12 to 24 bins
    if (timeSize >= 4 && timeSize <= 6) {
      timeUnit = every(15, d3.time.minute)
    }
    // 3 hours or less, go down to 5 minute bins: 14 to 24 bins
    if (timeSize <= 3) {
      timeUnit = every(5, d3.time.minute)
      timeSize = getTimeSize(timeUnit)

      // less than 14 * 5m = 70m, first go to 2 minute bins: 15 to 35 bins
      if (timeSize <= 14 && timeSize >= 6) {
        timeUnit = every(2, d3.time.minute)
      }
      // less than 6 * 5m = 30m, go to 1 minute bins
      if (timeSize < 6) {
        timeUnit = every(1, d3.time.minute)
      }
    }
  }
  return timeUnit
}

function percents(val, getMax) {
  return `${Math.round((val * 100) / getMax())} %`
}

// api of dataTable slightly differ from others
// it does not does not use crossfilte's group for grouping,
// but it works when group is used as dimension
// this customDataTable should unify that api
/* eslint-disable-next-line func-names */
dc.customDataTable = function (parent, chartGroup) {
  const chart = dc.dataTable(parent, chartGroup)
  const fooGrpFn = (d) => d

  chart.group(fooGrpFn)
  chart.showGroups(false)

  // rename dimension method to group
  /* eslint-disable-next-line func-names */
  chart.group = function (group, name) {
    if (!group) {
      return fooGrpFn
    }
    return this.dimension(group, name)
  }

  /* eslint-disable-next-line no-underscore-dangle */
  let _accessor
  /* eslint-disable-next-line func-names */
  chart.seriesAccessor = function (accessor) {
    if (!accessor) {
      return _accessor
    }

    _accessor = accessor

    return this
  }

  chart.transpose = function transpose() {
    const rows = d3.select(chart.root().node()).selectAll('tr')
    const attachedData = rows[0].map((row) =>
      d3
        .select(row)
        .selectAll('th, td')[0]
        .map((col) => col.innerText),
    )
    rows.remove()
    d3.select(chart.root().node()).selectAll('thead, tbody').remove()
    const transposedData = R.transpose(attachedData)

    // thead
    const newHeadRows = d3
      .select(chart.root().node())
      .append('thead')
      .selectAll('tr')
      .data([R.head(transposedData)])
      .enter()
      .append('tr')
      .attr('class', 'header')
    newHeadRows
      .selectAll('th')
      .data((d) => d)
      .enter()
      .append('th')
      .text((d) => d)

    // tbody
    const newBodyRows = d3
      .select(chart.root().node())
      .append('tbody')
      .selectAll('tr')
      .data(R.tail(transposedData))
      .enter()
      .append('tr')
      .attr('class', 'dc-table-group')
    newBodyRows
      .selectAll('td')
      .data((d) => d)
      .enter()
      .append('td')
      .attr('class', 'dc-table-column')
      .attr('title', (d) => d)
      .text((d) => d)
  }
  return chart
}

function OpointCustomTimeFormat(format: string): any {
  return R.partialRight(OpointDate.customFormat, [format])
}

/**
 * Returns function for formatting date based on array of [format, test] doubles rules
 * @param formats
 * @returns {function(*=)}
 */
function getMultiFormat(
  formats: Array<[(format: string) => any, (date: Object) => boolean]>,
): (date: Object) => string {
  return (date) => {
    let timeFormat
    formats.some(([format, test]) => /* eslint-disable-line */
      test(moment(date)) ? (timeFormat = format) && true : false,
    )
    return timeFormat(date)
  }
}

// TODO prevent unwanted transitioning if possible
function rotatePieChart(chart, deg) {
  chart.on('pretransition', (innerChart) => {
    innerChart.selectAll('.pie-slice-group .pie-slice').attr('transform', rotate(deg))
    innerChart.selectAll('.pie-label-group').attr('transform', rotate(deg))
    // innerChart.selectAll('.pie-label-group .pie-slice').interrupt()
  })
  chart.on('renderlet', (innerChart) => {
    innerChart.selectAll('.pie-label-group .pie-slice').attr('transform', rotate(-deg))
  })

  function rotate(degree) {
    /* eslint-disable-next-line func-names */
    return function () {
      const rot = `rotate(${degree}.0)`
      const transformations = (this.getAttribute('transform') || '').split(' ')
      if (transformations[transformations.length - 1] !== rot) {
        transformations.push(rot)
      }
      return transformations.join(' ')
    }
  }
}

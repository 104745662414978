import React from 'react'
import PropTypes from 'prop-types'

import OpointLightBox from './OpointLightBox'
import { getArticleUrl } from '../../../opoint/articles'

const SCREENSHOT_TYPE_THUMBNAIL = 2
const SCREENSHOT_TYPE_FULLSIZE = 4

export default class Screenshot extends React.Component<any, any> {
  static propTypes = {
    article: PropTypes.object.isRequired,
  }

  static contextTypes = {
    getString: PropTypes.func,
    URLService: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      isOpen: undefined,
    }
  }

  onClose = () => {
    this.setState({ isOpen: undefined })
  }

  onClick = () => {
    this.setState({ isOpen: true })
  }

  render() {
    const {
      article,
      article: {
        mediatype: { text: mediaType },
      },
    } = this.props

    const screenshot = article.screenshots.find(({ id_type: idType }) => idType === SCREENSHOT_TYPE_THUMBNAIL)
    const screenshotThumbnail = article.screenshots.find(({ id_type: idType }) => idType === SCREENSHOT_TYPE_FULLSIZE)

    if (!screenshotThumbnail || !screenshot) {
      return null
    }

    const image = <img src={screenshotThumbnail.text} alt={screenshotThumbnail.text} />

    return (
      <div>
        {this.state.isOpen && (
          <OpointLightBox
            images={[{ src: screenshot.text }]}
            showImageCount={false}
            enableKeyboardInput
            isOpen={this.state.isOpen !== undefined}
            onClose={this.onClose}
          />
        )}
        <div className="article-image">
          {mediaType === 'PRINT' ? (
            <a href={getArticleUrl(article)} target="_blank" rel="noopener noreferrer" className="pdf">
              {image}
            </a>
          ) : (
            <a onClick={this.onClick} className="pdf">
              {image}
            </a>
          )}
        </div>
      </div>
    )
  }
}

import React from 'react'
import PropTypes from 'prop-types'

import Translate from '../../../Translate'
import VideoMatchesReactComponent from './Videomatches'
import { stripHTML } from '../../../opoint/common'
import OpointTextWithMatchesAndEntities from '../../../new-components/articles/OpointTextWithMatchesAndEntities'

/**
 * Depending on searchD flags, it displays matchwords or perex
 */
export default class ArticleSummary extends React.PureComponent<any> {
  static propTypes = {
    onContentChange: PropTypes.func,
  }

  onInputChange = (event) => {
    const { onContentChange } = this.props

    onContentChange({ author: event.target.value })
  }

  onTextareaChange = (event) => {
    const { onContentChange } = this.props

    onContentChange({ summary: { text: event.target.value } })
  }

  render() {
    const { article, editable } = this.props

    if (article.mediatype && article.mediatype.timemap) {
      return <VideoMatchesReactComponent {...this.props} article={article} />
    }
    return editable ? (
      <div className="articleMetatags">
        <label htmlFor="author">
          <Translate i18nString="Author" />
        </label>
        <textarea
          name="author"
          className="articleMetatags-author"
          value={article.author}
          onChange={this.onInputChange}
        />
        <label htmlFor="summary">
          <Translate i18nString="Summary" />
        </label>
        <textarea
          name="summary"
          className="articleMetatags-summary"
          value={stripHTML(article.summary.text)}
          onChange={this.onTextareaChange}
        />
      </div>
    ) : (
      <div>
        <p className="perex">
          <OpointTextWithMatchesAndEntities text={article.summary.text} />
        </p>
        {!article.summary.text && !article.body.text && (
          <div>
            {!article.quotes ? (
              <div>
                {article.matches.map((match) => (
                  <div>
                    {/*
                    //@ts-ignore */}
                    <match color={match.color}>{match.text}</match>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                {Object.values(article.quotes).map((quote: any, i) => (
                  <p key={i}>
                    <OpointTextWithMatchesAndEntities text={quote.text} />
                  </p>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

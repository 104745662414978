import PropTypes from 'prop-types'
import React from 'react'

import { Article } from '../../../opoint/flow'

export function getArticleBadge(article: Article) {
  return article.mediatype.text !== 'WEB' ? <ArticleBadge article={article} /> : undefined
}

export class ArticleBadge extends React.PureComponent<any> {
  static propTypes = {
    article: PropTypes.object.isRequired,
  }

  render() {
    const { article } = this.props
    return <span>{article.mediatype.text.replace('_', ' ')}</span>
  }
}

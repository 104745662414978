import React, { memo } from 'react'
import { connect } from 'react-redux'

import Modal from '../common/Modal'
import { SETTINGS_MODAL_CLOSE } from '../../constants/actionTypes'
import buildActionCreators from '../../helpers/buildActionCreators'
import SettingsForm from './Form'
import Translate from '../common/Translate'
import styles from './index.module.scss'

const SettingsModal = (props) => {
  const { isOpen, settingsModalClose } = props
  return (
    <Modal isOpen={isOpen} onHide={settingsModalClose} title="Settings">
      <div className={styles.modalBody}>
        <h3>
          <Translate text="Adjust your display and search preferences" />:
        </h3>
        <SettingsForm {...props} />
      </div>
    </Modal>
  )
}

export default connect(
  (state: any) => ({
    isOpen: state.ui.settingsModalOpen,
  }),
  buildActionCreators({
    settingsModalClose: SETTINGS_MODAL_CLOSE,
  }),
)(memo(SettingsModal))

import * as React from 'react'
import * as classNames from 'classnames'
import { useEffect, useState } from 'react'

// @ts-ignore
import styles from './HorizontalScroll.module.scss'

// update this constant in .scss
const DEFAULT_VISIBLE_CELLS = 6

const HorizontalScroll = ({ children }) => {
  const scrollableAreaRef = React.useRef<HTMLUListElement>(null)
  const [leftHidden, setLeftHidden] = useState<boolean>(true)
  const [rightHidden, setRightHidden] = useState<boolean>(false)

  useEffect(() => {
    const el = scrollableAreaRef.current

    if (el !== null) {
      const offsetSize = el.clientWidth / DEFAULT_VISIBLE_CELLS
      setRightHidden(el.scrollWidth - offsetSize * 0.3 < el.clientWidth)
    }

    const onScroll = (e) => {
      const offsetSize = el.clientWidth / DEFAULT_VISIBLE_CELLS
      setLeftHidden(el.scrollLeft < offsetSize * 0.3)
      setRightHidden(el.scrollWidth - (el.scrollLeft + el.clientWidth) < offsetSize * 0.3)
    }
    scrollableAreaRef.current.addEventListener('scroll', onScroll)
    return () => scrollableAreaRef.current.removeEventListener('scroll', onScroll)
  }, [scrollableAreaRef])

  const scroll = (direction: 'left' | 'right') => () => {
    const speed = 2
    const offsetSize = scrollableAreaRef.current.clientWidth / DEFAULT_VISIBLE_CELLS
    scrollableAreaRef.current.scrollLeft += direction === 'left' ? -offsetSize * speed : offsetSize * speed
  }

  return (
    <div className={styles.wrapper}>
      <div>
        <button
          className={classNames([styles.leftPaddle, styles.paddle], {
            [styles.hidden]: leftHidden,
          })}
          onClick={scroll('left')}
        >
          <i className="op-icon-arrow-big-left" />
        </button>
        <button
          className={classNames([styles.rightPaddle, styles.paddle], {
            [styles.hidden]: rightHidden,
          })}
          onClick={scroll('right')}
        >
          <i className="op-icon-arrow-big-right" />
        </button>
      </div>
      <ul className={styles.content} ref={scrollableAreaRef}>
        {children}
      </ul>
    </div>
  )
}

export default HorizontalScroll

import type { TBListRuleExpression } from '../../opoint/flow'

export function convertRuleToStringExpressions(expressions: Array<TBListRuleExpression>) {
  return expressions.map((exp) => {
    const newExp: any = {}
    newExp.weight = exp.weight.numbernode.value

    const expression = JSONtoStringExpression(exp)
    newExp.value = expression.value
      .replace(/[,<>*=]/g, '<span class="h-1">$&</span>')
      .replace(/[)(]/g, '<span class="h-2">$&</span>')
      .replace(/[|&]{2}/g, '<span class="h-3">$&</span>')
    return newExp
  })
}

function JSONtoStringExpression(exp) {
  if (exp.logical !== undefined) {
    return processLogicalExpr(exp)
  }
  if (exp.flexregex !== undefined) {
    return {
      value: exp.flexregex.value,
      operator: null,
    }
  }
  if (exp.comparison !== undefined) {
    return processComparisonExpr(exp)
  }
  if (exp.numbernode !== undefined) {
    return processNumbernodeExpr(exp)
  }
  if (exp.arithmetic !== undefined) {
    return processArithmeticExpr(exp)
  }
  if (exp.function !== undefined) {
    return processFunctionExpr(exp)
  }
  if (exp.span) {
    return processSpanGroupExpr(exp)
  }
  if (exp.req_expr) {
    return {
      operator: null,
      value: '',
    }
  }
}

function processSpanGroupExpr(exp) {
  const spanExp = exp.span.SPANGROUP.map((x) => JSONtoStringExpression(x).value).join(' ')
  return {
    operator: null,
    value: `span_word (${spanExp})`,
  }
}

function processLogicalExpr(exp) {
  const ret: any = {}
  const left = JSONtoStringExpression(exp.logical.left)
  const right = JSONtoStringExpression(exp.logical.right)

  // logical expression can be one value
  if (exp.logical.operator === 'NOT') {
    return {
      operator: null,
      value: `!${right.value}`,
    }
  }

  const operator = exp.logical.operator === 'AND' ? '&&' : '||'
  if (operator !== left.operator && left.operator) {
    left.value = `(${left.value})`
  }
  if (operator !== right.operator && right.operator) {
    right.value = `(${right.value})`
  }
  ret.operator = operator
  ret.value = left.value + operator + right.value
  return ret
}

function processFunctionExpr(exp) {
  const fnArgs = exp.function.functionarg.map((x) => processNumbernodeExpr(x).value).join(',')
  return {
    operator: null,
    value: `(${exp.function.identifier} (${fnArgs}))`,
  }
}

function processArithmeticExpr(exp) {
  const left = JSONtoStringExpression(exp.arithmetic.left)
  const right = JSONtoStringExpression(exp.arithmetic.right)

  const operator = {
    SUB: '-',
  }[exp.arithmetic.operator]

  return {
    operator: exp.arithmetic.operator,
    value: `(${left.value} ${operator} ${right.value})`, // TODO implement arithmetic operator properly
  }
}

function processNumbernodeExpr(exp) {
  const { numbernode: { comment: { text = undefined } = {} } = {} } = exp
  const comment = typeof text === 'string' ? ` /* ${text} */` : ''
  return {
    operator: null,
    value: `${exp.numbernode.value}${comment}`,
  }
}

function processComparisonExpr(exp) {
  const ret: any = {}
  const left = JSONtoStringExpression(exp.comparison.left)
  const right = JSONtoStringExpression(exp.comparison.right)

  const operator = exp.comparison.operator === 'LT' ? '<' : '>'
  if (operator !== left.operator && left.operator) {
    left.value = `(${left.value})`
  }
  if (operator !== right.operator && right.operator) {
    right.value = `(${right.value})`
  }
  ret.operator = operator
  ret.value = `${left.value} ${operator} ${right.value}`
  return ret
}

import * as React from 'react'
import R from 'ramda'
import { translate } from 'react-i18next'
import { Field } from 'redux-form'

import { getAllNestedChildrenForProfile } from '../../opoint/profiles/index'

class ProfileParentSelectComponent extends React.PureComponent<any> {
  render() {
    const { profileId, profiles, i18n } = this.props
    const possibleProfileParents = R.differenceWith(R.eqProps('id'), profiles, [
      ...getAllNestedChildrenForProfile(profileId, profiles),
    ])
    // @ts-ignore
    const parentsWithoutCurrentProfile = possibleProfileParents.filter((profile) => profileId !== profile.id)

    return (
      <Field name="profileParent" component="select" className="op-select mod-profile-editor">
        <option value={0}>{i18n.t('None')}</option>
        {parentsWithoutCurrentProfile.map((profile) => (
          // @ts-ignore
          <option value={profile.id} key={profile.id}>
            {/*
            @ts-ignore */}
            {profile.name}
          </option>
        ))}
      </Field>
    )
  }
}

const ProfileParentSelect = translate([], { wait: true })(ProfileParentSelectComponent)

export default ProfileParentSelect

import React, { useState, useEffect, memo } from 'react'
import { connect } from 'react-redux'
import RMD from 'react-markdown'
import { useTranslation } from 'react-i18next-latest'

import Modal from '../common/Modal'
import buildActionCreators from '../../helpers/buildActionCreators'
import { getOpointLocale } from '../../selectors/settingsSelectors'
import version from '../../version.json'
import { getReleaseNotesModalOpen } from '../../selectors/uiSelectors'
import * as ActionTypes from '../../constants/actionTypes'
import { getCurrentM360Version } from '../../opoint/version/index'
import { javaLocaleToBaseLocaleId } from '../../opoint/common/constants'
import Loader from '../common/Loader'
import { wrapper } from './index.module.scss'

const { releaseDate, fullCurrentM360Version } = getCurrentM360Version()
const txToken = 'YXBpOjEvN2U3NjQ1MWMwMmJmMGUwMmFlMjAxZWNhOWE1ZDY5YjJiNzRiOTllZA=='
const txProjectUrl = 'https://www.transifex.com/api/2/project/m360-release-notes'

const fetchSource = (src: string) => {
  return fetch(src, {
    headers: {
      Authorization: `Basic ${txToken}`,
    },
  }).then((response) => response.json())
}

type Props = {
  closeModal: () => void
  isOpen: boolean
  locale: string
}

const ReleaseNotesModal = ({ locale, isOpen, closeModal }: Props) => {
  const [content, setContent] = useState<string | null>(null)
  const [history, setHistory] = useState<string[]>([])
  const [historyContent, setHistoryContent] = useState<Object>({})

  const { t } = useTranslation()

  const fetchNotes = async () => {
    const resources = await fetchSource(`${txProjectUrl}/resources`)
    const [current, ...history] = resources.map(({ slug }) => slug).reverse()
    const sourceUrl = `${txProjectUrl}/resource/${current}/translation/${javaLocaleToBaseLocaleId(locale)}/`
    const { content } = await fetchSource(sourceUrl)

    setContent(content)
    setHistory(history)
  }

  useEffect(() => {
    fetchNotes()
  }, [locale])

  const loadHistory = async (slug: string) => {
    if (!historyContent[slug]) {
      const sourceUrl = `${txProjectUrl}/resource/${slug}/translation/${javaLocaleToBaseLocaleId(locale)}/`
      const { content } = await fetchSource(sourceUrl)

      setHistoryContent((historyContent) => ({ ...historyContent, [slug]: content }))
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onHide={closeModal}
      title="What's new in M360"
      footer={version && <small className="version">{`${t('Version')}: ${fullCurrentM360Version}`}</small>}
    >
      <div className={wrapper}>
        <article>
          <h2>{`${releaseDate} ${t('release notes')}`}</h2>
          <RMD source={content} />
        </article>
        <hr />
        {history.map((slug) => {
          const date = slug.substring(6, 13).replace('-', '.')
          const markdown = historyContent[slug]

          return (
            <details key={date} onClick={() => loadHistory(slug)}>
              <summary>{date}</summary>
              {markdown ? <RMD source={markdown} /> : <Loader />}
            </details>
          )
        })}
      </div>
    </Modal>
  )
}

export default connect(
  (state) => ({
    locale: getOpointLocale(state),
    isOpen: getReleaseNotesModalOpen(state),
  }),
  buildActionCreators({
    closeModal: ActionTypes.RELEASE_NOTES_MODAL_HIDE,
  }),
)(memo(ReleaseNotesModal))

// @ts-nocheck
import React from 'react'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import { translate } from 'react-i18next'

import OpointDate from '../../opoint/common/time'
import KeywordTag from './keyword-tag'
import { tagStyle } from './common'
import { shouldDisplayTagWeight } from '../../opoint/tags/index'
import type { TagComponentProps } from '../../opoint/flow'

class AlertTag extends React.PureComponent<TagComponentProps> {
  onIncrementClickHandler = () => {
    const { article, tag, incrementTagWeightFn } = this.props
    incrementTagWeightFn(tag, article)
  }

  onSetClickHandler = () => {
    const { article, tag, setTagFn } = this.props
    setTagFn(tag, article)
  }

  render() {
    const { article, checked, tag, i18n, lastUsedTag } = this.props
    const timeSent: number = (article.tags[tag.id] && Number(article.tags[tag.id].sent)) || 0

    if (tag.children.length === 1) {
      return (
        <div className={`op-tag ${tagStyle(tag, article, checked, lastUsedTag)}`}>
          <span className="op-icon-alarm line-height-20" />
          &nbsp;
          <span
            className="op-tag__tag-name"
            onClick={this.onSetClickHandler}
            dangerouslySetInnerHTML={{ __html: tag.subject }}
          />
          {shouldDisplayTagWeight(article, tag.id) && (
            <span className="op-tag-button" onClick={this.onIncrementClickHandler}>
              {article && article.tags[tag.id] && article.tags[tag.id].weight}
            </span>
          )}
          {!!timeSent && (
            <span className="op-tag-button">
              <span
                className="op-icon-file"
                title={i18n.t('In report created {{date}}', { date: OpointDate.humanFormat(timeSent) })}
              />
            </span>
          )}
        </div>
      )
    }

    return (
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="top"
        container={this}
        containerPadding={20}
        overlay={
          <Popover className="op-tag-popover__wrapper" id={tag.id}>
            <ul className="op-tag-popover">
              {tag.children.map((innerTag) => (
                <li key={innerTag.id}>
                  <KeywordTag {...this.props} tag={innerTag} />
                </li>
              ))}
            </ul>
          </Popover>
        }
      >
        <div className="popover-tag-wrapper">
          <div className={`op-tag op-tag--popover ${tagStyle(tag, article, checked)}`}>
            <span className="op-icon-alarm line-height-20" />
            &nbsp;
            <span dangerouslySetInnerHTML={{ __html: tag.subject }} />
            <span className="op-tag-button">
              <span className="op-tag__click op-icon-hamburger" />
            </span>
            {!!timeSent && (
              <span className="op-tag-button">
                <span
                  className="op-icon-file"
                  title={i18n.t('In report created {{date}}', { date: OpointDate.humanFormat(timeSent) })}
                />
              </span>
            )}
          </div>
        </div>
      </OverlayTrigger>
    )
  }
}

AlertTag = translate([], { wait: true })(AlertTag)

export default AlertTag

import { Filter } from '../flow'

function normalizeName(name: string) {
  if (!name) {
    return ''
  }
  const limitedName = name.substring(0, 20)
  return limitedName !== name ? `\`${limitedName}...\`` : `\`${name}\``
}

/**
 * This function returns a placeholder based on provided filters
 * @returns {String} Relevant placeholder string
 */
export default function getPlaceholder(filtersInput: Filter[] = [], i18n) {
  const filters = filtersInput.filter((x) => x.type !== 'timePeriod') || []

  const onlyProfiles = filters.every((filter) => filter.type === 'profile')
  const onlyTags = filters.every((filter) => filter.type === 'tag')

  if (filters.length === 1 && (onlyProfiles || onlyTags)) {
    const [{ name, type }] = filters
    return `${i18n.t('Search within')} ${normalizeName(name)}${i18n.t(type)}`
  }
  if (filters.length > 1 && onlyProfiles) {
    return i18n.t('Search within selected profiles')
  }
  if (filters.length > 1 && onlyTags) {
    return i18n.t('Search within selected tags')
  }
  if (filters.length >= 1) {
    return i18n.t('Search within selected filters')
  }
  return i18n.t("Search directly among Opoint's 4 billion indexed articles")
}

import * as Rx from 'rxjs'
import { combineEpics } from 'redux-observable'

import alertsEpics from './alertsEpics'
import analyticsEpics from './analyticsEpics'
import articlesEpics from './articlesEpics'
import authEpics from './authEpics'
import buildAction from '../helpers/buildAction'
import contactsEpics from './contactsEpics'
import feedsEpics from './feedsEpics'
import notificationsEpics from './notificationsEpics'
import profilesEpics from './profilesEpics'
import reportsEpics from './reportsEpics'
import reportsHistoryEpics from './reportsHistoryEpics'
import searchEpics from './searchEpics'
import settingsEpics from './settingsEpics'
import statisticsEpics from './statisticsEpics'
import tagsEpics from './tagsEpics'
import templatesEpics from './templatesEpics'
import watchIndexEpics from './watchIndexEpics'
import * as ActionTypes from '../constants/actionTypes'

const epics = [
  ...alertsEpics,
  ...analyticsEpics,
  ...articlesEpics,
  ...authEpics,
  ...contactsEpics,
  ...feedsEpics,
  ...notificationsEpics,
  ...profilesEpics,
  ...reportsEpics,
  ...reportsHistoryEpics,
  ...searchEpics,
  ...settingsEpics,
  ...statisticsEpics,
  ...tagsEpics,
  ...templatesEpics,
  ...watchIndexEpics,
]

const configureEpics = (deps: Object, platformEpics: Array<any>) => (action$, { getState }) =>
  // @ts-ignore
  combineEpics(...epics, ...platformEpics)(action$, { ...deps, getState })

export const logOutOnExpiredToken = (val) =>
  val.status === 401
    ? Rx.Observable.of(buildAction(ActionTypes.LOGOUT), buildAction(ActionTypes.TOKEN_EXPIRED))
    : Rx.Observable.throw(val)

export const serverIsDown = (val) =>
  val.status === 0 || val.status >= 500
    ? Rx.Observable.of(buildAction(ActionTypes.SERVER_NOT_RESPONDING))
    : Rx.Observable.throw(val)

export default configureEpics

import React from 'react'

import Translate from '../../Translate'

const isCorrectListName = (listName) => listName === 'profiles' || listName === 'tags'

export default class FilterMessage extends React.Component<any, any> {
  numberOfImportant: number = 10

  toggleCategory = () => {
    const { toggleCategory, showImportantSetting } = this.props
    toggleCategory(showImportantSetting)
  }

  resetFilter = () => {
    this.props.resetFilter('')
  }

  render() {
    const { listLength, listName, filterExpression, numberOfFiltered, displayImportant } = this.props

    let filterMessage = <span />

    if (filterExpression && !!numberOfFiltered) {
      filterMessage = (
        <span onClick={this.resetFilter} className="filterMessageSpan">
          <Translate i18nString="+ {{numberOfFiltered}} hidden by filter" context={{ numberOfFiltered }} />
        </span>
      )
    }

    if (!filterExpression && isCorrectListName(listName) && listLength > this.numberOfImportant) {
      if (!displayImportant) {
        filterMessage = (
          <span className="filterMessageSpan" onClick={this.toggleCategory}>
            {listName === 'profiles' ? (
              <Translate i18nString="Show just most used profiles" />
            ) : (
              <Translate i18nString="Show just most used tags" />
            )}
          </span>
        )
      } else {
        filterMessage = (
          <span className="filterMessageSpan" onClick={this.toggleCategory}>
            {listName === 'profiles' ? (
              <Translate i18nString="Show all profiles" />
            ) : (
              <Translate i18nString="Show all tags" />
            )}
          </span>
        )
      }
    }

    if (!listLength && filterExpression && !numberOfFiltered) {
      filterMessage = <Translate i18nString="No {{listName}}" context={{ listName }} />
    }

    return <span>{filterMessage}</span>
  }
}

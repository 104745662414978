import React from 'react'

import Translate from '../../Translate'
import type { OrganizedCommonFilterDetails } from '../../opoint/flow'

type Props = {
  details: OrganizedCommonFilterDetails
}

const CommonFilterDetail = ({ details }: Props) => (
  <div>
    <div className="modal-body-legend">
      <Translate i18nString="Number of sites covered" />
    </div>
    <div className="modal-body-dialog op-modal-filter-details">
      {details.map(({ title, content }) => (
        <div key={title} className="dialog-column">
          <div className="dialog-column-title">
            <Translate i18nString={title} />
          </div>
          {content.map((subject) => (
            <div key={subject.subjectId} className="single-meta-data">
              {`${subject.subjectName}: `}
              <span className="metas-counts">{subject.subjectCount}</span>
            </div>
          ))}
        </div>
      ))}
    </div>
  </div>
)

export default CommonFilterDetail

// @ts-nocheck
import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, FieldArray } from 'redux-form'

import buildActionCreators from '../../helpers/buildActionCreators'
import ContactDeleteWarning from './ContactDeleteWarning'
import Translate from '../../Translate'
import { DatePickerField } from './InactiveContacts'
import { GroupsCheckboxes } from './GroupCheckForm'
import { getOpointLocale } from '../../selectors/settingsSelectors'
import {
  getActiveContactData,
  getActiveContactId,
  getContactDeleteWarning,
  getGroups,
  getPauseValue,
} from '../../selectors/contactSelectors'
import * as ActionTypes from '../../constants/actionTypes'
import type { Contact, EmptyContact, Group, LocaleKey } from '../../opoint/flow'

type ContactProps = {
  // TODO flow for functions
  activeContactId?: number
  contactDeleteWarning: string
  getLocale: LocaleKey
  groups: Array<Group>
  initialValues: Contact | EmptyContact
  initNewContact: Function
  onDeleteContact: Function
  pauseValue?: number
  removeActiveContact: Function
}

type ContactState = {
  isPauseCalendarVisible: boolean
}

class ContactEditor extends React.PureComponent<ContactProps, ContactState> {
  constructor(props) {
    super(props)
    this.state = {
      isPauseCalendarVisible: false,
    }
  }

  componentWillMount() {
    this.props.initNewContact()
  }

  componentWillUnmount() {
    this.props.removeActiveContact()
  }

  forceContactDelete = () => {
    this.props.onDeleteContact({ flag: true })
  }

  togglePauseCalendarVisibility = () => {
    this.setState({
      isPauseCalendarVisible: !this.state.isPauseCalendarVisible,
    })
  }

  formatInactiveDate = (pauseTime) => pauseTime.format('ddd DD MMM HH:mm YYYY')

  render() {
    const {
      activeContactId,
      contactDeleteWarning,
      getLocale,
      groups,
      pauseValue,
      onDeleteContact,
      resetInactiveContact,
    } = this.props
    const { isPauseCalendarVisible } = this.state
    const isActiveExistingContact = activeContactId !== null
    const pauseValueClass = pauseValue ? 'op-toggle-text-active' : 'op-toggle-text-inactive'

    return (
      <div className="col-md-12 mod-full-height">
        <div name="row mod-full-height">
          <div name="contactForm" id="contactForm" className="row">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12">
                  <div className="op-form-group">
                    <label htmlFor="firstName">
                      <Translate i18nString="First name" />
                    </label>
                    <Field id="firstName" tabIndex={1} name="firstName" component="input" type="text" required />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="op-form-group">
                    <label htmlFor="email">
                      <Translate i18nString="Email address" />
                    </label>
                    <Field name="email" id="email" tabIndex={3} component="input" type="email" required />
                  </div>
                </div>
              </div>
              {!!groups.length && (
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="selected-groups">
                      <Translate i18nString="Groups" />
                    </label>
                    <div className="op-checkbox-list-input">
                      <ul className="op-checkbox-list-input-list">
                        <FieldArray name="groups" component={GroupsCheckboxes} />
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-6">
              <div className="row">
                <div className="col-md-12">
                  <div className="op-form-group">
                    <label htmlFor="lastName">
                      <Translate i18nString="Last name" />
                    </label>
                    <Field name="lastName" id="lastName" component="input" type="text" tabIndex="2" required />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="op-form-group">
                    <label htmlFor="mobile">
                      <Translate i18nString="Phone number" />
                    </label>
                    <Field name="mobile" id="mobile" type="text" component="input" tabIndex="4" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="op-form-group">
                    <label htmlFor="pause">
                      <Translate i18nString="Alert pause" />
                      <span
                        className={`op-toggle-text ${pauseValueClass}`}
                        onClick={this.togglePauseCalendarVisibility}
                      >
                        {pauseValue ? (
                          <div>
                            <Translate i18nString="Paused until" />
                            <span>
                              {': '}
                              {this.formatInactiveDate(pauseValue)}
                            </span>
                          </div>
                        ) : (
                          <div>
                            <Translate i18nString="Alerts mails are active" />
                          </div>
                        )}
                      </span>
                    </label>
                    {isPauseCalendarVisible && (
                      <div>
                        <Field name="pause" component={DatePickerField} locale={getLocale} />
                        {pauseValue && (
                          <div className="row">
                            <div className="op-modal-contacts-reset-button">
                              <button type="button" className="op-button simple-red" onClick={resetInactiveContact}>
                                <Translate i18nString="Reset alert pause" />
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 mod-to-bottom">
              <div className="row">
                <div className="col-md-12  util_col-vertical-center">
                  <div className="row">
                    <div className="op-modal-contacts-right-button-wrapper">
                      <button className="op-button simple-blue" type="submit" id="submit">
                        <Translate i18nString="Save" />
                      </button>
                      {!contactDeleteWarning && isActiveExistingContact && (
                        <button
                          type="button"
                          className="op-button simple-red"
                          data-test="contact-delete"
                          onClick={onDeleteContact}
                          tabIndex="8"
                        >
                          <Translate i18nString="Delete" />
                        </button>
                      )}
                      {contactDeleteWarning && (
                        <button
                          type="button"
                          className="op-button simple-red"
                          data-test="contact-delete"
                          onClick={this.forceContactDelete}
                          tabIndex="8"
                        >
                          <Translate i18nString="Yes, I am sure" />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {contactDeleteWarning && <ContactDeleteWarning contactDeleteWarning={contactDeleteWarning} />}
      </div>
    )
  }
}

const ConnectedContactEditorForm = reduxForm({
  form: 'contactEditor',
  enableReinitialize: true,
})(ContactEditor)

const ConnectedContactEditor = connect(
  (state) => ({
    activeContactId: getActiveContactId(state),
    contactDeleteWarning: getContactDeleteWarning(state),
    getLocale: getOpointLocale(state),
    groups: getGroups(state),
    initialValues: getActiveContactData(state),
    pauseValue: getPauseValue(state),
  }),
  buildActionCreators({
    initNewContact: ActionTypes.CONTACT_INIT_NEW,
    onDeleteContact: ActionTypes.CONTACT_DELETE_CONFIRM,
    removeActiveContact: ActionTypes.CONTACT_REMOVE_ACTIVE,
    resetInactiveContact: ActionTypes.CONTACT_INACTIVE_RESET,
  }),
)(ConnectedContactEditorForm)

export default ConnectedContactEditor

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import buildActionCreators from '../../helpers/buildActionCreators'
import SearchInput from '../Search'
import NewArticlesBalloon from '../articles/NewArticlesBalloon'
import { getFiltersPanelOpen, getUserLoggedOut } from '../../selectors/uiSelectors'
import { getShowFilteredArticles } from '../../selectors/statisticsSelectors'
import { filterIsInverted, getUnmatchedString, isSearchInputNotEmpty } from '../../opoint/search/index'
import {
  getMainSearchLineWithTimePeriod,
  getSearchFilters,
  getSearchterm,
  getSuggestions,
} from '../../selectors/searchSelectors'
import * as ActionTypes from '../../constants/actionTypes'

class Search extends React.Component<any, any> {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = { searchterm: props.searchterm }
  }

  // Checks whether searchterm wasn't changed outside of the component
  // (user clicks profile or whatever) bit hackish but
  // there is probably little we can do about it - this whole component needs some love
  componentWillReceiveProps(nextProps) {
    if (nextProps.searchterm !== this.props.searchterm || (nextProps.searchterm === '' && this.props.searchterm)) {
      this.setState({
        searchterm: nextProps.searchterm,
      })
    }
  }

  onSearchTriggered = () => {
    const { onSearchTriggered, searchData, searchFilters } = this.props
    const { searchterm } = this.state
    if (isSearchInputNotEmpty({ filters: searchFilters, searchterm })) {
      const {
        router: {
          location: { pathname },
        },
      } = this.context
      const timestamp = Date.now()
      onSearchTriggered({ searchline: searchData, pathname, timestamp })
    }
  }

  // TODO discuss with @Jany then delete & replace with EmptyFunction
  onLoadSuggestions = () => undefined

  onFocusHandler = () => {
    this.props.onSearchTermChanged({ searchterm: this.state.searchterm })
  }

  onSearchFilterAdded = (filter) => {
    this.props.onSearchFilterAdded(filter)
    this.setState({
      searchterm: getUnmatchedString(filter, this.state.searchterm),
    })
  }

  onSearchFilterToggled = (filter) => {
    // debugger
    this.props.onSearchFilterToggled(filter)
    this.setState({
      searchterm: getUnmatchedString(filter, this.state.searchterm),
    })
  }

  handleInputChange = (searchterm) => {
    this.setState({
      searchterm,
    })
    this.props.onSearchTermChanged({ searchterm })

    this.props.updateSearchTerm({ searchterm })
  }

  clearSearchData = () => {
    this.handleInputChange('')
    this.props.clearSearchData()
  }

  excludeHandler = (filter) => {
    const { id, invertFilter } = this.props

    !filterIsInverted(filter) && invertFilter({ id, filter })
  }

  includeHandler = (filter) => {
    const { id, invertFilter } = this.props

    filterIsInverted(filter) && invertFilter({ id, filter })
  }

  render() {
    const {
      showInlineSuggestions,
      showFilteredArticles,
      userLoggedOut,
      suggestions,
      searchFilters,
      onSearchFilterRemoved,
      withoutNotificationBaloon,
      fetchArticles,
      justFetchArticles,
    } = this.props
    const { searchterm } = this.state

    const searchTrigger = justFetchArticles ? fetchArticles : this.onSearchTriggered

    return (
      <div id="mod-spy-height" className="row  util_col-centered">
        <div className="col-md-12  op-content-top-header-rest-searchbar">
          <div className="row">
            <div className="op-search-input_wrapper">
              <div className="search-input">
                <SearchInput
                  clearSearchData={this.clearSearchData}
                  excludeHandler={this.excludeHandler}
                  handleInputChange={this.handleInputChange}
                  includeHandler={this.includeHandler}
                  onFocusHandler={this.onFocusHandler}
                  onLoadSuggestions={this.onLoadSuggestions}
                  onSearchFilterAdded={this.onSearchFilterAdded}
                  onSearchFilterRemoved={onSearchFilterRemoved}
                  onSearchFilterToggled={this.onSearchFilterToggled}
                  onSearchTriggered={searchTrigger}
                  searchFilters={searchFilters}
                  searchterm={searchterm}
                  showInlineSuggestions={showInlineSuggestions}
                  suggestions={suggestions}
                />
              </div>

              <div className="op-search-input-button">
                {!showFilteredArticles && !userLoggedOut && !withoutNotificationBaloon && (
                  <div className="op-search-new-articles-notification">
                    <NewArticlesBalloon />
                  </div>
                )}
                <button className="op-search-input-button__interactive" onClick={searchTrigger}>
                  <i className="op-icon-search op-search-input-button__interactive__icon" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

// @ts-ignore
Search = connect(
  (state, props) => ({
    searchData: getMainSearchLineWithTimePeriod(state),
    searchFilters: getSearchFilters(state),
    suggestions: getSuggestions(state),
    searchterm: getSearchterm(state),
    showInlineSuggestions: !getFiltersPanelOpen(state),
    showFilteredArticles: getShowFilteredArticles(state),
    userLoggedOut: getUserLoggedOut(state),
  }),
  buildActionCreators({
    updateSearchTerm: ActionTypes.UPDATE_SEARCHTERM,
    onSearchTermChanged: ActionTypes.SEARCHTERM_CHANGED,
    onSearchTriggered: ActionTypes.SEARCH,
    fetchArticles: ActionTypes.FETCH_ARTICLES,
    clearSearchData: ActionTypes.SEARCHDATA_CLEAR,
    onSearchFilterAdded: ActionTypes.SEARCHFILTER_ADDED,
    onSearchFilterToggled: ActionTypes.SEARCHFILTER_TOGGLED,
    onSearchFilterRemoved: ActionTypes.SEARCHFILTER_REMOVED,
    invertFilter: ActionTypes.INVERT_FILTER,
  }),
)(Search)

export default Search

// @ts-nocheck
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import SAlert from 'react-s-alert'
import { browserHistory, Route, Router } from 'react-router'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import { render } from 'react-dom'
import { syncHistoryWithStore } from 'react-router-redux'
import Modal from 'react-modal'

import OpointConfig from './opoint/common/config'
// import * as registerServiceWorker from './registerServiceWorker'
import GetStringProvider from './getStringProvider'
import i18next from './i18nextInit'
import App from './components/App'
import Articles from './components/Articles'
import ArticleListing from './components/ArticleListing'
import FeedEditor from './components/feeds/FeedEditor'
import ProfileEditor from './components/profiles/ProfileEditor'
import * as ActionTypes from './constants/actionTypes'
import buildAction from './helpers/buildAction'
import AddAlert from './components/alerts/AddAlert'
import Alert from './components/alerts/NextAlert/NextAlertContainer'
import AlertHistory from './components/alerts/NextAlert/AlertHistory'
import EditAlert from './components/alerts/EditAlert'
import StatisticsSidebar from './components/statistics/Sidebar'
import StatisticsWrapper from './components/statistics/Statistics'
import StatisticView from './components/statistics/StatisticsViews/StatisticView'
import TimeScheduleAddAlert from './components/alerts/TimeSchedule/TimeScheduleAddAlert'
import TimeScheduleEditAlert from './components/alerts/TimeSchedule/TimeScheduleEditAlert'
import { decodeToken, getServerTime, setServerTime } from './opoint/auth/index'
import { checkM360Version } from './opoint/version/index'
import Login from './components/auth/Login'
import requireAuthentication from './components/auth/RequireAuthentication'
import store from './store'
import './app.css'
import 'react-s-alert/dist/s-alert-default.css'
import './ga'
// window.Perf = require('react-addons-perf')
import 'moment/locale/en-gb' /* eslint-disable-line import/first */
import 'moment/locale/nn' /* eslint-disable-line import/first */
import 'moment/locale/nb' /* eslint-disable-line import/first */
import 'moment/locale/da' /* eslint-disable-line import/first */
import 'moment/locale/sv' /* eslint-disable-line import/first */
import 'moment/locale/et' /* eslint-disable-line import/first */
import 'moment/locale/fi' /* eslint-disable-line import/first */
import version from './version.json'

Modal.setAppElement('#root')

global.version = version

const history = syncHistoryWithStore(browserHistory, store)

store.dispatch(buildAction(ActionTypes.USERS_LOGIN_INFO))

async function initialGetServertime() {
  const serverTime = await getServerTime()
  await setServerTime(serverTime)
  store.dispatch(buildAction(ActionTypes.GET_SERVER_TIME))
}

const oReq = new XMLHttpRequest()
oReq.addEventListener('load', async () => {
  await initialGetServertime()

  let response
  try {
    response = JSON.parse(this.responseText)
  } catch (e) {
    response = { isoCode: 'en-GB' }
  }
  store.dispatch(buildAction(ActionTypes.COUNTRY_CODE_FETCH_SUCCESS, response))

  bootstrapApp()
})
oReq.addEventListener('error', getServerTimeAndBootstrap)
oReq.addEventListener('timeout', getServerTimeAndBootstrap)
oReq.open('GET', OpointConfig.getUrl('/api/users/location/?format=json'))
oReq.timeout = 400
oReq.send()

async function getServerTimeAndBootstrap() {
  await initialGetServertime()
  bootstrapApp()
}

window.addEventListener('storage', (event) => {
  const { key } = event

  if (key === 'ls.OP-PORTAL-AUTH-TOKEN') {
    const { newValue, oldValue } = event
    let newUser = null
    let oldUser = null

    try {
      newUser = newValue === null ? null : decodeToken(newValue)
    } catch (ex) {
      console.log(ex)
    }
    try {
      oldUser = oldValue === null ? null : decodeToken(oldValue)
    } catch (ex) {
      console.log(ex)
    }

    // when changing this, please test IE11 properly
    store.dispatch(buildAction(ActionTypes.USER_TOKEN_CHANGED, { newUser, oldUser }))
  }
})

const onVersionUpdate = () => {
  store.dispatch(buildAction(ActionTypes.SHOW_NEW_VERSION_NOTIFICATION))
}

function bootstrapApp() {
  checkM360Version(onVersionUpdate)

  render(
    <Provider store={store}>
      <GetStringProvider>
        <I18nextProvider i18n={i18next}>
          <Router history={history}>
            <Route path="/login" component={Login} />
            <Route path="/" component={requireAuthentication(App)}>
              <Route path="search" components={{ main: Articles, rightColumn: ArticleListing }}>
                <Route path="new" component={ProfileEditor} />
                <Route path="edit" component={ProfileEditor} />
              </Route>
              <Route path="statistics" components={{ main: StatisticsWrapper, rightColumn: StatisticsSidebar }}>
                <Route path="edit" component={ProfileEditor} />
              </Route>
              <Route
                path="statistics(/:statisticViewId)"
                components={{ main: StatisticView, rightColumn: StatisticsSidebar }}
              />
              <Route path="feeds(/:feedId)" components={{ main: FeedEditor }} />
              <Route path="alerts/new" components={{ main: AddAlert, rightColumn: TimeScheduleAddAlert }} />
              <Route path="alerts/:alertId" components={{ main: Alert, rightColumn: AlertHistory }} />
              <Route path="alerts/:alertId/edit" components={{ main: EditAlert, rightColumn: TimeScheduleEditAlert }} />
            </Route>
          </Router>
        </I18nextProvider>
      </GetStringProvider>
    </Provider>,
    document.getElementById('root'),
  )

  store.dispatch(buildAction(ActionTypes.BOOTSTRAP))

  render(
    <div style={{ zIndex: 9999, position: 'relative' }}>
      <SAlert stack={{ limit: 4 }} offset={20} />
    </div>,
    document.getElementById('alert'),
  )
}

// registerServiceWorker.register()

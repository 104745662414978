import React from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'

import AddArticleForm from './Form'
import buildActionCreators from '../../../helpers/buildActionCreators'
import Translate from '../../../Translate'
import * as ActionTypes from '../../../constants/actionTypes'
import LeaveEditedModal from './LeaveEditedModal'
import { getAddArticleModalOpen } from '../../../selectors/uiSelectors'

type AddArticleModalProps = {
  isOpen: boolean
  addArticleModalClose: () => void
}

type State = {
  changed: boolean
  isLeaveModalOpen: boolean
}

class AddArticleModal extends React.Component<AddArticleModalProps, State> {
  state = {
    changed: false,
    isLeaveModalOpen: false,
  }

  setIsFormChanged = (isIt) => {
    this.setState({ changed: isIt })
  }

  closeHandler = () => {
    if (!this.state.changed) {
      this.props.addArticleModalClose()
    } else {
      this.setState({ isLeaveModalOpen: true })
    }
  }

  toggleLeaveEditedModal = () => this.setState(({ isLeaveModalOpen }) => ({ isLeaveModalOpen: !isLeaveModalOpen }))

  discardChangesAndCloseModal = () => {
    this.toggleLeaveEditedModal()
    this.props.addArticleModalClose()
  }

  render() {
    return (
      <Modal show={this.props.isOpen} onHide={this.closeHandler} className="op-modal-add-article" keyboard={false}>
        <Modal.Header>
          <button type="button" className="close" onClick={this.closeHandler}>
            ×
          </button>
          <h4 className="modal-title">
            <Translate i18nString="Upload Content to M360" />
          </h4>
        </Modal.Header>
        <Modal.Body>
          <AddArticleForm {...this.props} setIsFormChanged={this.setIsFormChanged} />
        </Modal.Body>
        <LeaveEditedModal
          isOpen={this.state.isLeaveModalOpen}
          discardAndClose={this.discardChangesAndCloseModal}
          closeModal={this.toggleLeaveEditedModal}
        />
      </Modal>
    )
  }
}

export default connect(
  (state) => ({
    isOpen: getAddArticleModalOpen(state),
  }),
  buildActionCreators({
    addArticleModalClose: ActionTypes.ADD_ARTICLE_MODAL_CLOSE,
  }),
  // @ts-ignore
)(AddArticleModal)

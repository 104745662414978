import React from 'react'
import PropTypes from 'prop-types'

import ArticleSummary from './ArticleSummary'

const ArticleSimpleBodyComponent = (props) => (
  <div className="op-content-article-listing-article-content-text">
    <ArticleSummary {...props} />
  </div>
)

ArticleSimpleBodyComponent.propTypes = {
  editable: PropTypes.bool,
}

ArticleSimpleBodyComponent.defaultProps = {
  editable: false,
}

export default ArticleSimpleBodyComponent

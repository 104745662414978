// @ts-nocheck
import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'

import OpointDate from '../../opoint/common/time'
import { getRelativeOffset } from '../../selectors/settingsSelectors'
import { TYPE_PREVIEW } from '../../opoint/articles/index'

type SmartDateProps = {
  timestamp: number
  baseDate: number
  dateFormat: string
  relativeOffset: number
  relative: boolean
  type: string
  showSign: boolean
}

class SmartDate extends React.PureComponent<SmartDateProps> {
  componentWillMount() {
    OpointDate.setCustomRelativeTimeOffset(this.props.relativeOffset)
  }

  componentWillUpdate(nextProps) {
    if (nextProps.relativeOffset !== this.props.relativeOffset) {
      OpointDate.setCustomRelativeTimeOffset(nextProps.relativeOffset)
    }
  }

  relativeDate = (baseDate, relativeDate, type, showSign) => {
    const base = baseDate ? moment(baseDate) : moment()
    const relative = moment(relativeDate)

    if (base.isSame(relative)) {
      // TODO: translate
      return type === TYPE_PREVIEW ? '+ 0 seconds' : '+0s'
    }

    const milisecondsDiff = base.diff(relative)
    const seconds = Math.abs(moment.duration(milisecondsDiff).asSeconds())
    let sign
    if (showSign) {
      sign = milisecondsDiff < 0 ? '+' : '-'
    } else {
      sign = ''
    }
    const duration = moment.duration(milisecondsDiff).humanize()

    // And then we rape moment.duration. I'm sorry.
    let number = duration.match(/\d+/) // if we didn't found any number - number is probably 1
    number = number ? number[0] : 1

    let unit = duration.split(' ').slice(-1)[0]
    // in moment duration is implmentation "a few seconds", so for this...
    if (seconds <= 45) {
      number = seconds
      // TODO: translation
      unit = 'seconds'
    }

    if (type === TYPE_PREVIEW) {
      // when sign is not displayed it means time belong to active article
      // in that case preview type has special formating
      if (!showSign) {
        return OpointDate.format(relativeDate, 'LONG')
      }

      // In preview we want long version.
      return `${sign} ${number} ${unit}`
    }

    // In listing we want short duration like -1s, +2d...
    return sign + number + unit[0].toLowerCase()
  }

  render() {
    const { timestamp, dateFormat = 'VERY_SHORT', relative, baseDate, type, showSign, relativeOffset } = this.props
    // If user doesn't want relative time, just show LONG format every time.
    if (!relativeOffset) {
      return <span>{OpointDate.format(timestamp, 'LONG')}</span>
    }
    // Display relative time based on relative time offset settings or when in identical
    // articles list
    if (OpointDate.isRelative(timestamp, relative) || baseDate) {
      return <span>{this.relativeDate(baseDate, timestamp, type, showSign)}</span>
    }

    const format = type === TYPE_PREVIEW ? 'LONG' : dateFormat

    return <span>{OpointDate.format(timestamp, format)}</span>
  }
}

export default connect((state) => ({
  relativeOffset: getRelativeOffset(state),
}))(SmartDate)

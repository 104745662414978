// @ts-nocheck

import Rx from 'rxjs'
import R from 'ramda'

import * as ActionTypes from '../constants/actionTypes'
import buildAction from '../helpers/buildAction'
import {
  getActiveContactData,
  getContactFormData,
  getEditedGroup,
  getGroupFormData,
} from '../selectors/contactSelectors'
import {
  saveContact,
  getContacts,
  deleteContact,
  getContactDetail,
  getGroupDetail,
  saveGroup,
  getGroups,
  deleteGroup,
  replaceCheckedGroupsWithId,
} from '../opoint/contacts'
import { getOpointLocale } from '../selectors/settingsSelectors'
import { logOutOnExpiredToken, serverIsDown } from './configureEpics'

const fetchContactsOnLogIn = (action$: any) =>
  action$.ofType(ActionTypes.LOG_IN_SUCCESS).mapTo(buildAction(ActionTypes.CONTACTS_FETCH))

const fetchGroupsOnLogIn = (action$: any) =>
  action$.ofType(ActionTypes.LOG_IN_SUCCESS).mapTo(buildAction(ActionTypes.GROUPS_FETCH))

const fetchContacts$ = Rx.Observable.defer(getContacts)
  .map((contacts) => buildAction(ActionTypes.CONTACTS_FETCH_SUCCESS, contacts))
  .catch(logOutOnExpiredToken)
  .catch(serverIsDown)
  .catch(() => Rx.Observable.of(buildAction(ActionTypes.CONTACTS_FETCH_FAILURE)))

export const fetchContacts = (action$: any) =>
  action$.ofType(ActionTypes.CONTACTS_FETCH).switchMap(() =>
    Rx.Observable.from(getContacts())
      .map((contacts) => buildAction(ActionTypes.CONTACTS_FETCH_SUCCESS, contacts))
      .catch((err) =>
        err.status === 401
          ? Rx.Observable.of(buildAction(ActionTypes.LOGOUT))
          : Rx.Observable.of(buildAction(ActionTypes.CONTACTS_FETCH_FAILURE)),
      ),
  )

export const fetchGroups = (action$: any) =>
  action$.ofType(ActionTypes.GROUPS_FETCH).switchMap(() =>
    Rx.Observable.from(getGroups())
      .map((groups) => buildAction(ActionTypes.GROUPS_FETCH_SUCCESS, groups))
      .catch((err) =>
        err.status === 401
          ? Rx.Observable.of(buildAction(ActionTypes.LOGOUT))
          : Rx.Observable.of(buildAction(ActionTypes.GROUPS_FETCH_FAILURE)),
      ),
  )

const saveContactsEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.CONTACT_SAVE).switchMap(() => {
    const contactFormData = getContactFormData(getState())
    // because backend takes only ids in group list
    const contactData = replaceCheckedGroupsWithId(contactFormData)
    const editedContactId = contactFormData && contactFormData.id

    const saveContact$ = Rx.Observable.fromPromise(saveContact(editedContactId, contactData)).map((contact) =>
      buildAction(ActionTypes.CONTACT_SAVE_SUCCESS, { contact }),
    )

    return saveContact$
      .switchMap((saveContactAction) =>
        Rx.Observable.concat(
          Rx.Observable.of(saveContactAction),
          fetchContacts$,
          Rx.Observable.of(buildAction(ActionTypes.CONTACT_DETAIL_FETCH_SUCCESS, contactFormData)),
          Rx.Observable.of(buildAction(ActionTypes.CONTACT_SET_ACTIVE, saveContactAction.payload.contact)),
        ),
      )
      .catch((e) => {
        let error
        try {
          error = {
            responseErrors: JSON.parse(e.originalEvent.target.response),
          }
        } catch (innerError) {
          error = {
            message: 'We were unable to save this contact',
          }
        }
        return Rx.Observable.of(buildAction(ActionTypes.CONTACT_SAVE_ERROR, error))
      })
  })

const inactiveContactEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.CONTACT_INACTIVE_RESET).switchMap(() => {
    const activeContactData = getActiveContactData(getState())
    activeContactData.pause = null // reset pause value
    const contactId = activeContactData && activeContactData.id
    // because backend takes only ids in group list
    const contactData = replaceCheckedGroupsWithId(activeContactData)

    const resetInactiveContact$ = Rx.Observable.fromPromise(saveContact(contactId, contactData)).map((contact) =>
      buildAction(ActionTypes.CONTACT_INACTIVE_RESET_SUCCESS, { contact }),
    )

    return resetInactiveContact$
      .switchMap((resetInactiveContactAction) =>
        Rx.Observable.concat(
          Rx.Observable.of(resetInactiveContactAction),
          fetchContacts$,
          Rx.Observable.of(buildAction(ActionTypes.CONTACT_DETAIL_FETCH_SUCCESS, activeContactData)),
          Rx.Observable.of(buildAction(ActionTypes.CONTACT_SET_ACTIVE, resetInactiveContactAction.payload.contact)),
        ),
      )
      .catch(() => {
        const error = {
          message: 'We were unable to reset pause of alerts for this contact',
        }
        return Rx.Observable.of(buildAction(ActionTypes.CONTACT_INACTIVE_RESET_ERROR, error))
      })
  })

const deleteContactEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.CONTACT_DELETE_CONFIRM).switchMap(({ payload }: any) => {
    const state = getState()
    const editedContact = getContactFormData(state)
    const editedContactId = editedContact.id
    const { flag }: boolean = payload || {}
    const deleteContact$ = Rx.Observable.fromPromise(deleteContact(editedContactId, getOpointLocale(state), flag))
      .map(() => buildAction(ActionTypes.CONTACT_DELETE_SUCCESS, editedContactId))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch((e) => {
        let message
        try {
          message = JSON.parse(e.originalEvent.target.response)
        } catch (innerError) {
          message = {
            message: 'We were unable to delete this contact',
          }
        }
        return message.data && message.data.alerts.length !== 0
          ? Rx.Observable.of(buildAction(ActionTypes.CONTACT_USED_DELETE_ERROR, message))
          : Rx.Observable.of(buildAction(ActionTypes.CONTACT_DELETE_ERROR, message))
      })

    return deleteContact$.switchMap((deleteContactAction) =>
      Rx.Observable.concat(Rx.Observable.of(deleteContactAction), fetchContacts$),
    )
  })

const setActiveContactEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.CONTACT_SET_ACTIVE).switchMap(({ payload: { id } }) => {
    const fetchContact$ = Rx.Observable.fromPromise(getContactDetail(id))

    return fetchContact$
      .switchMap((contact) =>
        Rx.Observable.concat(
          Rx.Observable.of(buildAction(ActionTypes.CONTACT_DETAIL_FETCH_SUCCESS, contact)),
          Rx.Observable.of(buildAction(ActionTypes.CONTACT_SET_ACTIVE_SUCCESS, contact)),
        ),
      )
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.CONTACT_SET_ACTIVE_FAILURE)))
  })

const setActiveGroupEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.GROUP_SET_ACTIVE).switchMap(({ payload: { id } }) => {
    const fetchGroup$ = Rx.Observable.fromPromise(getGroupDetail(id))

    return fetchGroup$
      .switchMap((group) =>
        Rx.Observable.concat(
          Rx.Observable.of(buildAction(ActionTypes.GROUP_DETAIL_FETCH_SUCCESS, group)),
          Rx.Observable.of(buildAction(ActionTypes.GROUP_SET_ACTIVE_SUCCESS, group)),
        ),
      )
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.GROUP_SET_ACTIVE_FAILURE)))
  })

const saveGroupEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.GROUP_SAVE).switchMap(() => {
    const editedGroup = getEditedGroup(getState())
    const mergedData = R.mergeAll([editedGroup, getGroupFormData(getState())])
    const data = R.over(R.lensPath(['contacts']), R.compose(Object.values, R.pluck('id')), mergedData)
    const editedGroupId = editedGroup && editedGroup.id
    const fetchGroups$ = Rx.Observable.defer(getGroups)
      .map((groups) => buildAction(ActionTypes.GROUPS_FETCH_SUCCESS, groups))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.GROUPS_FETCH_FAILURE)))

    const saveGroup$ = Rx.Observable.fromPromise(saveGroup(editedGroupId, data)).map((group) =>
      buildAction(ActionTypes.GROUP_SAVE_SUCCESS, { group }),
    )

    return saveGroup$
      .switchMap((saveGroupAction) =>
        Rx.Observable.concat(
          Rx.Observable.of(saveGroupAction),
          fetchGroups$,
          Rx.Observable.of(buildAction(ActionTypes.GROUP_DETAIL_FETCH_SUCCESS, mergedData)),
          Rx.Observable.of(buildAction(ActionTypes.GROUP_SET_ACTIVE, saveGroupAction.payload.group)),
        ),
      )
      .catch((e) => {
        let error
        try {
          error = {
            responseErrors: JSON.parse(e.originalEvent.target.response),
          }
        } catch (innerError) {
          error = {
            message: 'We were unable to save this group',
          }
        }
        return Rx.Observable.of(buildAction(ActionTypes.GROUP_SAVE_ERROR, error))
      })
  })

const deleteGroupEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.GROUP_DELETE_CONFIRM).switchMap(() => {
    const editedGroup = getEditedGroup(getState())
    const editedGroupId = editedGroup && editedGroup.id
    const fetchGroups$ = Rx.Observable.defer(getGroups)
      .map((groups) => buildAction(ActionTypes.GROUPS_FETCH_SUCCESS, groups))
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.GROUPS_FETCH_FAILURE)))

    const deleteGroup$ = Rx.Observable.fromPromise(deleteGroup(editedGroupId, getOpointLocale(getState())))
      .map(() => buildAction(ActionTypes.GROUP_DELETE_SUCCESS, editedGroupId))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch((e) => {
        let message
        try {
          message = JSON.parse(e.originalEvent.target.response)
        } catch (innerError) {
          message = {
            message: 'We were unable to delete this group',
          }
        }
        return Rx.Observable.of(buildAction(ActionTypes.GROUP_DELETE_ERROR, message))
      })

    return deleteGroup$.switchMap((deleteGroupAction) =>
      Rx.Observable.concat(Rx.Observable.of(deleteGroupAction), fetchGroups$),
    )
  })

export default [
  deleteContactEpic,
  deleteGroupEpic,
  fetchContacts,
  fetchContactsOnLogIn,
  fetchGroups,
  fetchGroupsOnLogIn,
  inactiveContactEpic,
  saveContactsEpic,
  saveGroupEpic,
  setActiveContactEpic,
  setActiveGroupEpic,
]

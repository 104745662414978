// @ts-nocheck
import React from 'react'
import R from 'ramda'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { FiltersPanel } from '../../new-components/filters/FilterPanel'
import { filterId } from '../../opoint/search/index'
import buildActionCreators from '../../helpers/buildActionCreators'
import * as ActionTypes from '../../constants/actionTypes'
import { getFiltersOrder } from '../../selectors/settingsSelectors'
import { getProfilesWithType, getSearchDataForSearchline, getFiltersShowMore } from '../../selectors/profilesSelectors'
import { getTagsWithType } from '../../selectors/tagsSelectors'
import styles from '../../new-components/filters/index.module.scss'
import BackButton from '../../new-components/filters/BackButton'

const FiltersWrapper = (props: any) => {
  const hasFiltersMore = props.filtersShowMore.length > 0
  const backButtonTitle = props.filtersShowMore.map((item) => item.header).join(' > ')

  const { profiles, profileEditorSearchterm } = props

  return (
    <div className={classNames('op-filters', styles.profileEditor)} id="op-app-filters">
      <div className={classNames(styles.filterPanel, { [styles.topPadding]: hasFiltersMore })}>
        {hasFiltersMore && (
          <BackButton
            onClick={(e) => {
              e.preventDefault()
              props.filtersPop()
            }}
            title={backButtonTitle}
          />
        )}
        <FiltersPanel isMainSearch profiles={profiles} searchterm={profileEditorSearchterm} {...props} />
      </div>
    </div>
  )
}

export default connect(
  (state: any) => {
    const activeLine = state.profiles.activeProfileEditorLine
    const searchline = getSearchDataForSearchline(activeLine)(state)
    return {
      suggestions:
        (state.profiles.profileEditorSuggestions && state.profiles.profileEditorSuggestions[activeLine]) || {},
      searchFilters: R.indexBy(
        filterId,
        R.view(
          R.compose(
            R.lensPath(['profiles', 'editedProfile', 'items']),
            R.lensIndex(activeLine),
            R.lensPath(['searchline', 'filters']),
          ),
          state,
        ) || [],
      ),
      filtersOrder: getFiltersOrder(state),
      profiles: getProfilesWithType(state),
      tags: getTagsWithType(state),
      profileEditorSearchterm: searchline ? searchline.searchterm : '',
      filtersShowMore: getFiltersShowMore(state),
    }
  },
  buildActionCreators({
    onSearchFilterToggled: ActionTypes.PROFILE_EDITOR_SEARCHFILTER_TOGGLE,
    onFiltersReorder: ActionTypes.FILTERS_REORDER,
    //filters more
    fetchNewFilters: ActionTypes.FILTERS_FETCH_MULTIPLE_SUCCESS,
    fetchShowMoreFilters: ActionTypes.PROFILE_EDITOR_FILTERS_FETCH_MULTIPLE_OF_TYPE_SUCCESS,
    filtersPop: ActionTypes.PROFILE_EDITOR_FILTERS_POP,
    fetchMoreFilters: ActionTypes.FETCH_PROFILE_EDITOR_MORE_FILTERS,
  }),
  // @ts-ignore
)(FiltersWrapper)

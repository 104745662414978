import React from 'react'

import { convertRuleToStringExpressions } from './index'
import type { TBList } from '../../opoint/flow'

type Props = {
  tbList: TBList
}

const TBListFilterDetail = ({ tbList }: Props) => (
  <div>
    {tbList.rules.map((rule) => (
      <div key={rule.id_rule}>
        <h4>{rule.header}</h4>
        <div className="op-modal-tblist-expressions">
          <div className="op-modal-tblist-expressions-header">
            <div className="op-modal-tblist-width">{rule.startvalue}</div>
          </div>
          {convertRuleToStringExpressions(rule.expression).map((exp, key) => (
            <div className="op-modal-tblist-expression" key={key}>
              <div className="op-modal-tblist-width">{exp.weight}</div>
              <div className="op-modal-tblist-expression-value" dangerouslySetInnerHTML={{ __html: exp.value }} />
            </div>
          ))}
        </div>
      </div>
    ))}
  </div>
)

export default TBListFilterDetail

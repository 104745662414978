import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import AlertForm from './AlertForm/AlertForm'
import * as ActionTypes from '../../constants/actionTypes'
import buildActionCreators from '../../helpers/buildActionCreators'
import {
  getOpointLocale,
  isAutoTranslationsOn,
  getAutoTranslationLanguageName,
  getMaxAlertRecipients,
} from '../../selectors/settingsSelectors'
import { getAddFormTranslate } from '../../selectors/alertsSelectors'
import { getRecipientsCount } from '../../selectors/contactSelectors'
import { getDefaultTimezone, getDefaultLanguage } from '../../opoint/common/constants'

function mapStateToProps(state) {
  const locale = getOpointLocale(state)
  return {
    initialValues: {
      active: 1,
      editable: 0,
      template: {
        url: 'https://m360-dev.opoint.com/api/templates/11/',
        id: 11,
        name: 'Compact HTML report',
        type: 3,
        readonly: true,
      },
      recipients: [],
      language: getDefaultLanguage(locale),
      timeZone: getDefaultTimezone(locale),
      smsRecipients: [],
      time: 1,
      translate: false,
    },
  }
}

const addAlert = (props) => <AlertForm {...props} />

const ReduxFormComponent = connect(mapStateToProps)(
  reduxForm({
    form: 'alert.add',
    keepDirtyOnReinitialize: false,
    enableReinitialize: true,
  })(addAlert),
)

const addAlertWrapper = connect(
  (state) => ({
    locale: getOpointLocale(state),
    autoTranslateOn: isAutoTranslationsOn(state), // global settings
    autoTranslateLanguage: getAutoTranslationLanguageName(state),
    autoTranslateReport: getAddFormTranslate(state), // settings for this alert,
    recipientsCount: getRecipientsCount(state),
    maxAlertRecipients: getMaxAlertRecipients(state),
  }),
  buildActionCreators({
    initForm: ActionTypes.INIT_FORM,
    clearForm: ActionTypes.CLEAR_FORM,
    clearRecipients: ActionTypes.ALERT_CLEAR_RECIPIENTS,
  }),
)(ReduxFormComponent)

export default addAlertWrapper

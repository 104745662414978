// @ts-nocheck
import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'

import buildActionCreators from '../../../helpers/buildActionCreators'
import Loader from '../../common/Loader'
import Translate from '../../../Translate'
import {
  getAlertArticles,
  getTrashedArticles,
  getTotalArticlesCount,
  isLoading,
} from '../../../selectors/alertsSelectors'
import { getAlertId } from '../../../selectors/routingSelectors'
import { articleId } from '../../../opoint/articles/index'
import * as ActionTypes from '../../../constants/actionTypes'

class RemoveArticle extends React.Component<any, any> {
  componentWillMount() {
    this.fetchNextArticles()
  }

  componentWillUnmount() {
    this.props.clearTrashedArticles()
    this.props.destroyArticles()
  }

  fetchNextArticles = () => {
    const { alertId, fetchArticles, articles } = this.props
    const pageId = articles.length / 10 + 1
    fetchArticles({ alertId, pageId })
  }

  trashArticleHandler = (article) => () => this.props.trashArticle(article)

  untrashArticleHandler = (article) => () => this.props.untrashArticle(article)

  render() {
    const { articles, totalArticlesCount, trashedArticles, loading } = this.props
    const hasNextPage = totalArticlesCount - articles.length > 0
    return (
      <div>
        {!!articles && !!articles.length && (
          <ul className="op-modal-remove-articles-body-list-sort">
            {articles.map((article) => (
              <li
                key={articleId(article)}
                className={classNames('op-modal-remove-articles-body-list-sort-item  animate', {
                  'is-deleted': !!trashedArticles[articleId(article)],
                })}
              >
                <span className="name">{article.header}</span>
                <div className="pull-right">
                  {!!trashedArticles[articleId(article)] && (
                    <button className="op-button-square" onClick={this.untrashArticleHandler({ article })}>
                      <span className="op-icon-update" />
                    </button>
                  )}
                  {!trashedArticles[articleId(article)] && (
                    <button className="op-button-square" onClick={this.trashArticleHandler({ article })}>
                      <span className="op-icon-bin" />
                    </button>
                  )}
                </div>
              </li>
            ))}
          </ul>
        )}
        {!articles.length && !loading && (
          <div>
            <Translate i18nString="There are no articles in the next alert yet" />
          </div>
        )}
        {!loading && hasNextPage && (
          <div className="text-center">
            <button className="op-button  green" onClick={this.fetchNextArticles}>
              <Translate i18nString="Load more articles" />
            </button>
          </div>
        )}
        {loading && <Loader />}
      </div>
    )
  }
}

RemoveArticle = connect(
  (state) => {
    const alertId = getAlertId(state)
    return {
      alertId,
      articles: getAlertArticles(state),
      totalArticlesCount: getTotalArticlesCount(alertId)(state),
      trashedArticles: getTrashedArticles(state),
      loading: isLoading(state),
    }
  },
  buildActionCreators({
    fetchArticles: ActionTypes.ALERT_FETCH_ARTICLES,
    trashArticle: ActionTypes.ALERT_TRASH_ARTICLE,
    untrashArticle: ActionTypes.ALERT_UNTRASH_ARTICLE,
    destroyArticles: ActionTypes.ALERT_DESTROY_ARTICLES,
    clearTrashedArticles: ActionTypes.CLEAR_TRASHED_ARTICLES,
  }),
)(RemoveArticle)

export default RemoveArticle

import React from 'react'
import HtmlReactParser from 'html-react-parser'
import { connect } from 'react-redux'

import { getWikinames, getWikidescriptions } from '../../../selectors/searchSelectors'
import Entity from '../Entity'
import Match from '../Match'
import styles from './index.module.scss'

type Props = {
  text?: string
  wikinames: { [key in number]: string }
  wikidescriptions: { [key in number]: string }
}

const OpointTextWithMatchesAndEntities = ({ text, wikinames, wikidescriptions }: Props) => {
  return (
    <>
      {HtmlReactParser(text || '', {
        replace: ({ name, type, children, attribs }) => {
          if (name === 'match' && type === 'tag') {
            const [node] = children // we only support `<match> with one text content element`
            const { ent, color } = attribs

            if (!node) {
              return null
            }

            if (ent) {
              const entityIds: Array<number> = ent.split(',').map(Number)
              const desc = wikidescriptions[entityIds[0]] || ''
              const firstName = wikinames[entityIds[0]] || ''
              const title = `${firstName}, ${desc}`
              const keywords = entityIds
                ?.filter(
                  (id, index) =>
                    index > 0 && wikinames[id] && !desc.includes(wikinames[id]) && !firstName.includes(wikinames[id]),
                )
                .map((id) => wikinames[id])
              const popupContent = (
                <div className={styles.tooltipContentWrapper}>
                  <strong>{title}</strong>
                  {keywords && keywords.length > 0 && (
                    <>
                      <hr />
                      <ul>
                        {keywords.map((word, index) => (
                          <li key={index}>{word}</li>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
              )

              if (!color || color === '0') {
                return (
                  <Entity popupContent={popupContent} tooltipClassName={styles.tooltipContainer}>
                    {node.data}
                  </Entity>
                )
              }

              return (
                <Match color={color}>
                  <Entity popupContent={popupContent} tooltipClassName={styles.tooltipContainer}>
                    {node.data}
                  </Entity>
                </Match>
              )
            }

            if (color) {
              return <Match color={color}>{node.data}</Match>
            }
          }
        },
      })}
    </>
  )
}

export default connect((state) => ({
  wikinames: getWikinames(state),
  wikidescriptions: getWikidescriptions(state),
}))(OpointTextWithMatchesAndEntities)

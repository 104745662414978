import PropTypes from 'prop-types'
import R from 'ramda'
import React from 'react'

import AutocompleteItem from '../opoint/search/AutocompleteItem'
import getPlaceholder from '../opoint/search/placeholder'
import SearchInput from '../components/search/SearchInput'
import { SearchFilterComponent } from './search/FilterComponent'
import type { Filter, Suggestion } from '../opoint/flow'

type SearchProps = {
  clearSearchData: () => void
  excludeHandler: (filter: Filter) => void
  handleInputChange: (searchterm: string) => void
  includeHandler: (filter: Filter) => void
  onFocusHandler: () => void
  onLoadSuggestions: () => void
  onSearchFilterAdded: (filter: Filter) => void
  onSearchFilterRemoved: () => void
  onSearchFilterToggled: (filter: Filter) => void
  onSearchTriggered: () => void
  searchFilters: { [key: string]: Filter }
  searchterm: string
  showInlineSuggestions: boolean
  suggestions: Array<Suggestion>
}

class Search extends React.PureComponent<SearchProps> {
  static contextTypes = {
    i18n: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
  }

  render() {
    const {
      clearSearchData,
      excludeHandler,
      handleInputChange,
      includeHandler,
      onFocusHandler,
      onLoadSuggestions,
      onSearchFilterAdded,
      onSearchFilterRemoved,
      onSearchFilterToggled,
      onSearchTriggered,
      searchFilters,
      searchterm,
      showInlineSuggestions,
      suggestions,
    } = this.props

    const { i18n } = this.context
    const arrayOfFilters = R.values(searchFilters)

    const getPlaceholderWrapper = (filters, i18n) => () => getPlaceholder(filters, i18n)

    return (
      <div>
        <SearchInput
          autoSuggestion
          clearAllHandler={clearSearchData}
          excludeHandler={excludeHandler}
          filterComponent={SearchFilterComponent}
          filters={arrayOfFilters}
          includeHandler={includeHandler}
          onFilterAdded={onSearchFilterAdded}
          onFilterToggled={onSearchFilterToggled}
          onFocusHandler={onFocusHandler}
          onLoadSuggestions={onLoadSuggestions}
          onSearchTermChanged={handleInputChange}
          placeholder={getPlaceholderWrapper(arrayOfFilters, i18n)}
          removeFilter={onSearchFilterRemoved}
          searchterm={searchterm}
          showInlineSuggestions={showInlineSuggestions}
          suggestionComponent={AutocompleteItem}
          suggestions={suggestions}
          triggerSearch={onSearchTriggered}
        />
      </div>
    )
  }
}

export default Search

import React from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'

import Translate from '../../Translate'

class UserLoggedOutModal extends React.PureComponent<any> {
  logOut = () => {
    window.location.reload()
  }

  render() {
    const { isOpen } = this.props

    return (
      <Modal className="modal top am-fade-and-slide-top" tabIndex="-1" role="dialog" show={isOpen}>
        <Modal.Header>
          <h4 className="modal-title">
            <Translate i18nString="User was logged out in an another tab" />
          </h4>
        </Modal.Header>

        <Modal.Body className="op-modal-user-logged-out">
          <div className="modal-body">
            <div>
              <Translate
                i18nString="
              This session has expired since you have logged out in another tab.
              If you need to log in as on two accounts at the same time, you can use an incognito window or different browsers.
              "
              />
            </div>
            <button className="op-button green op-modal-user-logged-out-button" onClick={this.logOut}>
              <Translate i18nString="Reload" />
            </button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

// @ts-ignore
UserLoggedOutModal = connect((state: any) => ({
  isOpen: state.ui.userLoggedOut,
}))(UserLoggedOutModal)

export default UserLoggedOutModal

import React, { SyntheticEvent } from 'react'

export type Props = {
  imageUrl?: string
  imageStyle?: object
  handleMissingImage: (event: SyntheticEvent<HTMLImageElement, Event>) => void
}

const Image = ({ imageUrl, imageStyle, handleMissingImage }: Props) => (
  <img
    className="op-content-right-section-content-left-holder-image"
    src={imageUrl}
    style={imageStyle}
    onError={handleMissingImage}
  />
)

export default Image

// @ts-nocheck
import Rx from 'rxjs'
import R from 'ramda'
import moment from 'moment'

import buildAction from '../helpers/buildAction'
import { OpointTimestampToTimestamp } from '../opoint/common/time'
import { getDefaultTemplate } from '../selectors/templatesSelectors'
import { getOpointLocale, getAutoTranslateSearchParams } from '../selectors/settingsSelectors'
import { getProfileById } from '../selectors/profilesSelectors'
import { getReportContacts } from '../selectors/contactSelectors'
import { getSelectedArticles } from '../selectors/articlesSelectors'
import { getTagById } from '../selectors/tagsSelectors'
import { logOutOnExpiredToken, serverIsDown } from './configureEpics'
import { search, searchTimestamps } from '../opoint/search/index'
import { sortTags, customSort, lastSort } from '../opoint/tags/index'
import {
  createReport,
  deleteReport,
  deleteReportNotification,
  getReportsTagHistory,
  shareReport,
  REPORT_STEP_CREATE_REPORT,
} from '../opoint/reports/index'
import {
  getAutoTranslate,
  isXlsTemplate,
  getCheckedTimestamps,
  getDeletedArticles,
  getEndDate,
  getFooter,
  getReportHistoryObject,
  getOpenedReport,
  getPreface,
  getSearchItems,
  getShareData,
  getReportObject,
  getSortedIDs,
  getAllSortedIDs,
  getSource,
  getStartDate,
  getStep,
  getTemplate,
  getTitle,
  useReportHistory,
} from '../selectors/reportsSelector'
import * as ActionTypes from '../constants/actionTypes'

const reportsSourceEpic = (action$: any) =>
  action$
    .filter(({ type, payload }) => type === ActionTypes.REPORTS_SOURCE && payload.type === 'tag')
    .map(({ payload }) => buildAction(ActionTypes.REPORTS_HISTORY_FETCH, payload))

const reportsTitleEpic = (action$: any, { getState }: any) =>
  Rx.Observable.combineLatest(
    action$.ofType(ActionTypes.REPORTS_SOURCE),
    action$.ofType(ActionTypes.TAGS_FETCH_SUCCESS).take(1),
    action$.ofType(ActionTypes.PROFILES_FETCH_SUCCESS).take(1),
  ).map(
    ([
      {
        payload: { type, id, i18n },
      },
    ]) => {
      let title
      switch (type) {
        case 'tag':
          title = getTagById(id)(getState()).name
          break
        case 'profile':
          title = getProfileById(id)(getState()).name
          break
        case 'current_search':
          title = i18n.t('Current search')
          break
        case 'selected_articles':
          title = i18n.t('Selected articles')
          break
        default:
          title = i18n.t('Report') // todo
      }
      return buildAction(ActionTypes.REPORTS_TITLE, { title })
    },
  )

const reportsOpenModalEpic = (action$: any, { getState }: any) =>
  Rx.Observable.combineLatest(
    action$.ofType(ActionTypes.REPORTS_MODAL_OPEN),
    action$.ofType(ActionTypes.TEMPLATES_FETCH_SUCCESS).take(1),
  ).switchMap(() => {
    const defaultTemplate = getDefaultTemplate(getState())
    return Rx.Observable.of(buildAction(ActionTypes.REPORTS_TEMPLATE, { templateId: defaultTemplate.id }))
  })

const reportsLoadEpic = (action$: any, { getState }: any) =>
  action$
    .ofType(ActionTypes.REPORTS_ARTICLES)
    .switchMap(({ payload: { context, groupidentical } = {} }) => {
      const state = getState()

      const params = { templateId: getTemplate(state) }
      const searchParams = {
        context,
        groupidentical,
        ...(getAutoTranslate(state) ? getAutoTranslateSearchParams(state) : {}),
      }
      const { type, id } = getSource(state)

      let backendData$
      switch (type) {
        case 'tag':
        case 'profile':
        case 'current_search':
          backendData$ = Rx.Observable.defer(() =>
            useReportHistory(state)
              ? // tag using history (timestamps)
                searchTimestamps(
                  {
                    tagId: id,
                    stimestamps: getCheckedTimestamps(state),
                  },
                  { ...searchParams, requestedarticles: 300 },
                  params,
                )
              : // tag or profile (current_search) by date
                search(
                  getSearchItems(state),
                  {
                    ...searchParams,
                    newest: moment(getEndDate(state)).unix(),
                    oldest: moment(getStartDate(state)).unix(),
                    requestedarticles: type === 'tag' ? 300 : 15,
                  },
                  params,
                  getOpointLocale(state),
                ),
          )
          break
        case 'selected_articles':
          backendData$ = Rx.Observable.of({
            searchresult: {
              document: getSelectedArticles(state),
            },
            alreadyPreprocessed: true,
          })
          break
        default:
          console.error('Wrong type!') /* eslint-disable-line no-console */
      }

      if (type === 'tag') {
        // set mark that for newer articles then last sorted timestamp
        // this should be IMHO at the API side
        const lastSorted$ = Rx.Observable.defer(() => lastSort(id))
        return Rx.Observable.combineLatest(lastSorted$, backendData$).switchMap(
          ([{ unixTimestamp }, { searchresult }]) => {
            const isNew = (a) => {
              const tags = R.path(['tags'], a)
              if (!tags) {
                return false
              }

              const { set } = R.find(R.propEq('id', id))(tags)
              return OpointTimestampToTimestamp(set) > unixTimestamp
            }

            return Rx.Observable.of(
              buildAction(ActionTypes.REPORTS_ARTICLES_SUCCESS, {
                searchresult: R.evolve(
                  {
                    document: R.map((document) => ({
                      groupId: document.sort_group_ref,
                      newSinceLastSorted: isNew(document),
                      document,
                    })),
                  },
                  searchresult,
                ),
              }),
            )
          },
        )
      }

      return backendData$.map(({ searchresult, alreadyPreprocessed }) =>
        buildAction(ActionTypes.REPORTS_ARTICLES_SUCCESS, {
          searchresult: R.evolve(
            {
              document: R.map((document) => ({
                groupId: document.sort_group_ref,
                document,
              })),
            },
            searchresult,
          ),
          alreadyPreprocessed, // articles
        }),
      )
    })
    .catch(logOutOnExpiredToken)
    .catch(serverIsDown)
    .catch((error) => Rx.Observable.of(buildAction(ActionTypes.REPORTS_ARTICLES_FAILURE, { error })))

const reportLoadInitialEpic = (action$: any) =>
  action$
    .ofType(ActionTypes.REPORTS_LOAD_INITIAL_ARTICLES)
    .switchMap(({ payload: { groupidentical } = {} }) =>
      Rx.Observable.of(buildAction(ActionTypes.REPORTS_ARTICLES, { groupidentical })),
    )

const reportsHistoryEpic = (action$: any) =>
  action$.ofType(ActionTypes.REPORTS_HISTORY_FETCH).switchMap(({ payload: { id } }) =>
    Rx.Observable.fromPromise(getReportsTagHistory(id))
      .map(({ response }) => buildAction(ActionTypes.REPORTS_HISTORY_FETCH_SUCCESS, response))
      .retry(3)
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.REPORTS_HISTORY_FETCH_FAILURE))),
  )

const reportsTagSortEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.REPORTS_SORT_TAG).switchMap(({ payload: { sortOrder, sortType, groupidentical } }) => {
    const state = getState()
    const params = useReportHistory(state)
      ? { stimestampUsed: getCheckedTimestamps(state) }
      : {
          fromTimestamp: moment(getStartDate(state)).unix(),
          toTimestamp: moment(getEndDate(state)).unix(),
        }

    return Rx.Observable.fromPromise(sortTags(getSource(state).id, { sortOrder, sortType, ...params }))
      .map(() => buildAction(ActionTypes.REPORTS_ARTICLES, { groupidentical }))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.PORTAL_ERROR, { error: 'REPORTS_SORT_TAG_ERROR' })))
  })

const reportsStepEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.REPORTS_STEP).switchMap(({ payload }) => {
    const state = getState()
    return Rx.Observable.of(
      getSource(state).type === 'tag' && getStep(state) === 2 && getSortedIDs(state) && getSortedIDs(state).length
        ? buildAction(ActionTypes.REPORTS_CUSTOM_SORT, payload)
        : buildAction(ActionTypes.REPORTS_STEP_SUCCESS, payload),
    )
  })

const reportsSaveCustomSortOnExitEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.REPORTS_MODAL_CLOSE).switchMap(({ payload }) => {
    const state = getState()
    return getSource(state).type === 'tag' && getStep(state) === 2 && getSortedIDs(state) && getSortedIDs(state).length
      ? Rx.Observable.of(buildAction(ActionTypes.REPORTS_CUSTOM_SORT, payload))
      : Rx.Observable.of()
  })

const reportsCustomSortEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.REPORTS_CUSTOM_SORT).switchMap(({ payload }) => {
    const state = getState()

    const customSort$ = Rx.Observable.fromPromise(customSort(getSource(state).id, getAllSortedIDs(state)))
    // TODO this is a quick fix, however it's very bad that this epic
    // is tightly coupled with reportsStepEpic (REPORTS_STEP_SUCCESS)
    // Now we distinguish between click on a next step vs. click on X
    // in a modal by inspecting payload
    // We should do this more explicitly -> by checking a flag
    // or completely decoupling these epics.
    // Please get back to me on this one @olovor
    if (payload) {
      return customSort$
        .switchMap(() =>
          Rx.Observable.concat(
            Rx.Observable.of(buildAction(ActionTypes.REPORTS_CUSTOM_SORT_SUCCESS)),
            Rx.Observable.of(buildAction(ActionTypes.REPORTS_STEP_SUCCESS, payload)),
          ),
        )
        .catch(logOutOnExpiredToken)
        .catch(serverIsDown)
        .catch(() => Rx.Observable.of(buildAction(ActionTypes.PORTAL_ERROR, { error: 'REPORTS_CUSTOM_SORT' })))
    }
    return customSort$
      .switchMap(() => Rx.Observable.of(buildAction(ActionTypes.REPORTS_CUSTOM_SORT_SUCCESS)))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.PORTAL_ERROR, { error: 'REPORTS_CUSTOM_SORT' })))
  })

const reportsCreateEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.REPORTS_CREATE).switchMap(({ payload }) => {
    const state = getState()
    const { type, id } = getSource(state)
    const deletedArticles = getDeletedArticles(state)
    const locale = getOpointLocale(state)
    const { groupidentical } = payload
    let subparams
    switch (type) {
      case 'tag':
        subparams = useReportHistory(state)
          ? {
              tagId: id,
              stimestamps: getCheckedTimestamps(state),
            }
          : {
              tagId: id,
              oldest: moment(getStartDate(state)).unix(),
              newest: moment(getEndDate(state)).unix(),
              expressions: getSearchItems(state),
            }
        break
      case 'current_search':
        subparams = {
          sortedArticles: getSortedIDs(state),
          oldest: moment(getStartDate(state)).unix(),
          newest: moment(getEndDate(state)).unix(),
          expressions: getSearchItems(state),
        }
        break
      case 'profile':
        subparams = {
          sortedArticles: getSortedIDs(state),
          oldest: moment(getStartDate(state)).unix(),
          newest: moment(getEndDate(state)).unix(),
          expressions: getSearchItems(state),
        }
        break
      case 'selected_articles':
        subparams = {
          articles: getSortedIDs(state),
        }
        break
      default:
        subparams = {}
    }

    const params = {
      templateId: getTemplate(state),
      title: getTitle(state),
      locale,
      params: {
        ...(getAutoTranslate(state) ? getAutoTranslateSearchParams(state) : {}),
        groupidentical: isXlsTemplate(state) ? false : groupidentical,
        excludearticles: deletedArticles,
      },
    }

    const preface = getPreface(state)
    const footer = getFooter(state)
    preface.length && (params.preface = preface)
    footer.length && (params.footer = footer)

    const generateReport$ = Rx.Observable.defer(() => createReport({ ...params, ...subparams }))

    return generateReport$
      .switchMap((reportObject) => {
        const actions = [buildAction(ActionTypes.REPORTS_CREATE_IN_PROGRESS, { reportObject })]
        // re-fetch history
        if (type === 'tag') {
          actions.push(buildAction(ActionTypes.REPORTS_HISTORY_FETCH, { type, id }))
        }

        return Rx.Observable.of(...actions)
      })
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch((e) => Rx.Observable.of(buildAction(ActionTypes.REPORTS_CREATE_FAILURE)))
  })

const reportsCreateFinishEpic = (action$: any, { getState }: any) =>
  Rx.Observable.combineLatest(
    action$.ofType(ActionTypes.NOTIFICATIONS_SOCKET_SUCCESS),
    action$.ofType(ActionTypes.REPORTS_CREATE_IN_PROGRESS),
  ).switchMap(
    ([
      {
        payload: {
          object: {
            value: reportObject,
            value: { id: notificationId },
          },
        },
      },
      {
        payload: {
          reportObject: { id: reportId },
        },
      },
    ]) => {
      if (notificationId !== reportId) {
        return Rx.Observable.of()
      }
      return Rx.Observable.of(buildAction(ActionTypes.REPORTS_CREATE_SUCCESS, { reportObject }))
    },
  )

const shareReportEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.SHARE_REPORT).switchMap(({ payload: { source } }) => {
    const state = getState()

    let reportObject
    switch (source) {
      case 'REPORTS_MODAL': // this should be placed inside const somewhere.
        reportObject = getReportObject(state) || getReportHistoryObject(state)
        break
      default:
        reportObject = getOpenedReport(state)
        break
    }

    const id = [reportObject.id]
    const message = getShareData(state).shareReportMessage
    const shareAttachment = getShareData(state).attachment
    const recipients = getReportContacts(state).map((contact) => {
      const {
        type,
        entity: { id, value },
      } = contact
      return R.mergeAll([{ value: id }, { value }, { type, id }])
    })

    // Checks whether user chose atleast one recipient
    if (!recipients.length) {
      return Rx.Observable.of(
        buildAction(ActionTypes.REPORTS_VALID_SHARE_FAILURE, {
          error: 'You have to enter at least one valid recipient',
        }),
        buildAction(ActionTypes.SHARE_REPORT_UPDATE_STEP, { stepNumber: 2 }),
      )
    }

    return Rx.Observable.fromPromise(
      shareReport({
        id,
        message,
        recipients,
        shareAttachment,
      }),
    )
      .switchMap(() =>
        Rx.Observable.concat(
          Rx.Observable.of(buildAction(ActionTypes.REPORTS_SHARE_SUCCESS)),
          Rx.Observable.of(buildAction(ActionTypes.SHARE_REPORT_UPDATE_STEP, { stepNumber: 4 })),
        ),
      )
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() =>
        Rx.Observable.concat(
          Rx.Observable.of(buildAction(ActionTypes.REPORTS_SHARE_FAILURE)),
          Rx.Observable.of(buildAction(ActionTypes.SHARE_REPORT_UPDATE_STEP, { stepNumber: 5 })),
        ),
      )
  })
// Add refetch after deleting report
const deleteReportNotificationEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.DELETE_REPORT_NOTIFICATION).switchMap(({ payload }) => {
    const { id } = payload
    return Rx.Observable.fromPromise(deleteReportNotification({ id }))
      .switchMap(() =>
        Rx.Observable.concat(
          Rx.Observable.of(buildAction(ActionTypes.NOTIFICATION_DOWNLOAD_MODAL_CLOSE)),
          Rx.Observable.of(buildAction(ActionTypes.DELETE_REPORT_NOTIFICATION_SUCCESS, { id })),
        ),
      )
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() =>
        Rx.Observable.concat(
          Rx.Observable.of(buildAction(ActionTypes.NOTIFICATION_DOWNLOAD_MODAL_CLOSE)),
          Rx.Observable.of(buildAction(ActionTypes.DELETE_REPORT_NOTIFICATION_FAILURE)),
        ),
      )
  })

const deleteReportEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.DELETE_REPORT).switchMap(({ payload }) => {
    const { id } = payload
    return Rx.Observable.fromPromise(deleteReport({ id }))
      .map(() => buildAction(ActionTypes.DELETE_REPORT_SUCCESS, { id }))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.DELETE_REPORT_FAILURE)))
  })

const reportsResetEpic = (action$: any, { getState }: any) =>
  action$
    .ofType(ActionTypes.REPORTS_MODAL_CLOSE)
    .delay(500)
    .mapTo(
      buildAction(ActionTypes.REPORTS_RESET, {
        soft: getState().reports.step <= REPORT_STEP_CREATE_REPORT,
        // preserve some metadata (soft reset) if modal closed before `generate report`
      }),
    )

export default [
  deleteReportEpic,
  deleteReportNotificationEpic,
  reportLoadInitialEpic,
  reportsCreateEpic,
  reportsCreateFinishEpic,
  reportsCustomSortEpic,
  reportsHistoryEpic,
  reportsLoadEpic,
  reportsOpenModalEpic,
  reportsResetEpic,
  reportsSaveCustomSortOnExitEpic,
  reportsSourceEpic,
  reportsStepEpic,
  reportsTagSortEpic,
  reportsTitleEpic,
  shareReportEpic,
]

import Rx from 'rxjs'
import Alert from 'react-s-alert'

import * as ActionTypes from '../../constants/actionTypes'
import { cannotDeleteEntityReason } from '../../opoint/common/index'

const alertError = (i18n, payload) => {
  if (payload.responseErrors) {
    // @ts-ignore
    Object.values(payload.responseErrors).forEach((errors) => errors.forEach((error) => Alert.error(error)))
  } else {
    Alert.error(i18n.t(payload.message))
  }
}

const contactErrorEpic = (action$: any, { i18n }: any) =>
  action$.switchMap(({ type, payload }) => {
    switch (type) {
      case ActionTypes.ADD_ARTICLE_SUCCESS:
        Alert.success(i18n.t('Article was successfully saved.'))
        break
      case ActionTypes.CONTACT_SAVE_SUCCESS:
        Alert.success(i18n.t('Contact was successfully saved.'))
        break
      case ActionTypes.CONTACT_DELETE_SUCCESS:
        Alert.success(i18n.t('Contact was successfully deleted'))
        break
      case ActionTypes.GROUP_DELETE_SUCCESS:
        Alert.success(i18n.t('Group was successfully deleted'))
        break
      case ActionTypes.GROUP_SAVE_SUCCESS:
        Alert.success(i18n.t('Group was successfully saved.'))
        break
      case ActionTypes.CONTACT_SAVE_ERROR:
      case ActionTypes.GROUP_SAVE_ERROR:
        alertError(i18n, payload)
        break
      case ActionTypes.CONTACT_INACTIVE_RESET_SUCCESS:
        Alert.success(i18n.t('Contact alerts pause is now not active.'))
        break
      case ActionTypes.CONTACT_INACTIVE_RESET_ERROR:
        Alert.error(i18n.t(payload.message))
        break
      case ActionTypes.CONTACT_DELETE_ERROR:
        Alert.error(payload.message)
        break
      case ActionTypes.CONTACT_USED_DELETE_ERROR:
        Alert.error(payload.message)
        break
      case ActionTypes.GROUP_DELETE_ERROR:
        Alert.error(payload.message)
        break
      case ActionTypes.CONTACT_SET_ACTIVE_FAILURE:
        Alert.error(i18n.t('We are currently not able to select this contact'))
        break
      case ActionTypes.GROUP_SET_ACTIVE_FAILURE:
        Alert.error(i18n.t('We are currently not able to select this group'))
        break
      default:
    }
    return Rx.Observable.of()
  })

const alertErrorEpic = (action$: any, { i18n }: any) =>
  action$.switchMap(({ type, payload }) => {
    switch (type) {
      case ActionTypes.ALERT_DELETE_SUCCESS:
        Alert.success(i18n.t('Alert was successfully deleted'))
        break
      case ActionTypes.SAVE_ALERT_SUCCESS:
        Alert.success(i18n.t('Alert was successfully saved'))
        break
      case ActionTypes.ALERT_SEND_SUCCESS:
        Alert.success(i18n.t('Alert was successfully sent'))
        break
      case ActionTypes.ALERT_HISTORY_SEND_AGAIN_SUCCESS:
        Alert.success(i18n.t('Sent alert was successfully resent'))
        break
      case ActionTypes.ALERT_REMOVE_TRASHED_ARTICLES_SUCCESS:
        Alert.success(i18n.t('Articles were successfully removed'))
        break
      case ActionTypes.GO_TO_ALERT_FAILURE:
        Alert.error(i18n.t('We were unable to go to selected alert'))
        break
      case ActionTypes.ALERT_DELETE_FAILURE:
        Alert.error(i18n.t('We were unable to delete selected alert'))
        break
      case ActionTypes.CREATE_ALERT_FAILURE:
        Alert.error(i18n.t('We were unable to go to the creation of a new alert'))
        break
      case ActionTypes.SAVE_ALERT_FAILURE:
        Alert.error(i18n.t('We were unable to save this alert'))
        break
      case ActionTypes.ALERT_SEND_FAILURE:
        Alert.error(i18n.t('We were unable to send this alert'))
        break
      case ActionTypes.ALERT_REMOVE_TRASHED_ARTICLES_FAILURE:
        Alert.error(i18n.t('We were unable to delete selected articles'))
        break
      case ActionTypes.ALERT_FETCH_FAILURE:
        Alert.error(i18n.t('We were unable to fetch this alert'))
        break
      case ActionTypes.ALERT_FETCH_NEXT_PREVIEW_FAILURE:
        Alert.error(i18n.t('We were unable to fetch preview of next alert'))
        break
      // TBD
      case ActionTypes.ALERT_FETCH_NEXT_FAILURE:
        Alert.error(i18n.t('We were unable to fetch this alert'))
        break
      case ActionTypes.ALERT_FETCH_HISTORY_FAILURE:
        Alert.error(i18n.t('We were unable to fetch alert history'))
        break
      case ActionTypes.ALERT_FETCH_HISTORY_ITEM_FAILURE:
        Alert.error(i18n.t('We were unable to fetch alert history details'))
        break
      case ActionTypes.ALERT_TEMPLATE_FETCH_FAILURE:
        Alert.error(i18n.t('We were unable to get this preview'))
        break
      case ActionTypes.SAVE_ALERT_VALID_FAILURE:
      case ActionTypes.LEAVE_ALERT_FAILURE:
        Alert.error(i18n.t(payload.error))
        break
      default:
    }
    return Rx.Observable.of()
  })

const articlesErrorEpic = (action$: any, { i18n }: any) =>
  action$.switchMap(({ type }) => {
    switch (type) {
      case ActionTypes.EDIT_ARTICLE_SUCCESS:
        Alert.success(i18n.t('Article was successfully updated'))
        break
      case ActionTypes.SHARE_ARTICLES_SUCCESS:
        Alert.success(i18n.t('Article was successfully shared'))
        break
      case ActionTypes.EDIT_ARTICLE_FAILURE:
        Alert.error(i18n.t("Article couldn't be updated, please contact support"))
        break
      case ActionTypes.ADD_ARTICLE_SUGGESTIONS_FAILURE:
        Alert.error(i18n.t('We were unable to get suggestions'))
        break
      case ActionTypes.ADD_ARTICLE_SUGGESTIONS_COUNTRY_FAILURE:
        Alert.error(i18n.t('We were unable to get suggestions'))
        break
      case ActionTypes.ADD_ARTICLE_FAILURE:
        Alert.error(i18n.t('We were unable to add this article'))
        break
      case ActionTypes.ADD_ARTICLE_FILE_UPLOAD_FAILURE:
        Alert.error(i18n.t('We were unable to upload this file'))
        break
      case ActionTypes.SHARE_ARTICLES_FAILURE:
        Alert.error(i18n.t('Error while sharing an article'))
        break
      case ActionTypes.ADD_ARTICLE_FILE_UNSUPPORTED_FILE_ERROR:
        Alert.error(i18n.t("Files this kind of type can't be uploaded"))
        break
      default:
    }
    return Rx.Observable.of()
  })

const feedErrorEpic = (action$: any, { i18n }: any) =>
  action$.switchMap(({ type, payload }) => {
    switch (type) {
      case ActionTypes.FEED_SAVE_SUCCESS:
        Alert.success(i18n.t('Feed successfully saved'))
        break
      case ActionTypes.FEED_NEW_SUCCESS:
        Alert.success(i18n.t('New feed successfully created'))
        break
      case ActionTypes.FEED_SET_ACTIVE_FAILURE:
        Alert.error(i18n.t('We were unable to select this feed'))
        break
      case ActionTypes.FEED_SAVE_ERROR:
        Alert.error(i18n.t('We were unable to save this feed'))
        break
      case ActionTypes.FEED_NEW_ERROR:
        Alert.error(i18n.t('We were not able to create new feed'))
        break
      case ActionTypes.FEED_DELETE_ERROR:
        Alert.error(i18n.t('We were unable to delete this feed'))
        break
      case ActionTypes.SAVE_FEED_VALID_FAILURE:
        Alert.error(payload.error)
        break
      default:
    }
    return Rx.Observable.of()
  })

const notificationsEpics = (action$: any, { i18n }: any) =>
  action$.switchMap(({ type }) => {
    switch (type) {
      case ActionTypes.NOTIFICATIONS_FETCH_FAILURE:
        Alert.error(i18n.t('We were unable to fetch notifications'))
        break
      default:
    }
    return Rx.Observable.of()
  })
const profilesErrorEpics = (action$: any, { i18n }: any) =>
  action$.switchMap(({ type }) => {
    switch (type) {
      case ActionTypes.PROFILES_FETCH_FAILURE:
        Alert.error(i18n.t('We were unable to fetch profiles'))
        break
      case ActionTypes.PROFILE_EDITOR_FILTERS_FETCH_MULTIPLE_FAILURE:
        Alert.error(i18n.t('We were unable to provide suggestions'))
        break
      case ActionTypes.PROFILE_EDITOR_FILTERS_FETCH_SIMPLE_FAILURE:
        Alert.error(i18n.t('We were unable to provide suggestions'))
        break
      default:
    }
    return Rx.Observable.of()
  })

const profileEditorErrorEpics = (action$: any, { i18n }: any) =>
  action$.switchMap(({ type, payload }) => {
    switch (type) {
      case ActionTypes.SAVE_AS_PROFILE_SUCCESS:
      case ActionTypes.PROFILE_EDITOR_SAVE_PROFILE_SUCCESS:
        Alert.success(i18n.t('Profile was successfully saved'))
        break
      case ActionTypes.PROFILE_DELETE_SUCCESS:
        Alert.success(i18n.t('Profile was successfully deleted'))
        break
      case ActionTypes.PROFILE_EDITOR_SAVE_PROFILE_FAILURE:
        Alert.error(i18n.t('We were unable to save this profile'))
        break
      case ActionTypes.LOAD_EDITED_PROFILE_FAILURE:
        Alert.error(i18n.t('We were unable to load this profile'))
        break
      case ActionTypes.PROFILE_DELETE_FAILURE:
        Alert.error(cannotDeleteEntityReason(payload, i18n))
        break
      case ActionTypes.PROFILE_HISTORY_FETCH_FAILURE:
        Alert.error(i18n.t('We were unable to fetch this profile history'))
        break
      case ActionTypes.DELETED_PROFILES_HISTORY_FETCH_FAILURE:
        Alert.error(i18n.t('We were unable to fetch deleted profiles history'))
        break
      case ActionTypes.PROFILE_EDITOR_INVALID_SEARCHLINE:
        Alert.error(i18n.t('We were unable to save this profile - search line is in wrong format'))
        break
      default:
    }
    return Rx.Observable.of()
  })

const reportErrorEpics = (action$: any, { i18n }: any) =>
  action$.switchMap(({ type, payload }) => {
    switch (type) {
      case ActionTypes.REPORTS_SHARE_SUCCESS:
        Alert.success(i18n.t('We successfully shared this report'))
        break
      case ActionTypes.DELETE_REPORT_NOTIFICATION_SUCCESS:
        Alert.success(i18n.t('We successfully deleted selected report notification'))
        break
      case ActionTypes.DELETE_REPORT_SUCCESS:
        Alert.success(i18n.t('We successfully deleted selected report'))
        break
      case ActionTypes.REPORTS_SHARE_FAILURE:
        Alert.error(i18n.t('We were not able to share this report'))
        break
      case ActionTypes.DELETE_REPORT_NOTIFICATION_FAILURE:
        Alert.error(i18n.t('We were not able to delete selected report notification'))
        break
      case ActionTypes.DELETE_REPORT_FAILURE:
        Alert.error(i18n.t('We were not able to delete selected report'))
        break
      case ActionTypes.REPORTS_ARTICLES_FAILURE:
        Alert.error(i18n.t('We were not able to fetch articles for report'))
        break
      case ActionTypes.REPORTS_HISTORY_FETCH_FAILURE:
        Alert.error(i18n.t('We were not able to fetch report history'))
        break
      case ActionTypes.REPORTHISTORY_FETCH_FAILURE:
        Alert.error(i18n.t('We were not able to fetch report history'))
        break
      case ActionTypes.REPORTS_VALID_SHARE_FAILURE:
        Alert.error(payload.error)
        break
      default:
    }
    return Rx.Observable.of()
  })

const searchErrorEpics = (action$: any, { i18n }: any) =>
  action$.switchMap(({ type }) => {
    switch (type) {
      case ActionTypes.FETCH_ARTICLES_FAILURE:
        Alert.error(i18n.t('We were not able to fetch articles for this search'))
        break
      case ActionTypes.SEARCH_CHANGE_DATERANGE_FAILURE:
        Alert.error(i18n.t('We were not able to change date range'))
        break
      case ActionTypes.FETCH_FILTER_DETAIL_FAILURE:
        Alert.error(i18n.t('We were not able to fetch filter details'))
        break
      default:
    }
    return Rx.Observable.of()
  })

const statisticsErrorEpics = (action$: any, { i18n }: any) =>
  action$.switchMap(({ type }) => {
    switch (type) {
      case ActionTypes.FETCH_STATISTICS_FAILURE:
        Alert.error(i18n.t('We were not able to fetch statistics for this search'))
        break
      case ActionTypes.STATISTICS_VIEW_DELETE_SUCCESS:
        Alert.success(i18n.t('Statistic view was successfully deleted'))
        break
      case ActionTypes.STATISTICS_VIEW_DELETE_FAILURE:
        Alert.error(i18n.t('We were unable to delete selected statistic view'))
        break
      case ActionTypes.STATISTICS_VIEW_SAVE_SUCCESS:
        Alert.success(i18n.t('Statistic view was successfully saved'))
        break
      case ActionTypes.STATISTICS_VIEW_SAVE_FAILURE:
        Alert.error(i18n.t('We were unable to save selected statistic view'))
        break
      case ActionTypes.STATISTICS_VIEW_EXPORT_PDF_SUCCESS:
        Alert.success(i18n.t('Statistics view PDF was successfully generated and downloaded'))
        break
      case ActionTypes.STATISTICS_VIEW_EXPORT_PDF_FAILURE:
        Alert.error(i18n.t('Export statistics as PDF failed'))
        break
      default:
    }
    return Rx.Observable.of()
  })

const tagErrorEpics = (action$: any, { i18n }: any) =>
  action$.switchMap(({ type, payload }) => {
    switch (type) {
      case ActionTypes.ADD_TAG_SUCCESS:
        Alert.success(i18n.t('We successfully added new tag'))
        break
      case ActionTypes.DELETE_TAG_SUCCESS:
        Alert.success(i18n.t('We successfully deleted selected tag'))
        break
      case ActionTypes.TAG_ARTICLES_FAILURE:
        Alert.error(i18n.t('We were not able to tag this article'))
        break
      case ActionTypes.UNTAG_ARTICLES_FAILURE:
        Alert.error(i18n.t('We were not able to untag this article'))
        break
      case ActionTypes.DELETE_ARTICLES_FAILURE:
        Alert.error(i18n.t('We were not able to delete this article'))
        break
      case ActionTypes.UNDELETE_ARTICLES_FAILURE:
        Alert.error(i18n.t('We were not able to restore this article'))
        break
      case ActionTypes.ADD_TAG_FAILURE:
        Alert.error(i18n.t('We were not able to add this tag'))
        break
      case ActionTypes.EDIT_TAG_FAILURE:
        Alert.error(i18n.t('We were not able to edit this tag'))
        break
      case ActionTypes.DELETE_TAG_FAILURE:
        Alert.error(i18n.t(cannotDeleteEntityReason(payload, i18n)))
        break
      default:
    }
    return Rx.Observable.of()
  })

const templateErrorEpics = (action$: any, { i18n }: any) =>
  action$.switchMap(({ type, payload }) => {
    switch (type) {
      case ActionTypes.TEMPLATE_SAVE_SUCCESS:
        Alert.success(i18n.t('We successfully saved your template'))
        break
      case ActionTypes.TEMPLATE_NEW_SUCCESS:
        Alert.success(i18n.t('We successfully created new template'))
        break
      case ActionTypes.TEMPLATE_DELETE_SUCCESS:
        Alert.success(i18n.t('We successfully deleted template'))
        break
      case ActionTypes.TEMPLATE_SAVE_FAILURE:
        Alert.error(i18n.t('We were not able to save your template'))
        break
      case ActionTypes.TEMPLATE_NEW_FAILURE:
        Alert.error(i18n.t('We were not able to create new template'))
        break
      case ActionTypes.TEMPLATE_DELETE_FAILURE:
        Alert.error(i18n.t('We were not able to delete your template'))
        break
      default:
    }
    return Rx.Observable.of()
  })

const routerErrorEpic = (action$: any, { i18n }: any) =>
  action$.switchMap(({ type }) => {
    switch (type) {
      case ActionTypes.ROUTE_CHANGE_FAILURE:
        Alert.error(i18n.t('We are unable to change route, try again'))
        break
      default:
    }
    return Rx.Observable.of()
  })

const portalErrorEpic = (action$: any, { i18n }: any) =>
  action$.switchMap(({ type }) => {
    switch (type) {
      case ActionTypes.TOKEN_EXPIRED:
        Alert.error(i18n.t('Authentication token expired, please log in again.'))
        break
      case ActionTypes.SERVER_NOT_RESPONDING:
        Alert.error(i18n.t('We are sorry, server is not responding, please, try again later.'))
        break
      default:
    }
    return Rx.Observable.of()
  })

export default [
  alertErrorEpic,
  articlesErrorEpic,
  contactErrorEpic,
  feedErrorEpic,
  notificationsEpics,
  portalErrorEpic,
  profileEditorErrorEpics,
  profilesErrorEpics,
  reportErrorEpics,
  routerErrorEpic,
  searchErrorEpics,
  statisticsErrorEpics,
  tagErrorEpics,
  templateErrorEpics,
]

import React from 'react'
import { connect } from 'react-redux'

import { getUserPermissions } from '../../selectors/settingsSelectors'

class ModulePermissions extends React.PureComponent<any> {
  render() {
    const { children, isPermitted, userPermissions } = this.props

    // @ts-ignore
    const childrenWithProps = React.Children.map(children, (child) => React.cloneElement(child, { userPermissions }))

    return isPermitted ? childrenWithProps : <div /> // TODO user info 'not permitted' ?
  }
}

// @ts-ignore
ModulePermissions = connect((state, ownProps: any) => {
  const userPermissions = getUserPermissions(ownProps.module)(state)
  const isPermitted = !!ownProps.permissions.filter((permission) => permission === userPermissions).length

  return {
    isPermitted,
    userPermissions,
  }
})(ModulePermissions)

export default ModulePermissions

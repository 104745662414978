import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import buildActionCreators from '../../helpers/buildActionCreators'
import HeaderActions from './HeaderActions'
import LogoWrapper from './LogoWrapper'
import Toolbar from '../Toolbar'
import { getEntityFilter } from '../../selectors/uiSelectors'
import * as ActionTypes from '../../constants/actionTypes'

type Props = {
  showSearch: boolean
  showToolbar: boolean
  showEntitiesToggle: boolean
  entityFilter: any
  entityFilterChanged: Function
}

class OpointHeader extends React.Component<Props> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  static defaultProps = {
    showSearch: true,
    showToolbar: true,
    showEntitiesToggle: true,
  }

  onEntityChangeHandler = (event) => {
    this.props.entityFilterChanged(event.target.value)
  }

  onResetClickHandler = () => {
    this.props.entityFilterChanged('')
  }

  render() {
    const { entityFilter, showToolbar, showSearch, showEntitiesToggle } = this.props
    const { i18n } = this.context

    return (
      <div className="op-relative">
        <div className="row">
          <div className="col-md-12 header-shadow">
            <div id="mod-spy-header" className="row  op-content-top_wrapper">
              <div className="header-top_wrapper">
                <LogoWrapper showMenuToggle showToolbar={showToolbar} showEntitiesToggle={showEntitiesToggle} />
                <HeaderActions showSearch={showSearch} />
              </div>
              <div className="header-bottom_wrapper" style={{ height: '37px' }}>
                <div className="col-md-2 entity-filter_wrapper">
                  <div className="row mod-full-height">
                    <div className="util_center-parent  mod-full-all">
                      <div className="util_center-child">
                        <label
                          // htmlFor="entityFilter"
                          className="entityFilter__label"
                          // aria-label={i18n.t('Filters for entity listing')}
                        >
                          <div className="col-md-3  op-content-top-filter-icon_wrapper util_col-vertical-center">
                            <div className="row">
                              <div className="op-main-menu-toggle">
                                <i className="op-icon-filter" />
                              </div>
                            </div>
                          </div>
                        </label>
                        <div className="col-md-8 col-md-pull-2  op-content-top-entity-filter_wrapper util_col-vertical-center">
                          <div className="row">
                            <div className="entityFilter">
                              <input
                                type="text"
                                id="entityFilter"
                                value={entityFilter}
                                placeholder={i18n.t('Search for profiles, tags and alerts')}
                                onChange={this.onEntityChangeHandler}
                              />
                              {entityFilter && (
                                <a
                                  className="entityFilter__resetButton"
                                  title={i18n.t('Clear search')}
                                  onClick={this.onResetClickHandler}
                                >
                                  <i className="op-icon-cross" />
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {!!showToolbar && (
                  <div className="col-md-10 op-content-center-toolbar-left">
                    <div className="op-content-toolbar_wrapper" id="op-app-toolbar">
                      <Toolbar i18n={i18n} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

// @ts-ignore
OpointHeader = connect(
  (state) => ({
    entityFilter: getEntityFilter(state),
  }),
  buildActionCreators({
    entityFilterChanged: ActionTypes.ENTITY_FILTER_CHANGED,
    fetchRecipients: ActionTypes.ALERTS_FETCH_RECIPIENTS,
  }),
  // @ts-ignore
)(OpointHeader)

export default OpointHeader

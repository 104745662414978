// @ts-nocheck
import React, { Component } from 'react'
import PopperJS from 'popper.js'
import moment from 'moment'

import Translate from '../../../Translate'
import PopperElement from './PopperElement'

class OpointDatepickerPop extends Component {
  constructor(props) {
    super(props)

    this.state = {
      popper: null,
    }

    this.popperRef = null
    this.rootRef = null
  }

  buttonClickHandler = (e) => {
    const { popper } = this.state

    popper ? this.hidePopper() : this.showPopper(e)
  }

  showPopper = (e) => {
    this.setState({
      popper: new PopperJS(e.target, this.popperRef, {
        placement: 'bottom',
        modifiers: {
          arrow: {
            enabled: true,
            element: '.popper__arrow',
          },
        },
      }),
    })
  }

  hidePopper = () => {
    const { popper: popperInstance } = this.state

    popperInstance && popperInstance.destroy()
    this.setState({ popper: null })
  }

  clickOutside = ({ target }) => {
    const timePicker = document.getElementsByClassName('rc-time-picker-panel')[0]

    if (!(this.rootRef && this.rootRef.contains(target)) && !(timePicker && timePicker.contains(target))) {
      this.hidePopper()
    }
  }

  onKeyDownHandler = (e) => {
    if (e.key === 'Tab') {
      this.hidePopper()
    }
  }

  render() {
    const { startDate } = this.props
    const { popper } = this.state

    return (
      <div
        className="op-form-group"
        ref={(ref) => {
          this.rootRef = ref
        }}
      >
        <label htmlFor="add-new-article-date">
          <Translate i18nString="Publishing time" />
        </label>
        <input
          name="add-new-article-date"
          id="add-new-article-date"
          type="text"
          onFocus={this.buttonClickHandler}
          value={moment(startDate).format('LLLL')}
          onKeyDown={this.onKeyDownHandler}
        />

        <span
          className="popper u-overall"
          ref={(ref) => {
            this.popperRef = ref
          }}
        >
          {popper && (
            <div className="popper--border u-bg-white">
              <div className="popper__arrow" x-arrow="true" />
              <PopperElement datePickerProps={this.props} clickOutside={this.clickOutside} />
            </div>
          )}
        </span>
      </div>
    )
  }
}

export default OpointDatepickerPop

import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import * as R from 'ramda'

import buildActionCreators from '../../helpers/buildActionCreators'
import OpointDate from '../../opoint/common/time'
import ReportActions from '../common/ReportActions'
import Translate from '../../Translate'
import { getOpenedReport } from '../../selectors/reportsSelector'
import { TEMPLATE_TYPES } from '../../opoint/templates/index'
import * as ActionTypes from '../../constants/actionTypes'

class NotificationDownloadModal extends React.PureComponent<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      deleteConfirmation: false,
    }
  }

  onDeleteClick = () => {
    const { notificationId, deleteReportNotification } = this.props
    deleteReportNotification({ id: notificationId })
  }

  clearData = () => {
    this.props.clearRecipients()
    this.props.clearData()
  }

  confirmDelete = () => {
    this.setState({ deleteConfirmation: true })
  }

  cancelDelete = () => {
    this.setState({ deleteConfirmation: false })
  }

  sendReport = () => {
    this.props.sendReport({ source: 'OPENED_REPORT' })
  }

  resetState = () => {
    this.setState({ deleteConfirmation: false })
  }

  render() {
    const {
      isOpen,
      notificationDownloadModalClose,
      shareReportMessage,
      changeMessage,
      reportOpened,
      selectedContacts,
      stepNumber,
      updateStep,
      toggleAttachment,
      isAttachmentChecked,
    } = this.props
    const time = reportOpened ? OpointDate.longFormat(reportOpened.timeFinished * 1000) : 'Not available'
    // @ts-ignore
    const type = reportOpened
      ? R.compose(
          // @ts-ignore
          R.keys(),
          // @ts-ignore
          R.filter((value, key) => value === reportOpened.type),
          // @ts-ignore
        )(TEMPLATE_TYPES)[0]
      : 'Not Available'

    return (
      <Modal
        show={isOpen}
        onHide={notificationDownloadModalClose}
        onExited={this.resetState}
        className="modal-dialog--short"
      >
        <Modal.Header>
          <button type="button" className="close" onClick={notificationDownloadModalClose}>
            ×
          </button>
          <h4 className="modal-title">
            <Translate i18nString="Report" />
            {': '}
            {!!reportOpened && reportOpened.title}
          </h4>
        </Modal.Header>
        <Modal.Body className="share-report-modal-body">
          <ReportActions
            shareReportMessage={shareReportMessage}
            changeMessage={changeMessage}
            selectedContacts={selectedContacts}
            reportOpened={reportOpened}
            step={stepNumber}
            updateStep={updateStep}
            toggleAttachment={toggleAttachment}
            sendReport={this.sendReport}
            clearData={this.clearData}
            isAttachmentChecked={isAttachmentChecked}
          />
        </Modal.Body>
        <br />
        <Modal.Footer>
          <div className="modal-body-info">
            <div className="modal-body-info-single">
              <span>
                <Translate i18nString="Date" />:
              </span>{' '}
              {time}
            </div>
            <div className="modal-body-info-single">
              <span>
                <Translate i18nString="Type" />:
              </span>{' '}
              {type}
            </div>
          </div>
          <div className={classNames('modal-delete', { 'is-show-confirm': this.state.deleteConfirmation })}>
            <div className="modal-delete-confirm">
              <span>
                <Translate i18nString="Do you really want to delete this notification" />?
              </span>
              <button className="op-button" onClick={this.cancelDelete}>
                <Translate i18nString="Cancel" />
              </button>
              <button className="op-button" onClick={this.onDeleteClick}>
                <Translate i18nString="Delete report" />
              </button>
            </div>
            <div className="modal-delete-button">
              <button className="op-button" onClick={this.confirmDelete}>
                <Translate i18nString="Delete report" />
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    )
  }
}

const NotificationDownloadModalWrapped = connect(
  (state: any) => ({
    isOpen: state.ui.notificationDownloadModalOpen,
    reportOpened: getOpenedReport(state),
    selectedContacts: state.contacts.shareReportContacts,
    shareReportMessage: state.reports.shareReportData.shareReportMessage,
    stepNumber: state.reports.shareReportData.stepNumber,
    isAttachmentChecked: state.reports.shareReportData.attachment,
    notificationId: state.ui.notificationId,
  }),
  buildActionCreators({
    notificationDownloadModalClose: ActionTypes.NOTIFICATION_DOWNLOAD_MODAL_CLOSE,
    changeMessage: ActionTypes.SHARE_REPORT_CHANGE_MESSAGE,
    updateStep: ActionTypes.SHARE_REPORT_UPDATE_STEP,
    toggleAttachment: ActionTypes.SHARE_REPORT_TOGGLE_ATTACHMENT,
    sendReport: ActionTypes.SHARE_REPORT,
    deleteReportNotification: ActionTypes.DELETE_REPORT_NOTIFICATION,
    clearRecipients: ActionTypes.CLEAR_SHARE_REPORT_RECIPIENTS,
    clearData: ActionTypes.CLEAR_SHARE_REPORT_DATA,
  }),
)(NotificationDownloadModal)

export default NotificationDownloadModalWrapped

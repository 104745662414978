import React from 'react'
import moment from 'moment'

import Checkbox from '../../common/Checkbox'
import Loader from '../../common/Loader'
import OpointDate from '../../../opoint/common/time'
import OpointDatepicker from '../../common/OpointDatepicker'
import Radio from '../../common/Radio'
import Translate from '../../../Translate'
import type { Action, LocaleKey } from '../../../opoint/flow'

export type Props = {
  reportsHistory?: Array<{
    count: number
    timestamp: number
    selected?: boolean
    stimestampUsed: number
  }>
  // todo probably define some general type in OpointDatepicker?
  onStartDateChange: () => Action<string>
  onEndDateChange: () => Action<string>
  startDate: Date
  endDate: Date
  locale: LocaleKey
  type: 'date' | 'history'
  reportsHistoryCheck: (id: number) => void
  changeType: (type: string) => void
  lastReportDate: any
}

export default class TagSource extends React.Component<any, any> {
  props: Props

  changeType = (type: 'date' | 'history') => {
    const { changeType } = this.props

    return () => changeType(type)
  }

  reportsHistoryCheck = (event: any) => {
    const { reportsHistoryCheck } = this.props

    const {
      target: { value: id },
    } = event

    return reportsHistoryCheck(id)
  }

  render() {
    const {
      reportsHistory,
      type,
      locale,
      startDate,
      endDate,
      onStartDateChange,
      onEndDateChange,
      lastReportDate,
    } = this.props

    return (
      <div>
        <div className="op-modal-report-body-content-section">
          <div className="content__tag">
            <div className="content__toggle">
              <Radio onChange={this.changeType('date')} selected={type === 'date'} />
              <span className="type-checkbox">
                <Translate i18nString="Select a date interval" />
              </span>
            </div>
            {type === 'date' && (
              <div>
                <div className="op-modal-datepicker__header">
                  <div className="op-modal-datepicker__heading">
                    <h4>
                      <Translate i18nString="Start date" />
                    </h4>
                    <span>{OpointDate.humanFormat(moment(startDate))}</span>
                  </div>
                  <div className="op-modal-datepicker__heading">
                    <h4>
                      <Translate i18nString="End date" />
                    </h4>
                    <span>{OpointDate.humanFormat(moment(endDate))}</span>
                  </div>
                </div>
                <OpointDatepicker
                  startDate={startDate}
                  endDate={endDate}
                  onStartDateChange={onStartDateChange}
                  onEndDateChange={onEndDateChange}
                  locale={locale}
                  lastReportDate={lastReportDate}
                />
              </div>
            )}
          </div>
        </div>

        <div className="op-modal-report-body-content-section">
          <div className="content__toggle">
            <Radio onChange={this.changeType('history')} selected={type === 'history'} />
            <span className="type-checkbox">
              <Translate i18nString="Choose based on report history" />
            </span>
          </div>
        </div>
        {!reportsHistory && <Loader />}
        {type === 'history' && reportsHistory && (
          <ul className="op-modal-report-body-list">
            {Object.values(reportsHistory).map(({ count, timestamp, selected = false, stimestampUsed }) => (
              <li className="op-modal-report-body-list-item" key={timestamp}>
                <Checkbox
                  id={stimestampUsed}
                  value={stimestampUsed}
                  label={count}
                  className="checkbox__wrapper"
                  onChangeHandler={this.reportsHistoryCheck}
                  isChecked={selected}
                />
                {stimestampUsed === 0 ? ( // 0 = recently tagged articles
                  <Translate i18nString="{{count}} recently tagged articles" context={{ count }} />
                ) : (
                  <Translate
                    i18nString={
                      count === 1
                        ? 'Include 1 article from report generated {{time}}'
                        : 'Include {{count}} articles from report generated from {{time}}'
                    }
                    context={{
                      count,
                      time: OpointDate.longFormat(timestamp),
                    }}
                  />
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    )
  }
}

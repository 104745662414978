import React from 'react'
import { Popover, OverlayTrigger } from 'react-bootstrap'

import type { ColorbarPointType } from '../../../opoint/flow'

export const ColorbarPoint = ({ point }: { point: ColorbarPointType }) => {
  const quotePopover = (
    <Popover id="ActionLinePopover" className="popover popover-menu popover-options op-colorbar-popover">
      <div className="popover-content" dangerouslySetInnerHTML={{ __html: point?.quote?.text ?? '' }} />
    </Popover>
  )

  return (
    <OverlayTrigger placement="bottom" overlay={quotePopover} rootClose>
      <li
        className={`op-content-right-section-content-options-colorbar-item color-num-${point.color}`}
        style={{ left: point.middle }}
      />
    </OverlayTrigger>
  )
}

import React from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'

import { MODULE_OPTION, MODULE } from '../../opoint/settings'
import ModulePermissions from '../common/ModulePermissions'
import ContactsForm from './Form'
import * as ActionTypes from '../../constants/actionTypes'
import buildActionCreators from '../../helpers/buildActionCreators'
import Translate from '../../Translate'

class ContactModal extends React.PureComponent<any> {
  render() {
    const { isOpen, contactModalClose } = this.props

    return (
      <Modal
        className="modal top am-fade-and-slide-top  op-modal-contacts"
        tabIndex="-1"
        role="dialog"
        show={isOpen}
        onHide={contactModalClose}
      >
        <Modal.Header>
          <button type="button" className="close" onClick={contactModalClose}>
            ×
          </button>
          <h4 className="modal-title">
            <Translate i18nString="Contacts" />
          </h4>
        </Modal.Header>

        <Modal.Body className="op-modal-contacts">
          <div className="modal-body">
            <ContactsForm {...this.props} />
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

// @ts-ignore
ContactModal = connect(
  (state: any) => ({
    isOpen: state.ui.contactModalOpen,
  }),
  buildActionCreators({
    contactModalOpen: ActionTypes.CONTACT_MODAL_OPEN,
    contactModalClose: ActionTypes.CONTACT_MODAL_CLOSE,
  }),
)(ContactModal)

const ContactModalWithPermission = () => (
  <ModulePermissions module={MODULE.CONTACT} permissions={[MODULE_OPTION.ON, MODULE_OPTION.READ_ONLY]}>
    <ContactModal />
  </ModulePermissions>
)

export default ContactModalWithPermission

import * as d3 from 'd3'

export const colorSetOpoint = [
  // base colors:
  // blågrå    grøn       blå        turkis     lila       rødbrun    gul
  '#637B9B',
  '#1d9a78',
  '#258FD9',
  '#36afce',
  '#A35F82',
  '#b74919',
  '#bc770b',
  // shade 1: very light
  '#C1CAD7',
  '#5FC0A5',
  '#8CB9D8',
  '#89D7EA',
  '#D4ABC0',
  '#F18D63',
  '#F3B452',
  // shade2: light
  '#91A2B9',
  '#38A587',
  '#64A2CE',
  '#5AC3DB',
  '#B37896',
  '#D86738',
  '#E69A22',
]

export const colorSetOpointBasic = [
  // Opoint base colors:
  // blå       blågrå     grøn       turkis     lila       rødbrun    gul
  '#1d6fa9',
  '#44546a',
  '#1d9a78',
  '#36afce',
  '#954f72',
  '#b74919',
  '#bc770b',
]

export const colorScale = d3.scale.ordinal().range(colorSetOpoint)
export const colorScaleBasic = d3.scale.ordinal().range(colorSetOpointBasic)

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'

import buildActionCreators from '../../helpers/buildActionCreators'
import Aspect from './aspect'
import Select from '../common/Select'
import Translate from '../../Translate'
import { getAspects, getCountBy } from '../../selectors/statisticsSelectors'
import * as ActionTypes from '../../constants/actionTypes'
import type { StatisticAspect } from '../../opoint/flow'

type Props = {
  aspects: Array<StatisticAspect>
}

function getInterest(aspect) {
  switch (true) {
    case aspect.comp_part === -1:
      return 'not-computed'
    case aspect.sep <= 100:
      return 'low'
    case aspect.sep <= 300:
      return 'mid-low'
    case aspect.sep <= 400:
      return 'mid'
    case aspect.sep <= 500:
      return 'mid-high'
    default:
      return 'high'
  }
}

class StatisticsAspects extends React.Component<any, any> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  sumByOptions: Array<any>

  constructor(props, context) {
    super(props)

    const { i18n } = context

    this.sumByOptions = [
      { value: 'count', label: i18n.t('n. of articles') },
      { value: 'circulation', label: i18n.t('circulation') },
      { value: 'reach', label: i18n.t('reach') },
      { value: 'adprice', label: i18n.t('adprice') },
    ]
  }

  onSumByChange = (propName) => {
    this.props.sumByChanged({
      by: propName,
    })
  }

  render() {
    const { aspects, sumBy } = this.props

    return (
      // TODO refactor css class names
      <div className="col-md-3 op-content-right_wrapper mod-statistics">
        <div className="aspects row">
          <ul className="op-content-right-statistics-list">
            <li className="op-content-right-statistics-list-item">
              <label className="op-content-right-statistics-list-item-name">
                <Translate i18nString="Show counts for" />
                <Select
                  className="op-select mod-rounded"
                  value={sumBy}
                  // @ts-ignore
                  onChange={this.onSumByChange}
                  // @ts-ignore
                  options={this.sumByOptions}
                />
              </label>
            </li>
            {aspects &&
              aspects.map((aspect) => <Aspect key={aspect.id} aspect={aspect} interest={getInterest(aspect)} />)}
          </ul>
        </div>
      </div>
    )
  }
}

// @ts-ignore
StatisticsAspects = connect(
  (state) => ({
    aspects: getAspects(state),
    sumBy: getCountBy(state),
  }),
  buildActionCreators({
    sumByChanged: ActionTypes.STATISTICS_COUNT_BY_CHANGED,
  }),
)(StatisticsAspects)

export default translate([], { wait: true })(StatisticsAspects)

import React from 'react'
import PropTypes from 'prop-types'
import R from 'ramda'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'

import buildActionCreators from '../../helpers/buildActionCreators'
import Checkbox from '../common/Checkbox'
import FeedEditorLine from './FeedEditorLine'
import GENERATED_BACKEND_TRANSLATIONS from '../../generated-backend-translations'
import Loader from '../common/Loader'
import Translate from '../../Translate'
import { getActiveProfileTagList } from '../../opoint/feeds/index'
import { getProfileTagList, getAbandonedSearchLine } from '../../selectors/searchSelectors'
import { getEditedFeed, getFeedUrlById } from '../../selectors/feedsSelector'
import * as ActionTypes from '../../constants/actionTypes'
import type { Feed, ProfileTag } from '../../opoint/flow'

type FeedProps = {
  activeFeed: Feed
  profileTagList: Array<ProfileTag>
  setActiveFeed: ({ id: number }) => void
  removeActiveFeed: (id: number) => void
  toggleFeedSettings: ({ setting: number }) => void
  params: any
  searchterm: string
  onChangeSearchterm: () => void
  currentFeedUrl: string
  abandonedSearchLine: Array<number>
  // feedSettings: { [key: $Keys<typeof GENERATED_BACKEND_TRANSLATIONS.feeds>]: boolean }
  // TODO fix typescript typing
  feedSettings: { [key: string]: boolean }
}

class FeedEditorComponent extends React.PureComponent<FeedProps> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  componentDidMount() {
    this.props.setActiveFeed({ id: this.props.params.feedId })
  }

  componentWillUnmount() {
    const { feedId } = this.props.params

    this.props.removeActiveFeed(feedId)
  }

  toggleFeedSettings = ({ target: { id } }) => {
    this.props.toggleFeedSettings({ setting: id })
  }

  // Used as prop for Reacttag autocomplete (FeedEditorLine) component to filter
  // suggestions based on searchterm
  filterSuggestions = (suggestions, searchterm) => {
    const re = new RegExp(searchterm, 'i')
    const filteredSuggestions = suggestions.filter((term) => re.test(term.name))
    const sortedSuggestions = filteredSuggestions.sort((a, b) => {
      const firstName = a.name.toLowerCase()
      const secondName = b.name.toLowerCase()

      if (firstName < secondName) {
        return -1
      }
      if (firstName > secondName) {
        return 1
      }
      return 0
    })
    return sortedSuggestions.map((suggestion) =>
      // TODO - this is a nasty hack, we should pass the type explicitely
      suggestion.hasOwnProperty('filtersUsed')
        ? { ...suggestion, name: `Profile: ${suggestion.name}` }
        : { ...suggestion, name: `Tag: ${suggestion.name}` },
    )
  }

  render() {
    const {
      activeFeed,
      profileTagList,
      searchterm,
      onChangeSearchterm,
      feedSettings,
      abandonedSearchLine,
      currentFeedUrl,
    } = this.props
    const { i18n } = this.context

    // Loading render must be called before activeProfileTagList otherwise
    // app can go to infinite loop as some data may not be fetched yet
    if (!activeFeed) {
      return (
        <div className="col-md-12  mod-full-height  mod-alerts">
          <Loader />
        </div>
      )
    }

    const activeIds = (activeFeed && activeFeed.mifluzIdLists) || abandonedSearchLine
    const activeProfileTagList = getActiveProfileTagList(profileTagList, activeIds)
    const feedViewUrl = (activeFeed && activeFeed.feedUrl) || (currentFeedUrl && `${currentFeedUrl}view/`)

    return (
      <div className="col-md-12  mod-full-height  mod-alerts">
        <div className="op-modal-feeds-list">
          <li className="op-modal-feeds-list-item animate mod-edit">
            <div className="op-modal-feeds-list-item-edit">
              <div className="col-md-12">
                <form name="feedForm" id="feedForm" className="row">
                  <div className="col-md-4">
                    <div className="row">
                      <div className="op-form-group">
                        <label htmlFor="name">
                          <Translate i18nString="Feed name" />:
                        </label>
                        <Field
                          id="name"
                          name="name"
                          component="input"
                          type="text"
                          placeholder={i18n.t('Enter feed name')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8  feed-options">
                    <div className="row">
                      <div className="op-form-group identical">
                        <label htmlFor="identical-articles">
                          <Translate i18nString="Include identical articles" />:
                        </label>
                        <div className="op-toggle">
                          <Field
                            id="identical-articles"
                            className="op-toggle-checkbox"
                            name="identicalArticles"
                            component="input"
                            type="checkbox"
                          />
                          <label className="op-toggle-label" htmlFor="identical-articles">
                            <span className="op-toggle-inner" />
                            <span className="op-toggle-switch" />
                          </label>
                        </div>
                      </div>
                      <div className="op-form-group pull-right">
                        <label htmlFor="type">
                          <Translate i18nString="Type" />:
                        </label>
                        <Field id="type" className="op-select mod-profile-editor" name="feedType" component="select">
                          <option value="rss">RSS</option>
                          <option value="json">JSON</option>
                          <option value="xml">XML</option>
                        </Field>
                      </div>
                      <div className="op-form-group articles-count">
                        <label htmlFor="articlesCount">
                          <Translate i18nString="Article count" />:
                        </label>
                        <Field
                          id="articlesCount"
                          name="articlesCount"
                          component="input"
                          type="number"
                          min="1"
                          max="100"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="row">
                      <div className="op-form-group">
                        <label htmlFor="description">
                          <Translate i18nString="Description" />:
                        </label>
                        <Field id="description" name="description" component="input" type="text" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7 col-md-push-1">
                    <div className="row">
                      <div className="op-form-group">
                        <label htmlFor="description">
                          <Translate i18nString="Content" />:
                        </label>
                        <FeedEditorLine
                          filters={activeProfileTagList}
                          suggestions={this.filterSuggestions(profileTagList, searchterm)}
                          searchterm={searchterm}
                          hint={searchterm}
                          onSearchTermChanged={onChangeSearchterm}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row feed-extra-options">
                      <div className="op-checkboxes-group">
                        <label htmlFor="description">
                          <Translate i18nString="Extra options" />:
                        </label>
                        {Object.keys(feedSettings).map((setting: string, index: number) => {
                          const settingValue = feedSettings[setting]
                          const label = i18n.t(GENERATED_BACKEND_TRANSLATIONS.feeds[setting]) || setting
                          return (
                            <Checkbox
                              key={index}
                              id={setting}
                              className="inline-checkbox"
                              isChecked={settingValue}
                              onChangeHandler={this.toggleFeedSettings}
                            >
                              {label}
                            </Checkbox>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row">
                      {activeFeed && (
                        <a href={feedViewUrl} target="_blank" rel="noopener noreferrer">
                          {feedViewUrl}
                        </a>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </li>
        </div>
      </div>
    )
  }
}

// TODO: refactor state selectors
const FeedEditorForm = reduxForm({
  form: 'feedEditor',
  keepDirtyOnReinitialize: false,
  enableReinitialize: true,
  // @ts-ignore
})(FeedEditorComponent)

const FeedEditor = connect(
  (state, props: any) => {
    const activeFeed = getEditedFeed(state)
    const searchterm = activeFeed ? activeFeed.searchterm : ''

    const initVal = activeFeed
      ? {
          name: activeFeed.name || '',
          description: activeFeed.description || '',
          articlesCount: activeFeed.articlesCount || '10',
          identicalArticles: activeFeed.identicalArticles || false,
          feedType: activeFeed.feedType || 'rss',
        }
      : null

    const feedSettings = activeFeed && activeFeed.feedSettings
    const isNewFeed = !props.params.feedId
    const eventualFeedSettings = isNewFeed
      ? R.merge(
          // For creation new feed use strings from backend and selected settings
          // @ts-ignore
          R.map((settingVal: string) => false, GENERATED_BACKEND_TRANSLATIONS.feeds),
          feedSettings,
        )
      : feedSettings

    return {
      activeFeed,
      searchterm,
      profileTagList: getProfileTagList(state),
      initialValues: initVal,
      feedSettings: eventualFeedSettings,
      abandonedSearchLine: getAbandonedSearchLine(state),
      currentFeedUrl: getFeedUrlById(props.params.feedId)(state),
    }
  },
  buildActionCreators({
    removeActiveFeed: ActionTypes.FEED_REMOVE_ACTIVE,
    setActiveFeed: ActionTypes.FEED_SET_ACTIVE,
    onChangeSearchterm: ActionTypes.FEED_SEARCHTERM_CHANGED,
    toggleFeedSettings: ActionTypes.FEED_TOGGLE_SETTING,
  }),
  // @ts-ignore
)(FeedEditorForm)

export default FeedEditor

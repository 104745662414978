import { ArticleMetadataType } from '../types/article'
import { FilterType, FilterItem } from '../types/settings'
import { ARTICLE_METADATA_CONFIG } from '../articles/ArticleMetadataBox'

export const IMAGE_URL_BASE = {
  old: 'http://pictures.opoint.com/',
  new: 'https://m360-dev.opoint.com/pictures/',
}

export const allMetadataOptions: Array<{
  name: string
  value: ArticleMetadataType
}> = Object.entries(ARTICLE_METADATA_CONFIG).map(([value, { title }]) => ({
  value: value as ArticleMetadataType,
  name: title(),
}))

export const allFilterOptions: FilterItem[] = [
  { value: 'timeperiod', name: 'Time Period' },
  { value: 'site', name: 'Site' },
  { value: 'rank', name: 'Rank' },
  { value: 'geo', name: 'Geo' },
  { value: 'lang', name: 'Lang' },
  { value: 'cov', name: 'Coverage' },
  { value: 'media', name: 'Media' },
  { value: 'content', name: 'Content' },
  { value: 'profiles', name: 'Profiles' },
  { value: 'tags', name: 'Tags' },
  { value: 'ent', name: 'Entities' },
  { value: 'topic', name: 'Topic' },
]

export const FILTERS_ORDER_DEFAULT: Array<FilterType> = [
  'timeperiod',
  'geo',
  'lang',
  'media',
  'content',
  'site',
  'rank',
  'cov',
  'profiles',
  'tags',
  'ent',
  'topic',
]

export const autoTranslateLanguages = [
  { en: 'English' },
  { de: 'German' },
  { fr: 'French' },
  { es: 'Spanish' },
  { it: 'Italian' },
  { no: 'Norwegian' },
  { sv: 'Swedish' },
  { da: 'Danish' },
  { fi: 'Finnish' },
  { zh: 'Chinese' },
  { ru: 'Russian' },
  { tr: 'Turkish' },
  { ko: 'Korean' },
  { ar: 'Arabic' },
  { pt: 'Portuguese' },
  { el: 'Greek' },
  { ja: 'Japanese' },
  { vi: 'Vietnamese' },
  { id: 'Indonesian' },
  { pl: 'Polish' },
  { nl: 'Dutch' },
  { hi: 'Hindi' },
  { ro: 'Romanian' },
  { bs: 'Bosnian' },
  { fa: 'Persian' },
  { th: 'Thai' },
  { sq: 'Albanian' },
  { hr: 'Croatian' },
  { cs: 'Czech' },
  { hu: 'Hungarian' },
  { bg: 'Bulgarian' },
  { uk: 'Ukrainian' },
  { sk: 'Slovak' },
  { mk: 'Macedonian' },
  { sl: 'Slovene' },
  { bn: 'Bengali' },
  { te: 'Telugu' },
  { lt: 'Lithuanian' },
  { ms: 'Malay' },
  { ca: 'Catalan' },
  { ml: 'Malayalam' },
  { ta: 'Tamil' },
  { lv: 'Latvian' },
  { az: 'Azerbaijani' },
  { et: 'Estonian' },
  { pa: 'Punjabi' },
  { ur: 'Urdu' },
  { ka: 'Georgian' },
  { gu: 'Gujarati' },
  { kn: 'Kannada' },
  { am: 'Amharic' },
  { gl: 'Galician' },
  { hy: 'Armenian' },
  { sr: 'Serbian' },
  { is: 'Icelandic' },
  { kk: 'Kazakh' },
  { eu: 'Basque' },
  { af: 'Afrikaans' },
  { so: 'Somali' },
  { mt: 'Maltese' },
  { sw: 'Swahili' },
  { mr: 'Marathi' },
  { uz: 'Uzbek' },
  { si: 'Sinhalese' },
  { tg: 'Tajik' },
  { cy: 'Welsh' },
  { my: 'Burmese' },
  { km: 'Khmer' },
  { tl: 'Tagalog' },
  { fy: 'Western Frisian' },
  { sm: 'Samisk' },
  { eo: 'Esperanto' },
  { mn: 'Mongolian' },
  { ha: 'Hausa' },
  { ga: 'Irish' },
  { be: 'Belarusian' },
  { ny: 'Chichewa' },
  { ps: 'Pashto' },
  { st: 'Southern Sotho' },
  { ht: 'Haitian' },
  { ky: 'Kirghiz' },
  { lb: 'Luxembourgish' },
  { lo: 'Lao' },
]

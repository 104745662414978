// @ts-nocheck
import React from 'react'
import PropTypes from 'prop-types'
import R from 'ramda'
import { connect } from 'react-redux'

import buildActionCreators from '../../helpers/buildActionCreators'
import ContactsFilter from './ContactsFilter'
import ReactTagsAutocomplete from '../../react-tags-autocomplete/src/ReactTagsAutocomplete'
import { emptyFunction } from '../../helpers/common'
import { getContactFilterName, getContactTypeAndName } from '../../opoint/contacts/index'
import { getContactsAndGroups } from '../../selectors/contactSelectors'
import { highlightSearched } from '../../opoint/common/highlight'
import * as ActionTypes from '../../constants/actionTypes'

//
// It's important for autocomplete
//
// Email may start with plus and be separated by space
const emailRegExp = /^((([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,}) ?)+$/i
// Phone number must be minimum 7 digit long
const phoneNumberContactRegExp = /^(\+?\d{7,} ?)+$/

type ContactsInputProps = {
  id: any
  contacts: Array<ContactFilter>
  contactsPath: string
  contactsSuggestions: Array<ContactFilter>
  suggestionWrapperStyle: any
  onContactFilterAdded: ({ filter: Filter, contactsPath: string }) => void // TODO flow @dmytro
  onContactFilterRemoved: ({ filter: Filter, contactsPath: string }) => void
  onContactFilterToggled: ({ filter: Filter, contactsPath: string }) => void
  onContactsFilterEmptied: ({ contactsPath: string }) => void
  tabIndex: number
  error?: boolean
}

type ContactsInputState = {
  expression: string
}

class ContactsInput extends React.PureComponent<ContactsInputProps, ContactsInputState> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      expression: '',
    }
  }

  onFilterToggled = (filter) => {
    const { contactsPath, onContactFilterToggled } = this.props
    onContactFilterToggled({ filter, contactsPath })
    this.setState({ expression: '' })
  }

  onFilterAdded = (filter) => {
    const { contactsPath, onContactFilterAdded } = this.props
    onContactFilterAdded({ filter, contactsPath })
    this.setState({ expression: '' })
  }

  onSearchtermChangedHandler = (searchterm: string) => {
    this.setState({ expression: searchterm })
  }

  onSuggestionClickHandler = (suggestion, onClickHandler) => () => onClickHandler(suggestion)

  getFilterId = (filter) => `${filter.type}_${filter.entity.id}`
  searchInputRef = null

  filterSuggestions(suggestions: Array<ContactFilter>, searchterm: string) {
    const searchword = searchterm.trim()
    let customSuggestions

    /**
     * If it is E-mail input, check if it contains @, if yes, then provide
     * E-mail suggestion for such term.
     * If it is Sms input, check if it is mobile number (starts with + and have numbers, or have
     * just numbers as an input) - if yes, provide Telephone number suggestion for it
     * #TODO @oliver maybe we should have some consistent naming for sharing?
     * It doesn't really matter what name is,
     * since it is always cleared and this is kinda messy in switch
     */
    switch (this.props.contactsPath) {
      case 'shareReportContacts':
      case 'shareArticlesContacts':
      case 'editedAlertEmail':
        customSuggestions = emailRegExp.test(searchword)
          ? { type: 'email', entity: { value: searchword, id: searchword } }
          : undefined
        break
      case 'editedAlertSms':
        customSuggestions = phoneNumberContactRegExp.test(searchword)
          ? { type: 'phoneNumber', entity: { value: searchword, id: searchword } }
          : undefined
        break
      default:
    }
    /**
     * Filter condition checks whether
     * 1) contact filter name contains searchterm
     * 2) If it is a group and contain searchterm then it is a suggestion.
     * 3) If it is a person contact, and contains searchterm checks if it is email or sms
     * 4) Checks if it even contains mobile/email, if not returns false
     * 5) If ye, checks if contact email/mobile contains searchterm, if yes returns true
     * #TODO @oliver refactor that
     * 4) returns false - we should just make it gray with some information (doesnt have
     * mobile number for example)
     * @type {Array.<ContactFilter>}
     */
    const contactSuggestions = suggestions.filter((contactFilter) => {
      const isContactName = R.contains(R.toLower(searchword.trim()), R.toLower(getContactFilterName(contactFilter)))
      if (contactFilter.type === 'person') {
        switch (this.props.contactsPath) {
          case 'shareReportContacts':
          case 'shareArticlesContacts':
          case 'editedAlertEmail':
            if (contactFilter.entity.email) {
              const containsEmail = R.contains(R.toLower(searchword.trim()), R.toLower(contactFilter.entity.email))
              return isContactName || containsEmail
            }
            return false

          case 'editedAlertSms':
            if (contactFilter.entity.mobile) {
              const containsNumber = R.contains(R.toLower(searchword.trim()), contactFilter.entity.mobile)
              return isContactName || containsNumber
            }
            return false

          default:
        }
      }
      return isContactName
    })
    return customSuggestions ? R.prepend(customSuggestions, contactSuggestions) : contactSuggestions
  }

  suggestionComponentHandler = ({ suggestion, suggestion: filter, onClickHandler }) => {
    const { i18n } = this.context

    const { type, name } = getContactTypeAndName(filter)

    return (
      <span className="auto-item" onClick={this.onSuggestionClickHandler(suggestion, onClickHandler)}>
        <span className="auto-item__type">
          {i18n.t(type)}
          {': '}
        </span>
        <span
          className="auto-item__name"
          dangerouslySetInnerHTML={{ __html: highlightSearched(name, this.state.expression) }}
        />
      </span>
    )
  }

  clearAllHandler = () => {
    const { contactsPath, onContactsFilterEmptied } = this.props
    this.setState({ expression: '' })
    onContactsFilterEmptied({ contactsPath })
    this.searchInputRef.focusTextArea()
  }

  removeFilterHandler = (filter) => {
    const { contactsPath, onContactFilterRemoved } = this.props
    onContactFilterRemoved({ filter, contactsPath })
  }

  isExpressionEmpty = () => {
    const { expression } = this.state
    return expression === ''
  }

  deleteHandler = (filter) => {
    const { onContactFilterRemoved, contactsPath } = this.props
    onContactFilterRemoved({ filter, contactsPath })
  }

  render() {
    const { id, contactsSuggestions, contacts, tabIndex, suggestionWrapperStyle, error } = this.props
    const { expression } = this.state
    const { i18n } = this.context
    const suggestions = this.filterSuggestions(contactsSuggestions, expression)

    const ContactsFilterComponent = (props) => <ContactsFilter {...props} deleteHandler={this.deleteHandler} />

    return (
      <ReactTagsAutocomplete
        ref={(ref) => {
          this.searchInputRef = ref
        }}
        id={id}
        typeComparator={emptyFunction}
        searchterm={expression}
        onSearchTermChanged={this.onSearchtermChangedHandler}
        triggerSearch={emptyFunction}
        placeholder={i18n.t('Enter recipients')}
        filterId={this.getFilterId}
        onLoadSuggestions={emptyFunction}
        suggestions={suggestions}
        filters={contacts}
        tabIndex={tabIndex}
        filterComponent={ContactsFilterComponent}
        suggestionComponent={this.suggestionComponentHandler}
        clearAllHandler={this.clearAllHandler}
        onFilterToggled={this.onFilterToggled}
        onFilterAdded={this.onFilterAdded}
        removeFilter={this.removeFilterHandler}
        suggestionWrapperStyle={suggestionWrapperStyle}
        isExpressionEmpty={this.isExpressionEmpty}
        autoSuggestion
        error={error}
      />
    )
  }
}

/**
 * @param contactsPath - path to where in a store contacts are located
 */
const contactsForm = (contactsPath: string, tabIndex: number | void = undefined) =>
  class extends React.Component<ContactsInputProps> {
    constructor(props: ContactsInputProps) {
      super(props)
      const pathLens = R.lensPath(contactsPath.split('.'))
      this.ContactsComponent = connect(
        (state) => ({
          contactsSuggestions: getContactsAndGroups(state),
          contacts: R.view(pathLens, state.contacts),
          contactsPath,
          tabIndex,
        }),
        buildActionCreators({
          onContactFilterToggled: ActionTypes.ON_CONTACT_FILTER_TOGGLED,
          onContactFilterAdded: ActionTypes.ON_CONTACT_FILTER_ADDED,
          onContactFilterRemoved: ActionTypes.ON_CONTACT_FILTER_REMOVED,
          onContactsFilterEmptied: ActionTypes.ON_CONTACTS_FILTER_EMPTIED,
        }),
      )(ContactsInput)
    }

    render() {
      return <this.ContactsComponent {...this.props} />
    }
  }

export default contactsForm

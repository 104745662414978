import React from 'react'
import classNames from 'classnames'

type Props = {
  activeStep: 0 | 1 | 2 | 3 | 4
  click: (param: any) => void
  reportSteps: Array<{ label: React.ReactElement; disabled?: boolean }>
  disabledSteps: Array<number>
}

type ArrowProps = {
  click: () => void
  type: 'left' | 'right'
}

// type ItemProps = {
//   active: boolean,
//   disabled: boolean,
//   click: () => void,
//   label: React$Element<any>
// }

const Arrow = ({ type, click }: ArrowProps) => (
  <div className={classNames('op-modal-report-navigator-arrow util_center-parent', type)} onClick={click}>
    <div className="util_center-child">
      <i className={classNames(`op-icon-arrow-big-${type}`)} />
    </div>
  </div>
)

const Item = ({ active, label, click, disabled }) => (
  <li
    className={classNames('op-modal-report-navigator-list-item', { active, 'mod-disabled': disabled })}
    onClick={!disabled ? click : undefined}
  >
    <div className="util_center-parent">
      <div className="util_center-child">{label}</div>
    </div>
  </li>
)

export default class ReportsNavigator extends React.PureComponent<Props> {
  prev = () => {
    const { activeStep, disabledSteps, click } = this.props
    const prevStep = activeStep >= 1 ? activeStep - 1 : activeStep

    if (disabledSteps.includes(prevStep)) {
      return
    }

    click(prevStep)
  }

  next = () => {
    const { activeStep, reportSteps, disabledSteps, click } = this.props
    const nextStep = activeStep < reportSteps.length - 1 ? activeStep + 1 : activeStep

    if (disabledSteps.includes(nextStep)) {
      return
    }

    click(nextStep)
  }

  step = (step: number) => {
    const { click } = this.props
    return () => click(step)
  }

  render() {
    const { activeStep, reportSteps, disabledSteps } = this.props
    return (
      <div className="op-modal-report-navigator">
        <Arrow type="left" click={this.prev} />
        <ul className="op-modal-report-navigator-list">
          <li
            className={classNames('op-modal-report-navigator-list-item selector', {
              'is-first': activeStep === 0,
              'is-second': activeStep === 1,
              'is-three': activeStep === 2,
              'is-four': activeStep === 3,
              'is-five': activeStep === 4,
            })}
          />
          {reportSteps &&
            reportSteps.map(({ label }, index) => (
              <Item
                key={index}
                active={activeStep === index}
                disabled={disabledSteps.includes(index)}
                label={label}
                click={this.step(index)}
              />
            ))}
        </ul>
        <Arrow type="right" click={this.next} />
      </div>
    )
  }
}

import React from 'react'
import classNames from 'classnames'

import FiltersMoreRow from './FilterMoreRow'
import Translate from '../common/Translate'
import styles from './index.module.scss'
import Icon from '../common/Icon'

class FiltersMoreColumn extends React.PureComponent<any> {
  render() {
    const { filters, isSingleColumn, filterName, width, fetchMoreFilters, action, ...props } = this.props

    if (isSingleColumn) {
      const formattedFilters = []
      for (let i = 0; i < filters.length; i += 10) {
        formattedFilters.push(filters.slice(i, i + 10))
      }

      return formattedFilters.map((group, index) => (
        <div
          key={index}
          className={classNames(
            styles.filterColumn,
            {
              [styles.width2]: width === 2,
            },
            {
              [styles.width3]: width === 3,
            },
          )}
        >
          <ul>
            {group.map((filter) => (
              <FiltersMoreRow key={filter.id} filter={filter} {...props} />
            ))}
          </ul>
        </div>
      ))
    }

    return (
      <div
        className={classNames(
          styles.filterColumn,
          {
            [styles.width2]: width === 2,
          },
          {
            [styles.width3]: width === 3,
          },
        )}
      >
        <button
          onClick={(e) => {
            e.preventDefault()
            fetchMoreFilters({
              header: filterName,
              action,
              width,
            })
          }}
          disabled={!action || action === '0'}
          className={styles.header}
        >
          <span className={styles.title}>
            <Translate text={filterName} />
          </span>
          {action && action !== '0' && <Icon name="plus" verticalCenter />}
        </button>
        <ul>
          {filters.map((filter) => (
            <FiltersMoreRow key={filter.id} filter={filter} {...props} />
          ))}
        </ul>
      </div>
    )
  }
}

export { FiltersMoreColumn }

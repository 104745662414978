import React from 'react'
import { connect } from 'react-redux'

import buildActionCreators from '../../../helpers/buildActionCreators'
import Statistics from '../index'
import { getRoutSearch } from '../../../selectors/routingSelectors'
import { getSearchMeta, isSearchInProgress } from '../../../selectors/searchSelectors'
import { isStatLoading, getShowFilteredArticles } from '../../../selectors/statisticsSelectors'
import * as ActionTypes from '../../../constants/actionTypes'

type Props = {
  hideFiltered: () => void
  setActiveView: (obj: { id: number | null }) => void
  clearFilteredArticles: () => void
  clearFilters: () => void
  routeSearch: string
  loading: boolean
  fetchStatistics: () => void
}

// TODO @oliver make separate action for unmounting
class StatisticView extends React.PureComponent<Props> {
  componentDidUpdate(prevProps) {
    const { routeSearch, clearFilters, loading, fetchStatistics } = this.props
    if (routeSearch !== prevProps.routeSearch) {
      clearFilters()
      if (loading === false) {
        fetchStatistics()
      }
    }
  }

  componentWillUnmount() {
    const { setActiveView, hideFiltered, clearFilteredArticles, clearFilters } = this.props
    setActiveView({ id: null })
    hideFiltered()
    clearFilteredArticles()
    clearFilters()
  }

  render() {
    return (
      <div className="col-md-12 mod-full-height">
        <Statistics {...this.props} />
      </div>
    )
  }
}

// @ts-ignore
StatisticView = connect(
  (state) => ({
    routeSearch: getRoutSearch(state),
    meta: getSearchMeta(state),
    loading: isStatLoading(state),
    showFilteredArticles: getShowFilteredArticles(state),
    searchInProgress: isSearchInProgress(state),
  }),
  buildActionCreators({
    fetchStatistics: ActionTypes.FETCH_STATISTICS,
    hideFiltered: ActionTypes.STATISTICS_HIDE_FILTERED,
    setActiveView: ActionTypes.STATISTICS_VIEWS_SET_ACTIVE,
    clearFilteredArticles: ActionTypes.STATISTICS_CLEAR_FILTERED_ARTICLES,
    clearFilters: ActionTypes.STATISTICS_CLEAR_FILTERS,
    searchChangeDateRange: ActionTypes.SEARCH_CHANGE_DATERANGE,
    extendTimeRangeAndFetch: ActionTypes.STATISTICS_EXTEND_TIME_RANGE,
  }),
)(StatisticView)

export default StatisticView

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'

import buildActionCreators from '../../../helpers/buildActionCreators'
import TemplateSelector from '../TemplateSelector'
import {
  getActiveTemplate,
  getGroupedTemplatesWithoutExcluded,
  getTemplatePreview,
  isPreviewModalOpen,
} from '../../../selectors/templatesSelectors'
import * as ActionTypes from '../../../constants/actionTypes'
import type { ActiveTemplate, Template } from '../../../opoint/flow'

type Props = {
  activeTemplate: ActiveTemplate
  groupedTemplates: any
  isPreviewModalOpen: boolean
  templatePreview?: string
  openPreviewModal: () => void
  openEditorModal: () => void
  closePreviewModal: () => void
  fetchTemplate: () => void
  updateActiveTemplate: () => void
  deleteTemplate: () => void
}

class TemplateEditorFormWrapper extends React.PureComponent<Props> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  componentWillMount() {
    const { groupedTemplates } = this.props
    if (groupedTemplates && Object.values(groupedTemplates)[0]) {
      // @ts-ignore
      const { id, type, readonly } = Object.values(groupedTemplates)[0][0]
      // @ts-ignore
      this.templateChange({ id, type, readonly })
    }
  }

  templateChange = (template: Template) => {
    const { id, type, readonly } = template
    // @ts-ignore
    this.props.updateActiveTemplate({ activeTemplate: { id, type, readonly } })
    // @ts-ignore
    this.props.fetchTemplate({ id })
  }

  render() {
    const {
      activeTemplate,
      openPreviewModal,
      openEditorModal,
      closePreviewModal,
      groupedTemplates,
      isPreviewModalOpen /* eslint-disable-line no-shadow */,
      templatePreview, // TEMPLATE SELECTOR PROPS
      deleteTemplate,
    } = this.props

    const { i18n } = this.context

    return (
      <div>
        {activeTemplate && (
          // @ts-ignore
          <TemplateSelector
            activeTemplate={activeTemplate}
            closePreviewModal={closePreviewModal}
            groupedTemplates={groupedTemplates}
            modalTitle={i18n.t('Select template to modify')}
            // @ts-ignore
            onTemplateChange={this.templateChange}
            openPreviewModal={openPreviewModal}
            openEditorModal={openEditorModal}
            isPreviewModalOpen={isPreviewModalOpen}
            previewModalContent={templatePreview}
            showPreviewButtonTitle={i18n.t('Preview')}
            deleteTemplate={deleteTemplate}
            isEditable
          />
        )}
      </div>
    )
  }
}

// @ts-ignore
TemplateEditorFormWrapper = translate([], { wait: true })(TemplateEditorFormWrapper)

// @ts-ignore
TemplateEditorFormWrapper = connect(
  (state) => ({
    activeTemplate: getActiveTemplate(state),
    groupedTemplates: getGroupedTemplatesWithoutExcluded(state),
    isPreviewModalOpen: isPreviewModalOpen(state),
    templatePreview: getTemplatePreview(state),
  }),
  buildActionCreators({
    fetchTemplate: ActionTypes.FETCH_TEMPLATE_DETAIL,
    openEditorModal: ActionTypes.TEMPLATE_EDITOR_FORM_MODAL_OPEN,
    openPreviewModal: ActionTypes.TEMPLATE_PREVIEW_MODAL_OPEN,
    closePreviewModal: ActionTypes.TEMPLATE_PREVIEW_MODAL_CLOSE,
    deleteTemplate: ActionTypes.TEMPLATE_DELETE,
    // This needs to be done because we need id in store
    updateActiveTemplate: ActionTypes.UPDATE_ACTIVE_TEMPLATE,
    // @ts-ignore
  }),
)(TemplateEditorFormWrapper)

export default TemplateEditorFormWrapper

import React, { ReactElement } from 'react'

import styles from './index.module.scss'
import Translate from '../common/Translate'

interface Props {
  title?: string
  children: ReactElement[] | ReactElement
}

export const SettingTitle = ({ text }: { text: string }) => (
  <h3 className={styles.settingTitle}>
    <Translate text={text} />
  </h3>
)

export default function SettingWrapper({ title, children }: Props) {
  return (
    <div className={styles.settingWrapper}>
      {title && <SettingTitle text={title} />}
      <div className={styles.childrenWrapper}>{children}</div>
    </div>
  )
}

// @ts-nocheck
import { connect } from 'react-redux'
import { DragDropContext } from 'react-dnd'
import classNames from 'classnames'
import HTML5Backend from 'react-dnd-html5-backend'
import PopperJS from 'popper.js'
import PropTypes from 'prop-types'
import R from 'ramda'
import React, { Component } from 'react'

import AddArticleModal from './articles/modal/AddArticleModal'
import AddTagPopover from './tags/ManageTags/AddTagPopover'
import BadBrowserPopover from './ui/BadBrowserPopover'
import buildActionCreators from '../helpers/buildActionCreators'
import ContactModal from '../components/contacts/Modal'
import SearchDateRangeModal from '../components/common/SearchDateRangeModal'
import EditArticleModal from './articles/editArticle/EditArticleModal'
import EditTagPopover from './tags/ManageTags/EditTagPopover'
import EditTemplateModal from './templates/TemplateEditor/EditTemplateModal'
import EditViewPopover from './statistics/StatisticsViews/EditView'
import EntityListing from './entityListing'
import FilterDetailModal from './search/FilterDetailModal'
import ExportPdfModal from '../new-components/statistics/export-pdf'
import HelpModal from '../new-components/help'
import LeaveEditedAlertModal from './alerts/AlertForm/LeaveEditedAlertModal'
import ManageProfilesModal from '../new-components/profile/manage'
import NotificationDownloadModal from './notifications/Modal'
import OpointHeader from './ui/header'
import RemoveArticleModal from './alerts/RemoveArticle/Modal'
import ReportsHistoryModal from './reportsHistory/Modal'
import ReportsModal from '../components/reports/Modal'
import SettingsModal from '../new-components/settings/Modal'
import ShareArticleModal from './articles/modal/ShareArticleModal'
import SortableModuleModal from './templates/TemplateEditor/SortableModuleModal'
import SourceListModal from '../new-components/source-list'
import TemplateEditor from './templates/TemplateEditor/Modal'
import Translate from '../Translate'
import ProfileDeleteConfirmationModal from '../new-components/profile/delete'
import UserLoggedOutModal from './ui/UserLoggedOutModal'
import ReleaseNotesModal from '../new-components/release-notes'
import { getArticleListingStyle, getUISetting, isUserArchiveOnly } from '../selectors/settingsSelectors'
import { isSearchInProgress, isSearchIsTakingTooLong } from '../selectors/searchSelectors'
import { LISTING_STYLES } from '../opoint/settings'
import * as ActionTypes from '../constants/actionTypes'
import type { AppLocation, AppRoute, articleListingType, PopoverConfig, SettingTypes } from '../opoint/flow'
import SearchInputWrapper from './ui/SearchInputWrapper'
import FiltersPanelSearchWrapped from '../new-components/filters/FilterPanel'
import FiltersDropDownButton from './search/FiltersDropDownButton'
import SearchImage from '../new-components/DecorativeComponents/SearchImage'

type AppProps = {
  articleListingStyle: articleListingType
  cancelSearch: () => void
  dispatch: Function
  location: AppLocation
  main: React.DOM
  params: Object
  rightColumn: React.DOM
  route: AppRoute
  routeParams: Object
  router: Object
  routes: Array<AppRoute>
  searchInProgress: boolean
  searchIsTakingTooLong: boolean
  settingsFetched: boolean
  sidebarShown: boolean
  isUserArchiveOnly: boolean
}

type AppState = {
  addTagPopover?: Object
  editTagPopover?: Object
  editViewPopover?: any
}

class App extends Component<AppProps, AppState> {
  static childContextTypes = {
    destroyAddTagPopover: PropTypes.func,
    destroyEditTagPopover: PropTypes.func,
    destroyEditViewPopover: PropTypes.func,
    showAddTagPopover: PropTypes.func,
    showEditTagPopover: PropTypes.func,
    showEditViewPopover: PropTypes.func,
  }

  constructor() {
    super()

    this.popoverConfig = {
      placement: 'right',
      modifiers: {
        flip: {
          behavior: ['right', 'left', 'top', 'bottom'],
          boundariesElement: 'window',
        },
        preventOverflow: {
          boundariesElement: 'window',
          padding: 0,
        },
      },
    }

    this.state = {
      addTagPopover: null,
      editTagPopover: null,
    }

    this.clickCallbackList = [this.addTagClickOutside, this.editTagClickOutside, this.editViewClickOutside]

    this.addTagPopoverRef = null
    this.addTagRef = null
    this.editTagPopoverRef = null
    this.editTagRef = null
    this.editViewPopoverRef = null
    this.editViewRef = null
  }

  getChildContext() {
    return {
      destroyAddTagPopover: this.destroyAddTagPopover,
      destroyEditTagPopover: this.destroyEditTagPopover,
      destroyEditViewPopover: this.destroyEditViewPopover,
      showAddTagPopover: this.showAddTagPopover,
      showEditTagPopover: this.showEditTagPopover,
      showEditViewPopover: this.showEditViewPopover,
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.callClickCallbacks, false)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.callClickCallbacks, false)
  }

  addTagPopoverRef: HTMLDivElement
  addTagRef: HTMLSpanElement
  editTagPopoverRef: HTMLDivElement
  editTagRef: HTMLButtonElement
  editViewPopoverRef: HTMLDivElement
  editViewRef: HTMLSpanElement

  clickCallbackList: Array<Function>
  popoverConfig: PopoverConfig

  callClickCallbacks = (e) => {
    this.clickCallbackList.map((clickCallback) => clickCallback.bind(this)(e))
  }

  addTagClickOutside({ target }: Object) {
    if (
      this.addTagRef &&
      this.addTagPopoverRef &&
      !this.addTagPopoverRef.contains(target) &&
      !this.addTagRef.contains(target) &&
      document.body.contains(target)
    ) {
      this.destroyAddTagPopover()
    }
  }

  editTagClickOutside({ target }: Object) {
    if (
      this.editTagRef &&
      this.editTagPopoverRef &&
      !this.editTagPopoverRef.contains(target) &&
      !this.editTagRef.contains(target) &&
      document.body.contains(target)
    ) {
      this.destroyEditTagPopover()
    }
  }

  showAddTagPopover = ({ target }: Object) => {
    this.destroyAddTagPopover()
    this.addTagRef = target

    this.setState({
      addTagPopover: new PopperJS(target, this.addTagPopoverRef, this.popoverConfig),
    })
  }

  showEditTagPopover = ({ target }: Object) => {
    this.destroyEditTagPopover()
    this.editTagRef = target

    this.setState({
      editTagPopover: new PopperJS(target, this.editTagPopoverRef, this.popoverConfig),
    })
  }

  showEditViewPopover = ({ target }: Object) => {
    this.destroyEditViewPopover()
    this.editViewRef = target

    this.setState({
      editViewPopover: new PopperJS(
        target,
        this.editViewPopoverRef,
        R.merge(this.popoverConfig, { placement: 'bottom' }),
      ),
    })
  }

  editViewClickOutside({ target }: Object) {
    if (
      this.editViewRef &&
      this.editViewPopoverRef &&
      !this.editViewPopoverRef.contains(target) &&
      !this.editViewRef.contains(target) &&
      document.body.contains(target)
    ) {
      this.destroyEditViewPopover()
    }
  }

  destroyAddTagPopover = () => {
    const { addTagPopover: popperInstance } = this.state

    this.setState({ addTagPopover: null })

    popperInstance && popperInstance.destroy()
  }

  destroyEditTagPopover = () => {
    const { editTagPopover: popperInstance } = this.state

    this.setState({ editTagPopover: null })

    popperInstance && popperInstance.destroy()
  }

  destroyEditViewPopover = () => {
    const { editViewPopover: popperInstance } = this.state

    this.setState({ editViewPopover: null })

    popperInstance && popperInstance.destroy()
  }

  cancelSearch = () => {
    this.props.cancelSearch()
  }

  handleFilterToggle = () => {
    this.props.onFilterPanelToggle({ searchterm: this.props.searchterm })
  }

  render() {
    const { addTagPopover, editTagPopover, editViewPopover } = this.state
    const { articleListingStyle, sidebarShown, searchIsTakingTooLong, isUserArchiveOnly } = this.props

    if (articleListingStyle && articleListingStyle === LISTING_STYLES.EMAIL) {
      document.body.classList.add('op-mode-articles-left-side-listing')
    } else {
      document.body.classList.remove('op-mode-articles-left-side-listing')
    }

    if (
      (articleListingStyle && articleListingStyle === LISTING_STYLES.ARCHIVE_LEFT) ||
      articleListingStyle === LISTING_STYLES.INTRO
    ) {
      document.body.classList.add('op-mode-articles-archive-side-listing')
    } else {
      document.body.classList.remove('op-mode-articles-archive-side-listing')
    }

    if (articleListingStyle && articleListingStyle === LISTING_STYLES.ARCHIVE_RIGHT) {
      document.body.classList.add('op-mode-articles-archive-side-listing-right')
    } else {
      document.body.classList.remove('op-mode-articles-archive-side-listing-right')
    }

    const searchHome: boolean =
      ['/search/', '/search'].includes(this.props.location.pathname) &&
      Object.entries(this.props.location.query).length === 0

    const isRegularUserOrNotHomepage = !isUserArchiveOnly || !searchHome

    return (
      <div
        className={classNames('container-fluid mod-full-height the-app', {
          'is-menu-hidden': (isUserArchiveOnly && searchHome) || !sidebarShown,
        })}
      >
        <div className="row mod-full-height">
          <div className="col-md-11 op-content_wrapper">
            <OpointHeader
              showEntitiesToggle={isRegularUserOrNotHomepage}
              showSearch={isRegularUserOrNotHomepage}
              showToolbar={!searchHome}
            />

            <div className="row  mod-height-adjust">
              <div
                className="popupWrapper"
                ref={(ref) => {
                  this.addTagPopoverRef = ref
                }}
              >
                {addTagPopover && <AddTagPopover />}
              </div>
              <div
                className="popupWrapper"
                ref={(ref) => {
                  this.editTagPopoverRef = ref
                }}
              >
                {editTagPopover && <EditTagPopover />}
              </div>
              <div
                className="popupWrapper"
                ref={(ref) => {
                  this.editViewPopoverRef = ref
                }}
              >
                {editViewPopover && <EditViewPopover />}
              </div>

              <BadBrowserPopover />

              {searchHome && isUserArchiveOnly ? (
                <React.Fragment>
                  <EntityListing />
                  <SearchImage />
                  <div className="col-md-10  mod-full-height  op-content-center-toolbar-left">
                    <div className="row mod-full-height">
                      <div className="col-md-2" />
                      <div
                        className="col-md-8"
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginTop: 30,
                          paddingLeft: 0,
                          paddingRight: 0,
                        }}
                      >
                        <div style={{ width: '100%' }}>
                          <SearchInputWrapper withoutNotificationBaloon />
                        </div>
                        <FiltersDropDownButton handleFilterToggle={this.handleFilterToggle} />
                        <FiltersPanelSearchWrapped />
                      </div>
                      <div className="col-md-2" />
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <EntityListing />
                  <div className="col-md-10  mod-full-height  op-content-center-toolbar-left">
                    <div className="row mod-full-height">
                      <div className="col-md-10 op-content-center_wrapper">
                        <div className="row mod-full-height">
                          <div className="op-content-article_wrapper util_col-centered mod-height-toolbar-fix">
                            <div className="col-md-12 mod-full-height">
                              <div className="row mod-full-height u-overflow--hidden">
                                {/* {searchInProgress && <Loader />} */}
                                {searchIsTakingTooLong && (
                                  <div className="op-content-article-listing-info">
                                    <button onClick={this.cancelSearch} className="op-button-load-more">
                                      <Translate i18nString="Cancel search" />
                                    </button>
                                  </div>
                                )}
                                {this.props.main}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Right column */}
                      {this.props.rightColumn}
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>

        {/* <NewVersionAlertTrigger /> */}
        <AddArticleModal />
        <ContactModal />
        <SearchDateRangeModal />
        <EditArticleModal />
        <EditTemplateModal />
        <FilterDetailModal />
        <ExportPdfModal />
        <HelpModal />
        <LeaveEditedAlertModal />
        <ManageProfilesModal />
        <NotificationDownloadModal />
        <ProfileDeleteConfirmationModal />
        <RemoveArticleModal />
        <ReportsHistoryModal />
        <ReportsModal />
        <SettingsModal />
        <ShareArticleModal />
        <SortableModuleModal />
        <SourceListModal />
        <TemplateEditor />
        <UserLoggedOutModal />
        <ReleaseNotesModal />
      </div>
    )
  }
}

App = connect(
  (state) => ({
    articleListingStyle: getArticleListingStyle(state),
    searchInProgress: isSearchInProgress(state),
    searchIsTakingTooLong: isSearchIsTakingTooLong(state),
    sidebarShown: getUISetting('SIDEBAR_SHOWN')(state),
    isUserArchiveOnly: isUserArchiveOnly(state),
  }),
  buildActionCreators({
    cancelSearch: ActionTypes.CANCEL_SEARCH,
    onFilterPanelToggle: ActionTypes.FILTERS_PANEL_TOGGLE,
  }),
)(App)

export default DragDropContext(HTML5Backend)(App)

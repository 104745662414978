// @ts-nocheck
import Rx from 'rxjs'

import * as ActionTypes from '../constants/actionTypes'
import { getNotificationsCount, getNotifications } from '../opoint/notifications'
import { getNotificationsPage } from '../selectors/notificationsSelectors'
import buildAction from '../helpers/buildAction'
import { notificationSocket } from '../opoint/notifications/socket'
import { isReportModalOpen } from '../selectors/uiSelectors'
import { logOutOnExpiredToken, serverIsDown } from './configureEpics'

const getNotificationsCountEpic = (action$, { getState }) =>
  action$.ofType(ActionTypes.LOG_IN_SUCCESS).switchMap(({ payload }) =>
    Rx.Observable.from(getNotificationsCount())
      .map((data) => buildAction(ActionTypes.NOTIFICATIONS_COUNT_FETCH_SUCCESS, data))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.NOTIFICATIONS_FETCH_FAILURE))),
  )

const resetNotificationsCountEpic = (action$, { getState }) =>
  action$.ofType(ActionTypes.NOTIFICATIONS_OPEN).switchMap(({ payload }) =>
    Rx.Observable.from(getNotificationsCount('post'))
      .map((data) => buildAction(ActionTypes.NOTIFICATIONS_COUNT_FETCH_SUCCESS, data))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.NOTIFICATIONS_FETCH_FAILURE))),
  )

const getNotificationsEpic = (action$, { getState }) =>
  action$
    .filter(({ type }) => [ActionTypes.LOG_IN_SUCCESS, ActionTypes.NOTIFICATIONS_FETCH].includes(type))
    .switchMap(({ payload }) =>
      Rx.Observable.from(getNotifications(getNotificationsPage(getState())))
        .map((data) => buildAction(ActionTypes.NOTIFICATIONS_FETCH_SUCCESS, data))
        .catch(logOutOnExpiredToken)
        .catch(serverIsDown)
        .catch(() => Rx.Observable.of(buildAction(ActionTypes.NOTIFICATIONS_FETCH_FAILURE))),
    )

const socketEpic = (action$, { getState }) =>
  action$
    .ofType(ActionTypes.NOTIFICATIONS_FETCH_SUCCESS)
    .first()
    .switchMap(() => {
      const webSocket = notificationSocket()
      return Rx.Observable.from(webSocket)
        .switchMap((data) => {
          const state = getState()

          // this is not bullet proof, but for now it's good enough
          if (isReportModalOpen(state)) {
            return Rx.Observable.of(buildAction(ActionTypes.NOTIFICATIONS_SOCKET_SUCCESS, data))
          }

          return Rx.Observable.of(
            buildAction(ActionTypes.NOTIFICATIONS_INC_COUNT),
            buildAction(ActionTypes.NOTIFICATIONS_SOCKET_SUCCESS, data),
          )
        })
        .catch(logOutOnExpiredToken)
        .catch(serverIsDown)
        .catch(() => Rx.Observable.of(buildAction(ActionTypes.NOTIFICATIONS_FETCH_FAILURE)))
    })

export default [getNotificationsCountEpic, getNotificationsEpic, resetNotificationsCountEpic, socketEpic]

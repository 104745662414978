import React from 'react'

import Translate from '../../../Translate'
import type { TemplatePreview } from '../../../opoint/flow'

type Props = {
  hasNext: boolean
  preview: TemplatePreview
  recipients: Array<string>
  alertId: number
}

export default function NextAlert(props: Props) {
  const { hasNext, preview, recipients } = props
  const recipientsString = recipients && recipients.join(', ')

  return (
    <div className="mod-alert-full-height">
      <div className="sent-alert-header" title={recipientsString}>
        <strong>
          <Translate i18nString="Will be sent to " />
        </strong>
        <span>{recipientsString}</span>
      </div>
      {preview.preview && !hasNext && (
        <div className="next-alert-is-preview">
          <Translate i18nString="This alert has no new content, the following preview is an illustration" />.
        </div>
      )}
      <div id="preview-wrapper" className="mod-alert-full-height">
        {preview.preview ? (
          <iframe src={preview.preview} title="Preview" />
        ) : (
          // A little bit of hacks with CSS to show vertical scrollbar
          // @ts-ignore
          <div className="inner-container" dangerouslySetInnerHTML={{ __html: preview }} />
        )}
      </div>
    </div>
  )
}

import React from 'react'
import className from 'classnames'
import { OverlayTrigger } from 'react-bootstrap'

import type { Article } from '../../../opoint/flow'
import SelectArticleCheckbox from '../SelectArticleCheckbox'

interface Props {
  isSticky: boolean
  isAtTheBottom: boolean
  getRef: (ref: any) => void
  shareButton?: {
    tooltip: any
    onClick: () => void
  }
  editButton?: {
    tooltip: any
    onClick: () => void
  }
  selectCheckbox?: {
    onChange: () => void
    article: Article
    checked: boolean
  }
}

export default function ArticleSideButtons({
  isSticky,
  isAtTheBottom,
  getRef,
  shareButton,
  editButton,
  selectCheckbox,
}: Props) {
  return (
    <div
      className={className(
        'op-content-article-listing-article-left-side-content',
        isSticky ? 'is-sticky' : '',
        isAtTheBottom ? 'at-the-bottom' : '',
      )}
      ref={getRef}
    >
      {selectCheckbox && (
        <SelectArticleCheckbox
          onChange={selectCheckbox.onChange}
          article={selectCheckbox.article}
          checked={selectCheckbox.checked}
        />
      )}
      {shareButton && (
        <OverlayTrigger placement="top" overlay={shareButton.tooltip}>
          <button className="op-content-article-listing-article-left-side-button" onClick={shareButton.onClick}>
            <span aria-describedby="shareTooltip">
              <i className="op-icon-redo2" />
            </span>
          </button>
        </OverlayTrigger>
      )}
      {editButton && (
        <OverlayTrigger placement="top" overlay={editButton.tooltip}>
          <button className="op-content-article-listing-article-left-side-button" onClick={editButton.onClick}>
            <span aria-describedby="editTooltip">
              <i className="op-icon-pencil" />
            </span>
          </button>
        </OverlayTrigger>
      )}
    </div>
  )
}

// @ts-nocheck
import React from 'react'
import className from 'classnames'

import { shouldHaveStickyLeftSide } from '../../opoint/articles/index'
import ArticleFooter from './common/ArticleFooter'
import ArticleHeaderText from './common/ArticleHeaderText'
import ArticleSimpleBodyComponent from './common/ArticleSimpleBodyComponent'
import ArticleSourceLine from './common/ArticleSourceLine'
import DeletedArticle from './common/DeletedArticle'
import { Colorbar } from './common/colorbar'
import ArticleMetadata from './common/ArticleMetadata'
import ArticleSideButtons from './common/ArticleSideButtons'
import { CONTENT_HEIGHT_THRESHOLD } from '../../constants'

class SimpleListingArticleComponent extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      destroyed: false,
      expanded: false,
      isLeftSideSticky: false,
      isLeftSideAtTheBottom: false,
    }
  }

  componentWillMount() {
    this.articlePartsRefs = {}
  }

  componentDidMount() {
    const contentHeight = this.articlePartsRefs.content.getBoundingClientRect().height
    this.isAboveThreshold = contentHeight > CONTENT_HEIGHT_THRESHOLD
  }

  onCheckChange() {
    const { article } = this.props
    article.checked = !article.checked
  }

  onChange = () => {
    const { article, onCheckedToggle } = this.props

    onCheckedToggle(article)
  }

  onEditClickHandler = () => {
    const { onEditArticleModalOpen, article } = this.props
    onEditArticleModalOpen({ article })
  }

  /**
   * Gets an active article. If an identical article is set, it's stored
   * as an object in article.activeIdenticalArticle.
   * If it's undefined, the regular article is the active one.
   */
  getActiveArticle() {
    const {
      article,
      article: { activeIdenticalArticle },
    } = this.props
    return activeIdenticalArticle || article
  }

  toggleExpanded() {
    /* Expands an article */
    const expand = () => {
      this.articlePartsRefs.content.style['max-height'] = `${this.getExpandedArticleHeight()}px`
      this.setState({ expanded: true })
    }
    /* Collapse an article */
    const collapse = () => {
      this.props.deactivateStickyTags(this.props.articleId)
      this.articlePartsRefs.content.style['max-height'] = null
      this.setState({ expanded: false })
    }
    if (this.state.expanded) {
      collapse()
    } else {
      expand()
    }
  }

  deleteArticle(article) {
    const actionsDropdown = this.props.articleService

    if (!article.deleted) {
      actionsDropdown.delete(article)
      this.props.deactivateStickyTags(this.props.articleId)
    } else {
      actionsDropdown.undelete(article)
    }
  }

  destroyArticle() {
    this.setState({ destroyed: true })
  }

  shareArticleHandler = () => {
    const { shareArticle, article } = this.props
    shareArticle(article)
  }

  checkStickySidePanel = () => {
    if (this.articlePartsRefs.sidePanel && this.isAboveThreshold) {
      const { isSticky, isAtTheBottom } = shouldHaveStickyLeftSide(
        this.articlePartsRefs.content,
        this.articlePartsRefs.sidePanel,
      )
      this.setState({ isLeftSideSticky: isSticky, isLeftSideAtTheBottom: isAtTheBottom })
    }
  }

  render() {
    const {
      setActiveIdenticalArticle,
      article,
      editMode,
      checked,
      shareTooltip,
      editTooltip,
      articleMetadata,
      locale,
    } = this.props

    const activeArticle = this.getActiveArticle()

    const { isLeftSideAtTheBottom, isLeftSideSticky } = this.state

    // if an article is deleted and user confirmed it we display an empty div
    if (this.state.destroyed) {
      return <div />
    }

    // if an article is deleted, we display a confirmation message
    if (article.deleted) {
      return (
        <DeletedArticle article={article} destroyArticle={this.destroyArticle} deleteArticle={this.deleteArticle} />
      )
    }

    // TODO get rid of this somehow
    if (article.deleted === undefined) {
      article.deleted = false
    }

    return (
      <article className="op-content-article-listing-article is-read-more-disabled">
        <ArticleMetadata article={article} articleMetadata={articleMetadata} locale={locale} />
        <div
          className="op-content-article-listing-article-content-wrapper"
          ref={(ref) => {
            this.articlePartsRefs.content = ref
          }}
        >
          {!editMode && (
            <ArticleSideButtons
              isSticky={isLeftSideSticky}
              isAtTheBottom={isLeftSideAtTheBottom}
              getRef={(ref) => {
                this.articlePartsRefs.sidePanel = ref
              }}
              shareButton={{
                tooltip: shareTooltip,
                onClick: this.shareArticleHandler,
              }}
              editButton={{
                tooltip: editTooltip,
                onClick: this.onEditClickHandler,
              }}
              selectCheckbox={{
                onChange: this.onChange,
                article,
                checked,
              }}
            />
          )}
          <div
            className={className(
              'op-content-article-listing-article-content',
              !editMode ? 'has-left-side' : '',
              isLeftSideSticky ? 'left-side-sticky' : '',
            )}
          >
            <ArticleHeaderText {...this.props} />
            <div
              className="op-content-article-listing-article-content-source-line"
              ref={(ref) => {
                this.articlePartsRefs.sourceLine = ref
              }}
            >
              <ArticleSourceLine
                dateFormat="fulldate"
                sourceIsLink
                showBadge
                showAuthor
                setActiveArticle={setActiveIdenticalArticle}
                article={article.activeIdenticalArticle ? article.activeIdenticalArticle : article}
                locale={this.props.locale}
              />
            </div>

            <div
              ref={(ref) => {
                this.articlePartsRefs.contentText = ref
              }}
            >
              <ArticleSimpleBodyComponent {...this.props} simple article={activeArticle} />
              <Colorbar
                colorbar={article.activeIdenticalArticle ? article.activeIdenticalArticle.colorbar : article.colorbar}
              />
            </div>
          </div>
        </div>
        <div className="op-content-article-listing-article-footer">
          <ArticleFooter {...this.props} />
        </div>
      </article>
    )
  }
}

export default SimpleListingArticleComponent

import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'

import buildActionCreators from '../../helpers/buildActionCreators'
import { getActiveArticle, getSelectedArticles } from '../../selectors/articlesSelectors'
import { getTags, getTagById, getLastUsedTagId } from '../../selectors/tagsSelectors'
import { KEYS, Shortcuts } from '../../shortcuts/components/Shortcuts'
import * as ActionTypes from '../../constants/actionTypes'

class ArticlesShortcuts extends React.PureComponent<any> {
  render() {
    const {
      tags,
      activeArticle,
      lastUsedTag,
      toggleTagArticle,
      tagArticles,
      decrementTagWeight,
      incrementTagWeight,
      nextActiveArticle,
      previousActiveArticle,
    } = this.props

    const tagsComboMapping = R.compose(
      R.map((tag) => () =>
        toggleTagArticle({
          tag,
          articles: activeArticle,
        }),
      ),
      // @ts-ignore
      R.indexBy(R.prop('combo')),
      // @ts-ignore
      R.filter(R.prop('combo')),
      // @ts-ignore
    )(tags)

    // @ts-ignore
    const numberKeyPairs: Array<Array<number, () => void>> = R.range(1, 7).map((number) => [
      number,
      () =>
        tagArticles({
          tag: lastUsedTag,
          articles: activeArticle,
          weight: number,
        }),
    ])

    const lastUsedMapping = lastUsedTag
      ? {
          ...R.fromPairs([...numberKeyPairs]),
          ...{
            '-': () =>
              decrementTagWeight({
                tag: lastUsedTag,
                articles: activeArticle,
              }),
            '+': () =>
              incrementTagWeight({
                tag: lastUsedTag,
                articles: activeArticle,
              }),
            [KEYS.space]: () =>
              toggleTagArticle({
                tag: lastUsedTag,
                articles: activeArticle,
              }),
          },
        }
      : {}

    const keyMap = {
      ...tagsComboMapping,
      ...lastUsedMapping,
      [KEYS.left]: previousActiveArticle,
      [KEYS.right]: nextActiveArticle,
    }

    return <Shortcuts keyMap={keyMap} />
  }
}

export default connect(
  (state) => {
    const tags = getTags(state)
    const activeArticle = getActiveArticle(state)
    const lastUsedTagId = getLastUsedTagId(state)
    const lastUsedTag = getTagById(lastUsedTagId)(state)
    const selectedArticles = getSelectedArticles(state)

    return {
      activeArticle: selectedArticles.length ? selectedArticles : activeArticle,
      lastUsedTag,
      tags,
    }
  },
  buildActionCreators({
    toggleTagArticle: ActionTypes.TOGGLE_TAG_ARTICLE,
    tagArticles: ActionTypes.TAG_ARTICLES,
    decrementTagWeight: ActionTypes.DECREMENT_TAG_WEIGHT_FOR_ARTICLE,
    incrementTagWeight: ActionTypes.INCREMENT_TAG_WEIGHT_FOR_ARTICLE,
    nextActiveArticle: ActionTypes.NEXT_ACTIVE_ARTICLE,
    previousActiveArticle: ActionTypes.PREVIOUS_ACTIVE_ARTICLE,
  }),
)(ArticlesShortcuts)

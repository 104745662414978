import React from 'react'
import PropTypes from 'prop-types'
import Measure from 'react-measure'
import { translate } from 'react-i18next'

import Graph from './Graph'
import { colorScaleBasic } from '../../opoint/common/colors'

const GAP = 8
const BASE_WIDTH = 295 + GAP

class CrossfilterGraph extends React.PureComponent<any> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  menu: Array<any>

  constructor(props, context) {
    super(props)

    const { i18n } = context

    this.menu = [
      [
        [i18n.t('Export SVG'), (graph) => graph.saveFile('svg')],
        [i18n.t('Export PNG'), (graph) => graph.saveFile('png')],
      ],
    ]
  }

  state = {
    width: -1,
  }

  onFilterChange = (filter) => {
    const { onFilterChange } = this.props
    if (onFilterChange) {
      onFilterChange({
        id: 'time',
        filter,
      })
    }
  }

  onResizeHandler = (contentRect) => this.setState({ width: contentRect.bounds.width })

  render() {
    const { title, values, filter, countBy, getPdfExportData } = this.props
    const { width } = this.state

    const visibleGraphContainerClassName = 'visible-main-graph'
    const invisibleGraphContainerClassName = 'invisible-rendered-main-graph-for-pdf-export'

    return (
      <Measure bounds onResize={this.onResizeHandler}>
        {({ measureRef }) => (
          <div className="time-chart-wrapper" ref={measureRef}>
            <div className={visibleGraphContainerClassName}>
              <Graph
                brushOn
                chartGroup="statistics"
                chartType="barChart"
                className="time-chart"
                colors={colorScaleBasic}
                containerClassName={visibleGraphContainerClassName}
                countBy={countBy}
                dimensionBy="date"
                filter={filter}
                height={125}
                hideTitle
                menu={this.menu}
                name={title}
                onFilterChange={this.onFilterChange}
                style={{ minWidth: `${width - (width % BASE_WIDTH) - GAP}px`, width: '99%' }}
                values={values}
                width={Math.max(BASE_WIDTH - 24, +(width - 34))}
              />
            </div>

            <div className={invisibleGraphContainerClassName}>
              {/* Render clone with constant width for PDF exporting */}
              <Graph
                brushOn
                chartGroup="statistics"
                chartType="barChart"
                className="time-chart"
                colors={colorScaleBasic}
                containerClassName={invisibleGraphContainerClassName}
                countBy={countBy}
                dimensionBy="date"
                filter={filter}
                getPdfExportData={getPdfExportData}
                height={125}
                hideTitle
                menu={this.menu}
                name={title}
                onFilterChange={this.onFilterChange}
                style={{
                  minWidth: '550px',
                  width: '99%',
                  position: 'absolute',
                  opacity: 0,
                  left: '-2000px',
                  overflow: 'hidden',
                }}
                values={values}
                width={550}
              />
            </div>
          </div>
        )}
      </Measure>
    )
  }
}

export default translate([], { wait: true })(CrossfilterGraph)

// @ts-nocheck
import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'rc-slider'
import { Field } from 'redux-form'

import 'rc-slider/assets/index.css'
import 'rc-tooltip/assets/bootstrap.css'

import Checkbox from '../../common/Checkbox'
import GENERATED_BACKEND_TRANSLATIONS from '../../../generated-backend-translations'
import type { TemplateModule, Widget, WidgetType } from '../../../opoint/flow'

const CheckboxWrapper = ({ input: { value, onChange }, ...props }) => (
  <Checkbox onChangeHandler={onChange} value={value} isChecked={value} {...props} />
)

const RadioWrapper = ({ input: { name, value, onChange, checked }, label }) => (
  <div className="template-editor__radio-wrapper">
    <div className="op-radio template-editor__radio-button-margin">
      <input type="radio" id={name + value} name={name} value={value} onChange={onChange} checked={checked} readOnly />
      <label onClick={() => onChange(value)} />
    </div>
    <label htmlFor={name + value} className="radio-label">
      {label}
    </label>
  </div>
)

const TextareaWrapper = ({ input: { value, onChange }, label, i18n, textAreaStyle }) => (
  <div>
    <label htmlFor={value}>{i18n.t(GENERATED_BACKEND_TRANSLATIONS.templates[label]) || label}</label>
    <textarea
      className={`op-input template-editor__textarea ${textAreaStyle}`}
      id={value}
      value={value}
      onChange={onChange}
    />
  </div>
)

const GroupWrapper = ({
  input: { value, onChange, name },
  childrenModule,
  groupName,
  parent,
  i18n,
  classNameMod,
  children,
  level,
}) => {
  const generatedName = GENERATED_BACKEND_TRANSLATIONS.templates[name]

  if (parent) {
    return (
      <div>
        <Checkbox
          children={children}
          classNameMod={classNameMod}
          id={name}
          isChecked={value}
          name={name}
          onChangeHandler={onChange}
          value={value}
        />
        {value && (
          <div className="template-editor__children">
            <Module module={childrenModule} radioGroup={groupName} maxLevel={level} i18n={i18n} />
          </div>
        )}
      </div>
    )
  }
  return (
    <div>
      <span className="template-editor__parent-name">{i18n.t(generatedName)}</span>
      <div className="template-editor__children">
        <Module module={childrenModule} radioGroup={name} maxLevel={level} i18n={i18n} />
      </div>
    </div>
  )
}

const SliderWrapper = ({ input: { value, onChange }, defaultValues: { min, max, selected } }) => (
  <div className="template-editor__slider-wrapper">
    <Slider
      className="template-editor__slider"
      dots
      max={max}
      min={min}
      onChange={onChange}
      step={max - min < 2000 ? 1 : 5}
      value={value || selected}
    />
    <span className="vertical-align">{value || selected}</span>
  </div>
)

type getCorrectWidgetProps = {
  type: WidgetType
  value: any
  name: string
  children?: Array<Widget>
  parent?: Widget
  radioGroup?: string
  index: number
  level?: number
  i18n: any
}

const getCorrectWidget = ({
  type,
  value,
  name,
  children,
  parent,
  radioGroup,
  index,
  level,
  i18n,
}: getCorrectWidgetProps) => {
  const generatedName = GENERATED_BACKEND_TRANSLATIONS.templates[name]
  switch (type) {
    case 'WIDGET_INPUT_SMALL':
    case 'WIDGET_INPUT_BIG' /* eslint-disable-line no-case-declarations */:
      const inputStyle = type === 'WIDGET_INPUT_SMALL' ? 'input-small' : 'input-big'
      return (
        <div key={index}>
          <label htmlFor={name}>{generatedName ? i18n.t(generatedName) : name}</label>
          <Field id={name} name={name} component="input" className={`op-input mod-login ${inputStyle}`} />
        </div>
      )
    case 'WIDGET_DROPDOWN':
      return (
        <div className="template-editor__dropdown" key={index}>
          <label htmlFor={name}>{generatedName ? i18n.t(generatedName) : name}</label>
          <br />
          <Field id={name} name={name} component="select" className="op-select mod-login">
            {value.map((opt) => (
              <option key={opt.value} value={opt.value}>
                {i18n.t(GENERATED_BACKEND_TRANSLATIONS.templates[opt.name]) || opt.name}
              </option>
            ))}
          </Field>
        </div>
      )
    case 'WIDGET_VALUE_RADIO':
      return (
        <div key={index}>
          <label htmlFor={name}>{generatedName ? i18n.t(generatedName) : name}</label>
          <br />
          {value.map((opt) => (
            <div key={opt.name} style={{ display: 'inline-block' }}>
              <Field
                key={opt.name}
                type="radio"
                name={name}
                value={opt.value}
                label={i18n.t(GENERATED_BACKEND_TRANSLATIONS.templates[opt.name]) || opt.name}
                component={RadioWrapper}
              />
            </div>
          ))}
        </div>
      )
    case 'WIDGET_RADIO': {
      return (
        <Field
          key={index}
          type="radio"
          name={radioGroup}
          value={name}
          label={generatedName ? i18n.t(generatedName) : name}
          component={RadioWrapper}
        />
      )
    }
    case 'WIDGET_CHECKBOX':
      return (
        <Field
          classNameMod="edit-template"
          component={CheckboxWrapper}
          id={name}
          key={index}
          name={name}
          type="checkbox"
        >
          {generatedName ? i18n.t(generatedName) : name}
        </Field>
      )
    case 'WIDGET_GROUP':
      if (parent) {
        const parentName = parent.name || name
        return (
          <Field
            childrenModule={{ widgets: children }}
            classNameMod="edit-template"
            component={GroupWrapper}
            groupName={name}
            i18n={i18n}
            key={index}
            level={level}
            name={parentName}
            parent={parent}
            type="checkbox"
          >
            {i18n.t(GENERATED_BACKEND_TRANSLATIONS.templates[parentName]) || parentName}
          </Field>
        )
      }
      return (
        <Field
          childrenModule={{ widgets: children }}
          classNameMod="edit-template"
          component={GroupWrapper}
          i18n={i18n}
          id={name}
          key={index}
          level={level}
          name={name}
          type="checkbox"
        >
          {generatedName ? i18n.t(generatedName) : name}
        </Field>
      )

    case 'WIDGET_SLIDER':
      return (
        <div key={index}>
          <span className="template-editor__slider-span">{generatedName ? i18n.t(generatedName) : name}</span>
          <Field component={SliderWrapper} defaultValues={value} id={name} name={name} />
        </div>
      )
    case 'WIDGET_TEXTAREA_SMALL':
    case 'WIDGET_TEXTAREA_BIG':
      return (
        <Field
          component={TextareaWrapper}
          i18n={i18n}
          id={name}
          key={index}
          label={name}
          name={name}
          textAreaStyle={type === 'WIDGET_TEXTAREA_SMALL' ? 'textarea-small' : 'textarea-big'}
          type="text"
        />
      )
    default:
  }
  return (
    <div key={index}>
      {`${type} - UNDEFINED TYPE - ${name}`}
      <br />
    </div>
  )
}

type ModuleProps = {
  module: TemplateModule
  radioGroup?: string
  maxLevel: number
}

const Module = ({ module, radioGroup, maxLevel }: ModuleProps, { i18n }: { i18n: any }) => (
  <div>
    {module.widgets.map(({ type, value, name, children, parent, level }, index) =>
      maxLevel === undefined || maxLevel >= level
        ? getCorrectWidget({
            parent,
            type,
            value,
            name,
            children,
            radioGroup,
            index,
            maxLevel,
            i18n,
          })
        : null,
    )}
  </div>
)

Module.contextTypes = { i18n: PropTypes.object }

export default Module

import React from 'react'
import { connect } from 'react-redux'

import buildAction from '../helpers/buildAction'
import BaseActionLine from './toolbars/BaseActionLine'
import getToolbarActions from '../opoint/toolbar/index'
import ToolbarHeader from './toolbars/ToolbarHeader'
import { getDeleteFeedInProgress, getEditedFeed } from '../selectors/feedsSelector'
import { getLocationBeforeTransitions } from '../selectors/routingSelectors'
import { getSelectedArticlesIds, noArticlesFound } from '../selectors/articlesSelectors'
import { isAlertPreviewOpen } from '../selectors/uiSelectors'
import { getAddFormData, getDeleteAlertInProgress, getEditFormData, getAlertsCount } from '../selectors/alertsSelectors'
import {
  areStatisticsFiltered,
  areStatisticsOpen,
  getActiveViewId,
  getShowFilteredArticles,
} from '../selectors/statisticsSelectors'
import { getRecipientsCount } from '../selectors/contactSelectors'
import {
  getMainSearchLine,
  getSearchMeta,
  isOneTagSelected,
  isSearchNotEmpty,
  atLeastOneTagSelected,
  getSearchTimePeriod,
} from '../selectors/searchSelectors'
import { getEditedProfileActive, isNewProfile } from '../selectors/profilesSelectors'
import { getMaxAlerts, getMaxAlertRecipients } from '../selectors/settingsSelectors'
import * as ActionTypes from '../constants/actionTypes'
import type { Searchline } from '../opoint/flow'

type Props = {
  // TODO flow details @dmytro
  alertPreview: boolean
  atLeastOneTagSelected: boolean
  deleteAlertInProgress: boolean
  deleteFeedInProgress: boolean
  editedFeed: any
  getAddFormData: any
  getEditFormData: any
  getPermissions: (module: string) => void
  i18n: Object
  isEditedProfile?: boolean
  isOneTagSelected: boolean
  isSearch: boolean
  newProfile: any
  noArticles: boolean
  onClickHandler: (action: any) => void
  openDatepicker: () => void
  routing: any
  searchLine: Searchline
  searchMeta: any
  selectedArticlesIds: Array<number>
  showFilteredArticles: boolean
  statistics: boolean
  statisticsFiltered: boolean
  statisticViewId: any
  alertsCount: number
  maxAlerts: number
  recipientsCount: number
  maxAlertRecipients: number
}

// TODO: handling of onClick action on action line buttons is not optimal
// maybe refactor to be able to change route as well
class Toolbar extends React.Component<Props> {
  onActionClickHandler = (action: any): void => {
    this.props.onClickHandler(action)
  }

  render() {
    const toolbar = getToolbarActions(this.props)

    return (
      <div className="op-content-toolbar util_col-centered">
        <div className="op-content-toolbar-articles op-content-toolbar-single animate">
          <div className="col-lg-5 col-md-5 util_col-vertical-center op-content-toolbar-single-info">
            {toolbar.header && <ToolbarHeader header={toolbar.header} headerProps={toolbar.headerProps} />}
          </div>
          <div className="col-lg-7 col-md-7 util_col-vertical-center op-content-toolbar-single-options">
            <BaseActionLine
              {...this.props}
              onActionClickHandler={this.onActionClickHandler}
              actions={toolbar.actions}
            />
          </div>
        </div>
      </div>
    )
  }
}

// @ts-ignore
export default connect(
  (state) => ({
    alertPreview: isAlertPreviewOpen(state),
    atLeastOneTagSelected: atLeastOneTagSelected(state),
    deleteAlertInProgress: getDeleteAlertInProgress(state),
    deleteFeedInProgress: getDeleteFeedInProgress(state),
    editedFeed: getEditedFeed(state),
    getAddFormData: getAddFormData(state),
    getEditFormData: getEditFormData(state),
    // @ts-ignore
    getPermissions: (module) => state.settings.list[module],
    isEditedProfile: getEditedProfileActive(state),
    isOneTagSelected: isOneTagSelected(state),
    isSearch: isSearchNotEmpty(state),
    newProfile: isNewProfile(state),
    noArticles: noArticlesFound(state),
    routing: getLocationBeforeTransitions(state),
    searchline: getMainSearchLine(state),
    searchMeta: getSearchMeta(state),
    selectedArticlesIds: getSelectedArticlesIds(state),
    showFilteredArticles: getShowFilteredArticles(state),
    statistics: areStatisticsOpen(state),
    withExpliciteDateRange: getSearchTimePeriod(state),
    statisticsFiltered: areStatisticsFiltered(state),
    statisticViewId: getActiveViewId(state),
    maxAlerts: getMaxAlerts(state),
    alertsCount: getAlertsCount(state),
    recipientsCount: getRecipientsCount(state),
    maxAlertRecipients: getMaxAlertRecipients(state),
  }),
  (dispatch) => ({
    onClickHandler: (action) => dispatch(buildAction(action)),
    openDatepicker: () => dispatch(buildAction(ActionTypes.DATEPICKER_MODAL_OPEN)),
  }),
  // @ts-ignore
)(Toolbar)

// @ts-nocheck
import d3tip from 'd3-tip'
import R from 'ramda'
import * as d3 from 'd3'

d3.tip = d3tip

const SQUARE = '◼' // &#x025FC

// create invisible element for attaching tooltip, which changing position according of mouse
const ghost = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
{
  ghost.style.position = 'absolute'
  ghost.style.top = 0
  ghost.style.left = 0
  // Problem with following lines is that when you zoom,
  // 0.1 size actually gets bigger, so we need to be careful to have
  // offset in function followMouse, otherwise with zooming
  // you will get unclicable links / you are unable to scroll
  // and so on - that is the reason why in followMouse function
  // is offset +3 instead of +1, which was insufficient
  ghost.setAttribute('width', 0.1) // zero size breaks firefox
  ghost.setAttribute('height', 0.1)
  document.removeEventListener('mousemove', followMouse)
  document.addEventListener('mousemove', followMouse)
  function followMouse(event) {
    const [x, y] = [event.clientX + 3, event.clientY + 3]
    ghost.style.left = `${x}px`
    ghost.style.top = `${y}px`
  }
  document.body.appendChild(ghost)
}

/**
 * @param chart
 * @param chartType
 * @param group
 * @param groupTopKeys
 * @param keyAccessor
 * @param seriesAccessor
 * @param keyToName
 * @param timeFormat
 * @param commonDimension
 * @param countBy
 */
export function attachD3Tooltips( // TODO refactor - too many args
  chart,
  chartType,
  group,
  groupTopKeys = [],
  keyAccessor,
  seriesAccessor,
  keyToName,
  timeFormat,
  commonDimension,
  countBy,
) {
  if (!commonDimension) {
    return
  }
  const commonGroup = commonDimension.group().reduceSum((d) => (countBy ? d[countBy] : 1))

  switch (chartType) {
    case 'lineChart':
      chart.on('renderlet.linetip', renderLineTip)
      break
    case 'barChart':
      chart.on('renderlet.bartip', renderBarTip)
      break
    case 'pieChart':
      chart.on('renderlet.pietip', renderPieTip)
      break
    case 'rowChart':
      chart.on('renderlet.rowtip', renderRowTip)
      break
    default:
      throw new Error(`Unknown chartType ${chartType}`)
  }

  function renderLineTip(lineTip) {
    if (lineTip.cachedTip) {
      lineTip.cachedTip.destroy()
    }

    const tip = d3
      .tip()
      .attr('class', 'd3-tip')
      .direction('e')
      .offset([-2, 10])
      .html(
        R.memoize((d) => {
          // get cohor
          const cohort = group
            .all()
            .filter(
              (member) =>
                // filter out what is not shown on timeseries
                +keyAccessor(member) === +keyAccessor(d.data) &&
                groupTopKeys.some((keys) => keys.includes(seriesAccessor(member))) &&
                member.value !== 0,
            )
            .reverse()
          // get colors from legend DOM object
          const colorFills = {}
          /* eslint-disable-next-line func-names */
          lineTip.selectAll('.dc-legend-item').each(function () {
            /* eslint-disable-next-line no-underscore-dangle */
            colorFills[this.__data__.name] = this.__data__.color
          })
          // get faded stacks from legend: two class selectors
          const faded = []
          chart.selectAll('.dc-legend-item.fadeout').each(() =>
            /* eslint-disable-next-line no-underscore-dangle */
            faded.push(this.__data__.name),
          )
          // build html table
          let html = '<table class="d3-tip__table">'
          // print out date
          html += `<tr><th colspan='3'>${timeFormat(keyAccessor(d.data))}</th></tr>`
          cohort.forEach((member) => {
            // if stack has not been faded out
            if (!faded.includes(seriesAccessor(member)) && member.value !== 0) {
              html += `<tr>
                  <td><span style='color: ${colorFills[seriesAccessor(member)]}'> ${SQUARE} </span></td>
                  <td> ${keyToName(seriesAccessor(member))} </td>
                  <td class='d3-tip-num-cell'> ${member.value} </td>
                  </tr>`
            }
          })
          html += '</table>'
          return html
        }),
      )

    chart.cachedTip = tip

    // apply tooltips to DOM elements
    // this is called twice and sometimes throws error,
    // but since it works anyway we can happily ignore it
    try {
      chart.selectAll('.dot').call(tip).on('mouseenter', show).on('mouseleave', hide)
    } catch (e) {} /* eslint-disable-line no-empty */

    function show(d) {
      tip.show(d, this)
    }
    function hide(d) {
      tip.hide(d)
    }
  }

  function renderBarTip(barTip) {
    if (barTip.cachedTip) {
      barTip.cachedTip.destroy()
    }

    const tip = d3
      .tip()
      .attr('class', 'd3-tip')
      .direction('e')
      .offset([0, 18])
      .html(
        R.memoize((d) => {
          const cohort = group
            .all()
            .filter(
              (
                member, // filter out what is not shown on timeseries
              ) =>
                +keyAccessor(member) === +keyAccessor(d.data) &&
                groupTopKeys.some((keys) => keys.includes(seriesAccessor(member))) &&
                member.value !== 0,
            )
            .reverse() // reverse to show in same order in which stacking works
          // fetch colors from legend DOM object
          const colorFills = {}
          barTip.selectAll('.dc-legend-item').each(() => {
            /* eslint-disable-next-line no-underscore-dangle */
            colorFills[this.__data__.name] = this.__data__.color
          })
          // fetch faded stacks from legend: two class selectors
          const faded = []
          barTip.selectAll('.dc-legend-item.fadeout').each(() =>
            /* eslint-disable-next-line no-underscore-dangle */
            faded.push(this.__data__.name),
          )
          // build html table
          let html = "<table class='d3-tip__table'>"
          // print out date
          html += `<tr><th colspan='3'>${timeFormat(keyAccessor(d.data))}</th></tr>`
          cohort.forEach((member) => {
            // if stack has not been faded out
            if (!faded.includes(seriesAccessor(member))) {
              html += `<tr>
              <td><span style='color: ${colorFills[seriesAccessor(member)]}'> ${SQUARE} </span></td>
              <td><span class='part-name'> ${keyToName(seriesAccessor(member))} </span></td>
              <td class='d3-tip-num-cell'> ${member.value} </td>
              </tr>`
            }
          })
          html += '</table>'
          return html
        }),
      )

    barTip.cachedTip = tip

    // apply tooltips to DOM elements
    // this is called twice and sometimes throws error,
    // but since it works anyway we can happily ignore it
    try {
      barTip.selectAll('.bar').call(tip).on('mouseenter', show).on('mousemove', show).on('mouseleave', tip.hide)
    } catch (e) {} /* eslint-disable-line no-empty */

    function show(d) {
      fixateXPosAccordingTo(ghost, this)
      tip.show(d, ghost)
    }
  }

  function renderPieTip(pieTip) {
    if (pieTip.cachedTip) {
      pieTip.cachedTip.destroy()
    }

    const tip = d3
      .tip()
      .attr('class', 'd3-tip')
      .direction('e')
      .offset([0, 18])
      .html((d, i) => {
        // make title with percentages
        const roundedPercent = getRoundedPercentage(d, commonGroup)
        const color = pieTip.getColor(d.data, i)
        return `<span class='d3tip_q' style='color: ${color}'>${SQUARE}</span> <span class='part-name'>${keyToName(
          d.data.key,
        )}: ${d.data.value}</span> (${roundedPercent})`
      })
    pieTip.cachedTip = tip

    // this is called twice and sometimes throws error,
    // but since it works anyway we can happily ignore it
    try {
      pieTip.selectAll('g.pie-slice').call(tip).on('mouseenter', show).on('mousemove', show).on('mouseleave', tip.hide)
    } catch (e) {} /* eslint-disable-line no-empty */

    function show(d) {
      tip.show(d, ghost)
    }
  }

  function renderRowTip(rowTip) {
    if (rowTip.cachedTip) {
      rowTip.cachedTip.destroy()
    }

    const tip = d3
      .tip()
      .attr('class', 'd3-tip')
      .direction('e')
      .offset([-1, 18])
      .html((d, i) => {
        // make title with percentages
        const roundedPercent = getRoundedPercentage(d, commonGroup)
        const color = rowTip.getColor(d, i)
        return `<span class='d3tip_q' style='color: ${color}'>${SQUARE}</span> <span class='part-name'>${keyToName(
          d.key,
        )}</span>: ${d.value} (${roundedPercent})`
      })

    rowTip.cachedTip = tip

    // this is called twice and sometimes throws error,
    // but since it works anyway we can happily ignore it
    try {
      rowTip.selectAll('g.row').call(tip).on('mouseenter', show).on('mousemove', show).on('mouseleave', tip.hide)
    } catch (e) {} /* eslint-disable-line no-empty */

    function show(d) {
      fixateYPosAccordingTo(ghost, this)
      tip.show(d, ghost)
    }
  }
}

function getRoundedPercentage(datum, group) {
  return `${d3.format('.3')((datum.value / d3.sum(group.all(), (d) => d.value)) * 100, 1)}%`
}

function fixateXPosAccordingTo(destination, source) {
  const bounds = source.getBoundingClientRect()
  destination.style.left = `${bounds.left + bounds.width / 2}px`
}

function fixateYPosAccordingTo(destination, source) {
  const bounds = source.getBoundingClientRect()
  destination.style.top = `${bounds.top + bounds.height / 2}px`
}

import React from 'react'
import PropTypes from 'prop-types'

import ArticleTag from './ArticleTag'
import TrashTag from './TrashTag'

export default class ArticleTags extends React.Component<any, any> {
  static propTypes = {
    setTag: PropTypes.func.isRequired,
    setTrashTag: PropTypes.func.isRequired,
    incrementTag: PropTypes.func.isRequired,
    decrementTag: PropTypes.func.isRequired,
    tags: PropTypes.array.isRequired,
  }

  setTagFn = (tag, article) => {
    this.props.setTag({
      tag,
      articles: article,
    })
  }

  setTrashTagFn = (tag, article) => {
    this.props.setTrashTag({
      tag,
      article,
    })
  }

  incrementTagFn = (tag, article) => {
    this.props.incrementTag({
      tag,
      articles: article,
    })
  }

  decrementTagFn = (tag, article) => {
    this.props.decrementTag({
      tag,
      articles: article,
    })
  }

  render() {
    const {
      article,
      tags,
      visibleTrashTags,
      decrementIdenticalArticleTag,
      incrementIdenticalArticleTag,
      setIdenticalArticleTag,
      setActiveTag,
      lastUsedTag,
      showIdenticalArticlesPopup,
    } = this.props

    return (
      <div className="op-content-article-listing-article-footer-tags">
        {tags &&
          tags.map((tag, index) =>
            article.tags[tag.id] || tag.visibility === 2 ? (
              <ArticleTag
                article={article}
                decrementTagWeightFn={decrementIdenticalArticleTag || this.decrementTagFn}
                incrementTagWeightFn={incrementIdenticalArticleTag || this.incrementTagFn}
                setTagFn={setIdenticalArticleTag || this.setTagFn}
                key={index}
                tag={tag}
                setActiveTag={setActiveTag}
                lastUsedTag={lastUsedTag}
                showIdenticalArticlesPopup={showIdenticalArticlesPopup}
              />
            ) : undefined,
          )}

        {visibleTrashTags &&
          visibleTrashTags.map((tag, index) => (
            // @ts-ignore
            <TrashTag {...this.props} setTrashTagFn={this.setTrashTagFn} key={index} tag={tag} />
          ))}
      </div>
    )
  }
}

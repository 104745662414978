import React from 'react'

import { tagStyle } from './common'
import type { TagComponentProps } from '../../opoint/flow'

export default class MentometerTag extends React.PureComponent<TagComponentProps> {
  onIncrementClickHandler = () => {
    const { article, tag, incrementTagWeightFn } = this.props
    incrementTagWeightFn(tag, article)
  }

  onDecrementClickHandler = () => {
    const { article, tag, decrementTagWeightFn } = this.props
    decrementTagWeightFn(tag, article)
  }

  onSetClickHandler = () => {
    const { article, tag, setTagFn } = this.props
    setTagFn(tag, article)
  }

  render() {
    const { article, checked, tag, hideWeightControlls, lastUsedTag } = this.props

    return (
      // @ts-ignore
      <div className={`op-tag  tag-mentometer ${tagStyle(tag, article, checked, lastUsedTag)}`} title={tag.name}>
        {!hideWeightControlls && (
          <span className="mentometer-button mentometer-decrement" onClick={this.onDecrementClickHandler}>
            -
          </span>
        )}
        <div className="mentometer-name-weight" onClick={this.onSetClickHandler}>
          <span className="mentometer-weight">
            {(article && article.tags[tag.id] && article.tags[tag.id].weight) || 0}
          </span>

          <span className="mentometer-name op-tag__tag-name" dangerouslySetInnerHTML={{ __html: tag.name }} />
        </div>
        {!hideWeightControlls && (
          <span className="mentometer-button mentometer-increment" onClick={this.onIncrementClickHandler}>
            +
          </span>
        )}
      </div>
    )
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import classNames from 'classnames'

import buildActionCreators from '../../helpers/buildActionCreators'
import Logo from '../common/Logo'
import { getUISetting } from '../../selectors/settingsSelectors'
import * as ActionTypes from '../../constants/actionTypes'

type Props = {
  showMenuToggle: boolean
  showToolbar: boolean
  toggleSidebarShown: any
  goToDefaultProfile: any
  sidebarShown: any
  showEntitiesToggle: boolean
}

class LogoWrapper extends React.Component<Props> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  static defaultProps = {
    showMenuToggle: true,
    showToolbar: true,
    showEntitiesToggle: true,
  }

  onToggleClickHandler = () => {
    this.props.toggleSidebarShown('SIDEBAR_SHOWN')
  }

  onLogoClickHandler = () => {
    this.props.goToDefaultProfile()
  }

  render() {
    const { sidebarShown, showMenuToggle, showEntitiesToggle } = this.props
    const { i18n } = this.context

    return (
      <div className="col-md-2 header__logo-wrapper">
        {!!showMenuToggle && showEntitiesToggle && (
          <div
            className="op-main-menu-toggle"
            title={sidebarShown ? i18n.t('Hide menu') : i18n.t('Show menu')}
            onClick={this.onToggleClickHandler}
          >
            <i className="op-icon-hamburger" />
          </div>
        )}
        <div className="header__clickable-logo" onClick={this.onLogoClickHandler}>
          <Logo className="op-portal-logo" />
        </div>
      </div>
    )
  }
}

// @ts-ignore
LogoWrapper = translate([], { wait: true })(LogoWrapper)

// @ts-ignore
export default LogoWrapper = connect(
  (state) => ({
    sidebarShown: getUISetting('SIDEBAR_SHOWN')(state),
  }),
  buildActionCreators({
    toggleSidebarShown: ActionTypes.TOGGLE_UI_SETTING,
    goToDefaultProfile: ActionTypes.GO_TO_DEFAULT_PROFILE,
  }),
  // @ts-ignore
)(LogoWrapper)

import React from 'react'

import Translate from '../../../Translate'

class MultiSelectDropdown extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      sendInterval: this.props.interval.sendInterval || 0,
      start: this.props.interval.start || 0,
      end: this.props.interval.end || 0,
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.props.clickOutside, false)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.props.clickOutside, false)
  }

  handleInputChange = (event) => {
    const { value } = event.target
    const { name } = event.target
    const { updateSpecifiedData, interval } = this.props

    const newInterval = {
      sendInterval: this.state.sendInterval,
      start: this.state.start,
      end: this.state.end,
      [name]: value,
      interval: interval.interval,
    }

    this.setState({
      [name]: value,
    })
    updateSpecifiedData(newInterval, interval.interval[0])
  }

  deleteInterval = () => {
    const { deleteInterval, interval } = this.props

    deleteInterval(interval.interval[0])
  }

  render() {
    const { formatHours } = this.props
    const { interval } = this.props.interval
    if (interval && interval.length > 1) {
      return (
        <div className="op-multiselect-point-edit">
          <div className="row">
            <div className="col-md-12">
              <div className="col-md-6">
                <div className="row">
                  <label htmlFor="interval">
                    <Translate i18nString="Interval" />
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <input
                    id="interval"
                    name="sendInterval"
                    type="number"
                    min="0"
                    step="1"
                    placeholder="0"
                    tabIndex={2}
                    onChange={this.handleInputChange}
                    value={this.state.sendInterval}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="col-md-6">
                <div className="row">
                  <label htmlFor="start">
                    <Translate i18nString="Start" />
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <span>
                    {formatHours(interval[0])}
                    {': '}
                  </span>
                  <input
                    id="start"
                    name="start"
                    min="0"
                    max="60"
                    step="5"
                    tabIndex={3}
                    placeholder="0"
                    type="number"
                    onChange={this.handleInputChange}
                    value={this.state.start}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="col-md-6">
                <div className="row">
                  <label htmlFor="end">
                    <Translate i18nString="End" />
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <span>
                    {formatHours(interval[interval.length - 1])}
                    {': '}
                  </span>
                  <input
                    id="end"
                    name="end"
                    min="0"
                    max="60"
                    step="5"
                    tabIndex={4}
                    placeholder="0"
                    type="number"
                    onChange={this.handleInputChange}
                    value={this.state.end}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="button-wrapper">
                    <button type="button" onClick={this.deleteInterval} className="op-button">
                      <Translate i18nString="Delete" />
                    </button>
                    <button type="button" onClick={this.props.clickSaveButton} className="op-button">
                      <Translate i18nString="Save" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className="op-multiselect-point-edit">
        <div className="row">
          <div className="col-md-12">
            <div className="col-md-6">
              <div className="row">
                <label htmlFor="start-single">
                  <Translate i18nString="Start" />
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <span>
                  {formatHours(interval[0])}
                  {': '}
                </span>
                <input
                  id="start-single"
                  name="start"
                  min="0"
                  max="60"
                  step="5"
                  tabIndex={1}
                  type="number"
                  placeholder="0"
                  onChange={this.handleInputChange}
                  value={this.state.start}
                />
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="col-md-12">
              <div className="row">
                <div className="button-wrapper">
                  <button type="button" onClick={this.deleteInterval} className="op-button">
                    <Translate i18nString="Delete" />
                  </button>
                  <button type="button" onClick={this.props.clickSaveButton} className="op-button">
                    <Translate i18nString="Save" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MultiSelectDropdown

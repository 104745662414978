import React from 'react'

import ModulePermissions from '../common/ModulePermissions'
import { MODULE, MODULE_OPTION } from '../../opoint/settings/index'
import { isTagOnArticle } from '../../opoint/tags'
import { ArticleListingTag } from './ArticleListingTag'
import type { Article, Tag } from '../../opoint/flow'

export const ArticleListingTags = ({ article, tags }: { article: Article; tags: Array<Tag> }) => (
  <ModulePermissions module={MODULE.TAG} permissions={[MODULE_OPTION.ON]}>
    <div className="op-content-right-section-content-left-tags">
      <ul className="op-content-right-section-content-left-tags-list">
        {tags &&
          tags.map(
            (tag, index) =>
              isTagOnArticle(tag, article) && <ArticleListingTag key={index} article={article} tag={tag} />,
          )}
      </ul>
    </div>
  </ModulePermissions>
)

import React from 'react'

// TODO flow
export default (props) => {
  const { id, value, label, className, classNameMod, isChecked, name, onChangeHandler, disabled, children } = props

  // TODO: accessibility (checkbox cannot be selected with keyboard)
  return (
    <div className={className}>
      <div className={`op-checkbox mod-${classNameMod}`}>
        <input
          id={id}
          value={value}
          name={name}
          type="checkbox"
          checked={isChecked}
          onChange={onChangeHandler}
          disabled={disabled}
        />
        <label htmlFor={id} />
      </div>
      <label className="op-checkbox-label" htmlFor={id}>
        {children}
      </label>
    </div>
  )
}

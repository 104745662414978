import R from 'ramda'
import momentTz from 'moment-timezone'

import config from './config'

const common = {
  config,
}
export default common

export function stripHTML(html) {
  const tmp = document.createElement('DIV')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}

export function firstToUpperCase(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

/**
 * Decides if n is a numeric value or not
 * @param n could be anything
 * @returns true if n is a numeric value (finite number), false otherwise
 */
export function isNumeric(n: any): boolean {
  return !isNaN(n) && isFinite(n)
}

// This constant add index property into each element in Array of Objects
// used for DnD sorting of profiles and tags
export const indexArray = R.addIndex(R.map)((x, y) => R.assoc('index', y, x))

type entities = {
  alerts?: Array<{ id: number; name: string }>
  feeds?: Array<{ id: number; name: string }>
  profiles?: Array<{ id: number; name: string }>
}

export const cannotDeleteEntityReason = ({ data }: { data: entities }, i18n: any): string => {
  const { feeds, alerts, profiles } = data
  return R.filter((x) => !!x, [
    feeds && !!feeds.length && `${i18n.t('feeds')} ${feeds.map((f) => f.name).join(', ')}`,
    profiles && !!profiles.length && `${i18n.t('profiles')} ${profiles.map((f) => f.name).join(', ')}`,
    alerts && !!alerts.length && `${i18n.t('alerts')} ${alerts.map((f) => f.name).join(', ')}`,
  ]).join('; ')
}
// bitwise operations // TODO unit sets

/**
 * Set bit or bits in number, given by mask of ones
 * Eg. setBit(0b0101, 0b0011) === 0b0111
 * @param number
 * @param mask
 */
/* eslint-disable-next-line no-bitwise */
export const setBit = (number: number, mask: number): number => number | mask

/**
 * Clear bit or bits, in number, given by mask of ones
 * Eg. clearBit(0b0101, 0b0011) === 0b0100
 * @param number
 * @param mask
 */
/* eslint-disable-next-line no-bitwise */
export const clearBit = (number: number, mask: number): number => number & ~mask

/**
 * Determine whether bit or some of bits given by mask is/are set in number
 * Eg isBitSet(0b0101, 0b0011) === true
 * @param number
 * @param mask
 */
/* eslint-disable-next-line no-bitwise */
export const isBitSet = (number: number, mask: number): boolean => Boolean(number & mask)

export const differenceById = R.differenceWith(R.eqProps('id'))

export function getTimeZoneHours() {
  // 60 because there are 60 minutes in an hour, and minus because we want to reverse sign
  let timeZone: number | string = new Date().getTimezoneOffset() / -60
  if (timeZone > 0) {
    timeZone = `+${timeZone}`
  }
  return timeZone
}

export const getIntlTimeZone = (separatedBy: string | boolean = false): string =>
  separatedBy ? momentTz.tz.guess().split('/').join(separatedBy) : momentTz.tz.guess()

export const handleErrors = (response: Response): Response | Promise<any> => {
  if (!response.ok) {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({ message: response.statusText, status: response.status })
  }
  return response
}

import React from 'react'
import R from 'ramda'
import { connect } from 'react-redux'

import ArticleReactComponent from '../preview'
import EditableArticle from './EditableArticle'
import * as ActionTypes from '../../../constants/actionTypes'
import buildActionCreators from '../../../helpers/buildActionCreators'
import {
  getEditedArticle,
  getEditedArticlePreviewMode,
  getEditedArticleXHRStatus,
} from '../../../selectors/articlesSelectors'
import type { Article, XhrStatus } from '../../../opoint/flow'

const EditableArticleComponent = EditableArticle(ArticleReactComponent)

type EditArticleProps = {
  article: Article
  updateArticle: ({ article: Article, updatedArticleData: Object }) => void
  editedArticleXHRStatus: XhrStatus
  editedArticlePreviewMode: boolean
  previewModeOn: () => void
  previewModeOff: () => void
}

class EditArticle extends React.PureComponent<EditArticleProps> {
  updateArticle = (article, updatedArticleData) => {
    this.props.updateArticle({
      article,
      updatedArticleData,
    })
  }

  render() {
    const { article, editedArticleXHRStatus, editedArticlePreviewMode, previewModeOn, previewModeOff } = this.props

    return (
      <div>
        <EditableArticleComponent
          article={article}
          updateArticle={this.updateArticle}
          // @ts-ignore
          getString={R.identity}
          xhrStatus={editedArticleXHRStatus}
          previewMode={editedArticlePreviewMode}
          previewModeOn={previewModeOn}
          previewModeOff={previewModeOff}
        />
      </div>
    )
  }
}

export default connect(
  (state) => ({
    article: getEditedArticle(state),
    editedArticleXHRStatus: getEditedArticleXHRStatus(state),
    editedArticlePreviewMode: getEditedArticlePreviewMode(state),
  }),
  buildActionCreators({
    updateArticle: ActionTypes.EDIT_ARTICLE,
    previewModeOn: ActionTypes.EDIT_ARTICLE_PREVIEW_MODE_ON,
    previewModeOff: ActionTypes.EDIT_ARTICLE_PREVIEW_MODE_OFF,
  }),
  // @ts-ignore
)(EditArticle)

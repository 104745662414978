import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { OverlayTrigger, Popover } from 'react-bootstrap'

import buildActionCreators from '../../helpers/buildActionCreators'
import NotificationComponent from '../notifications/Notification'
import Translate from '../../Translate'
import { getNotificationsCount, getNotifications } from '../../selectors/notificationsSelectors'
import * as ActionTypes from '../../constants/actionTypes'
import type { Notification, Action } from '../../opoint/flow'

type Props = {
  notificationsCount: number
  notifications: Array<Notification>
  loadMoreNotifications: () => Action<any>
  downloadReport: (id: number) => Action<any>
  openNotifications: () => Action<any>
}

class NotificationsPopover extends PureComponent<Props> {
  constructor(props) {
    super(props)

    this.popoverRef = null
  }

  onDownloadClickHandler = (data) => this.props.downloadReport(data)

  onLoadClickHandler = () => {
    this.props.loadMoreNotifications()
  }

  onEnterNotifHandler = () => {
    this.props.openNotifications()
  }

  popoverRef: any

  render() {
    const { notificationsCount, notifications } = this.props

    const overlay = (
      <Popover id="popover-positioned-left" className="op-notifications">
        <div className="col-md-12 wrapper">
          <div className="row op-notifications_wrapper">
            {(!notifications || !notifications.length) && (
              <div className="op-notifications-no-notifications">
                <Translate i18nString="notifications to display" />
              </div>
            )}

            {notifications && !!notifications.length && (
              <div className="col-md-12">
                {notifications.map((notification) =>
                  notification.object && notification.object.type === 'report' ? (
                    <div key={notification.id} className="row op-notifications-item  mod-report">
                      <NotificationComponent notification={notification} onClickHandler={this.onDownloadClickHandler} />
                    </div>
                  ) : (
                    <div className="op-notifications-item">
                      <span>{notification.title}</span>
                    </div>
                  ),
                )}
              </div>
            )}
          </div>

          {notifications && !!notifications.length && (
            <div className="col-md-12">
              <div className="row">
                <div className="op-notifications-load-more" onClick={this.onLoadClickHandler}>
                  <Translate i18nString="Load more" />
                </div>
              </div>
            </div>
          )}
        </div>
      </Popover>
    )

    return (
      <OverlayTrigger
        trigger="click"
        rootClose
        ref={(ref) => {
          this.popoverRef = ref
        }}
        placement="bottom"
        overlay={overlay}
        onEnter={this.onEnterNotifHandler}
      >
        <li className="op-content-top-header-rest-user-list-item user-options user-options__badge mod-full-height u-all-center">
          {!!notificationsCount && (
            <span className="op-content-top-header-rest-user-list-item-badge">{notificationsCount}</span>
          )}
          <i className="op-icon-bell-small" />
        </li>
      </OverlayTrigger>
    )
  }
}

const NotificationsPopoverWrapped = connect(
  (state) => ({
    notificationsCount: getNotificationsCount(state),
    notifications: getNotifications(state),
  }),
  buildActionCreators({
    loadMoreNotifications: ActionTypes.NOTIFICATIONS_FETCH,
    downloadReport: ActionTypes.NOTIFICATION_DOWNLOAD_MODAL_OPEN,
    openNotifications: ActionTypes.NOTIFICATIONS_OPEN,
  }),
)(NotificationsPopover)

export default NotificationsPopoverWrapped

// @ts-nocheck
import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { translate } from 'react-i18next'

import buildActionCreators from '../../helpers/buildActionCreators'
import entityFilter from '../common/entityFilter'
import FilterMessage from '../common/FilterMessage'
import ModulePermissions from '../common/ModulePermissions'
import Translate from '../../Translate'
import { getAlertId } from '../../selectors/routingSelectors'
import { getUISetting, getMaxAlerts } from '../../selectors/settingsSelectors'
import { MODULE_OPTION } from '../../opoint/settings/index'
import {
  getActiveAlerts,
  getByRecipientsFilterableAlerts,
  getInactiveAlerts,
  getAlertsCount,
} from '../../selectors/alertsSelectors'
import * as ActionTypes from '../../constants/actionTypes'
import type { Alert, Settings } from '../../opoint/flow'
import Tooltip from '../../new-components/common/Tooltip'

const alertListItem = ({ alert, activeAlertId, openAlert, editAlert, i18n }) => {
  const { id, subject } = alert
  return (
    <li key={id} title={subject}>
      <div className="radio-container">
        <div>
          <div className="op-radio left-column-radio">
            <input type="radio" id={id} checked={id === activeAlertId} readOnly />
            <label htmlFor={id} onClick={openAlert(id)} />
          </div>
          <span className="radio-desc" onClick={openAlert(id)}>
            {subject}
          </span>
        </div>
      </div>
    </li>
  )
}

const AlertListItem = translate([], { wait: true })(alertListItem)

const createNewAlertButton = ({ onClick, i18n, disabled, maxAlerts }) => (
  <div style={{ position: 'relative', display: 'inline-block' }}>
    {disabled && (
      <Tooltip content={`${i18n.t('You have reached your maximum of')} ${maxAlerts} ${i18n.t('alerts')}`}>
        <div className="toolip-trigger" />
      </Tooltip>
    )}
    <button className="op-button-square" title={i18n.t('Create new alert')} onClick={onClick} disabled={disabled}>
      <span className="op-icon-plus" />
    </button>
  </div>
)
const CreateNewAlertButton = translate([], { wait: true })(createNewAlertButton)

type Props = {
  activeAlerts: Array<Alert>
  expanded?: boolean
  filterExpression?: string
  inactiveAlerts: Array<Alert>
  onEditAlertClick: (alertId: number) => void
  onNewAlertClick: () => void
  onOpenAlertClick: ({ alertId: number }) => void
  toggleCategory: (category: Settings) => void
}

class AlertListing extends React.Component<Props> {
  onToggleCategoryClick = () => this.props.toggleCategory('ALERTS_CATEGORY')
  editAlertHandler = (alertId) => () => this.props.onEditAlertClick(alertId)
  openAlertHandler = (alertId) => () => this.props.onOpenAlertClick({ alertId })
  toggleInactiveAlerts = () => this.props.toggleCategory('DISPLAY_INACTIVE')

  isExpanded() {
    const { expanded, filterExpression } = this.props
    return expanded || !!filterExpression
  }

  render() {
    const {
      selectedAlert,
      displayInactive,
      activeAlerts,
      inactiveAlerts,
      displayImportant,
      filterExpression,
      numberOfFiltered,
      toggleCategory,
      showImportantSetting,
      resetFilter,
      list,
      maxAlerts,
      onNewAlertClick,
      alertsCount,
    } = this.props

    if (!activeAlerts.length && !inactiveAlerts.length) {
      return <div />
    }

    const isAlertsLimitReached = alertsCount >= maxAlerts

    return (
      <div className="col-md-12">
        <div className="row">
          <div className="entity">
            <div className="entity__header">
              <i
                className={classNames('op-icon-arrow-down', { 'title--expanded': this.isExpanded() })}
                onClick={this.onToggleCategoryClick}
              />
              <h3 onClick={this.onToggleCategoryClick}>
                <Translate i18nString="Alerts" />
              </h3>
              <ModulePermissions module="ALERT_MODULE" permissions={[MODULE_OPTION.ON]}>
                <div className="entity__options">
                  <CreateNewAlertButton
                    onClick={onNewAlertClick}
                    disabled={isAlertsLimitReached}
                    maxAlerts={maxAlerts}
                  />
                </div>
              </ModulePermissions>
            </div>
          </div>
          <div className={classNames('entity__body', { 'entity_body--hidden': !this.isExpanded() })}>
            {numberOfFiltered ? (
              <ul>
                {list.map(
                  (alert) =>
                    !!alert.id && (
                      <AlertListItem
                        activeAlertId={selectedAlert}
                        alert={alert}
                        editAlert={this.editAlertHandler}
                        key={alert.id}
                        openAlert={this.openAlertHandler}
                      />
                    ),
                )}
              </ul>
            ) : (
              <ul>
                {activeAlerts.map(
                  (alert) =>
                    !!alert.id && (
                      <AlertListItem
                        activeAlertId={selectedAlert}
                        alert={alert}
                        editAlert={this.editAlertHandler}
                        key={alert.id}
                        openAlert={this.openAlertHandler}
                      />
                    ),
                )}
                {!!inactiveAlerts.length && (
                  <li className="toggleAlerts" onClick={this.toggleInactiveAlerts}>
                    {displayInactive ? (
                      <Translate
                        i18nString="Hide {{count}} inactive alerts"
                        context={{
                          count: inactiveAlerts.length,
                        }}
                      />
                    ) : (
                      <Translate
                        i18nString="Show {{count}} inactive alerts"
                        context={{
                          count: inactiveAlerts.length,
                        }}
                      />
                    )}
                  </li>
                )}
                {displayInactive &&
                  inactiveAlerts.map(
                    (alert) =>
                      !!alert.id && (
                        <AlertListItem
                          activeAlertId={selectedAlert}
                          alert={alert}
                          editAlert={this.editAlertHandler}
                          key={alert.id}
                          openAlert={this.openAlertHandler}
                        />
                      ),
                  )}
              </ul>
            )}
            {numberOfFiltered && (
              <FilterMessage
                displayImportant={displayImportant}
                filterExpression={filterExpression}
                listLength={list.length}
                listName="alerts"
                numberOfFiltered={numberOfFiltered}
                showImportantSetting={showImportantSetting}
                toggleCategory={toggleCategory}
                resetFilter={resetFilter}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}

AlertListing = entityFilter(AlertListing)

AlertListing = connect(
  (state, ownProps) => ({
    list: getByRecipientsFilterableAlerts(state), // for entityFilter HOC
    activeAlerts: getActiveAlerts(state),
    selectedAlert: getAlertId(state),
    inactiveAlerts: getInactiveAlerts(state),
    displayInactive: getUISetting('DISPLAY_INACTIVE')(state),
    expanded: getUISetting('ALERTS_CATEGORY')(state),
    maxAlerts: getMaxAlerts(state),
    alertsCount: getAlertsCount(state),
  }),
  {
    onEditAlertClick: (alertId) => push(`/alerts/${alertId}/edit`),
    ...buildActionCreators({
      onOpenAlertClick: ActionTypes.GO_TO_ALERT,
      onNewAlertClick: ActionTypes.CREATE_ALERT,
      toggleCategory: ActionTypes.TOGGLE_UI_SETTING,
      resetFilter: ActionTypes.ENTITY_FILTER_CHANGED,
    }),
  },
)(AlertListing)

export default AlertListing

import React from 'react'
import { DragSource, DropTarget } from 'react-dnd'
import classNames from 'classnames'

import Icon from '../../common/Icon'
import Checkbox from '../../common/Checkbox'
import styles from './index.module.scss'

// TODO update react-dnd and react-dnd-html5-backend to latest and remake this component using hooks.
// This is a temporary solution. We need to update react-dnd and react-dnd-html5-backend to latest version so we can use useDrag and useDrop hooks.
// We use DragNDrop on multiple places in the project and it's not backwards compatible, so we need to adjust each of them at once with the update.

interface Props {
  label: string
  value: string
  index: number
  checked: boolean
  onChange: () => void
  dropHandler: (obj: { targetValue: string; sourceValue: string }) => void
  hoverHandler?: (obj: { oldIndex: number; newIndex: number }) => void
  // DND props
  isDragging?: boolean
  isOver?: boolean
  connectDragSource?: any
  connectDropTarget?: any
}

const cardSource = {
  beginDrag(props: Props) {
    return props
  },

  endDrag(_, monitor) {
    if (!monitor.didDrop()) {
      return
    }
  },
}

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }
}

const columnTarget = {
  drop(props: Props, monitor) {
    props.dropHandler({
      targetValue: props.value,
      sourceValue: monitor.getItem().value,
    })
  },

  canDrop() {
    return true
  },
}

function columnDropCollect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  }
}

let DragNDropOption: React.FC<Props> = (props) => {
  const { isDragging, connectDragSource, connectDropTarget, isOver, label, value, onChange, checked } = props

  return connectDragSource(
    connectDropTarget(
      <li
        className={classNames(
          styles.dnditem,
          {
            [styles.wrapperIsOver]: isOver && !isDragging,
          },
          { [styles.isDragging]: isDragging },
        )}
      >
        <div>
          <Checkbox label={label} value={value} onChange={onChange} checked={checked} />
          <div className={styles.dragIcon}>
            <Icon verticalCenter name="hamburger" />
          </div>
        </div>
      </li>,
    ),
  )
}

DragNDropOption = DragSource('DND_OPTION', cardSource, collect)(DragNDropOption)
DragNDropOption = DropTarget('DND_OPTION', columnTarget, columnDropCollect)(DragNDropOption)

export default DragNDropOption

import R from 'ramda'
import { createSelector } from 'reselect'

import { getStatistics as getState, getForm } from './rootSelectors'
import { searchContext } from './routingSelectors'
import type {} from '../opoint/flow'
import { SPECIAL_ASPECTS_IDS, getSelectedTagLikeEntities } from '../opoint/statistics/aspects'

export const getAspects = createSelector(getState, (statState) => statState.aspects)

export const getSelectedAspects = createSelector(getAspects, (aspects) => aspects.filter(({ selected }) => selected))

export const getAspectById = (aspectId: number) =>
  createSelector(getAspects, (aspects) => aspects.find(({ id }) => aspectId === id))

export const areStatisticsOpen = createSelector(searchContext, (statState) => statState === 'statistics')

export const areStatisticsFiltered = createSelector(getState, (statState) => !R.isEmpty(statState.filters))

/*
 * selector for selecting selected tags of special aspects
 */

const getSelectedTagLikeEntitiesOfSelectedAspect = (aspect) =>
  (aspect && aspect.selected && getSelectedTagLikeEntities(aspect)) || []

export const getSelectedTags = createSelector(
  getAspectById(SPECIAL_ASPECTS_IDS.TAG),
  getSelectedTagLikeEntitiesOfSelectedAspect,
)

export const getSelectedSentiments = createSelector(
  getAspectById(SPECIAL_ASPECTS_IDS.SENTIMENT),
  getSelectedTagLikeEntitiesOfSelectedAspect,
)

export const getSelectedAnalysis = createSelector(
  getAspectById(SPECIAL_ASPECTS_IDS.ANALYSIS),
  getSelectedTagLikeEntitiesOfSelectedAspect,
)

export const getSelectedProfiles = createSelector(
  getAspectById(SPECIAL_ASPECTS_IDS.PROFILE),
  getSelectedTagLikeEntitiesOfSelectedAspect,
)

export const getSubQueries = createSelector(getSelectedProfiles, (profiles) => profiles.map(({ id }) => ({ id })))

export const getBaskets = createSelector(
  getSelectedTags,
  getSelectedSentiments,
  getSelectedAnalysis,
  (tags, sentiments, analysis) => [...tags, ...sentiments, ...analysis].map(({ id }) => id).join(','),
)

export const getActiveViewId = createSelector(getState, (statState) => statState.activeStatView)

export const getActiveStatView = createSelector(getState, getActiveViewId, (statState, id) =>
  statState.list.find((view) => view.id === id),
)

export const getActiveStatViewName = createSelector(
  getActiveStatView,
  (statisticsView) => statisticsView && statisticsView.name,
)

export const getFilteredArticles = createSelector(getState, (statState) => statState.filteredArticles)

export const getShowFilteredArticles = createSelector(getState, (statState) => statState.showFilteredArticles)

export const getChangedAspectsType = createSelector(getState, (statState) => statState.changedAspectsType)

export const getChangedAspectsCountBy = createSelector(getState, (statState) => statState.changedAspectsCountBy)

export const getStatisticsList = createSelector(getState, (statState) => statState.list)

export const getAspectCombo = createSelector(getState, (statState) =>
  R.flatten(
    statState.aspects
      .filter(({ overlap }) => overlap > 0)
      .map(({ id, overlap, overlapMode }) => [id, overlapMode * overlap]),
  ),
)

export const getCountBy = createSelector(getState, (statState) => statState.countBy)

export const getEditName = createSelector(getForm, (formState) => formState.view.edit.values.name)

export const getComputedAspectGroup = createSelector(getState, (statState) => statState.computedAspectGroup)

export const getStatDocuments = createSelector(getState, (statState) => statState.documents)

export const getStatFilters = createSelector(getState, (statState) => statState.filters)

export const isStatLoading = createSelector(getState, (statState) => statState.loading)

export const getAspectsRequested = createSelector(getState, (statState) => statState.aspectsRequested)

// @ts-nocheck
import * as React from 'react'

type Props = {
  id: number | string
  /*
   * True - Checked
   * False - Unchecked
   * Null - Indeterminate
   */
  isChecked?: boolean
  name: string
  onChange: Function
  style?: Object
  value: any
}

export default class Checkbox extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props)

    this.ref = null
  }

  componentDidMount() {
    this.isIndeterminate()
  }

  componentDidUpdate() {
    this.isIndeterminate()
  }

  isIndeterminate = () => {
    const { isChecked } = this.props

    // Do not alter the statement
    // Strict check against null is intended
    if (isChecked === null) {
      this.ref.indeterminate = true
    } else {
      this.ref.indeterminate = false
    }
  }

  render() {
    const { id, value, isChecked, name, onChange, style } = this.props

    return (
      <div className="op-checkbox">
        <input
          ref={(ref) => {
            this.ref = ref
          }}
          checked={!!isChecked}
          id={id}
          name={name}
          type="checkbox"
          value={value}
        />
        <label htmlFor={id} onClick={onChange} style={style} />
      </div>
    )
  }
}

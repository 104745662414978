import React from 'react'
import { connect } from 'react-redux'

import TimeSchedule from './TimeSchedule'
import { getAlertTimeConfiguration, getAlertFormAddValueTime } from '../../../selectors/alertsSelectors'

type Props = {
  timeConfiguration: Object
  whenAlert: number
}

const TimeScheduleAddAlertComponent = ({ timeConfiguration, whenAlert }: Props) => (
  <div className="col-md-3 op-content-right_wrapper mod-alerts">
    <TimeSchedule timeConfiguration={timeConfiguration} whenAlert={whenAlert} />
  </div>
)

const TimeScheduleAddAlert = connect((state) => ({
  timeConfiguration: getAlertTimeConfiguration(state),
  whenAlert: getAlertFormAddValueTime(state),
}))(TimeScheduleAddAlertComponent)

export default TimeScheduleAddAlert

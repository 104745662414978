import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { OverlayTrigger, Popover } from 'react-bootstrap'

import buildActionCreators from '../../helpers/buildActionCreators'
import ModulePermissions from '../common/ModulePermissions'
import Translate from '../../Translate'
import { MODULE, MODULE_OPTION } from '../../opoint/settings/index'
import * as ActionTypes from '../../constants/actionTypes'
import { getUser } from '../../selectors/authSelectors'
import { setReleaseNotesViewed } from '../../opoint/version/index'

type Props = {
  contactModalOpen: () => any
  helpModalOpen: () => any
  logout: () => any
  reportsHistoryOpen: () => any
  settingsModalOpen: () => any
  sourceListModalOpen: () => any
  user: { username: string }
  templateEditOpen: any
  releaseNotesModalOpen: any
  hideNewVersionNotification: any
}

class UserPopover extends PureComponent<Props> {
  popoverRef: any

  constructor(props) {
    super(props)

    this.popoverRef = null
  }

  onSettingsClickHandler = () => {
    this.popoverRef.hide()
    this.props.settingsModalOpen()
  }

  onReportsHistoryClickHandler = () => {
    this.popoverRef.hide()
    this.props.reportsHistoryOpen()
  }

  onSourceListClickHandler = () => {
    this.popoverRef.hide()
    this.props.sourceListModalOpen()
  }

  onHelpClickHandler = () => {
    this.popoverRef.hide()
    this.props.helpModalOpen()
  }

  onContactsClickHandler = () => {
    this.popoverRef.hide()
    this.props.contactModalOpen()
  }

  onLogoutClickHandler = () => {
    this.popoverRef.hide()
    this.props.logout()
  }

  onTemplateClickHandler = () => {
    this.popoverRef.hide()
    this.props.templateEditOpen()
  }

  onReleaseNotesClickHandler = () => {
    this.popoverRef.hide()
    this.props.releaseNotesModalOpen()
    this.props.hideNewVersionNotification()
    setReleaseNotesViewed()
  }

  render() {
    const {
      user: { username },
    } = this.props

    const overlay = (
      <Popover id="popover-positioned-left" className="op-user-options">
        <div className="op-user-options-info">{username}</div>
        <ul className="op-user-options-list">
          <ModulePermissions module={MODULE.CONTACT} permissions={[MODULE_OPTION.ON, MODULE_OPTION.READ_ONLY]}>
            <li className="op-user-options-list-item" onClick={this.onContactsClickHandler}>
              <div className="op-user-options-list-item-icon-wrapper">
                <i className="op-icon-menu" data-test="menu-contacts" />
              </div>
              <Translate i18nString="Contacts" />
            </li>
          </ModulePermissions>
          <li className="op-user-options-list-item" onClick={this.onTemplateClickHandler}>
            <div className="op-user-options-list-item-icon-wrapper">
              <i className="op-icon-activity" />
            </div>
            <Translate i18nString="Template editor" />
          </li>
          <ModulePermissions module={MODULE.SETTING} permissions={[MODULE_OPTION.ON]}>
            <li className="op-user-options-list-item" onClick={this.onSettingsClickHandler}>
              <div className="op-user-options-list-item-icon-wrapper">
                <i className="op-icon-gear" />
              </div>
              <span data-test="settings">
                <Translate i18nString="Settings" />
              </span>
            </li>
          </ModulePermissions>
          <ModulePermissions module={MODULE.REPORT} permissions={[MODULE_OPTION.ON]}>
            <li className="op-user-options-list-item" onClick={this.onReportsHistoryClickHandler}>
              <div className="op-user-options-list-item-icon-wrapper">
                <i className="op-icon-file" />
              </div>
              <Translate i18nString="Report history" />
            </li>
          </ModulePermissions>
          <li className="op-user-options-list-item" onClick={this.onSourceListClickHandler}>
            <div className="op-user-options-list-item-icon-wrapper">
              <i className="op-icon-pencil" />
            </div>
            <Translate i18nString="Source lists" />
          </li>
          <li className="op-user-options-list-item" onClick={this.onHelpClickHandler}>
            <div className="op-user-options-list-item-icon-wrapper">
              <i className="op-icon-question" />
            </div>
            <Translate i18nString="Help" />
          </li>
          <li className="op-user-options-list-item" onClick={this.onReleaseNotesClickHandler}>
            <div className="op-user-options-list-item-icon-wrapper">
              <i className="op-icon-new_releases" />
            </div>
            <Translate i18nString="What's new in M360" />
          </li>
          <li className="op-user-options-list-item" data-test="menu-contacts" onClick={this.onLogoutClickHandler}>
            <div className="op-user-options-list-item-icon-wrapper">
              <i className="op-icon-power" data-test="menu-log-out" />
            </div>
            <Translate i18nString="Log out" />
          </li>
        </ul>
      </Popover>
    )

    return (
      <OverlayTrigger
        trigger="click"
        rootClose
        ref={(ref) => {
          this.popoverRef = ref
        }}
        placement="bottom"
        overlay={overlay}
      >
        <li className="op-content-top-header-rest-user-list-item user-options u-no-padding op-user-with-bg">
          <div className="mod-margin-center">
            <i className="op-icon-user op-user-light-color" />
          </div>
        </li>
      </OverlayTrigger>
    )
  }
}

const UserPopoverWrapped = connect(
  (state) => ({
    user: getUser(state),
  }),
  buildActionCreators({
    contactModalOpen: ActionTypes.CONTACT_MODAL_OPEN,
    helpModalOpen: ActionTypes.HELP_MODAL_OPEN,
    logout: ActionTypes.LOGOUT,
    reportsHistoryOpen: ActionTypes.REPORTS_HISTORY_OPEN,
    settingsModalOpen: ActionTypes.SETTINGS_MODAL_OPEN,
    sourceListModalOpen: ActionTypes.SOURCE_LIST_OPEN,
    templateEditOpen: ActionTypes.TEMPLATE_EDITOR_MODAL_OPEN,
    releaseNotesModalOpen: ActionTypes.RELEASE_NOTES_MODAL_OPEN,
    hideNewVersionNotification: ActionTypes.HIDE_NEW_VERSION_NOTIFICATION,
  }),
)(UserPopover)

export default UserPopoverWrapped

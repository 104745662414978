import React from 'react'

type Props = {
  tag: { name: string; id: number }
  onDelete?: (any) => void
}

const Tag = ({ onDelete, tag }: Props) => (
  <span title={`${tag.name} (${tag.id})`} className="filter-tag">
    <span className="filter-tag__tag" onClick={onDelete}>
      <span className="filter-tag__label">{tag.name}</span>
      <span className="filter-tag__controls">
        <i className="op-icon-delete" onClick={onDelete} />
      </span>
    </span>
  </span>
)

export default Tag

import React from 'react'
import PropTypes from 'prop-types'

import type { Article } from '../../../opoint/flow'
import OpointTextWithMatchesAndEntities from '../../../new-components/articles/OpointTextWithMatchesAndEntities'

type Props = {
  article: Article
  editable: Boolean
  onOpenImagePraview: (index: number) => void
}

export default class ArticleImagesPreview extends React.Component<Props> {
  static propTypes = {
    editable: PropTypes.bool.isRequired,
  }

  static contextTypes = {
    getString: PropTypes.func,
    URLService: PropTypes.object,
  }

  shouldComponentUpdate = () => false

  onClick = (index: number) => () => this.props.onOpenImagePraview(index)

  render() {
    const { article, editable } = this.props
    const { URLService } = this.context

    return (
      <div>
        {article.articleimages?.articleimage.map((articleImage, index) => {
          const imageDesc = articleImage.caption && (
            <div>
              <OpointTextWithMatchesAndEntities text={articleImage.caption ? articleImage.caption.text : ''} />
            </div>
          )

          return (
            <figure key={index} className="article-image">
              {editable && 'X'}
              {articleImage.url && (
                <a onClick={this.onClick(index)}>
                  <img src={URLService.getPreviewThumbnailUrl(articleImage.url, 200)} alt="" />
                </a>
              )}
              <figcaption>{imageDesc}</figcaption>
            </figure>
          )
        })}
      </div>
    )
  }
}

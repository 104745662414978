import React from 'react'
import classNames from 'classnames'

import { wrapper } from './index.module.scss'

interface Props {
  children?: any
  className?: string
  flexDirection?: 'row' | 'column'
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'initial' | 'inherit'
  flexWrap?: 'wrap' | 'nowrap' | 'wrap-reverse'
  alignItems?: 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'initial' | 'inherit'
  flexGrow?: number
  flexShrink?: number
  flexBasis?: number
  flex?: string
}

export default function Flexbox(props: Props) {
  return (
    <div
      className={classNames(wrapper, props.className)}
      style={{
        justifyContent: props.justifyContent || 'flex-start',
        flexDirection: props.flexDirection || 'row',
        flexGrow: props.flexGrow || 0,
        flexBasis: props.flexBasis || 'auto',
        flexShrink: props.flexShrink || 1,
        flexWrap: props.flexWrap || 'nowrap',
        flex: props.flex || '0 1 auto',
        alignItems: props.alignItems || 'stretch',
      }}
    >
      {props.children}
    </div>
  )
}

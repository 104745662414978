import PropTypes from 'prop-types'
import React from 'react'

import Checkbox from './Checkbox'

type GroupCheckboxProps = {
  list: Array<any>
  keyFn: Function
  valueFn: Function
  renderComponent: Function
  filterExp: string
  filteringFn: Function
  onFilterChange: (value: string) => void
  isChecked: Function
  onToggle: Function
}

export default class GroupCheckbox extends React.Component<GroupCheckboxProps> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  onChangeHandler = ({ target: { value } }) => {
    this.props.onToggle(value)
  }

  onFilterChangeHandler = ({ target: { value } }) => {
    this.props.onFilterChange(value)
  }

  render() {
    const { list, keyFn, valueFn, isChecked, filterExp, filteringFn, renderComponent } = this.props

    const { i18n } = this.context

    const filteredList = filterExp ? list.filter((item) => filteringFn(filterExp, item)) : list

    // TODO: accessibility (checkboxes cannot be selected with keyboard)
    return (
      <div className="op-checkbox-list-input mod-statistics">
        {list.length > 10 && (
          <input type="text" placeholder={i18n.t('Filter')} value={filterExp} onChange={this.onFilterChangeHandler} />
        )}
        <ul className="op-checkbox-list-input-list">
          {filteredList.map((item) => (
            <li key={keyFn(item)} className="op-checkbox-list-input-list-item">
              <Checkbox
                classNameMod="bordered"
                id={keyFn(item)}
                isChecked={isChecked(item)}
                name={item.name}
                onChangeHandler={this.onChangeHandler}
                value={valueFn(item)}
              >
                {renderComponent(item)}
              </Checkbox>
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

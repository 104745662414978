import React from 'react'

import { IMAGES } from '../../../constants'
import Translate from '../../common/Translate'
import Icon from '../../common/Icon'
import styles from './ArticlePlaceholder.module.scss'

export type Props = {
  alignLeft: boolean
}

const ArticlePlaceholder = ({ alignLeft }: { alignLeft: boolean }) => (
  <div id="article-placeholder" className={styles.wrapper}>
    <p>
      {alignLeft && <Icon name="ifm-arrow-left" />}
      <Translate text="Select an article" />
      {!alignLeft && <Icon name="ifm_arrow-right" />}
    </p>
    <p>
      <img src={IMAGES.article_not_selected} width="200" alt="Article" title="Article" />
    </p>
  </div>
)

export default ArticlePlaceholder

import React, { InputHTMLAttributes, ReactNode } from 'react'
import classNames from 'classnames'

import { IconName } from '../../types/theme'
import Icon from '../Icon'
import styles from './index.module.scss'

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  mode?: 'default' | 'delete'
  label?: ReactNode
  testid?: string
}

export default function Checkbox({ label, testid, mode = 'default', ...inputProps }: CheckboxProps) {
  let iconName: IconName = 'checked'
  switch (mode) {
    case 'delete':
      iconName = 'bin'
      break
    case 'default':
    default:
      iconName = 'checked'
  }

  return (
    <label
      className={classNames(
        styles.wrapper,
        { [styles.disabled]: inputProps.disabled },
        { [styles.delete]: mode === 'delete' },
      )}
    >
      <div className={styles.checkbox}>
        <input type="checkbox" data-testid={testid} {...inputProps} />
        <span className={styles.checkboxControl}>
          <Icon name={iconName} verticalCenter />
        </span>
      </div>
      {label}
    </label>
  )
}

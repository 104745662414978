import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import IdenticalArticles from './common/IdenticalArticles'
import SelectArticleCheckbox from './SelectArticleCheckbox'
import ImageComponent from '../../new-components/articles/ImageComponent'
import { ArticleListingTags } from '../tags/ArticleListingTags'
import { Colorbar } from './common/colorbar'
import type { Article } from '../../opoint/flow'
import OpointTextWithMatchesAndEntities from '../../new-components/articles/OpointTextWithMatchesAndEntities'

class ArticleListingReactComponent extends React.Component<any> {
  static propTypes = {
    article: PropTypes.object.isRequired,
    showShortSummary: PropTypes.bool,
    imagePosition: PropTypes.oneOf(['left', 'right']),
    imageSize: PropTypes.oneOf(['normal', 'big']),
  }

  static defaultProps = {
    showShortSummary: false,
    imagePosition: 'left',
    imageSize: 'normal',
  }

  onCheckChange = () => {
    const {
      articleService: { toggleCheck },
    } = this.props
    toggleCheck()
  }

  onClick = (): ((index: number) => void) => this.props.onClickHandler(this.props.index)

  onChange = (article: Article): void => {
    this.props.onCheckedToggle(article)
  }

  getTitle = () => this.props.article.header.text

  render() {
    const { article, checked, originalArticle, active, shouldHideTags, index, imagePosition } = this.props

    // if article is deleted, we show an empty div
    if (article.deleted) {
      return <div />
    }

    const tags = shouldHideTags ? [] : this.props.tagsFn(article)

    /*
     Set Classes based on state
     */
    let wrapperClass = ''
    if (active) {
      wrapperClass += ' is-selected'
    }
    if (checked) {
      wrapperClass += ' is-checked'
    }
    if (article.deleted) {
      wrapperClass += ' is-deleted'
    }

    const imageElement = (
      <div
        className={classNames(`op-content-right-section-content-${imagePosition}`, {
          __pictureBig: this.props.imageSize === 'big',
        })}
      >
        <ImageComponent
          {...this.props}
          article={article.activeIdenticalArticle ? article.activeIdenticalArticle : article}
        />
        <ArticleListingTags tags={tags} article={originalArticle || article} />
      </div>
    )

    // Article listing (right column)
    return (
      <div className={`op-content-right-section-content_wrapper${wrapperClass}`} onClick={this.onClick}>
        <div className="op-content-highlight-wrapper">
          <div className="op-content-right-section-wrapper">
            <div className="col-md-1 op-content-right-section__checkbox">
              <div className="row">
                <SelectArticleCheckbox onChange={this.onChange} article={article} checked={checked} />
              </div>
              <div className="row op-content-right-section-content__numbering">{index + 1}</div>
            </div>
            {imagePosition === 'left' && imageElement}
            <div className="op-content-right-section-content">
              <span className="op-content-right-section-content-title">
                <OpointTextWithMatchesAndEntities text={this.getTitle()} />
              </span>
              <IdenticalArticles
                {...this.props}
                article={originalArticle || article}
                activeArticle={article}
                initiallyShown={1}
                type="listing"
              />
              {!!this.props.showShortSummary && this.props.article.short_summary && (
                <div>
                  <OpointTextWithMatchesAndEntities text={this.props.article.short_summary.text} />
                </div>
              )}
            </div>
            {imagePosition === 'right' && imageElement}
          </div>
          {!!this.props.showShortSummary && (
            <div className="op-content-right-section-short-summary">
              {!!this.props.article.author && (
                <span className="op-content-right-section-short-summary-author">
                  Author: <b>{this.props.article.author}</b>
                </span>
              )}
            </div>
          )}
        </div>
        <Colorbar
          colorbar={article.activeIdenticalArticle ? article.activeIdenticalArticle.colorbar : article.colorbar}
        />
      </div>
    )
  }
}

export default ArticleListingReactComponent

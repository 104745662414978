import R from 'ramda'

export function highlightWithSuggestionServerHint(expression: string, hint: string) {
  if (!hint) {
    return expression
  }
  const matchedIndexes = R.unnest(
    hint.split(',').map((x) => {
      const [start, end] = x.split('-')
      return R.range(+start, +end + 1)
    }),
  )

  const expressionSplitted = expression.split('')

  const r = expressionSplitted.reduce(
    ([res, hlActive], val, index) => {
      if (!hlActive && matchedIndexes.includes(index)) {
        return [`${res}<span class="text-highlight"><b>${val}`, true]
      }
      if (hlActive && !matchedIndexes.includes(index)) {
        return [`${res}</b></span>${val}`, false]
      }
      return [res + val, hlActive]
    },
    ['', false],
  )

  return r[0] + (r[1] ? '</b></span>' : '')
}

export function highlightSearched(text: string, search: string) {
  /* eslint no-useless-escape: "off" */

  const searchWords = search.trim().split(' ')
  let unsafeHTML = text

  searchWords.forEach((searchWord) => {
    if (searchWord.length) {
      const tempHTML = text.replace(
        new RegExp(searchWord.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&'), 'gi'),
        '<span class="text-highlight"><b>$&</b></span>',
      )

      unsafeHTML = tempHTML
    }
  })

  return unsafeHTML
}

import React from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'

import OpointTextWithMatchesAndEntities from './new-components/articles/OpointTextWithMatchesAndEntities'

type Props = {
  i18nString: string
  context?: Object
  isDangerous?: boolean
}

class Translate extends React.PureComponent<Props> {
  static contextTypes = {
    i18n: PropTypes.object.isRequired,
  }

  render() {
    const { i18nString, context, isDangerous } = this.props
    const { i18n } = this.context

    // In case there are HTML elements in context or i18 string
    return isDangerous ? (
      <span>
        <OpointTextWithMatchesAndEntities text={i18n.t(i18nString, context)} />
      </span>
    ) : (
      <span>{i18n.t(i18nString, context)}</span>
    )
  }
}

export default translate()(Translate)

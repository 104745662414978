// @ts-nocheck
import PropTypes from 'prop-types'
import React from 'react'

import SmartDate from '../../common/SmartDate'
import OpointDate from '../../../opoint/common/time'
import type { Article, DateFormat, articleListingType } from '../../../opoint/flow'

type ArticleSourceLineDateProps = {
  article: Article
  baseDate?: Date
  dateFormat: DateFormat
  relative: boolean
  type: articleListingType
  showSign?: boolean
}

/**
 * Component for viewing time of identical articles
 * @param article - identical article
 * @param baseDate - specific date
 * @param dateFormat - format of date
 * @param relative - is time relative
 * @param type - either listing or preview
 * @param showSign - show '+' and '-' sign
 */
export const ArticleSourceLineDate = ({
  article,
  baseDate,
  dateFormat,
  relative,
  type,
  showSign,
}: ArticleSourceLineDateProps) => (
  <div
    className="source-line__item source-line__item--date"
    title={OpointDate.format(article.unix_timestamp * 1000, 'FULL_DATE')}
  >
    <SmartDate
      timestamp={article.unix_timestamp * 1000}
      dateFormat={dateFormat}
      relative={relative}
      baseDate={baseDate}
      type={type}
      showSign={showSign}
    />
  </div>
)

ArticleSourceLineDate.contextTypes = {
  getString: PropTypes.func,
}

import R from 'ramda'
import { createSelector } from 'reselect'

import { TAG_TYPES } from '../opoint/tags'
import { generateBaskets, getTrashTagsVisibleOnArticle, getVisibleTrashTags } from '../opoint/tags/trash'
import { getAlertTags, getAllAlertTags } from './alertsSelectors'
import { getAnalyticsTags } from './analytics'
import { getArticleTrashTags } from './articlesSelectors'
import { getSelectedProfilesIds, getTrashTagFilterIds } from './searchSelectors'
import { getTags as getState, getTrashTags } from './rootSelectors'
import { getUISetting } from './settingsSelectors'

export const getTags = createSelector(getState, (tagsState) => tagsState.list)

export const getTagsWithType = createSelector(getTags, (list) => R.map(R.assoc('type', 'tag'), list))

export const getAllTrashTags = createSelector(getTrashTags, (trash) => trash.list)

export const getTagById = (tagId) =>
  createSelector(getTags, getAllAlertTags, getAnalyticsTags, (tags, alertTags, analyticsTags) =>
    [...tags, ...alertTags, ...analyticsTags].find(({ id }) => tagId === id),
  )

export const getLastUsedTagId = createSelector(getState, (tagsState) => tagsState.lastUsedTagId)

export const getLastUsedTag = createSelector(R.identity, getLastUsedTagId, (state, lastUsedTagId) =>
  getTagById(lastUsedTagId)(state),
)

export const getTrashTagById = (trashId) =>
  createSelector(getAllTrashTags, (trash) => trash.find(({ id }) => Math.abs(trashId) === id))

export const getGlobalTrashTag = createSelector(getAllTrashTags, (trash) =>
  trash.find(({ type }) => type === TAG_TYPES.GLOBAL_TRASH),
)

// get trash tags displayed in entity listing
export const getVisibleTrashTagsInListing = createSelector(
  getAllTrashTags,
  getSelectedProfilesIds,
  getTrashTagFilterIds,
  (trashTags, profiles, trashTagFilterIds) => getVisibleTrashTags(trashTags, profiles, trashTagFilterIds),
)

// get trash tags which are displayed in article footer
export const getActiveTrashTags = createSelector(
  getAllTrashTags,
  getVisibleTrashTagsInListing,
  getUISetting('TRASH_TAGS_VISIBLE'),
  getTrashTagFilterIds,
  getArticleTrashTags,
  (trashState, trashInListing, active, trashTagFilterIds, articleTrash) => (article) =>
    getTrashTagsVisibleOnArticle(trashState, trashInListing, active, trashTagFilterIds, articleTrash(article)),
)

export const getBaskets = createSelector(getTags, getAllTrashTags, getAllAlertTags, (tags, trashTags, alertTags) =>
  generateBaskets(tags, trashTags, alertTags),
)

export const getAllTagLikeEntities = createSelector(
  getTags,
  getActiveTrashTags,
  getAlertTags,
  (tags, trashTags, alertTags) => (article) => [...tags, ...trashTags(article), ...alertTags],
)

export const getFilteredTags = createSelector(getTags, (tags) =>
  // @ts-ignore
  R.memoize((type) => R.filter(R.propEq('type', type), tags)),
)

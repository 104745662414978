// @ts-nocheck

import React from 'react'
import classNames from 'classnames'

import { filterIsInverted, isNotInvertable, filterIsDetailsSupported } from './index'
import type { Filter } from '../flow'

type Props = {
  filter: Filter
  separator: string
  deleteHandler?: (filter: Filter) => void
  invertHandler?: (filter: Filter) => void
  onClickHandler?: (filter: Filter) => void
}

class FilterTag extends React.PureComponent<any> {
  onClickHandler = () => {
    const { filter, onClickHandler } = this.props
    onClickHandler && onClickHandler(filter)
  }

  onClickInvertHandler = (e) => {
    const { filter, invertHandler } = this.props
    invertHandler && invertHandler(filter)
    e.stopPropagation()
  }

  onClickDeleteHandler = (e) => {
    const { filter, deleteHandler } = this.props
    deleteHandler && deleteHandler(filter)
    e.stopPropagation()
  }

  render() {
    const { filter, separator, invertHandler } = this.props
    const isTrash = filter.type === 'trash'
    const nonInvertable = isNotInvertable(filter)

    return (
      <span
        title={filter.name}
        className={classNames('filter-tag', { 'filter-tag--inverted': filterIsInverted(filter) })}
      >
        <span className="filter-tag__tag" onClick={this.onClickHandler}>
          <span className="filter-tag__label">
            {isTrash && <i className="op-icon-bin" />}
            &nbsp;
            {filter.name}
          </span>
          <span className="filter-tag__controls">
            {invertHandler && !nonInvertable && (
              <i className="filter-tag__invert" onClick={this.onClickInvertHandler} />
            )}
            {filter.type && filterIsDetailsSupported(filter) && <i className="op-icon-info" />}
            <i className="op-icon-delete" onClick={this.onClickDeleteHandler} />
          </span>
        </span>
        {separator && <span className={`filter-tag__delimiter filter-tag--${separator}`} />}
      </span>
    )
  }
}

export default FilterTag

import React from 'react'
import PropTypes from 'prop-types'
import className from 'classnames'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { translate } from 'react-i18next'

import buildActionCreators from '../../helpers/buildActionCreators'
import Logo from '../../components/common/Logo'
import Translate from '../../Translate'
import * as ActionTypes from '../../constants/actionTypes'
import LoginByIpForm from './LoginByIpForm'

class LoginForm extends React.PureComponent<any> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { onLogin, username, password } = this.props

    if (!username || !password) {
      return false
    }

    return onLogin({ username, password })
  }

  render() {
    const {
      loginError,
      loginForm: {
        fields: {
          username: fieldUsername = {
            touched: false,
          },
          password: fieldPassword = {
            touched: false,
          },
        } = {},
      },
      username,
      password,
    } = this.props

    const { i18n } = this.context

    return (
      <form onSubmit={this.handleSubmit}>
        <div className={className('form-group', { 'has-error': fieldUsername.touched && !username })}>
          <div className="op-input-group  mod-login">
            <Field name="username" component="input" type="text" placeholder={i18n.t('User name')} autoFocus />
            <label htmlFor="username">
              <i className="op-icon-user" />
            </label>
          </div>
        </div>
        <div className={className('form-group', { 'has-error': fieldPassword.touched && !password })}>
          <div className="op-input-group  mod-login">
            <Field name="password" component="input" type="password" placeholder={i18n.t('Password')} />
            <label htmlFor="password">
              <i className="op-icon-key" />
            </label>
          </div>
        </div>
        <div className="op-login-remember-me">
          <div className="op-checkbox">
            {/* // TODO remember me - login */}
            <input type="checkbox" id="rememberMe" name="check" />
            <label htmlFor="rememberMe" />
          </div>
          <Translate i18nString="Remember me on this computer" />
        </div>

        <button type="submit" className="op-button  mod-login" id="submit" disabled={!password || !username}>
          <Translate i18nString="Submit" />
        </button>

        {document.location.origin === 'https://m360-dev.opoint.com' && (
          <div className="op-login-container-form-error-message animate" style={{ marginTop: 25 }}>
            <Translate i18nString="Warning: You are about to log into the development version of M360. Use this version only if you have problems with the production version or if you want to help us testing. The production version can be found on m360.opoint.com." />
          </div>
        )}

        {document.location.origin === 'https://m360-alpha.opoint.com' && (
          <div className="op-login-container-form-error-message animate" style={{ marginTop: 25 }}>
            <Translate i18nString="Warning: You are about to log into an obsolete version of M360. Use this version only if you have problems with the production version. The production version can be found on m360.opoint.com." />
          </div>
        )}
      </form>
    )
  }
}

// @ts-ignore
LoginForm = translate([], { wait: true })(LoginForm)

// @ts-ignore
LoginForm = connect(
  (state: any) => ({
    user: state.auth.user,
    loginForm: state.form.login,
    username: state.form.login.values.username,
    password: state.form.login.values.password,
    loginError: state.auth.loginError,
  }),
  buildActionCreators({
    onLogin: ActionTypes.LOG_IN,
  }),
)(LoginForm)

// @ts-ignore
LoginForm = reduxForm({
  form: 'login',
  initialValues: {
    username: '',
    password: '',
  },
  // @ts-ignore
})(LoginForm)

export default LoginForm

export const getAlerts = (appState) => appState.alerts
export const getArticles = (appState) => appState.articles
export const getAuth = (appState) => appState.auth
export const getProfiles = (appState) => appState.profiles
export const getSearch = (appState) => appState.search
export const getSettings = (appState) => appState.settings
export const getTags = (appState) => appState.tags
export const getUI = (appState) => appState.ui
export const getFeeds = (appState) => appState.feeds
export const getForm = (appState) => appState.form
export const getContacts = (appState) => appState.contacts
export const getGroups = (appState) => appState.groups
export const getNotifications = (appState) => appState.notifications
export const getStatistics = (appState) => appState.statistics
export const getRouting = (appState) => appState.routing
export const getTrashTags = (appState) => appState.trash
export const getEntities = (appState) => appState.entities
export const getReports = (appState) => appState.reports
export const getTemplates = (appState) => appState.templates
export const getAnalytics = (appState) => appState.analytics
export const getWatchIndexes = (appState) => appState.watchIndex

// @ts-nocheck
import ReactDOM from 'react-dom'
import jstz from 'jstz'
import R from 'ramda'
import Rx from 'rx-dom'

import config from '../common/config'
import { OpointTimestampToTimestamp } from '../common/time'
import type { Article, UpdatedArticleData, AddArticleSuggestionMediatype } from '../flow'
import { articleWithCorrectedImagePaths } from '../../new-components/helpers/article'

export const TYPE_PREVIEW = 'preview'
export const TYPE_LISTING = 'listing'

export function articleId(article: Article) {
  return `${article.id_site}_${article.id_article}`
}

/**
 * @return list of articleId of all identical articles of given article
 */
export function articleIdenticalIds(article: Article) {
  return getAllIdenticalArticles(article).map(articleId)
}

export const eqArticles = R.eqBy(articleId)

export function articleIdToArticle(articleId: string) {
  const [id_site, id_article] = articleId.split('_')

  return { id_site, id_article }
}

/**
 * @return active identical article or null if no identical articles are available
 */
export function getIdenticalArticle(identical, article: Article) {
  const index = identical[articleId(article)]

  if (isNaN(index) || !article.identical_documents) {
    return null
  }

  return article.identical_documents.document[index]
}

/**
 * Given an article, this function returns all articles (incl. identical articles)
 * @sig Article -> Array<Article>
 */
export const getAllIdenticalArticles = R.ifElse(
  (article) => article.identical_documents && article.identical_documents.document.length,
  (article) => article.identical_documents.document,
  (article) => [article],
)

export const ARTICLE_NORMAL = 0
export const ARTICLE_LOGIN = 1
export const ARTICLE_LOGIN_DOWNLOAD = 2
export const ARTICLE_NORMAL_SEPARATE_VIP = 3
export const ARTICLE_GALLERY = 4
export const ARTICLE_NOT_OLE = 5
export const ARTICLE_NOT_OLE_REDIR = 6
export const ARTICLES_BEFORE_END_TO_AUTOLOAD = 5
export const MAX_AUTOLOADED_ARTICLES = 50

/* eslint-disable-next-line max-len */
export const urlRegExp = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]?[a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]?[a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})/

export function getArticleUrl(article: Article) {
  /**
   * @example orig_url: "http://kulturistika.ronnie.cz/c-25432-na-balaton-open-throwdown-2016-zavodili-i-cesi.html"
   * @example url: "http://redir.opoint.com/?key=ajiIFdAFBziAtzMZ1FoM"
   *
   * We return orig_url in case article is public or navigate to
   * redir page in case it's protected.
   * if content_protected is not from the interval [0,6]m return url
   *
   * @see https://trello.com/c/H3MyjB21/1274-articles-behind-paywalls-is-being-visible-to-the-users-the-wrong-way
   * @see https://trello.com/c/9EnBJiRG/1584-pay-wall-we-read-behind-the-wall-in-m3-but-not-in-app
   *
   */

  function isOrigUrlUsed(contentProtected: number) {
    switch (contentProtected) {
      case ARTICLE_LOGIN:
      case ARTICLE_LOGIN_DOWNLOAD:
      case ARTICLE_NOT_OLE_REDIR:
        return false
      case ARTICLE_NORMAL:
      case ARTICLE_NORMAL_SEPARATE_VIP:
      case ARTICLE_GALLERY:
      case ARTICLE_NOT_OLE:
        return true
      default:
        return true
    }
  }

  return isOrigUrlUsed(article.content_protected) ? article.orig_url : article.url
}

/**
 * Function return primary and secondary url for article headline
 * for translated article urls are swapped
 *
 * @param {Article} article   Article object
 * @returns {{ primary: string, secondary: string}}
 */
export function getHeaderUrls(article) {
  const origUrl = getArticleUrl(article)
  const gtUrl = article.GTranslate_url
  return article.translated ? { primary: gtUrl, secondary: origUrl } : { primary: origUrl, secondary: gtUrl }
}

/**
 * Function return language iso codes
 * original and destination if translated
 *
 * @param {Article} article   Article object
 * @returns {{orig: string, dest:? string}}
 */
export function getLangCodes(article) {
  if (article.translated) {
    return {
      orig: article.orig_language.text,
      dest: article.language.text,
    }
  }
  return {
    orig: article.language.text,
    dest: article.GTranslate_url ? article.GTranslate_url.match(/tl=([a-z]{2})/)[1] : null,
  }
}

/**
 * Funtion returns node reference of specific article from Array of references
 * @param  {Array} articlesRefs   Array of article refs
 * @param  {Article} article      Article object
 * @return {Node}                 Node reference for specific article
 */
export function getActiveArticleNode(articlesRefs, article) {
  return ReactDOM.findDOMNode(articlesRefs[articleId(article)])
}

/**
 * Function is used to decide if there is change of active article or not.
 * @param  {Node} container     Article listing container
 * @param  {Array} articles     Array of Article objects
 * @param  {Array} articlesRefs  Array of article nodes references
 * @param  {Integer} active     index of active article
 * @return {Integer}            returns relative change of active article index
 *                              or 0 if there is no change
 */
export function crossScrollTreshold(container, articles, articlesRefs, active) {
  const treshold = 75
  const activeArticle = articles[active]
  if (!activeArticle) {
    return null
  }
  const article = getActiveArticleNode(articlesRefs, activeArticle)
  const articleBox = article.getBoundingClientRect()
  const containerBox = container.getBoundingClientRect()

  if (articles.length === active + 1) {
    return null
  }

  // Change index when scrolling up
  if (articleBox.top - treshold > containerBox.top) {
    return getIndexChange(-1, active, articles, articlesRefs, containerBox, treshold)
  }

  // Change index when scrolling down
  if (articleBox.bottom - treshold <= containerBox.top) {
    return getIndexChange(1, active, articles, articlesRefs, containerBox, treshold)
  }

  return null
}

/**
 * function return index of new active article relative to current active
 * article
 * @param  {[Integer]} direction    direction of scroll (+1/-1) - +1 is down
 * @param  {[Integer]} activeIndex  index of current active article
 * @param  {[Array]} articles       Array of Article objects
 * @param  {[Array]} articlesRefs    Array of article references
 * @param  {[Object]} containerBox  bounding rectangle of article listing
 *                                  container
 * @param  {[Integer]} treshold     Size of transition area where active
 *                                  article change can happen, in px
 * @return {[Integer]}              difference between old and new active
 *                                  article index
 */
export function getIndexChange(direction, activeIndex, articles, articlesRefs, containerBox, treshold) {
  let delta = null

  for (let i = activeIndex + direction; i >= 0 && i < articles.length; ) {
    const a = getActiveArticleNode(articlesRefs, articles[i])
    const aBox = a.getBoundingClientRect()

    if (R.gt(aBox.bottom - treshold, containerBox.top) && R.lte(aBox.top - treshold, containerBox.top)) {
      delta = i
      return delta
    }

    i += direction
  }

  return delta
}

export const suggestionMediatypesIntoSuggestionShape = R.map(
  ({ id_site: idSite, type, sitename }: AddArticleSuggestionMediatype) => ({
    id: idSite,
    name: `${type}: ${sitename}`,
    expandedMediatype: true,
  }),
)

export function addArticle(newArticle) {
  const requestHeaders = R.merge(config.getRequestHeaders(), {
    url: config.getUrl('/api/articles/create/'),
    method: 'post',
    body: JSON.stringify(newArticle),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

// used in Add Article file upload
export function uploadArticleFile(file: Object) {
  // dissocPath is used to remove content-type property from headers. This way
  // browser can set this header by itself. It is easiest way how to set correct
  // boundary for file upload to work correctly
  const requestHeaders = R.merge(R.dissocPath(['headers', 'content-type'], config.getRequestHeaders()), {
    url: config.getUrl('/api/articles/upload/'),
    method: 'post',
    body: file,
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

/**
 * Function copies input article to 1st position in
 * identical_documents.document array but without identical_documents prop
 * @param {Article} article - article to process
 * @return Article
 */
const removeIdentical = (article: Article): Article =>
  R.when(
    R.always(R.gt(R.length(article.identical_documents.document), 0)),
    R.evolve({
      identical_documents: {
        document: R.prepend(R.dissoc('identical_documents', article)),
      },
    }),
  )(article)

/**
 * Index tags by id
 * @param article
 */
export const indexTagsById = (article: Article): Article =>
  R.evolve({
    tags: R.indexBy(R.prop('id')),
  })(article)

/**
 * Create unique id of document and his own identical documents
 * @param article
 */
const indexBySiteAndArticleId = (article: Article): Article =>
  R.compose(
    R.assoc('id', articleId(article)),
    R.evolve({
      identical_documents: {
        document: R.map(indexBySiteAndArticleId),
      },
    }),
  )(article)

const normalizeTagsTimestamps = R.evolve({
  tags: R.map(
    R.evolve({
      sent: (timestamp) => OpointTimestampToTimestamp(timestamp) * 1000,
      equal_sent: (timestamp) => OpointTimestampToTimestamp(timestamp) * 1000,
    }),
  ),
})

/**
 * @sig Array<Article> -> Array<Article>
 */

export const preprocessArticle = R.compose(
  removeIdentical,
  R.evolve({
    identical_documents: {
      document: R.map(indexTagsById),
    },
  }),
  indexTagsById,
  normalizeTagsTimestamps,
  indexBySiteAndArticleId,
  articleWithCorrectedImagePaths,
)

export const preprocessArticles = R.compose(R.map(preprocessArticle))

/**
 * Reducer to merge current identical object with new properties
 * @param  {Object} acc      identical object with indentical articles indexes
 * @param  {Article} article article to iterate over
 * @return {Object}          merged identical object
 */
export const identicalReducer = (acc, article) =>
  R.gt(R.length(article.identical_documents.document), 0) ? R.merge(acc, { [articleId(article)]: 0 }) : acc

/**
 * Updates article using update metadata provided
 */
export function updateArticle(article: Article, updatedArticleData: UpdatedArticleData): Promise<any> {
  const { id_site, id_article } = article

  const updatedArticle = {
    body: updatedArticleData.body && updatedArticleData.body.text,
    summary: updatedArticleData.summary && updatedArticleData.summary.text,
    header: updatedArticleData.header && updatedArticleData.header.text,
    author: updatedArticleData.author,
  }

  const body = {
    id_site,
    id_article,
    ...updatedArticle,
  }

  const requestHeaders = R.merge(config.getRequestHeaders(), {
    method: 'POST',
    url: config.getUrl('/api/articles/edited/'),
    body: JSON.stringify(body),
    responseType: 'text', // backend returns nothing
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(() => ({
      ...article,
      ...updatedArticleData,
    }))
}

export function shareArticles(
  articles: Array<Article>,
  message: string,
  recipients: Array<any>,
  shareAttachment: boolean,
  title: string = 'Shared articles',
  templateId: number = 11,
) {
  const body = {
    articles,
    message,
    recipients,
    shareAttachment,
    templateId,
    title,
    timezone: jstz.determine().name(),
  }

  const requestHeaders = R.merge(config.getRequestHeaders(), {
    method: 'POST',
    url: config.getUrl('/api/articles/share/'),
    body: JSON.stringify(body),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

/**
 * Function calculates whether article should have sticky footer
 */
export function shouldHaveStickyTags(content: HTMLElement, text: HTMLElement, footer: HTMLElement) {
  const footerHeight = footer && footer.getBoundingClientRect().height
  const contentMeasure = content.getBoundingClientRect()
  const OFFSET = 240

  if (contentMeasure.height - (footerHeight || 0) < OFFSET) {
    return false
  }

  const windowInnerHeight = window.innerHeight

  return (
    !!content &&
    !!text &&
    !!footer &&
    windowInnerHeight - text.getBoundingClientRect().top > OFFSET &&
    contentMeasure.bottom + footerHeight > windowInnerHeight
  )
}

/**
 * Function calculates whether article should have sticky left side panel
 */
export function shouldHaveStickyLeftSide(
  content: HTMLElement,
  sidePanel: HTMLElement,
): { isSticky: boolean; isAtTheBottom: boolean } {
  const sidePanelMeasure = sidePanel && sidePanel.getBoundingClientRect()
  const contentMeasure = content.getBoundingClientRect()

  const HEADER_HEIGHT = 95
  const bottomOffset = contentMeasure.bottom - sidePanelMeasure.height - HEADER_HEIGHT

  return {
    isSticky: contentMeasure.top - HEADER_HEIGHT < 0 && bottomOffset > 0,
    isAtTheBottom: bottomOffset < 0,
  }
}

export function getGroupsOfIdMediatypes(sitesIds: string) {
  const requestHeaders = R.merge(config.getRequestHeaders(), {
    url: config.getUrl(`/api/site_search/site_type/?sites=${sitesIds}`),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

/**
 * Sends information about article read
 * @param article - article that was read
 * @param listingStyle - current display mode (archive, search engine, etc)
 * @param timeOffset - in seconds, indicates whether we waited a bit before we reported
 * the article was read (this happens in standard modes, where user can scroll through the listing)
 * @returns {*}
 */
export function sendPaymentInfo(article: Article, listingStyle, timeOffset: number = 0) {
  const { id_site, id_article } = article

  const requestHeaders = R.merge(config.getRequestHeaders(), {
    method: 'POST',
    url: config.getUrl('/api/articles/log_visited/'),
    body: JSON.stringify({
      id_site,
      id_article,
      uiMetadata: {
        listingStyle,
        timeOffset,
      },
    }),
  })

  return Rx.DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

import React, { useState, useEffect, memo } from 'react'
import R from 'ramda'
import { connect } from 'react-redux'
import { walk, getTreeFromFlatData, SortableTreeWithoutDndContext as SortableTree } from 'react-sortable-tree'

import Checkbox from '../../common/Checkbox'
import Modal from '../../common/Modal'
import buildActionCreators from '../../../helpers/buildActionCreators'
import Translate from '../../common/Translate'
import { getProfiles, getProfilesToDelete } from '../../../selectors/profilesSelectors'
import * as ActionTypes from '../../../constants/actionTypes'
import { getManageProfilesModalOpen, getDeleteProfilesModeEnabled } from '../../../selectors/uiSelectors'
import type { Profile } from '../../types/profile'
import styles from './index.module.scss'

interface Props {
  isOpen: boolean
  profiles: Profile[]
  deleteMode: boolean
  profileMarkedToDelete: number[]
  markToDelete: (payload: any) => any
  toggleDeleteMode: () => any
  manageProfilesModalClose: () => any
  moveProfile: (payload: any) => any
  deleteProfiles: (payload: { profileIds: number[] }) => any
}

const ManageProfilesModal = ({
  isOpen,
  manageProfilesModalClose,
  profiles,
  deleteMode,
  profileMarkedToDelete,
  markToDelete,
  toggleDeleteMode,
  moveProfile,
  deleteProfiles,
}: Props) => {
  const [treeData, setTreeData] = useState([])

  const onChangeHandler = (id) => {
    markToDelete(id)
  }

  const onMoveNodeHandler = ({ treeData, node: profile }) => {
    walk({
      callback: ({ node, parentNode }) => {
        if (node.id === profile.id) {
          moveProfile({
            parent: parentNode === null ? 0 : parentNode.id,
            position: (parentNode === null ? treeData : parentNode.children).findIndex(({ id }) => id === profile.id),
            profileId: profile.id,
          })
        }
      },
      getNodeKey: R.prop('id'),
      ignoreCollapsed: false,
      treeData,
    })
  }

  const deleteProfilesHandler = () => {
    deleteProfiles({ profileIds: profileMarkedToDelete })
  }

  const updateTree = (treeData) => {
    walk({
      callback: ({ node }) => {
        node.expanded = true
      },
      getNodeKey: R.prop('id'),
      ignoreCollapsed: false,
      treeData,
    })

    setTreeData(treeData)
  }

  useEffect(() => {
    const treeData = getTreeFromFlatData({
      flatData: profiles.map(({ name, id, ...props }) => ({
        title: () => (
          <div>
            {deleteMode ? (
              <Checkbox
                label={name}
                mode="delete"
                disabled={!!R.find(({ parent }) => id === parent, profiles)}
                checked={profileMarkedToDelete.includes(id)}
                onChange={() => onChangeHandler(id)}
              />
            ) : (
              <span className={styles.label}>{name}</span>
            )}
          </div>
        ),
        ...props,
        id,
      })),
      getKey: R.prop('id'),
      getParentKey: R.prop('parent'),
      rootKey: 0,
    })

    updateTree(treeData)
  }, [isOpen, deleteMode, profileMarkedToDelete])

  return (
    <Modal isOpen={isOpen} onHide={manageProfilesModalClose} title="Manage profiles">
      <SortableTree
        treeData={treeData}
        onChange={updateTree}
        rowHeight={50}
        canDrag={!deleteMode}
        onMoveNode={onMoveNodeHandler}
        className={styles.sortableTree}
      />
      <div className={styles.actions}>
        <button onClick={toggleDeleteMode} className="op-button default">
          {deleteMode ? <Translate text="Cancel" /> : <Translate text="Enable delete mode" />}
        </button>
        {deleteMode && (
          <button onClick={deleteProfilesHandler} disabled={!profileMarkedToDelete.length} className="op-button red">
            <Translate text="Delete profiles" />
          </button>
        )}
      </div>
    </Modal>
  )
}

export default connect(
  (state) => ({
    isOpen: getManageProfilesModalOpen(state),
    profiles: getProfiles(state),
    deleteMode: getDeleteProfilesModeEnabled(state),
    profileMarkedToDelete: getProfilesToDelete(state),
  }),
  buildActionCreators({
    markToDelete: ActionTypes.PROFILE_MARK_FOR_DELETE_MODE,
    toggleDeleteMode: ActionTypes.DELETE_PROFILES_MODE_TOGGLE,
    manageProfilesModalClose: ActionTypes.MANAGE_PROFILES_MODAL_CLOSE,
    moveProfile: ActionTypes.PROFILE_REORDER,
    deleteProfiles: ActionTypes.PROFILE_DELETE_CONFIRMATION_OPEN,
  }),
)(memo(ManageProfilesModal))

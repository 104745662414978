// @ts-nocheck
import PropTypes from 'prop-types'
import classNames from 'classnames'
import React from 'react'
import Rx from 'rx-dom'
import { Popover, OverlayTrigger } from 'react-bootstrap'

import Tooltip from '../../new-components/common/Tooltip'
import type { PortalAction /* , Searchline */ } from '../../opoint/flow'

type Props = {
  // TODO flow details @dmytro
  actions: Array<PortalAction>
  // alertPreview: boolean,
  // deleteAlertInProgress: boolean,
  // deleteFeedInProgress: boolean,
  // editedFeed: any | null,
  // getAddFormData: any,
  // getEditFormData: any,
  getPermissions: (module: any) => void
  // isEditedProfile: any,
  // isOneTagSelected: boolean,
  // isSearch: boolean,
  // newProfile: any,
  // noArticles: boolean,
  onActionClickHandler: (action: any) => void
  // onClickHandler: (action: SyntheticEvent<any>) => void,
  // openDatepicker: () => void, // Don't used @dmytro
  // routing: any,
  // searchMeta: any,
  // searchline: Searchline,
  // selectedArticlesIds: Array<string>,
  // showFilteredArticles: boolean,
  // statisticViewId: ?string,
  // statistics: boolean,
  // statisticsFiltered: boolean,
  maxAlerts: number
  alertsCount: number
  recipientsCount: number
  maxAlertRecipients: number
}

type State = {
  buttonsCnt: number
}

export default class BasicActionLine extends React.Component<Props, State> {
  static propTypes = {
    actions: PropTypes.array.isRequired,
  }
  static contextTypes = {
    i18n: PropTypes.object,
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      buttonsCnt: this.props.actions.length,
    }

    this.buttonsRef = []
    this.hiddenButtonsRef = []
    this.popoverRef = null
    this.groupBtnRef = null
    this.actionLineRef = null
    this.resize$ = null
  }

  componentDidMount() {
    this.resize$ = Rx.Observable.fromEvent(window, 'resize')
      .debounce(300)
      .subscribe(() => {
        this.update()
      })

    this.update()
  }

  componentWillUpdate() {
    this.buttonsRef = []
    this.hiddenButtonsRef = []
  }

  componentWillUnmount() {
    this.resize$.dispose()
  }

  onActionClickHandler = (action) => () => this.props.onActionClickHandler(action)

  onActionClickHideHandler = (action) => () => {
    this.popoverRef.hide()
    this.props.onActionClickHandler(action)
  }

  update = () => {
    /* eslint-disable-line consistent-return */
    if (!this.actionLineRef || !this.buttonsRef.length) {
      return false
    }

    const getButtonsWidth = (buttons, count) =>
      buttons
        .filter((b) => b !== null)
        .slice(0, count)
        .map((b) => {
          let { marginLeft, marginRight } = window.getComputedStyle(b)

          marginLeft = parseInt(marginLeft, 10)
          marginRight = parseInt(marginRight, 10)

          return marginLeft + marginRight + b.offsetWidth
        })
        .reduce((a, b) => a + b, 0)

    const {
      buttonsRef,
      actionLineRef: {
        offsetParent: { clientWidth: actionLineWidth },
      },
      groupBtnRef,
    } = this

    const groupButtonWidth = (button) => {
      const width = button.offsetWidth
      let { marginLeft, marginRight } = window.getComputedStyle(button)

      marginLeft = parseInt(marginLeft, 10)
      marginRight = parseInt(marginRight, 10)

      return width + marginLeft + marginRight
    }

    const { actions } = this.props
    let buttonsCnt = actions.length

    while (getButtonsWidth(buttonsRef, buttonsCnt) >= actionLineWidth - groupButtonWidth(groupBtnRef)) {
      buttonsCnt -= 1
    }

    if (this.state.buttonsCnt === buttonsCnt) {
      return false
    }

    this.setState({
      buttonsCnt,
    })
  }

  hidePopover = () => {
    this.popoverRef.hide()
  }

  render() {
    const { i18n } = this.context
    const { actions, maxAlerts, alertsCount, recipientsCount, maxAlertRecipients } = this.props
    const { buttonsCnt } = this.state
    const sortedActions = actions.filter((a) => a !== null).sort((a, b) => a.priority - b.priority)
    const excessiveButtons = sortedActions.slice(buttonsCnt)

    const isAlertsLimitReached = alertsCount >= maxAlerts
    const isRecipientsLimitReached = recipientsCount > maxAlertRecipients

    const alertsTooltip = (
      <Tooltip content={`${i18n.t('You have reached your maximum of')} ${maxAlerts} ${i18n.t('alerts')}`}>
        <div className="toolip-trigger" />
      </Tooltip>
    )

    const recipientsTooltip = (
      <Tooltip content={`${i18n.t('You have reached your maximum of')} ${maxAlertRecipients} ${i18n.t('recipients')}`}>
        <div className="toolip-trigger" />
      </Tooltip>
    )

    const groupButtonPopover = (
      <Popover id="ActionLinePopover" className="actionLine__options">
        <ul>
          {excessiveButtons.map((action, key) => {
            const isDisabled =
              (action.isNewAlertAction && isAlertsLimitReached) ||
              (action.isSaveAlertAction && isRecipientsLimitReached)

            if (action.module && action.hasPermissions) {
              const moduleAccessLevel = this.props.getPermissions(action.module)
              if (!action.hasPermissions(moduleAccessLevel)) {
                return null
              }
            }

            if (typeof action.onClickAction !== 'function') {
              return (
                <li
                  className={classNames('actionLine-popover-child', { disabled: isDisabled })}
                  key={action.id}
                  onClick={isDisabled ? undefined : this.onActionClickHideHandler(action.onClickAction)}
                  ref={(ref) => {
                    this.hiddenButtonsRef[key] = ref
                  }}
                >
                  {action.isNewAlertAction && isAlertsLimitReached && alertsTooltip}
                  {action.isSaveAlertAction && isRecipientsLimitReached && recipientsTooltip}
                  <a>
                    {action.icon && <i className={action.icon} />}
                    {action.name}
                  </a>
                </li>
              )
            }
            return (
              <action.onClickAction
                key={action.id}
                keyRef={key}
                buttonsCnt={buttonsCnt}
                name={action.name}
                hidePopover={this.hidePopover}
                isFromPopover
              />
            )
          })}
        </ul>
      </Popover>
    )
    const isOneExcessive = excessiveButtons.length === 1 && sortedActions.length <= 5

    return (
      <div className="row">
        <div
          className="actionLine"
          ref={(ref) => {
            this.actionLineRef = ref
          }}
        >
          {sortedActions.map((action, key) => {
            // Do NOT disable but completely hide button
            // for no permission
            if (
              action.hasPermissions &&
              action.module &&
              !action.hasPermissions(this.props.getPermissions(action.module)) &&
              action.hideOnNoPermission
            ) {
              return null
            }

            // onClickAction is an action string
            if (typeof action.onClickAction === 'string') {
              let isDisabled
              if (action.module && action.hasPermissions) {
                const moduleAccessLevel = this.props.getPermissions(action.module)
                isDisabled = !action.hasPermissions(moduleAccessLevel)
              }
              return isDisabled ? null : (
                <span
                  key={key}
                  ref={(ref) => {
                    this.buttonsRef[key] = ref
                  }}
                  className={classNames(
                    'op-button-toolbar-wrapper',
                    { 'actionLine__button--hidden': !isOneExcessive && key >= buttonsCnt },
                    { 'actionLine__button--last': key === buttonsCnt - 1 },
                  )}
                >
                  {action.isNewAlertAction && isAlertsLimitReached && alertsTooltip}
                  {action.isSaveAlertAction && isRecipientsLimitReached && recipientsTooltip}
                  <button
                    disabled={
                      isDisabled ||
                      (action.isNewAlertAction && isAlertsLimitReached) ||
                      (action.isSaveAlertAction && isRecipientsLimitReached)
                    }
                    onClick={this.onActionClickHandler(action.onClickAction)}
                    className="op-button-toolbar"
                  >
                    <span>{action.name}</span>
                    {action.icon && <i className={action.icon} />}
                  </button>
                </span>
              )
              // onClickAction is a React$Element reference
            }
            if (typeof action.onClickAction === 'function') {
              return (
                <span
                  key={key}
                  ref={(ref) => {
                    this.buttonsRef[key] = ref
                  }}
                  className={classNames(
                    'op-button-toolbar-wrapper',
                    { 'actionLine__button--hidden': !isOneExcessive && key >= buttonsCnt },
                    { 'actionLine__button--last': key === buttonsCnt - 1 },
                  )}
                >
                  <action.onClickAction keyRef={key} buttonsCnt={buttonsCnt} name={action.name} />
                </span>
              )
            }
            return null
          })}
          <OverlayTrigger
            trigger="click"
            ref={(ref) => {
              this.popoverRef = ref
            }}
            rootClose
            placement="bottom"
            overlay={groupButtonPopover}
          >
            <button
              ref={(ref) => {
                this.groupBtnRef = ref
              }}
              className={classNames('op-button-toolbar', 'actionLine__groupButton', {
                'actionLine__button--hidden': isOneExcessive || actions.length <= actions.slice(0, buttonsCnt).length,
              })}
            >
              <i className="op-icon-three-dots" />
            </button>
          </OverlayTrigger>
        </div>
      </div>
    )
  }
}

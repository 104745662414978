import React, { ReactNode } from 'react'
import { OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap'

export interface Props {
  placement?: 'top' | 'right' | 'bottom' | 'left'
  content: ReactNode
  children: ReactNode
  className?: string
}

export default function Tooltip({ placement = 'top', children, content, className }: Props) {
  const tooltip = (
    <BootstrapTooltip id="editTooltip" className={className}>
      {content}
    </BootstrapTooltip>
  )

  return (
    <OverlayTrigger placement={placement} overlay={tooltip}>
      {children}
    </OverlayTrigger>
  )
}

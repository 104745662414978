// @ts-nocheck
import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Popover, Overlay } from 'react-bootstrap'

import XCloseButton from '../../common/XCloseButton'
import ModulePermissions from '../../common/ModulePermissions'
import { MODULE, MODULE_OPTION } from '../../../opoint/settings/index'
import ArticleTags from '../../tags/tag'
import buildActionCreators from '../../../helpers/buildActionCreators'
import IdenticalArticles from './IdenticalArticles'
import Translate from '../../../Translate'
import * as ActionTypes from '../../../constants/actionTypes'
import { TYPE_PREVIEW } from '../../../opoint/articles/index'
import { getLastUsedTag } from '../../../selectors/tagsSelectors'

class ArticleFooterComponent extends React.PureComponent<any> {
  static propTypes = {
    article: PropTypes.object.isRequired,
    setTag: PropTypes.func.isRequired,
    incrementTag: PropTypes.func.isRequired,
    decrementTag: PropTypes.func.isRequired,
    tagsFn: PropTypes.func.isRequired,
  }

  state = {
    showPopup: false,
    targetRef: null,
  }

  onControllerLeftClick = (originalArticle) => {
    const { previousIdentical } = this.props

    return () => previousIdentical(originalArticle)
  }

  onControllerRightClick = (originalArticle) => {
    const { nextIdentical } = this.props

    return () => nextIdentical(originalArticle)
  }

  toggleIdenticalArticlesPopup = () =>
    this.setState(
      {
        showPopup: !this.state.showPopup,
      },
      this.waitTransitionFinish,
    )

  showIdenticalArticlesPopup = (targetRef) =>
    this.setState({
      showPopup: true,
      targetRef,
    })

  hideIdenticalArticlesPopup = () => {
    this.setState(
      {
        showPopup: false,
      },
      this.waitTransitionFinish,
    )
  }

  waitTransitionFinish = () => setTimeout(() => this.setState({ targetRef: null }), 500)

  render() {
    const {
      identical,
      article,
      originalArticle,
      shouldHideTags,
      setActiveTag,
      originalArticle: { identical_documents: { document: identicalDocuments = [] } = {} } = {},
      lastUsedTag,
    } = this.props
    const { showPopup, targetRef } = this.state

    const tags = shouldHideTags ? [] : this.props.tagsFn(article)
    const visibleTrashTags = this.props.visibleTrashTagsFn(article)

    const articleTags = (
      <ModulePermissions module={MODULE.TAG} permissions={[MODULE_OPTION.ON]}>
        <ArticleTags
          article={originalArticle || article}
          decrementTag={this.props.decrementTag}
          incrementTag={this.props.incrementTag}
          setTag={this.props.setTag}
          setTrashTag={this.props.setTrashTag}
          tags={tags}
          visibleTrashTags={visibleTrashTags}
          setActiveTag={setActiveTag}
          lastUsedTag={lastUsedTag}
          showIdenticalArticlesPopup={this.showIdenticalArticlesPopup}
        />
      </ModulePermissions>
    )

    if (!identicalDocuments.length) {
      return (
        <div className="col-md-12">
          <div className="row">{articleTags}</div>
        </div>
      )
    }

    return (
      <div>
        {/* Identical articles controlls */}
        <div className="col-md-2 util_col-vertical-center">
          <div className="row">
            <div className="article-identical-controller">
              <div className="article-identical-controller--left" onClick={this.onControllerLeftClick(originalArticle)}>
                <i className="op-icon-arrow-left" />
              </div>

              <div
                className="article-identical-controller--center"
                onClick={this.toggleIdenticalArticlesPopup}
                ref={(ref) => {
                  this.target = ref
                }}
              >
                <Translate
                  i18nString="{{identicalCount}} of {{identicalDocumentsCount}}"
                  context={{
                    identicalCount: identical + 1,
                    identicalDocumentsCount: identicalDocuments.length,
                  }}
                />
              </div>

              <Overlay
                show={showPopup}
                placement="top"
                container={this}
                containerPadding={20}
                /* eslint-disable-next-line */
                target={() => ReactDOM.findDOMNode(targetRef || this.target)}
              >
                <Popover id="identical-article-switcher">
                  <XCloseButton onClick={this.hideIdenticalArticlesPopup} />
                  <IdenticalArticles
                    {...this.props}
                    article={originalArticle}
                    activeArticle={article}
                    locale={this.props.locale}
                    lastUsedTag={lastUsedTag}
                    autoExpand
                    initiallyShown={1}
                    type={TYPE_PREVIEW}
                    showActiveTag
                  />
                </Popover>
              </Overlay>
              <div
                className="article-identical-controller--right"
                onClick={this.onControllerRightClick(originalArticle)}
              >
                <i className="op-icon-arrow-right" />
              </div>
            </div>
          </div>
        </div>

        {/* Article tags */}
        <div className="col-md-10 util_col-vertical-center">
          <div className="row">{articleTags}</div>
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    lastUsedTag: getLastUsedTag(state),
  }),
  buildActionCreators({
    setTag: ActionTypes.TOGGLE_TAG_ARTICLE,
    setTrashTag: ActionTypes.TOGGLE_DELETE_ARTICLES,
    incrementTag: ActionTypes.INCREMENT_TAG_WEIGHT_FOR_ARTICLE,
    decrementTag: ActionTypes.DECREMENT_TAG_WEIGHT_FOR_ARTICLE,
    nextIdentical: ActionTypes.NEXT_IDENTICAL,
    previousIdentical: ActionTypes.PREVIOUS_IDENTICAL,
    setActiveTag: ActionTypes.SET_LAST_USED_TAG_ID,
  }),
)(ArticleFooterComponent)

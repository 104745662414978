import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'

type inputProps = {
  input: Object
  label: string | Function
  id: string
  errorMsg: string | Function
  type: string
  meta: Object
  touched: boolean
  error: boolean
  placeholder: string
  disabled?: boolean
}

const OpointInput = (props: inputProps, context: any) => {
  const { input, label, id, errorMsg, type, meta, placeholder, disabled } = props
  const { i18n } = context
  // @ts-ignore
  const { touched, error } = meta || {}

  return (
    <div className={classNames('op-form-group', touched && error ? 'is-error' : null, { 'is-disabled': disabled })}>
      <label htmlFor={id}>{label}</label>
      <input placeholder={i18n.t(placeholder)} {...input} id={id} name="title" type={type} disabled={disabled} />
      {touched && error && <p className="help-block">{errorMsg}</p>}
    </div>
  )
}

OpointInput.contextTypes = {
  i18n: PropTypes.object,
}

export default translate([], { wait: true })(OpointInput)

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import FiltersRow from './FilterRow'
import Translate from '../common/Translate'
import styles from './index.module.scss'
import Icon from '../common/Icon'

class FiltersPanelColumn extends React.PureComponent<any> {
  static contextTypes = {
    i18n: PropTypes.object,
  }

  render() {
    const { filters, filterName, action, fetchMoreFilters, width, ...props } = this.props

    return (
      <div
        key={filterName}
        className={classNames(
          styles.filterColumn,
          {
            [styles.width2]: width === 2,
          },
          {
            [styles.width3]: width === 3,
          },
        )}
      >
        <button
          onClick={(e) => {
            e.preventDefault()
            fetchMoreFilters({
              header: filterName,
              action,
            })
          }}
          disabled={!action || action === '0'}
          className={styles.header}
        >
          {filterName && (
            <span className={styles.title}>
              <Translate text={filterName} />
            </span>
          )}
          {action && action !== '0' && <Icon name="plus" verticalCenter />}
        </button>
        <ul>{filters && filters.map((filter) => <FiltersRow key={filter.id} filter={filter} {...props} />)}</ul>
      </div>
    )
  }
}

export { FiltersPanelColumn }
